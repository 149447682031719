import * as Account from "Store/constants/account.constants";

const initialState = {
  list: [],
  loading: false,
  loadingStatus: "",
  balanceLoadingStatus: "",
  dropDownList: [],
  accountBalanceDropDownList: [],
  totalYearlyTrxn: [],
  monthlyAccountBalance: [],
  statement: {
    loadingStatus: "",
    walletStatement: [],
    openingBalance: 0.0,
    closingBalance: 0.0,
  },
};

const nameFormat = {
  January: { name: "Jan" },
  February: { name: "Feb" },
  March: { name: "Mar" },
  April: { name: "Apr" },
  May: { name: "May" },
  June: { name: "Jun" },
  July: { name: "Jul" },
  August: { name: "Aug" },
  September: { name: "Sept" },
  October: { name: "Oct" },
  November: { name: "Nov" },
  December: { name: "Dec" },
};

const nameFormater = (chartData) => {
  return chartData.map((item) => ({
    ...item,
    month: nameFormat[item.month].name,
  }));
};

const formatForDropdown = (payloadData) => {
  return payloadData.map((item) => ({
    ...item,
    value: item['walletAccountCurrency'],
    label: `${(item['accountName'] === null || item['accountName'] === '') ? 'No Account Name' : item['accountName'].toUpperCase()} (${item['walletAccountCurrency']} ${item['walletAccountBalance'].toLocaleString()})`,
  }));
};

const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case Account.FETCH_ACCOUNT_BALANCE_REQUEST:
      return {
        ...state,
        loading: true,
        loadingStatus: "FETCHING",
      };

    case Account.FETCH_ACCOUNT_BALANCE_SUCCESS:
      return {
        ...state,
        loading: false,
        loadingStatus: "SUCCESS",
        list: action.payload,
        dropDownList: formatForDropdown(action.payload),
      };

    case Account.FETCH_ACCOUNT_BALANCE_FAILURE:
      return {
        ...state,
        list: [],
        loading: false,
        loadingStatus: "ERROR",
      };

    case Account.FETCH_ACCOUNT_BALANCE_BY_ACCOUNT_CURRENCY_REQUEST:
      return {
        ...state,
        loading: true,
        balanceLoadingStatus: "FETCHING",
      };

    case Account.FETCH_ACCOUNT_BALANCE_BY_ACCOUNT_CURRENCY_SUCCESS:
      return {
        ...state,
        loading: false,
        balanceLoadingStatus: "SUCCESS",
        list: action.payload,
        accountBalanceDropDownList: formatForDropdown(action.payload),
      };

    case Account.FETCH_ACCOUNT_BALANCE_BY_ACCOUNT_CURRENCY_FAILURE:
      return {
        ...state,
        list: [],
        loading: false,
        balanceLoadingStatus: "ERROR",
      };

    case Account.FETCH_ACCOUNT_STATEMENT_REQUEST: {
      return {
        ...state,
        statement: {
          ...state.statement,
          loadingStatus: "FETCHING",
        },
      };
    }

    case Account.FETCH_ACCOUNT_STATEMENT_SUCCESS:
      return {
        ...state,
        statement: {
          ...state.statement,
          ...action.payload,
          loadingStatus: "SUCCESS",
          walletStatement: action.payload['walletStatemtent'],
        },
      };

    case Account.FETCH_ACCOUNT_STATEMENT_FAILURE:
      return {
        ...state,
        statement: {
          ...state.statement,
          loadingStatus: "ERROR",
        },
      };

    case Account.FETCH_MONTHLY_ACCOUNT_BALANCE_REQUEST: {
      return {
        ...state,
        loadingStatus: "FETCHING"
      };
    }

    case Account.FETCH_MONTHLY_ACCOUNT_BALANCE_SUCCESS:
      return {
        ...state,
        loadingStatus: "SUCCESS",
        monthlyAccountBalance: action.payload,
      };

    case Account.FETCH_MONTHLY_ACCOUNT_BALANCE_FAILURE:
      return {
        ...state,
        loadingStatus: "ERROR",
      };

    case Account.FETCH_YEARLY_TRANSACTION_REQUEST: {
      return {
        ...state,
        loadingStatus: "FETCHING"
      };
    }

    case Account.FETCH_YEARLY_TRANSACTION_SUCCESS:
      const list = action.payload;
      return {
        ...state,
        loadingStatus: "SUCCESS",
        totalYearlyTrxn: list,
      };

    case Account.FETCH_YEARLY_TRANSACTION_FAILURE:
      return {
        ...state,
        statement: {
          ...state.statement,
          loadingStatus: "ERROR",
        },
      };

    default:
      return state;
  }
};

export default accountReducer;

// eslint-disable-next-line no-unused-vars
import React from "react";
import styled from "@emotion/styled";

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/react";

const Label = styled.p`
  font-size: 16px;
  color: ${(props) => props.theme.primaryColor};
  font-weight: 500;
`;

export default ({ text, required, children }) => {
  return (
    <Label>
      {text}
      {required ? <span style={{ color: "red", marginBottom: "12px" }}>*</span> : ""}
      {children}
    </Label>
  );
};

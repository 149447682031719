import * as DomainBrand from "../constants/domain-brand.constants";
import * as Loading from "../constants/loadingState.constants";

const initialState = {
  domainBrand: {},
  loading: ""
};

const formatLanguage = (payloadData) => {
  let newArray = [];
  for (const language in payloadData) {
    const forEach = {
      id: language,
      value: language,
      commonName: payloadData[language],
      label: payloadData[language]
    };
    newArray.push(forEach);
  }
  return newArray;
};

const domainBrandReducer = (state = initialState, action) => {
  switch (action.type) {
    case DomainBrand.GET_DOMAIN_BRAND_IDENTITY_REQUEST:
      return {
        ...state,
        loading: Loading.FETCHING
      };

    case DomainBrand.GET_DOMAIN_BRAND_IDENTITY_SUCCESS:
      const data = { ...action.payload };
      const formattedLanguage = formatLanguage(action.payload.language);
      data.language = formattedLanguage;

      return {
        ...state,
        domainBrand: data,
        loading: Loading.SUCCESS
      };

    case DomainBrand.GET_DOMAIN_BRAND_IDENTITY_FAILURE:
      return {
        ...state,
        loading: Loading.ERROR
      };

    default:
      return state;
  }
};

export default domainBrandReducer;

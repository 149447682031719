// eslint-disable-next-line no-unused-vars
import React from 'react';
/** @jsx jsx */
/** @jsxRuntime classic */

import { jsx } from '@emotion/react';
import styled from '@emotion/styled';
import CustomButton from 'Components/CustomButton';

const ContentContainer = styled.div`
  background: white;
  border: 1px solid #1149835b;
  min-height: 50vh;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50vw;
  flex-direction: column;
`;

const MainHeading = styled.h1`
  text-align: center;
  margin: 0;
  font-weight: 600;
  font-size: 2rem;
  line-height: 1.35;
  margin-bottom: 1.5rem;
  color: ${props => props.theme.primaryColor};
`;

const SubHeading = styled.p`
  text-align: center;
  color: ${props => props.theme.primaryColor};
  font-weight: 400;
  font-size: 1.35rem;
  margin-bottom: 1rem;
`;

const SubHeadingTwo = styled.p`
  text-align: center;
  color: ${props => props.theme.primaryColor};
  font-size: 1rem;
  margin-bottom: 1.3rem;
  line-height: 1.4;
`;

const MoneyText = styled.span`
  color: #0fcc98;
  font-weight: 600;
`;

const ModalContent = ({ closeModal }) => {
  return (
    <ContentContainer>
      <MainHeading>
        We are checking the details <br /> of your payment
      </MainHeading>
      <SubHeading>What happens after we receive your payment</SubHeading>
      <SubHeadingTwo>
        After we receive your money, <MoneyText>12.93</MoneyText> <br />
        USD will be transferred to Cath Rina.
      </SubHeadingTwo>
      <CustomButton
        buttonText="Transfer details"
        buttonWidth="25%"
        onClick={closeModal}
      />
    </ContentContainer>
  );
};

export default ModalContent;

import setAuthHeader from "./setAuthHeader";

export class TokenHelpers {
  static getToken() {
    return localStorage.getItem('mtf_token_f5c2c287');
  }

  static saveToken(token) {
    localStorage.setItem('mtf_token_f5c2c287', token);
  }

  static deleteToken() {
    localStorage.removeItem('mtf_token_f5c2c287');
  }

  static clearAllTokens() {
    localStorage.clear();
  }

  static checkIfLoggedIn() {
    const TOKEN = localStorage.getItem('mtf_token_f5c2c287');
    return !!TOKEN;
  }

  static autoLogIn() {
    const token = TokenHelpers.getToken();
    if (token) {
      setAuthHeader(token);
    }
  }
}

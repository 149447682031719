// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from "react";
/** @jsx jsx */
/** @jsxRuntime classic */
import { jsx, css } from "@emotion/react";
import { Helmet } from "react-helmet";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import HeadingText from "Components/HeadingText";
import NavBar from "Components/NavbarKYC";
import Footer from "Components/Footer";
import { Container, InnerContainer, OffsetIcon, ImageElement } from "Components/Styled";
import CustomInput from "Components/CustomInput";
import offSetImg from "Assets/svg/man-user-1.svg";
import {
  mainBody,
  textContainer,
  P,
  uploadContainerHeader,
  emailText,
  uploadContainerDiv,
  leftCol,
  rightCol
} from "./style";
import ErrorField from "Components/ErrorField";
import FormLabel from "Components/FormLabel";
import CustomButton from "Components/CustomButton";
import ButtonKYC from "Components/ButtonKYC";
import { getAllCountries } from "Store/actions/country.action";
import { getAllComplianceForm, updateUploadLater } from "Store/actions/compliance.action";
import * as Loading from "Store/constants/loadingState.constants";
import { useHistory } from "react-router-dom";
import { userStatus } from "Utils/constants";
import Spinner from "Components/Spinner";
import AriaModal from "react-aria-modal";
import DatePicker from "react-datepicker";
import FormSelect from "Components/FormSelect";
import FormSelectKYC from "Components/FormSelectKYC";
import { complianceOptions } from "Utils/constants";
import { logUserOut } from "Store/actions/auth.action";
import { fetchDocuments, uploadDocuments } from "Store/actions/document.action";
import { fileToBase64, daysFromToday } from "Utils/Helpers/general";
import { toast } from "react-toastify";
import userReducer from "Store/reducers/user.reducer";
import PlusIcon from "Assets/svg/plus-form.svg";
import {
  modalContainer,
  modalFormContainer,
  modalFormRow,
  modalContainerHeading,
  fileUploadInput,
  fileUploadLabel,
  modalFormDatePicker,
  uploadButton,
  MainCompliance
} from "./style";

const CreateUser = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [showModal, updateShowModal] = useState(false);
  let domainBrandStatus;

  const {
    country,
    user,
    compliance,
    complianceAction,
    userData,
    countryDropdown,
    uploadDocumentStatus,
    domainBrand
  } = useSelector(
    ({
      countryReducer,
      complianceReducer,
      themeReducer,
      profileReducer,
      userReducer,
      authReducer,
      documentReducer,
      domainBrandReducer
    }) => ({
      country: countryReducer,
      complianceAction: complianceReducer.complianceFormList,
      compliance: complianceReducer,
      userData: profileReducer.userData,
      countryDropdown: countryReducer.countryDropdownList,
      uploadDocumentStatus: documentReducer.uploadDocumentStatus,
      user: userReducer,
      theme: themeReducer,
      domainBrand: domainBrandReducer
    })
  );

  // language state
  const languageDictionary = domainBrand.domainBrand["languageDictionary"];
  const languageDictionaryStatus = !(
    typeof languageDictionary === "undefined" || languageDictionary === null
  );

  domainBrandStatus = domainBrand.loading === Loading.SUCCESS;

  const handleHomePage = () => {
    // eslint-disable-next-line array-callback-return
    complianceAction.map((item) => {
      if (item.restrictionLevel === "BLOCK") {
        dispatch(logUserOut());
      } else {
        dispatch(updateUploadLater());
        history.push("/");
      }
    });
  };

  const handleLoginPage = () => {
    dispatch(logUserOut());
    history.push("/login");
  };

  const validateFile = (values) => {
    const allowedExtensions = ["jpg", "jpeg", "png", "pdf", "doc", "docx", "xls", "xlsx"],
      sizeLimit = 2097152; // 2 megabyte

    // const formatAllowedExtensions = allowedExtensions
    const { name: fileName, size: fileSize } = values;

    const fileExtension = fileName.split(".").pop();
    const formatFileExtension = fileExtension.toLowerCase();

    if (!allowedExtensions.includes(formatFileExtension)) {
      toast.error("Please upload only jpg, jpeg, png and pdf files", {
        position: "top-center",
        autoClose: false
      });
      values = null;
      return false;
    } else if (fileSize > sizeLimit) {
      toast.error("File size too large", {
        position: "top-center"
      });
      values = null;
      return false;
    }

    return true;
  };

  const showSpinner = compliance.loading === Loading.FETCHING;
  const complianceFormLoading = compliance.loading === Loading.SUCCESS;
  const docUploadLoading = uploadDocumentStatus === Loading.FETCHING;

  const formik = useFormik({
    initialValues: {
      documentType: "",
      issueDate: daysFromToday(),
      expiryDate: new Date(),
      idNumber: "",
      issueCountry: "",
      dateOfBirth: daysFromToday(),
      file_data: ""
    },
    validationSchema: Yup.object({
      idNumber: Yup.string().required("required"),
      issueCountry: Yup.object({
        value: Yup.string().required("required")
      }),
      documentType: Yup.object({
        value: Yup.string().required("required")
      }),
      expiryDate: Yup.string().required("required"),
      issueDate: Yup.string().required("required"),
      dateOfBirth: Yup.string().required("required")
    }),
    validate: (values) => {
      const errors = {};

      if (!(values.file_data instanceof File)) {
        errors.file_data = "file is required";
      }
      return errors;
    },
    onSubmit: async (values) => {
      try {
        const fileDataBase64 = await fileToBase64(values.file_data);

        const payload = {
          customerCode: userData.customerCode,
          attachedDocument: [
            {
              file_base64: fileDataBase64,
              name: values.documentType.value,
              idType: values.documentType.value,
              dob: values.dateOfBirth,
              issueCountry: values.issueCountry.value,
              issueNo: values.idNumber,
              issueDate: values.issueDate.toISOString(),
              expiryDate: values.expiryDate
            }
          ]
        };
        dispatch(uploadDocuments(payload));

        setTimeout(() => {
          updateShowModal(false);
          dispatch(getAllComplianceForm());
        }, 1000);
      } catch (error) {
        toast.error("error uploading file", {
          position: "top-center"
        });
      }
    }
  });

  const getApplicationNode = () => {
    return document.getElementById("root");
  };

  useEffect(() => {
    if (userStatus === Loading.SUCCESS) {
      dispatch(fetchDocuments(userData.customerCode));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userStatus]);

  useEffect(() => {
    if (country.loading !== Loading.SUCCESS || country.loading === Loading.ERROR) {
      dispatch(getAllCountries());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (compliance.loading !== Loading.SUCCESS || compliance.loading === Loading.ERROR) {
      dispatch(getAllComplianceForm());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>
          KYC Compliance &mdash;{" "}
          {domainBrandStatus ? domainBrand["domainBrand"]["companyName"] : "MultiView MTA"}
        </title>
      </Helmet>
      <Container>
        <NavBar />
        <InnerContainer>
          <OffsetIcon>
            <ImageElement src={offSetImg} title="money icon" />
          </OffsetIcon>
          <MainCompliance>
            {complianceFormLoading && complianceAction.length === 0 ? (
              <>
                <P>
                  {languageDictionaryStatus ? languageDictionary["WO_HELL_589729818"] : "Hello"}{" "}
                  {userData.firstName && `${userData.firstName},`}
                </P>

                <div css={textContainer}>
                  <p css={emailText}>
                    {languageDictionaryStatus
                      ? languageDictionary["WO_THERE_ARE_CURRENTLY_445624668"]
                      : "There are currently no outstanding document awaiting upload. Your registration application is currently undergoing review. You will receive email confirmation once your application is approved."}
                  </p>
                </div>
                <div className="text-center">
                  <CustomButton
                    buttonText={
                      languageDictionaryStatus
                        ? languageDictionary["WO_PLEASE_PROCEED_TO_LO_-1389523935"]
                        : "Please proceed to login"
                    }
                    buttonWidth="30%"
                    marginTop="0"
                    onClick={handleLoginPage}
                  />
                </div>
              </>
            ) : (
              <>
                {showSpinner ? (
                  <Spinner />
                ) : (
                  <>
                    <P>
                      {" "}
                      {languageDictionaryStatus
                        ? languageDictionary["WO_HELL_589729818"]
                        : "Hello"}{" "}
                      {userData.firstName && `${userData.firstName},`}
                    </P>

                    <HeadingText
                      text={
                        languageDictionaryStatus
                          ? languageDictionary["WO_PLEASE_UPLOAD_THE_BE_1913169394"]
                          : "The documents below have been requested by the compliance team"
                      }
                      textAlign="center"
                      fontSize="20px"
                    />
                    <div css={mainBody} className="table-responsive">
                      <table className="table">
                        <div>
                          <div css={uploadContainerHeader} className="thead">
                            <HeadingText text="Name" fontSize="20px" />
                            <HeadingText text="Action" fontSize="20px" />
                          </div>
                          {complianceAction.map((form, index) => {
                            return (
                              <>
                                {form.actionType === "VERIFY_ACCOUNT_VIA_EMAIL" ? (
                                  <div key={index} css={uploadContainerDiv}>
                                    <div css={leftCol}>
                                      <h4>Verify customer email</h4>
                                      <h4>
                                        (
                                        {languageDictionaryStatus
                                          ? languageDictionary["WO_OPTIONA_391630173"]
                                          : "OPTIONAL"}
                                        )
                                      </h4>
                                    </div>
                                    <div css={rightCol}>
                                      <p css={emailText}>
                                        {languageDictionaryStatus
                                          ? languageDictionary[
                                              "WO_IF_YOU_DO_NOT_RECEIV_-1320552170"
                                            ]
                                          : "If you do not receive our email address verification right after registration, please look into your SPAM or Junk mailbox or try to register once again, indicating an alternative email address."}
                                        ({userData.email})
                                      </p>
                                    </div>
                                  </div>
                                ) : (
                                  <div>
                                    <div css={uploadContainerDiv}>
                                      <div css={leftCol}>
                                        <h4>{form.docTypeCommonName}</h4>
                                        <h4>
                                          ({form.restrictionLevel === "BLOCK" ? "MANDATORY" : null}
                                          {form.restrictionLevel === "WARN" ? "OPTIONAL" : null})
                                        </h4>
                                      </div>
                                      <div css={rightCol}>
                                        <ButtonKYC
                                          buttonText={
                                            languageDictionaryStatus
                                              ? languageDictionary["WO_UPLOAD_DOCUMEN_1496209122"]
                                              : "Upload Document"
                                          }
                                          buttonWidth="350px"
                                          buttonIcon={PlusIcon}
                                          marginTop="0"
                                          onClick={() => updateShowModal(true)}
                                          type="button"
                                        />
                                      </div>
                                    </div>
                                    <div>
                                      {showModal && (
                                        <AriaModal
                                          titleText={
                                            languageDictionaryStatus
                                              ? languageDictionary["para_attach_customer_documents"]
                                              : "Attach Customer Documents"
                                          }
                                          onExit={() => updateShowModal(false)}
                                          getApplicationNode={getApplicationNode}
                                          verticallyCenter={true}
                                          underlayStyle={{
                                            backdropFilter: "blur(28px)",
                                            background: "none"
                                          }}>
                                          <div css={modalContainer}>
                                            <h2 css={modalContainerHeading}>
                                              {languageDictionaryStatus
                                                ? languageDictionary[
                                                    "para_attach_customer_documents"
                                                  ]
                                                : "Attach Customer Documents"}
                                            </h2>
                                            <form onSubmit={formik.handleSubmit}>
                                              <div>
                                                <FormSelectKYC
                                                  formik={formik}
                                                  options={form.docTypeCommonName}
                                                  option2={complianceOptions}
                                                  value={form.docIdType}
                                                  onChange={formik.handleChange}
                                                  selectLabel={
                                                    languageDictionaryStatus
                                                      ? languageDictionary[
                                                          "WO_DOCUMENT_TYP_-13031371"
                                                        ]
                                                      : "Document Type"
                                                  }
                                                  selectPlaceholder="Select document type"
                                                  selectName="documentType"
                                                />
                                              </div>

                                              <div css={modalFormContainer}>
                                                <div className="row mt-md-3">
                                                  <div className="col-md-6">
                                                    <p className="benCreate__main__form--inputHeading mb-md-3">
                                                      {languageDictionaryStatus
                                                        ? languageDictionary["l_issue_country"]
                                                        : "Issue Country"}
                                                    </p>
                                                    <FormSelect
                                                      formik={formik}
                                                      options={countryDropdown}
                                                      selectPlaceholder="Select issue country"
                                                      selectName="issueCountry"
                                                      isSearchable
                                                    />
                                                  </div>
                                                  <div className="col-md-6">
                                                    <CustomInput
                                                      formik={formik}
                                                      inputKey="idNumber"
                                                      labelName={
                                                        languageDictionaryStatus
                                                          ? languageDictionary[
                                                              "WO_ID_NUMBE_1281572260"
                                                            ]
                                                          : "ID Number"
                                                      }
                                                      marginBottom="0"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="row">
                                                  <div className="col-md-6">
                                                    <FormLabel
                                                      text={
                                                        languageDictionaryStatus
                                                          ? languageDictionary["l_issue_date"]
                                                          : "Issue Date"
                                                      }>
                                                      <ErrorField
                                                        formik={formik}
                                                        fieldName="issueDate"
                                                      />
                                                      <DatePicker
                                                        selected={formik.values.issueDate}
                                                        onChange={(date) =>
                                                          formik.setFieldValue("issueDate", date)
                                                        }
                                                        onBlur={() =>
                                                          formik.setFieldTouched("issueDate", true)
                                                        }
                                                        startDate={formik.values.issueDate}
                                                        endDate={formik.values.expiryDate}
                                                        maxDate={formik.values.expiryDate}
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        dropdownMode="select"
                                                        selectsStart
                                                        css={modalFormDatePicker}
                                                      />
                                                    </FormLabel>
                                                  </div>
                                                  <div className="col-md-6">
                                                    <FormLabel
                                                      text={
                                                        languageDictionaryStatus
                                                          ? languageDictionary["l_expiry_date"]
                                                          : "Expiry Date"
                                                      }>
                                                      <ErrorField
                                                        formik={formik}
                                                        fieldName="expiryDate"
                                                      />
                                                      <DatePicker
                                                        selected={formik.values.expiryDate}
                                                        onChange={(date) =>
                                                          formik.setFieldValue("expiryDate", date)
                                                        }
                                                        onBlur={() =>
                                                          formik.setFieldTouched("expiryDate", true)
                                                        }
                                                        startDate={formik.values.issueDate}
                                                        endDate={formik.values.expiryDate}
                                                        minDate={formik.values.issueDate}
                                                        selectsEnd
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        dropdownMode="select"
                                                        css={modalFormDatePicker}
                                                      />
                                                    </FormLabel>
                                                  </div>
                                                </div>
                                                <div className="row">
                                                  <div className="col-md-6">
                                                    <FormLabel
                                                      text={
                                                        languageDictionaryStatus
                                                          ? languageDictionary["l_date_of_birth"]
                                                          : "Date Of Birth"
                                                      }>
                                                      <ErrorField
                                                        formik={formik}
                                                        fieldName="dateOfBirth"
                                                      />
                                                      <DatePicker
                                                        selected={formik.values.dateOfBirth}
                                                        onChange={(date) =>
                                                          formik.setFieldValue("dateOfBirth", date)
                                                        }
                                                        onBlur={() =>
                                                          formik.setFieldTouched(
                                                            "dateOfBirth",
                                                            true
                                                          )
                                                        }
                                                        css={modalFormDatePicker}
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        dropdownMode="select"
                                                      />
                                                    </FormLabel>
                                                  </div>
                                                </div>
                                              </div>

                                              <div css={modalFormRow}>
                                                <label htmlFor="fileUpload" css={fileUploadLabel}>
                                                  <ErrorField
                                                    formik={formik}
                                                    fieldName="file_data"
                                                  />
                                                  <input
                                                    css={fileUploadInput}
                                                    type="file"
                                                    id="fileUpload"
                                                    name="fileUpload"
                                                    onChange={(e) => {
                                                      const isValid = validateFile(
                                                        e.target.files[0]
                                                      );
                                                      if (isValid) {
                                                        formik.setFieldValue(
                                                          "file_data",
                                                          e.target.files[0]
                                                        );
                                                      }
                                                    }}
                                                    onBlur={() =>
                                                      formik.setFieldTouched("file_data", true)
                                                    }
                                                  />
                                                </label>
                                              </div>

                                              <div css={modalFormRow}>
                                                <CustomButton
                                                  buttonText={
                                                    languageDictionaryStatus
                                                      ? languageDictionary["button_submit"]
                                                      : "Submit"
                                                  }
                                                  buttonWidth="100%"
                                                  marginTop="0"
                                                  onClick={() => updateShowModal(true)}
                                                  loading={docUploadLoading}
                                                />
                                              </div>
                                            </form>
                                          </div>
                                        </AriaModal>
                                      )}
                                    </div>
                                  </div>
                                )}
                              </>
                            );
                          })}

                          <div css={uploadButton}>
                            <CustomButton
                              buttonText="I WILL UPLOAD LATER"
                              buttonWidth="30%"
                              marginTop="0"
                              onClick={() => handleHomePage(true)}
                              loading={docUploadLoading}
                            />
                          </div>
                        </div>
                      </table>
                    </div>
                  </>
                )}
              </>
            )}
          </MainCompliance>
        </InnerContainer>
        <Footer />
      </Container>
    </>
  );
};

export default CreateUser;

import React from "react";
import t from "prop-types";
import styled from "@emotion/styled";

const Card = styled.div`
  background-color: #fff;
  box-shadow: -1px 0 3px 1px rgb(77 77 77 / 12%);
  border: none;
  text-overflow: ellipsis;
  overflow: hidden;

  :active,
  :hover {
    background-color: #b1d5fb;
    color: #fff;
  }
`;

const P = styled.p`
  color: ${(props) => props.theme.primaryColor};
  
  :hover {
    color: #fff;
  }
`;

const ServiceCard = ({ link, icon, text, onClick }) => {
  return (
    <div className="col-12 col-md-6 col-xl-4 mb-3">
      <Card onClick={onClick} className="card shadow">
        <a href={link} className="text-decoration-none">
          <div className="card-body">
            <div className="row align-items-center">
              <div className="col-4">
                <div className="small">
                  <img src={icon} alt="" className="img-fluid" />
                </div>
              </div>
              <div className="col-8">
                <div className="mx-auto">
                  <P className="mb-0 mt-2 text-truncate">{text}</P>
                </div>
              </div>
            </div>
          </div>
        </a>
      </Card>
    </div>
  );
};

ServiceCard.propTypes = {
  onClick: t.func,
};

export default ServiceCard;

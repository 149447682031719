// eslint-disable-next-line no-unused-vars
import React, { useState } from "react";
/** @jsx jsx */
/** @jsxRuntime classic */

import { jsx, css } from "@emotion/react";
import {
  containerStyle,
  ContainerWithCounter,
  StyledParagraph,
  StyledTextHeading,
  StyledInfoContainer,
} from "./BankTransferstyle";

const BankTransfer = ({ dataObject }) => {
  const {
    parameters: { "bank-details": bankFields },
  } = dataObject;
  return (
    <div css={containerStyle}>
      <div>
        <ContainerWithCounter counter="1">
          <StyledParagraph>
            Connect to your online banking or call your bank & transfer the sending amount to the
            bank account given below
          </StyledParagraph>
        </ContainerWithCounter>

        <ContainerWithCounter
          counter="2"
          css={css`
            margin-top: 3rem;
          `}>
          <StyledParagraph>Transfer the amount due using this information</StyledParagraph>

          <div
            css={css`
              background: #f5f9fb;
              border-radius: 6px;
              padding: 2.5rem 2rem;
              margin-top: 1rem;
            `}>
            {Object.entries(bankFields).map(([fieldKey, fieldValue]) => (
              <StyledInfoContainer key={fieldKey}>
                <StyledTextHeading>{fieldKey}</StyledTextHeading>
                <StyledParagraph>{fieldValue}</StyledParagraph>
              </StyledInfoContainer>
            ))}
          </div>
        </ContainerWithCounter>
      </div>
    </div>
  );
};

export default BankTransfer;

import { css } from '@emotion/react';

export const amountSection = (theme) => css`
  flex-basis: 48%;
  max-width: 48%;
  background: #f7fafc;
  /* border: 1px solid ${theme.primaryColor}; */
  border: 1px solid #1149831a;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  @media (max-width: 56.25rem) {
    max-width: 100%;
    margin-bottom: 1rem;
  }
`;

export const transInfoSection = (theme) => css`
  flex-basis: 48%;
  max-width: 48%;
  border: 1px solid #1149831a;
  border-radius: 10px;
  background: white;
`;

export const amountSectionInner = css`
  padding: 30px 40px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const amountSectionBottom = css`
  background: #0fcc98;
  padding: 30px 40px;
  display: flex;
  justify-content: space-between;
  /* margin-top: auto; */

  p {
    color: white;
    font-weight: 600;
    font-size: 16px;

    &:last-of-type {
      padding-left: 15px;
      font-weight: 500;
    }
  }
`;

export const amountSectionRow = (theme) => css`
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;

  &:last-of-type {
    margin-bottom: 0;
  }

  p {
    color: ${theme.primaryColor};
    font-weight: 600;
    font-size: 16px;

    &:last-of-type {
      padding-left: 15px;
      font-weight: 500;
    }
  }
`;

import * as BankAccount from 'Store/constants/bankAccount.constants';

const initialState = {
  list: [],
  createLoading: false,
  createAccountLoadingStatus: "",
  walletCurrencies: [],
  walletCurrenciesDropdown: [],
  walletCurrenciesLoading: false
};

const formatDropDown = payload => {
  return payload.map(item => ({
    value: item,
    label: item
  }));
};

const bankAccountReducer = (state = initialState, action) => {
  switch (action.type) {
    case BankAccount.CREATE_BANK_ACCOUNT_REQUEST:
      return {
        ...state,
        loading: true,
        createAccountLoadingStatus: "FETCHING",
      };

    case BankAccount.CREATE_BANK_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        createAccountLoadingStatus: "SUCCESS",
      };

    case BankAccount.CREATE_BANK_ACCOUNT_FAILURE:
      return {
        ...state,
        loading: false,
        createAccountLoadingStatus: "ERROR",
      };

    case BankAccount.FETCH_CURRENCIES_REQUEST:
      return {
        ...state,
        walletCurrenciesLoading: true
      };

    case BankAccount.FETCH_CURRENCIES_SUCCESS:
      return {
        ...state,
        walletCurrenciesLoading: false,
        walletCurrencies: action.payload,
        walletCurrenciesDropdown: formatDropDown(action.payload)
      };

    case BankAccount.FETCH_CURRENCIES_FAILURE:
      return {
        ...state,
        walletCurrenciesLoading: false
      };

    default:
      return state;
  }
};

export default bankAccountReducer;

import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import Footer from "Components/Footer";
import NavBar from "Components/Navbar";
import { NextButton, CardBorder, SanctionCard } from "./style";
import pdfGeneratorPayout from "../../../../Utils/Helpers/pdfGeneratorPayout";

const Payout = () => {
  const history = useHistory();
  let payoutData;
  if (typeof history.location.state === "undefined") history.push("/cashier/payout-search");
  else payoutData = history.location.state['payoutData']['payoutData'];

  useEffect(() => {
    if (typeof history.location.state === "undefined") history.push("/cashier/payout-search");
  }, []);

  const getReceipt = () => {
    console.log(payoutData);
    pdfGeneratorPayout(payoutData);

  }

  return (
    <>
      <Helmet>
        <title>Payout</title>
      </Helmet>
      <NavBar />
      <section>
        <div className="container">
          <div className="mt-5 mb-5">
            <h2 className="text-blue font-weight-bold">Payout</h2>
          </div>
          <hr className="mb-3" />
          <div className="row mb-5">
            <div className="col-lg-12">
              <CardBorder className="card mb-3 mb-lg-5 p-2 p-xl-3">
                <div className="row mb-5">
                  <div className="col-12 col-md-3 mb-3">
                    <NextButton onClick={getReceipt} className="btn btn-lg btn-green btn-block text-uppercase text-white">
                      Download receipt pdf
                    </NextButton>
                  </div>
                  <div className="col-12 col-md-4 mb-3">
                    <NextButton className="btn btn-lg btn-blue btn-block text-uppercase text-white">
                      Download payout receipt pdf
                    </NextButton>
                  </div>
                </div>

                {/*<Table className="table-responsive-xl border mb-5">
                  <thead>
                    <tr>
                      <TH scope="col">date</TH>
                      <TH scope="col">pcn</TH>
                      <TH scope="col">total amount sent</TH>
                      <TH scope="col">sender</TH>
                      <TH scope="col">beneficiary name</TH>
                      <TH scope="col">beneficiary code</TH>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <TD>
                        <Span>22/11/2012</Span>
                      </TD>
                      <TD>
                        <Span>CATH65341226</Span>
                      </TD>
                      <TD>
                        <Span>sanction list</Span>
                      </TD>
                      <TD>
                        <Span>On transaction create</Span>
                      </TD>
                      <TD>
                        <Span>Pass</Span>
                      </TD>
                      <TD>
                        <Span>Reciever</Span>
                      </TD>
                    </tr>
                  </tbody>
                </Table>*/}

                <div className="mb-5" id="transaction">
                  <h5 className="text-blue">Transaction Details</h5>
                  <CardBorder className="card">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-12 col-lg-12">
                          <div className="row">
                            <div className="col-3">
                              <p className="text-blue text-uppercase">Date</p>
                              <span className="h6">-</span>
                            </div>
                            <div className="col-3">
                              <p className="text-blue text-uppercase">PCN</p>
                              <span className="h6">{payoutData['objectReference']}</span>
                            </div>
                            <div className="col-3">
                              <p className="text-blue text-uppercase">Sending Amount</p>
                              <span
                                className="h6">{payoutData.sendingCurrency} {payoutData.sendingAmount}</span>
                            </div>
                            <div className="col-3">
                              <p className="text-blue text-uppercase">Receiving Amount</p>
                              <span
                                className="h6">{payoutData["receiverCurrency"]} {payoutData.receivingAmount}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </CardBorder>
                </div>

                <div className="mb-5" id="sender">
                  <h5 className="text-blue">Sender details</h5>
                  <CardBorder className="card">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-12 col-lg-12">
                          <div className="row">
                            <div className="col-4">
                              <p className="text-blue text-uppercase">Sender code</p>
                              <span className="h6">-</span>
                            </div>
                            <div className="col-4">
                              <p className="text-blue text-uppercase">Sender name</p>
                              <span
                                className="h6">{payoutData["senderFirstName"]} {payoutData["senderLastName"]}</span>
                            </div>
                            <div className="col-4">
                              <p className="text-blue text-uppercase">Sending Amount</p>
                              <span
                                className="h6">{payoutData.sendingCurrency} {payoutData.sendingAmount}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </CardBorder>
                </div>

                <div className="mb-5" id="receiver">
                  <h5 className="text-blue">Receiver details</h5>
                  <CardBorder className="card">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-12 col-lg-12">
                          <div className="row">
                            <div className="col-4">
                              <p className="text-blue text-uppercase">Beneficiary code</p>
                              <span className="h6">-</span>
                            </div>
                            <div className="col-4">
                              <p className="text-blue text-uppercase">Name</p>
                              <span
                                className="h6">{payoutData["receiverFirstName"]} {payoutData["receiverLastName"]}</span>
                            </div>
                            <div className="col-4">
                              <p className="text-blue text-uppercase">Payout Amount</p>
                              <span
                                className="h6">{payoutData["receiverCurrency"]} {payoutData.receivingAmount}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </CardBorder>
                </div>

                <div className="mb-5" id="search">
                  <h5 className="text-blue">Sanction Search Report</h5>
                  <SanctionCard className="card">
                    <div className="text-center" />
                  </SanctionCard>
                </div>

                <div className="mb-5" id="sending">
                  <h5 className="text-blue">Pay Centre</h5>
                  <CardBorder className="card">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-6 col-md-6">
                          <p className="text-blue text-uppercase">Agent/branch name</p>
                          <span className="h6">-</span>
                        </div>
                        <div className="col-6 col-md-6">
                          <p className="text-blue text-uppercase">Cashier Code</p>
                          <span className="h6">-</span>
                        </div>
                      </div>
                    </div>
                  </CardBorder>
                </div>

              </CardBorder>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Payout;

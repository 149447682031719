// eslint-disable-next-line no-unused-vars
import React from "react";
/** @jsx jsx */
/** @jsxRuntime classic */

import { jsx, css } from "@emotion/react";

const SubHeadingText = ({ text }) => {
  return (
    <p
      css={css`
        font-size: 18px;
        color: #90a8be;
        font-weight: 400;
        margin-bottom: 0.2em !important;
      `}>
      {text}
    </p>
  );
};

export default SubHeadingText;

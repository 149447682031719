export const accountNameOptions = [
  { value: 'Current Account', label: 'Current Account' },
  { value: 'Business Account', label: 'Business Account' },
  { value: 'Savings Account', label: 'Savings Account' },
];

// date, account name, account type
export const sortOptions = [
  { value: 'currency', label: 'Currency' },
  { value: 'all', label: 'All' },
];

export const filterAccountStatement = [
  {
    value: ['pcn', 'beneficiaryName', 'paymentReference'],
    label: 'All',
  },
  { value: ['pcn'], label: 'Reference' },
  { value: ['beneficiaryName'], label: 'Beneficiary' },
  { value: ['paymentReference'], label: 'Payment Reference' },
];

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import * as BillActions from "Store/actions/bill.action";
import { Helmet } from "react-helmet";
import Footer from "Components/Footer";
import NavBar from "Components/Navbar";
import Notes from "Components/Notes";
import ServiceCard from "Components/ServiceCard";
import Spinner from "Components/Spinner";
import Select from "react-select";
import DropDownComponent from "Components/DropDownComponent";
import customStyles from "Assets/styles/dropdown/customStyles";
import downArrow from "Assets/svg/blue-drop-down-arrow.svg";
import charging from "Assets/svg/charging.svg";
import tick from "Assets/svg/tick2.svg";
import { Input, Card } from "./style";
import cookieHelper from "Utils/Helpers/cookieHelper";
import config from "Utils/Helpers/config";
import { fetchDynamicFields } from "../../../../Store/actions/dynamicFields.action";
import * as Loading from "../../../../Store/constants/loadingState.constants";
import DynamicFields from "../../../../Components/DynamicFields";
import Styles from "../../../Auth/Login/index.module.scss";
import Loader from "../../../../Components/loader/Loader";
import { useFormik } from "formik";
import BackArrow from "Components/BackArrow";

const BillPaymentDetails = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const [selected, setSelected] = useState({
    product: null,
    service: null,
    provider: null
  });

  let showSpinner, allDynamicFields = [];
  let dynamicFormType = "REGISTERED_CUSTOMER_SEND_BILL_PAYMENT";
  const countryOfConfig = cookieHelper.get(config.PARAMS_COUNTRY);
  const [filteredServices, setFilteredServices] = useState(null);
  const [verifyBtn, setVerifyBtn] = useState(false);
  const [dynamicFieldValues, setDynamicFieldValues] = useState({});

  const formik = useFormik({
    initialValues: {},
    onSubmit: (values) => {
      const payloadData = {
        ...values
      };
    }
  });

  const fetchOperatorsByServiceId = (serviceCode) => {
    dispatch(BillActions.getOperatorByService(countryOfConfig, serviceCode));
  };

  const fetchProductsByOperatorId = (serviceCode, operatorCode) => {
    dispatch(BillActions.getProductByOperator(countryOfConfig, serviceCode, operatorCode));
    if (operators["account_verification_is_required"] === "false") {
      setVerifyBtn(true);
    }
  };

  const {
    bill: {
      services,
      servicesLoadingStatus,
      products,
      productsLoadingStatus,
      operators,
      operatorsLoadingStatus,
      quoteData
    },
    domainBrand,
    dynamicFields
  } = useSelector(({ billReducer, domainBrandReducer, dynamicFieldsReducer }) => ({
    bill: billReducer,
    domainBrand: domainBrandReducer,
    dynamicFields: dynamicFieldsReducer
  }));

  showSpinner = domainBrand.loading === Loading.FETCHING || dynamicFields.loading === Loading.FETCHING;
  const languageDictionary = domainBrand.domainBrand["languageDictionary"];
  const languageDictionaryStatus = !(
    typeof languageDictionary === "undefined" || languageDictionary === null
  );

  dynamicFields.loading === Loading.SUCCESS
    ? (allDynamicFields = dynamicFields[dynamicFormType]["dynamicFields"])
    : (allDynamicFields = []);
  const renderDynamicFields = allDynamicFields.map((eachField, index) => {
    return <DynamicFields key={index} eachField={eachField} formik={formik} />;
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(fetchDynamicFields(dynamicFormType));
    dispatch(BillActions.getBillServiceByCountry(countryOfConfig));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  const handleSearch = (event) => {
    const { value } = event.target;
    if (!value) {
      setFilteredServices(null);
      return;
    }
    const searchTerm = value.toLowerCase();
    const filtered = services.filter((service) => {
      const serviceName = service.service.toLowerCase();
      return serviceName.includes(searchTerm);
    });
    setFilteredServices(filtered);
  };

  useEffect(() => {
    setDynamicFieldValues(formik.values);
  }, [formik.values]);

  const productsLoading = productsLoadingStatus === "FETCHING";
  const handleBillPayment = () => {
    let { product } = selected;
    const payload = {
      amount: product.original.product_value,
      currency: product.product_currency,
      productId: product.original.product_id,
      serviceId: product.original.service_id,
      operatorId: product.original.operator_id,
      ...dynamicFieldValues
    };
    dispatch(BillActions.initiateBillPayment(payload));
    history.push("/billpayment/choose-payment-method");
  };

  return (
    <>
      <Helmet>
        <title>
          {languageDictionaryStatus ? languageDictionary["WO_BILL_PAYMEN_-1233806888"] : "Bill Payment"}
        </title>
      </Helmet>
      <NavBar />
      {showSpinner ? (
        <div className={Styles.modSpinner}>
          <Loader />
        </div>
      ) : (
        <section>
          <div className="container">
            <div className="mt-5 mb-5">
              <div className="d-flex">
                <BackArrow />
                <h2 className="text-blue mt-4">
                  {languageDictionaryStatus ? languageDictionary["WO_BILL_PAYMEN_-1233806888"] : "Bill Payment"}
                </h2>
              </div>
              <div className="row">
                <div className="col-12 col-xl-12">
                  <div className="card mt-5 p-md-5">
                    <div className="card-body mb-3 p-md-3">
                      <div className="row mb-2">
                        <div className="col-12 col-lg-7">
                          <div className="mb-3">
                            <p className="text-blue">
                              {languageDictionaryStatus ? languageDictionary["WO_SELECT_SERVIC_1697546694"] : "Select Service"}
                            </p>
                            <Input
                              type="text"
                              className="form-control"
                              placeholder="Search service..."
                              onChange={handleSearch}
                            />
                          </div>
                        </div>
                      </div>

                      {servicesLoadingStatus === "FETCHING" ? (
                        <Spinner containerHeight="30vh" />
                      ) : (
                        <div className="row mb-2">
                          <div className="col-12">
                            <div className="mt-5 mb-5">
                              <p className="text-blue">
                                {languageDictionaryStatus ? languageDictionary["WO_PICK_A_SERVIC_61150277"] : "Pick a service"}
                              </p>

                              {selected.service !== null && (
                                <div className="row mb-3">
                                  <div className="col-12 col-lg-9 col-xl-7">
                                    <Card
                                      className="card"
                                      onClick={() => {
                                        setSelected({ ...selected, service: null });
                                      }}>
                                      <div className="card-body">
                                        <div className="row align-items-center">
                                          <div className="col">{`${selected.service?.service} is selected`}</div>
                                          <div className="col-auto">
                                            <img src={tick} alt="tick" />
                                          </div>
                                        </div>
                                      </div>
                                    </Card>
                                  </div>
                                </div>
                              )}

                              {selected.service === null ? (
                                <div className="row">
                                  {(filteredServices || services).map((service) => (
                                    <ServiceCard
                                      key={service.service_id}
                                      icon={charging}
                                      text={service.service}
                                      link={`#${service.service}`}
                                      onClick={() => {
                                        setSelected({ ...selected, service });
                                        fetchOperatorsByServiceId(service.service_id);
                                      }}
                                    />
                                  ))}
                                </div>
                              ) : null}
                            </div>

                            <div className="row mb-2">
                              <div className="col-12">
                                <div>
                                  {operatorsLoadingStatus === "FETCHING" ? (
                                    <Spinner containerHeight="30vh" />
                                  ) : operators.length === 0 ? (
                                    <p>Please pick a service to see operators</p>
                                  ) : (
                                    <>
                                      <p className="text-blue">
                                        {languageDictionaryStatus
                                          ? languageDictionary["l_select_operator"]
                                          : "Select Operator"}
                                      </p>
                                      {selected.provider !== null && (
                                        <div className="row mb-3">
                                          <div className="col-lg-9 col-xl-7">
                                            <Card
                                              className="card"
                                              onClick={() => {
                                                setSelected({ ...selected, provider: null });
                                              }}>
                                              <div className="card-body">
                                                <div className="row align-items-center">
                                                  <div className="col">{`${selected.provider?.provider} is selected`}</div>
                                                  <div className="col-auto">
                                                    <img src={tick} alt="tick" />
                                                  </div>
                                                </div>
                                              </div>
                                            </Card>
                                          </div>
                                        </div>
                                      )}

                                      {selected.provider === null ? (
                                        <div className="row">
                                          {operators.map((provider) => (
                                            <ServiceCard
                                              key={provider["providerId"]}
                                              icon={`https://cath.themoneytransferapplication.com/${provider["logo"]}`}
                                              text={provider.provider}
                                              link={`#${provider.provider}`}
                                              onClick={() => {
                                                setSelected({ ...selected, provider });
                                                fetchProductsByOperatorId(
                                                  provider.serviceId,
                                                  provider["providerId"]
                                                );
                                              }}
                                            />
                                          ))}
                                        </div>
                                      ) : null}

                                      {/* Account Verification */}
                                      <div className="row mb-2">
                                        <div className="col-12 col-lg-9 col-xl-7">
                                          <div className="mt-5 mb-5">
                                            <p className="text-blue text-capitalize">
                                              account reference number
                                            </p>

                                            <Input
                                              type="text"
                                              className="form-control"
                                              placeholder="Enter number here..."
                                            />
                                            {verifyBtn ? (
                                              <div className="mt-3 d-flex justify-content-end mb-2">
                                                <button className="btn btn-blue text-white">
                                                  {languageDictionaryStatus
                                                    ? languageDictionary["l_verify"]
                                                    : "Verify"}
                                                </button>
                                              </div>
                                            ) : null}
                                          </div>

                                          {/* ----- Select Product ----- */}
                                          <div className="mb-5">
                                            <p className="text-blue">
                                              {languageDictionaryStatus
                                                ? languageDictionary["WO_SELECT_PRODUC_-1243389070"]
                                                : "Select Product"}
                                            </p>
                                            <Select
                                              options={(products || []).map((e) => ({
                                                label: e.product_name,
                                                value: e.product_name,
                                                original: e
                                              }))}
                                              styles={customStyles}
                                              isClearable={false}
                                              isSearchable={true}
                                              onChange={(value) => {
                                                const { original } = value;
                                                dispatch(
                                                  BillActions.getQuoteForBillPayment({
                                                    amount: original.product_value,
                                                    currency: original.product_currency
                                                  })
                                                );
                                                setSelected({ ...selected, product: value });
                                              }}
                                              customTheme={""}
                                              isLoading={productsLoading}
                                              placeholder="Select Product"
                                              components={{
                                                IndicatorSeparator: null,
                                                DropdownIndicator: () => (
                                                  <DropDownComponent iconSource={downArrow} />
                                                )
                                              }}
                                            />
                                          </div>
                                          {/* ------ Product description ----- */}
                                          <div className="mb-5">
                                            <p className="text-blue text-capitalize">
                                              {languageDictionaryStatus
                                                ? languageDictionary[
                                                    "WO_PRODUCT_DESCRIPTIO_-40082400"
                                                  ]
                                                : "product description"}
                                            </p>

                                            <Input
                                              type="text"
                                              className="form-control"
                                              value={
                                                products.find(
                                                  (e) => e.product_name === selected.product?.value
                                                )?.product_short_desc || ""
                                              }
                                              readOnly
                                            />
                                          </div>
                                          {renderDynamicFields}
                                          {/* ------ Message/Remark ----- */}
                                          <div className="mb-5">
                                            <p className="text-blue">
                                              {languageDictionaryStatus
                                                ? languageDictionary["WO_REMARK_1233903749"]
                                                : "Remark"}
                                            </p>

                                            <Input type="text" className="form-control" />
                                          </div>
                                          {/* ------ Note Section */}
                                          <Notes
                                            exchangeRate={`1.00 GBP = ${
                                              quoteData.fxRate || "1.00"
                                            }${quoteData.receivingCurrency || ""}`}
                                            fee={quoteData["payment_method_fee"] || "0.00"}
                                            tax={quoteData.tax || "0.00"}
                                            totalAmount={`${quoteData["total_amount"] || "0.00"}${
                                              quoteData.sendingCurrency || ""
                                            }`}
                                          />
                                          {/* ----- Proceed button ------ */}
                                          <div className="mt-5 col-12 col-lg-6">
                                            <button
                                              className="btn btn-lg btn-blue btn-block text-uppercase text-white"
                                              disabled={!quoteData["total_amount"]}
                                              onClick={handleBillPayment}>
                                              Proceed
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      <Footer />
    </>
  );
};

export default BillPaymentDetails;

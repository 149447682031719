import React from 'react';

import './Loader.css';

function Loader() {
    const root = document.documentElement;
    const bgColor = root.style.getPropertyValue('--background-color') || '#0055AD';
    const primaryColor = root.style.getPropertyValue('--navbar-color') || '#0055AD';
    const secondaryColor = root.style.getPropertyValue('--text-color') || '#404041';

    return (
        <>
            <div className="pre-loader">
                <svg className="loader" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 340 340">
                    <circle cx="170" cy="170" r="160" stroke={primaryColor}/>
                    <circle cx="170" cy="170" r="135" stroke={secondaryColor}/>
                    <circle cx="170" cy="170" r="110" stroke={bgColor}/>
                    <circle cx="170" cy="170" r="85" stroke="#E2007C"/>
                </svg>

            </div>
        </>
    );
}

export default Loader;

import { css } from '@emotion/react';

export const pdBody = css`
  flex-basis: 50%;
  padding-left: 2.5%;

  @media ( max-width: 56.25rem ){
    margin-top: 2rem;
    font-size: 1.5rem;
    border-top : 1px solid  #90a8be;
  }
`;

export const headingContainer = css`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;

  h3 {
    font-size: 16px;
    font-weight: 500;
    color: #368fea;
    line-height: 1.6;

    @media ( max-width: 56.25rem ){
      font-size: 1.5rem;
    }
  }

  p {
    color: #0fcc98;
    font-size: 20px;
    font-weight: 500;
    text-transform: uppercase;
    line-height: 1.7;
  }
`;

export const headingLeft = css`
  margin-right: 50px;
  min-width: 250px;
`;

export const confirmButton = css`
  display: flex;
  justify-content: flex-end;
  text-transform: uppercase;

  @media (max-width: 61.94rem) {
    justify-content: flex-start;
  }
`;

import Axios from "./Axios.service";

export const fetchCountryList = async () => {
  return Axios({
    method: "GET",
    url:
      "/web-services/api/v6/services/quote/destination-country-by-delivery-method/GBR/AIRTIME_TOPUP",
    headers: {
      platform: "fincode",
      UUID: 200,
    },
  });
};

export const fetchPreTopup = async (phoneNumber, destinationCountryIso3) => {
  return Axios({
    method: "POST",
    url: "/web-services/api/v6/services/transactionmanagement/pre-topup",
    headers: {
      platform: "fincode",
      UUID: 200,
    },
    data: {
      beneficiaryPhone: phoneNumber,
      destinationCountryIso3: destinationCountryIso3,
    },
  });
};

export const fetchQuoteForAirtimeTopup = async ({ amount, currency }) => {
  const requestPayload = {
    paymentType: "ONLINE_CARD_PAYMENT",
    chargeCategory: "MONEYTRANSFER",
    principalAmount: amount,
    principalAmountCurrency: "GBP",
    deriveAmount: amount,
    deriveAmountCurrency: currency,
    transactionType: "AIRTIME_TOPUP",
    inverseCalculation: true,
    conversion: true,
  };

  return Axios({
    data: requestPayload,
    method: "POST",
    url: "web-services/api/v6/services/quote/callQuote",
    headers: {
      platform: "fincode",
      UUID: 200,
    },
  });
};

export const initiateAirtimePayment = async ({ amount, phoneNumber, destinationCountryIso3 }) => {
  const requestPayload = {
    payerCurrency: "GBP",
    productAmount: amount,
    beneficiaryPhone: phoneNumber,
    destinationCountryIso3: destinationCountryIso3,
  };
  return Axios({
    method: "POST",
    url: "/web-services/api/v6/services/transactionmanagement/orderairtime",
    headers: {
      platform: "fincode",
      UUID: 200,
    },
    data: requestPayload,
  });
};

export const getAirtimePaymentMethods = async ({
  payableType,
  amount,
  pcn,
  currency,
  countryIsoCode3,
}) => {
  const requestPayload = {
    countryIsoCode3,
    currency,
    pcn,
    amount,
    payAbleType: payableType,
    transactionType: "AIRTIME_TOPUP",
    appId: "1",
  };

  return Axios({
    data: requestPayload,
    method: "POST",
    url: "/web-services/api/v6/services/paymentmanagement/supported-payment-methods",
    headers: {
      platform: "fincode",
      UUID: 200,
    },
  });
};

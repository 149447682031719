// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from "react";
/** @jsx jsx */
/** @jsxRuntime classic */

import { jsx } from "@emotion/react";
import { Helmet } from "react-helmet";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { InnerContainer, Main } from "Components/Styled";
import HeadingText, { H2Text } from "Components/HeadingText";
import Spinner from "Components/Spinner";
import { DownloadButton } from "./styled";
import { ReactComponent as DownloadIcon } from "Assets/svg/download.svg";
import { sectionOne, mainWrapper, sectionTwo } from "./styleSheet";
import AmountSection from "./atom/AmountSection";
import TransactionInfoSection from "./atom/TransactionInfoSection";
import IdentityTable from "./atom/IdentityTable";
import PaymentTable from "./atom/PaymentAttemptsTable";
import BeneficiaryDetails from "./atom/BeneficiaryDetails";
import { getTransactionDetails } from "Store/actions/transfer.action";
import * as Loading from "Store/constants/loadingState.constants";
import Button from "react-bootstrap/Button";
import pdfGeneratorTransDetails from "Utils/Helpers/pdfGeneratorTransDetails";

const TransactionDetails = () => {
  const location = useLocation(), history = useHistory();
  const dispatch = useDispatch();
  const [PCN, updatePCN] = useState("");
  let domainBrandStatus;

  const { transactionDetailsStatus, transactionDetails, domainBrand } = useSelector(
    ({ transferReducer, domainBrandReducer }) => ({
      transactionDetails: transferReducer.transactionDetails,
      transactionDetailsStatus: transferReducer.transactionDetailsStatus,
      domainBrand: domainBrandReducer
    })
  );

  const languageDictionary = domainBrand.domainBrand["languageDictionary"];
  const languageDictionaryStatus = !(typeof languageDictionary === "undefined" || languageDictionary === null);
  domainBrandStatus = domainBrand.loading === Loading.SUCCESS;
  const loading = transactionDetailsStatus === Loading.FETCHING;
  const clickAction = () => {
    history.push("/account/balance");
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  useEffect(() => {
    const pcn = location.search.split("?pcn=")[1];
    const payableType = location.search.split("?payabletype=")[1];
    updatePCN(pcn);
    dispatch(getTransactionDetails({ pcn, payableType }));
  }, [dispatch, location.search]);

  const handleClick = () => {
    if (!transactionDetails || Object.keys(transactionDetails).length === 0) {
      return;
    }
    pdfGeneratorTransDetails({ data: { transactionDetails } }, domainBrand.domainBrand['logo']);
  };

  if (transactionDetailsStatus === Loading.ERROR) {
    return (
      <InnerContainer>
        <Helmet>
          <title>
            {languageDictionaryStatus ? languageDictionary["l_transaction_details"] : "Transaction details"}
          </title>
        </Helmet>
        <Main css={mainWrapper}>
          <HeadingText
            text={languageDictionaryStatus ? languageDictionary["l_transaction_details"] : "Transaction details"}
          />
          <div className="mt-5 mb-5">
            There was an issue with fetching transactions details for this PCN, {PCN}. Contact your
            adminstrator manager.
          </div>
          <section css={sectionTwo}>
            <Button onClick={clickAction}>Go to Account History</Button>
          </section>
        </Main>
      </InnerContainer>
    );
  }

  return (
    <InnerContainer>
      <Helmet>
        <title>
          {languageDictionaryStatus ? languageDictionary["para_transaction_details"] : "Transaction Details"}{" "}
          &mdash; {domainBrandStatus ? domainBrand["domainBrand"]["companyName"] : "MultiView MTA"}
        </title>
      </Helmet>
      {loading ? <Spinner /> :
        <Main css={mainWrapper}>
          <div className="d-flex">
            <HeadingText
              text={languageDictionaryStatus ? languageDictionary["para_transaction_details"] : "Transaction Details"}
            />
          </div>

          <section css={sectionOne}>
            <AmountSection data={transactionDetails} />
            <TransactionInfoSection data={transactionDetails} />
          </section>

          <H2Text text={languageDictionaryStatus ? languageDictionary["l_identification"] : "Identification"} />

          <IdentityTable data={transactionDetails} />

          <H2Text text={languageDictionaryStatus ? languageDictionary["l_beneficiary_details"] : "Beneficiary Details"} />

          <BeneficiaryDetails data={transactionDetails} />

          <H2Text text={languageDictionaryStatus ? languageDictionary["WO_PAYMENT_ATTEMPT_-1251576265"] : "Payment Attempts"} />

          <PaymentTable data={transactionDetails} />

          <section css={sectionTwo}>
            <Button onClick={clickAction}>Go to Account History </Button>
            <DownloadButton onClick={handleClick} default>
              <DownloadIcon />
            </DownloadButton>
          </section>
        </Main>
      }
    </InnerContainer>
  );
};

export default TransactionDetails;

import { css } from '@emotion/react';

export const fullWidthContainer = () => css`
  width: 100%;
  border: 1px solid #1149831a;
  border-radius: 10px;
  background: white;
  padding: 40px 40px;

  @media ( max-width: 56.25rem ) {
    width: 100%;
  }
`;

export const container = (theme) => css`
  width: 48.5%;
  border: 1px solid #1149831a;
  border-radius: 10px;
  background: white;
  padding: 40px 40px;

  @media ( max-width: 56.25rem ) {
    width: 100%;
  }
`;

export const transferContainer = (theme) => css`
  flex-basis: 47%;
  max-width: 47%;
  border-bottom: 1.5px solid #1149831a;
`;

export const emailColumn = (theme) => css`
  flex-basis: 47%;
  max-width: 47%;
  border-bottom: 1.5px solid #1149831a;
  height: 100%;
`;

export const transferColumnHeading = (theme) => css`
  color: ${theme.primaryColor};
  font-weight: 600;
  font-size: 13px;
  padding-bottom: 20px;
`;

export const noBorderColumn = css`
  flex-basis: 47%;
  max-width: 47%;
`;

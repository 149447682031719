import React from 'react';
import styled from '@emotion/styled';
import { ReactComponent as CheckIcon } from 'Assets/svg/checkLayer.svg';

const Label = styled.h3`
  font-size: 16px;
  font-weight: 500;
  color: ${(props) => props.theme.primaryColor};
  line-height: 1.6;
`;

const FieldBox = styled.div`
  display: flex;
  margin-top: 12px;
  justify-content: space-between;
  font-size: 15px;
  font-weight: 500;
  color: ${(props) => props.theme.primaryColor};
  background: #0fcc9814;
  border: 1px solid #0fcc98;
  border-radius: 5px;
  height: 50px;
  align-items: center;
  padding: 0 20px;
`;

const CompContainer = styled.div`
  width: 100%;
`;

const CheckFieldDisplay = ({ label, fieldValue }) => {
  return (
    <CompContainer>
      <Label>{label}</Label>
      <FieldBox>
        <p>{fieldValue}</p>
        <CheckIcon width={18} height={13} />
      </FieldBox>
    </CompContainer>
  );
};

export default CheckFieldDisplay;

import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const mainBody = css`
  border: 1px solid #e3edf3;
  border-radius: 10px;
  background: #ffffff;
  padding: 30px 40px;
  /* width: 48.5%; */
  margin-top: 30px;
  margin-bottom: 50px;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const selectWrapper = css`
  margin-bottom: 30px;
`;

export const selectDropdownContainer = css`
  max-width: 47.5%;
  flex-basis: 47.5%;
`;

export const buttonWrapper = css`
  display: flex;
  justify-content: flex-end;
`;

export const actionWrapper = css`
  display: flex;
  justify-content: flex-start;
`;

export const divider = css`
  margin-top: 10px;
  border-top: 6px solid blue;
  border-radius: 5px;
`;

export const containerStyle = css`
  max-width: 1200px;
  margin: 0 auto;

  @media screen and (min-width: 900px) and (max-width: 1250px) {
    padding: 0 5%;
  }
`;

export const uploadContainerHeader = css`
  border-bottom: 2px solid #90a8be6b;
  padding-bottom: 25px;
  margin-bottom: 15px;
  display: grid;
  grid-template-columns: auto auto;
  padding: 10px;
  padding-bottom: 20px;
`;

export const uploadContainerDiv = css`
  border-bottom: 2px solid #90a8be6b;
  padding-bottom: 25px;
  margin-bottom: 15px;
  display: grid;
  grid-template-columns: auto auto;
  padding: 10px;
`;

export const leftCol = css`
  border-right: 2px solid #90a8be6b;
  padding-bottom: 25px;
  margin-bottom: 15px;
  display: grid;
  padding: 10px;
  padding-left: 0px;
  color: #14a3de;
  width: 200px;
`;

export const rightCol = css`
  padding-bottom: 25px;
  margin-bottom: 15px;
  display: grid;
  grid-template-columns: auto auto;
  padding: 10px;
  padding-left: 30px;
`;

export const emailText = css`
  color: #14a3de;
`;

export const textContainer = css`
  text-align: center;
  padding: 20px;
`;

export const uploadLabel = (theme) => css`
  display: flex;
  flex-direction: column;
  color: ${theme.primaryColor};
  font-size: 16px;
  font-weight: 500;
`;

export const attachButtonContainer = css`
  display: flex;
  justify-content: flex-end;
`;

export const modalContainer = css`
  background: white;
  border: 1px solid #1149835b;
  min-height: 70vh;
  max-height: 80vh;
  border-radius: 20px;
  display: flex;
  padding: 25px 30px;
  width: 50vw;
  flex-direction: column;
  overflow-y: auto;
`;

export const modalContainerHeading = css`
  font-size: 20px;
  color: #90a8be;
  font-weight: 400;
  padding-bottom: 10px;
  margin-bottom: 15px;
  border-bottom: 1px solid #1149835b;
`;

export const fileUploadInput = css`
  overflow: visible;
  width: 100%;
  border: 0;
  background: #e3edf3;
  border-radius: 5px;
  padding: 15px 20px;
  font-size: 16px;
  font-weight: 400;
  color: #14a3de;
  font-weight: 300;
  margin-top: 12px;
`;
export const fileUploadLabel = css`
  width: 100%;
`;

export const modalFormColumn = css`
  flex-basis: 47.5%;
  max-width: 47.5%;
`;

export const modalFormDatePicker = css`
  margin-top: 12px;
  background: #e3edf3;
  border: 0;
  width: 100%;
  padding: 15px 10px;
  border-radius: 4px;
  height: 48px;
  color: var(--primary-color);
  font-size: 15px;
  font-weight: 300;
`;

export const tableStyle = (theme) => {
  return css`
    overflow-x: auto;
    margin-bottom: 30px;

    table {
      border-collapse: separate;
      border-spacing: 0 15px;
      width: 100%;
      border: 1px solid #e2e8ec;
      border-radius: 5px;
      background: #ffffff;
    }

    th {
      color: #90a8be;
      font-weight: 600;
      text-transform: uppercase;
      font-size: 12px;
      padding-top: 5px;
      padding-bottom: 5px;
      padding-left: 10px;
      padding-right: 10px;
    }

    td {
      text-align: center;
    }

    tbody {
      td {
        background: #e3edf3;

        span {
          display: block;
          margin-top: 24.5px;
          margin-bottom: 24.5px;
          border-right: 2px solid #90a8be47;
          font-size: 14px;
          color: ${theme.primaryColor};
          font-weight: 500;
          padding-left: 10px;
          padding-right: 10px;
        }

        &:nth-of-type(1) {
          span {
            font-weight: 400;
          }
        }

        &:last-of-type {
          span {
            /* color: #90a8be; */
          }
        }

        &:last-of-type {
          span {
            border-right: none;
          }
        }
      }
    }
  `;
};

export const modalFormContainer = css``;

export const modalFormRow = css`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  align-items: flex-end;
`;

export const uploadButton = css`
  text-align: right;
`;

export const noInvoiceText = css`
  color: #90a8be;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 30px;
`;

export const uploadContainer = css`
  margin-top: 30px;
`;

export const profileBoxContainer = css`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  flex: 1;
  position: relative;
  overflow: hidden;
  width: 100%;
  border-radius: 5px;
`;

export const profilePicture = css`
  margin-top: -80px;
  height: 300px;
  width: 300px;
  border-radius: 50%;
  display: flex;
  border: 1px solid #0fcc9838;
`;

export const profilePictureInner = css`
  height: 260px;
  width: 260px;
  border-radius: 50%;
  display: flex;
  border: 1px solid #3796f6;
  margin: auto;
`;

export const profilePictureMain = css`
  height: 230px;
  width: 230px;
  border-radius: 50%;
  display: flex;
  margin: auto;
  overflow: hidden;
`;

export const profileBox = css`
  border-radius: 5px;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

export const profileBoxUpload = css`
  background: #e3edf38f;
  margin-top: 30px;
  height: 200px;
  width: 100%;
  display: flex;
  place-items: center;
`;

export const profileBoxUploadContainer = css`
  border-radius: 10px;
  width: 50%;
  height: 100px;
  margin: auto;

  @media (max-width: 56.25rem) {
    width: 100%;
    padding: 1rem;
    margin: 0;
  }
`;

export const profileBoxUploadInner = css`
  border: 2px solid #14a3de;
  border-radius: 10px;
  width: 100%;
  // height: 50px;
  margin: auto;

  @media (max-width: 56.25rem) {
    width: 100%;
    margin: 0;
  }
`;

export const profileBoxUploadBtn = css`
  border: 2px solid #14a3de;
  border-radius: 10px;
  width: 100%;
  // height: 50px;
  margin: auto;
`;

export const headingTextStyle = css`
  margin-bottom: 30px;
`;

export const customfileInput = css`
  display: inline-block;
`;

export const inputStyle = (theme) => css`
  // margin-top: 12px;
  border: 0;
  background: linear-gradient(top, #f9f9f9, #e3e3e3);
  border-radius: 5px;
  padding: 10px;
  font-size: 16px;
  font-weight: 400;
  color: ${theme.primaryColor};
  font-weight: 300;

  &::placeholder {
    opacity: 1;
  }
`;

export const buttonStyle = () => css`
  margin-top: 12px;
  border: 0;
  background: #14a3de;
  border-radius: 5px;
  padding: 12px;
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  font-weight: 300;
  float: right;

  &::placeholder {
    opacity: 1;
  }
`;

import Axios from "./Axios.service";

const createRequestFormatter = (userInput) => {
  const {
    firstName,
    lastName,
    middleName,
    companyName,
    regNumber,
    email,
    phone,
    type,
    relationship,
    address1,
    address2,
    city,
    countryCommonName,
    country,
    postcode,
    state,
    id
  } = userInput;

  const formattedData = {
    senderCode: null,
    firstName,
    lastName,
    middleName,
    companyName,
    regNumber,
    email,
    phone,
    beneficiaryName: null,
    dob: null,
    type,
    action: null,
    comment: null,
    employer: null,
    salary: null,
    salaryCurrency: null,
    relationShip: relationship,
    address: {
      address1: address1,
      address2: address2,
      city: city,
      countryCommonName,
      postcode: postcode,
      countryIso3: country,
      state: state
    }
  };

  if (id) {
    return {
      ...formattedData,
      id
    };
  }

  return formattedData;
};

export default class BeneficiaryService {
  static async fetchAll() {
    return Axios({
      method: "GET",
      url: "/web-services/api/v6/services/usermanagement/find-all-beneficiery",
      headers: {
        platform: "fincode",
        UUID: 200
      }
    });
  }

  static async create(payload) {
    const requestPayload = createRequestFormatter(payload);

    return Axios({
      method: "POST",
      url: "/web-services/api/v6/services/usermanagement/new-beneficiary",
      data: requestPayload,
      headers: {
        platform: "fincode",
        UUID: 200
      }
    });
  }

  static async update(payload) {
    return Axios({
      method: "PUT",
      url: "/web-services/api/v6/services/usermanagement/edit-beneficiary",
      data: payload,
      headers: {
        platform: "fincode",
        UUID: 200
      }
    });
  }

  static async fetchBankDetails(customerCode) {
    return Axios({
      method: "GET",
      url: `web-services/api/v6/services/bankmanagement/list-beneficiary-bank-details/${customerCode}`,
      headers: {
        platform: "fincode",
        UUID: 200
      }
    });
  }

  static async addBankDetails(payload) {
    return Axios({
      method: "POST",
      url: `web-services/api/v6/services/bankmanagement/add-bank-details`,
      headers: {
        platform: "fincode",
        UUID: 200
      },
      data: payload
    });
  }

  static async updateBankDetails(payload) {
    return Axios({
      method: "PUT",
      url: "web-services/api/v6/services/bankmanagement/update-customer-bank-details",
      headers: {
        platform: "fincode",
        UUID: 200
      },
      data: payload
    });
  }

  static async listSupportedBanks({ countryIso }) {
    return Axios({
      method: "GET",
      url: `web-services/api/v6/services/bankmanagement/list-supported-banks-iso3/${countryIso}`,
      headers: {
        platform: "fincode",
        UUID: 200
      }
    });
  }

  static async fetchBeneficiaryDetails({ beneficiaryId }) {
    return Axios({
      method: "GET",
      url: `web-services/api/v6/services/usermanagement/view-beneficiery-details/${beneficiaryId}`,
      headers: {
        platform: "fincode",
        UUID: 200
      }
    });
  }

  static async findBeneficiaryBySearchCriteria(payload) {
    return Axios({
      method: "GET",
      url: `web-services/api/v6/services/usermanagement/find-beneficiery-by-search-criteria${payload}`,
      headers: {
        platform: "fincode",
        UUID: 200
      }
    });
  }

  static async getDocuments() {}
}

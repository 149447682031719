import * as ThemeConstants from "../constants/theme.constants";
import * as Loading from "../constants/loadingState.constants";

const initialState = {
  primaryColor: "#0055AD",
  secondaryColor: "#5BCEAE"
};

const themeReducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default themeReducer;

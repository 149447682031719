import { ActionCreator } from "../../Utils/Helpers";
import * as DomainBrand from "../constants/domain-brand.constants";
import * as DomainBrandService from "../../Utils/Services/DomainBrand.service";

export const getDomainBrandIdentity = (subdomain, languageOption) => async (dispatch) => {
  try {
    dispatch(ActionCreator(DomainBrand.GET_DOMAIN_BRAND_IDENTITY_REQUEST));
    const { data: responseData } = await DomainBrandService.fetchDomainBrandIdentity(subdomain, languageOption);
    if (responseData.status === "FAILED") throw responseData;
    const domainData = responseData['data'];
    delete domainData.language;
    delete domainData.languageDictionary;

    const { data: dictionaryData } = await DomainBrandService.languageDictionary(languageOption);
    const { data: langData } = await DomainBrandService.fetchLanguages();

    const data = {languageDictionary: dictionaryData.data, language: langData.data, ...domainData};

    dispatch(ActionCreator(DomainBrand.GET_DOMAIN_BRAND_IDENTITY_SUCCESS, data));
  } catch (error) {
    dispatch(ActionCreator(DomainBrand.GET_DOMAIN_BRAND_IDENTITY_FAILURE));
  }
};


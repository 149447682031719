// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from "react";
/** @jsx jsx */
/** @jsxRuntime classic */

import { jsx } from "@emotion/react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import NavBar from "Components/Navbar";
import Footer from "Components/Footer";
import HeadingText from "Components/HeadingText";
import TableButton from "Components/TableRowButton";
import Spinner from "Components/Spinner";
import TableSpinner from "Components/TableSpinner";
import usePagination from "Components/Pagination/usePagination";
import Pagination from "Components/Pagination";
import BackArrow from "Components/BackArrow";

import surfaceIcon from "Assets/svg/surface1.svg";
import addUserIcon from "Assets/svg/add-user.svg";
import rightArrowIcon from "Assets/svg/right-arrow.svg";
import offSetImg from "Assets/svg/man-user-1.svg";
import downloadIcon from "Assets/svg/download.svg";

import { styleSheet, bottomSection, tableStyle } from "./styleSheet";
import { getUserList, deactivateUserAccount, activateUserAccount } from "Store/actions/user.action";
import { filterData } from "Utils/Helpers/general";
import pdfGenerator from "Utils/Helpers/pdfGenerator";
import * as Loading from "Store/constants/loadingState.constants";
import { BrandedRegularButton } from "../../../../Components/BrandingComponents";

const colorMap = {
  blue: "#0055AD",
  green: "#0FCC98",
  red: "#E50C37"
};

const getClassNameByStatus = (status) => {
  switch (status) {
    case "PENDING":
      return "blue";
    case "ACTIVATED":
    case "CONFIRMED":
      return "green";
    default:
      return "red";
  }
};

const TableRow = ({ tableData, actionStatus, dispatch, editButtonClick }) => {
  const { firstName, lastName, phone, email, country, status, id } = tableData;
  return (
    <tr>
      <td>
        <span>
          {firstName} {lastName}
        </span>
      </td>
      <td>
        <span>{email}</span>
      </td>
      <td>
        <span>{phone}</span>
      </td>
      <td>
        <span>{country}</span>
      </td>
      <td>
        <span className={getClassNameByStatus(status)}>{status}</span>
      </td>

      <td>
        <div className="table-row-controls">
          <TableButton
            text="Edit"
            color={colorMap.blue}
            onClick={() => editButtonClick(tableData)}
          />
          {actionStatus === Loading.FETCHING ? (
            <TableSpinner />
          ) : (
            <React.Fragment>
              {["ACTIVATED", "CONFIRMED"].includes(status) ? (
                <TableButton
                  text="Deactivate"
                  color={colorMap.red}
                  onClick={() => dispatch(deactivateUserAccount({ userId: id }))}
                />
              ) : (
                <TableButton
                  text="Activate"
                  color={colorMap.green}
                  onClick={() => dispatch(activateUserAccount({ userId: id }))}
                />
              )}
            </React.Fragment>
          )}
        </div>
      </td>
    </tr>
  );
};

const generateDownloadData = (data) => {
  return data.map(({ firstName, lastName, email, phone, country, status }) => [
    firstName || "-",
    lastName || "-",
    email || "-",
    phone || "-",
    country || "-",
    status || "-"
  ]);
};

const activateUser = (dispatch) => ({
  updateUser: (userId) => dispatch(activateUserAccount(userId))
});

const ViewUsers = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [filterText, setFilterText] = useState("");
  const [collection, setCollection] = useState([]);
  const {
    user: { userList, userListStatus, userListAction }, domainBrand
  } = useSelector(({ userReducer, themeReducer, domainBrandReducer }) => ({
    user: userReducer,
    theme: themeReducer,
    domainBrand: domainBrandReducer
  }));

  const languageDictionary = domainBrand.domainBrand["languageDictionary"];
  const languageDictionaryStatus = !(typeof languageDictionary === "undefined" || languageDictionary === null);

  const editButtonClick = (stateData) => {
    history.push({
      pathname: "/users/update",
      state: stateData
    });
  };

  useEffect(() => {
    dispatch(getUserList());
  }, []);

  useEffect(() => {
    const data = userList;
    if (data.length > 0) {
      const filteredData = filterData(data, filterText, ["firstName", "lastName"]);
      setCollection(filteredData);
    }
  }, [filterText, userList]);

  const PAGE_LIMIT = 5;
  const [currentData, onPageChange] = usePagination({ pageLimit: PAGE_LIMIT, collection });

  return (
    <div css={(theme) => styleSheet(theme)}>
      <NavBar />

      <main className="main-section mt-5">
        <div className="canvas-img">
          <img src={offSetImg} alt="off canvas icon" />
        </div>
        <div className="heading-wrapper d-flex">
          <BackArrow />
          <HeadingText text="Users" />
        </div>
        {userListStatus === "FETCHING" ? <Spinner /> :
          <>
            <section className="body-section">
              <div className="top-section">
                <div className="top-section--leftSide">
                  <div className="first-circle">
                    <div className="second-circle">
                      <div className="third-circle">
                        <p className="circle-text">
                          Total Number of <br /> Users
                        </p>
                        <p className="circle-number">{userList.length}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="top-section--rightSide">
                  <BrandedRegularButton onClick={() => history.push("/users/create")}>
                    <span>
                      <img src={addUserIcon} alt="add user icon" />
                    </span>
                    <p>
                      {languageDictionaryStatus ? languageDictionary["WO_ADD_NEW_USE_1247195183"] : "Add new user"}
                    </p>
                    <span>
                      <img src={rightArrowIcon} alt="right arrow icon" />
                    </span>
                  </BrandedRegularButton>

                  <BrandedRegularButton onClick={() => history.push("/bank-account/create")}>
                    <span>
                      <img src={surfaceIcon} alt="create icon" />
                    </span>
                    <p>
                      {languageDictionaryStatus ? languageDictionary["WO_REQUEST_BANK_ACCOUN_1842427382"] : "Request bank account"}
                    </p>
                    <span>
                      <img src={rightArrowIcon} alt="right arrow icon" />
                    </span>
                  </BrandedRegularButton>
                </div>
              </div>
            </section>

            <section css={(theme) => bottomSection(theme)}>
              <div className="table-controls mt-5">
                <p className="table-controls--text">
                  {languageDictionaryStatus ? languageDictionary["WO_CURRENT_USER_-2142666363"] : "Current Users"}
                </p>

                <div className="table-controls--filters">
                  <div className="table-controls--filterInput">
                    <label htmlFor="filterText">
                      <input
                        type="text"
                        name="filterText"
                        id="filterText"
                        placeholder="Filter by first or last name"
                        value={filterText}
                        onChange={(e) => setFilterText(e.target.value)}
                      />
                    </label>
                  </div>
                  <button
                    className="table-controls--download"
                    onClick={() =>
                      pdfGenerator({
                        heading: "Users",
                        tableHeaders: [
                          "First name",
                          "Last Name",
                          "Email",
                          "Phone",
                          "Country",
                          "Status"
                        ],
                        data: generateDownloadData(userList)
                      })
                    }>
                    <img src={downloadIcon} alt="download icon" />
                  </button>
                </div>
              </div>

              <div css={(theme) => tableStyle(theme)}>
                <table>
                  <thead>
                  <tr>
                    <th scope="col">
                      {languageDictionaryStatus
                        ? languageDictionary["l_account_commionssion"]
                        : "Name"}
                    </th>
                    <th scope="col">
                      {languageDictionaryStatus ? languageDictionary["para_email"] : "Email"}
                    </th>
                    <th scope="col">
                      {languageDictionaryStatus
                        ? languageDictionary["para_phone_number"]
                        : "Phone Number"}
                    </th>
                    <th scope="col">
                      {languageDictionaryStatus
                        ? languageDictionary["WO_COUNTR_1271562677"]
                        : "Country"}
                    </th>
                    <th scope="col">
                      {languageDictionaryStatus
                        ? languageDictionary["param_services_type_status"]
                        : "Status"}
                    </th>
                    <th />
                  </tr>
                  </thead>
                  <tbody>
                  {currentData < 1 ? (
                    <>
                      <tr>
                        <td colSpan="6" className="text-center bg-grey">
                          <span>No user available</span>
                        </td>
                      </tr>
                    </>
                  ) : (
                    <>
                      {currentData.map((user) => (
                        <TableRow
                          tableData={user}
                          key={user.id}
                          actionStatus={userListAction[user.id]}
                          dispatch={dispatch}
                          editButtonClick={editButtonClick}
                        />
                      ))}
                    </>
                  )}
                  </tbody>
                </table>
              </div>
              <Pagination
                resultName="Total Users"
                onPageChange={onPageChange}
                pageLimit={PAGE_LIMIT}
                totalRecords={collection.length}
              />
            </section>
          </>
        }
      </main>
      <Footer />
    </div>
  );
};

export default ViewUsers;

import * as CashPickUpConstants from "../constants/cash-pickup.constants";
import * as Loading from "../constants/loadingState.constants";

const initialState = {
  countries: [],
  loading: '',
};

const cashPickUpReducer = (state = initialState, action) => {
  switch (action.type){
    case CashPickUpConstants.FETCH_COUNTRY_LIST_REQUEST:
      return {
        ...state,
        loading: Loading.FETCHING,
      };

    case CashPickUpConstants.FETCH_COUNTRY_LIST_SUCCESS:
      return {
        ...state,
        countries: action.payload,
        loading: Loading.SUCCESS,
      }
    case CashPickUpConstants.FETCH_COUNTRY_LIST_FAILURE:
      return {
        ...state,
        loading : Loading.ERROR,
      }
    default:
      return state;
  }
}

export default cashPickUpReducer;

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import Footer from "Components/Footer";
import NavBar from "Components/Navbar";
import Spinner from "Components/Spinner";
import ShoppingCartItem from "Components/ShoppingCartItem";
import PaymentSummary from "Components/PaymentSummary";
import CartIcon from "Assets/svg/merchant-product.svg";
import { NextButton, CartCircle } from "./style";
import * as CartActions from "Store/actions/cart.action";
import * as MerchantActions from "Store/actions/merchant.action";
import BackArrow from "Components/BackArrow";

const Cart = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { cart, merchant, domainBrand } = useSelector(
    ({ cartReducer, merchantReducer, domainBrandReducer }) => ({
      cart: cartReducer,
      merchant: merchantReducer,
      domainBrand: domainBrandReducer,
    })
  );

  // Language state
  const languageDictionary = domainBrand.domainBrand["languageDictionary"];
  const languageDictionaryStatus = !(
    typeof languageDictionary === "undefined" || languageDictionary === null
  );

  const { userData } = useSelector(({ profileReducer }) => profileReducer);
  const itemsSubtotal = cart.items.reduce((acc, item) => {
    return acc + item.quantity * item.product_value;
  }, 0);
  const itemsTotal = itemsSubtotal + 35;

  const handleMakePayment = () => {
    const { customerType } = userData;

    dispatch(CartActions.initiateCartPayment({}));
    history.push("/checkout");

    if (!customerType) {
      dispatch(
        MerchantActions.initiateMerchantPayment({
          amount: itemsTotal,
          currency: cart.items[0].product_currency,
        })
      );
      return history.push("/make-payment");
    }
    // history.push(/* Other path for non-cashier user to handle payment of goods goes here */);
  };

  return (
    <>
      <Helmet>
        <title>Shopping Cart</title>
      </Helmet>
      <NavBar />
      <section>
        <div className="container">
          <div className="mt-5 mb-5">
            <div className="d-flex justify-content-between">
              <div className="d-flex">
                <BackArrow />
                <h2 className="text-blue mt-4">
                  {languageDictionaryStatus ? languageDictionary["WO_CAR_-1891781838"] : "Cart"}
                </h2>
              </div>

              <div>
                <Link
                  to="/merchant-product"
                  className="text-decoration-none"
                  role="button"
                  tabIndex="-1"
                  onKeyPress={() => {}}>
                  <NextButton className="btn btn-lg btn-green text-white btn-block px-3 mb-3 text-uppercase">
                    {languageDictionaryStatus
                      ? languageDictionary["WO_CONTINUE_SHOPPIN_-822938188"]
                      : "Continue Shopping"}
                  </NextButton>
                </Link>
              </div>
            </div>

            <div className="card mt-5 p-xl-2">
              <div className="card-body mb-3 p-md-3">
                <div className="row">
                  <div className="col-12">
                    {/* --- Product item --- */}
                    {cart.cartLoadingStatus === "FETCHING" ? (
                      <Spinner containerHeight="30vh" />
                    ) : (
                      <>
                        {cart.items < 1 ? (
                          <>
                            <div className="text-center ">
                              <CartCircle className="mb-5 mx-auto py-4">
                                <img src={CartIcon} alt="cart" />
                              </CartCircle>

                              <h2 className="mb-5 font-weight-bolder text-blue">
                                Your Cart is Empty!
                              </h2>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="row">
                              <div className="col-12 col-md-7 col-lg-8 mb-5">
                                {cart.items.map((item) => (
                                  <ShoppingCartItem
                                    key={item.product_id}
                                    name={item.product_name}
                                    amount={`${item.product_currency}${item.product_value}`}
                                    imageSrc={item.productMainPhotoUrl}
                                    quantity={item.quantity}
                                    productId={item.product_id}
                                  />
                                ))}
                              </div>

                              {/* ----- Shopping Summary ------ */}
                              <div className="col-12 col-md-5 col-lg-4">
                                <PaymentSummary
                                  address={
                                    userData.address && (
                                      <>
                                        <p>
                                          {`${userData.address.address1} ${userData.address.address2}`}
                                          <br />
                                          {`${userData.address.city}, ${userData.address.countryCommonName}`}
                                        </p>
                                      </>
                                    )
                                  }
                                  name={`${userData.firstName} ${userData.lastName}`}
                                  shippingfee={`${cart.items[0].product_currency}`}
                                  subtotal={`${cart.items[0].product_currency} ${itemsSubtotal}`}
                                  total={`${cart.items[0].product_currency} ${itemsTotal}`}
                                />
                                <div className="row">
                                  <div className="col-12">
                                    <NextButton
                                      onClick={handleMakePayment}
                                      className="btn btn-lg btn-green text-white btn-block px-3 mb-3 text-uppercase">
                                      {languageDictionaryStatus
                                        ? languageDictionary["button_make_payment"]
                                        : "Make Payment"}
                                    </NextButton>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Cart;

import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useHistory } from "react-router-dom";

import Footer from "Components/Footer";
import NavBar from "Components/Navbar";
import styled from "@emotion/styled";
import { toast } from "react-toastify";
import TransferService from "../../../../Utils/Services/Transfer.service";

const Input = styled.input`
  width: 100%;
  margin-top: 12px;
  border: 0;
  background: #e3edf3;
  border-radius: 5px;
  padding: 1.5rem;
  font-size: 16px;
  font-weight: 400;

  ::placeholder {
    color: #6c757d;
  }
`;

const NextButton = styled.button`
  font-size: 1rem;
`;

const SecurityQuestion = () => {
  const history = useHistory();
  const [securityAnswer, setSecurityAnswer] = useState("");
  const [password, setPassword] = useState("");
  const [OTP, setOTP] = useState("");
  let payoutData, secretQuestionState, securityOTPState;
  if (typeof history.location.state === "undefined") history.push("/cashier/payout-search");
  else {
    payoutData = history.location.state;
    secretQuestionState = (payoutData.payoutData["secretQuestion"] !== null || payoutData.payoutData["secretQuestion"] === "");
    securityOTPState = payoutData.payoutData["requiredOtpOnPayout"];
  }

  console.log(payoutData)

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const validateSecurity = async () => {
    const payload = { pcn: payoutData.pcn, paymentReferenceCode: payoutData["serverCorrelationId"] };
    if (secretQuestionState) payload["security_question_answer"] = securityAnswer;
    if (securityOTPState) payload["security_otp"] = OTP;
    const { data: response } = await TransferService.payoutTransaction(payload, password);
    if (response.status === "ERROR") toast.error(`${response.message}`, { position: toast.POSITION.TOP_CENTER });
    else history.push({ pathname: "/cashier/payout", state: { response: response.data, payoutData } });
  };

  return (
    <>
      <Helmet>
        <title>Security Question &mdash; Multiview MTA</title>
      </Helmet>
      <NavBar />
      <section>
        <div className="container">
          <div className="mt-5 mb-5">
            <h2 className="text-blue ">Security Question</h2>
            <div className="card mt-5 p-xl-5">
              <div className="card-body mb-3 p-md-3">
                <div className="row mb-5">
                  <div className="col-12 col-md-6">
                    {secretQuestionState ?
                      <div>
                        <h2 className="text-blue mb-4">{payoutData.payoutData["secretQuestion"]}</h2>
                        <p className="text-blue">Security Answer</p>
                        <Input
                          value={securityAnswer}
                          onChange={(e) => setSecurityAnswer(e.target.value)}
                          type="text"
                          className="form-control"
                          placeholder="Type in answer..."
                        />
                      </div> : ""
                    }
                    {securityOTPState ?
                      <div className="mt-4">
                        <p className="text-blue">Provided OTP</p>
                        <Input
                          value={OTP}
                          onChange={(e) => setOTP(e.target.value)}
                          type="password"
                          className="form-control"
                          placeholder="Enter Password"
                        />
                      </div> : ""
                    }
                    <div className="mt-4">
                      <p className="text-blue">Your Password</p>
                      <Input
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        type="password"
                        className="form-control"
                        placeholder="Enter Password"
                      />

                    </div>
                  </div>

                </div>

                <div className="row">
                  <div className="col-12 col-md-3">
                    <NextButton onClick={validateSecurity}
                                className="btn btn-lg btn-blue text-white btn-block px-3 text-uppercase">
                      submit
                    </NextButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default SecurityQuestion;

import React from 'react';
import Select from 'react-select';
import { useSelector } from 'react-redux';

import FormLabel from 'Components/FormLabel';
import DropDownComponent from 'Components/DropDownComponent';
import downArrow from 'Assets/svg/blue-drop-down-arrow.svg';
import customStyles from 'Assets/styles/dropdown/customStyles';
import ErrorField from 'Components/ErrorField';

const convertToArray = (value, options) => {
  const optionArr = [];
  const optionObj = {"value": value, "label": options}
  optionArr.push(optionObj);
  return optionArr;
}

const FormSelect = ({
  formik,
  options,
  option2,
  value,
  selectLabel,
  selectPlaceholder,
  selectName,
  fetchingData,
  isSearchable,
  onChangeCallback = () => {},
}) => {
  const theme = useSelector(({ themeReducer }) => themeReducer);
  return (
    <FormLabel text={selectLabel}>
      <ErrorField
        formik={formik}
        fieldName={selectName}
        fetchingData={fetchingData}
        selectField
      />
      <Select
        options={convertToArray(value, options)}
        defaultValue={{ label: options, value: value }}
        styles={customStyles}
        customTheme={theme}
        isClearable={false}
        isSearchable={isSearchable}
        placeholder={selectPlaceholder}
        name={selectName}
        value={formik.values[selectName]}
        onChange={(option) => {
          onChangeCallback();
          formik.setFieldValue(selectName, option);
        }}
        onBlur={() => formik.setFieldTouched(selectName, true)}
        components={{
          IndicatorSeparator: null,
          DropdownIndicator: () => <DropDownComponent iconSource={downArrow} />,
        }}
      />
    </FormLabel>
  );
};

export default FormSelect;

import Axios from "./Axios.service";

export default class ProfileService {
  static async fetchUser() {
    return Axios({
      method: "GET",
      url: "/web-services/api/v6/services/usermanagement/profile",
      headers: {
        platform: "fincode",
        UUID: 200
      }
    });
  }

  // static async update({
  //   firstName,
  //   lastName,
  //   password,
  //   confirmPassword,
  //   devicePlatform,
  //   dialingCode,
  //   phone,
  //   dob,
  //   appVersion,
  //   appId,
  //   referralCode,
  //   nationality,
  //   customerType,
  //   customerGender,
  //   socialId,
  //   socialMediaAccessToken,
  //   email,
  //   address,
  //   id
  // }) {
  //   const dataValues = {
  //     firstName,
  //     lastName,
  //     password,
  //     confirmPassword,
  //     devicePlatform,
  //     dialingCode,
  //     phone,
  //     dob,
  //     appVersion,
  //     appId,
  //     referralCode,
  //     nationality,
  //     customerType,
  //     customerGender,
  //     socialId,
  //     socialMediaAccessToken,
  //     email,
  //     userId: id,
  //     address: {
  //       address1: address.address1,
  //       address2: address.address2,
  //       city: address.city,
  //       countryCommonName: address.countryCommonName,
  //       postcode: address.postcode,
  //       countryIso3: address.countryIso3
  //     }
  //   };

  static async update(payload) {
    return Axios({
      method: "PUT",
      url: "/web-services/api/v6/services/usermanagement/profile-edit",
      headers: {
        platform: "fincode",
        UUID: 200
      },
      data: payload
    });
  }
}

// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from "react";

/** @jsx jsx */
/** @jsxRuntime classic */
import { jsx } from "@emotion/react";
import { useHistory, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector, batch } from "react-redux";
import SubHeadingText from "Components/SubHeadingText";
import HeadingText from "Components/HeadingText";
import { formContainer } from "./stylesheet";
import PaymentDetails from "./PaymentDetails";
import TransDetails from "./TransDetails";
import { getAllBeneficiaries } from "Store/actions/beneficiary.action";
import { getAccountBalances } from "Store/actions/account.action";
import { getAllCategories } from "Store/actions/category.action";
import { getCashPickupCountryList } from "Store/actions/cash-pickup.action";
import { getMobileMobileCountryList } from "Store/actions/mobileMoney.action";
import {
  getTransferCountryList,
  getBeneficiaryBankDetails,
  initiateTransaction
} from "Store/actions/transfer.action";
import * as Loading from "Store/constants/loadingState.constants";
import { getProfile } from "Store/actions/profile.action";
import navigateMethod from "Utils/Helpers/navigateMethod";
import { bankPairs, purposeOfTransfer, sourceOfFunds } from "Utils/constants";
import Styles from "../../../Auth/Login/index.module.scss";
import Spinner from "Components/Spinner";
import { createLabelAndValueForCountryDropdown } from "Utils/Helpers/general";
import cookieHelper from "../../../../Utils/Helpers/cookieHelper";
import config from "../../../../Utils/Helpers/config";
import { toast } from "react-toastify";
import BackArrow from "Components/BackArrow";

const objCreator = (valueData) => {
  return Object.keys(valueData || {}).reduce(
    (acc, curr) => {
      if (valueData[curr] === true) {
        const key = curr.split("Required")[0];
        acc.fieldData[key] = "";
        acc.validations[key] = "";
      }
      return acc;
    },
    { fieldData: {}, validations: {} }
  );
};

const TransactionDetails = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { beneficiaries, accounts, transfers, profile, theme, domainBrand } = useSelector(
    ({
      beneficiaryReducer,
      accountReducer,
      transferReducer,
      profileReducer,
      themeReducer,
      domainBrandReducer
    }) => ({
      beneficiaries: beneficiaryReducer,
      accounts: accountReducer,
      transfers: transferReducer,
      profile: profileReducer,
      theme: themeReducer,
      domainBrand: domainBrandReducer
    })
  );

  const { cashPickupCountries, transferFundsCountries, mobileMoneyCountries, currencies } =
    useSelector(({ cashPickupReducer, transferReducer, mobileMoneyReducer, countryReducer }) => ({
      cashPickupCountries: cashPickupReducer["countries"],
      transferFundsCountries: transferReducer["transferFundsCountries"],
      mobileMoneyCountries: mobileMoneyReducer["countries"],
      currencies: countryReducer
    }));

  const [initialValues, updateInitialValues] = useState({
    sendingAmount: "",
    receivingAmount: "",
    bankFields: "",
    sendingCurrency: "",
    receivingCurrency: "",
    selectedCurrency: "",
    currencyStatus: "",
    transferPurpose: "",
    fundsSource: sourceOfFunds.INDIVIDUAL[0],
    category: "",
    beneficiary: "",
    receivingCountry: "",
    securityQuestion: "",
    securityAnswer: "",
    phoneNumber: "",
    confirmPhoneNumber: "",
    transactionReference: "",
    remittancePurpose: ""
  });

  const initialValidation = {
    beneficiary: Yup.object({ value: Yup.string().required("Beneficiary is required") })
  };
  const [validationSchema, updateValidationSchema] = useState(initialValidation);
  const [bankDetailsSelect, updateBankDetailsSelect] = useState("");
  const [originCountry, updateOriginCountry] = useState("");
  const [senderDetailsForCashier, updateSenderDetailsForCashier] = useState({});
  const userType = profile["userData"]["userType"];
  const tradeOriginCountry =
    cookieHelper.get(config.USER_COUNTRY) || profile["userData"]["address"]["countryIso3"];

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: Yup.object({ ...validationSchema }),
    onSubmit: (values) => {
      let creditParty = [],
        inclusionList = [];
      const {
        // sendingAmount,
        // receivingAmount,
        beneficiary,
        receivingCurrency,
        sendingCurrency,
        phoneNumber,
        confirmPhoneNumber
      } = values;
      const fields = transfers.bankFields;

      const handleFloat = (t) => {
        const v = t?.sendingAmount || t?.receivingAmount;
        const strfy = v.toString();
        if (strfy.indexOf(",") !== -1) {
          return parseFloat(v.replace(/,/g, ""));
        } else {
          return v;
        }
      };

      creditParty.push({ key: "mobilemoneynumber", value: phoneNumber });
      /*const inclusionList = Object.keys(fields || {})
        .filter((value) => fields[value] === true)
        .map((value) => value.split("Required")[0]);*/
      fields.forEach((element) => inclusionList.push(element["apiName"]));
      let creditPartyForBankCodes = inclusionList.map((partyVal) => {
        const key = partyVal;
        const value = values[[partyVal]];
        return { key, value };
      });

      creditParty = creditParty.concat(creditPartyForBankCodes);
      const {
        userData: {
          address: { countryIso3 }
        }
      } = profile;
      const customerCode =
        profile.userData["userType"] === "CASHIER"
          ? senderDetailsForCashier.code
          : profile.userData.customerCode;
      const password = profile.userData["userType"] === "CASHIER" ? formik.values.password : null;

      if (transactionType === "MOBILE_MONEY" && confirmPhoneNumber !== phoneNumber)
        toast.error(`Phone number does not match`, { position: toast.POSITION.TOP_CENTER });
      else {
        const dataPayload = {
          tax: transfers.quoteData.tax,
          creditParty,
          type: transactionType,
          // sendAmount: parseFloat(sendingAmount.replace(/,/g, "")),
          sendAmount: handleFloat(values),
          // amount: parseFloat(receivingAmount.replace(/,/g, "")),
          amount: handleFloat(values),
          senderCustomerCode: customerCode,
          recipientCustomerCode: beneficiary.beneficiaryCode,
          originCountry: countryIso3,
          receivingCountry: formik.values.receivingCountry["iso3Code"],
          receivingCurrency: receivingCurrency["value"],
          sendingCurrency: sendingCurrency["value"],
          securityQuestion: formik.values.securityQuestion,
          securityAnswer: formik.values.securityAnswer,
          phoneNumber: formik.values.phoneNumber,
          transactionReference: formik.values.transactionReference,
          sourceOfFunds: formik.values.fundsSource.value,
          remittancePurpose: formik.values.remittancePurpose.value
        };
        const navigator = navigateMethod(history, "/transfer/choose-payment-method");
        dispatch(initiateTransaction({ dataPayload, navigator }, password));
      }
    }
  });

  const getRecipientBankDetails = (allInfo) => {
    const { value } = allInfo;
    batch(() => {
      dispatch(getBeneficiaryBankDetails({ customerCode: value }));
    });
  };

  useEffect(() => {
    if (profile.userProfileLoading === "" || profile.userProfileLoading === Loading.ERROR)
      dispatch(getProfile());
    if (typeof location.state !== "undefined" && "user" in history.location.state)
      updateSenderDetailsForCashier(location.state.user);
    else {
      if (userType === "CASHIER")
        history.push({
          pathname: "/get-sender",
          state: { prevLocation: history.location.pathname, pageStatus: location.state?.text }
        });
    }

    batch(() => {
      const originCountry =
        userType === "CASHIER" && typeof location.state.user !== "undefined"
          ? location.state.user.countryIso3
          : tradeOriginCountry;
      updateOriginCountry(originCountry);
      dispatch(getTransferCountryList(originCountry));
      dispatch(getMobileMobileCountryList(originCountry));
      dispatch(getCashPickupCountryList(originCountry));
    });
  }, [location]);

  useEffect(() => {
    updateBankDetailsSelect(transfers.beneficiaryBankInfo[0]);
  }, [transfers.beneficiaryBankInfo]);

  useEffect(() => {
    if (userType !== "CASHIER") {
      batch(() => {
        dispatch(getAllBeneficiaries());
        dispatch(getAccountBalances());
      });
    }
  }, []);

  useEffect(() => {
    dispatch(getAllCategories());
  }, []);

  useEffect(() => {
    if (transfers.bankFieldStatus === "SUCCESS") {
      const bankFields = objCreator(transfers.bankField);
      const formikMods = {
        ...formik.values,
        ...bankFields.fieldData,
        requiredBankFields: transfers.bankField
      };
      updateInitialValues(formikMods);
      updateValidationSchema({
        ...initialValidation,
        ...bankFields.validations
      });

      if (location.state && location.state.reload && accounts.loadingStatus === Loading.SUCCESS) {
        const {
          state: { bankFields, sendingAmount }
        } = location;
        const selectBank = transfers.beneficiaryBankInfo.find(
          (item) => item.accountNumber === bankFields.accountNumber
        );
        updateBankDetailsSelect(selectBank);
        updateInitialValues({
          ...formik.values,
          ...selectBank,
          sendingAmount
        });
      }
    }
  }, [transfers.bankField, transfers.bankFieldStatus, accounts.loadingStatus]);

  useEffect(() => {
    if (beneficiaries.loadingStatus === Loading.SUCCESS && location.state) {
      const {
        state: { id, beneficiaryCode }
      } = location;
      const preselectedBeneficiary = beneficiaries.dropDownList.find(
        (ben) => ben.id === id || ben.beneficiaryCode === beneficiaryCode
      );
      updateInitialValues({
        ...formik.values,
        beneficiary: preselectedBeneficiary
      });
      if (preselectedBeneficiary) {
        getRecipientBankDetails(preselectedBeneficiary);
      }
    }
  }, [beneficiaries.loadingStatus]);

  const showSpinner =
    domainBrand.loading === Loading.FETCHING || profile.userProfileLoading === Loading.FETCHING;
  const languageDictionary = domainBrand.domainBrand["languageDictionary"];
  const languageDictionaryStatus = !(
    typeof languageDictionary === "undefined" || languageDictionary === null
  );
  let pageStatus = location.state?.text;
  let pageStatusRender, transactionType, chargeCategory, pageCountries;
  let cashPickUpLingual = languageDictionaryStatus
    ? languageDictionary["para_cash_pickup"]
    : "Cash Pickup";
  let transferFundsLingual = languageDictionaryStatus
    ? languageDictionary["WO_DEPOSIT_TO_BANK_ACCO_216619421"]
    : "Transfer Funds";
  let mobileMoneyLingual = languageDictionaryStatus
    ? languageDictionary["MOBILE_MONE_1497679979"]
    : "Mobile Money";

  if (pageStatus === "Cash Pickup") {
    pageStatusRender = cashPickUpLingual;
    pageCountries = cashPickupCountries;
    transactionType = "CASHPICKUP";
    chargeCategory = "MONEYTRANSFER";
  } else if (pageStatus === "Transfer Funds") {
    pageStatusRender = transferFundsLingual;
    pageCountries = transferFundsCountries;
    transactionType = "ACCOUNTPAYMENT";
    chargeCategory = "MONEYTRANSFER";
  } else if (pageStatus === "Mobile Money") {
    pageStatusRender = mobileMoneyLingual;
    pageCountries = mobileMoneyCountries;
    transactionType = "MOBILE_MONEY";
    chargeCategory = "MOBILE_MONEY_TRANSFER";
  } else {
    pageStatus = "Transfer Funds";
    pageStatusRender = transferFundsLingual;
    pageCountries = transferFundsCountries;
    transactionType = "ACCOUNTPAYMENT";
    chargeCategory = "MONEYTRANSFER";
  }
  pageCountries = createLabelAndValueForCountryDropdown(pageCountries);

  return (
    <>
      {showSpinner ? (
        <div className={Styles.modSpinner}>
          <Spinner />
        </div>
      ) : (
        <>
          <div className="d-flex">
            <BackArrow />
            <HeadingText text={pageStatusRender} />
          </div>
          <div className="fdt_subHeading">
            <SubHeadingText
              text={
                languageDictionaryStatus
                  ? languageDictionary["l_transaction_details"]
                  : "Transaction Details"
              }
            />
          </div>
          <form css={formContainer} onSubmit={formik.handleSubmit}>
            <TransDetails
              pageStatus={pageStatus}
              userRole={userType}
              originCountry={originCountry}
              cashier={senderDetailsForCashier}
              transactionType={transactionType}
              chargeCategory={chargeCategory}
              formik={formik}
              beneficiaries={beneficiaries}
              accounts={accounts.dropDownList}
              countries={pageCountries}
              getRecipientBankDetails={getRecipientBankDetails}
              transfers={transfers}
              bankDetailsSelect={bankDetailsSelect}
              updateBankDetailsSelect={updateBankDetailsSelect}
              history={history}
              profile={profile}
              theme={theme}
              languageDictionary={languageDictionary}
              currencies={currencies}
            />
            <PaymentDetails
              formik={formik}
              bankDetailsSelect={bankDetailsSelect}
              languageDictionary={languageDictionary}
              transfers={transfers}
              pageStatus={pageStatus}
              profile={profile}
            />
          </form>
        </>
      )}
    </>
  );
};

export default TransactionDetails;

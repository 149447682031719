import React from "react";
import { Helmet } from "react-helmet";
import OrderHistoryItems from "Components/OrderHistoryItems";
import { useSelector } from "react-redux";
import { TH, Table, Span } from "./style";

const OrderHistory = () => {
  const { cart } = useSelector(({ cartReducer }) => ({ cart: cartReducer }));
  return (
    <>
      <Helmet>
        <title>Order History</title>
      </Helmet>
      <section>
        <div className="container">
          <div className="mb-5">
            <h2 className="text-blue mb-5">Order History</h2>
            <div className="row">
              <div className="col-12">
                {/* --- Product item --- */}
                <div className="row">
                  <div className="col-12 col-md-12 mb-2">
                    <Table className="table-hover table-responsive-md mb-5">
                      <thead>
                        <tr>
                          <TH scope="col" className="py-4 text-uppercase-md fs-6">
                            Product Name
                          </TH>
                          <TH scope="col" className="py-4 text-uppercase-md fs-6">
                            Date ordered
                          </TH>
                          <TH scope="col" className="py-4 text-uppercase-md fs-6">
                            Total
                          </TH>
                          <TH scope="col" className="py-4 text-uppercase-md fs-6">
                            Status
                          </TH>
                          {/* <TH scope="col" className="py-4 text-uppercase-md fs-6">
                            Action
                          </TH> */}
                        </tr>
                      </thead>
                      <tbody>
                        {cart.items < 1 ? (
                          <>
                            <tr>
                              <td colSpan="7" className="text-center bg-grey">
                                <Span>You haven't ordered anything yet</Span>
                              </td>
                            </tr>
                          </>
                        ) : (
                          <>
                            {cart.items.map((item) => (
                              <OrderHistoryItems
                                key={item.product_id}
                                productName={item.product_name}
                                amount={`${item.product_currency}${item.product_value}`}
                              />
                            ))}
                          </>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default OrderHistory;

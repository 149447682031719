import pdfmake from "pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { imageDataUrl } from "./imageDataUrl";

const pdfGenerator = ({
  balance,
  dates,
  openingBalance,
  closingBalance,
  data,
  heading,
  headings,
  tableHeaders,
  isAuto,
  userData,
  walletAccountNumber,
}) => {
  pdfmake.vfs = pdfFonts.pdfMake.vfs;
  const [accountStatement, customerServiceInformation, accountSummary] = headings || [heading];

  const { from, to } = dates || {};
  const { address, firstName, lastName, phone } = userData || {};
  const accountName = `${firstName} ${lastName}`;
  const { address1, address2, city, countryCommonName, postcode } = address || {};
  const fullAddress = `${address1} ${address2}, ${city}, ${postcode}, ${countryCommonName}`;

  const headers = tableHeaders.map((item) => ({
    text: item,
    style: "tableHeader",
  }));
  const widthStyle = "*";
  const widths = tableHeaders.map(() => widthStyle);
  const otherHeadings =
    (customerServiceInformation &&
      accountSummary && [
        { text: customerServiceInformation, style: "header" },

        {
          width: "40%",
          style: "accountName",
          text: accountName,
          margin: [0, 0, 0, 5],
        },
        {
          width: "40%",
          style: "fullAddress",
          text: fullAddress,
          margin: [0, 0, 0, 5],
        },
        {
          width: "40%",
          style: "fullAddress",
          text: phone,
          margin: [0, 0, 0, 5],
        },
        { text: accountSummary, style: "header" },
        {
          width: "15%",
          style: "accountSummaryHeader",
          text: `Start Date: ${from}`,
          margin: [0, 0, 0, 2],
        },
        {
          width: "15%",
          style: "accountSummaryHeader",
          text: `End Date: ${to}`,
          margin: [0, 0, 0, 2],
        },
        {
          width: "15%",
          style: "accountSummaryHeader",
          text: `Balance: ${balance}`,
          margin: [0, 0, 0, 2],
        },
        {
          width: "20%",
          style: "accountSummaryHeader",
          text: `Account Number: ${walletAccountNumber}`,
          margin: [0, 0, 0, 2],
        },
        {
          width: "35%",
          style: "accountSummaryHeader",
          text: `Opening Balance: ${openingBalance}`,
          margin: [0, 0, 0, 2],
        },
        {
          width: "35%",
          style: "accountSummaryHeader",
          text: `Closing Balance: ${closingBalance}`,
          margin: [0, 0, 0, 2],
        },
      ]) ||
    [];
  const docDefinition = {
    pageOrientation: "landscape",
    content: [
      {
        columns: [
          {
            image: imageDataUrl,
            width: 150,
          },
        ],
      },
      ...otherHeadings,
      { text: accountStatement, style: "header" },
      {
        table: {
          // headers are automatically repeated if the table spans over multiple pages
          // you can declare how many rows should be treated as headers
          headerRows: 1,
          widths,
          body: [headers, ...data],
        },
      },
    ],
    styles: {
      accountName: {
        color: "#000",
        fontSize: 14,
      },
      accountSummaryBody: {
        color: "#000",
        fontSize: 18,
      },
      accountSummaryHeader: {
        color: "#000",
        fontSize: 14,
      },
      customer: {
        color: "#000",
        fontSize: 10,
      },
      header: {
        fontSize: 22,
        bold: true,
        color: "#000",
        margin: [0, 10],
      },
      link: {
        color: "#000",
        bold: true,
      },
      tableHeader: {
        bold: true,
        fontSize: 13,
      },
    },
  };

  pdfmake.createPdf(docDefinition).open();
};

export default pdfGenerator;

import * as Notification from 'Store/constants/notification.constants';
import * as Loading from 'Store/constants/loadingState.constants';

const initialState = {
  list: [],
  listStatus: '',
  sendStatus: ''
};

const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case Notification.LIST_NOTIFICATION_REQUEST: {
      return {
        ...state,
        listStatus: Loading.FETCHING,
        list: []
      };
    }

    case Notification.LIST_NOTIFICATION_SUCCESS: {
      return {
        ...state,
        listStatus: Loading.SUCCESS,
        list: action.payload
      };
    }

    case Notification.LIST_NOTIFICATION_FAILURE: {
      return {
        ...state,
        listStatus: Loading.ERROR,
        list: []
      };
    }

    case Notification.SEND_NOTIFICATION_REQUEST: {
      return {
        ...state,
        sendStatus: Loading.FETCHING
      };
    }

    case Notification.SEND_NOTIFICATION_SUCCESS: {
      return {
        ...state,
        sendStatus: Loading.SUCCESS
      };
    }

    case Notification.SEND_NOTIFICATION_FAILURE: {
      return {
        ...state,
        sendStatus: Loading.ERROR
      };
    }

    default:
      return state;
  }
};

export default notificationReducer;

import React from "react";
import styled from "@emotion/styled";

const OrderItem = styled.div`
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid #E3EDF3;
`;


const OrderItems = ({ name, amount, quantity, totalAmount, imageSrc }) => {
  return (
    <OrderItem>
      <div className="row d-flex align-items-center">
        <div className="col-6">
          <div className="d-flex align-items-center mb-3 col-4 col-md-5">
            <img src={imageSrc} alt="test" className="img-fluid" />
          </div>
          <div className="ml-3 text-left">
            <h5 className="text-uppercase text-green">{name}</h5>
          </div>
        </div>
        <div className="col-2 text-blue h5">{amount}</div>
        <div className="col-2 text-blue h5">{quantity}</div>
        <div className="col-2 text-blue h5">{totalAmount}</div>
      </div>
    </OrderItem>
  );
};

export default OrderItems;

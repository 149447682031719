import styled from "@emotion/styled";

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  border: 1px solid #1149835b;
  border-radius: 10px;
  padding: 3rem 40px;

  @media (max-width: 56.25rem) {
    padding: 1.2rem;
  }
`;

export const InnerTopContainer = styled.div`
  background: #f5f9fb;
  padding: .5rem .5rem 0;
  border-radius: 6px;

  @media (max-width: 56.25rem) {
    padding: 1rem;
  }
`;

export const InnerBottomContainer = styled.div`
  background: #e3edf3;
  text-align: center;
  padding: .5rem 2.5rem;
  //margin: 0 auto;
  margin-top: 1rem;
  border-radius: 6px;
  display: flex;
`;

export const TitleHeading = styled.p`
  color: #000000af;
  font-weight: 500;
  font-size: 0.8rem;
  line-height: 1.8;
  margin-bottom: 0.2rem;
`;

export const SubHeading = styled.p`
  color: ${(props) => props.theme.primaryColor};
  font-weight: 500;
  font-size: 1.1rem;
  line-height: 1.5;
  margin-bottom: 1rem;
`;

export const InfoItem = styled.div`
  border-bottom: 2px solid #1149831a;
  flex-basis: 45%;
  margin-bottom: 2.5rem;
`;

export const InfoItemContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

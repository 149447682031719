// eslint-disable-next-line no-unused-vars
import React from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';

const HeadingText = ({ text, fontSize, textAlign, }) => {
  return (
    <p
      css={() => css`
        font-size: ${fontSize || '22px'};
        margin-top: 2rem;
        color: var(--page-title-color);
        font-weight: 500;
        text-align: ${textAlign || 'none'};
      `}
    >
      {text}
    </p>
  );
};

export const H2Text = ({ text, fontSize, textAlign, }) => {
  return (
    <p
      css={() => css`
        font-size: ${fontSize || '22px'};
        color: var(--page-title-color);
        font-weight: 500;
        margin: 2rem 0 1rem 0;
        text-align: ${textAlign || 'none'};
      `}
    >
      {text}
    </p>
  );
};

export default HeadingText;


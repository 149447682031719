import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
/** @jsx jsx */
/** @jsxRuntime classic */

import { jsx, css } from "@emotion/react";
import AriaModal from "react-aria-modal";
import { useLocation, Redirect, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import HeadingText from "Components/HeadingText";
import CheckBox from "Components/CheckBox";
import CheckBoxContent from "Components/CheckBoxContent/termsAndConditions";
import CustomButton from "Components/CustomButton";
import Spinner from "Components/Spinner";
import BankTransfer from "./atom/BankTransfer";
import Wallet from "./atom/Wallet";
import ModalContent from "./atom/ModalContent";

import { fetchPaymentMethods, initiateWalletPayment } from "Store/actions/transfer.action";
import * as Loading from "Store/constants/loadingState.constants";
import {
  leftSectionWrapper,
  rightSectionWrapper,
  bodyWrapper,
  textWrapperStyle,
  AmountDetailWrapper,
  MethodTypeWrapper,
  Separator
} from "./style";
import { getAccountBalances } from "../../../../Store/actions/account.action";
import { getBaseUrl } from "../../../../Utils/Services/BaseUrl";
import Cookies from "js-cookie";
import transferIcon from "../../../../Assets/svg/transfer.svg";
import bankTransferIcon from "../../../../Assets/svg/BankTransfer.svg";
import cardPaymentIcon from "../../../../Assets/svg/CardPayment.svg";
import walletIcon from "../../../../Assets/svg/Wallet.svg";
import PaymentCard from "../../../../Components/PaymentCard";
import customStyles from "../../../../Assets/styles/dropdown/customStyles";
import DropDownComponent from "../../../../Components/DropDownComponent";
import downArrow from "../../../../Assets/svg/blue-drop-down-arrow.svg";
import Select from "react-select";
import { setPaymentMethodDropdown } from "../../../../Utils/Helpers/general";

const PaymentIcons = {
  ONLINE_CARD_PAYMENT: cardPaymentIcon,
  BANK_TRANSFER: bankTransferIcon,
  E_WALLET: walletIcon,
  CRYPTO_CURRENCY_PAYMENT: transferIcon,
  CASH: transferIcon
};

const PaymentTexts = {
  ONLINE_CARD_PAYMENT: "Fast and easy payment with",
  BANK_TRANSFER: "Advanced transfer with",
  E_WALLET: "Make payment with your wallet with",
  CRYPTO_CURRENCY_PAYMENT: "Payment with multiple cryptocurrencies and ",
  CASH: "Cash payment with "
};

const PaymentMethod = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    theme,
    wallets,
    transfer: { paymentMethodStatus, paymentMethod, paymentStatus },
    domainBrand,
    profile
  } = useSelector(
    ({ themeReducer, accountReducer, transferReducer, domainBrandReducer, profileReducer }) => ({
      theme: themeReducer,
      wallets: accountReducer["dropDownList"],
      transfer: transferReducer,
      domainBrand: domainBrandReducer,
      profile: profileReducer
    })
  );

  const languageDictionary = domainBrand.domainBrand["languageDictionary"];
  const languageDictionaryStatus = !(
    typeof languageDictionary === "undefined" || languageDictionary === null
  );
  const [activeMethod, updateActiveMethod] = useState({});
  const [selectedWallet, setWallet] = useState("");
  const [filteredWallets, setFilteredWallets] = useState([]);
  const [checkBoxValue, updateCheckBoxValue] = useState(false);
  const [showModal, updateShowModal] = useState(false);
  const getApplicationNode = () => {
    return document.getElementById("root");
  };

  const PaymentLabels = {
    ONLINE_CARD_PAYMENT: "Card Payment",
    BANK_TRANSFER: "Bank Transfer",
    E_WALLET: [
      languageDictionaryStatus ? languageDictionary["WO_YOUR_ACCOUN_1659198121"] : "Wallet"
    ],
    CRYPTO_CURRENCY_PAYMENT: "Cryptocurrency Payment",
    CASH: "Cash Payment"
  };

  useEffect(() => {
    if (location.state) dispatch(fetchPaymentMethods(location.state));
  }, [location.state]);

  useEffect(async () => {
    if (wallets.length < 1) await dispatch(getAccountBalances());
    setFilteredWallets(wallets);
  }, [wallets]);

  useEffect(() => {
    let newArray = [];
    const { $accountNumber, $referrer } = location.state;
    const isFundWallet = $referrer === "fundWallet";
    if (isFundWallet && wallets.length > 0) {
      wallets.forEach((element) => {
        if (element["walletAccountNumber"] !== $accountNumber) newArray.push(element);
      });
      setFilteredWallets(newArray);
    }
  }, [wallets]);

  useEffect(() => {
    if (
      paymentMethodStatus === Loading.SUCCESS &&
      paymentMethod["paymentMethodsMetadata"] &&
      paymentMethod["paymentMethodsMetadata"].length > 0
    ) {
      const { paymentMethodsMetadata } = paymentMethod;
      updateActiveMethod(paymentMethodsMetadata[0]);
    }
  }, [paymentMethodStatus]);

  if (!location.state) return <Redirect to="/transfer/history" />;

  const navigateToAccountStatement = () => {
    history.push("/account/statement");
  };

  const navigateToTransfer = () => {
    history.push("/transfer/history");
  };

  const makeWalletPayment = () => {
    const { parameters, "total-amount": totalCheckOutAmount } = activeMethod;
    const obj = {
      payAbleId: location.state.payAbleId,
      accountNumber: selectedWallet.walletAccountNumber,
      accountId: selectedWallet.id,
      paymentGatewayRef: parameters.paymentGatewayRef,
      payableType: parameters.payableType,
      password: "password",
      totalCheckOutAmount: parseFloat(totalCheckOutAmount)
    };
    dispatch(initiateWalletPayment(obj, navigateToTransfer, location.state.currency));
  };

  const makeCardPayment = () => {
    const { $accountNumber, $accountName, $referrer, $currency } = location.state;
    const domainUrl = getBaseUrl();
    const originUrl = window.location.origin;
    const payableType = location.state["payableType"];
    const isFundWallet = $referrer === "fundWallet";

    const redirectUrl = isFundWallet
      ? `&callbackurl=${originUrl}/account/statement?payabletype=${payableType}`
      : `&callbackurl=${originUrl}/transfer/details?payabletype=${payableType}`;

    const finalLink = activeMethod["hosted_gateway_url"];
    const paymentUrl = `${domainUrl}${finalLink}${redirectUrl}`;
    if (isFundWallet)
      Cookies.set(
        "recentPayment",
        JSON.stringify({
          $accountNumber,
          $accountName,
          $currency,
          $referrer
        })
      );
    window.location.href = paymentUrl;
  };

  const PaymentComponents = (dataObject) => ({
    BANK_TRANSFER: <BankTransfer dataObject={dataObject} />,
    E_WALLET: (
      <Wallet
        theme={theme}
        dataObject={dataObject}
        wallets={filteredWallets}
        wallet={setWallet}
        code={profile.userData["customerCode"]}
      />
    )
  });

  const buttonMethod = {
    ONLINE_CARD_PAYMENT: makeCardPayment,
    E_WALLET: makeWalletPayment,
    BANK_TRANSFER: navigateToTransfer
  };

  const updateMethod = (method) => {
    updateActiveMethod(method);
  };

  const { currency, paymentTermsNConditions } = paymentMethod;
  const sendingAmount = location.state && location.state.sendAmount;
  const ReceivingAmount = location.state && location.state.amount;
  const receivingCurrency = location.state && location.state.receivingCurrency;

  const paymentMethodDropdown =
    typeof paymentMethod["paymentMethodsMetadata"] !== "undefined"
      ? setPaymentMethodDropdown(paymentMethod["paymentMethodsMetadata"], PaymentLabels)
      : [];

  return (
    <>
      <Helmet>
        <title>
          {languageDictionaryStatus ? languageDictionary["l_payment_details"] : "Payment Details"}
        </title>
      </Helmet>
      <HeadingText
        text={
          languageDictionaryStatus
            ? languageDictionary["para_choose_your_payment_method"]
            : "Choose your payment method"
        }
      />
      <div css={bodyWrapper}>
        {paymentStatus === Loading.FETCHING || paymentMethodStatus === Loading.FETCHING ? (
          <Spinner containerWidth="100%" containerHeight="50vh" />
        ) : (
          <>
            <section css={leftSectionWrapper}>
              {paymentMethod["paymentMethodsMetadata"]
                ? paymentMethod["paymentMethodsMetadata"].map((method, index) => (
                    <div
                      css={css`
                        display: flex;
                        margin-top: 20px;
                      `}>
                      <PaymentCard
                        key={index}
                        paymentMethod={method}
                        onChangeActiveMethod={() => updateMethod(method)}
                        type={method.type}
                        icon={PaymentIcons[method.type]}
                        currency={currency}
                        theme={theme}
                        selectIcon={activeMethod["type"] === method.type}
                        wallets={filteredWallets}
                        text={PaymentLabels[method.type]}
                        prefix={PaymentTexts[method.type]}
                        muted={method["payment-method-fee"]}
                        activeMethod={activeMethod}
                      />
                    </div>
                  ))
                : null}
            </section>

            <section css={rightSectionWrapper}>
              <MethodTypeWrapper>
                <Select
                  noOptionsMessage={() => "No Payment method added"}
                  value={activeMethod}
                  options={paymentMethodDropdown}
                  styles={customStyles}
                  isClearable={false}
                  isSearchable={false}
                  customTheme={theme}
                  placeholder={
                    languageDictionaryStatus
                      ? languageDictionary["WO_CHOOSE_OPTIO_-243822441"]
                      : "Select an option"
                  }
                  components={{
                    IndicatorSeparator: null,
                    DropdownIndicator: () => <DropDownComponent iconSource={downArrow} />
                  }}
                  onChange={(option) => {
                    updateActiveMethod(option);
                  }}
                />
              </MethodTypeWrapper>

              <AmountDetailWrapper>
                <div css={textWrapperStyle}>
                  <p>
                    {languageDictionaryStatus
                      ? languageDictionary["para_total_to_pay"]
                      : "Total to Pay"}
                  </p>
                  <p className="value">
                    {activeMethod["total-amount"] &&
                      Number(activeMethod["total-amount"]).toFixed(2)}{" "}
                    {currency}
                  </p>
                </div>
                <div css={textWrapperStyle}>
                  <p className="prefix">
                    {languageDictionaryStatus
                      ? languageDictionary["l_sending_amount"]
                      : "Sending Amount"}
                    :
                  </p>
                  <p className="suffix">
                    {sendingAmount && Number(sendingAmount).toFixed(2)} {currency}
                  </p>
                </div>
                <div css={textWrapperStyle}>
                  <p className="prefix">
                    {languageDictionaryStatus ? languageDictionary["VA_-1896798840"] : "VAT"}:
                  </p>
                  <p className="suffix">
                    {parseFloat(activeMethod["tax"]).toFixed(2)} {currency}
                  </p>
                </div>
                <div css={textWrapperStyle}>
                  <p className="prefix">
                    {languageDictionaryStatus
                      ? languageDictionary["l_total_comm"]
                      : "Total commission"}
                    :
                  </p>
                  <p className="suffix">
                    {parseFloat(activeMethod["commission"]).toFixed(2)} {currency}
                  </p>
                </div>
                <div css={textWrapperStyle}>
                  <p className="prefix">
                    {languageDictionaryStatus
                      ? languageDictionary["para_payment_fee"]
                      : "Payment Fee"}
                    :
                  </p>
                  <p className="suffix">
                    {activeMethod["payment-method-fee"] &&
                      Number(activeMethod["payment-method-fee"]).toFixed(2)}{" "}
                    {currency}
                  </p>
                </div>
                <Separator />
                <div css={textWrapperStyle}>
                  <p className="prefix">
                    {languageDictionaryStatus
                      ? languageDictionary["para_your_beneficiary_receives"]
                      : "Your beneficiary receives"}
                    :
                  </p>
                  <p className="value">
                    {ReceivingAmount && Number(ReceivingAmount).toFixed(2)} {receivingCurrency}
                  </p>
                </div>

                {PaymentComponents({ ...activeMethod, currency })[activeMethod.type]}
              </AmountDetailWrapper>

              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                  margin-top: 2rem;
                `}>
                <div>
                  <CheckBox
                    checkboxName="tAndC"
                    checkboxId="GeneralTerms"
                    checkboxValue={checkBoxValue}
                    checkboxOnChange={(e) => updateCheckBoxValue(e.target.checked)}
                    content={
                      <CheckBoxContent checkboxId="GeneralTerms" link={paymentTermsNConditions} />
                    }
                  />
                </div>
                <div
                  css={css`
                    width: 60%;
                  `}>
                  <CustomButton
                    buttonText={
                      languageDictionaryStatus
                        ? languageDictionary["WO_CLICK_HERE_TO_CONTIN_297369385"]
                        : "Click here to continue"
                    }
                    buttonWidth="100%"
                    disabled={!checkBoxValue}
                    onClick={buttonMethod[activeMethod.type]}
                  />
                </div>
              </div>
            </section>

            {showModal && (
              <AriaModal
                titleText="transfer details"
                onExit={() => updateShowModal(false)}
                getApplicationNode={getApplicationNode}
                verticallyCenter={true}
                underlayStyle={{
                  backdropFilter: "blur(28px)",
                  background: "none"
                }}>
                <ModalContent
                  closeModal={() => {
                    updateShowModal(false);
                    history.push("/transfer/details");
                  }}
                />
              </AriaModal>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default PaymentMethod;

import { combineReducers } from "redux";
import accountReducer from "./account.reducer";
import airtimeReducer from "./airtime.reducer";
import authReducer from "./auth.reducer";
import bankAccountReducer from "./bankAccount.reducer";
import beneficiaryReducer from "./beneficiary.reducer";
import billReducer from "./bill.reducer";
import cartReducer from "./cart.reducer";
import categoryReducer from "./category.reducer";
import complianceReducer from "./compliance.reducer";
import countryReducer from "./country.reducer";
import documentReducer from "./document.reducer";
import merchantReducer from "./merchant.reducer";
import mobileMoneyReducer from "./mobileMoney.reducer";
import notificationReducer from "./notification.reducer";
import profileReducer from "./profile.reducer";
import themeReducer from "./theme.reducer";
import transferReducer from "./transfer.reducer";
import userReducer from "./user.reducer";
import domainBrandReducer from "./domain-brand.reducer";
import multiLingualReducer from "./multilingual.reducer";
import cashPickupReducer from './cashPickup.reducer'
import dynamicFieldsReducer from "./dynamicFields.reducer";

const rootReducer = combineReducers({
  accountReducer,
  airtimeReducer,
  authReducer,
  bankAccountReducer,
  beneficiaryReducer,
  billReducer,
  categoryReducer,
  cartReducer,
  complianceReducer,
  countryReducer,
  documentReducer,
  dynamicFieldsReducer,
  mobileMoneyReducer,
  merchantReducer,
  notificationReducer,
  profileReducer,
  transferReducer,
  themeReducer,
  userReducer,
  domainBrandReducer,
  multiLingualReducer,
  cashPickupReducer
});

export default rootReducer;

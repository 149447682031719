import React from "react";
import { useSelector } from "react-redux";

import NavBar from "../../../../Components/Navbar";
import BackArrow from "../../../../Components/BackArrow";
import HeadingText from "../../../../Components/HeadingText";
import SubHeadingText from "../../../../Components/SubHeadingText";
import DashboardCard from "../../../../Components/DashboardCard";
import Footer from "../../../../Components/Footer";

import transferIcon from "Assets/svg/transfer.svg";
import offSetImg from "../../../../Assets/svg/transfer1.svg";
import "../Routes/index.scss";

const TransferTypes = () => {
  const domainBrand = useSelector(({ domainBrandReducer }) => domainBrandReducer);
  const languageDictionary = domainBrand.domainBrand["languageDictionary"];
  const languageDictionaryStatus =
    typeof languageDictionary === "undefined" || languageDictionary === null;
  const nameValuePrefix = languageDictionaryStatus
    ? languageDictionary["WO_TRANSFER_TO_123558816"]
    : "Transfer to a ";
  const nameValueSuffix = languageDictionaryStatus
    ? languageDictionary["WO_ACCOUN_93052003"]
    : " Account";

  return (
    <div className="fdt_body">
      <NavBar />
      <main className="fdt_main">
        <div className="fdt__canvasImg">
          <img src={offSetImg} alt="off canvas icon" />
        </div>
        <section className="fdt_topSection">
          <div className="d-flex">
            <BackArrow />
            <HeadingText text={"Transfer Modes"} />
          </div>
          <div className="fdt_subHeading">
            <SubHeadingText text={"Select a transfer method"} />
          </div>
          <div className="row g-0">
            <DashboardCard
              key={1}
              to={"/transfer"}
              icon={transferIcon}
              count={2}
              text={
                languageDictionaryStatus
                  ? languageDictionary["WO_TRANSFER_TO_EXTERNAL_-330691986"]
                  : "Transfer to external bank"
              }
            />
            <DashboardCard
              key={2}
              to={"transfer/account-to-account"}
              icon={transferIcon}
              count={2}
              text={nameValuePrefix + domainBrand["domainBrand"]["companyName"] + nameValueSuffix}
            />
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default TransferTypes;

import * as Cart from "Store/constants/cart.constants";

const initialState = {
  items: [],
  loading: false,
  loadingStatus: "",
  cartLoadingStatus: "",
  paymentInfo: {},
};

const addToCart = (product, items) => {
  const item = items.find((item) => item.product_id === product.product_id);

  if (!item) {
    return [...items, { ...product, quantity: 1 }];
  }

  return items.map((p) => {
    if (p.product_id === item.product_id) {
      return { ...p, quantity: p.quantity + 1 };
    }
    return p;
  });
};

const CartReducer = (state = initialState, action) => {
  switch (action.type) {
    case Cart.ADD_TO_CART_REQUEST:
      return {
        ...state,
        loading: true,
        loadingStatus: "FETCHING",
      };
    case Cart.ADD_TO_CART_SUCCESS:
      return {
        ...state,
        loading: false,
        loadingStatus: "SUCCESS",
        items: addToCart(action.payload, state.items),
      };
    case Cart.ADD_TO_CART_FAILURE:
      return {
        ...state,
        loading: false,
        loadingStatus: "ERROR",
      };
    case Cart.GET_ITEMS_IN_CART_REQUEST:
      return {
        ...state,
        loading: true,
        cartLoadingStatus: "FETCHING",
      };
    case Cart.GET_ITEMS_IN_CART_SUCCESS:
      return {
        ...state,
        loading: false,
        cartLoadingStatus: "SUCCESS",
        items: action.payload,
      };
    case Cart.GET_ITEMS_IN_CART_FAILURE:
      return {
        ...state,
        loading: false,
        cartLoadingStatus: "ERROR",
      };
    case Cart.INCREMENT_QUANTITY_REQUEST:
      return {
        ...state,
        loading: true,
        loadingStatus: "FETCHING",
      };
    case Cart.INCREMENT_QUANTITY_SUCCESS:
      return {
        ...state,
        loading: false,
        loadingStatus: "SUCCESS",
        items: state.items.map((product) =>
          product.product_id === action.payload.productId
            ? { ...product, quantity: action.payload.quantity }
            : product
        ),
      };
    case Cart.INCREMENT_QUANTITY_FAILURE:
      return {
        ...state,
        loading: false,
        loadingStatus: "ERROR",
      };
    case Cart.DECREMENT_QUANTITY_REQUEST:
      return {
        ...state,
        loading: true,
        loadingStatus: "FETCHING",
      };
    case Cart.DECREMENT_QUANTITY_SUCCESS:
      return {
        ...state,
        loading: false,
        loadingStatus: "SUCCESS",
        items: state.items.map((product) =>
          product.product_id === action.payload.productId
            ? { ...product, quantity: action.payload.quantity }
            : product
        ),
      };
    case Cart.DECREMENT_QUANTITY_FAILURE:
      return {
        ...state,
        loading: false,
        loadingStatus: "ERROR",
      };
    case Cart.REMOVE_FROM_CART_REQUEST:
      return {
        ...state,
        loading: true,
        loadingStatus: "FETCHING",
      };
    case Cart.REMOVE_FROM_CART_SUCCESS:
      return {
        ...state,
        loading: false,
        loadingStatus: "SUCCESS",
        items: state.items.filter((product) => product.product_id !== action.payload),
      };
    case Cart.REMOVE_FROM_CART_FAILURE:
      return {
        ...state,
        loading: false,
        loadingStatus: "ERROR",
      };
    case Cart.INITIATE_CART_PAYMENT_REQUEST: 
      return {
        ...state,
        loading: true,
        loadingStatus: "FETCHING",
      };
    case Cart.INITIATE_CART_PAYMENT_SUCCESS: 
      return {
        ...state,
        loading: false,
        loadingStatus: "SUCCESS",
        paymentInfo: action.payload,
      };
    case Cart.INITIATE_CART_PAYMENT_FAILURE:
      return {
        ...state,
        loadingStatus: "ERROR",
      };  
    default:
      return state;
  }
};
export default CartReducer;

// eslint-disable-next-line no-unused-vars
import React from 'react';
/** @jsx jsx */
/** @jsxRuntime classic */

import { jsx } from '@emotion/react';
import NavBar from 'Components/Navbar';
import Footer from 'Components/Footer';
import {
  contactContainer,
  contactMain,
  contactCard,
  mainContainer,
  mainHeading,
  contactCircleTwo,
  contactCircleOne,
  contactCardTitle,
  contactCardText,
} from './style';

const Contact = () => {
  return (
    <div css={contactContainer}>
      <NavBar />
      <main css={contactMain}>
        <div css={mainContainer}>
          <h2 css={mainHeading}>Get in touch with us</h2>

          <div css={contactCard}>
            <div>
              <p css={contactCardTitle}>Contact Us</p>
              <p css={contactCardText}>3rd Floor, 86-90 Paul Street,</p>
              <p css={contactCardText}>London, EC2A 4NE</p>
              <p css={contactCardText}>United Kingdom</p>
              <p css={contactCardText}>+44 (0)20 8720 7109</p>
              <p css={contactCardText}>info@spotbanc.com</p>
            </div>

            <div css={contactCircleOne} />
            <div css={contactCircleTwo} />
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default Contact;

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import Select from "react-select";
import DropDownComponent from "Components/DropDownComponent";
import styles from "./index.module.scss";
import dashboardIcon from "Assets/svg/dashboard.svg";
import transferIcon from "Assets/svg/transfer.svg";
import userIcon from "Assets/svg/man-user.svg";
import notificationIcon from "Assets/svg/notification.svg";
import customStyles from "Assets/styles/dropdown/customStyles";
import accountIcon from "Assets/svg/account-icon.svg";
import downArrowIcon from "Assets/svg/down-arrow.svg";
import downArrow from "Assets/svg/blue-down-arrow.svg";
import { logUserOut } from "Store/actions/auth.action";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import * as Loading from "Store/constants/loadingState.constants";
import qrCodeWhite from "Assets/svg/qr-code-white.svg";
import avatar from "Assets/images/gravatar.png";
import { fetchHostURL } from "../../Utils/Helpers/general";
import * as DomainBrand from "Store/actions/domain-brand.action";
import cookieHelper from "Utils/Helpers/cookieHelper";
import config from "Utils/Helpers/config";

function isLinkActive(path) {
  return new URL(window.location).pathname.startsWith(path);
}

const Dropdown = ({ navItems, smallSize, switchMethod, isButton, logOutMethod, className }) => {
  const LinkElement = ({ item }) => {
    if (isButton) return <button onClick={() => switchMethod(item.name)}>{item.name}</button>;
    else if (item.isLogOut) return <button onClick={logOutMethod}>{item.name}</button>;
    else return <Link to={item.link}>{item.name}</Link>;
  };

  return (
    navItems && (
      <ul
        className={`${styles.navBar__dropdown} ${
          smallSize ? styles.navBar__dropdown__small : styles.navBar__dropdown__large
        } ${className ? className : ""}`}>
        {React.Children.toArray(
          navItems.map((item, index) => {
            return (
              <li key={index} className={`${item.active ? styles.active : null}`}>
                <LinkElement item={item} />
              </li>
            );
          })
        )}
      </ul>
    )
  );
};

const ProfileAvatar = ({ userData, dispatch, domainBrandStatus, languages, logOutUrl }) => {

  const profileImage = typeof userData.profileImage === "undefined" || userData.profileImage === null ? avatar : userData.profileImage.replace("?type=profile", "");

  return (
    <div className={styles.navBar__rightSection__avatar}>
      <span>
        <img src={profileImage} alt="user" />
      </span>
      <span>
        <img src={downArrowIcon} alt="right arrow" />
      </span>
      <Dropdown
        logOutMethod={() => dispatch(logUserOut(logOutUrl))}
        navItems={[
          {
            name: domainBrandStatus ? languages["para_profile"] : "Profile",
            link: "/profile"
          },
          {
            name: domainBrandStatus ? languages["WO_CONTACT_SUPPOR_-1552316922"] : "Contact Support",
            link: "/profile/notifications"
          },
          {
            name: domainBrandStatus ? languages["m_change_password"] : "Change Password",
            link: "/change-password"
          },
          {
            name: domainBrandStatus ? languages["button_logout"] : "Log out",
            isLogOut: true
          }
        ]}
      />
    </div>
  );
};

const Cart = ({ cartCount }) => {
  return (
    <Link to="/cart" className="text-decoration-none btn">
      <div className="position-relative">
        <div className="position-absolute bottom-0 start-0">
          <span className="cart-items">{cartCount}</span>
        </div>
        <svg viewBox="0 0 20 20" fill="#fff" width="26px">
          <path
            d="M3 1a1 1 0 000 2h1.22l.305 1.222a.997.997 0 00.01.042l1.358 5.43-.893.892C3.74 11.846 4.632 14 6.414 14H15a1 1 0 000-2H6.414l1-1H14a1 1 0 00.894-.553l3-6A1 1 0 0017 3H6.28l-.31-1.243A1 1 0 005 1H3zM16 16.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM6.5 18a1.5 1.5 0 100-3 1.5 1.5 0 000 3z" />
        </svg>
      </div>
    </Link>
  );
};

const NavBar = () => {
  const dispatch = useDispatch();
  const cookieLanguage = cookieHelper.get(config.LANGUAGE_DATA);
  const checkTransferModeStatus = cookieHelper.get(config.TRANSFER_MODE_STATUS);
  const [languageField, updateLanguageField] = useState(cookieLanguage);
  const { cart, profile, domainBrand } = useSelector(
    ({ cartReducer, profileReducer, domainBrandReducer }) => ({
      cart: cartReducer,
      profile: profileReducer,
      domainBrand: domainBrandReducer
    })
  );
  const { userData } = profile;
  const cartCount = cart.items.reduce((acc, item) => acc + item.quantity, 0);
  const domainBrandStatus = domainBrand.loading === Loading.SUCCESS;
  const checkMerchantPaymentStatus = cookieHelper.get(config.MERCHANT_PAYMENT_STATUS);
  const languageDictionary = domainBrand.domainBrand["languageDictionary"];
  const languageDictionaryStatus = !(typeof languageDictionary === "undefined" || languageDictionary === null);
  const headerRenderStatus = domainBrand["domainBrand"]["header"] === "" || domainBrand["domainBrand"]["header"] === null;
  const headerRender = domainBrand["domainBrand"]["header"];
  let fetchedLanguages = domainBrand.domainBrand["language"];

  const createMarkup = () => {
    return { __html: headerRender };
  };

  const handleLanguageChange = (value) => {
    updateLanguageField(value);
    const domainURL = fetchHostURL();
    const subdomain = domainURL.split(".")[0];
    cookieHelper.set(config.LANGUAGE_DATA, value, config.COOKIE_EXPIRY_PERIOD);
    dispatch(DomainBrand.getDomainBrandIdentity(subdomain, value["id"]));
  };

  const transferArray = [{
    name: languageDictionaryStatus ? languageDictionary["WO_TRANSFER_HISTOR_57573459"] : "Transfer History",
    link: "/transfer/history"
  }];

  if (checkTransferModeStatus !== null && checkTransferModeStatus.transferFunds === "ENABLED"
    && checkTransferModeStatus.wallet === "ENABLED") {
    transferArray.push({
      name: languageDictionaryStatus ? languageDictionary["WO_TRANSFER_FUND_1805465128"] : "Transfer Funds",
      link: "/transfer-modes"
    });
  }

  if (checkTransferModeStatus !== null && checkTransferModeStatus.transferFunds === "ENABLED"
    && checkTransferModeStatus.wallet === "DISABLED") {
    transferArray.push({
      name: languageDictionaryStatus ? languageDictionary["WO_TRANSFER_FUND_1805465128"] : "Transfer Funds",
      link: "/transfer"
    });
  }

  if (checkTransferModeStatus !== null && checkTransferModeStatus.transferFunds === "DISABLED"
    && checkTransferModeStatus.wallet === "ENABLED") {
    transferArray.push({
      name: languageDictionaryStatus ? languageDictionary["WO_TRANSFER_FUND_1805465128"] : "Transfer Funds",
      link: "/transfer/account-to-account"
    });
  }

  return (
    <>
      {!headerRenderStatus ? <div dangerouslySetInnerHTML={createMarkup()} /> :
        <Navbar collapseOnSelect expand="lg" className={`${styles.navBar}`}>
          <div className="container-fluid">
            <Navbar.Brand>
              <Link to="/dashboard" className="d-none d-xl-block">
                <img
                  src={domainBrandStatus ? domainBrand["domainBrand"]["logo"] : "MultiView MTA"}
                  alt={domainBrandStatus ? domainBrand["domainBrand"]["companyName"] : "MultiView MTA"}
                  className="img-fluid"
                  style={{ width: "100px" }}
                />
              </Link>
              <Link to="/dashboard" className="d-xl-none">
                <img
                  src={domainBrandStatus ? domainBrand["domainBrand"]["logo"] : "MultiView MTA"}
                  alt="logo"
                  className="img-fluid"
                  style={{ width: "80px" }}
                />
              </Link>
            </Navbar.Brand>

            <div className="d-lg-none ml-auto mr-2">
              <ProfileAvatar userData={userData} dispatch={dispatch} displayName={false}
                             logOutUrl={domainBrand.domainBrand['publicWebSite']}
                             domainBrandStatus={domainBrandStatus}
                             languages={domainBrand.domainBrand.languageDictionary} />
            </div>

            {checkMerchantPaymentStatus === "ENABLED" ?
              <div className="d-lg-none mr-2">
                <Cart cartCount={cartCount} />
              </div> : ""
            }

            <Navbar.Toggle aria-controls="responsive-navbar-nav" className="navbar-toggler" />

            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className={styles.navBar__menu} role="navigation">
                <ul className="mt-lg-3">
                  <li>
                <span>
                  <img src={dashboardIcon} alt="" />
                </span>
                    <NavLink to="/dashboard" className="mr-4" exact activeClassName="active">
                      {languageDictionaryStatus ? languageDictionary["l_dashboard"] : "Dashboard"}
                    </NavLink>
                  </li>

                  {checkTransferModeStatus !== null && checkTransferModeStatus.wallet === "ENABLED" ?
                    <li>
                      <span><img src={accountIcon} alt="" className="img-fluid" /></span>
                      <NavLink to="#" activeClassName={isLinkActive("/account") ? "active" : ""}>
                        {languageDictionaryStatus ? languageDictionary["para_account"] : "Account"}
                      </NavLink>
                      <span><img src={downArrowIcon} alt="right arrow" /></span>
                      <Dropdown
                        navItems={[
                          {
                            name: languageDictionaryStatus ? languageDictionary["WO_ACCOUNT_BALANCE_960871353"] : "Account Balance",
                            link: "/account/balance"
                          },
                          {
                            name: languageDictionaryStatus ? languageDictionary["l_wallet_transaction_history"] : "Account Statement",
                            link: "/account/statement"
                          },
                          {
                            name: languageDictionaryStatus ? languageDictionary["l_fund_wallet"] : "Fund Account",
                            link: "/account/fund-account"
                          }
                        ]}
                      />
                    </li> : ""
                  }

                  <li>
                    <span><img src={transferIcon} alt="" /></span>
                    <NavLink to="#" activeClassName={isLinkActive("/transfer") ? "active" : ""}>
                      {languageDictionaryStatus ? languageDictionary["m_wallet_fund_transfer"] : "Transfer"}
                    </NavLink>
                    <span><img src={downArrowIcon} alt="right arrow" /></span>
                    <Dropdown navItems={transferArray} />
                  </li>

                  {profile["userData"]["userType"] === "CASHIER" ? "" :
                    <li>
                      <span><img src={userIcon} alt="" /></span>
                      <NavLink to="/beneficiaries" activeClassName="active">
                        {languageDictionaryStatus ? languageDictionary["para_beneficiary"] : "Beneficiaries"}
                      </NavLink>
                    </li>
                  }

                  {checkMerchantPaymentStatus === "ENABLED" ?
                    <li>
                      <span><img src={qrCodeWhite} alt="scan-icon" /></span>
                      <NavLink to="/scan-and-pay" activeClassName="active">
                        {languageDictionaryStatus ? languageDictionary["WO_SCAN_AND_PA_-1356185446"] : "Scan and Pay"}
                      </NavLink>
                    </li> : ""
                  }

                </ul>
              </Nav>

              <Nav className={styles.navBar__rightSection}>
                <div className={styles.navBar__rightSection__notification}>
                  <Link to="/profile/notifications">
                    <img src={notificationIcon} alt="" />
                  </Link>
                </div>

                <div className="d-none d-lg-block mr-3">
                  <ProfileAvatar userData={userData} dispatch={dispatch} domainBrandStatus={domainBrandStatus}
                                 logOutUrl={domainBrand.domainBrand['publicWebSite']}
                                 languages={domainBrand.domainBrand.languageDictionary} />
                </div>

                {checkMerchantPaymentStatus === "ENABLED" && (
                  <div className="ml-md-2 mt-4 mt-lg-0 d-none d-lg-block mr-4">
                    <Cart cartCount={cartCount} />
                  </div>
                )}

                <div className={styles.navBar__rightSection__dropdown}>
                  <Select
                    options={fetchedLanguages}
                    isClearable={false}
                    isSearchable={true}
                    customTheme={""}
                    styles={customStyles}
                    placeholder={"ENGLISH"}
                    value={languageField}
                    onChange={(option) => {
                      handleLanguageChange(option);
                    }}
                    components={{
                      IndicatorSeparator: null,
                      DropdownIndicator: () => <DropDownComponent iconSource={downArrow} />
                    }}
                  />
                </div>
              </Nav>
            </Navbar.Collapse>
          </div>
        </Navbar>
      }
    </>
  );
};

export default NavBar;

import React from "react";
import DropDownComponent from "../DropDownComponent";
import Select from "react-select";

import customStyles from "Assets/styles/dropdown/customStyles";
import downArrow from "../../Assets/svg/blue-drop-down-arrow.svg";
import { dynamicFieldsDropdown } from "../../Utils/Helpers/general";
import { selectWrapper } from "../../Pages/Auth/SignUp/style";
import { Input } from "../../Pages/MultiView/BillPayment/BillPaymentDetails/style";

const DynamicFields = ({ divClass, eachField, theme, formik }) => {
  let renderState;
  renderState = (eachField["inputType"] === "DROP_DOWN") ? DROPDOWN({
    divClass,
    eachField,
    theme,
    formik
  }) : (eachField["inputType"] === "FREE_TEXT_SINGLE_LINE") ?
    INPUT({ divClass, eachField, formik }) : INPUT({ divClass, eachField, formik });
  return (
    <> {renderState} </>
  );
};

const DROPDOWN = ({ divClass, eachField, theme, formik }) => {
  const dropdownValues = dynamicFieldsDropdown(eachField["dropDown"]);
  let key = eachField["key"];

  return (
    <div css={selectWrapper} className={divClass}>
      <Select
        options={dropdownValues}
        value={formik.values.key}
        onChange={option => formik.setFieldValue(key, option["value"])}
        // onBlur={() => formik.setFieldTouched(key, true)}
        customTheme={theme}
        styles={customStyles}
        isClearable={false}
        isSearchable={false}
        placeholder={eachField["label"]}
        components={{
          IndicatorSeparator: null,
          DropdownIndicator: () => <DropDownComponent iconSource={downArrow} />
        }}
      />
    </div>
  );
};

const INPUT = ({ divClass, eachField, formik }) => {
  let key = eachField["key"];
  return (
    <div className={divClass}>
      <p className="text-blue">{eachField["label"]}</p>
      <Input
        name={eachField["key"]}
        type={eachField["inputFieldValueDataType"]}
        className="form-control"
        minLength={eachField["minCharacterLen"]}
        maxLength={eachField["maxCharacterLen"]}
        value={formik.values[key]}
        onChange={formik.handleChange}
        placeholder={eachField["label"]}
      />
    </div>
  );
};

export default DynamicFields;

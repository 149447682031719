import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const MainCompliance = styled.div`
  padding-top: 2.8rem;
  margin-left: 15rem;
  margin-right: 15rem;

  @media screen and (max-width: 1140px) {
    padding: 2.8rem 1rem 1rem;
    margin: 0
  }
`;
export const mainBody = css`
  border: 1px solid #e3edf3;
  border-radius: 10px;
  background: #ffffff;
  padding: 30px 40px;
  margin-top: 30px;
  margin-bottom: 50px;
`;

export const P = styled.p`
  line-height: 1;
  font-size: 1.5625rem;
  color: ${(props) => props.theme.primaryColor};
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const selectWrapper = css`
  margin-bottom: 30px;
`;

export const selectDropdownContainer = css`
  max-width: 47.5%;
  flex-basis: 47.5%;
`;

export const buttonWrapper = css`
  display: flex;
  justify-content: flex-end;
`;


export const actionWrapper = css`
  display: flex;
  justify-content: flex-start;
`;

export const divider = css`
margin-top: 10px;
  border-top: 6px solid blue;
  border-radius: 5px;
`;


export const uploadContainerHeader = css`
  border-bottom: 2px solid #90a8be6b;
  padding-bottom: 25px;
  margin-bottom: 15px;
  display: grid;
  grid-template-columns: auto auto;
  padding: 10px;
  padding-bottom: 20px; 
`;


export const uploadContainerDiv = css`
  border-bottom: 2px solid #90a8be6b;
  padding-bottom: 25px;
  margin-bottom: 15px;
  display: grid;
  grid-template-columns: auto auto;
  padding: 10px;

  @media screen and (max-width: 992px) {
    display: column;
  }
`;




export const leftCol = css`
  border-right: 2px solid #90a8be6b;
  padding-bottom: 25px;
  margin-bottom: 15px;
  display: grid;
  padding: 10px;
  padding-left: 0px;
  color: #14a3de;
  // width: 200px;
`;


export const rightCol = css`
  padding-bottom: 25px;
  margin-bottom: 15px;
  display: grid;
  grid-template-columns: auto auto;
  padding: 10px; 
  padding-left: 30px;
`;

export const emailText = css`
  color: #14a3de;
`;

export const textContainer = css`
  text-align: center;
  padding: 20px;
`;



export const uploadLabel = (theme) => css`
  display: flex;
  flex-direction: column;
  color: ${theme.primaryColor};
  font-size: 16px;
  font-weight: 500;
`;


export const attachButtonContainer = css`
  display: flex;
  justify-content: flex-end;
`;

export const modalContainer = css`
  background: white;
  border: 1px solid #1149835b;
  min-height: 70vh;
  max-height: 80vh;
  border-radius: 20px;
  display: flex;
  padding: 25px 30px;
  width: 50vw;
  flex-direction: column;
  overflow-y: auto;

  @media screen and (max-width: 992px) {
    width: 100vw;
  }
`;

export const modalContainerHeading = css`
  font-size: 20px;
  color: #90a8be;
  font-weight: 400;
  padding-bottom: 10px;
  margin-bottom: 15px;
  border-bottom: 1px solid #1149835b;
`;


export const fileUploadInput = css`
  overflow: visible;
  width: 100%;
  border: 0;
  background: #e3edf3;
  border-radius: 5px;
  padding: 15px 20px;
  font-size: 16px;
  font-weight: 400;
  color: #14a3de;
  font-weight: 300;
  margin-top: 12px;
`;
export const fileUploadLabel = css`
  width: 100%;
`;


export const modalFormColumn = css`
  flex-basis: 47.5%;
  max-width: 47.5%;
`;

export const modalFormDatePicker = css`
  margin-top: 12px;
  background: #e3edf3;
  border: 0;
  width: 100%;
  padding: 15px 10px;
  border-radius: 4px;
  height: 48px;
  color: var(--primary-color);
  font-size: 15px;
  font-weight: 300;
`;



export const tableStyle = (theme) => {
  return css`
    overflow-x: auto;
    margin-bottom: 30px;

    table {
      border-collapse: separate;
      border-spacing: 0 15px;
      width: 100%;
      border: 1px solid #e2e8ec;
      border-radius: 5px;
      background: #ffffff;
    }

    th {
      color: #90a8be;
      font-weight: 600;
      text-transform: uppercase;
      font-size: 12px;
      padding-top: 5px;
      padding-bottom: 5px;
      padding-left: 10px;
      padding-right: 10px;
    }

    td {
      text-align: center;
    }

    tbody {
      td {
        background: #e3edf3;

        span {
          display: block;
          margin-top: 24.5px;
          margin-bottom: 24.5px;
          border-right: 2px solid #90a8be47;
          font-size: 14px;
          color: ${theme.primaryColor};
          font-weight: 500;
          padding-left: 10px;
          padding-right: 10px;
        }

        &:nth-of-type(1) {
          span {
            font-weight: 400;
          }
        }

        &:last-of-type {
          span {
            /* color: #90a8be; */
          }
        }

        &:last-of-type {
          span {
            border-right: none;
          }
        }
      }
    }
  `;
};

export const modalFormContainer = css``;




export const modalFormRow = css`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  align-items: flex-end;
`;

export const uploadButton = css`
  text-align: center;
`;

export const noInvoiceText = css`
  color: #90a8be;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 30px;
`;


export const uploadContainer = css`
  margin-top: 30px;
`;
export const envMapper = {
  HOSTS: {
    id: 1,
    env: "moneytransferapplication.uk"
  },
  TEST: {
    id: 2,
    env: "moneytransferapplication.uk"
  },
  LIVE: {
    id: 3,
    env: "spotbanc.cloud"
  }
};

export const subDomainMapper = {
  HOSTS: {
    id: 1,
    SUBDOMAIN: "hosts",
    API_SUBDOMAIN: "test",
    API_ENV: envMapper.HOSTS
  },
  TEST: {
    id: 2,
    SUBDOMAIN: "test",
    API_SUBDOMAIN: "stag",
    API_ENV: envMapper.TEST
  },
  "FINCODE-LIVE": {
    id: 3,
    SUBDOMAIN: "fincode",
    API_SUBDOMAIN: "fincode",
    API_ENV: envMapper.LIVE
  },
  KOGOPAY: {
    id: 4,
    SUBDOMAIN: "kogopay",
    API_SUBDOMAIN: "kogopay",
    API_ENV: envMapper.TEST
  },
  "BANKING-LIVE": {
    id: 5,
    SUBDOMAIN: "kogopay",
    API_SUBDOMAIN: "kogobank",
    API_ENV: envMapper.LIVE
  },
  MUHASFINANCIAL: {
    id: 6,
    SUBDOMAIN: "muhasfinancial",
    API_SUBDOMAIN: "muhasfinancial",
    API_ENV: envMapper.TEST
  },
  HELVTICA: {
    id: 8,
    SUBDOMAIN: "helvtica",
    API_SUBDOMAIN: "helvtica",
    API_ENV: envMapper.TEST
  },
  DEMO: {
    id: 10,
    SUBDOMAIN: "demo",
    API_SUBDOMAIN: "demo",
    API_ENV: envMapper.TEST
  },
  WORFX: {
    id: 12,
    SUBDOMAIN: "worfx",
    API_SUBDOMAIN: "worfx",
    API_ENV: envMapper.TEST
  }
};

import React from 'react';
import styled from '@emotion/styled';

const MainBody = styled.div`
  border: 1px solid #e3edf3;
  border-radius: 10px;
  padding: 45px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  background: white;

  @media ( max-width: 56.25rem ) {
    padding: 0.5rem;
    flex-direction: column;
  }
`;

const MainItem = styled.div`
  flex-basis: 20%;
  max-width: 20.5%;

  h3 {
    font-size: 16px;
    font-weight: 500;
    color: ${(props) => props.theme.primaryColor};
    line-height: 1.6;
  }

  p {
    color: #0fcc98;
    font-size: 20px;
    font-weight: 500;
    text-transform: uppercase;
    line-height: 1.7;
  }

  @media ( max-width: 56.25rem ) {
    max-width: 100%;
  }
`;

/*const formatNum = (num) =>
  num
    ? Number(num).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    : '0.00';*/

const FxCard = ({
  currencySymbol,
  fxRate,
  tax,
  totalAmount,
  openingBalance,
  paymentFee,
}) => {
  return (
    <MainBody>
      <MainItem>
        <h3>From</h3>
        <p>
          {"12/09/20"}
        </p>
      </MainItem>
      <MainItem>
        <h3>To</h3>
        <p>
        {"12/09/20"}
        </p>
      </MainItem>
      <MainItem>
        <h3>Balance</h3>
        <p>
        {"100,000.00"}
        </p>
      </MainItem>
      <MainItem>
        <h3>Account Number</h3>
        <p>
        {"110009056"}
        </p>
      </MainItem>
      <MainItem>
        <h3>Deposit and other credits</h3>
        <p>
        {"----------"}
        </p>
      </MainItem>
    </MainBody>
  );
};

export default FxCard;

export const modifyActiveUser = (userList, userId, idKey) => {
  return userList.map((user) => {
    if (user[idKey] === userId) {
      user["status"] = "DEACTIVATED";
      return user;
    }
    return user;
  });
};

export const modifyInactiveUser = (userList, userId, idKey) => {
  return userList.map((user) => {
    if (user[idKey] === userId) {
      user["status"] = "ACTIVATED";
      return user;
    }
    return user;
  });
};

export const updateCancelledTrnx = (list, id, key) => {
  return list.map((transaction) => {
    if (transaction[key] === id) {
      transaction["display_status"] = "Cancelled";
      return transaction;
    }
    return transaction;
  });
};

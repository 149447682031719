import { toast } from "react-toastify";
import { ActionCreator } from "Utils/Helpers";
import * as User from "Store/constants/user.constants";
import {
  fetchUsers,
  createUser,
  fetchCustomerServiceTeam,
  activateUser,
  deactivateUser,
  customerSignUp,
  changePassword,
} from "Utils/Services/User.service";
import { expiredTokenCheck } from "./auth.action";
import ProfileService from "Utils/Services/Profile.service";

export const registerCustomer = (payload, navigate) => async (dispatch) => {
  try {
    dispatch(ActionCreator(User.CREATE_CUSTOMER_REQUEST));
    const { data: responseData } = await customerSignUp(payload);

    if (responseData.status === "FAILED") throw responseData;

    dispatch(ActionCreator(User.CREATE_CUSTOMER_SUCCESS, responseData.data));
    toast.success("Customer registered successfully. You can now log-in", {
      position: toast.POSITION.TOP_CENTER,
    });
    navigate();
  } catch (error) {
    dispatch(ActionCreator(User.CREATE_CUSTOMER_FAILURE));
    toast.error(`Signup Failed! ${error.message}`, {
      position: toast.POSITION.TOP_CENTER,
    });
    console.error(error);
  }
};

export const getUserList =
  (initialLoad = true) =>
  async (dispatch) => {
    try {
      if (initialLoad) dispatch(ActionCreator(User.GET_USERS_REQUEST));
      const { data: responseData } = await fetchUsers();

      if (responseData.status === "FAILED") throw responseData;
      const { data: { team } } = responseData;
      dispatch(ActionCreator(User.GET_USERS_SUCCESS, team));
    } catch (error) {
      expiredTokenCheck(error, dispatch);

      console.error(error, "error fetching users");
      dispatch(ActionCreator(User.GET_USERS_FAILURE));
    }
  };

export const createNewUser = (payload, navigate) => async (dispatch) => {
  try {
    dispatch(ActionCreator(User.CREATE_USER_REQUEST));
    const { data: responseData } = await createUser(payload);

    if (responseData.status === "FAILED") throw responseData;
    dispatch(ActionCreator(User.CREATE_USER_SUCCESS));
    toast.success("User Creation Successful", { position: toast.POSITION.TOP_CENTER, });
    navigate();
  } catch (error) {
    expiredTokenCheck(error, dispatch);
    console.error(error, "error creating user");
    toast.error(`${error.message}`, { position: toast.POSITION.TOP_CENTER });
    dispatch(ActionCreator(User.CREATE_USER_FAILURE));
  }
};

export const getCustomerServiceUsers = () => async (dispatch) => {
  try {
    dispatch(ActionCreator(User.GET_CUSTOMER_SERVICE_TEAM_REQUEST));
    const { data: responseData } = await fetchCustomerServiceTeam();

    if (responseData.status === "FAILED") {
      throw responseData;
    }

    const {
      data: { team },
    } = responseData;
    dispatch(ActionCreator(User.GET_CUSTOMER_SERVICE_TEAM_SUCCESS, team));
  } catch (error) {
    expiredTokenCheck(error, dispatch);

    console.error(error, "error fetching users");
    dispatch(ActionCreator(User.GET_CUSTOMER_SERVICE_TEAM_FAILURE));
  }
};

export const activateUserAccount =
  ({ userId }) =>
  async (dispatch) => {
    try {
      dispatch(ActionCreator(User.ACTIVATE_USER_REQUEST, { userId }));
      const { data: responseData } = await activateUser({ userId });

      if (responseData.status === "FAILED") {
        throw responseData;
      }

      dispatch(getUserList());
      dispatch(ActionCreator(User.ACTIVATE_USER_SUCCESS, { userId, responseData }));
    } catch (error) {
      expiredTokenCheck(error, dispatch);

      console.error(error, "error activating users");
      dispatch(ActionCreator(User.ACTIVATE_USER_FAILURE, { userId }));
    }
  };

export const deactivateUserAccount =
  ({ userId }) =>
  async (dispatch) => {
    try {
      dispatch(ActionCreator(User.DEACTIVATE_USER_REQUEST, { userId }));
      const { data: responseData } = await deactivateUser({ userId });

      if (responseData.status === "FAILED") {
        throw responseData;
      }

      dispatch(getUserList());
      dispatch(ActionCreator(User.DEACTIVATE_USER_SUCCESS, { userId, responseData }));
    } catch (error) {
      expiredTokenCheck(error, dispatch);

      console.error(error, "error deactivating users");
      dispatch(ActionCreator(User.DEACTIVATE_USER_FAILURE, { userId }));
    }
  };

export const updateUser = (payload, navigateAction) => async (dispatch) => {
  try {
    dispatch(ActionCreator(User.EDIT_USER_REQUEST));
    const { data: responseData } = await ProfileService.update(payload);

    if (responseData.status === "FAILED") {
      throw responseData;
    }

    toast.success("User update successful", {
      position: toast.POSITION.TOP_CENTER,
    });
    navigateAction();
    dispatch(ActionCreator(User.EDIT_USER_SUCCESS));
  } catch (error) {
    expiredTokenCheck(error, dispatch);
    dispatch(ActionCreator(User.EDIT_USER_FAILURE));
    toast.error(`Error, ${error.message}`, {
      position: toast.POSITION.TOP_CENTER,
    });
    console.error(error);
  }
};

export const changeUserPassword = (payload, navigate) => async (dispatch) => {
  try {
    dispatch(ActionCreator(User.CHANGE_PASSWORD_REQUEST, { loading: true }));
    const { data: responseData } = await changePassword(payload);

    if (responseData.status === "FAILED") throw responseData;

    toast.success("User password change successful", {
      position: toast.POSITION.TOP_CENTER,
    });
    dispatch(ActionCreator(User.CHANGE_PASSWORD_SUCCESS, { loading: false }));
    // dispatch(logUserOut());
    // navigate();
  } catch (error) {
    expiredTokenCheck(error, dispatch);
    dispatch(ActionCreator(User.CHANGE_PASSWORD_FAILURE, { loading: false }));
    toast.error(`User password change failed! ${error.message}`, {
      position: toast.POSITION.TOP_CENTER,
    });
    console.error(error);
  }
};

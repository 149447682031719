// eslint-disable-next-line no-unused-vars
import React, { useState } from 'react';
/** @jsx jsx */
/** @jsxRuntime classic */

import { jsx, css } from '@emotion/react';

import CheckBox from 'Components/CheckBox';
import CheckBoxContent from 'Components/CheckBoxContent/termsAndConditions';
import CustomButton from 'Components/CustomButton';


const containerStyle = css`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 20px;
  border: 1px solid #1149835b;
  border-radius: 10px;
  justify-content: center;
  align-items: center;

  @media (max-width: 56.25rem) {
    padding: 1.2rem;
    margin-bottom: 1rem;
  }
`;

const containerHeading = css`
  color: #000000af;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5;
  text-align: center;
  margin-bottom: 20px;
`;

const containerSubHeading = theme => css`
  color: ${theme.primaryColor};
  font-weight: 500;
  font-size: 1rem;
  text-align: center;
  margin-bottom: 20px;
`;

const buttonWrapper = css`
  width: 42%;

  @media (max-width: 56.25rem) {
    width: 100%;
    display: block;
   }
`;

const CardPayment = ({ submitAction }) => {
  const [checkBoxValue, updateCheckBoxValue] = useState(false);

  return (
    <div css={containerStyle}>
      <p css={containerHeading}>
        You'll be redirected to an external page <br /> to complete your payment
      </p>
      <p css={containerSubHeading}>Payment Terms and Conditions</p>
      <div>
        <CheckBox
          checkboxName="tAndC"
          checkboxId="CardPaymentTerms"
          checkboxValue={checkBoxValue}
          checkboxOnChange={e => updateCheckBoxValue(e.target.checked)}
          content={<CheckBoxContent checkboxId="CardPaymentTerms" />}
        />
      </div>
      <div css={buttonWrapper}>
        <CustomButton
          buttonText="Click here to continue"
          buttonWidth="100%"
          disabled={!checkBoxValue}
          onClick={submitAction}
        />
      </div>
    </div>
  );
};

export default CardPayment;

// eslint-disable-next-line no-unused-vars
import React from "react";
/** @jsx jsx */
/** @jsxRuntime classic */

import { jsx } from "@emotion/react";
import styled from "@emotion/styled";
import { useSelector } from "react-redux";
import CustomButton from "Components/CustomButton";

const ContentContainer = styled.div`
  background: white;
  border: 1px solid #1149835b;
  min-height: 50vh;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50vw;
  flex-direction: column;
`;

const MainHeading = styled.h1`
  text-align: center;
  margin: 0;
  font-weight: 600;
  font-size: 2rem;
  line-height: 1.35;
  margin-bottom: 1.5rem;
  color: ${(props) => props.theme.primaryColor};
`;

const SubHeading = styled.p`
  text-align: center;
  color: ${(props) => props.theme.primaryColor};
  font-weight: 400;
  font-size: 1.35rem;
  margin-bottom: 1rem;
`;

const SubHeadingTwo = styled.p`
  text-align: center;
  color: ${(props) => props.theme.primaryColor};
  font-size: 1rem;
  margin-bottom: 1.3rem;
  line-height: 1.4;
`;

const MoneyText = styled.span`
  color: #0fcc98;
  font-weight: 600;
`;

const ModalContent = () => {
  const { domainBrand } = useSelector(({ domainBrandReducer }) => ({
    domainBrand: domainBrandReducer,
  }));

  //  language state
  const languageDictionary = domainBrand.domainBrand["languageDictionary"];
  const languageDictionaryStatus = !(
    typeof languageDictionary === "undefined" || languageDictionary === null
  );

  return (
    <ContentContainer>
      <MainHeading>
        {languageDictionaryStatus
          ? languageDictionary["l_we_are_checking_the_details_of_your_payment"]
          : "We are checking the details of your payment"}
      </MainHeading>
      <SubHeading>
        {languageDictionaryStatus
          ? languageDictionary["para_what_happens_after_we_receive_your_payment"]
          : "What happens after we receive your payment"}
      </SubHeading>
      <SubHeadingTwo>
        {languageDictionaryStatus
          ? languageDictionary["para_after_we_receive_your_money"]
          : "After we receive your money,"}
        <MoneyText>12.93</MoneyText> <br />
        USD
        {languageDictionaryStatus
          ? languageDictionary["para_will_be_transferred_to"]
          : "will be transferred to"}
        your beneficiary.
      </SubHeadingTwo>
      <CustomButton buttonText="Transfer details" buttonWidth="25%" onClick={() => "closeModal"} />
    </ContentContainer>
  );
};

export default ModalContent;

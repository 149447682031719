// eslint-disable-next-line no-unused-vars
import React from 'react';
/** @jsx jsx */
/** @jsxRuntime classic */
import { jsx } from '@emotion/react';
import { Link } from 'react-router-dom';

import {
  resetContainer,
  leftSection,
  leftContainer,
  leftHeading,
  leftFormLabel,
  leftFormButton,
  leftLink,
} from './style';
import RightBanner from 'Components/RightBanner';

const ResetPassword = () => {
  return (
    <div css={resetContainer}>
      <div css={leftSection}>
        <div>
          <h1 css={leftHeading}>Reset password</h1>

          <form css={leftContainer} onSubmit={(e) => e.preventDefault()}>
            <label htmlFor="password" css={leftFormLabel}>
              <span>New Password</span>
              <input type="password" name="password" id="password" required />
            </label>
            <label htmlFor="confirmPassword" css={leftFormLabel}>
              <span>Confirm Password</span>
              <input
                type="password"
                name="confirmPassword"
                id="confirmPassword"
                required
              />
            </label>

            <button css={leftFormButton} type="submit">
              Submit
            </button>

            <Link to="/" css={leftLink}>
              or return to homepage
            </Link>
          </form>
        </div>
      </div>
      <RightBanner />
    </div>
  );
};

export default ResetPassword;

/** @jsx jsx */
/** @jsxRuntime classic */

import { css } from '@emotion/react';

export const headingTextStyle = css`
  margin-bottom: 30px;
`;

export const rowWrapper = css`
  display: flex;
  justify-content: space-between;

  @media (max-width: 56.25rem) {
    flex-direction: column;
  }
`;

export const buttonWrapper = css`
  display: flex;
  justify-content: flex-end;
`;
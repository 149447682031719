import styled from "@emotion/styled";

export const NextButton = styled.button`
  font-size: 0.7rem;
`;

export const Input = styled.input`
  width: 100%;
  border: 0;
  background: #e3edf3;
  border-radius: 5px;
  padding: 1.5rem;
  font-size: 16px;
  font-weight: 400;

  ::placeholder {
    color: #6c757d;
  }
`;

export const TH = styled.th`
  color: #90a8be;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 12px;
  padding: 5px 30px;
`;

export const TD = styled.td`
  background: #e3edf3;
  text-align: center;
`;

export const Span = styled.span`
  display: block;
  margin-top: 24.5px;
  margin-bottom: 24.5px;
  border-right: 2px solid #90a8be47;
  font-size: 15px;
  color: #0055ad;
  font-weight: 500;
  padding-left: 20px;
  padding-right: 20px;
`;

export const Table = styled.table`
  background: #ffffff;
  border: 1px solid #e2e8ec;
  border-collapse: separate;
  border-radius: 5px;
  border-spacing: 0 15px;
  width: 100%;
`;

export const Card = styled.div`
  border-radius: 15px;
`;

export const CardBorder = styled.div`
 border: 4px solid #E3EDF3;
`;

export const SanctionCard = styled.div`
  border: 4px dotted #0FCC984F;
  background: #0FCC9817;
  padding: 2rem 3rem;

`;
import pdfmake from "pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";


const pdfGeneratorPayout = async (data) => {
  pdfmake.vfs = pdfFonts.pdfMake.vfs;

  const {
    date,
    objectReference,
    transactionType,
    transactionStatus,
    receiverFirstName,
    receiverMiddleName,
    receiverLastName,
    receivingAmount,
    receiverCurrency,
    sendingAmount,
    sendingCurrency,
    senderFirstName,
    senderLastName
  } = data;

  const docDefinition = {
    pageOrientation: "portrait",

    content: [
      {
        columns: []
      },
      {
        text: "Payout Details",
        style: "header"
      },
      {
        columns: [
          {
            columns: [
              {
                text: "Date \u200B\ \n PCN \u200B\ \n Status",
                width: "auto",
                style: "infoNude"
              },
              {
                text: date,
                width: "auto",
                style: "info"
              }
            ]
          },
          {
            text: transactionType + "\n" + objectReference + "\n" + transactionStatus,
            width: "*",
            style: "address"
          }
        ]
      },
      {
        text: "Transaction Information",
        style: "subheader"
      },
      { canvas: [{ type: "line", x1: 0, y1: 5, x2: 595 - 2 * 40, y2: 5, lineWidth: 0.2 }], style: "pretext" },
      {
        columns: [
          {
            text: "Date of Transaction",
            width: 300,
            style: "infoNude"
          },
          {
            text: "PCN",
            width: 300,
            style: "infoNude"
          }
        ]
      },
      {
        columns: [
          {
            text: "-",
            width: 300,
            style: "info"
          },
          {
            text: objectReference,
            width: 300,
            style: "info"
          }
        ]
      },
      {
        columns: [
          {
            text: "Amount sent to" + " " + receiverFirstName + " " + receiverMiddleName + " " + receiverLastName,
            width: 300,
            style: "infoNude"
          },
          {
            text: "Total received",
            width: 300,
            style: "infoNude"
          }
        ]
      },
      {
        columns: [
          {
            text: parseFloat(sendingAmount).toFixed(2) + " " + sendingCurrency,
            width: 300,
            style: "info"
          },
          {
            text: parseFloat(receivingAmount).toFixed(2) + " " + receiverCurrency,
            width: 300,
            style: "info"
          }
        ]
      },
      {
        text: "Sender details",
        style: "subheader"
      },
      { canvas: [{ type: "line", x1: 0, y1: 5, x2: 595 - 2 * 40, y2: 5, lineWidth: 0.2 }], style: "pretext" },
      {
        columns: [
          {
            text: "Name",
            width: 300,
            style: "infoNude"
          },
          {
            text: "Email",
            width: 300,
            style: "infoNude"
          }
        ]
      },
      {
        columns: [
          {
            text: senderFirstName + " " + senderLastName,
            width: 300,
            style: "info"
          },
          {
            text: "-",
            width: 300,
            style: "info"
          }
        ]
      },
      {
        text: "Pay Center",
        style: "subheader"
      },
      { canvas: [{ type: "line", x1: 0, y1: 5, x2: 595 - 2 * 40, y2: 5, lineWidth: 0.2 }], style: "pretext" },
      {
        columns: [
          {
            text: "Name of Agency / Cahier",
            width: 300,
            style: "infoNude"
          },
          {
            text: "Email",
            width: 300,
            style: "infoNude"
          }
        ]
      },
      // {
      //   columns: [
      //     {
      //       text: sender["first_name"] + " " + sender["last_name"],
      //       width: 300,
      //       style: "info"
      //     },
      //     {
      //       text: sender["email"],
      //       width: 300,
      //       style: "info"
      //     }
      //   ]
      // },
      // {
      //   columns: [
      //     {
      //       text: "Payment Method",
      //       width: 300,
      //       style: "infoNude"
      //     }
      //   ]
      // },
      // {
      //   columns: [
      //     {
      //       text: payment_method,
      //       width: 300,
      //       style: "info"
      //     }
      //   ]
      // }
    ],

    styles: {
      header: {
        fontSize: 16,
        bold: true,
        margin: [0, 20],
        alignment: "left"
      },
      subheader: {
        fontSize: 12,
        bold: true,
        margin: [0, 40, 0, 10],
        alignment: "left"
      },
      pretext: {
        margin: [0, 0, 0, 20],
        alignment: "left"
      },
      infoNude: {
        color: "#696969",
        fontSize: 10,
        margin: [0, 10, 0, 0],
        bold: true,
        alignment: "left"
      },
      info: {
        color: "black",
        fontSize: 10,
        margin: [0, 10, 0, 0],
        alignment: "left"
      },
      address: {
        fontSize: 10,
        alignment: "right",
        color: "black"
      }
    }
  };

  pdfmake.createPdf(docDefinition).open();
};

export default pdfGeneratorPayout;

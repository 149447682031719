// eslint-disable-next-line no-unused-vars
import React from 'react';
/** @jsx jsx */
/** @jsxRuntime classic */
import { css, jsx } from '@emotion/react';
import ButtonSpinner from 'Components/ButtonSpinner';
import { BrandedRegularButton } from "../BrandingComponents";

const DEFAULTS = {
  fontSize: '16px',
  fontWeight: 500,
  textColor: '#ffffff',
  paddingVertical: '1rem',
  paddingHorizontal: '1.875rem',
  width: '150px',
  height: 'auto',
};

const buttonStyle = (Styles, theme) => {
  return css`
    border: 0;
    background: ${Styles.buttonColor || theme.primaryColor};
    border-radius: 5px;
    font-size: ${Styles.fontSize};
    font-weight: ${Styles.fontWeight};
    color: ${Styles.textColor};
    padding: ${Styles.paddingVertical} ${Styles.paddingHorizontal};
    min-width: ${Styles.width};
    height: ${Styles.height};
    cursor: pointer;
  `;
};

const FlexibleButton = ({
  loading,
  buttonWidth,
  buttonText,
  buttonColor,
  fontSize,
  fontWeight,
  textColor,
  paddingVertical,
  paddingHorizontal,
  onClick,
  height,
  disabled,
}) => {
  const buttonStyles = {
    width: buttonWidth || DEFAULTS.width,
    buttonColor: buttonColor,
    fontSize: fontSize || DEFAULTS.fontSize,
    fontWeight: fontWeight || DEFAULTS.fontWeight,
    textColor: textColor || DEFAULTS.textColor,
    paddingVertical: paddingVertical || DEFAULTS.paddingVertical,
    paddingHorizontal: paddingHorizontal || DEFAULTS.paddingHorizontal,
    height: height || DEFAULTS.height,
  };
  const clickHandler = onClick || null;

  return (
    <BrandedRegularButton
      type="submit"
      disabled={loading || disabled}
      css={(theme) => buttonStyle(buttonStyles, theme)}
      onClick={clickHandler}
    >
      {loading ? <ButtonSpinner /> : <span>{buttonText}</span>}
    </BrandedRegularButton>
  );
};

export default FlexibleButton;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";
import Footer from "Components/Footer";
import NavBar from "Components/Navbar";
import ItemsInCart from "Components/ItemsInCart";
import { addToCart } from "Store/actions/cart.action";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import TabContent from "react-bootstrap/TabContent";
import { Image, NextButton, Card } from "./style";
import BackArrow from "Components/BackArrow";

const ProductDescription = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const search = location.search;
  const [productDetails, setProductDetails] = useState({});
  const { cart, domainBrand } = useSelector(({ cartReducer, domainBrandReducer }) => ({
    cart: cartReducer,
    domainBrand: domainBrandReducer,
  }));

  // Language state
  const languageDictionary = domainBrand.domainBrand["languageDictionary"];
  const languageDictionaryStatus = !(
    typeof languageDictionary === "undefined" || languageDictionary === null
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    if (location.state) {
      setProductDetails(location.state);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddToCart = (product) => {
    const productId = product.product_id;

    dispatch(addToCart(productId));
    history.push({
      search: `?newItem=${productId}`,
      state: productDetails,
    });
  };

  if (!location.state) {
    return <Redirect to="/merchant-product" />;
  }

  if (search.startsWith("?newItem")) {
    const itemId = search.slice(1).split("=")[1];

    if (itemId) {
      return (
        <>
          <ItemsInCart
            productImage={productDetails.productMainPhotoUrl}
            currency={productDetails.product_currency}
          />
        </>
      );
    }
  }

  return (
    <>
      <Helmet>
        <title>Product Description</title>
      </Helmet>
      <NavBar />
      <section>
        <div className="container">
          <div className="mt-5 mb-5">
            <div className="d-flex">
              <BackArrow />
              <h2 className="text-blue ">
                {languageDictionaryStatus
                  ? languageDictionary["WO_MERCHANT_PRODUCT_1436670176"]
                  : "Merchant Product"}
              </h2>
            </div>
            <Card className="card mt-5 p-xl-5">
              <div className="card-body mb-3 p-md-3">
                {/* --- Product description --- */}
                <div className="row mb-5">
                  <div className="col-12">
                    <div className="row">
                      <div className="col-12 col-md-6 mb-3">
                        <Image>
                          <img
                            src={productDetails.productMainPhotoUrl}
                            alt={productDetails.product_name}
                            className="img-fluid"
                          />
                        </Image>
                      </div>
                      <div className="col-12 col-md-6 pl-lg-5">
                        {/* ------ Product Name ------ */}
                        <div className="mb-4">
                          <span className="text-muted text-capitalize">
                            {productDetails.service}
                          </span>
                          <h3 className="text-blue">{productDetails.product_name}</h3>
                          <span className="text-green h3">{`${productDetails.product_currency}${productDetails.product_value}`}</span>
                        </div>

                        <div className="d-none d-md-block mb-5">
                          <img
                            src={productDetails.photoDescriptionUrl}
                            alt=""
                            className="img-fluid"
                          />
                        </div>

                        {/* ----- Product Quantity --------- */}
                        <div className="row">
                          <div className="col-12 col-md-6">
                            <NextButton
                              className="btn btn-lg btn-green text-uppercase text-white btn-block px-3"
                              onClick={() => handleAddToCart(productDetails)}>
                              Add to cart
                            </NextButton>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* ------ Product Information ------ */}
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <Tabs
                      id="uncontrolled-tab"
                      defaultActiveKey="description"
                      variant="pills"
                      scrollable="true"
                      className="nav nav-overflow flex-nowrap d-flex justify-content-start justify-content-md-center border-bottom">
                      <Tab eventKey="description" title="Description">
                        <TabContent className="tab-pane fade show active">
                          <div className="row justify-content-center py-5">
                            <div className="col-12 col-lg-12 col-xl-12">
                              <div className="row">
                                <div className="col-12">
                                  {/* <!-- Text --> */}
                                  <p className="text-dark">{productDetails.product_short_desc}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </TabContent>
                      </Tab>
                    </Tabs>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default ProductDescription;

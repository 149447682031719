import { ActionCreator } from "Utils/Helpers";
import * as Transfer from "Store/constants/transfer.constants";
import TransferService from "Utils/Services/Transfer.service";
import BeneficiaryService from "Utils/Services/Beneficiary.service";
import { toast } from "react-toastify";
import { daysFromToday, formatReloadData } from "Utils/Helpers/general";
import { expiredTokenCheck } from "./auth.action";
import { fetchSupportedCountriesByDeliveryMethod } from "../../Utils/Services/Country.service";
import { GET_REQUIRED_TRANSACTION_DOCUMENTS_REQUEST } from "Store/constants/transfer.constants";
import { fetchRequiredTransDocx } from "../../Utils/Services/Compliance.service";

export const resetQuoteData = () => (dispatch) => {
  try {
    dispatch(ActionCreator(Transfer.RESET_QUOTE_DATA));
  } catch (error) {
    console.error("error clearing quote data");
  }
};

export const getTransferCountryList = (originCountry) => async (dispatch) => {
  try {
    dispatch(ActionCreator(Transfer.FETCH_TRANSFER_COUNTRY_LIST_REQUEST));
    const { data: responseData } = await fetchSupportedCountriesByDeliveryMethod(
      originCountry,
      "ACCOUNTPAYMENT"
    );

    if (responseData.status === "FAILED") {
      throw responseData;
    }
    const transferFundsCountries = responseData["data"]["countries"];
    dispatch(ActionCreator(Transfer.FETCH_TRANSFER_COUNTRY_LIST_SUCCESS, transferFundsCountries));
  } catch (error) {
    dispatch(ActionCreator(Transfer.FETCH_TRANSFER_COUNTRY_LIST_FAILURE));
  }
};

export const fetchTransferHistory = ({ currency, toDate, fromDate }) => async (dispatch) => {
  try {
    dispatch(ActionCreator(Transfer.FETCH_TRANSFER_HISTORY_REQUEST));
    const { data: responseData } = await TransferService.getTransferHistory({
      currency,
      toDate,
      fromDate
    });

    if (responseData.status === "FAILED") throw responseData;
    const { data } = responseData;
    dispatch(ActionCreator(Transfer.FETCH_TRANSFER_HISTORY_SUCCESS, data));

  } catch (error) {
    expiredTokenCheck(error, dispatch);
    dispatch(ActionCreator(Transfer.FETCH_TRANSFER_HISTORY_FAILURE));
    console.error(error);
  }
};

export const getBeneficiaryBankDetails = ({ customerCode }) => async (dispatch) => {
  try {
    dispatch(ActionCreator(Transfer.FETCH_BENEFICIARY_BANK_INFO_REQUEST));
    const { data: responseData } = await BeneficiaryService.fetchBankDetails(customerCode);
    if (responseData.status === "FAILED") {
      throw responseData;
    }

    const { data: { bankDetails } } = responseData;
    dispatch(ActionCreator(Transfer.FETCH_BENEFICIARY_BANK_INFO_SUCCESS, bankDetails));
  } catch (error) {
    expiredTokenCheck(error, dispatch);

    dispatch(ActionCreator(Transfer.FETCH_BENEFICIARY_BANK_INFO_FAILURE));
    console.error(error);
  }
};

export const getQuoteData = (payload) => async (dispatch) => {
  try {
    dispatch(ActionCreator(Transfer.GET_QUOTE_DATA_REQUEST));
    const { data: responseData } = await TransferService.getQuote(payload);
    if (responseData.status === "FAILED") {
      throw responseData;
    }
    const { data } = responseData;
    dispatch(ActionCreator(Transfer.GET_QUOTE_DATA_SUCCESS, data));
  } catch (error) {
    expiredTokenCheck(error, dispatch);
    dispatch(ActionCreator(Transfer.GET_QUOTE_DATA_FAILURE));
    console.error(error);
  }
};

export const fetchRequiredBankFields = (payload) => async (dispatch) => {
  try {
    dispatch(ActionCreator(Transfer.GET_BANK_FIELDS_REQUEST));
    const path = `${payload.countryCode}`;
    const { data: responseData } = await TransferService.fetchRequiredBankFields(path);
    if (responseData.status === "FAILED") {
      throw responseData;
    }

    const { data } = responseData;

    dispatch(ActionCreator(Transfer.GET_BANK_FIELDS_SUCCESS, data));
  } catch (error) {
    expiredTokenCheck(error, dispatch);

    dispatch(ActionCreator(Transfer.GET_BANK_FIELDS_FAILURE));
    console.error(error);
  }
};

export const fetchRequiredBankFieldsByFilter = (countryCode, type, currencyCode) => async (dispatch) => {
  try {
    dispatch(ActionCreator(Transfer.GET_BANK_FIELDS_REQUEST));
    const method = type || "ACCOUNTPAYMENT";
    const path = `${countryCode}?delivery_method=${method}&currency_ode=${currencyCode}`;
    const { data: responseData } = await TransferService.fetchRequiredBankFields(path);
    if (responseData.status === "FAILED") {
      throw responseData;
    }

    const { data } = responseData;

    dispatch(ActionCreator(Transfer.GET_BANK_FIELDS_SUCCESS, data));
  } catch (error) {
    expiredTokenCheck(error, dispatch);

    dispatch(ActionCreator(Transfer.GET_BANK_FIELDS_FAILURE));
    console.error(error);
  }
};

export const fetchRequiredDocumentsForTransaction = (payload) => async (dispatch) => {
  try {
    dispatch(ActionCreator(Transfer.GET_REQUIRED_TRANSACTION_DOCUMENTS_REQUEST));
    const { data: responseData } = await fetchRequiredTransDocx(payload);
    if (responseData.status === "FAILED") {
      throw responseData;
    }

    const { data } = responseData;

    dispatch(ActionCreator(Transfer.GET_REQUIRED_TRANSACTION_DOCUMENTS_SUCCESS, data));
  } catch (error) {
    expiredTokenCheck(error, dispatch);

    dispatch(ActionCreator(Transfer.GET_REQUIRED_TRANSACTION_DOCUMENTS_FAILURE));
    console.error(error);
  }
};

export const initiateTransaction = ({ dataPayload, navigator }, password) => async (dispatch) => {
  try {
    dispatch(ActionCreator(Transfer.CREATE_TRANSACTION_REQUEST));
    const { data: responseData } = await TransferService.createTransaction(dataPayload, password);

    if (responseData.error.errorCode === "-1" || responseData.error["errorCategory"] === "FAILED") throw responseData.error;
    const { objectReference, payAbleId, payableType } = responseData;
    const { originCountry, receivingCurrency, amount, sendAmount, tax } = dataPayload;

    const dataValues = {
      taxAmount: tax,
      sendAmount,
      payAbleId,
      payableType,
      amount,
      pcn: objectReference,
      receivingCurrency,
      countryIsoCode3: originCountry
    };
    navigator(dataValues);
    dispatch(ActionCreator(Transfer.CREATE_TRANSACTION_SUCCESS, dataValues));
  } catch (error) {
    expiredTokenCheck(error, dispatch);
    dispatch(ActionCreator(Transfer.CREATE_TRANSACTION_FAILURE));
    toast.error(error["errorDescription"] || "Error submitting transaction", { position: toast.POSITION.TOP_CENTER });
    console.error(error);
  }
};

export const initiateFundWallet = (payload, navigator, options = {}) => async (dispatch) => {
  try {
    dispatch(ActionCreator(Transfer.BOOK_FUND_WALLET_REQUEST));
    const { data: responseData } = await TransferService.bookFundWalletAccount(payload);
    if (responseData.status === "FAILED") throw responseData;

    if (
      responseData.error &&
      (responseData.error.errorCode === "-1" || responseData.error["errorCategory"] === "FAILED")
    ) throw responseData.error["errorDescription"];

    responseData.data.sendAmount = responseData.data.amount;
    responseData.data.receivingCurrency = responseData.data.currency;

    navigator({ ...responseData.data, ...options });
    dispatch(ActionCreator(Transfer.BOOK_FUND_WALLET_SUCCESS, responseData.data));
  } catch (error) {
    expiredTokenCheck(error, dispatch);
    dispatch(ActionCreator(Transfer.BOOK_FUND_WALLET_FAILURE));
    const errorMessage = error.message || error;
    toast.error(errorMessage, {
      position: toast.POSITION.TOP_CENTER
    });
  }
};

export const initiateWalletPayment = (payload, navigateToTransfer, currency) => async (dispatch) => {
  try {
    dispatch(ActionCreator(Transfer.MAKE_WALLET_PAYMENT_REQUEST));
    const { data: responseData } = await TransferService.makeWalletPayment(payload);

    if (responseData.status === "FAILED") throw responseData;

    await dispatch(fetchTransferHistory({ currency: currency, fromDate: daysFromToday(), toDate: new Date() }));
    await dispatch(ActionCreator(Transfer.MAKE_WALLET_PAYMENT_SUCCESS));
    navigateToTransfer();
    toast.success("Payment submitted", { position: toast.POSITION.TOP_CENTER });

  } catch (error) {
    expiredTokenCheck(error, dispatch);
    dispatch(ActionCreator(Transfer.MAKE_WALLET_PAYMENT_FAILURE));
    toast.error("Error completing payment", { position: toast.POSITION.TOP_CENTER });
    console.error(error);
  }
};

export const fetchPaymentMethods = (payload) => async (dispatch) => {
  try {
    dispatch(ActionCreator(Transfer.LIST_PAYMENT_METHODS_REQUEST));
    const { data: responseData } = await TransferService.getPaymentMethods(payload);
    if (responseData.status === "FAILED") {
      throw responseData;
    }

    dispatch(ActionCreator(Transfer.LIST_PAYMENT_METHODS_SUCCESS, responseData.data));
  } catch (error) {
    expiredTokenCheck(error, dispatch);

    dispatch(ActionCreator(Transfer.LIST_PAYMENT_METHODS_FAILURE));
    console.error(error);
  }
};

export const getTransactionStatus = (payload) => async (dispatch) => {
  try {
    dispatch(ActionCreator(Transfer.GET_TRANSACTION_STATUS_REQUEST));
    const { data: responseData } = await TransferService.fetchTransactionStatus(payload);
    if (responseData.status === "FAILED") {
      throw responseData;
    }

    if (
      responseData.error &&
      (responseData.error.errorCode === "-1" || responseData.error["errorCategory"] === "FAILED")
    ) {
      throw responseData.error["errorDescription"];
    }

    dispatch(ActionCreator(Transfer.GET_TRANSACTION_STATUS_SUCCESS, responseData.data));
  } catch (error) {
    expiredTokenCheck(error, dispatch);

    dispatch(ActionCreator(Transfer.GET_TRANSACTION_STATUS_FAILURE));
    console.error(error);
    toast.error(error, {
      position: toast.POSITION.TOP_CENTER
    });
  }
};

export const lookupAccountInfo = (payload, senderData, navigator) => async (dispatch) => {
  try {
    dispatch(ActionCreator(Transfer.ACCOUNT_INFO_LOOKUP_REQUEST));
    const { data: responseData } = await TransferService.accountInfoLookup(payload);

    if (responseData.status === "FAILED") throw responseData;
    if (responseData.error && (responseData.error.errorCode === "-1" || responseData.error["errorCategory"] === "FAILED")) throw responseData.error["errorDescription"];

    const navigateState = {
      accountData: {
        ...responseData.data,
        senderData,
        accountNumber: payload.findbyAccountNumber
      }
    };
    if (navigator !== null) navigator(navigateState);

    dispatch(ActionCreator(Transfer.ACCOUNT_INFO_LOOKUP_SUCCESS, responseData.data));
  } catch (error) {
    expiredTokenCheck(error, dispatch);

    dispatch(ActionCreator(Transfer.ACCOUNT_INFO_LOOKUP_FAILURE));
    console.error(error);
    const errorMessage = error.message || error;
    toast.error(errorMessage, {
      position: toast.POSITION.TOP_CENTER
    });
  }
};

export const initiateWalletToWalletTransfer = (payload, navigator) => async (dispatch) => {
  try {
    dispatch(ActionCreator(Transfer.BOOK_WALLET_TO_WALLET_REQUEST));
    const { data: responseData } = await TransferService.bookWalletToWallet(payload);
    if (responseData.status === "FAILED") {
      throw responseData;
    }

    if (
      responseData.error &&
      (responseData.error.errorCode === "-1" || responseData.error["errorCategory"] === "FAILED")
    ) {
      throw responseData.error["errorDescription"];
    }

    responseData.data.receivingCurrency = responseData.data.currency;
    responseData.data.sendAmount = responseData.data.amount;
    navigator(responseData.data);

    dispatch(ActionCreator(Transfer.BOOK_WALLET_TO_WALLET_SUCCESS, responseData.data));
  } catch (error) {
    expiredTokenCheck(error, dispatch);
    dispatch(ActionCreator(Transfer.BOOK_WALLET_TO_WALLET_FAILURE));
    const errorMessage = error.message || error;
    toast.error(errorMessage, {
      position: toast.POSITION.TOP_CENTER
    });
  }
};

export const getTransactionDetails = (payload, navigator) => async (dispatch) => {
  try {
    dispatch(ActionCreator(Transfer.GET_TRANSACTION_DETAILS_REQUEST));
    const { data: responseData } = await TransferService.fetchTransactionDetails(payload);

    if (responseData.status === "FAILED" || responseData.status === "ERROR") throw responseData;
    if (responseData.error &&
      (responseData.error.errorCode === "-1" || responseData.error["errorCategory"] === "FAILED")
    ) throw responseData.error["errorDescription"];

    if (navigator) navigator(formatReloadData(responseData.data));
    dispatch(ActionCreator(Transfer.GET_TRANSACTION_DETAILS_SUCCESS, responseData.data));
  } catch (error) {
    expiredTokenCheck(error, dispatch);

    dispatch(ActionCreator(Transfer.GET_TRANSACTION_DETAILS_FAILURE));
    console.error(error);
  }
};

export const cancelTransactionPayment = (payload) => async (dispatch) => {
  try {
    dispatch(ActionCreator(Transfer.CANCEL_TRANSACTION_REQUEST));
    const { data: responseData } = await TransferService.cancelTransaction(payload);
    if (responseData.status === "FAILED" || responseData.status === "ERROR") {
      throw responseData;
    }

    if (
      responseData.error &&
      (responseData.error.errorCode === "-1" || responseData.error.errorCategory === "FAILED")
    ) {
      throw responseData.error.errorDescription;
    }

    dispatch(ActionCreator(Transfer.CANCEL_TRANSACTION_SUCCESS, responseData));
    toast.success("Transaction cancelled successfully", {
      position: toast.POSITION.TOP_CENTER
    });
  } catch (error) {
    expiredTokenCheck(error, dispatch);
    dispatch(ActionCreator(Transfer.CANCEL_TRANSACTION_FAILURE));
    const errorMessage = error.message || error;
    toast.error(errorMessage, {
      position: toast.POSITION.TOP_CENTER
    });
    console.error(error);
  }
};

export const fetchPayLater =
  ({ pcn, payableType }) =>
    async (dispatch) => {
      try {
        dispatch(ActionCreator(Transfer.FETCH_PAY_LATER_REQUEST));
        const { data: responseData } = await TransferService.payLater({ pcn, payableType });
        if (responseData.status === "FAILED" || responseData.status === "ERROR") {
          throw responseData;
        }

        dispatch(ActionCreator(Transfer.FETCH_PAY_LATER_SUCCESS, responseData.data));
      } catch (error) {
        expiredTokenCheck(error, dispatch);
        dispatch(ActionCreator(Transfer.FETCH_PAY_LATER_FAILURE));
        console.error(error);
      }
    };

export const alertBankTransfer = (pcn, payableType) => async (dispatch) => {
  try {
    dispatch(ActionCreator(Transfer.ALERT_BANK_TRANSFER_REQUEST));
    const { data: responseData } = await TransferService.notifyBank(pcn, payableType);
    if (responseData.status === "FAILED" || responseData.status === "ERROR") {
      throw responseData;
    }

    dispatch(ActionCreator(Transfer.ALERT_BANK_TRANSFER_SUCCESS, responseData.data));
    toast.success("Bank alerted successfully", {
      position: toast.POSITION.TOP_CENTER
    });
  } catch (error) {
    expiredTokenCheck(error, dispatch);
    dispatch(ActionCreator(Transfer.ALERT_BANK_TRANSFER_FAILURE));
    const errorMessage = error.message || error;
    toast.error(errorMessage, {
      position: toast.POSITION.TOP_CENTER
    });
    console.error(error);
  }
};

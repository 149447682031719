import React, { useState } from "react";
import Select from "react-select";

/** @jsx jsx */
/** @jsxRuntime classic */
import { jsx, css } from "@emotion/react";
import { selectWalletCSS } from "../../../BankAccount/Create/styleSheet";
import customStyles from "../../../../../Assets/styles/dropdown/customStyles";
import DropDownComponent from "../../../../../Components/DropDownComponent";
import downArrow from "../../../../../Assets/svg/blue-drop-down-arrow.svg";
import FormLabel from "../../../../../Components/FormLabel";
import {
  InnerTopContainer,
  InnerBottomContainer,
  TitleHeading,
  SubHeading,
  InfoItem,
  InfoItemContainer
} from "./WalletStyle";
import { useSelector } from "react-redux";

const Wallet = ({ theme, wallet, wallets, dataObject: { parameters, currency }, code }) => {
  const [walletHolder, updateWalletHolder] = useState("");
  const filteredWalletByCurrency = wallets.filter(element => element["value"].includes(currency));
  const { domainBrand } = useSelector(({ domainBrandReducer }) => ({
    domainBrand: domainBrandReducer
  }));
  const languageDictionary = domainBrand.domainBrand["languageDictionary"];
  const languageDictionaryStatus = !(typeof languageDictionary === "undefined" || languageDictionary === null);

  return (
    <>
      <FormLabel
        text={languageDictionaryStatus ? languageDictionary["WO_SELECT_AN_ACCOUN_-743338052"] : "Select Wallet"}>
        <div css={selectWalletCSS}>
          <Select
            noOptionsMessage={() => `No wallet holder with ${currency} added`}
            value={walletHolder}
            options={filteredWalletByCurrency}
            customTheme={theme}
            styles={customStyles}
            isClearable={false}
            isSearchable={true}
            onChange={(option) => {
              wallet(option);
              updateWalletHolder(option);
            }}
            components={{
              IndicatorSeparator: null,
              DropdownIndicator: () => <DropDownComponent iconSource={downArrow} />
            }}
          />
        </div>
      </FormLabel>
      <InnerTopContainer>
        <InfoItemContainer>
          <InfoItem>
            <TitleHeading>
              {languageDictionaryStatus ? languageDictionary["para_account_holder_name"] : "Account Holder Name"}
            </TitleHeading>
            <SubHeading>{walletHolder["accountName"]}</SubHeading>
          </InfoItem>

          <InfoItem>
            <TitleHeading>
              {languageDictionaryStatus ? languageDictionary["para_balance"] : "Balance"}
            </TitleHeading>
            <SubHeading>
              {walletHolder["value"]}{" "}
              {parseFloat(walletHolder["walletAccountBalance"])
                .toFixed(2)
                .replace(/[^0-9.]/g, "")}
            </SubHeading>
          </InfoItem>

          <InfoItem>
            <TitleHeading>
              {languageDictionaryStatus ? languageDictionary["WO_ACCOUNT_NUMBE_554490787"] : "Account Number"}
            </TitleHeading>
            <SubHeading>{walletHolder["walletAccountNumber"]}</SubHeading>
          </InfoItem>
          <InfoItem>
            <TitleHeading>
              {languageDictionaryStatus ? languageDictionary["l_account_currency"] : "Account Currency"}
            </TitleHeading>
            <SubHeading>{walletHolder["value"]}</SubHeading>
          </InfoItem>
        </InfoItemContainer>
      </InnerTopContainer>
      <InnerBottomContainer>
        <TitleHeading
          css={(theme) => css`
            color: ${theme.primaryColor};
            margin-bottom: 0;
          `}>
          {languageDictionaryStatus ? languageDictionary["para_bank_transfer_instruction"] : "TRACKING NUMBER:"}
        </TitleHeading>
        <TitleHeading
          css={css`
            color: #0fcc98;
            margin-left: 5px;
            margin-bottom: 0;
          `}>
          {parameters && parameters.paymentGatewayRef}
        </TitleHeading>
      </InnerBottomContainer>
      <InnerBottomContainer>
        <TitleHeading
          css={(theme) => css`
            color: ${theme.primaryColor};
            margin-bottom: 0;
          `}>
          {languageDictionaryStatus ? languageDictionary["CUSTOMER_COD_-69633046"] : "Code:"}
        </TitleHeading>
        <TitleHeading
          css={css`
            color: #0fcc98;
            margin-left: 5px;
            margin-bottom: 0;
          `}>
          {code}
        </TitleHeading>
      </InnerBottomContainer>
    </>
  );
};

export default Wallet;

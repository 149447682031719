import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCountryList } from "Store/actions/airtime.action";
import { Helmet } from "react-helmet";
import Footer from "Components/Footer";
import NavBar from "Components/Navbar";
import Spinner from "Components/Spinner";
import Pagination from "Components/Pagination";
import usePagination from "Components/Pagination/usePagination";
import { Input } from "./style";
import CountryFlagCard from "Components/CountryFlagCard";
import * as Loading from "../../../../Store/constants/loadingState.constants";
import Loader from "../../../../Components/loader/Loader";
import cookieHelper from "../../../../Utils/Helpers/cookieHelper";
import config from "../../../../Utils/Helpers/config";
import BackArrow from "Components/BackArrow";

const AirtimeDestinationCountry = () => {
  const dispatch = useDispatch();
  const [filteredCountries, setFilteredCountries] = useState(null);

  const {
    airtime: { loadingStatus, countries: collection },
    profile,
    domainBrand
  } = useSelector(({ airtimeReducer, profileReducer, domainBrandReducer }) => ({
    airtime: airtimeReducer,
    profile: profileReducer,
    domainBrand: domainBrandReducer
  }));

  const showLoader = domainBrand.loading === Loading.FETCHING || profile.loader === Loading.FETCHING;
  const languageDictionary = domainBrand.domainBrand["languageDictionary"];
  const languageDictionaryStatus = !(
    typeof languageDictionary === "undefined" || languageDictionary === null
  );

  const PAGE_LIMIT = 24;

  const [countries, onPageChange] = usePagination({
    collection,
    pageLimit: PAGE_LIMIT
  });

  useEffect(() => {
    const tradeOriginCountry = cookieHelper.get(config.USER_COUNTRY);
    if (loadingStatus !== "SUCCESS") {
      dispatch(getCountryList(tradeOriginCountry));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = (event) => {
    const { value } = event.target;
    if (!value) {
      setFilteredCountries(null);
      return;
    }
    const searchTerm = value.toLowerCase();
    const filtered = collection.filter((country) => {
      const countryName = country.common_name.toLowerCase();
      const countryIso3Code = country.iso3Code.toLowerCase();
      return countryName.includes(searchTerm) || countryIso3Code.includes(searchTerm);
    });
    setFilteredCountries(filtered);
  };

  return (
    <>
      <Helmet>
        <title>Destination Country</title>
      </Helmet>
      <NavBar />
      {showLoader ? (
        <Loader />
      ) : (
        <section>
          <div className="container">
            <div className="mt-5 mb-5">
              <div className="d-flex">
                <BackArrow />
                <h2 className="text-blue mt-4">
                  {languageDictionaryStatus
                    ? languageDictionary["m_mobile_top_up"]
                    : "Airtime Topup"}
                </h2>
              </div>

              <div className="card mt-5 p-xl-5">
                <div className="card-body mb-3 p-md-3">
                  {/* --- Search box for destination country --- */}
                  <div className="row mb-5">
                    <div className="col-12 col-md-6">
                      <p className="text-blue">
                        {languageDictionaryStatus
                          ? languageDictionary["DESTINATION_COUNTR_1477464105"]
                          : "Destination Country"}
                      </p>
                      <Input
                        type="text"
                        className="form-control"
                        placeholder="Search for country..."
                        onChange={handleSearch}
                      />
                    </div>
                  </div>

                  {/* --- Search box for destination country --- */}
                  <div className="row">
                    <div className="col-12">
                      <p className="text-blue ">
                        {languageDictionaryStatus
                          ? languageDictionary["msg_please_select_a_country"]
                          : "Select a country"}
                      </p>

                      {loadingStatus === "FETCHING" ? (
                        <Spinner containerHeight="30vh" />
                      ) : countries.length === 0 ? (
                        <p className="text-center">No countries available now</p>
                      ) : (
                        <React.Fragment>
                          <div className="row">
                            {(filteredCountries || countries).map((country, index) => {
                              return (
                                <CountryFlagCard
                                  key={index}
                                  text={country.iso3Code}
                                  // link={`airtime-topup/services`}
                                  link={`/airtime-topup/services?country=${country.iso3Code}`}
                                  params={country["iso3Code"]}
                                  flag={country.flag}
                                  country={country.common_name}
                                />
                              );
                            })}
                          </div>
                        </React.Fragment>
                      )}
                      <Pagination
                        onPageChange={onPageChange}
                        pageLimit={PAGE_LIMIT}
                        totalRecords={collection.length}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      <Footer />
    </>
  );
};

export default AirtimeDestinationCountry;

export const FETCH_TRANSFER_HISTORY_REQUEST = "FETCH_TRANSFER_HISTORY_REQUEST";
export const FETCH_TRANSFER_HISTORY_SUCCESS = "FETCH_TRANSFER_HISTORY_SUCCESS";
export const FETCH_TRANSFER_HISTORY_FAILURE = "FETCH_TRANSFER_HISTORY_FAILURE";

export const FETCH_TRANSFER_COUNTRY_LIST_REQUEST = "FETCH_TRANSFER_COUNTRY_LIST_REQUEST";
export const FETCH_TRANSFER_COUNTRY_LIST_SUCCESS = "FETCH_TRANSFER_COUNTRY_LIST_SUCCESS";
export const FETCH_TRANSFER_COUNTRY_LIST_FAILURE = "FETCH_TRANSFER_COUNTRY_LIST_FAILURE";

export const FETCH_BENEFICIARY_BANK_INFO_REQUEST = "FETCH_BENEFICIARY_BANK_INFO_REQUEST";
export const FETCH_BENEFICIARY_BANK_INFO_SUCCESS = "FETCH_BENEFICIARY_BANK_INFO_SUCCESS";
export const FETCH_BENEFICIARY_BANK_INFO_FAILURE = "FETCH_BENEFICIARY_BANK_INFO_FAILURE";

export const GET_QUOTE_DATA_REQUEST = "GET_QUOTE_DATA_REQUEST";
export const GET_QUOTE_DATA_SUCCESS = "GET_QUOTE_DATA_SUCCESS";
export const GET_QUOTE_DATA_FAILURE = "GET_QUOTE_DATA_FAILURE";
export const RESET_QUOTE_DATA = "RESET_QUOTE_DATA";

export const CREATE_TRANSACTION_REQUEST = "CREATE_TRANSACTION_REQUEST";
export const CREATE_TRANSACTION_SUCCESS = "CREATE_TRANSACTION_SUCCESS";
export const CREATE_TRANSACTION_FAILURE = "CREATE_TRANSACTION_FAILURE";

export const MAKE_WALLET_PAYMENT_REQUEST = "MAKE_WALLET_PAYMENT_REQUEST";
export const MAKE_WALLET_PAYMENT_SUCCESS = "MAKE_WALLET_PAYMENT_SUCCESS";
export const MAKE_WALLET_PAYMENT_FAILURE = "MAKE_WALLET_PAYMENT_FAILURE";

export const LIST_PAYMENT_METHODS_REQUEST = "LIST_PAYMENT_METHODS_REQUEST";
export const LIST_PAYMENT_METHODS_SUCCESS = "LIST_PAYMENT_METHODS_SUCCESS";
export const LIST_PAYMENT_METHODS_FAILURE = "LIST_PAYMENT_METHODS_FAILURE";

export const GET_BANK_FIELDS_REQUEST = "GET_BANK_FIELDS_REQUEST";
export const GET_BANK_FIELDS_SUCCESS = "GET_BANK_FIELDS_SUCCESS";
export const GET_BANK_FIELDS_FAILURE = "GET_BANK_FIELDS_FAILURE";

export const GET_REQUIRED_TRANSACTION_DOCUMENTS_REQUEST = "GET_REQUIRED_TRANSACTION_DOCUMENTS_REQUEST";
export const GET_REQUIRED_TRANSACTION_DOCUMENTS_SUCCESS = "GET_REQUIRED_TRANSACTION_DOCUMENTS_SUCCESS";
export const GET_REQUIRED_TRANSACTION_DOCUMENTS_FAILURE = "GET_REQUIRED_TRANSACTION_DOCUMENTS_FAILURE";

export const GET_TRANSACTION_STATUS_REQUEST = "GET_TRANSACTION_STATUS_REQUEST";
export const GET_TRANSACTION_STATUS_SUCCESS = "GET_TRANSACTION_STATUS_SUCCESS";
export const GET_TRANSACTION_STATUS_FAILURE = "GET_TRANSACTION_STATUS_FAILURE";

export const ACCOUNT_INFO_LOOKUP_REQUEST = "ACCOUNT_INFO_LOOKUP_REQUEST";
export const ACCOUNT_INFO_LOOKUP_SUCCESS = "ACCOUNT_INFO_LOOKUP_SUCCESS";
export const ACCOUNT_INFO_LOOKUP_FAILURE = "ACCOUNT_INFO_LOOKUP_FAILURE";

export const BOOK_WALLET_TO_WALLET_REQUEST = "BOOK_WALLET_TO_WALLET_REQUEST";
export const BOOK_WALLET_TO_WALLET_SUCCESS = "BOOK_WALLET_TO_WALLET_SUCCESS";
export const BOOK_WALLET_TO_WALLET_FAILURE = "BOOK_WALLET_TO_WALLET_FAILURE";

export const BOOK_FUND_WALLET_REQUEST = "BOOK_FUND_WALLET_REQUEST";
export const BOOK_FUND_WALLET_SUCCESS = "BOOK_FUND_WALLET_SUCCESS";
export const BOOK_FUND_WALLET_FAILURE = "BOOK_FUND_WALLET_FAILURE";

export const GET_TRANSACTION_DETAILS_REQUEST = "GET_TRANSACTION_DETAILS_REQUEST";
export const GET_TRANSACTION_DETAILS_SUCCESS = "GET_TRANSACTION_DETAILS_SUCCESS";
export const GET_TRANSACTION_DETAILS_FAILURE = "GET_TRANSACTION_DETAILS_FAILURE";

export const CANCEL_TRANSACTION_REQUEST = "CANCEL_TRANSACTION_REQUEST";
export const CANCEL_TRANSACTION_SUCCESS = "CANCEL_TRANSACTION_SUCCESS";
export const CANCEL_TRANSACTION_FAILURE = "CANCEL_TRANSACTION_FAILURE";

export const FETCH_PAY_LATER_REQUEST = "FETCH_PAY_LATER_REQUEST";
export const FETCH_PAY_LATER_SUCCESS = "FETCH_PAY_LATER_SUCCESS";
export const FETCH_PAY_LATER_FAILURE = "FETCH_PAY_LATER_FAILURE";

export const ALERT_BANK_TRANSFER_REQUEST = "ALERT_BANK_TRANSFER_REQUEST";
export const ALERT_BANK_TRANSFER_SUCCESS = "ALERT_BANK_TRANSFER_SUCCESS";
export const ALERT_BANK_TRANSFER_FAILURE = "ALERT_BANK_TRANSFER_FAILURE";

import { ActionCreator } from "Utils/Helpers";
import * as BankAccount from "Store/constants/bankAccount.constants";
import BankAccountService from "Utils/Services/BankAccount.service";
import { toast } from "react-toastify";
import { expiredTokenCheck } from "./auth.action";

export const createBankAccount = (payload, navigate) => async (dispatch) => {
  try {
    dispatch(ActionCreator(BankAccount.CREATE_BANK_ACCOUNT_REQUEST));

    const { data: responseData } = await BankAccountService.create(payload);

    if (responseData.status === "FAILED") {
      throw responseData;
    }
    dispatch(ActionCreator(BankAccount.CREATE_BANK_ACCOUNT_SUCCESS));
    toast.success("Account created successfully", {
      position: toast.POSITION.TOP_CENTER
    });
    navigate();
  } catch (error) {
    expiredTokenCheck(error, dispatch);
    dispatch(ActionCreator(BankAccount.CREATE_BANK_ACCOUNT_FAILURE));

    toast.error(error.message, {
      position: toast.POSITION.TOP_CENTER
    });
    console.error(error);
  }
};

export const fetchCurrencies = () => async (dispatch) => {
  try {
    dispatch(ActionCreator(BankAccount.FETCH_CURRENCIES_REQUEST));
    const { data: responseData } = await BankAccountService.fetchCurrencies();

    if (responseData.status === "FAILED") {
      throw responseData;
    }

    const { data } = responseData;
    dispatch(ActionCreator(BankAccount.FETCH_CURRENCIES_SUCCESS, data.response));
  } catch (error) {
    expiredTokenCheck(error, dispatch);
    dispatch(ActionCreator(BankAccount.FETCH_CURRENCIES_FAILURE));
    console.error(error, "fetching currencies failed");
  }
};

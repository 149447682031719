import styled from '@emotion/styled';

export const HeadingCard = styled.p`
  width: 208px;
  height: 43px;
  background: ${(props) => props.theme.primaryColor};
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 100;
  color: white;
`;

export const TopHeading = styled.div`
  margin-top: 15px;
  display: flex;
  margin-bottom: 30px;
`;

export const DownloadButton = styled.button`
  height: 43px;
  width: 70px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 15px;
  border: 0;
  background: ${(props) =>
    props.default ? '#E3EDF3' : props.theme.primaryColor};

  svg {
    path {
      fill: ${(props) =>
        props.default ? props.theme.primaryColor : '#ffffff'};
    }
  }
`;

export const SectionHeadingContainer = styled.div`
  margin-top: 45px;
  margin-bottom: 30px;
`;

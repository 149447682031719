// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
/** @jsx jsx */
/** @jsxRuntime classic */
import { jsx } from "@emotion/react";
import * as Yup from "yup";
import { useFormik } from "formik";
import Select from "react-select";
import { useSelector, useDispatch, batch } from "react-redux";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

import HeadingText from "Components/HeadingText";
import NavBar from "Components/NavbarSignUp";
import Footer from "Components/Footer";
import CustomInput from "Components/CustomInput";
import DropDownComponent from "Components/DropDownComponent";
import Label from "Components/Label";
import downArrow from "Assets/svg/blue-drop-down-arrow.svg";
import customStyles from "Assets/styles/dropdown/customStyles";
import offSetImg from "Assets/svg/man-user-1.svg";
import ErrorField from "Components/ErrorField";
import CustomButton from "Components/CustomButton";

import { nationalityList } from "Utils/constants";
import * as Loading from "Store/constants/loadingState.constants";
import "./index.module.scss";
import { mainBody, selectWrapper, buttonWrapper, Row, selectDropdownContainer } from "./style";
import { typeOptions } from "Utils/constants";
import { modalFormDatePicker } from "./style";
import { Container, Main, InnerContainer, OffsetIcon, ImageElement } from "Components/Styled";
import { fetchComplianceRequiredFields } from "../../../Store/actions/compliance.action";
import { registerCustomer } from "../../../Store/actions/user.action";
import { getAllCountries, getAllSupportedCountries } from "Store/actions/country.action";
import { fetchDynamicFields } from "../../../Store/actions/dynamicFields.action";
import DynamicFields from "../../../Components/DynamicFields";
import { dynamicFieldsFormikGetKeys } from "../../../Utils/Helpers/general";
import Loader from "../../../Components/loader/Loader";


const RegisterCustomer = () => {
  const dispatch = useDispatch(), history = useHistory();
  let domainBrandStatus, allDynamicFields;
  const [dynamicFormType, setDynamicFormType] = useState("REGISTERED_CUSTOMER_SIGNUP");
  const [mandatoryFields, setMandatoryFields] = useState([]);
  const [visibleFields, setVisibleFields] = useState([]);
  const { domainBrand, country, signUpCompliance, supportedCountries, user, theme, dynamicFields } = useSelector(
    ({ domainBrandReducer, countryReducer, complianceReducer, userReducer, themeReducer, dynamicFieldsReducer }) => ({
      domainBrand: domainBrandReducer,
      country: countryReducer,
      signUpCompliance: complianceReducer,
      supportedCountries: countryReducer["supportedCountries"],
      user: userReducer,
      theme: themeReducer,
      dynamicFields: dynamicFieldsReducer
    })
  );
  const formik = useFormik({
    initialValues: {
      type: typeOptions[0],
      email: "",
      firstName: "",
      lastName: "",
      phone: "",
      country: "",
      dateOfBirth: "",
      address1: "",
      address2: "",
      nationality: "",
      city: "",
      dialingCode: "",
      password: "",
      confirmPassword: "",
      postcode: "",
      companyName: "",
      companyRegistrationNumber: ""
    },
    validationSchema: Yup.object({
      firstName: Yup.string().when("type", {
        is: (val) => val.value === "INDIVIDUAL",
        then: Yup.string().required("Required")
      }),
      lastName: Yup.string().when("type", {
        is: (val) => val.value === "INDIVIDUAL",
        then: Yup.string().required("Required")
      }),
      companyName: Yup.string().when("type", {
        is: (val) => val.value === "COMPANY",
        then: Yup.string().required("Required")
      }),
      email: Yup.string().email("Invalid email").required("Required"),
      phone: Yup.string().required("Required"),
      country: Yup.object({ value: Yup.string().required("Required") }),
      dialingCode: Yup.object({ value: Yup.string().required("Required") }),
      password: Yup.string().required("Required"),
      confirmPassword: Yup.mixed()
        .test("match", "Passwords do not match", function(confirmPassword) {
          return confirmPassword === this.options.parent.password;
        })
        .required("Required")
    }),
    onSubmit: (values) => {
      let requiredFieldsIncomplete = false;
      let dynamicValues = dynamicFieldsFormikGetKeys(dynamicFields[dynamicFormType]["dynamicFields"], values);
      mandatoryFields.forEach(element => {
        if (values[element["fieldType"].toLowerCase()] === "") {
          requiredFieldsIncomplete = true;
          toast.error(`Error, ${element["fieldType"]} is required`, { position: toast.POSITION.TOP_CENTER });
        }
      });

      if (!requiredFieldsIncomplete) {
        const payloadData = {
          dynamicFields: { ...dynamicValues },
          companyName: values.companyName,
          companyRegistrationNumber: values.companyRegistrationNumber,
          customerType: values.type.value,
          dialingCode: values.dialingCode.value,
          password: values.password,
          confirmPassword: values.password,
          firstName: values.firstName,
          lastName: values.lastName,
          phone: values.phone,
          email: values.email,
          dob: values.dateOfBirth.toISOString(),
          nationality: values.nationality.value || "",
          address: {
            address1: values.address1,
            address2: values.address2,
            city: values.city,
            state: values.state,
            postcode: values.postcode,
            countryIso3: values.country.value,
            countryCommonName: values.country.label
          }
        };
        const navigateMethod = () => history.push("/login");
        // console.log(payloadData);
        dispatch(registerCustomer(payloadData, navigateMethod));
      }
    }
  });

  useEffect(() => {
    batch(() => {
      dispatch(getAllSupportedCountries());
      dispatch(fetchComplianceRequiredFields("ONLINE_SENDER"));
    });
  }, [dispatch]);

  useEffect(() => {
    (formik.values.type.value === "COMPANY") ? setDynamicFormType("COMPANY_CUSTOMER_SIGNUP")
      : (formik.values.type.value === "INDIVIDUAL") ? setDynamicFormType("REGISTERED_CUSTOMER_SIGNUP")
        : setDynamicFormType("REGISTERED_CUSTOMER_SIGNUP");
    dispatch(fetchDynamicFields(dynamicFormType));
  }, [formik.values.type, dynamicFormType]);

  useEffect(() => {
    if (country.loading !== Loading.SUCCESS || country.loading === Loading.ERROR) dispatch(getAllCountries());
  }, []);

  useEffect(() => {
    if (signUpCompliance.loading === Loading.SUCCESS) {
      setMandatoryFields(fieldsValidator(signUpCompliance["dynamicFields"]["dynamicFieldDTOs"])["reqArray"]);
      setVisibleFields(fieldsValidator(signUpCompliance["dynamicFields"]["dynamicFieldDTOs"])["visibleArray"]);
    }
  }, [signUpCompliance]);

  const countryLoading = country.loading === Loading.FETCHING;
  const customerCreationLoading = user.registerCustomerLoading === Loading.FETCHING;
  const url = domainBrand["domainBrand"]["publicWebSite"];
  const logOutUrl = url === null || url === "" || typeof url === "undefined" ? "/login" : url;
  let languageDictionary = domainBrand["domainBrand"]["languageDictionary"];
  domainBrandStatus = !(domainBrand["domainBrand"].length < 1 || domainBrand["domainBrand"] === null);
  allDynamicFields = dynamicFields.loading === Loading.FETCHING || typeof dynamicFields[dynamicFormType] === "undefined" ? [] : dynamicFields[dynamicFormType]["dynamicFields"];

  const fieldsValidator = (entity) => {
    let reqArray = [], visibleArray = [];
    entity.forEach(element => {
      if (element["mandatory"]) reqArray.push(element);
      if (element["visible"]) visibleArray.push(element);
    });
    return { reqArray, visibleArray };
  };

  const renderDynamicFields = allDynamicFields.map((eachField, index) => {
    return <DynamicFields divClass="col-6 mb-4" key={index} theme={theme} eachField={eachField} formik={formik} />;
  });

  return (
    <Container>
      <Helmet>
        <title>
          {domainBrandStatus ? domainBrand["domainBrand"]["languageDictionary"]["WO_SIGN_UP_FOR_AN_ACCOU_-546145385"] : "Sign Up"}{" "}
          &mdash; {domainBrandStatus ? domainBrand["domainBrand"]["companyName"] : "MultiView MTA"}
        </title>
      </Helmet>
      <NavBar />
      <InnerContainer>
        <OffsetIcon>
          <ImageElement src={offSetImg} title="money icon" />
        </OffsetIcon>

        <Main>
          <div className="row">
            <div className="col-6">
              <HeadingText
                text={domainBrandStatus ? domainBrand["domainBrand"]["languageDictionary"]["button_create_wallet_account"] : "Create an account"} />
            </div>
          </div>

          <form css={mainBody} onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-12 col-md-6">
                <div className="benCreate__main__form--control">
                  <Label className="benCreate__main__form--inputHeading">
                    {domainBrandStatus ? domainBrand["domainBrand"]["languageDictionary"]["l_type"] : "Type"}
                  </Label>
                  <Select
                    value={formik.values.type}
                    onChange={option => formik.setFieldValue("type", option)}
                    onBlur={() => formik.setFieldTouched("type", true)}
                    options={typeOptions}
                    placeholder="Please select..."
                    isClearable={false}
                    isSearchable={false}
                    styles={customStyles}
                    customTheme={theme}
                    components={{
                      IndicatorSeparator: null,
                      DropdownIndicator: DropDownComponent
                    }}
                  />
                </div>
              </div>

              {/*{!visibleFields.some(o => o["fieldType"] === "EMAIL") ? "" :
                  <div className="col-12 col-md-6">
                    <p className="benCreate__main__form--inputHeading">
                      Email<span style={{ color: "red" }}>*</span>
                    </p>
                    <CustomInput formik={formik} inputKey="email" inputType="email" />
                  </div>
                }*/}
              {/*<div className="col-md-6">
                <p className="benCreate__main__form--inputHeading">
                  {domainBrandStatus ? domainBrand["domainBrand"]["languageDictionary"]["l_registration_no"] : "Company Registration Number"}
                  <span style={{ color: "red" }}>*</span>
                </p>
                <CustomInput formik={formik} inputKey="companyRegistrationNumber" />
              </div>*/}


              {formik.values.type.label === "Company" ?
                <div className="col-12 col-md-6">
                  <p className="benCreate__main__form--inputHeading">
                    {domainBrandStatus ? domainBrand["domainBrand"]["languageDictionary"]["WO_COMPANY_NAM_-805761030"] : "Company Name"}
                    <span style={{ color: "red" }}>*</span>
                  </p>
                  <CustomInput formik={formik} inputKey="companyName" />
                </div> :
                <div className="col-12 col-md-6">
                  <p className="benCreate__main__form--inputHeading">
                    {domainBrandStatus ? domainBrand["domainBrand"]["languageDictionary"]["para_first_name"] : "First Name"}
                    <span style={{ color: "red" }}>*</span>
                  </p>
                  <CustomInput formik={formik} inputKey="firstName" />
                </div>
              }

              {formik.values.type.label === "Company" ?
                <div className="col-12 col-md-6">
                  <p className="benCreate__main__form--inputHeading">
                    {domainBrandStatus ? domainBrand["domainBrand"]["languageDictionary"]["WO_REPRESENTATIVE_FIRST_797939742"] : "Rep First Name"}
                    <span style={{ color: "red" }}>*</span>
                  </p>
                  <CustomInput formik={formik} inputKey="firstName" />
                </div> :
                <div className="col-12 col-md-6">
                  <p className="benCreate__main__form--inputHeading">
                    {domainBrandStatus ? domainBrand["domainBrand"]["languageDictionary"]["WO_MIDDLE_NAM_1847397348"] : "Middle Name"}
                  </p>
                  <CustomInput formik={formik} inputKey="middleName" />
                </div>
              }

              {formik.values.type.label === "Company" ?
                <div className="col-12 col-md-6">
                  <p className="benCreate__main__form--inputHeading">
                    {domainBrandStatus ? domainBrand["domainBrand"]["languageDictionary"]["WO_REPRESENTATIVE_LAST_-1662581052"] : "Rep Last Name"}
                    <span style={{ color: "red" }}>*</span>
                  </p>
                  <CustomInput formik={formik} inputKey="lastName" />
                </div> :
                <div className="col-12 col-md-6">
                  <p className="benCreate__main__form--inputHeading">
                    {domainBrandStatus ? domainBrand["domainBrand"]["languageDictionary"]["para_last_name"] : "Last Name"}
                  </p>
                  <CustomInput formik={formik} inputKey="lastName" />
                </div>
              }

              {formik.values.type.label === "Company" ?
                <div className="col-12 col-md-6">
                  <p className="benCreate__main__form--inputHeading">
                    {domainBrandStatus ? domainBrand["domainBrand"]["languageDictionary"]["l_registration_no"] : "Company Registration Number"}
                    <span style={{ color: "red" }}>*</span>
                  </p>
                  <CustomInput formik={formik} inputKey="companyRegistrationNumber" />
                </div> : ""
              }

              {!visibleFields.some(o => o["fieldType"] === "EMAIL") ? "" :
                <div className="col-12 col-md-6">
                  <p className="benCreate__main__form--inputHeading">
                    Email<span style={{ color: "red" }}>*</span>
                  </p>
                  <CustomInput formik={formik} inputKey="email" inputType="email" />
                </div>
              }

              {!visibleFields.some(o => o["fieldType"] === "PHONE") ? "" :
                <div className="col-12 col-sm-12 col-md-6">
                  <div className="row justify-content-end">
                    <div className="col-sm-12 col-md-6">
                      <p className="benCreate__main__form--inputHeading">
                        Dialling Code
                        <span style={{ color: "red" }}>*</span>
                      </p>
                      <ErrorField
                        formik={formik}
                        fieldName="dialingCode"
                        fetchingData={countryLoading}
                        selectField
                      />
                      <div css={selectWrapper}>
                        <Select
                          value={formik.values.dialingCode}
                          onChange={(option) => formik.setFieldValue("dialingCode", option)}
                          onBlur={() => formik.setFieldTouched("dialingCode", true)}
                          options={country.countryCodeDropdownList}
                          styles={customStyles}
                          isClearable={false}
                          //isSearchable={false}
                          customTheme={theme}
                          isLoading={countryLoading}
                          loadingMessage={() => "Fetching country codes"}
                          components={{
                            IndicatorSeparator: null,
                            DropdownIndicator: () => <DropDownComponent iconSource={downArrow} />
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-6">
                      <p className="benCreate__main__form--inputHeading">
                        {domainBrandStatus ? domainBrand["domainBrand"]["languageDictionary"]["para_phone_number"] : "Phone Number"}
                        {mandatoryFields.some(o => o["fieldType"] === "PHONE") ?
                          <span style={{ color: "red" }}>*</span> : ""
                        }
                      </p>
                      <CustomInput formik={formik} inputKey="phone" />
                    </div>
                  </div>
                </div>
              }

              {!visibleFields.some(o => o["fieldType"] === "DOB") ? "" :
                <div className="col-12 col-md-6" id="signup-dob">
                  {formik.values.type.value === "COMPANY" ?
                    <p className="benCreate__main__form--inputHeading">
                      {domainBrandStatus ? domainBrand["domainBrand"]["languageDictionary"]["WO_REPRESENTATIVE_DATE_703049064"] : "Date of Birth"}
                      {mandatoryFields.some(o => o["fieldType"] === "DOB") ?
                        <span style={{ color: "red" }}>*</span> : ""
                      }
                    </p> :
                    <p className="benCreate__main__form--inputHeading">
                      {domainBrandStatus ? domainBrand["domainBrand"]["languageDictionary"]["para_date_of_birth"] : "Date of Birth"}
                      {mandatoryFields.some(o => o["fieldType"] === "DOB") ?
                        <span style={{ color: "red" }}>*</span> : ""
                      }
                    </p>
                  }

                  <DatePicker
                    selected={formik.values.dateOfBirth}
                    onChange={(date) => formik.setFieldValue("dateOfBirth", date)}
                    onBlur={() => formik.setFieldTouched("dateOfBirth", true)}
                    format="YYYY-MM-DD"
                    css={modalFormDatePicker}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                  />
                </div>
              }

              <div className="col-12 col-md-6">
                <p className="benCreate__main__form--inputHeading">
                  {domainBrandStatus ? domainBrand["domainBrand"]["languageDictionary"]["l_password"] : "Password"}
                  <span style={{ color: "red" }}>*</span>
                </p>
                <CustomInput formik={formik} inputKey="password" inputType="password" />
              </div>
              <div className="col-12 col-md-6">
                <p className="benCreate__main__form--inputHeading">
                  {domainBrandStatus ? domainBrand["domainBrand"]["languageDictionary"]["para_confirm_password"] : "Confirm Password"}
                  <span style={{ color: "red" }}>*</span>
                </p>
                <CustomInput formik={formik} inputKey="confirmPassword" inputType="password" />
              </div>

              {!visibleFields.some(o => o["fieldType"] === "ADDRESS_1") ? "" :
                <div className="col-12 col-md-6">
                  <p className="benCreate__main__form--inputHeading">
                    {domainBrandStatus ? domainBrand["domainBrand"]["languageDictionary"]["para_address1"] : "Address Line 1"}
                    {mandatoryFields.some(o => o["fieldType"] === "ADDRESS_1") ?
                      <span style={{ color: "red" }}>*</span> : ""
                    }
                  </p>
                  <CustomInput formik={formik} inputKey="address1" />
                </div>
              }

              {!visibleFields.some(o => o["fieldType"] === "ADDRESS_2") ? "" :
                <div className="col-12 col-md-6">
                  <p className="benCreate__main__form--inputHeading">
                    {domainBrandStatus ? domainBrand["domainBrand"]["languageDictionary"]["para_address_line_2"] : "Address Line 2"}
                    {mandatoryFields.some(o => o["fieldType"] === "ADDRESS_2") ?
                      <span style={{ color: "red" }}>*</span> : ""
                    }
                  </p>
                  <CustomInput formik={formik} inputKey="address2" />
                </div>
              }

              {!visibleFields.some(o => o["fieldType"] === "CITY") ? "" :
                <div className="col-12 col-md-6">
                  <p className="benCreate__main__form--inputHeading">
                    {domainBrandStatus ? domainBrand["domainBrand"]["languageDictionary"]["l_city"] : "City"}
                    {mandatoryFields.some(o => o["fieldType"] === "CITY") ?
                      <span style={{ color: "red" }}>*</span> : ""
                    }
                  </p>
                  <CustomInput formik={formik} inputKey="city" />
                </div>
              }

              {!visibleFields.some(o => o["fieldType"] === "POSTCODE") ? "" :
                <div className="col-12 col-md-6">
                  <p className="benCreate__main__form--inputHeading">
                    {domainBrandStatus ? domainBrand["domainBrand"]["languageDictionary"]["para_postcode"] : "Area / Postcode"}
                    {mandatoryFields.some(o => o["fieldType"] === "POSTCODE") ?
                      <span style={{ color: "red" }}>*</span> : ""
                    }
                  </p>
                  <CustomInput formik={formik} inputKey="postcode" />
                </div>
              }

              {!visibleFields.some(o => o["fieldType"] === "STATE") ? "" :
                <div className="col-12 col-md-6">
                  <p className="benCreate__main__form--inputHeading">
                    {domainBrandStatus ? domainBrand["domainBrand"]["languageDictionary"]["WO_STAT_-878960465"] : "State"}
                    {mandatoryFields.some(o => o["fieldType"] === "STATE") ?
                      <span style={{ color: "red" }}>*</span> : ""
                    }
                  </p>
                  <CustomInput formik={formik} inputKey="state" />
                </div>
              }

              <div className="col-12 col-md-6">
                <p className="benCreate__main__form--inputHeading">
                  {domainBrandStatus ? domainBrand["domainBrand"]["languageDictionary"]["WO_COUNTR_1271562677"] : "Country"}
                  <span style={{ color: "red" }}>*</span>
                </p>
                <ErrorField
                  formik={formik}
                  fieldName="country"
                  fetchingData={countryLoading}
                  selectField
                />{" "}
                <div css={selectWrapper}>
                  <Select
                    value={formik.values.country}
                    onChange={(option) => formik.setFieldValue("country", option)}
                    onBlur={() => formik.setFieldTouched("country", true)}
                    styles={customStyles}
                    isClearable={false}
                    isSearchable={true}
                    customTheme={theme}
                    isLoading={countryLoading}
                    options={supportedCountries}
                    placeholder={domainBrandStatus ? languageDictionary["para_country"] : "Select Country"}
                    components={{
                      IndicatorSeparator: null,
                      DropdownIndicator: () => <DropDownComponent iconSource={downArrow} />
                    }}
                  />
                </div>
              </div>

              {renderDynamicFields}

            </div>
            <Row>
              <h4>
                Already have an account ?{" "}
                <a href={logOutUrl}>
                  {domainBrandStatus ? languageDictionary["b_login"] : "Log in"}
                </a>
              </h4>
              <div css={buttonWrapper}>
                <CustomButton buttonText={domainBrandStatus ? languageDictionary["button_register"] : "CREATE USER"}
                              loading={customerCreationLoading} />
              </div>
            </Row>
          </form>

        </Main>
      </InnerContainer>
      <Footer />
    </Container>
  );
};

export default RegisterCustomer;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import NavBar from "Components/Navbar";
import Footer from "Components/Footer";
import HeadingText from "Components/HeadingText";
import offSetImg from "Assets/svg/funds.svg";
import { filterAccountStatement } from "Utils/DummyData/dropDown";
import { General } from "Utils/Helpers";
import { getAccountStatement, getAccountBalances } from "Store/actions/account.action";
import Spinner from "Components/Spinner";
import Pagination from "Components/Pagination";
import usePagination from "Components/Pagination/usePagination";
import { filterData, daysFromToday } from "Utils/Helpers/general";
import ReceiptHeader from "Components/ReceiptHeader";
import AccountSummary from "Components/AccountSummary";

const formatNum = (num) =>
  num
    ? Number(num).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })
    : "0.00";

const selectClassName = (num) => {
  if (num > 0) {
    return "ast_row_green";
  } else if (num < 0) {
    return "ast_row_red";
  } else {
    return "";
  }
};

const TableRow = ({ tableData }) => {
  const {
    executionDate,
    pcn,
    beneficiaryName,
    transactionAmount,
    paymentReference,
    newBalance,
    currencyCode
  } = tableData;

  return (
    <tr>
      <td>
        <span>{General.formatDate(executionDate) || "-"}</span>
      </td>
      <td>
        <span>{pcn || "-"}</span>
      </td>
      <td>
        <span>{beneficiaryName || "-"}</span>
      </td>
      <td>
        <span>{paymentReference || "-"}</span>
      </td>
      <td>
        <span className={selectClassName(transactionAmount)}>
          {(transactionAmount && transactionAmount) || "-"}
        </span>
      </td>
      <td>
        <span>{`${currencyCode}${formatNum(newBalance)}` || "-"}</span>
      </td>
    </tr>
  );
};

function useCustomHook(accountSelector, statementMethod) {
  const [selectedAccount, setSelectedAccount] = useState({});
  const checkAccountLength =
    Object.keys(selectedAccount).length < 1 && accountSelector.dropDownList.length > 0;

  useEffect(() => {
    const selectedAccountExists = Object.keys(selectedAccount).length > 0;
    if (checkAccountLength) {
      setSelectedAccount(accountSelector.dropDownList[0]);
    } else if (selectedAccountExists) {
      statementMethod(selectedAccount);
    }
  }, [accountSelector.dropDownList, checkAccountLength, selectedAccount]);

  return [selectedAccount, setSelectedAccount];
}

const AccountStatement = () => {
  const dispatch = useDispatch();
  const [account] = useSelector(({ accountReducer }) => [accountReducer]);
  const [fromDate] = useState(daysFromToday());
  const [toDate] = useState(new Date());
  const [filterText] = useState("");
  const [filterSection] = useState(filterAccountStatement[0]);
  const [collection, setCollection] = useState([]);

  const statementFetch = (selectCurrency, selectAccount) => {
    handleSearch(selectCurrency, toDate, fromDate, selectAccount);
  };

  const [selectedAccount, setSelectedAccount] = useCustomHook(account, statementFetch);

  const handleSearch = (_selectedCurrency, _toDate, _fromDate, _selectedAccount) => {
    dispatch(
      getAccountStatement({
        selectedCurrency: _selectedCurrency.value,
        toDate: _toDate,
        fromDate: _fromDate,
        accountNumber: _selectedAccount.value
      })
    );
  };

  const PAGE_LIMIT = 5;
  const [currentData, onPageChange] = usePagination({
    collection,
    pageLimit: PAGE_LIMIT
  });

  useEffect(() => {
    const data = account.statement.walletStatement;
    if (data.length > 0) {
      const filteredData = filterData(data, filterText, filterSection.value);
      setCollection(filteredData);
    }
  }, [filterText, filterSection.value, account.statement.walletStatement]);

  useEffect(() => {
    dispatch(getAccountBalances());
  }, []);

  return (
    <div className="ast_body">
      <NavBar />
      <main className="ast_main">
        <div className="ast__canvasImg">
          <img src={offSetImg} alt="off canvas icon" />
        </div>

        <div className="ast_tableSection">
          <div>
            <ReceiptHeader />
          </div>

          <div className="ast-heading--text">
            <HeadingText text="Account Summary" />
          </div>

          <div>
            <AccountSummary />
          </div>
          <div className="ast-heading--text">
            <HeadingText text="Account Statement" />
          </div>
          <React.Fragment>
            <section className="ast_tableSection">
              <div className="ast_table">
                {account.statement.loadingStatus === "FETCHING" ? (
                  <Spinner containerHeight="40vh" />
                ) : (
                  <React.Fragment>
                    <div className="scrollable-table">
                      <table>
                        <thead>
                        <tr>
                          <th scope="col">Ref</th>
                          <th scope="col">Beneficiary</th>
                          <th scope="col">Payment Reference</th>
                          <th scope="col">Amount</th>
                          <th scope="col">Balance</th>
                          <th scope="col">Date</th>
                        </tr>
                        </thead>
                        <tbody>
                        {currentData < 1 ? (
                          <>
                            <tr>
                              <td colSpan="6" className="text-center bg-grey">
                                <span>You haven't ordered anything yet</span>
                              </td>
                            </tr>
                          </>
                        ) : (
                          <>
                            {currentData.map((iData, index) => (
                              <TableRow tableData={iData} key={index} />
                            ))}
                          </>
                        )}
                        </tbody>
                      </table>
                    </div>
                    <Pagination
                      resultName="Total Accounts"
                      onPageChange={onPageChange}
                      pageLimit={PAGE_LIMIT}
                      totalRecords={collection.length}
                    />
                  </React.Fragment>
                )}
              </div>
            </section>
          </React.Fragment>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default AccountStatement;

export const FETCH_COUNTRY_LIST_REQUEST = "FETCH_COUNTRY_LIST_REQUEST";
export const FETCH_COUNTRY_LIST_SUCCESS = "FETCH_COUNTRY_LIST_SUCCESS";
export const FETCH_COUNTRY_LIST_FAILURE = "FETCH_COUNTRY_LIST_FAILURE";

export const FETCH_BILL_SERVICE_BY_COUNTRY_REQUEST = "FETCH_BILL_SERVICE_BY_COUNTRY_REQUEST";
export const FETCH_BILL_SERVICE_BY_COUNTRY_SUCCESS = "FETCH_BILL_SERVICE_BY_COUNTRY_SUCCESS";
export const FETCH_BILL_SERVICE_BY_COUNTRY_FAILURE = "FETCH_BILL_SERVICE_BY_COUNTRY_FAILURE";

export const FETCH_OPERATORS_BY_SERVICE_REQUEST = "FETCH_OPERATORS_BY_SERVICE_REQUEST";
export const FETCH_OPERATORS_BY_SERVICE_SUCCESS = "FETCH_OPERATORS_BY_SERVICE_SUCCESS";
export const FETCH_OPERATORS_BY_SERVICE_FAILURE = "FETCH_OPERATORS_BY_SERVICE_FAILURE";

export const FETCH_PRODUCTS_BY_OPERATOR_REQUEST = "FETCH_PRODUCTS_BY_OPERATOR_REQUEST";
export const FETCH_PRODUCTS_BY_OPERATOR_SUCCESS = "FETCH_PRODUCTS_BY_OPERATOR_SUCCESS";
export const FETCH_PRODUCTS_BY_OPERATOR_FAILURE = "FETCH_PRODUCTS_BY_OPERATOR_FAILURE";

export const FETCH_QUOTE_FOR_BILL_PAYMENT_REQUEST = "FETCH_QUOTE_FOR_BILL_PAYMENT_REQUEST";
export const FETCH_QUOTE_FOR_BILL_PAYMENT_SUCCESS = "FETCH_QUOTE_FOR_BILL_PAYMENT_SUCCESS";
export const FETCH_QUOTE_FOR_BILL_PAYMENT_FAILURE = "FETCH_QUOTE_FOR_BILL_PAYMENT_FAILURE";

export const INITIATE_BILL_PAYMENT_REQUEST = "INITIATE_BILL_PAYMENT_REQUEST";
export const INITIATE_BILL_PAYMENT_SUCCESS = "INITIATE_BILL_PAYMENT_SUCCESS";
export const INITIATE_BILL_PAYMENT_FAILURE = "INITIATE_BILL_PAYMENT_FAILURE";

export const LIST_BILL_PAYMENT_METHODS_REQUEST = "LIST_BILL_PAYMENT_METHODS_REQUEST";
export const LIST_BILL_PAYMENT_METHODS_SUCCESS = "LIST_BILL_PAYMENT_METHODS_SUCCESS";
export const LIST_BILL_PAYMENT_METHODS_FAILURE = "LIST_BILL_PAYMENT_METHODS_FAILURE";

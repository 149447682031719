/** @jsx jsx */
/** @jsxRuntime classic */
import { jsx, css, keyframes } from '@emotion/react';

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const containerStyle = (containerHeight, containerWidth) => {
  return css`
    height: ${containerHeight};
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${containerWidth || 'auto'};
  `;
};

const Spinner = ({
  containerHeight,
  spinnerSize,
  borderSize,
  containerWidth,
}) => {
  const borderWidth = borderSize || '6px';
  const spinnerDimension = spinnerSize || '70px';
  const wrapperHeight = containerHeight || '70vh';

  return (
    <div css={containerStyle(wrapperHeight, containerWidth)}>
      <div
        css={(theme) => css`
          height: ${spinnerDimension};
          width: ${spinnerDimension};
          border: ${borderWidth} solid #f3f3f3;
          border-radius: 50%;
          border-top: ${borderWidth} solid ${theme.primaryColor};
          animation: ${spin} 2s linear infinite;
        `}
      />
    </div>
  );
};

export default Spinner;

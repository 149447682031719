// eslint-disable-next-line no-unused-vars
import React from 'react';
/** @jsx jsx */
/** @jsxRuntime classic */
import { jsx } from '@emotion/react';
import {
  bannerText,
  topRightCircle,
  bottomRightCircle,
  bottomImgDark,
  circleOne,
  circleTwo,
  circleThree,
  rightSection,
} from './style';
import { ReactComponent as FundsBlueImg } from 'Assets/svg/fundsBlue.svg';

const RightBanner = () => {
  return (
    <div css={rightSection}>
      <div css={bannerText}>
        <p>The simplest way</p>
        <p>to set up your</p>
        <p>digital banking service</p>
      </div>

      <div css={topRightCircle}>
        <div />
      </div>
      <div css={bottomRightCircle}>
        <div />
      </div>

      <div css={circleOne} />
      <div css={circleTwo} />
      <div css={circleThree} />

      <div css={bottomImgDark}>

        <FundsBlueImg />
      </div>
    </div>
  );
};

export default RightBanner;

export const GET_REQUIRED_COMPLIANCE_FIELDS_REQUEST = 'GET_REQUIRED_COMPLIANCE_FIELDS_REQUEST';
export const GET_REQUIRED_COMPLIANCE_FIELDS_SUCCESS = 'GET_REQUIRED_COMPLIANCE_FIELDS_SUCCESS';
export const GET_REQUIRED_COMPLIANCE_FIELDS_FAILURE = 'GET_REQUIRED_COMPLIANCE_FIELDS_FAILURE';

export const GET_COMPLIANCE_REQUEST = 'GET_COMPLIANCE_REQUEST';
export const GET_COMPLIANCE_SUCCESS = 'GET_COMPLIANCE_SUCCESS';
export const GET_COMPLIANCE_FAIL = 'GET_COMPLIANCE_FAIL';

export const UPLOAD_COMPLIANCE_LATER_REQUEST = 'UPLOAD_COMPLIANCE_LATER_REQUEST';
export const UPLOAD_COMPLIANCE_LATER_SUCCESS = 'UPLOAD_COMPLIANCE_LATER_SUCCESS';
export const UPLOAD_COMPLIANCE_LATER_FAIL = 'UPLOAD_COMPLIANCE_LATER_FAIL';


import styled from '@emotion/styled';

export const CardContainer = styled.div`
  border: 1px solid #e3edf3;
  border-radius: 10px;
  padding: 45px;
  width: 48.5%;
  margin-top: 30px;

  @media (max-width: 56.25rem) {
    padding: 1rem;
    width: 100%;
  }
`;

export const FormContainer = styled.form`
  margin-top: 30px;
`;

import Axios from "./Axios.service";

export const fetchRequiredFieldsByType = async (type) => {
  return Axios({
    method: "GET",
    url: `web-services/api/v6/services/complaince/required-fields-by-operation/${type}`,
  });
};

export const complianceForm = async (country, kyc_trigger) => {
  return Axios({
    method: "GET",
    url: `/web-services/api/v6/services/complaince/kyc-requirements-by-isocountry/${country}/${kyc_trigger}`,
    headers: {
      platform: "fincode",
      UUID: 200
    }
  });
};

export const fetchRequiredTransDocx = async (payload) => {
  return Axios({
    data: payload,
    method: "POST",
    url: `web-services/api/v6/services/complaince/documents-required-for-transaction`,
    headers: {
      platform: "fincode",
      UUID: 200
    }
  });
};


export const fetchComplianceForm = async () => {
  return Axios({
    method: "GET",
    url: `/web-services/api/v6/services/complaince/awaiting-compliance-requirements`,
    headers: {
      platform: "fincode",
      UUID: 200
    }
  });
};

const customStyles = {
  option: (provided, state) => {
    const {
      selectProps: {
        customTheme: { primaryColor },
      },
    } = state;
    return {
      ...provided,
      borderBottom: `${
        state.value === state.options[state.options.length - 1].value
          ? null
          : '1px solid #90A8BE47'
      }`,
      color: state.isSelected ? '#fff' : primaryColor,
      padding: '15px 20px',
      fontSize: '14px',
      fontWeight: 300,
      // ":active": {
      //   backgroundColor: 'red'
      // },
      // background: state.isSelected ? "red" :  "transparent"
      // ":hover": {
      //   color: "yellow"
      // }
    };
  },
  control: (provided, state) => {
    const {
      selectProps: {
        customTheme: { primaryColor },
      },
    } = state;

    return {
      ...provided,
      background: '#E3EDF3',
      border: 0,
      color: primaryColor,
      fontWeight: 300,
      borderRadius: 0,
      borderTopLeftRadius: '4px',
      borderBottomLeftRadius: '4px',
    };
  },
  placeholder: (provided, state) => {
    const {
      selectProps: {
        customTheme: { primaryColor },
      },
    } = state;

    return {
      ...provided,
      color: primaryColor,
      fontSize: '14px',
      fontWeight: 300,
    };
  },
  container: (provided) => ({
    ...provided,
    marginTop: 12,
  }),
  menu: (provided) => {
    return {
      ...provided,
      margin: 0,
      background: '#F5F9FB',
      borderRadius: 0,
      padding: 0,
    };
  },
  menuList: (provided) => {
    return {
      ...provided,
      padding: 0,
    };
  },
  indicatorsContainer: (provided) => {
    return {
      ...provided,
      paddingLeft: 8,
      paddingRight: 20,
    };
  },
  input: (provided) => {
    return {
      ...provided,
      padding: 0,
      margin: 0,
    };
  },
  singleValue: (provided, state) => {
    const {
      selectProps: {
        customTheme: { primaryColor },
      },
    } = state;

    return {
      ...provided,
      color: primaryColor,
      fontSize: '14px',
      paddingTop: '5px',
      paddingBottom: '5px',
    };
  },
  valueContainer: (provided) => {
    return {
      ...provided,
      padding: '15px 20px',
    };
  },
};

export default customStyles;

import * as Category from "Store/constants/category.constants";

const initialState = {
  list: [],
  loading: false,
  createLoading: false,
  dropDownList: [],
  categoryModal: false,
};

const formatForDropdown = (payloadData) => {
  return payloadData.map((item) => ({
    ...item,
    value: item.id,
    label: `${item.name}`,
  }));
};

const categoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case Category.FETCH_CATEGORY_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case Category.FETCH_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload,
        dropDownList: formatForDropdown(action.payload),
      };

    case Category.FETCH_CATEGORY_FAILURE:
      return {
        ...state,
        list: [],
        loading: false,
      };

    case Category.CREATE_CATEGORY_REQUEST:
      return {
        ...state,
        createLoading: true,
      };

    case Category.CREATE_CATEGORY_SUCCESS:
    case Category.CREATE_CATEGORY_FAILURE:
      return {
        ...state,
        createLoading: false,
        categoryModal: true,
      };

    default:
      return state;
  }
};

export default categoryReducer;

import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Footer from "Components/Footer";
import NavBar from "Components/Navbar";
import Loader from "Components/loader/Loader";
import Spinner from "Components/Spinner";
import Product from "Components/Product";
import { Button, Input } from "./style";
import { useDispatch, useSelector } from "react-redux";
import * as MerchantActions from "Store/actions/merchant.action";
import charging from "Assets/svg/charging.svg";
import BackArrow from "Components/BackArrow";

const MerchantProducts = () => {
  const dispatch = useDispatch();
  const [filteredProducts, setFilteredProducts] = useState(null);
  const { merchant } = useSelector(({ merchantReducer }) => ({
    merchant: merchantReducer
  }));

  useEffect(() => {
    window.scrollTo(0, 0);

    dispatch(MerchantActions.getMerchantCategoryByCountry("NGA"));
    dispatch(MerchantActions.getProducts());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = (event) => {
    const { value } = event.target;
    if (!value) {
      setFilteredProducts(null);
      return;
    }
    const searchTerm = value.toLowerCase();
    const filtered = merchant.products.filter((product) => {
      const productName = product.product_name.toLowerCase();
      return productName.includes(searchTerm);
    });
    setFilteredProducts(filtered);
  };

  const handleFilter = (service) => {
    if (!service) {
      setFilteredProducts(null);
      return;
    }
    const serviceId = service.toLowerCase();
    const filtered = merchant.products.filter((product) => {
      const merchantServiceId = product.service.toLowerCase();
      return merchantServiceId === serviceId;
    });
    setFilteredProducts(filtered);
  };

  return (
    <>
      <Helmet>
        <title>Merchant Products</title>
      </Helmet>
      <NavBar />
      <section>
        {merchant.productsLoadingStatus === "FETCHING" ? (
          <Loader />
        ) : (
          <div className="container">
            <div className="mt-5 mb-5">
              <div className="d-flex">
                <BackArrow />
                <h2 className="text-blue mt-4">Merchant Product</h2>
              </div>
              <div className="card mt-5 p-lg-5">
                <div className="card-body mb-3 p-md-3">
                  {/* --- Search box for product --- */}
                  <div className="row mb-5">
                    <div className="col-12 col-lg-8 col-xl-6">
                      <p className="text-blue">Search product</p>
                      <Input
                        type="text"
                        className="form-control mb-5"
                        placeholder="Type in a product name....."
                        onChange={handleSearch}
                      />
                      {merchant.productsLoadingStatus === "FETCHING" ? (
                        <Spinner containerHeight="30vh" />
                      ) : (
                        <div className="row d-flex justify-content-evenly">
                          <div className="col-12 col-md-4 mb-3">
                            <Button
                              className="btn btn-block text-uppercase"
                              onClick={() => handleFilter()}>
                              All
                            </Button>
                          </div>
                          {merchant.services.map((service) => (
                            <div className="col-12 col-md-4 mb-3" key={service.service_id}>
                              <Button
                                className="btn btn-block text-uppercase"
                                onClick={() => handleFilter(service.service)}>
                                {service.service}
                              </Button>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>

                  {/* --- Products list --- */}
                  <div className="mb-5">
                    <div className="row">
                      {(filteredProducts || merchant.products).map((product) => (
                        <Product
                          key={product.product_id}
                          name={product.product_name}
                          amount={product.product_value}
                          imageSrc={product.productMainPhotoUrl || charging}
                          currency={product.product_currency}
                          service={product.service}
                          product={product}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </section>

      <Footer />
    </>
  );
};

export default MerchantProducts;

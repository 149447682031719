import { css } from '@emotion/react';
import styled from '@emotion/styled';
import mediaQueries from 'Utils/mediaQuery';

export const modalFormColumn = css`
  flex-basis: 47.5%;
  max-width: 47.5%;
`;

export const modalFormDatePicker = css`
  margin-top: 12px;
  background: #e3edf3;
  border: 0;
  width: 100% !important;
  padding: 15px 10px;
  border-radius: 4px;
  color: var(--primary-color);
  font-size: 15px;
  font-weight: 300;
  display: block !important;
`;

export const mainBody = css`
  border: 1px solid #e3edf3;
  border-radius: 10px;
  background: #ffffff;
  padding: 30px 40px;
  /* width: 48.5%; */
  margin-top: 60px;
  margin-bottom: 50px;

  @media (max-width: 56.25rem) {
    padding: 1rem;
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  ${mediaQueries.tablet`
    flex-direction: column;
  `}
`;

export const selectWrapper = css`
  margin-bottom: 30px;
  margin-top: 1rem;
`;

export const selectDropdownContainer = css`
  flex-basis: 47.5%;
`;

export const areaDate = css`
  flex-basis: 47.5%;

  // &:first-of-type {
  //   max-width: 130px;
  // }
`;

export const buttonWrapper = css`
  display: flex;
  justify-content: flex-end;
`;

// eslint-disable-next-line no-unused-vars
import React, { useState } from "react";
import { Helmet } from "react-helmet";
/** @jsx jsx */
/** @jsxRuntime classic */
import { jsx, css } from "@emotion/react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import HeadingText from "Components/HeadingText";
import NavBar from "Components/Navbar";
import Footer from "Components/Footer";
import { Container, Main, InnerContainer, OffsetIcon, ImageElement } from "Components/Styled";
import CustomInput from "Components/CustomInput";
import { mainBody, buttonWrapper, Row } from "./style";
import CustomButton from "Components/CustomButton";
import { useHistory } from "react-router-dom";
import { changeUserPassword } from "Store/actions/user.action";
import offSetImg from "Assets/svg/man-user-1.svg";
import BackArrow from "Components/BackArrow";

const ChangePassword = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisibility = () => {
    setPasswordShown(!passwordShown);
  };

  const toggleIcon = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="#0055AD">
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
        />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
        />
      </svg>
    );
  };

  const { user, domainBrand } = useSelector(({ userReducer, domainBrandReducer }) => ({
    user: userReducer,
    domainBrand: domainBrandReducer
  }));

  // Language state
  const languageDictionary = domainBrand.domainBrand["languageDictionary"];
  const languageDictionaryStatus = !(
    typeof languageDictionary === "undefined" || languageDictionary === null
  );

  const formik = useFormik({
    initialValues: {
      newPassword: "",
      currentPassword: "",
      confirmPassword: ""
    },

    validationSchema: Yup.object({
      currentPassword: Yup.string().required("required"),
      newPassword: Yup.string().required("required"),

      confirmPassword: Yup.mixed()
        .test("match", "Passwords do not match", function (confirmPassword) {
          return confirmPassword === this.options.parent.newPassword;
        })
        .required("required")
    }),

    onSubmit: (values) => {
      const payloadData = {
        newPassword: values.newPassword,
        currentPassword: values.currentPassword
      };

      const navigateMethod = () => history.push("/");
      dispatch(changeUserPassword(payloadData, navigateMethod));
    }
  });

  return (
    <Container>
      <Helmet>
        <title>
          {languageDictionaryStatus ? languageDictionary["m_change_password"] : "Change Password"}
        </title>
      </Helmet>
      <NavBar />
      <InnerContainer>
        <OffsetIcon>
          <ImageElement src={offSetImg} title="money icon" />
        </OffsetIcon>

        <Main className="mt-3">
          <div className="d-flex">
            <BackArrow />
            <HeadingText
              text={
                languageDictionaryStatus
                  ? languageDictionary["m_change_password"]
                  : "Change Password"
              }
            />
          </div>
          <form css={mainBody} onSubmit={formik.handleSubmit}>
            <Row>
              <CustomInput
                formik={formik}
                inputKey="currentPassword"
                labelName={
                  languageDictionaryStatus
                    ? languageDictionary["para_current_password"]
                    : "Current Password"
                }
                // inputSize={47.5}
                inputSize={"100%"}
                inputType="password">
                <btn
                  role="button"
                  onClick={togglePasswordVisibility}
                  onKeyDown={togglePasswordVisibility}>
                  {toggleIcon}
                </btn>
                Have
              </CustomInput>

              <CustomInput
                formik={formik}
                inputKey="newPassword"
                labelName={
                  languageDictionaryStatus
                    ? languageDictionary["para_new_password"]
                    : "New Password"
                }
                inputSize={"100%"}
                // inputSize={47.5}
                inputType="password"
              />

              <CustomInput
                formik={formik}
                inputKey="confirmPassword"
                labelName={
                  languageDictionaryStatus
                    ? languageDictionary["para_confirm_password"]
                    : "Confirm Password"
                }
                inputSize={"100%"}
                inputType="password"
              />

              <div css={buttonWrapper}>
                <CustomButton
                  buttonText={languageDictionaryStatus ? languageDictionary["button_update"] : "UPDATE"}
                  loading={user.changePasswordLoading}
                />
              </div>
            </Row>
          </form>
        </Main>
      </InnerContainer>
      <Footer />
    </Container>
  );
};

export default ChangePassword;

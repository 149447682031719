import React from 'react';
import spinnerIcon from 'Assets/svg/spinner-of-dots.svg';
import './index.scss';

const buttonSpinner = () => {
  return (
    <div className="buttonSpinner">
      <img src={spinnerIcon} alt="loader" />
    </div>
  );
};

export default buttonSpinner;

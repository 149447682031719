import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import BackArrow from "../../../../Components/BackArrow";
import HeadingText from "../../../../Components/HeadingText";
import NavBar from "../../../../Components/Navbar";
import offSetImg from "../../../../Assets/svg/transfer1.svg";
import Footer from "../../../../Components/Footer";
import SubHeadingText from "../../../../Components/SubHeadingText";
import {
  ButtonAlign,
  CardBox,
  FlexFormWrapper,
  FundFormRow,
  MiniContainer
} from "../../../Dashboard/FundAccount/styled";
import FormSelect from "../../../../Components/FormSelect";
import CustomButton from "../../../../Components/CustomButton";
import CustomInput from "../../../../Components/CustomInput";
import { useHistory } from "react-router-dom";
import CashierSenderCard from "../../../../Components/CashierSenderCard";
import TransferService from "../../../../Utils/Services/Transfer.service";
import { toast } from "react-toastify";


const GetCustomerDetails = () => {
  const history = useHistory();
  const prevLocation = history.location.state.prevLocation;
  const pageStatus = history.location.state.pageStatus;
  const { domainBrand } = useSelector(({ domainBrandReducer }) => ({ domainBrand: domainBrandReducer }));
  const languageDictionary = domainBrand.domainBrand["languageDictionary"];
  const languageDictionaryStatus = !(typeof languageDictionary === "undefined" || languageDictionary === null);
  const [lookupUser, updateLookup] = useState({});

  const searchTypes = [
    { "label": "Phone Number", "value": "phoneNumber" },
    { "label": "Account Number", "value": "findbyAccountNumber" },
    { "label": "Email Address", "value": "emailAddress" },
    { "label": "Customer Code", "value": "customerCode" }];

  const formik = useFormik({
    initialValues: {
      searchParam: "",
      searchType: ""
    },
    onSubmit: async (values) => {
      const payload = { [values.searchType.value]: values.searchParam };
      const { data: responseData } = await TransferService.accountInfoLookup(payload);
      if (responseData["status"] === "FAILED") {
        updateLookup({});
        toast.error(`${responseData["message"]}`, { position: toast.POSITION.TOP_CENTER });
      } else updateLookup(responseData.data);
    }
  });

  return (
    <div className="fdt_body">
      <NavBar />
      <main className="fdt_main">
        <div className="fdt__canvasImg">
          <img src={offSetImg} alt="off canvas icon" />
        </div>
        <section className="fdt_topSection">
          <div className="d-flex">
            <HeadingText text={"Get Sender"} />
          </div>
          <div className="fdt_subHeading">
            <SubHeadingText
              text={languageDictionaryStatus ? languageDictionary["l_sender_details"] : "Sender Details"} />
          </div>
          <div>
            <FlexFormWrapper onSubmit={formik.handleSubmit}>
              <CardBox>
                <FundFormRow>
                  <FormSelect
                    formik={formik}
                    options={searchTypes}
                    selectLabel="Please select the search method"
                    selectName="searchType"
                  />
                </FundFormRow>
                <CustomInput
                  formik={formik}
                  inputKey="searchParam"
                  inputSize="200"
                  labelName={languageDictionaryStatus ? languageDictionary["FIND_ACCOUN_31978564"] : "Find Account"}
                />
                <ButtonAlign>
                  <CustomButton
                    buttonText={languageDictionaryStatus ? languageDictionary["para_find_sender_"] : "Find Sender"} />
                </ButtonAlign>
              </CardBox>

              <MiniContainer>
                <div>
                  {Object.entries(lookupUser).length === 0 ? "" :
                    <CashierSenderCard lookup={lookupUser} language={languageDictionary} pageStatus={pageStatus}
                                       langState={languageDictionaryStatus} link={prevLocation} />}
                </div>
              </MiniContainer>
            </FlexFormWrapper>
          </div>
        </section>
      </main>
      <Footer />
    </div>

  );
};

export default GetCustomerDetails;

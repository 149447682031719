import * as Transfer from "Store/constants/transfer.constants";
import * as Loading from "Store/constants/loadingState.constants";
import { updateCancelledTrnx } from "Utils/Helpers/dataModifier";

const initialState = {
  transferHistory: [],
  transferFundsCountries: [],
  transferHistoryLoading: false,
  transferChart: { active: [], all: [] },
  beneficiaryBankInfo: [],
  beneficiaryBankInfoStatus: "",
  quoteStatus: "",
  quoteData: {},
  paymentMethod: {},
  paymentMethodStatus: "",
  bankField: {},
  bankFields: [],
  bankFieldStatus: "",
  createTransactionStatus: "",
  requiredDocuments: {},
  createTransactionData: {},
  paymentStatus: "",
  checkTransactionStatus: "",
  transactionStatusDetails: {},
  accountInfoLookupStatus: "",
  accountInfoLookupData: {},
  walletToWalletStatus: "",
  walletToWalletData: {},
  fundWalletStatus: "",
  fundWalletData: {},
  transactionDetails: {},
  transactionDetailsStatus: "",
  cancelTransactionStatus: ""
};

const colorFill = {
  pendingCount: { displayName: "Pending", color: "#0088FE" },
  expiredCount: { displayName: "Expired", color: "#FFBB28" },
  confirmedCount: { displayName: "Confirmed", color: "#00C49F" },
  cancelledCount: { displayName: "Cancelled", color: "#FF8042" },
  voidCount: { displayName: "Void", color: "#ccb9a3" },
  refundedCount: { displayName: "Refunded", color: "#23f0ec" },
  paidOutCount: { displayName: "Paid Out", color: "#109f52" },
  failedCount: { displayName: "Failed", color: "#d73f37" },
  heldCount: { displayName: "Held", color: "#a95c90" },
  pendingPaymentCount: { displayName: "Pending Payment", color: "#e45769" },
  totalCount: { displayName: "Total", color: "#21b0e4" }
};

const formatForDropdown = (payloadData) => {
  return payloadData.map((item) => ({
    ...item,
    value: item.id,
    label: `${item.bankName ? item.bankName : ""} ${item.accountNumber ? item.accountNumber : ""}`
  }));
};

const formatDataForChart = (pureData) =>
  Object.keys(pureData).reduce((prev, curr) => {
      const isKeyExists = curr !== 'transactionHistory' && curr !== 'totalCount';
      if (isKeyExists) {
        prev.all.push({
          name: colorFill[curr].displayName,
          value: pureData[curr],
          color: colorFill[curr].color
        });

        if (pureData[curr] > 0) {
          prev.active.push({
            name: colorFill[curr].displayName,
            value: pureData[curr],
            color: colorFill[curr].color
          });
        }
      }
      return prev;
    },
    { active: [], all: [] }
  );

const removeEmpty = (obj) => {
  Object.keys(obj).forEach((key) => (obj[key] === null || obj[key] === "") && delete obj[key]);
};

const transferReducer = (state = initialState, action) => {
  switch (action.type) {
    case Transfer.FETCH_TRANSFER_HISTORY_REQUEST:
      return {
        ...state,
        transferHistoryLoading: Loading.FETCHING
      };

    case Transfer.FETCH_TRANSFER_HISTORY_SUCCESS:
      const chartData = formatDataForChart(action.payload);
      return {
        ...state,
        transferHistoryLoading: Loading.SUCCESS,
        transferHistory: action.payload.transactionHistory,
        transferChart: chartData
      };

    case Transfer.FETCH_TRANSFER_HISTORY_FAILURE:
      return {
        ...state,
        transferHistory: [],
        transferHistoryLoading: Loading.ERROR,
        transferChart: []
      };

    case Transfer.FETCH_TRANSFER_COUNTRY_LIST_REQUEST:
      return {
        ...state,
        loading: Loading.FETCHING
      };

    case Transfer.FETCH_TRANSFER_COUNTRY_LIST_SUCCESS:
      return {
        ...state,
        transferFundsCountries: action.payload,
        loading: Loading.SUCCESS
      };

    case Transfer.FETCH_TRANSFER_COUNTRY_LIST_FAILURE:
      return {
        ...state,
        loading: Loading.ERROR
      };

    case Transfer.FETCH_BENEFICIARY_BANK_INFO_REQUEST: {
      return {
        ...state,
        beneficiaryBankInfoStatus: Loading.FETCHING,
        beneficiaryBankInfo: []
      };
    }

    case Transfer.FETCH_BENEFICIARY_BANK_INFO_SUCCESS: {
      const bankInfo = [...action.payload];
      bankInfo.forEach((item) => removeEmpty(item));

      return {
        ...state,
        beneficiaryBankInfoStatus: Loading.SUCCESS,
        beneficiaryBankInfo: formatForDropdown(bankInfo)
      };
    }

    case Transfer.FETCH_BENEFICIARY_BANK_INFO_FAILURE: {
      return {
        ...state,
        beneficiaryBankInfoStatus: Loading.ERROR
      };
    }

    case Transfer.GET_QUOTE_DATA_REQUEST: {
      return {
        ...state,
        quoteStatus: Loading.FETCHING,
        quoteData: {}
      };
    }

    case Transfer.GET_QUOTE_DATA_SUCCESS: {
      return {
        ...state,
        quoteStatus: Loading.SUCCESS,
        quoteData: action.payload
      };
    }

    case Transfer.GET_QUOTE_DATA_FAILURE: {
      return {
        ...state,
        quoteStatus: Loading.ERROR
      };
    }

    case Transfer.RESET_QUOTE_DATA: {
      return {
        ...state,
        quoteStatus: "",
        quoteData: {}
      };
    }

    case Transfer.GET_BANK_FIELDS_REQUEST: {
      return {
        ...state,
        bankFieldStatus: Loading.FETCHING,
        bankField: {}
      };
    }

    case Transfer.GET_BANK_FIELDS_SUCCESS: {
      return {
        ...state,
        bankFieldStatus: Loading.SUCCESS,
        bankField: action.payload["requiredBankFields"],
        bankFields: action.payload["bankFields"]
      };
    }

    case Transfer.GET_BANK_FIELDS_FAILURE: {
      return {
        ...state,
        bankFieldStatus: Loading.ERROR
      };
    }

    case Transfer.GET_REQUIRED_TRANSACTION_DOCUMENTS_REQUEST: {
      return {
        ...state,
        requiredDocuments: {},
      };
    }

    case Transfer.GET_REQUIRED_TRANSACTION_DOCUMENTS_SUCCESS: {
      return {
        ...state,
        requiredDocuments: action.payload,
      };
    }

    case Transfer.GET_REQUIRED_TRANSACTION_DOCUMENTS_FAILURE: {
      return {
        ...state,
      };
    }

    case Transfer.CREATE_TRANSACTION_REQUEST: {
      return {
        ...state,
        createTransactionStatus: Loading.FETCHING,
        createTransactionData: {}
      };
    }

    case Transfer.CREATE_TRANSACTION_SUCCESS: {
      return {
        ...state,
        createTransactionStatus: Loading.SUCCESS,
        createTransactionData: action.payload
      };
    }

    case Transfer.CREATE_TRANSACTION_FAILURE: {
      return {
        ...state,
        createTransactionStatus: Loading.ERROR,
        createTransactionData: {}
      };
    }

    case Transfer.MAKE_WALLET_PAYMENT_REQUEST: {
      return {
        ...state,
        paymentStatus: Loading.FETCHING
      };
    }

    case Transfer.MAKE_WALLET_PAYMENT_SUCCESS: {
      return {
        ...state,
        paymentStatus: Loading.SUCCESS
      };
    }

    case Transfer.MAKE_WALLET_PAYMENT_FAILURE: {
      return {
        ...state,
        paymentStatus: Loading.ERROR
      };
    }

    case Transfer.LIST_PAYMENT_METHODS_REQUEST: {
      return {
        ...state,
        paymentMethodStatus: Loading.FETCHING,
        paymentMethod: {}
      };
    }

    case Transfer.LIST_PAYMENT_METHODS_SUCCESS: {
      return {
        ...state,
        paymentMethodStatus: Loading.SUCCESS,
        paymentMethod: action.payload
      };
    }

    case Transfer.LIST_PAYMENT_METHODS_FAILURE: {
      return {
        ...state,
        paymentMethodStatus: Loading.ERROR
      };
    }

    case Transfer.GET_TRANSACTION_STATUS_REQUEST: {
      return {
        ...state,
        checkTransactionStatus: Loading.FETCHING,
        transactionStatusDetails: {}
      };
    }

    case Transfer.GET_TRANSACTION_STATUS_SUCCESS: {
      return {
        ...state,
        checkTransactionStatus: Loading.SUCCESS,
        transactionStatusDetails: action.payload
      };
    }

    case Transfer.GET_TRANSACTION_STATUS_FAILURE: {
      return {
        ...state,
        checkTransactionStatus: Loading.ERROR
      };
    }

    case Transfer.ACCOUNT_INFO_LOOKUP_REQUEST: {
      return {
        ...state,
        accountInfoLookupStatus: Loading.FETCHING,
        accountInfoLookupData: {}
      };
    }

    case Transfer.ACCOUNT_INFO_LOOKUP_SUCCESS: {
      return {
        ...state,
        accountInfoLookupStatus: Loading.SUCCESS,
        accountInfoLookupData: action.payload
      };
    }

    case Transfer.ACCOUNT_INFO_LOOKUP_FAILURE: {
      return {
        ...state,
        accountInfoLookupStatus: Loading.ERROR,
        accountInfoLookupData: {}
      };
    }

    case Transfer.BOOK_WALLET_TO_WALLET_REQUEST: {
      return {
        ...state,
        walletToWalletStatus: Loading.FETCHING,
        walletToWalletData: {}
      };
    }

    case Transfer.BOOK_WALLET_TO_WALLET_SUCCESS: {
      return {
        ...state,
        walletToWalletStatus: Loading.SUCCESS,
        walletToWalletData: action.payload
      };
    }

    case Transfer.BOOK_WALLET_TO_WALLET_FAILURE: {
      return {
        ...state,
        walletToWalletStatus: Loading.ERROR,
        walletToWalletData: {}
      };
    }

    case Transfer.BOOK_FUND_WALLET_REQUEST: {
      return {
        ...state,
        fundWalletStatus: Loading.FETCHING,
        fundWalletData: {}
      };
    }

    case Transfer.BOOK_FUND_WALLET_SUCCESS: {
      return {
        ...state,
        fundWalletStatus: Loading.SUCCESS,
        fundWalletData: action.payload
      };
    }

    case Transfer.BOOK_FUND_WALLET_FAILURE: {
      return {
        ...state,
        fundWalletStatus: Loading.ERROR,
        fundWalletData: {}
      };
    }

    case Transfer.GET_TRANSACTION_DETAILS_REQUEST: {
      return {
        ...state,
        transactionDetailsStatus: Loading.FETCHING,
        transactionDetails: {}
      };
    }

    case Transfer.GET_TRANSACTION_DETAILS_SUCCESS: {
      return {
        ...state,
        transactionDetailsStatus: Loading.SUCCESS,
        transactionDetails: action.payload
      };
    }

    case Transfer.GET_TRANSACTION_DETAILS_FAILURE: {
      return {
        ...state,
        transactionDetailsStatus: Loading.ERROR
      };
    }

    case Transfer.CANCEL_TRANSACTION_REQUEST: {
      return {
        ...state,
        cancelTransactionStatus: Loading.FETCHING
      };
    }

    case Transfer.CANCEL_TRANSACTION_SUCCESS: {
      const transactionList = [...state.transferHistory];
      const transactionInfo = action.payload;
      const newTraxnList = updateCancelledTrnx(transactionList, transactionInfo.pcn, "pcn");

      return {
        ...state,
        transferHistory: newTraxnList,
        cancelTransactionStatus: Loading.SUCCESS
      };
    }

    case Transfer.CANCEL_TRANSACTION_FAILURE: {
      return {
        ...state,
        cancelTransactionStatus: Loading.ERROR
      };
    }

    default:
      return state;
  }
};

export default transferReducer;

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import rightArrowIcon from 'Assets/svg/right-arrow.svg';

const buttonStyle = () => css`
  background: var(--regular-button-color);
  border: 0;
  border-radius: 5px;
  padding: 20px 20px;
  display: flex;
  align-items: center;
  box-shadow: 5px 5px 25px #0000002b;
  width: 100%;
  height: 100%;
  cursor: pointer;

  span {
    &:first-of-type {
      width: 12%;
    }

    &:nth-of-type(2) {
      color: var(--regular-button-text-color);
      font-size: 16px;
      margin-left: 7%;
      font-weight: 300;
    }

    &:last-of-type {
      margin-left: auto;
      width: 7%;
    }

    img {
      height: 100%;
      width: 100%;
    }
  }
`;

const ButtonWithIcon = ({ buttonIcon, buttonText, clickAction }) => {
  return (
    <button css={buttonStyle} onClick={clickAction}>
      <span>
        <img src={buttonIcon} alt="transfer icon" />
      </span>
      <span>{buttonText}</span>
      <span>
        <img src={rightArrowIcon} alt="right arrow" />
      </span>
    </button>
  );
};

export default ButtonWithIcon;

import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useLocation, Redirect, useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";

import {
  MiniContainer,
  FieldContainer,
  ButtonContainer,
  Card,
  FlexWrapper,
  FormRow,
  RowItem,
  LabelHeading
} from "./styled";
import HeadingText from "Components/HeadingText";
import FxCard from "Components/FxCard";
import CheckFieldDisplay from "Components/CheckFieldDisplay";
import CustomButton from "Components/CustomButton";
import FormSelect from "Components/FormSelect";
import Spinner from "Components/Spinner";
import CustomInput from "Components/CustomInput";
import CustomNumInput from "Components/CustomNumInput";
import useQuoteDebounceHook from "Components/useQuoteDebounceHook";

import * as Loading from "Store/constants/loadingState.constants";
import { getAccountBalances } from "Store/actions/account.action";
import { purposeOfTransfer, sourceOfFunds } from "Utils/constants";
import { getQuoteData, initiateWalletToWalletTransfer } from "Store/actions/transfer.action";
import navigateMethod from "Utils/Helpers/navigateMethod";
import cookieHelper from "../../../../../Utils/Helpers/cookieHelper";
import config from "../../../../../Utils/Helpers/config";
import BackArrow from "Components/BackArrow";
import { fetchCurrencies } from "../../../../../Store/actions/bankAccount.action";
import bankAccountReducer from "../../../../../Store/reducers/bankAccount.reducer";

const AccountToAccount = () => {
  const location = useLocation(), dispatch = useDispatch(), history = useHistory();
  const { accounts, transfers, profile, currencies, domainBrand } = useSelector(
    ({ accountReducer, transferReducer, profileReducer, bankAccountReducer, domainBrandReducer }) => ({
      accounts: accountReducer,
      transfers: transferReducer,
      profile: profileReducer,
      currencies: bankAccountReducer["walletCurrenciesDropdown"],
      domainBrand: domainBrandReducer
    })
  );

  const languageDictionary = domainBrand.domainBrand["languageDictionary"];
  const languageDictionaryStatus = !(typeof languageDictionary === "undefined" || languageDictionary === null);
  const { state: { accountData: { accountNumber, firstName, lastName, countryIso3 } } } = location;
  const { quoteData: { total_amount, payment_method_fee, fxRate, tax } } = transfers;
  const tradeOriginCountry = cookieHelper.get(config.USER_COUNTRY);
  const spinnerLoading = accounts.loadingStatus === Loading.FETCHING || transfers.walletToWalletStatus === Loading.FETCHING;
  const buttonLoading = transfers.walletToWalletStatus === Loading.FETCHING;
  const { userData: { customerType } } = profile;

  const [initialValues, updateInitialValues] = useState({
    amount: "",
    currency: "",
    purposeOfTransfer: "",
    paymentReference: "",
    sourceOfFund: "",
    creditAccountNumber: "",
    debitAccountNumber: ""
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: Yup.object({
      amount: Yup.string().required("required"),
      paymentReference: Yup.string().required("required"),
      // purposeOfTransfer: Yup.object({ value: Yup.string().required("required") }),
      // sourceOfFund: Yup.object({ value: Yup.string().required("required") })
    }),
    onSubmit: (values) => {
      const { state: { accountData: { beneficiaryId } } } = location;
      const formattedAmount = Number(values.amount.replace(/,/g, ""));
      const valueBody = {
        beneficiaryId,
        amount: formattedAmount,
        purposeOfTransfer: values.purposeOfTransfer.value,
        creditingCurrency: values.currency.value,
        sourceOfFund: values.sourceOfFund.value,
        creditAccountNumber: accountNumber
        // debitAccountNumber: values.currency.walletAccountNumber
      };
      if (profile["userData"]["userType"] === 'CASHIER') valueBody.accountOwnerCustomerId = location.state.accountData.senderData.beneficiaryId;

      const navigator = navigateMethod(history, "/transfer/choose-payment-method");
      console.log(valueBody);
      dispatch(initiateWalletToWalletTransfer(valueBody, navigator));
    }
  });

  const currencySymbol = formik.values.currency.value || "";
  const openingBalance = formik.values.currency["walletAccountBalance"] || "";
  const currencyLabel = currencySymbol.length > 0 ? `(${currencySymbol})` : "";

  let callQuoteData = {
    transactionType: "WALLET",
    conversion: true,
    chargeCategory: "WALLET_MONEY_LOAD_CASH",
    paymentType: "E_WALLET",
    inverseCalculation: true
  };

  useQuoteDebounceHook({
    amount: formik.values.amount,
    currency: formik.values.currency
  });

  useEffect(() => {
    dispatch(getAccountBalances());
  }, []);

  useEffect(() => {
    dispatch(fetchCurrencies());
  }, []);

  useEffect(() => {
    if (accounts.loadingStatus === Loading.SUCCESS && accounts.dropDownList.length > 0) {
      updateInitialValues({
        ...formik.values
      });
    }
  }, [accounts.dropDownList, accounts.loadingStatus]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      callQuoteData.principalAmountCurrency = formik.values["currency"]["walletAccountCurrency"];
      callQuoteData.deriveAmountCurrency = formik.values["currency"]["walletAccountCurrency"];
      callQuoteData.deriveAmount = formik.values["amount"];
      dispatch(getQuoteData(callQuoteData));
    }, 2000);
    return () => clearTimeout(timeoutId);
  }, [formik.values.amount]);

  if (!location.state) {
    return <Redirect to="/transfer/account-to-account" />;
  }

  return (
    <div>
      <Helmet>
        <title>
          {languageDictionaryStatus ? languageDictionary["WO_ACCOUNT_TO_ACCOUNT_T_-364487608"] : "Account to Account Transfer"}
        </title>
      </Helmet>
      <div className="d-flex">
        <BackArrow />
        <HeadingText
          text={languageDictionaryStatus ? languageDictionary["WO_ACCOUNT_TO_ACCOUNT_T_-364487608"] : "Account to Account Transfer"}
        />
      </div>
      {spinnerLoading ? <Spinner /> :
        <FlexWrapper onSubmit={formik.handleSubmit}>
          <Card>
            <FormRow>
              <CheckFieldDisplay
                label={languageDictionaryStatus ? languageDictionary["l_beneficiary_name"] : "Beneficiary name"}
                fieldValue={`${firstName} ${lastName}`}
              />
            </FormRow>
            <FormRow>
              <CheckFieldDisplay
                label={languageDictionaryStatus ? languageDictionary["para_account_number"] : "Account number"}
                fieldValue={accountNumber}
              />
            </FormRow>
            <FormRow>
              <RowItem>
                <FormSelect
                  noOptionsMessage={() => `No currency added`}
                  formik={formik}
                  options={currencies}
                  selectLabel={
                    languageDictionaryStatus
                      ? languageDictionary["WO_SELECT_CURRENC_1917455719"]
                      : "Select currency"
                  }
                  selectPlaceholder={
                    languageDictionaryStatus
                      ? languageDictionary["WO_SELECT_CURRENC_1917455719"]
                      : "Select currency"
                  }
                  selectName="currency"
                />
              </RowItem>
            </FormRow>
            <div className="row">
              <div className="col-md-12 mb-2">
                <CustomNumInput
                  formik={formik}
                  inputKey="amount"
                  inputSize="100"
                  labelName={`${languageDictionaryStatus ? languageDictionary["l_sending_amount"] : "Sending Amount"} ${currencyLabel}`}
                  marginTop="0"
                />
              </div>
            </div>
            <CustomInput
              formik={formik}
              inputKey="paymentReference"
              inputSize="100"
              labelName={languageDictionaryStatus ? languageDictionary["PAYMENT_REFERENCE_1737194208"] : "Payment Reference"}
            />
            <FormRow>
              <RowItem>
                <FormSelect
                  formik={formik}
                  options={purposeOfTransfer[customerType]}
                  selectLabel={languageDictionaryStatus ? languageDictionary["l_purpose_transfer"] : "Purpose of transfer"}
                  selectPlaceholder="select purpose"
                  selectName="purposeOfTransfer"
                />
              </RowItem>
            </FormRow>

            <FormSelect
              formik={formik}
              options={sourceOfFunds[customerType]}
              selectLabel={languageDictionaryStatus ? languageDictionary["WO_SOURCE_OF_FUND_1149426292"] : "Source of funds"}
              selectPlaceholder="select source of funds"
              selectName="sourceOfFund"
            />
          </Card>

          <MiniContainer>
            <div>
              <FxCard
                currencySymbol={currencySymbol}
                fxRate={fxRate}
                tax={tax}
                totalAmount={total_amount}
                paymentFee={payment_method_fee}
                openingBalance={openingBalance}
              />
            </div>

            <ButtonContainer>
              <CustomButton
                buttonText={
                  languageDictionaryStatus ? languageDictionary["para_confirm"] : "CONFIRM"
                }
                loading={buttonLoading}
              />
            </ButtonContainer>
          </MiniContainer>
        </FlexWrapper>
      }
    </div>
  );
};

export default AccountToAccount;

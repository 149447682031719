import Axios from './Axios.service';

export const createNotification = async payload => {
  return Axios({
    method: 'POST',
    url: '/web-services/api/v6/services/notification/send-notifications',
    headers: {
      platform: 'fincode',
      UUID: 200
    },
    data: payload
  });
};

export const listNotifications = async (status = 'ALL') => {
  return Axios({
    method: 'GET',
    url: `/web-services/api/v6/services/notification/list-notifications/${status}`,
    headers: {
      platform: 'fincode',
      UUID: 200
    }
  });
};

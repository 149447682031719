import React from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";

import Footer from "Components/Footer";
import NavBar from "Components/Navbar";
import styled from "@emotion/styled";
import BackArrow from "Components/BackArrow";

export const Input = styled.input`
  width: 100%;
  margin-top: 12px;
  border: 0;
  background: #e3edf3;
  border-radius: 5px;
  padding: 1.5rem;
  font-size: 16px;
  font-weight: 400;

  ::placeholder {
    color: #6c757d;
  }
`;

export const NextButton = styled.button`
  font-size: 1rem;
`;

const ScanAndPay = () => {

  const { domainBrand } = useSelector(
    ({ domainBrandReducer }) => ({
      domainBrand: domainBrandReducer,
    })
  );

  // Language state
  const languageDictionary = domainBrand.domainBrand["languageDictionary"];
  const languageDictionaryStatus = !(
    typeof languageDictionary === "undefined" || languageDictionary === null
  );

  return (
    <>
      <Helmet>
        <title>
          {languageDictionaryStatus
            ? languageDictionary["WO_SCAN_AND_PA_-1356185446"]
            : "Scan and Pay"}{" "}
          &mdash; Multiview MTA
        </title>
      </Helmet>
      <NavBar />
      <section>
        <div className="container">
          <div className="mt-5 mb-5">
            <div className="d-flex">
              <BackArrow />
              <h2 className="text-blue mt-4">
                {languageDictionaryStatus
                  ? languageDictionary["button_make_payment"]
                  : "Make Payment"}
              </h2>
            </div>
            <div className="card mt-5 p-md-5">
              <div className="card-body mb-3 p-md-3">
                {/* --- Search box for product --- */}
                <div className="row mb-5">
                  <div className="col-12 col-md-6">
                    {/* ---- Enter reference number */}
                    <div className="mb-5">
                      <p className="text-blue">Enter Reference Amount</p>
                      <Input
                        type="text"
                        className="form-control mb-5"
                        placeholder="Type in a number here....."
                      />
                    </div>

                    {/* ----- Scan QR Code ----- */}
                    <div>
                      <p className="text-blue">SCAN QR CODE</p>
                      <NextButton className="btn btn-lg btn-green btn-block text-white">
                        SCAN CODE
                      </NextButton>
                    </div>
                  </div>
                </div>

                {/* ----- Proceed Button ----- */}
                <div className="row">
                  <div className="col-6 col-md-3 col-xl-2 ">
                    <NextButton className="btn btn-lg btn-blue btn-block text-white">
                      {languageDictionaryStatus ? languageDictionary["para_pay"] : "Pay"}
                    </NextButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default ScanAndPay;

import { ActionCreator } from 'Utils/Helpers';
import * as CountryConstants from "Store/constants/country.constants";
import * as CountryService from 'Utils/Services/Country.service';
import { expiredTokenCheck } from './auth.action';

export const getAllCountries = () => async (dispatch) => {
  try {
    dispatch(ActionCreator(CountryConstants.FETCH_ALL_COUNTRIES_REQUEST));
    const { data: responseData } = await CountryService.fetchAllCountries();

    if (responseData.status === 'FAILED') throw responseData;

    const { data: { countries } } = responseData;
    dispatch(ActionCreator(CountryConstants.FETCH_ALL_COUNTRIES_SUCCESS, countries));
  } catch (error) {
    expiredTokenCheck(error, dispatch);
    dispatch(ActionCreator(CountryConstants.FETCH_ALL_COUNTRIES_FAILURE));
    console.error(error);
  }
};

export const getAllSupportedCountries = () => async (dispatch) => {
  try {
    dispatch(ActionCreator(CountryConstants.FETCH_ALL_SUPPORTED_COUNTRIES_REQUEST ));
    const { data: responseData } = await CountryService.fetchAllSupportedCountries();

    if (responseData.status === 'FAILED') throw responseData;
    dispatch(ActionCreator(CountryConstants.FETCH_ALL_SUPPORTED_COUNTRIES_SUCCESS, responseData.data));
  } catch (error) {
    expiredTokenCheck(error, dispatch);
    dispatch(ActionCreator(CountryConstants.FETCH_ALL_SUPPORTED_COUNTRIES_FAILURE ));
    console.error(error);
  }
};

export const fetchCurrenciesByCountries = (tradeOriginCountry, destinationCountry, transactionType) => async (dispatch) => {
  try {
    dispatch(ActionCreator(CountryConstants.FETCH_CURRENCY_BY_COUNTRIES_REQUEST));
    const { data: responseData } = await CountryService.fetchSupportedCurrenciesByOriginCountryDestinationCountryAndDeliveryMethod(tradeOriginCountry, destinationCountry, transactionType);

    if (responseData.status === 'FAILED') throw responseData;

    const { data: { currencies } } = responseData;
    dispatch(ActionCreator(CountryConstants.FETCH_CURRENCY_BY_COUNTRIES_SUCCESS, currencies));
  } catch (error) {
    expiredTokenCheck(error, dispatch);

    dispatch(ActionCreator(CountryConstants.FETCH_CURRENCY_BY_COUNTRIES_FAILURE));
    console.error(error);
  }
};


export const fetchReceivingCurrenciesByCountries = (tradeOriginCountry, sendingCurrencyParameter, destinationCountry, transactionType) => async (dispatch) => {
  try {
    dispatch(ActionCreator(CountryConstants.FETCH_RECEIVING_CURRENCY_BY_COUNTRIES_REQUEST));
    const { data: responseData } = await CountryService.fetchReceivingCurrenciesByOriginCountryDestinationCountryAndDeliveryMethod(tradeOriginCountry, sendingCurrencyParameter, destinationCountry, transactionType);

    if (responseData.status === 'FAILED') throw responseData;

    const { data: { currencies } } = responseData;
    dispatch(ActionCreator(CountryConstants.FETCH_RECEIVING_CURRENCY_BY_COUNTRIES_SUCCESS, currencies));
  } catch (error) {
    expiredTokenCheck(error, dispatch);

    dispatch(ActionCreator(CountryConstants.FETCH_RECEIVING_CURRENCY_BY_COUNTRIES_FAILURE));
    console.error(error);
  }
};

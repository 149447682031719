// eslint-disable-next-line no-unused-vars
import React from "react";
/** @jsx jsx */
/** @jsxRuntime classic */
import { css, jsx } from "@emotion/react";
import ButtonSpinner from "Components/ButtonSpinner";

const buttonStyle = ({ buttonSize, marginLeft, fontSize, marginTop, theme }) => {
  return css`
    margin-top: ${marginTop || "10px"};
    border: 0;
    background: ${theme.primaryColor};
    border-radius: 5px;
    font-size: ${fontSize || "15px"};
    font-weight: 500;
    color: #fff;
    padding: 1rem 0.9rem;
    min-width: ${buttonSize};
    margin-left: ${marginLeft || 0};
    transition: opacity 0.2s ease-in-out;
    cursor: pointer;

    &:disabled {
      cursor: not-allowed;
      opacity: 0.7;
    }
  `;
};

const ButtonKYC = ({
  loading,
  buttonWidth,
  buttonText,
  onClick,
  marginLeft,
  disabled,
  fontSize,
  marginTop,
  type,
  buttonIcon,
}) => {
  const buttonSize = buttonWidth || "150px";

  return (
    <button
      type={type || "submit"}
      disabled={disabled || loading}
      css={(theme) => buttonStyle({ buttonSize, marginLeft, fontSize, marginTop, theme })}
      onClick={onClick || null}>
      {loading ? (
        <ButtonSpinner />
      ) : (
        <span>
          <img src={buttonIcon} alt="icon" /> {buttonText}
        </span>
      )}
    </button>
  );
};

export default ButtonKYC;

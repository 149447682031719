import { ActionCreator } from "Utils/Helpers";
import * as Merchant from "Store/constants/merchant.constants";
import * as MerchantService from "Utils/Services/Merchant.service";
import { fetchSupportedCountriesByDeliveryMethod } from "Utils/Services/Country.service";
import { expiredTokenCheck } from './auth.action';

export const getCountryList = (originCountry) => async (dispatch) => {
  try {
    dispatch(ActionCreator(Merchant.FETCH_COUNTRY_LIST_REQUEST));
    const { data: responseData } = await fetchSupportedCountriesByDeliveryMethod(originCountry, 'MERCHANT_PAYMENT');

    if (responseData.status === "FAILED") {
      throw responseData;
    }

    const {
      data: { countries },
    } = responseData;
    dispatch(ActionCreator(Merchant.FETCH_COUNTRY_LIST_SUCCESS, countries));
  } catch (error) {
    dispatch(ActionCreator(Merchant.FETCH_COUNTRY_LIST_FAILURE));
  }
};

export const getMerchantCategoryByCountry = (countryIso3Code) => async (
  dispatch
) => {
  try {
    dispatch(
      ActionCreator(Merchant.FETCH_MERCHANT_CATEGORY_BY_COUNTRY_REQUEST)
    );
    const {
      data: responseData,
    } = await MerchantService.fetchMerchantCategoryByCountry(countryIso3Code);

    if (responseData.status === "FAILED") {
      throw responseData;
    }

    const {
      data: { serviceDTO },
    } = responseData;
    dispatch(
      ActionCreator(
        Merchant.FETCH_MERCHANT_CATEGORY_BY_COUNTRY_SUCCESS,
        serviceDTO
      )
    );
  } catch (error) {
    dispatch(
      ActionCreator(Merchant.FETCH_MERCHANT_CATEGORY_BY_COUNTRY_FAILURE)
    );
  }
};

export const getProducts = () => async (dispatch) => {
  try {
    dispatch(ActionCreator(Merchant.FETCH_PRODUCTS_REQUEST));
    const { data: responseData } = await MerchantService.fetchProducts();

    if (responseData.status === "FAILED") {
      throw responseData;
    }

    const {
      data: { productDTO },
    } = responseData;

    dispatch(ActionCreator(Merchant.FETCH_PRODUCTS_SUCCESS, productDTO));
  } catch (error) {
    dispatch(ActionCreator(Merchant.FETCH_PRODUCTS_FAILURE));
  }
};

export const initiateMerchantPayment = (payload) => async (dispatch) => {
  try {
    dispatch(ActionCreator(Merchant.INITIATE_MERCHANT_PAYMENT_REQUEST));
    const { data: responseData } = await MerchantService.bookMerchantPayment(payload);
    if (responseData.status === "FAILED") {
      throw responseData;
    }

    dispatch(ActionCreator(Merchant.INITIATE_MERCHANT_PAYMENT_SUCCESS, responseData.data));
  } catch (error) {
    expiredTokenCheck(error, dispatch);

    dispatch(ActionCreator(Merchant.INITIATE_MERCHANT_PAYMENT_FAILURE));
    console.error(error);
  }
};

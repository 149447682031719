/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import ErrorField from "Components/ErrorField";

const formControl = (flexBasis) => css`
  flex-basis: ${flexBasis};
  max-width: ${flexBasis};
  margin-bottom: 30px;

  @media (max-width: 56.25rem) {
    max-width: 100%;
  }
`;

const labelStyle = (theme) => css`
  display: flex;
  flex-direction: column;
  color: ${theme.primaryColor};
  font-size: 16px;
  font-weight: 500;
`;

const inputStyle = ({ height, theme }) => css`
  margin-top: 12px;
  border: 0;
  background: #e3edf3;
  border-radius: 5px;
  padding: 15px 20px;
  font-size: 16px;
  color: ${theme.primaryColor};
  font-weight: 300;
  resize: none;
  height: ${height};

  &::placeholder {
    opacity: 1;
  }
`;

const CustomTextArea = ({
                          formik,
                          inputSize,
                          labelName,
                          inputKey,
                          placeholder,
                          rows,
                          height
                        }) => {
  const inputWidth = `${inputSize || 47.5}%`;
  const rowsNum = rows || 5;

  return (
    <div css={formControl(inputWidth)}>
      <label htmlFor={inputKey} css={labelStyle}>
        {labelName ? labelName : null}
        <ErrorField formik={formik} fieldName={inputKey} />

        <textarea
          rows={rowsNum}
          cols="30"
          name={inputKey}
          id={inputKey}
          placeholder={placeholder || ""}
          onChange={formik.handleChange}
          value={formik.values[inputKey]}
          css={(theme) => inputStyle({ height, theme })}
        />
      </label>
    </div>
  );
};

export default CustomTextArea;

import React from "react";
import styles from "./index.module.scss";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const Footer = () => {

  const { domainBrand } = useSelector(({ domainBrandReducer }) => ({ domainBrand: domainBrandReducer }));
  const { profile } = useSelector(({ profileReducer }) => ({ profile: profileReducer }));
  const languageDictionary = domainBrand.domainBrand["languageDictionary"];
  const languageDictionaryStatus = (!(typeof languageDictionary === "undefined" || languageDictionary === null));
  const getYear = new Date().getFullYear();
  const getNewsLink = domainBrand["domainBrand"]["companyWebSiteUrl"];
  const getMailLink = domainBrand["domainBrand"]["companyContactEmailAddress"];
  const footerRenderStatus = domainBrand["domainBrand"]["footer"] === "" || domainBrand["domainBrand"]["footer"] === null;
  const footerRender = domainBrand["domainBrand"]["footer"];

  const createMarkup = () => {
    return { __html: footerRender };
  };

  return (
    <>
      {!footerRenderStatus ? <div dangerouslySetInnerHTML={createMarkup()} /> :
        <footer className={styles.footerSection}>
          <div className={styles.footerSection__container}>
            <div className={styles.footerSection__container__wrapper}>
              <nav>
                <ul>
                  <li>
                    <a href={`mailto:${getMailLink}`}>{languageDictionaryStatus ? languageDictionary["WO_CONTACT_U_631529475"] :("CONTACT US")}</a>
                  </li>
                  <li>
                    <Link to={{ pathname: getNewsLink }}
                          target="_blank">{languageDictionaryStatus ? languageDictionary["title_news"] : ("NEWS")}</Link>
                  </li>
                  <li>
                    <Link
                      to="/profile/notifications">{languageDictionaryStatus ? languageDictionary["l_help"] : ("HELP")}</Link>
                  </li>
                </ul>
              </nav>

              <div>
                <p>Copyright &copy; {getYear} SpotBanc. {languageDictionaryStatus ? languageDictionary["l_all_rights_reserved"] : ("All Rights Reserved")}.</p>
              </div>
            </div>
          </div>
        </footer>
      }
    </>
  );
};

export default Footer;

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './index.scss';
import 'bootstrap/dist/css/bootstrap.css';
import { Provider } from 'react-redux';
import { toast } from 'react-toastify';

import configureStore from 'Store';
import 'react-toastify/dist/ReactToastify.min.css';
import 'react-datepicker/dist/react-datepicker.css';
import { setLoggedInUser } from "./Store/actions/auth.action";
const store = configureStore();

setLoggedInUser(store, null, 'Reload');
toast.configure({ pauseOnFocusLoss: true });

const AppRoot = () => {

  return (
    <Provider store={store}>
      <App />
    </Provider>
  );
};

ReactDOM.render(<AppRoot />, document.getElementById('root'));
serviceWorker.unregister();

import { css } from '@emotion/react';

export const identityTableStyle = (theme) => {
  return css`
    overflow-x: auto;

    table {
      border-collapse: separate;
      border-spacing: 0 15px;
      width: 100%;
      border: 1px solid #e2e8ec;
      border-radius: 5px;
      background: #ffffff;
    }

    th {
      color: #90a8be;
      font-weight: 600;
      text-transform: uppercase;
      font-size: 12px;
      padding-top: 5px;
      padding-bottom: 5px;
      padding-left: 10px;
      padding-right: 10px;
    }

    td {
      text-align: center;
    }

    tbody {
      td {
        background: #e3edf3;

        span {
          display: block;
          margin-top: 24.5px;
          margin-bottom: 24.5px;
          border-right: 2px solid #90a8be47;
          font-size: 14px;
          color: ${theme.primaryColor};
          font-weight: 500;
          padding-left: 10px;
          padding-right: 10px;
        }

        &:nth-of-type(1) {
          span {
            font-weight: 400;
          }
        }

        &:last-of-type {
          span {
            color: #90a8be;
          }
        }

        &:last-of-type {
          span {
            border-right: none;
          }
        }
      }
    }
  `;
};

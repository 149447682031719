import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
/** @jsx jsx */
/** @jsxRuntime classic */

import { jsx } from "@emotion/react";

import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";

import HeadingText from "Components/HeadingText";
import CustomInput from "Components/CustomInput";

import { headingTextStyle, rowWrapper } from "./styleSheet";
import { updateProfile } from "Store/actions/profile.action";
import * as Loading from "Store/constants/loadingState.constants";
import { getAllCountries } from "../../../../../Store/actions/country.action";
import UploadDocuments from "../../UploadDocuments";

const EditProfile = () => {
  const dispatch = useDispatch();
  const {
    countries,
    user: { userData },
    theme,
    domainBrand
  } = useSelector(({ countryReducer, profileReducer, themeReducer, domainBrandReducer }) => ({
    countries: countryReducer["countryDropdownList"],
    user: profileReducer,
    theme: themeReducer,
    domainBrand: domainBrandReducer
  }));

  const languageDictionary = domainBrand.domainBrand["languageDictionary"];
  const languageDictionaryStatus = !(typeof languageDictionary === "undefined" || languageDictionary === null);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      company: "",
      email: userData.email || "",
      firstName: userData.firstName || "",
      lastName: userData.lastName || "",
      phone: userData.phone || "",
      city: (userData.address && userData.address.city) || "",
      countryCode: userData.address.countryIso3 || "",
      country: userData.address.countryCommonName || "",
      postcode: (userData.address && userData.address.postcode) || "",
      address1: userData.address.address1 || "",
      address2: userData.address.address2 || ""
    },
    validationSchema: Yup.object({}),
    onSubmit: (values) => {
      const dataValues = {
        firstName: values.firstName,
        lastName: values.lastName,
        phone: values.phone,
        customerGender: userData.customerGender,
        customerType: userData.customerType,
        companyName: userData.companyName,
        nationality: userData.nationality,
        address: {
          address1: values.address1,
          address2: values.address2,
          city: values.city,
          countryCommonName: values.country.label,
          countryIso3: values.countryCode,
          postcode: values.postcode
        }
      };
      dispatch(updateProfile(dataValues));
    }
  });

  useEffect(() => {
    if (countries.loading !== Loading.SUCCESS || countries.loading === Loading.ERROR) {
      dispatch(getAllCountries());
    }
  }, []);

  return (
    <div>
      <Helmet>
        <title>View profile</title>
      </Helmet>
      <div css={headingTextStyle}>
        <HeadingText
          text={
            languageDictionaryStatus ? languageDictionary["WO_YOUR_NEW_DETAILS_WIL_1343499140"] : "Your new details will be saved"
          }
        />
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div css={rowWrapper}>
          <CustomInput
            formik={formik}
            inputKey="firstName"
            labelName={
              languageDictionaryStatus ? languageDictionary["para_first_name"] : "First Name"
            }
            disabled
          />
          <CustomInput
            formik={formik}
            inputKey="lastName"
            labelName={
              languageDictionaryStatus ? languageDictionary["para_last_name"] : "Last Name"
            }
            disabled
          />
        </div>
        <div css={rowWrapper}>
          <CustomInput
            formik={formik}
            inputKey="email"
            labelName={languageDictionaryStatus ? languageDictionary["para_email"] : "Email"}
            disabled
          />
          <CustomInput
            formik={formik}
            inputKey="phone"
            labelName={
              languageDictionaryStatus ? languageDictionary["para_phone_number"] : "Phone Number"
            }
            disabled
          />
        </div>
        <div css={rowWrapper}>
          <CustomInput
            formik={formik}
            inputKey="address1"
            labelName={
              languageDictionaryStatus ? languageDictionary["WO_ADDRESS_-646731655"] : "Address"
            }
            disabled
          />
          <CustomInput
            formik={formik}
            inputKey="address2"
            labelName={
              languageDictionaryStatus ? languageDictionary["WO_ADDRESS_-1044430036"] : "Address 2"
            }
            disabled
          />
        </div>
        <div css={rowWrapper}>
          <CustomInput
            formik={formik}
            inputKey="city"
            labelName={languageDictionaryStatus ? languageDictionary["para_city"] : "City"}
            disabled
          />
          <CustomInput
            formik={formik}
            inputKey="postcode"
            labelName={languageDictionaryStatus ? languageDictionary["l_post_code"] : "Post Code"}
            disabled
          />
        </div>
        <div className="row">
          <div className="col-md-6 mb-3">
            <p className="benCreate__main__form--inputHeading">
              {languageDictionaryStatus ? languageDictionary["para_country"] : "Country"}
            </p>
            <CustomInput formik={formik} inputKey="country" disabled />
          </div>
        </div>
      </form>

      <UploadDocuments theme={theme} languageDictionary={languageDictionary} />
    </div>
  );
};

export default EditProfile;

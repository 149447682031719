export const FETCH_ALL_COUNTRIES_REQUEST = 'FETCH_ALL_COUNTRIES_REQUEST';
export const FETCH_ALL_COUNTRIES_SUCCESS = 'FETCH_ALL_COUNTRIES_SUCCESS';
export const FETCH_ALL_COUNTRIES_FAILURE = 'FETCH_ALL_COUNTRIES_FAILURE';

export const FETCH_ALL_SUPPORTED_COUNTRIES_REQUEST = 'FETCH_ALL_SUPPORTED_COUNTRIES_REQUEST';
export const FETCH_ALL_SUPPORTED_COUNTRIES_SUCCESS = 'FETCH_ALL_SUPPORTED_COUNTRIES_SUCCESS';
export const FETCH_ALL_SUPPORTED_COUNTRIES_FAILURE = 'FETCH_ALL_SUPPORTED_COUNTRIES_FAILURE';

export const FETCH_CURRENCY_BY_COUNTRIES_REQUEST = 'FETCH_CURRENCY_BY_COUNTRIES_REQUEST';
export const FETCH_CURRENCY_BY_COUNTRIES_SUCCESS = 'FETCH_CURRENCY_BY_COUNTRIES_SUCCESS';
export const FETCH_CURRENCY_BY_COUNTRIES_FAILURE = 'FETCH_CURRENCY_BY_COUNTRIES_FAILURE';

export const FETCH_RECEIVING_CURRENCY_BY_COUNTRIES_REQUEST = 'FETCH_RECEIVING_CURRENCY_BY_COUNTRIES_REQUEST';
export const FETCH_RECEIVING_CURRENCY_BY_COUNTRIES_SUCCESS = 'FETCH_RECEIVING_CURRENCY_BY_COUNTRIES_SUCCESS';
export const FETCH_RECEIVING_CURRENCY_BY_COUNTRIES_FAILURE = 'FETCH_RECEIVING_CURRENCY_BY_COUNTRIES_FAILURE';

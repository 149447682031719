import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import './index.scss';
import NavBar from 'Components/Navbar';
import Footer from 'Components/Footer';
import offSetImg from 'Assets/svg/transfer1.svg';
import { getAllCategories } from 'Store/actions/category.action';
import Spinner from 'Components/Spinner';

const CategoryRow = ({ tableData }) => {
  const { name, description } = tableData;
  return (
    <tr>
      <td>
        <span>{name}</span>
      </td>
      <td>
        <span>{description}</span>
      </td>
    </tr>
  );
};

const ViewCategories = () => {
  const categorySelector = useSelector(({ categoryReducer }) => ({
    list: categoryReducer.list,
    loading: categoryReducer.loading,
  }));
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllCategories());
  }, [dispatch]);

  return (
    <div className="view__cat__body">
      <NavBar />
      <main className="view__cat__main">
        <div className="view__cat__main__canvasImg">
          <img src={offSetImg} alt="off canvas icon" />
        </div>
        {categorySelector.loading ? (
          <Spinner />
        ) : (
          <React.Fragment>
            <h2 className="view__cat__main--heading">All Categories</h2>
            <div className="view__cat__main--tableContainer">
              <table>
                <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Description</th>
                  </tr>
                </thead>
                <tbody>
                  {categorySelector.list.map((iData) => (
                    <CategoryRow tableData={iData} key={iData.id} />
                  ))}
                </tbody>
              </table>
            </div>
          </React.Fragment>
        )}
      </main>
      <Footer />
    </div>
  );
};

export default ViewCategories;

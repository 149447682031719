import React from "react";
import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom";
import AirtimeDestinationCountry from "../AirtimeDestinationCountry";
import AirtimeDetails from "../AirtimeDetails";
import AirtimePayment from "../AirtimePayment";
import PaymentMethod from "../../../Dashboard/Transfer/PaymentMethod";
import { TokenHelpers } from "../../../../Utils/Helpers";


const AirtimeRoutes = () => {
  const { path } = useRouteMatch();


  return (
    <Switch>
      <Route exact path={path}>
        <AirtimeDestinationCountry />
      </Route>

      {/*<PrivateRoute path={`/${path}/services`}>
        <AirtimeDetails />
      </PrivateRoute>*/}

      {/*<Route exact path={`${path}/services`}>
        <AirtimeDetails />
      </Route>*/}


      {/*<Route path={`${path}/services?country`}>
        <AirtimeDetails />
      </Route>*/}

      <Route path={`${path}/choose-payment-method`}>
        <PaymentMethod/>
      </Route>
    </Switch>
  );
};

export default AirtimeRoutes;

import React from 'react';
import styled from '@emotion/styled';
import CustomButton from "../CustomButton";
import { useHistory } from "react-router-dom";

const MainBody = styled.div`
  border: 1px solid #e3edf3;
  border-radius: 10px;
  padding: 45px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  background: white;

  @media (max-width: 56.25rem) {
    padding: 1rem;
  }
`;

const MainItem = styled.div`
  flex-basis: 40.5%;
  max-width: 40.5%;

  &:nth-of-type(odd) {
    flex-basis: 55.5%;
    max-width: 55.5%;
  }

  &:nth-of-type(1),
  &:nth-of-type(2) {
    margin-bottom: 20px;
  }

  &:nth-of-type(5) {
    margin-top: 20px;
  }

  h3 {
    font-size: 16px;
    font-weight: 500;
    color: ${(props) => props.theme.primaryColor};
    line-height: 1.6;
  }

  p {
    color: #0fcc98;
    font-size: 20px;
    font-weight: 500;
    text-transform: uppercase;
    line-height: 1.7;
  }
`;


const CashierSenderCard = ({ lookup, language, pageStatus, langState, link}) => {

  const history = useHistory();
  const linkToContinue = () => history.push({ pathname: link, state: {user: lookup, text: pageStatus} });

  return (
    <MainBody>
      <MainItem>
        <h3>Name</h3>
        <p> {lookup['firstName']} {lookup['lastName']}</p>
      </MainItem>

      <MainItem>
        <h3>Customer Code</h3>
        <p> {lookup['code']}</p>
      </MainItem>

      <MainItem>
        <h3>Phone Number</h3>
        <p> {lookup['phoneNumber']}</p>
      </MainItem>

      <MainItem>
        <h3>Country</h3>
        <p> {lookup['countryCommonName']}</p>
      </MainItem>

      <CustomButton
        buttonText={langState ? language["WO_CLICK_HERE_TO_CONTIN_297369385"] : "Click here to continue"}
        buttonWidth="100%"
        onClick={linkToContinue}
      />

    </MainBody>
  );
};

export default CashierSenderCard;

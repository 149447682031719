import Axios from "./Axios.service";

export const fetchCountryList = async () => {
  return Axios({
    method: "GET",
    url:
      "/web-services/api/v6/services/quote/destination-country-by-delivery-method/GBR/MERCHANT_PAYMENT",
    headers: {
      platform: "fincode",
      UUID: 200,
    },
  });
};

export const fetchMerchantCategoryByCountry = async (countryIso3Code) => {
  return Axios({
    method: "GET",
    url: `/web-services/api/v6/services/merchantpayment/listmerchantcategorybycountry1/${countryIso3Code}`,
    headers: {
      platform: "fincode",
      UUID: 200,
    },
  });
};

export const fetchProducts = async () => {
  return Axios({
    method: "POST",
    url: "/web-services/api/v6/services/merchantpayment/searchbyproductnameandcountry",
    data: {
      destinationIsoCode3: "NGA",
      productName: "",
      categoryId: "",
      serviceId: "02",
      totalNumber: 10,
      startNumber: 3,
      productOrderBy: "ADDITION_DATE",
    },
    headers: {
      platform: "fincode",
      UUID: 200,
    },
  });
};

export const bookMerchantPayment = async (payload) => {
  return Axios({
    method: "POST",
    url: "/web-services/api/v6/services/merchantpayment/initiatemerchantpayment",
    headers: {
      platform: "fincode",
      UUID: 200,
    },
    data: {
      email: "",
      merchant_identification_number: "",
      amount: payload.amount,
      external_merchant_trackingid: "",
      paying_currency: payload.currency,
      provided_payer_fullname: "",
      merchant_call_backurl: "",
      product_name: "",
      remark: "",
      product_short_desc: "",
      productWebsiteURL: "",
      productMainPhotoURL: "",
    },
  });
};

// eslint-disable-next-line no-unused-vars
import React from 'react';
/** @jsx jsx */
/** @jsxRuntime classic */

import { jsx } from '@emotion/react';
import { identityTableStyle } from './tableStyle';

const TableRow = ({ tableData }) => {
  const {
    idNo,
    issueCountry,
    issueDate,
    expiryDate,
    dob,
    type,
    creation_date,
  } = tableData;

  return (
    <tr>
      <td>
        <span>{type || '-'}</span>
      </td>
      <td>
        <span>{idNo || '-'}</span>
      </td>
      <td>
        <span>{issueCountry || '-'}</span>
      </td>
      <td>
        <span>{issueDate || '-'}</span>
      </td>
      <td>
        <span>{expiryDate || '-'}</span>
      </td>
      <td>
        <span>{dob || '-'}</span>
      </td>
      <td>
        <span>{creation_date || '-'}</span>
      </td>
    </tr>
  );
};

const IdentityTable = ({ data }) => {
  const tableData = data && data.docs ? data.docs : [];

  return (
    <div css={identityTableStyle}>
      <table>
        <thead>
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Id No</th>
            <th scope="col">ISSUE COUNTRY</th>
            <th scope="col">ISSUE DATE</th>
            <th scope="col">EXPIRY DATE</th>
            <th scope="col">DATE OF BIRTH</th>
            <th scope="col">DATE</th>
          </tr>
        </thead>
        <tbody>
          {tableData < 1 ? (
            <>
              <tr>
                <td colSpan="7" className="text-center bg-grey">
                  <span>No documents have been attached</span>
                </td>
              </tr>
            </>
          ) : (
            <>
              {tableData.map((dataValue, index) => (
                <TableRow tableData={dataValue} key={index} />
              ))}
            </>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default IdentityTable;

import * as Lingual from "../constants/multilingual.constants";
import * as Loading from "../constants/loadingState.constants";

const initialState = {
  multilingual: [],
  loading: '',
};

const multiLingualReducer = (state = initialState, action) => {
  switch (action.type){
    case Lingual.GET_LANGUAGE_REQUEST:
      return {
        ...state,
        loading: Loading.FETCHING,
      };

    case Lingual.GET_LANGUAGE_SUCCESS:
      return {
        ...state,
        multilingual: action.payload,
        loading: Loading.SUCCESS,
      }
    case Lingual.GET_LANGUAGE_FAILURE:
      return {
        ...state,
        loading : Loading.ERROR,
      }
    default:
      return state;
  }
}

export default multiLingualReducer;

import { css } from '@emotion/react';

export const alertContainer = css`
  display: flex;
  height: 80px;
  align-items: center;
  background: #0fcc982b;
  padding: 10px 15px;
  margin-top: 20px;

  @media (max-width: 56.25rem) {
    padding: 1rem;
  }
`;

export const alertCheck = css`
  flex-basis: 10%;
  max-width: 10%;
  margin-right: 5px;

  svg {
    height: 20px;
    width: 20px;
  }
`;

export const alertText = css`
  font-size: 20px;
  color: black;
  line-height: 1.2;

  @media (max-width: 56.25rem) {
    font-size: 1rem;
    margin-bottom: 0;
  }
`;

import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import BillPaymentCountry from "../BillPaymentCountry";
import BillPaymentDetails from "../BillPaymentDetails";
import BillPaymentMethods from "../BillPaymentMethods";

const BillPaymentRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <BillPaymentCountry />
      </Route>

      <Route path={`${path}/services`}>
        <BillPaymentDetails />
      </Route>

      <Route path={`${path}/choose-payment-method`}>
        <BillPaymentMethods />
      </Route>
    </Switch>
  );
};

export default BillPaymentRoutes;

import { ActionCreator } from "Utils/Helpers";
import { toast } from "react-toastify";
import * as Document from "Store/constants/document.constants";
import * as Profile from "Store/constants/profile.constants";
import { DocumentService } from "Utils/Services/Document.service";
import { expiredTokenCheck } from "./auth.action";

export const fetchDocuments = (customerCode) => async (dispatch) => {
  try {
    dispatch(ActionCreator(Document.FETCH_DOCUMENTS_REQUEST));
    const { data: responseData } = await DocumentService.listDocuments(customerCode);

    if (responseData.status === "FAILED") {
      throw responseData;
    }

    dispatch(ActionCreator(Document.FETCH_DOCUMENTS_SUCCESS, responseData.data.docs));
  } catch (error) {
    expiredTokenCheck(error, dispatch);
    dispatch(ActionCreator(Document.FETCH_DOCUMENTS_FAILURE));
  }
};

export const uploadDocuments = (payload) => async (dispatch) => {
  try {
    dispatch(ActionCreator(Document.ADD_DOCUMENTS_REQUEST));
    const { data: responseData } = await DocumentService.attachDocuments(payload);
    if (responseData.status === "FAILED") {
      throw responseData;
    }
    toast.success(responseData.message, {
      position: toast.POSITION.TOP_CENTER
    });
    dispatch(ActionCreator(Document.ADD_DOCUMENTS_SUCCESS, responseData.data.docs));
    fetchDocuments(payload.customerCode);
  } catch (error) {
    expiredTokenCheck(error, dispatch);
    dispatch(ActionCreator(Document.ADD_DOCUMENTS_FAILURE));
    toast.error(`Document Upload Failed! ${error.message}`, {
      position: toast.POSITION.TOP_CENTER
    });
  }
};

export const deleteUserDocument = (payload) => async (dispatch) => {
  try {
    dispatch(ActionCreator(Document.DELETE_DOCUMENT_REQUEST));
    const { data: responseData } = await DocumentService.deleteDocument(payload);
    if (responseData.status === "FAILED") {
      throw responseData;
    }
    toast.success(responseData.message, {
      position: toast.POSITION.TOP_CENTER
    });
    dispatch(ActionCreator(Document.DELETE_DOCUMENT_SUCCESS, payload.docId));
  } catch (error) {
    expiredTokenCheck(error, dispatch);
    dispatch(ActionCreator(Document.DELETE_DOCUMENT_FAILURE));
    toast.error(error.message, {
      position: toast.POSITION.TOP_CENTER
    });
  }
};

export const updateProfilePicture = (payload) => async (dispatch) => {
  try {
    dispatch(ActionCreator(Document.UPDATE_PROFILE_PICTURE_REQUEST, { loading: true }));

    const { data: responseData } = await DocumentService.updateProfilePicture(payload);
    if (responseData.status === "FAILED") {
      throw responseData;
    }
    toast.success("Profile picture Successful!", {
      position: toast.POSITION.TOP_CENTER
    });
    dispatch(
      ActionCreator(Document.UPDATE_PROFILE_PICTURE_SUCCESS, { loading: false, data: responseData })
    );
    dispatch(ActionCreator(Profile.SET_USER_PROFILE_PICTURE_SUCCESS, responseData.data));
  } catch (error) {
    expiredTokenCheck(error, dispatch);
    dispatch(ActionCreator(Document.UPDATE_PROFILE_PICTURE_FAILURE, { loading: false }));
    toast.error(`Profile picture Failed! ${error.message}`, {
      position: toast.POSITION.TOP_CENTER
    });
  }
};

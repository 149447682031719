import { ActionCreator, TokenHelpers } from "Utils/Helpers";
import * as Profile from "Store/constants/profile.constants";
import * as Auth from "Store/constants/auth.constants";
import ProfileService from "Utils/Services/Profile.service";
import { toast } from "react-toastify";
import { expiredTokenCheck } from "./auth.action";
import cookieHelper from "../../Utils/Helpers/cookieHelper";
import config from "../../Utils/Helpers/config";

export const getProfile = () => async (dispatch) => {
  try {
    dispatch(ActionCreator(Profile.FETCH_USER_PROFILE_REQUEST));
    const { data: responseData } = await ProfileService.fetchUser();

    if (responseData.status === "FAILED") throw responseData;
    const userData = responseData.data;
    const verificationState = responseData.data.userAccountVerified;
    const userType = responseData.data["userType"];
    const userAccountVerified = (userType === "CASHIER") ? "VERIFIED" : verificationState ? "VERIFIED" : "UNVERIFIED";
    if (cookieHelper.get(config.USER_VERIFIED_STATUS) === null) cookieHelper.set(config.USER_VERIFIED_STATUS, userAccountVerified, config.COOKIE_EXPIRY_PERIOD);
    await cookieHelper.set(config.USER_COUNTRY, userData.address.countryIso3, config.COOKIE_EXPIRY_PERIOD);
    dispatch(ActionCreator(Profile.FETCH_USER_PROFILE_SUCCESS, userData));
  } catch (error) {
    expiredTokenCheck(error, dispatch);
    dispatch(ActionCreator(Profile.FETCH_USER_PROFILE_FAILURE));
    console.error(error);
  }
};

export const updateProfile = (payload) => async (dispatch) => {
  try {
    dispatch(ActionCreator(Profile.EDIT_USER_PROFILE_REQUEST));
    const { data: responseData } = await ProfileService.update(payload);
    if (responseData.status === "FAILED") throw responseData;
    toast.success("Profile update successful", { position: toast.POSITION.TOP_CENTER });
    dispatch(ActionCreator(Profile.EDIT_USER_PROFILE_SUCCESS));
  } catch (error) {
    expiredTokenCheck(error, dispatch);
    dispatch(ActionCreator(Profile.EDIT_USER_PROFILE_FAILURE));
    toast.error("Profile update failed", { position: toast.POSITION.TOP_CENTER });
    console.error(error);
  }
};

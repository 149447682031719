import React from "react";
import styled from "@emotion/styled";

const Card = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #e3edf3;
  border-radius: 0.25rem;
  padding: 1rem 0.2rem;
`;

const Span = styled.p`
  line-height: 1.6;
  font-size: 1rem;
  color: ${(props) => props.theme.primaryColor};
`;

const Notes = ({ exchangeRate, tax, fee, totalAmount }) => {
  return (
    <Card>
      <div className="card-header text-uppercase text-blue font-weight-bold">note</div>
      <div className="card-body">
        {/* ---- Rate ------- */}
        <div className="d-flex justify-content-around mb-3">
          <Span className="col">Exchange Rate</Span>
          <Span className="col-auto font-weight-bold">{exchangeRate}</Span>
        </div>
        {/* ---- Tax ------- */}
        <div className="d-flex justify-content-around mb-3">
          <Span className="col">Tax</Span>
          <Span className="col-auto font-weight-bold">{tax}</Span>
        </div>
        {/* ---- Fee ------- */}
        <div className="d-flex justify-content-around mb-3">
          <Span className="col">Fee</Span>
          <Span className="col-auto font-weight-bold">{fee}</Span>
        </div>
        {/* ---- Total Amount ------- */}
        <div className="d-flex justify-content-around">
          <Span className="col">Total Amount</Span>
          <Span className="col-auto font-weight-bold">{totalAmount}</Span>
        </div>
      </div>
    </Card>
  );
};

export default Notes;

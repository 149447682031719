/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import ErrorField from "Components/ErrorField";

const formControl = (flexBasis, marginBottom) => css`
  flex-basis: ${flexBasis};
  margin-bottom: ${marginBottom || "30px"};
`;

const labelStyle = (theme) => css`
  display: flex;
  flex-direction: column;
  color: ${theme.primaryColor};
  font-size: 16px;
  font-weight: 500;
`;

const inputStyle = (theme) => css`
  margin-top: 12px;
  border: 0;
  background: #e3edf3;
  border-radius: 5px;
  padding: 15px 20px;
  font-size: 16px;
  color: ${theme.primaryColor};
  font-weight: 300;

  &::placeholder {
    opacity: 1;
  }
`;

const CustomInput = ({
  disabled,
  formik,
  inputSize,
  labelName,
  inputKey,
  placeholder,
  marginBottom,
  nestedKey,
  inputType,
  required
}) => {
  const inputWidth = `${inputSize || 47.5}%`;
  const value = formik.values[inputKey] || (nestedKey && formik.values[nestedKey][inputKey]) || "";
  const inputKeyId = `${nestedKey ? `${nestedKey}.${inputKey}` : inputKey}`;

  return (
    <div css={formControl(inputWidth, marginBottom)}>
      <label htmlFor={inputKeyId} css={labelStyle}>
        {labelName ? labelName : null}
        {required ? <span style={{ color: "red" }}>*</span> : ""}
        <ErrorField formik={formik} fieldName={inputKeyId} nestedKey={nestedKey} />
        <input
          disabled={disabled}
          type={inputType || "text"}
          name={inputKeyId}
          id={inputKeyId}
          onChange={formik.handleChange}
          value={value}
          css={inputStyle}
          placeholder={placeholder || ""}
        />
      </label>
    </div>
  );
};

export default CustomInput;

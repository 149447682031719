import { ActionCreator } from "Utils/Helpers";
import * as Category from "Store/constants/category.constants";
import CategoryService from "Utils/Services/Category.service";
import { toast } from "react-toastify";
import { expiredTokenCheck } from "./auth.action";

export const getAllCategories = () => async (dispatch) => {
  try {
    dispatch(ActionCreator(Category.FETCH_CATEGORY_REQUEST));
    const { data: responseData } = await CategoryService.fetchAll();

    if (responseData.status === "FAILED") {
      throw responseData;
    }

    const {
      data: { categoryDTO }
    } = responseData;
    dispatch(ActionCreator(Category.FETCH_CATEGORY_SUCCESS, categoryDTO));
  } catch (error) {
    expiredTokenCheck(error, dispatch);

    dispatch(ActionCreator(Category.FETCH_CATEGORY_FAILURE));

    console.error(error);
  }
};

export const createCategory = (requestData) => async (dispatch) => {
  try {
    dispatch(ActionCreator(Category.CREATE_CATEGORY_REQUEST));
    const { data: responseData } = await CategoryService.create(requestData);

    if (responseData.status === "FAILED") {
      throw responseData;
    }
    toast.success("Category creation successful", {
      position: toast.POSITION.TOP_CENTER
    });
    dispatch(ActionCreator(Category.CREATE_CATEGORY_SUCCESS, responseData));
    getAllCategories();
  } catch (error) {
    expiredTokenCheck(error, dispatch);

    dispatch(ActionCreator(Category.CREATE_CATEGORY_FAILURE));
    toast.error("Category Creation Failed", {
      position: toast.POSITION.TOP_CENTER
    });
    console.error(error);
  }
};

import Axios from './Axios.service';

export const fetchAllCountries = async () => {
  return Axios({
    method: 'GET',
    url: '/web-services/api/v6/services/utilitiesmanagement/list-all-countries',
    headers: {
      platform: 'fincode',
      UUID: 200
    }
  });
};

export const fetchAllSupportedCountries = async () => {
  return Axios({
    method: 'GET',
    url: '/web-services/api/v6/services/quote/supported-origin-countries',
    headers: {
      platform: 'fincode',
      UUID: 200
    }
  });
};

export const fetchSupportedCountriesByDeliveryMethod = async (originCountry, deliveryMethod) => {
  return Axios({
    method: "GET",
    url: `/web-services/api/v6/services/quote/destination-country-by-delivery-method/${originCountry}/${deliveryMethod}`,
    headers: {
      platform: "fincode",
      UUID: 200,
    },
  });
};

export const fetchSupportedCurrenciesByOriginCountryDestinationCountryAndDeliveryMethod = async (originCountry, destinationCountry, deliveryMethod) => {
  return Axios({
    method: "GET",
    url: `/web-services/api/v6/services/quote/supported-currencies/${originCountry}/${destinationCountry}/${deliveryMethod}`,
    headers: {
      platform: "fincode",
      UUID: 200,
    },
  });
};

export const fetchReceivingCurrenciesByOriginCountryDestinationCountryAndDeliveryMethod = async (originCountry, sendingCurrencyParameter, destinationCountry, deliveryMethod) => {
  return Axios({
    method: "GET",
    url: `/web-services/api/v6/services/quote/supported-delivery-currencies/${originCountry}/${destinationCountry}/${deliveryMethod}/${sendingCurrencyParameter}`,
    headers: {
      platform: "fincode",
      UUID: 200,
    },
  });
};

// eslint-disable-next-line no-unused-vars
import React from "react";
/** @jsx jsx */
/** @jsxRuntime classic */

import { jsx, css } from "@emotion/react";

const CheckBoxContent = ({ checkboxId, link }) => (
  <div
    css={css`
      color: #000000af;
      font-weight: 500;
      font-size: 1rem;
      line-height: 1.5;
      margin-top: -1.5625rem;
      margin-bottom: 20px;
      margin-left: 30px;
    `}>
    <label
      htmlFor={checkboxId}
      css={css`
        cursor: pointer;
        display: inline;
      `}>
      By checking this box, I have agreed this{" "}
    </label>
    <a
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      css={css`color: #0fcc98; cursor: pointer;
        text-decoration: none;`}>
      terms and conditions.
    </a>
  </div>
);

export default CheckBoxContent;

import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import MerchantCountry from "../MerchantCountry";
import Products from "../Products";
import ProductDescription from "../ProductDescription";

const MerchantProductRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <MerchantCountry />
      </Route>

      <Route exact path={`${path}/products`}>
        <Products />
      </Route>

      <Route exact path={`${path}/:id`}>
        <ProductDescription />
      </Route>
    </Switch>
  );
};

export default MerchantProductRoutes;

// eslint-disable-next-line no-unused-vars
import React from "react";
/** @jsx jsx */
/** @jsxRuntime classic */
import { jsx, css } from "@emotion/react";

const colorMap = (theme) => ({
  Pay: theme.primaryColor,
  Edit: theme.primaryColor,
  Deactivate: "#E50C37",
  Cancel: "#dc3545"
});

const style = (theme, text) => css`
  background: ${colorMap(theme)[text] || "#0FCC98"};
  border: 0;
  border-radius: 5px;
  padding: 6px 8px;
  min-width: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;

  p {
    color: white;
    font-size: 15px;
    margin-bottom: 0 !important
  }

  div {
    width: 14px;
    display: flex;

    img {
      width: 100%;
      height: 100%;
    }
  }
`;

const TableButton = ({ icon, text, onClick }) => {
  return (
    <button className="mr-1" css={(theme) => style(theme, text, icon)} onClick={onClick}>
      {text ? <p>{text}</p> :
        <div>
          <img src={icon} alt="button action" />
        </div>
      }
    </button>
  );
};

export default TableButton;

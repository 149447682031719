import styled from "@emotion/styled";


export const TH = styled.th`
  color: #90a8be;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 12px;
  padding: 5px 30px;
`;

export const Span = styled.span`
  display: block;
  margin-top: 24.5px;
  margin-bottom: 24.5px;
  border-right: 2px solid #90a8be47;
  font-size: 15px;
  color: #0055ad;
  font-weight: 500;
  padding-left: 20px;
  padding-right: 20px;
`;

export const Table = styled.table`
  background: #ffffff;
  border: 1px solid #e2e8ec;
  border-collapse: separate;
  border-radius: 5px;
  border-spacing: 0 15px;
  width: 100%;
`;
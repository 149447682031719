import { css } from '@emotion/react';

export const contactContainer = css`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const contactMain = css`
  flex: 1;
  position: relative;
`;

export const mainContainer = css`
  max-width: 1100px;
  margin: 0 auto;

  @media (max-width: 56.25rem) {
    padding: 1rem;
  }
`;

export const mainHeading = (theme) => css`
  margin-top: 70px;
  color: ${theme.primaryColor};
  font-weight: 500;
  font-size: 3.125rem;
  margin-bottom: 40px;

  @media (max-width: 56.25rem) {
    font-size: 1.5rem;

  }
`;

export const contactCard = (theme) => css`
  border-radius: 5px;
  background: ${theme.primaryColor};
  height: 400px;
  position: relative;
  overflow: hidden;
  margin-bottom: 50px;
  display: flex;
  align-items: center;
  padding: 0 80px;

  @media (max-width: 56.25rem) {
    height: 465px;
    padding: 1rem;
    align-items: flex-start
    

  }
`;

export const contactCircleOne = css`
  height: 400px;
  width: 400px;
  background: #0fcc98;
  position: absolute;
  bottom: 0;
  border-radius: 50%;
  top: -120px;
  right: -50px;

  @media (max-width: 56.25rem) {
    height: 300px;
    width: 300px;
    top: 230px;
    right: -50px;
  }
`;

export const contactCircleTwo = css`
  height: 400px;
  width: 400px;
  background: #368fea;
  position: absolute;
  border-radius: 50%;
  bottom: -100px;
  right: -150px;

  @media (max-width: 56.25rem) {
    height: 300px;
    width: 300px;
    bottom: -150px;
    left: -50px;

  }
`;

export const contactCardTitle = css`
  font-weight: 600;
  font-size: 28px;
  margin-bottom: 10px;
  line-height: 1.5;
  color: white;

  @media (max-width: 56.25rem) {
    font-size: 1.3rem;

  }


`;

export const contactCardText = css`
  font-size: 25px;
  margin-bottom: 10px;
  color: white;
  line-height: 1.5;

  @media (max-width: 56.25rem) {
    font-size: 1rem;

  }
`;

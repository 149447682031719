import pdfmake from "pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
// 'X-MTA-Sec': true

const pdfGeneratorTransDetails = async ({ data }, logo) => {
  pdfmake.vfs = pdfFonts.pdfMake.vfs;

  const {
    date,
    country_from,
    amount_sent,
    sending_currency_code,
    paymentMethodFee,
    charges,
    total_amount_paid,
    amount_received,
    receiving_currency_code,
    payment_method,
    code,
    status,
    recipient_bank_details
  } = data.transactionDetails;

  const { BankName, AccountNumber } = recipient_bank_details || {};
  const sender = data.transactionDetails.sender || {};
  const receiver = data.transactionDetails.receiver || {};
  const amountReceived = typeof amount_received === "undefined" || amount_received === null ? amount_received : amount_received.replace(/,/g, "");

  const getBase64ImageFromURL = (url) => {
/*    return new Promise((resolve, reject) => {
      const img = new Image();
      img.setAttribute("crossOrigin", "anonymous");
      // img.setAttribute("X-MTA-Sec", "true");
      img.onload = () => {
        const canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        const dataURL = canvas.toDataURL("image/png");
        resolve(dataURL);
      };
      img.onerror = error => reject(error);
      img.src = url;
    });*/

    const xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.onreadystatechange = function () {
      if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
        const img = document.createElement('img');
        img.src = URL.createObjectURL(xhr.response);
        return img;
      }
    };
    xhr.open('GET', url, true);
    xhr.setRequestHeader('X-MTA-Sec', 'true');
    xhr.send();
  }


  const docDefinition = {
    pageOrientation: "portrait",

    content: [
      {
        columns: [
          {
            // image: await getBase64ImageFromURL(logo),
            // image: await getBase64ImageFromURL("https://images.pexels.com/photos/209640/pexels-photo-209640.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=300"),
            // image: imageDataUrl,
            // width: 150
          }
        ]
      },
      {
        text: "Transaction Details",
        style: "header"
      },
      {
        columns: [
          {
            columns: [
              {
                text: "Date \u200B\ \n PCN \u200B\ \n Status",
                width: "auto",
                style: "infoNude"
              },
              {
                text: date + "\n" + code + "\n" + status,
                width: "auto",
                style: "info"
              }
            ]
          },
          {
            text: sender["first_name"] + " " + sender["last_name"] + "\n" + country_from,
            width: "*",
            style: "address"
          }
        ]
      },
      {
        text: "Transaction Information",
        style: "subheader"
      },
      { canvas: [{ type: "line", x1: 0, y1: 5, x2: 595 - 2 * 40, y2: 5, lineWidth: 0.2 }], style: "pretext" },
      {
        columns: [
          {
            text: "Amount sent to" + " " + receiver["first_name"] + " " + receiver["last_name"],
            width: 300,
            style: "infoNude"
          },
          {
            text: "Fees and Commission",
            width: 300,
            style: "infoNude"
          }
        ]
      },
      {
        columns: [
          {
            text: parseFloat(amount_sent).toFixed(2) + " " + sending_currency_code,
            width: 300,
            style: "info"
          },
          {
            text: paymentMethodFee + parseFloat(charges).toFixed(2) + " " + sending_currency_code,
            width: 300,
            style: "info"
          }
        ]
      },
      {
        columns: [
          {
            text: "Total amount paid (including VAT, fees and commissions)",
            width: 300,
            style: "infoNude"
          },
          {
            text: "Exchange rate value",
            width: 300,
            style: "infoNude"
          }
        ]
      },
      {
        columns: [
          {
            text: parseFloat(total_amount_paid).toFixed(2) + " " + sending_currency_code,
            width: 300,
            style: "info"
          },
          {
            text: "-",
            width: 300,
            style: "info"
          }
        ]
      },
      {
        columns: [
          {
            text: receiver["first_name"] + " " + receiver["last_name"] + " receives",
            width: 300,
            style: "infoNude"
          }
        ]
      },
      {
        columns: [
          {
            // text: parseFloat(amount_received).toFixed(2) + " " + receiving_currency_code,
            text: parseFloat(amountReceived).toLocaleString("en-US") + " " + receiving_currency_code,
            width: 300,
            style: "info"
          }
        ]
      },
      {
        text: "Sent to",
        style: "subheader"
      },
      { canvas: [{ type: "line", x1: 0, y1: 5, x2: 595 - 2 * 40, y2: 5, lineWidth: 0.2 }], style: "pretext" },
      {
        columns: [
          {
            text: "Name",
            width: 300,
            style: "infoNude"
          },
          {
            text: "Email",
            width: 300,
            style: "infoNude"
          }
        ]
      },
      {
        columns: [
          {
            text: receiver["first_name"] + " " + receiver["last_name"],
            width: 300,
            style: "info"
          },
          {
            text: receiver["email"],
            width: 300,
            style: "info"
          }
        ]
      },
      {
        columns: [
          {
            text: "Account Number",
            width: 300,
            style: "infoNude"
          },
          {
            text: "Bank Name",
            width: 300,
            style: "infoNude"
          }
        ]
      },
      {
        columns: [
          {
            text: AccountNumber,
            width: 300,
            style: "info"
          },
          {
            text: BankName,
            width: 300,
            style: "info"
          }
        ]
      },
      {
        text: "Paid from",
        style: "subheader"
      },
      { canvas: [{ type: "line", x1: 0, y1: 5, x2: 595 - 2 * 40, y2: 5, lineWidth: 0.2 }], style: "pretext" },
      {
        columns: [
          {
            text: "Name",
            width: 300,
            style: "infoNude"
          },
          {
            text: "Email",
            width: 300,
            style: "infoNude"
          }
        ]
      },
      {
        columns: [
          {
            text: sender["first_name"] + " " + sender["last_name"],
            width: 300,
            style: "info"
          },
          {
            text: sender["email"],
            width: 300,
            style: "info"
          }
        ]
      },
      {
        columns: [
          {
            text: "Payment Method",
            width: 300,
            style: "infoNude"
          }
        ]
      },
      {
        columns: [
          {
            text: payment_method,
            width: 300,
            style: "info"
          }
        ]
      }
    ],

    styles: {
      header: {
        fontSize: 16,
        bold: true,
        margin: [0, 20],
        alignment: "left"
      },
      subheader: {
        fontSize: 12,
        bold: true,
        margin: [0, 40, 0, 10],
        alignment: "left"
      },
      pretext: {
        margin: [0, 0, 0, 20],
        alignment: "left"
      },
      infoNude: {
        color: "#696969",
        fontSize: 10,
        margin: [0, 10, 0, 0],
        bold: true,
        alignment: "left"
      },
      info: {
        color: "black",
        fontSize: 10,
        margin: [0, 10, 0, 0],
        alignment: "left"
      },
      address: {
        fontSize: 10,
        alignment: "right",
        color: "black"
      }
    }
  };

  pdfmake.createPdf(docDefinition).open();
};

export default pdfGeneratorTransDetails;

// eslint-disable-next-line no-unused-vars
import React from 'react';
/** @jsx jsx */
/** @jsxRuntime classic */

import { jsx, css } from '@emotion/react';
import styled from '@emotion/styled';

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  border: 1px solid #1149835b;
  border-radius: 10px;
  padding: 3rem 40px;

  @media (max-width: 56.25rem) {
    padding: 1.2rem;
   }
`;

const InnerTopContainer = styled.div`
  background: #f5f9fb;
  padding: 2.5rem 2.5rem 0;
  border-radius: 6px;

  @media (max-width: 56.25rem) {
    padding: 1rem;
   }
`;

const InnerBottomContainer = styled.div`
  background: #e3edf3;
  padding: 1.5rem 2.5rem;
  margin-top: 1.5rem;
  border-radius: 6px;
  display: flex;
`;

const TitleHeading = styled.p`
  color: #000000af;
  font-weight: 500;
  font-size: 0.9rem;
  line-height: 1.8;
  margin-bottom: 0.5rem;
`;

const SubHeading = styled.p`
  color: ${props => props.theme.primaryColor};
  font-weight: 500;
  font-size: 1.1rem;
  line-height: 1.5;
  margin-bottom: 1rem;
`;

const InfoItem = styled.div`
  border-bottom: 2px solid #1149831a;
  flex-basis: 45%;
  margin-bottom: 2.5rem;
`;

const InfoItemContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const Wallet = ({ dataObject: { parameters, currency } }) => {
  return (
    <MainContainer>
      <InnerTopContainer>
        <InfoItemContainer>
          <InfoItem>
            <TitleHeading>Account Holder Name</TitleHeading>
            <SubHeading>Walter Cath</SubHeading>
          </InfoItem>

          <InfoItem>
            <TitleHeading>Balance</TitleHeading>
            <SubHeading>GBP 97,177.04</SubHeading>
          </InfoItem>

          <InfoItem>
            <TitleHeading>Account Number</TitleHeading>
            <SubHeading>----------------</SubHeading>
          </InfoItem>

          <InfoItem>
            <TitleHeading>Account Currency</TitleHeading>
            <SubHeading>{currency}</SubHeading>
          </InfoItem>
        </InfoItemContainer>
      </InnerTopContainer>
      <InnerBottomContainer>
        <TitleHeading
          css={theme =>
            css`
              color: ${theme.primaryColor};
              margin-bottom: 0;
            `
          }
        >
          TRACKING NUMBER:
        </TitleHeading>
        <TitleHeading
          css={css`
            color: #0fcc98;
            margin-left: 5px;
            margin-bottom: 0;
          `}
        >
          {parameters && parameters.paymentGatewayRef}
        </TitleHeading>
      </InnerBottomContainer>
    </MainContainer>
  );
};

export default Wallet;

import Axios from './Axios.service';

export default class CategoryService {
  static async fetchAll() {
    return Axios({
      method: 'GET',
      url: '/web-services/api/v6/services/walletmanagement/listcategories',
      headers: {
        platform: 'fincode',
        UUID: 200
      }
    });
  }

  static async create(payload) {
    return Axios({
      method: 'POST',
      url: '/web-services/api/v6/services/walletmanagement/addcategory',
      data: payload,
      headers: {
        platform: 'fincode',
        UUID: 200
      }
    });
  }
}

import cookieHelper from "./cookieHelper";
import config from "./config";
import {
  DEFAULT_BASEURL,
  DEV_BRANCH_BASEURL,
  LOCALHOST_BASEURL,
  TEMPORARY_BASEURL
} from "../Services/BaseUrl";

export const formatDate = (dateValue) =>
  dateValue ? new Date(dateValue).toLocaleString().replace(", ", " - ") : dateValue;

export const getYear = () => {
  const date = new Date();
  return date.getFullYear();
};

export const truncateNum = data => {
  if (!data) return data;
  let valNum = data;
  valNum = valNum.toString();
  valNum = valNum.slice(0, valNum.indexOf(".") + 3);
  return valNum;
};

export const formatStringCurrency = data => {
  const format = (typeof data === "undefined" || data === null || data === "") ? "0" : data;
  if (format.toString().includes(",")) {
    const newFormat = format.toString().replace(",", "");
    const formattedNumber = parseFloat(newFormat);
    return formattedNumber.toLocaleString();
  } else {
    const formattedNumber = parseFloat(format).toFixed(2);
    return formattedNumber.toLocaleString();
  }
};

export const filterData = (data, filterValue, keys) => {
  if (filterValue === "") return data;
  const filter = filterValue.toLowerCase();
  return data.filter((item) => keys.some((keyItem) => item[keyItem] && item[keyItem].toLowerCase().indexOf(filter) > -1));
};

export const fetchHostURL = () => {
  let baseURL;
  const subdomain = window.location.hostname.split(".")[0];
  baseURL = subdomain === TEMPORARY_BASEURL || subdomain === DEV_BRANCH_BASEURL || subdomain === LOCALHOST_BASEURL ? `${DEFAULT_BASEURL}.spotbanc.io` : window.location.hostname;
  return baseURL;
};

export const fetchAppLanguage = () => {
  let cookieLanguage, selectedLanguage;
  cookieLanguage = cookieHelper.get(config.LANGUAGE_DATA);
  cookieLanguage === null || cookieLanguage === "" || typeof cookieLanguage === "undefined"
    ? (selectedLanguage = { id: "en", commonName: "ENGLISH", label: "ENGLISH", value: "en" })
    : (selectedLanguage = cookieLanguage);
  return selectedLanguage;
};

export const checkUrlExists = (url) => {
  const reqChecker = new XMLHttpRequest();
  reqChecker.open("GET", url, true);
  if (reqChecker.status === 400 || reqChecker.status === 1) {
    reqChecker.send(null);
    return true;
  }
};

export const createLabelAndValueForCountryDropdown = (array) => {
  array.forEach((element) => {
    element["label"] = element["common_name"];
    element["value"] = element["iso3Code"];
  });
  return array;
};

export const dynamicFieldsFormikGetKeys = (array, values) => {
  let data, fieldsData;
  array.forEach((element) => {
    data = { [element["key"]]: values[element["key"]] };
    fieldsData = { ...fieldsData, ...data };
  });
  return fieldsData;
};

export const dynamicFieldsDropdown = (array) => {
  array.forEach((element) => {
    element["label"] = element["displayValue"];
    element["value"] = element["optionValue"];
  });
  return array;
};

export const setPaymentMethodDropdown = (array, PaymentLabels) => {
  let newArray = [];
  array.forEach((element) => {
    element["label"] = PaymentLabels[element["type"]];
    element["value"] = element["type"];
    newArray.push(element);
  });
  return newArray;
};

export const languageChangeHelper = (value) => {
  cookieHelper.set(config.LANGUAGE_DATA, value, config.COOKIE_EXPIRY_PERIOD);
  const subdomain = cookieHelper.get(config.DOMAIN_DATA);
  return { value, subdomain };
};

export const useGeoLocator = () => {
  if (!navigator.geolocation) {
    return ipLookup();
  } else {
    const success = (position) => {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;
      reverseGeocodingWithGoogle(latitude, longitude);
    };
    const error = () => {
      return ipLookup();
    };
    return navigator.geolocation.getCurrentPosition(success, error);
  }
};

export const ipLookup = () => {
  fetch("https://extreme-ip-lookup.com/json/")
    .then((res) => res.json())
    .then((response) => {
      return response;
    })
    .catch((data, status) => {
      // console.log("We could not find your location", status, data);
      return status;
    });
};

const reverseGeocodingWithGoogle = (latitude, longitude) => {
  fetch(
    `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyDF0JOxzUgFPXe3X8FLi1b1fVDDir1-0cs`
  )
    .then((res) => res.json())
    .then((response) => {
    })
    .catch((status) => {
    });
};

export const daysFromToday = () => {
  const today = new Date();
  return new Date(today.getTime() - 30 * 24 * 60 * 60 * 1000);
};

export const formatReloadData = (reloadData) => {
  const {
    bankName,
    accountNumber,
    accountHolderName,
    bankCity,
    sortCode,
    swiftCode,
    ibanCode,
    routingNumber,
    transitNumber,
    branchCode,
    bankCode,
    ifscCode,
    rutNumber,
    receiver: { code: beneficiaryCode },
    amount_sent: sendingAmount,
    sending_currency_code: currency
  } = reloadData;

  return {
    bankFields: {
      bankName,
      accountNumber,
      accountHolderName,
      bankCity,
      sortCode,
      swiftCode,
      ibanCode,
      routingNumber,
      transitNumber,
      branchCode,
      bankCode,
      ifscCode,
      rutNumber
    },
    beneficiaryCode,
    sendingAmount,
    currency,
    reload: true
  };
};

export const formatDateShort = (dateValue) =>
  dateValue ? new Date(dateValue).toLocaleDateString() : "-";

export const fileToBase64 = (file) => {
  return new Promise((resolve) => {
    var reader = new FileReader();
    reader.onload = function(event) {
      resolve(event.target.result);
    };

    reader.readAsDataURL(file);
  });
};

export const searchFilter = (data, filterValue, keys) => {
  if (filterValue === "") {
    return data;
  }

  if (filterValue !== null || filterValue !== undefined) {
    const filter = filterValue.toLowerCase();
    return data.filter((item) =>
      keys.some((keyItem) => item[keyItem].toLowerCase().indexOf(filter) !== -1)
    );
  } else {
    return "";
  }
};

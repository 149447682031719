import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import Footer from "Components/Footer";
import NavBar from "Components/Navbar";

import styled from "@emotion/styled";
import TransferService from "../../../../Utils/Services/Transfer.service";
import { toast } from "react-toastify";

const Input = styled.input`
  width: 100%;
  margin-top: 12px;
  border: 0;
  background: #e3edf3;
  border-radius: 5px;
  padding: 1.5rem;
  font-size: 16px;
  font-weight: 400;

  ::placeholder {
    color: #6c757d;
  }
`;

export const TH = styled.th`
  color: #90a8be;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 12px;
  padding: 5px 30px;
`;

/*export const TD = styled.td`
  background: #e3edf3;
  text-align: center;
`;*/

export const Span = styled.span`
  display: block;
  margin-top: 24.5px;
  margin-bottom: 24.5px;
  border-right: 2px solid #90a8be47;
  font-size: 15px;
  color: #0055ad;
  font-weight: 500;
  padding-left: 20px;
  padding-right: 20px;
`;

/*export const Table = styled.table`
  background: #ffffff;
  border: 1px solid #e2e8ec;
  border-collapse: separate;
  border-radius: 5px;
  border-spacing: 0 15px;
  width: 100%;
`;*/

const NextButton = styled.button`
  font-size: 1rem;
`;

const PayoutSearch = () => {
  const history = useHistory();
  const [PCN, updatePCN] = useState('');

  const verifyPCN = async () => {
    const { data: response } = await TransferService.lockTransactionForPayout(PCN);
    if (response.status === 'ERROR') toast.error(`${response.message}`, { position: toast.POSITION.TOP_CENTER });
    else history.push({pathname: '/cashier/payout-transaction', state: { pcn: PCN, response: response.data }});
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <>
      <Helmet>
        <title>Payout</title>
      </Helmet>
      <NavBar />
      <section>
        <div className="container">
          <div className="mt-5 mb-5">
            <h2 className="text-blue">Payout</h2>
            <p>Search the transactions here by entering PCN Number</p>
            <div className="card mt-5 p-lg-5">
              <div className="card-body mb-3 p-md-3">
                {/*<div className="row mb-5">
                  <div className="col-12 col-md-6 col-xl-7">
                    <p className="text-blue">Please select partner you wish to pay-out on behalf</p>
                    <Input
                      type="text"
                      className="form-control"
                      placeholder="Search for country..."
                    />
                  </div>
                </div>*/}

                <div className="row mb-5">
                  <div className="col-12">
                    <p className="text-blue">
                      Payment Collection Number (PCN) provided by beneficiary.
                    </p>
                    <div className="row">
                      <div className="col-12 col-md-6 col-xl-7">
                        <div className="d-flex justify-content-between align-content-center">
                          <Input
                            type="text"
                            className="form-control"
                            value={PCN}
                            onChange={(e) => updatePCN(e.target.value)}
                            placeholder="Search for PCN..."
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-3 col-xl-3 p-3">
                          <NextButton onClick={verifyPCN} className="btn btn-lg btn-blue text-white btn-block px-3 text-uppercase">
                            verify
                          </NextButton>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default PayoutSearch;

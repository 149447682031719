import * as User from "Store/constants/user.constants";
import * as Loading from "Store/constants/loadingState.constants";
import { modifyInactiveUser, modifyActiveUser } from "Utils/Helpers/dataModifier";

/**
 * @function modifyLoadingHelper
 * @description This function makes a shallow copy of the state object
 * passed and re-assigns the new loading state to the object
 * @typedef {('SUCCESS'|'ERROR'|'FETCHING')} StatusEnums
 * @param {Object} payload - Required parameters
 * @param {StatusEnums} payload.loadingState - Loading state
 * @param {string} payload.keyId - item id
 * @param {Object} payload.state - current state containing the current loading state
 * @returns {Object} Modified loading state
 */
const modifyLoadingHelper = ({ loadingState, keyId, state }) => {
  const actionLoading = { ...state };
  actionLoading[keyId] = loadingState;
  return actionLoading;
};

const initialState = {
  userListStatus: "",
  userList: [],
  userListDropdown: [],
  userListAction: {},
  userEditStatus: "",
  userCreateStatus: "",
  customerServiceTeamStatus: "",
  customerServiceTeamDropdown: [],
  customerServiceTeam: [],
  registerCustomerLoading: "",
  awaitingComplianceAction: "",
  changePassword: "",
  changePasswordLoading: "",
};

const formatForDropdown = (payloadData) => {
  return payloadData.map((item) => ({
    ...item,
    value: item.id,
    label: `${item.firstName} ${item.lastName}`,
  }));
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case User.GET_USERS_REQUEST: {
      return {
        ...state,
        userListStatus: Loading.FETCHING,
      };
    }

    case User.GET_USERS_SUCCESS: {
      return {
        ...state,
        userListStatus: Loading.SUCCESS,
        userList: action.payload,
        userListDropdown: formatForDropdown(action.payload),
      };
    }

    case User.GET_USERS_FAILURE: {
      return {
        ...state,
        userListStatus: Loading.ERROR,
      };
    }

    case User.CREATE_USER_REQUEST: {
      return {
        ...state,
        userCreateStatus: Loading.FETCHING,
      };
    }

    case User.CREATE_USER_SUCCESS: {
      return {
        ...state,
        userCreateStatus: Loading.SUCCESS,
      };
    }

    case User.CREATE_USER_FAILURE: {
      return {
        ...state,
        userCreateStatus: Loading.ERROR,
      };
    }

    case User.CREATE_CUSTOMER_REQUEST: {
      return {
        ...state,
        registerCustomerLoading: Loading.FETCHING,
      };
    }

    case User.CREATE_CUSTOMER_SUCCESS: {
      return {
        ...state,
        registerCustomerLoading: Loading.SUCCESS,
      };
    }

    case User.CREATE_CUSTOMER_FAILURE: {
      return {
        ...state,
        registerCustomerLoading: Loading.ERROR,
      };
    }

    case User.GET_CUSTOMER_SERVICE_TEAM_REQUEST: {
      return {
        ...state,
        customerServiceTeamStatus: Loading.FETCHING,
        customerServiceTeam: [],
      };
    }

    case User.GET_CUSTOMER_SERVICE_TEAM_SUCCESS: {
      return {
        ...state,
        customerServiceTeamStatus: Loading.SUCCESS,
        customerServiceTeam: action.payload,
        customerServiceTeamDropdown: formatForDropdown(action.payload),
      };
    }

    case User.GET_CUSTOMER_SERVICE_TEAM_FAILURE: {
      return {
        ...state,
        customerServiceTeamStatus: Loading.ERROR,
      };
    }

    case User.ACTIVATE_USER_REQUEST: {
      return {
        ...state,
        userListAction: modifyLoadingHelper({
          loadingState: Loading.FETCHING,
          keyId: action.payload.userId,
          state: state.userListAction,
        }),
      };
    }

    case User.ACTIVATE_USER_SUCCESS: {
      const currentUserList = [...state.userList];
      const modifiedList = modifyInactiveUser(
        currentUserList,
        action.payload.responseData.data.id,
        "id"
      );
      return {
        ...state,
        userListAction: modifyLoadingHelper({
          loadingState: Loading.SUCCESS,
          keyId: action.payload.userId,
          state: state.userListAction,
        }),
        userList: modifiedList,
      };
    }

    case User.ACTIVATE_USER_FAILURE: {
      return {
        ...state,
        userListAction: modifyLoadingHelper({
          loadingState: Loading.ERROR,
          keyId: action.payload.userId,
          state: state.userListAction,
        }),
      };
    }

    case User.DEACTIVATE_USER_REQUEST: {
      return {
        ...state,
        userListAction: modifyLoadingHelper({
          loadingState: Loading.FETCHING,
          keyId: action.payload.userId,
          state: state.userListAction,
        }),
      };
    }

    case User.DEACTIVATE_USER_SUCCESS: {
      const currentUserList = [...state.userList];
      const modifiedList = modifyActiveUser(
        currentUserList,
        action.payload.responseData.data.id,
        "id"
      );
      return {
        ...state,
        userListAction: modifyLoadingHelper({
          loadingState: Loading.SUCCESS,
          keyId: action.payload.userId,
          state: state.userListAction,
        }),
        userList: modifiedList,
      };
    }

    case User.DEACTIVATE_USER_FAILURE: {
      return {
        ...state,
        userListAction: modifyLoadingHelper({
          loadingState: Loading.ERROR,
          keyId: action.payload.userId,
          state: state.userListAction,
        }),
      };
    }

    case User.CHANGE_PASSWORD_REQUEST: {
      return {
        ...state,
        changePasswordLoading: action.payload.loading,
      };
    }

    case User.CHANGE_PASSWORD_SUCCESS: {
      return {
        ...state,
        changePasswordLoading: action.payload.loading,
      };
    }

    case User.CHANGE_PASSWORD_FAILURE: {
      return {
        ...state,
        changePasswordLoading: action.payload.loading,
      };
    }

    case User.EDIT_USER_REQUEST: {
      return {
        ...state,
        userEditStatus: Loading.FETCHING,
      };
    }

    case User.EDIT_USER_SUCCESS: {
      return {
        ...state,
        userEditStatus: Loading.SUCCESS,
      };
    }

    case User.EDIT_USER_FAILURE: {
      return {
        ...state,
        userEditStatus: Loading.ERROR,
      };
    }

    default:
      return state;
  }
};

export default userReducer;

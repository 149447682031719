import * as MobileMoney from "Store/constants/mobileMoney.constants";

const initialState = {
  countries: [],
  loading: false,
  loadingStatus: "",
  currencyLoadingStatus: "",
  deliveryLoadingStatus: "",
  currencies: [],
  deliveryCurrencies: [],
  quoteLoadingStatus: "",
  quoteData: {},
};

const mobileMoneyReducer = (state = initialState, action) => {
  switch (action.type) {
    case MobileMoney.FETCH_MOBILE_MONEY_COUNTRY_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        loadingStatus: "FETCHING",
      };

    case MobileMoney.FETCH_MOBILE_MONEY_COUNTRY_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        loadingStatus: "SUCCESS",
        countries: action.payload,
      };

    case MobileMoney.FETCH_MOBILE_MONEY_COUNTRY_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        loadingStatus: "ERROR",
      };
    case MobileMoney.FETCH_SUPPORTED_CURRENCIES_REQUEST:
      return {
        ...state,
        loading: true,
        currencyLoadingStatus: "FETCHING",
      };
    case MobileMoney.FETCH_SUPPORTED_CURRENCIES_SUCCESS:
      return {
        ...state,
        loading: false,
        currencyLoadingStatus: "SUCCESS",
        currencies: action.payload,
      };

    case MobileMoney.FETCH_SUPPORTED_CURRENCIES_FAILURE:
      return {
        ...state,
        loading: false,
        currencyLoadingStatus: "ERROR",
      };
    case MobileMoney.FETCH_SUPPORTED_DELIVERY_CURRENCIES_REQUEST:
      return {
        ...state,
        loading: true,
        deliveryLoadingStatus: "FETCHING",
      };
    case MobileMoney.FETCH_SUPPORTED_DELIVERY_CURRENCIES_SUCCESS:
      return {
        ...state,
        loading: false,
        deliveryLoadingStatus: "SUCCESS",
        deliveryCurrencies: action.payload,
      };

    case MobileMoney.FETCH_SUPPORTED_DELIVERY_CURRENCIES_FAILURE:
      return {
        ...state,
        loading: false,
        deliveryLoadingStatus: "ERROR",
      };
    case MobileMoney.FETCH_QUOTE_FOR_MOBILE_MONEY_REQUEST:
      return {
        ...state,
        loading: true,
        quoteLoadingStatus: "FETCHING",
      };

    case MobileMoney.FETCH_QUOTE_FOR_MOBILE_MONEY_SUCCESS:
      return {
        ...state,
        loading: false,
        quoteLoadingStatus: "SUCCESS",
        quoteData: action.payload,
      };

    case MobileMoney.FETCH_QUOTE_FOR_MOBILE_MONEY_FAILURE:
      return {
        ...state,
        loading: false,
        quoteLoadingStatus: "ERROR",
      };
    default:
      return state;
  }
};

export default mobileMoneyReducer;

// eslint-disable-next-line no-unused-vars
import React from 'react';

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';

const labelStyle = (theme) => css`
  display: flex;
  flex-direction: column;
  color: ${theme.primaryColor};
  font-size: 15px;
  font-weight: 500;
  @media (max-width: 56.25rem) {
    font-size: 0.9375rem;
    max-width: 100%;
  }
`;

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ text, children, extraStyles }) => {
  return (
    <label
      css={(theme) => [labelStyle(theme), ...(extraStyles ? extraStyles : [])]}
    >
      {text}
      {children}
    </label>
  );
};

// export default FormLabel;

import Axios from "./Axios.service";

export const fetchDomainBrandIdentity = async (subdomain, languageOption) => {
  return Axios({
    method: 'GET',
    url: `/web-services/api/v6/services/generalmanagement/load-domain-brand-identity?lang_iso2=${languageOption}`,
    headers: {
      platform: 'fincode',
      UUID: 200
    }
  });
};

export const transactionTypes = async () => {
  return Axios({
    method: 'GET',
    url: `/web-services/api/v6/services/transactionmanagement/transaction-types`,
    headers: {
      platform: 'fincode',
      UUID: 200
    }
  });
};

export const languageDictionary = async (language) => {
  return Axios({
    method: 'GET',
    url: `https://mta-cdn-public.s3.eu-west-1.amazonaws.com/languages/${language}.json`,
    headers: {
      credentials: 'include',
    }
  });
};

export const fetchLanguages = async () => {
  return Axios({
    method: 'GET',
    url: `https://mta-cdn-public.s3.eu-west-1.amazonaws.com/languages.json`,
    headers: {
      credentials: 'include',
    }
  });
};

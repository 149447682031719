import { ActionCreator } from "Utils/Helpers";
import * as MobileMoney from "Store/constants/mobileMoney.constants";
import * as MobileMoneyService from "Utils/Services/MobileMoney.service";
import { fetchSupportedCountriesByDeliveryMethod } from "Utils/Services/Country.service";
import { expiredTokenCheck } from "./auth.action";

export const getMobileMobileCountryList = (originCountry) => async (dispatch) => {
  try {
    dispatch(ActionCreator(MobileMoney.FETCH_MOBILE_MONEY_COUNTRY_LIST_REQUEST));
    const { data: responseData } = await fetchSupportedCountriesByDeliveryMethod(originCountry, 'MOBILE_MONEY');

    if (responseData.status === "FAILED") {
      throw responseData;
    }

    const { data: { countries }, } = responseData;
    dispatch(ActionCreator(MobileMoney.FETCH_MOBILE_MONEY_COUNTRY_LIST_SUCCESS, countries));
  } catch (error) {
    expiredTokenCheck(error, dispatch);
    dispatch(ActionCreator(MobileMoney.FETCH_MOBILE_MONEY_COUNTRY_LIST_FAILURE));
  }
};

export const getSupportedCurrencies = (destinationCountryIso3) => async (dispatch) => {
  try {
    dispatch(ActionCreator(MobileMoney.FETCH_SUPPORTED_CURRENCIES_REQUEST));
    const { data: responseData } = await MobileMoneyService.fetchSupportedCurrencies(destinationCountryIso3);

    if (responseData.status === "FAILED") {
      throw responseData;
    }

    const { data: { currencies } } = responseData;
    dispatch(ActionCreator(MobileMoney.FETCH_SUPPORTED_CURRENCIES_SUCCESS, currencies));
  } catch (error) {
    expiredTokenCheck(error, dispatch);
    dispatch(ActionCreator(MobileMoney.FETCH_SUPPORTED_CURRENCIES_FAILURE));
  }
};

export const getSupportedDeliveryCurrencies = (
  destinationCountryIso3,
  originatingCountryCurrency
) => async (dispatch) => {
  try {
    dispatch(ActionCreator(MobileMoney.FETCH_SUPPORTED_DELIVERY_CURRENCIES_REQUEST));
    const { data: responseData } = await MobileMoneyService.fetchSupportedDeliveryCurrencies(
      destinationCountryIso3,
      originatingCountryCurrency
    );

    if (responseData.status === "FAILED") {
      throw responseData;
    }

    const {
      data: { currencies },
    } = responseData;
    dispatch(ActionCreator(MobileMoney.FETCH_SUPPORTED_DELIVERY_CURRENCIES_SUCCESS, currencies));
  } catch (error) {
    expiredTokenCheck(error, dispatch);
    dispatch(ActionCreator(MobileMoney.FETCH_SUPPORTED_DELIVERY_CURRENCIES_FAILURE));
  }
};

export const getQuoteForMobileMoney = (payload) => async (dispatch) => {
  try {
    dispatch(ActionCreator(MobileMoney.FETCH_QUOTE_FOR_MOBILE_MONEY_REQUEST));
    const { data: responseData } = await MobileMoneyService.fetchQuoteForMobileMoney(payload);
    if (responseData.status === "FAILED") {
      throw responseData;
    }
    const { data } = responseData;

    dispatch(ActionCreator(MobileMoney.FETCH_QUOTE_FOR_MOBILE_MONEY_SUCCESS, data));
  } catch (error) {
    expiredTokenCheck(error, dispatch);

    dispatch(ActionCreator(MobileMoney.FETCH_QUOTE_FOR_MOBILE_MONEY_FAILURE));
    console.error(error);
  }
};

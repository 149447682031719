import { ActionCreator } from "Utils/Helpers";
import * as Account from "Store/constants/account.constants";
import * as AccountService from "Utils/Services/Account.service";
import { expiredTokenCheck } from "./auth.action";

export const getAccountBalances = () => async (dispatch) => {
  try {
    dispatch(ActionCreator(Account.FETCH_ACCOUNT_BALANCE_REQUEST));
    const { data: responseData } = await AccountService.fetchAccountBalance();

    if (responseData.status === "FAILED") {
      throw responseData;
    }

    const {
      data: { accounts },
    } = responseData;
    dispatch(ActionCreator(Account.FETCH_ACCOUNT_BALANCE_SUCCESS, accounts));
  } catch (error) {
    expiredTokenCheck(error, dispatch);
    dispatch(ActionCreator(Account.FETCH_ACCOUNT_BALANCE_FAILURE));
  }
};

export const getAccountBalancesByAccountCurrency = (accountCurrency) => async (dispatch) => {
  try {
    dispatch(ActionCreator(Account.FETCH_ACCOUNT_BALANCE_BY_ACCOUNT_CURRENCY_REQUEST));
    const { data: responseData } = await AccountService.fetchAccountBalanceByAccountCurrency(
      accountCurrency
    );

    if (responseData.status === "FAILED") {
      throw responseData;
    }

    const {
      data: { accounts },
    } = responseData;
    dispatch(ActionCreator(Account.FETCH_ACCOUNT_BALANCE_BY_ACCOUNT_CURRENCY_SUCCESS, accounts));
  } catch (error) {
    expiredTokenCheck(error, dispatch);
    dispatch(ActionCreator(Account.FETCH_ACCOUNT_BALANCE_BY_ACCOUNT_CURRENCY_FAILURE));
  }
};

export const getAccountStatement = (reqBody) => async (dispatch) => {
  try {
    dispatch(ActionCreator(Account.FETCH_ACCOUNT_STATEMENT_REQUEST));
    const { data: responseData } = await AccountService.fetchAccountStatement(reqBody);
    if (responseData.status === "FAILED") {
      throw responseData;
    }

    dispatch(ActionCreator(Account.FETCH_ACCOUNT_STATEMENT_SUCCESS, responseData.data));
  } catch (error) {
    expiredTokenCheck(error, dispatch);
    dispatch(ActionCreator(Account.FETCH_ACCOUNT_STATEMENT_FAILURE));
  }
};

export const getMonthlyWalletBalances =
  (currency, startYear, accountNumberQuery) => async (dispatch) => {
    try {
      dispatch(ActionCreator(Account.FETCH_MONTHLY_ACCOUNT_BALANCE_REQUEST));
      const { data: responseData } = await AccountService.fetchAccountBalanceMonthlyStatement(
        currency,
        startYear,
        accountNumberQuery
      );

      if (responseData.status === "FAILED") {
        throw responseData;
      }

      dispatch(
        ActionCreator(
          Account.FETCH_MONTHLY_ACCOUNT_BALANCE_SUCCESS,
          responseData.data["walletBalance"]
        )
      );
    } catch (error) {
      expiredTokenCheck(error, dispatch);
      dispatch(ActionCreator(Account.FETCH_MONTHLY_ACCOUNT_BALANCE_FAILURE));
    }
  };

export const getYearlyTransaction = (userCode, startYear) => async (dispatch) => {
  try {
    dispatch(ActionCreator(Account.FETCH_YEARLY_TRANSACTION_REQUEST));
    const { data: responseData } = await AccountService.fetchYearlyTransaction(userCode, startYear);

    if (responseData.status === "FAILED") {
      throw responseData;
    }

    dispatch(
      ActionCreator(
        Account.FETCH_YEARLY_TRANSACTION_SUCCESS,
        responseData.data["transactionCountDTO"]
      )
    );
  } catch (error) {
    expiredTokenCheck(error, dispatch);
    dispatch(ActionCreator(Account.FETCH_YEARLY_TRANSACTION_FAILURE));
  }
};

// eslint-disable-next-line no-unused-vars
import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { containerStyle } from 'Assets/styles/global/base';

export const InnerContainer = styled.div`
  background: var(--background-color);
  flex: 1;
  width: 100%;
  overflow: hidden;
  position: relative;
`;

export const Main = styled.div`
  ${containerStyle};
  padding-top: 2.8rem;

  @media (max-width: 56.25rem) {
    padding: 1rem;
  }

`;

export const sectionContainer = css`
  display: flex;
  margin-top: 120px;
  margin-bottom: 75px;

  @media (max-width: 56.25rem) {
    flex-direction: column;
  }
`;

export const leftSection = css`
  flex-basis: 30%;
  max-width: 30%;
  padding-right: 25px;
  border-right: 3px solid var(--navbar-color);
  height: 100%;
  padding-bottom: 100px;

  @media (max-width: 56.25rem) {
    max-width: 100%;
    padding-right: 0;
    border-right: 0;
  }

  
`;

export const rightSection = css`
  flex-basis: 70%;
  max-width: 70%;
  height: 100%;
  padding-left: 25px;

  @media (max-width: 56.25rem) {
    max-width: 100%;
    padding-left: 0;
  }
`;

export const profileBox = css`
  background: var(--navbar-color);
  border-radius: 5px;
  min-height: 270px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  border-radius: 5px;
  margin-bottom: 30px;
`;

export const profileBoxContainer = css`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  flex: 1;
  position: relative;
  overflow: hidden;
  width: 100%;
  border-radius: 5px;
`;

export const profilePicture = css`
  height: 170px;
  width: 170px;
  border-radius: 50%;
  display: flex;
  border: 1px solid #0fcc9838;
  top: -85px;
  position: absolute;
`;

export const profilePictureInner = css`
  height: 140px;
  width: 140px;
  border-radius: 50%;
  display: flex;
  border: 1px solid #3796f6;
  margin: auto;
`;

export const profilePictureMain = css`
  height: 110px;
  width: 110px;
  border-radius: 50%;
  display: flex;
  margin: auto;
  overflow: hidden;
`;

export const leftEllipse = css`
  top: -40px;
  left: -40px;
`;

export const rightEllipse = css`
  bottom: -40px;
  right: -40px;
  background: #3796f64d;
`;

export const userNameStyle = () => css`
  color: var(--navbar-text-color);
  font-size: 16px;
  line-height: 1.5;
  margin-top: 70px;
`;

export const emailStyle = () => css`
  color: var(--navbar-text-color);
  font-size: 14px;
  line-height: 1.5;
`;

export const logOutButton = (theme) => css`
  height: 80px;
  background: #e3edf373;
  border: 0;
  width: 100%;
  border-radius: 5px;
  color: ${theme.primaryColor};
  font-size: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;

  path {
    fill: ${theme.primaryColor};
  }
`;

import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const mainBody = css`
  border: 1px solid var(--background-color);
  border-radius: 10px;
  padding: 30px 40px;
  /* width: 48.5%; */
  margin-top: 30px;
  margin-bottom: 50px;

  @media (max-width: 56.25rem) {
    padding: 1rem;
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 56.25rem) {
    flex-direction: column;
    max-width: 100%;
  }
`;

export const selectWrapper = css`
  margin-bottom: 30px;
`;

export const selectDropdownContainer = css`
  max-width: 47.5%;
  flex-basis: 47.5%;
`;

export const buttonWrapper = css`
  display: flex;
  justify-content: flex-end;
`;

export const Label = styled.p`
  font-size: 16px;
  color: ${(props) => props.theme.primaryColor};
  font-weight: 500;
`;

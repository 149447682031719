import { ActionCreator } from 'Utils/Helpers';
import * as Notification from 'Store/constants/notification.constants';
import * as NotificationService from 'Utils/Services/Notification.service';

import { expiredTokenCheck } from './auth.action';
import { toast } from 'react-toastify';

export const sendNotification = (payload, navigator) => async (dispatch) => {
  try {
    dispatch(ActionCreator(Notification.SEND_NOTIFICATION_REQUEST));
    const { data: responseData } = await NotificationService.createNotification(payload);

    if (responseData.status === 'FAILED') throw responseData;
    toast.success('Mail sent successfully', { position: toast.POSITION.TOP_CENTER, });

    dispatch(ActionCreator(Notification.SEND_NOTIFICATION_SUCCESS));
    navigator();
  } catch (error) {
    expiredTokenCheck(error, dispatch);
    dispatch(ActionCreator(Notification.SEND_NOTIFICATION_FAILURE));
    toast.error('Error sending mail', {
      position: toast.POSITION.TOP_CENTER,
    });
    console.error(error);
  }
};

export const fetchNotifications = (payload) => async (dispatch) => {
  try {
    dispatch(ActionCreator(Notification.LIST_NOTIFICATION_REQUEST));
    const { data: responseData } = await NotificationService.listNotifications(
      payload
    );

    if (responseData.status === 'FAILED') {
      throw responseData;
    }

    dispatch(
      ActionCreator(
        Notification.LIST_NOTIFICATION_SUCCESS,
        responseData.data.notificationsDTOs
      )
    );
  } catch (error) {
    expiredTokenCheck(error, dispatch);

    dispatch(ActionCreator(Notification.LIST_NOTIFICATION_FAILURE));
    toast.error('Error fetching notifications', {
      position: toast.POSITION.TOP_CENTER,
    });
    console.error(error);
  }
};

import React from "react";
/** @jsx jsx */
/** @jsxRuntime classic */
import { Helmet } from "react-helmet";
import { jsx } from "@emotion/react";
import { useFormik } from "formik";
import Select from "react-select";
import * as Yup from "yup";

import NavBar from "Components/Navbar";
import Footer from "Components/Footer";
import BackArrow from "Components/BackArrow";
import HeadingText from "Components/HeadingText";
import CustomInput from "Components/CustomInput";
import FormLabel from "Components/FormLabel";
import DropDownComponent from "Components/DropDownComponent";
import CustomTextArea from "Components/CustomTextArea";
import CustomButton from "Components/CustomButton";
import downArrow from "Assets/svg/blue-drop-down-arrow.svg";
import customStyles from "Assets/styles/dropdown/customStyles";
import offSetImg from "Assets/svg/transfer1.svg";

import {
  bodyCSS,
  selectWrapperCSS,
  formWrapperCSS,
  offCanvasIconCSS,
  formContainerCSS
} from "./styleSheet";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { fetchCurrencies, createBankAccount } from "Store/actions/bankAccount.action";
import Spinner from "Components/Spinner";
import ErrorField from "Components/ErrorField";

const CreateBankAccount = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [bankSelector, theme, domainBrand] = useSelector(
    ({ bankAccountReducer, themeReducer, domainBrandReducer }) => [
      bankAccountReducer,
      themeReducer,
      domainBrandReducer
    ]
  );

  const languageDictionary = domainBrand.domainBrand["languageDictionary"];
  const languageDictionaryStatus = !(
    typeof languageDictionary === "undefined" || languageDictionary === null
  );

  const loading = bankSelector.createAccountLoadingStatus === "FETCHING";
  const { walletCurrenciesLoading, walletCurrenciesDropdown } = bankSelector;

  const formik = useFormik({
    initialValues: {
      accountName: "",
      currencyCode: "",
      description: ""
    },
    validationSchema: Yup.object({
      accountName: Yup.string().required("Account name is required"),
      currencyCode: Yup.object({ value: Yup.string().required("Currency code is Required") }),
      description: Yup.string().required("Description is required")
    }),
    onSubmit: (values) => {
      const requestPayload = {
        name: values.accountName,
        description: values.description,
        walletCurrency: values.currencyCode.value
      };

      const navigateMethod = () => history.push("/account/balance");
      dispatch(createBankAccount(requestPayload, navigateMethod));
    }
  });

  useEffect(() => {
    dispatch(fetchCurrencies());
  }, [dispatch]);

  return (
    <div css={bodyCSS}>
      <Helmet>
        <title>
          {languageDictionaryStatus
            ? languageDictionary["WO_REQUEST_BANK_ACCOUN_1842427382"]
            : "Request bank account"}{" "}
        </title>
      </Helmet>
      <NavBar />
      <main className="mb-5">
        <div css={offCanvasIconCSS}>
          <img src={offSetImg} alt="off canvas icon" />
        </div>
        <section className="container pt-3 mt-2">
          <div className="d-flex">
            <BackArrow />
            <HeadingText
              text={
                languageDictionaryStatus
                  ? languageDictionary["WO_REQUEST_BANK_ACCOUN_1842427382"]
                  : "Request bank account"
              }
            />
          </div>

          <div css={formWrapperCSS}>
            {walletCurrenciesLoading ? (
              <Spinner />
            ) : (
              <form onSubmit={formik.handleSubmit} css={formContainerCSS}>
                <CustomInput
                  formik={formik}
                  inputKey="accountName"
                  className="form-control"
                  labelName={
                    languageDictionaryStatus
                      ? languageDictionary["WO_ACCOUNT_NAM_-935856925"]
                      : "Account Name"
                  }
                />
                <FormLabel text="Currency Code">
                  <ErrorField
                    formik={formik}
                    fieldName={
                      languageDictionaryStatus
                        ? languageDictionary["para_currency_code"]
                        : "Currency Code"
                    }
                    fetchingData={walletCurrenciesLoading}
                    selectField
                  />
                  <div css={selectWrapperCSS}>
                    <Select
                      value={formik.values.currencyCode}
                      onChange={(option) => formik.setFieldValue("currencyCode", option)}
                      onBlur={() => formik.setFieldTouched("currencyCode", true)}
                      options={walletCurrenciesDropdown}
                      customTheme={theme}
                      styles={customStyles}
                      isClearable={false}
                      isSearchable={false}
                      isLoading={walletCurrenciesLoading}
                      placeholder="Select currency"
                      components={{
                        IndicatorSeparator: null,
                        DropdownIndicator: () => <DropDownComponent iconSource={downArrow} />
                      }}
                    />
                  </div>
                </FormLabel>
                <CustomTextArea
                  formik={formik}
                  inputKey="description"
                  inputSize="100"
                  labelName={
                    languageDictionaryStatus
                      ? languageDictionary["para_description"]
                      : "Description"
                  }
                />
                <div>
                  <CustomButton
                    buttonText={
                      languageDictionaryStatus
                        ? languageDictionary["para_create_new_account"]
                        : "Create"
                    }
                    buttonWidth="120px"
                    loading={loading}
                  />
                </div>
              </form>
            )}
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default CreateBankAccount;

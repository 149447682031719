import { css } from '@emotion/react';

export const newsContainer = css`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const newsMain = css`
  flex: 1;
  position: relative;

  
`;

export const mainContainer = css`
  max-width: 1100px;
  margin: 0 auto;

  @media (max-width: 56.25rem) {
    padding: 1rem;
  }
`;

export const mainHeading = (theme) => css`
  margin-top: 70px;
  color: ${theme.secondaryColor};
  font-weight: 500;
  font-size: 43px;
  margin-bottom: 40px;

  @media (max-width: 56.25rem) {
    font-size: 1.5rem;
    margin-top: 2.5rem;
  }
`;

export const topSection = css`
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;

  @media (max-width: 56.25rem) {
    flex-direction: column;
  }
`;

export const topSectionCard = (theme) => css`
  background: ${theme.primaryColor};
  border-radius: 20px;
  height: 350px;
  flex-basis: 30%;
  max-width: 30%;
  padding: 60px 47px 50px;
  display: flex;
  flex-direction: column;

  @media (max-width: 56.25rem) {
    max-width: 100%;
    border-radius: 20px 20px 0 0;
  }
`;

export const topSectionCardDate = css`
  font-size: 22px;
  color: white;
  font-weight: 500;
`;

export const topSectionCardTitle = css`
  font-size: 35px;
  line-height: 1.5;
  color: white;
  margin-top: 15px;
  font-weight: 500;

  @media (max-width: 56.25rem) {
    font-size: 1.5625rem;
  }
`;

export const topSectionCardButton = (theme) => css`
  background: ${theme.secondaryColor};
  margin-top: auto;
  border: 0;
  height: 55px;
  color: white;
  width: 140px;
  font-weight: 300;
  border-radius: 5px;
  outline: none;
  font-size: 20px;
`;

export const topSectionImg = css`
  flex-basis: 65%;
  max-width: 65%;
  border-radius: 20px;
  overflow: hidden;
  background: #0fcc98;

  @media (max-width: 56.25rem) {
    max-width: 100%;
    border-radius: 0 0 20px 20px;
  }

  img {
    height: 100%;
    width: 100%;
    mix-blend-mode: multiply;
  }
`;

export const bottomSection = css`
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;

  @media (max-width: 56.25rem) {
    flex-direction: column-reverse;
  }
`;

export const bottomSectionCard = (theme) => css`
  background: ${theme.secondaryColor};
  border-radius: 20px;
  height: 350px;
  flex-basis: 30%;
  max-width: 30%;
  padding: 60px 47px 50px;
  display: flex;
  flex-direction: column;
  
  @media (max-width: 56.25rem) {
    max-width: 100%;
    border-radius: 20px 20px 0 0;
  }
`;

export const bottomSectionCardButton = (theme) => css`
  background: ${theme.primaryColor};
  margin-top: auto;
  border: 0;
  height: 55px;
  color: white;
  width: 140px;
  font-weight: 300;
  border-radius: 5px;
  outline: none;
  font-size: 20px;
`;

export const bottomSectionImg = css`
  flex-basis: 65%;
  max-width: 65%;
  border-radius: 20px;
  overflow: hidden;
  background: #368fea;
  /* background-blend-mode: multiply; */

  @media (max-width: 56.25rem) {
    max-width: 100%;
    border-radius: 0 0 20px 20px;
  }

  img {
    height: 100%;
    width: 100%;
    mix-blend-mode: multiply;
  }
`;

// eslint-disable-next-line no-unused-vars
import React, { useEffect } from "react";
/** @jsx jsx */
/** @jsxRuntime classic */

import { jsx, css } from "@emotion/react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import HeadingText from "Components/HeadingText";
import CustomButton from "Components/CustomButton";
import CustomInput from "Components/CustomInput";
import FormLabel from "Components/FormLabel";
import CustomTextArea from "Components/CustomTextArea";
import DropDownComponent from "Components/DropDownComponent";
import ErrorField from "Components/ErrorField";
import customStyles from "Assets/styles/dropdown/customStyles";
import downArrow from "Assets/svg/blue-drop-down-arrow.svg";
import { getCustomerServiceUsers } from "Store/actions/user.action";
import * as Loading from "Store/constants/loadingState.constants";
import navigateMethod from "Utils/Helpers/navigateMethod";
import { sendNotification } from "Store/actions/notification.action";

const CreateNotifications = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { user, notification, theme } = useSelector(
    ({ userReducer, notificationReducer, themeReducer }) => ({
      user: userReducer,
      notification: notificationReducer,
      theme: themeReducer
    })
  );
  const formik = useFormik({
    initialValues: {
      subject: "",
      body: ""
    },
    validationSchema: Yup.object({
      subject: Yup.string().required("required"),
      body: Yup.string().required("required")
    }),
    onSubmit: (values) => {
      const dataValues = {
        subject: values.subject,
        body: values.body
      };
      const navigator = navigateMethod(history, "/profile/notifications");
      dispatch(sendNotification(dataValues, navigator));
    }
  });

  useEffect(() => {
    if (user.customerServiceTeamStatus !== Loading.SUCCESS || user.customerServiceTeamStatus === Loading.ERROR) {
      dispatch(getCustomerServiceUsers());
    }
  }, []);

  return (
    <div>
      <HeadingText text="Send a mail" />
      <form onSubmit={formik.handleSubmit} css={css`margin-top: 20px;`}>
        <CustomInput
          formik={formik}
          inputKey="subject"
          inputSize="100"
          labelName="Subject:"
        />
        <CustomTextArea
          formik={formik}
          inputKey="body"
          inputSize="100"
          labelName="Message:"
          height="230px"
        />
        <div css={css`display: flex;justify-content: flex-end;`}>
          <CustomButton
            buttonText="SEND MESSAGE TO SUPPORT"
            loading={notification.sendStatus === Loading.FETCHING}
          />
        </div>
      </form>
    </div>
  );
};

export default CreateNotifications;

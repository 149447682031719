// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from "react";
/** @jsx jsx */
/** @jsxRuntime classic */

import { jsx, css } from "@emotion/react";
import * as Yup from "yup";
import { useFormik } from "formik";
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";

import HeadingText from "Components/HeadingText";
import NavBar from "Components/Navbar";
import Footer from "Components/Footer";
import { Container, Main, InnerContainer, OffsetIcon, ImageElement } from "Components/Styled";
import CustomInput from "Components/CustomInput";
import DropDownComponent from "Components/DropDownComponent";

import downArrow from "Assets/svg/blue-drop-down-arrow.svg";
import customStyles from "Assets/styles/dropdown/customStyles";
import offSetImg from "Assets/svg/man-user-1.svg";
import { mainBody, selectWrapper, buttonWrapper } from "./style";
import ErrorField from "Components/ErrorField";
import Label from "Components/Label";
import CustomButton from "Components/CustomButton";
import { getAllCountries } from "Store/actions/country.action";
import * as Loading from "Store/constants/loadingState.constants";
import { useHistory, useLocation, Redirect } from "react-router-dom";
import { updateUser } from "Store/actions/user.action";
import { userStatus } from "Utils/constants";
import BackArrow from "Components/BackArrow";

const EditUser = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { country, user, profile, theme, domainBrand } = useSelector(
    ({ countryReducer, userReducer, profileReducer, themeReducer, domainBrandReducer }) => ({
      country: countryReducer,
      user: userReducer,
      profile: profileReducer,
      theme: themeReducer,
      domainBrand: domainBrandReducer
    })
  );

  const userAddress  = profile['userData']['address'];
  const languageDictionary = domainBrand.domainBrand["languageDictionary"];
  const languageDictionaryStatus = !(typeof languageDictionary === "undefined" || languageDictionary === null);
  const [initialValues, updateInitialValues] = useState({
    email: "",
    firstName: "",
    lastName: "",
    phone: "",
    country: "",
    customerGender: "",
    status: "",
    address1: "",
    address2: "",
    city: "",
    dialingCode: "",
    postcode: ""
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: Yup.object({
      email: Yup.string().required("required"),
      firstName: Yup.string().required("required"),
      lastName: Yup.string().required("required"),
      phone: Yup.string().required("required"),
    }),
    onSubmit: (values) => {
      const payloadData = {
        firstName: location.state.firstName,
        lastName: location.state.lastName,
        phone: values.phone,
        customerGender: values.customerGender.value,
        address: {
          address1: values.address1,
          address2: values.address2,
          city: values.city,
          postcode: values.postcode,
          countryCommonName: values.country,
          countryIso3: values.countryIso3
        }
      };

      const navigateMethod = () => history.push("/users");
      dispatch(updateUser(payloadData, navigateMethod));
    }
  });

  useEffect(() => {
    if (country.loading !== Loading.SUCCESS || country.loading === Loading.ERROR) {
      dispatch(getAllCountries());
    }
  }, []);

  useEffect(() => {
    if (location.state && country.loading === Loading.SUCCESS) {
      const { state: { firstName, lastName, phone, country: countryValue, email, status } } = location;

      updateInitialValues({
        ...formik.initialValues,
        email,
        firstName,
        lastName,
        phone,
        country: userAddress.countryCommonName,
        status: userStatus.find((item) => item.value === status),
        address1: userAddress.address1,
        address2: userAddress.address2,
        city: userAddress.city,
        postcode: userAddress.postcode,
        countryIso3: userAddress.countryIso3
      });
    }
  }, [country.loading]);

  const countryLoading = country.loading === Loading.FETCHING;
  const userCreationLoading = user.userCreateStatus === Loading.FETCHING;
  const customerGenderTypes = [{value: 'MALE', label: 'Male'}, {value: 'FEMALE', label: 'Female'}];

  if (!location.state) {
    return <Redirect to="/users" />;
  }

  return (
    <Container>
      <NavBar />
      <InnerContainer>
        <OffsetIcon>
          <ImageElement src={offSetImg} title="money icon" />
        </OffsetIcon>

        <Main className="mt-5">
          <div className="d-flex">
            <BackArrow />
            <HeadingText text="Update user" />
          </div>
          <form css={mainBody} onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-md-6">
                <Label text={languageDictionaryStatus ? languageDictionary["l_first_name"] : "First Name"}>
                  <CustomInput formik={formik} inputKey="firstName" inputSize={47.5} />
                </Label>
              </div>
              <div className="col-md-6">
                <Label
                  text={
                    languageDictionaryStatus ? languageDictionary["para_last_name"] : "Last Name"
                  }>
                  <CustomInput formik={formik} inputKey="lastName" inputSize={47.5} />
                </Label>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <Label text={languageDictionaryStatus ? languageDictionary["l_email"] : "Email"}>
                  <CustomInput
                    formik={formik}
                    inputKey="email"
                    inputSize={47.5}
                    inputType="email"
                  />
                </Label>
              </div>
              <div className="col-md-6">
                <Label
                  text={
                    languageDictionaryStatus
                      ? languageDictionary["para_phone_number"]
                      : "Phone Number"
                  }>
                  <CustomInput formik={formik} inputKey="phone" inputSize={47.5} />
                </Label>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <Label
                  text={languageDictionaryStatus ? languageDictionary["para_status"] : "Status"}>
                  <ErrorField formik={formik} fieldName="status" selectField />{" "}
                  <div css={selectWrapper}>
                    <Select
                      value={formik.values.status}
                      onChange={(option) => formik.setFieldValue("status", option)}
                      onBlur={() => formik.setFieldTouched("status", true)}
                      options={userStatus}
                      styles={customStyles}
                      isClearable={false}
                      isSearchable={false}
                      customTheme={theme}
                      className="mt-3"
                      placeholder="Select status"
                      components={{
                        IndicatorSeparator: null,
                        DropdownIndicator: () => <DropDownComponent iconSource={downArrow} />,
                      }}
                    />
                  </div>
                </Label>
              </div>
              <div className="col-md-6">
                <Label
                  text={
                    languageDictionaryStatus ? languageDictionary["WO_GENDE_-274590021"] : "Gender"
                  }>
                  <ErrorField
                    formik={formik}
                    fieldName="customerGender"
                    fetchingData={customerGenderTypes}
                    selectField
                  />{" "}
                  <div css={selectWrapper}>
                    <Select
                      value={formik.values.customerGender}
                      onChange={(option) => formik.setFieldValue("customerGender", option)}
                      onBlur={() => formik.setFieldTouched("customerGender", true)}
                      styles={customStyles}
                      isClearable={false}
                      customTheme={theme}
                      className="mt-3"
                      options={customerGenderTypes}
                      placeholder="Select Gender"
                      components={{
                        IndicatorSeparator: null,
                        DropdownIndicator: () => <DropDownComponent iconSource={downArrow} />,
                      }}
                    />
                  </div>
                </Label>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <Label
                  text={
                    languageDictionaryStatus
                      ? languageDictionary["para_address_line_1"]
                      : "Address Line 1"
                  }>
                  <CustomInput disabled={true} formik={formik} inputKey="address1" inputSize={47.5} />
                </Label>
              </div>
              <div className="col-md-6">
                <Label
                  text={
                    languageDictionaryStatus
                      ? languageDictionary["para_address_line_2"]
                      : "Address Line 2"
                  }>
                  <CustomInput disabled={true} formik={formik} inputKey="address2" inputSize={47.5} />
                </Label>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <Label text={languageDictionaryStatus ? languageDictionary["para_city"] : "City"}>
                  <CustomInput disabled={true} formik={formik} inputKey="city" inputSize={47.5} />
                </Label>
              </div>

              <div className="col-md-6">
                <Label
                  text={
                    languageDictionaryStatus ? languageDictionary["POSTCOD_1360880751"] : "Postcode"
                  }>
                  <CustomInput disabled={true} formik={formik} inputKey="postcode" inputSize={47.5} />
                </Label>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <Label text={languageDictionaryStatus ? languageDictionary["WO_COUNTR_1271562677"] : "Country"}>
                  <CustomInput disabled={true} formik={formik} inputKey="country" inputSize={47.5} />
                </Label>
              </div>
            </div>

            <div css={buttonWrapper}>
              <CustomButton buttonText="UPDATE USER" loading={userCreationLoading} />
            </div>
          </form>
        </Main>
      </InnerContainer>
      <Footer />
    </Container>
  );
};

export default EditUser;

const customStyles = {
  option: (provided, state) => {
    const { selectProps: { customTheme: { primaryColor } } } = state;
    return {
      ...provided,
      borderBottom: `${state.value === state.options[state.options.length - 1].value ? null : "1px solid #90A8BE47"}`,
      color: state.isSelected ? "#fff" : primaryColor,
      padding: "15px 20px",
      fontSize: "14px",
      fontWeight: 300
    };
  },
  control: (provided, state) => {
    const { selectProps: { customTheme: { primaryColor } } } = state;
    return {
      ...provided,
      background: "#E3EDF3",
      marginTop: "1rem",
      border: 0,
      borderRadius: "5px",
      color: primaryColor,
      fontWeight: 300,
      width: "100%",
      height: "3.3rem",
      padding: "10px 10px"
    };
  },
  placeholder: (provided, state) => {
    const { selectProps: { customTheme: { primaryColor } } } = state;
    return {
      ...provided,
      color: primaryColor,
      fontSize: "14px",
      fontWeight: 300
    };
  },
  container: (provided) => ({
    ...provided
    // marginTop: 12,
    // width: 110,
  }),
  menu: (provided) => {
    return {
      ...provided,
      margin: 0,
      background: "#F5F9FB",
      borderRadius: 0,
      padding: 0,
      zIndex: 9
    };
  },
  menuList: (provided) => {
    return {
      ...provided,
      padding: 0
    };
  },
  dropdownIndicator: (provided) => {
    return {
      ...provided
    };
  },
  indicatorsContainer: (provided) => {
    return {
      ...provided,
      paddingLeft: 8,
      paddingRight: 20
    };
  },
  input: (provided) => {
    return {
      ...provided,
      padding: 0,
      margin: 0
    };
  },
  singleValue: (provided, state) => {
    // const opacity = state.isDisabled ? 0.5 : 1;
    // const transition = 'opacity 300ms';
    const {
      selectProps: {
        customTheme: { primaryColor }
      }
    } = state;

    return {
      ...provided,
      color: primaryColor,
      fontSize: "14px",
      paddingTop: "5px",
      paddingBottom: "5px"
    };
  },
  valueContainer: (provided) => {
    return {
      ...provided,
      padding: "0"
    };
  }
};

export default customStyles;

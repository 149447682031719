import React from "react";
import { useHistory } from "react-router-dom";
import styled from "@emotion/styled";

export const TD = styled.td`
  background: #e3edf3;
  text-align: center;
`;

export const Span = styled.span`
  display: block;
  margin-top: 24.5px;
  margin-bottom: 24.5px;
  border-right: 2px solid #90a8be47;
  font-size: 15px;
  color: #0055ad;
  font-weight: 500;
  padding-left: 20px;
  padding-right: 20px;
`;

const OrderHistoryItems = ({ to, amount, productName }) => {
  const history = useHistory();

  return (
    <tr>
      <TD className="py-4 align-middle"><Span>{productName}</Span></TD>
      <TD className="py-4 align-middle"><Span>26/02/2021</Span></TD>
      <TD className="py-4 align-middle"><Span>{amount}</Span></TD>
      <TD className="py-4 align-middle">
        <span className="badge p-2 text-uppercase bg-secondary text-white">Pending</span>
      </TD>
      {/* <TD className="py-4 align-middle">
        <Link to="/profile/order-details" className="btn btn-blue text-white"
        onClick={() => history.push(to)}
        role="button"
        tabIndex="-1"
        onKeyPress={() => {}}>
          View
        </Link>
      </TD> */}
    </tr>


  );
};

export default OrderHistoryItems;

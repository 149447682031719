import encryptService from "./encryptHelper";
import Cookies from "js-cookie";

const cookieHelper = {

    get: (key) => {
        Cookies.get()
        return encryptService.decrypt(Cookies.get(key));
    },

    set: (key, data, ex) => {
        const dataEncrypted = encryptService.encrypt(data);
        Cookies.set(key, dataEncrypted, {expires: ex});
    },

    remove: (key) => {
        localStorage.removeItem(key);

    },


}

export default cookieHelper;



import styled from "@emotion/styled";

export const Button = styled.button`
  color: #fff;
  border-color: #0FCC98;
  border-radius: 0.3125rem;

  :hover::after {
    background-color: #0055AD;
    color: #fff;
  }
`;

export const NextButton = styled.button`
  font-size: 0.8rem;
`;

export const Image = styled.div`
    position: relative;
    overflow: hidden;
`;

export const Card = styled.div`
  background-color: #e3edf36b;
`;

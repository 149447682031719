// eslint-disable-next-line no-unused-vars
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
/** @jsx jsx */
/** @jsxRuntime classic */
import { jsx } from "@emotion/react";
import {
  forgotContainer,
  leftSection,
  leftContainer,
  leftHeading,
  leftFormLabel,
  leftFormButton,
  leftLink
} from "./style";
import RightBanner from "Components/RightBanner";
import AlertCard from "Components/AlertCard";
import ButtonSpinner from "Components/ButtonSpinner";
import { forgotPassword } from "Store/actions/auth.action";
import * as Status from "Store/constants/loadingState.constants";
import { BrandedRegularButton, BrandedSubmitButton } from "../../../Components/BrandingComponents";

const ForgotPassword = () => {
  const dispatch = useDispatch();
  let domainBrandStatus;
  const { domainBrand } = useSelector(({ domainBrandReducer }) => ({
    domainBrand: domainBrandReducer
  }));
  const [emailField, updateEmailField] = useState("");
  const { forgotPasswordStatus } = useSelector(({ authReducer }) => authReducer);
  const isButtonDisabled = forgotPasswordStatus === Status.FETCHING;

  const handleForgotPassword = (e) => {
    e.preventDefault();
    if (!emailField) {
      return;
    }
    const payload = { email: emailField };
    dispatch(forgotPassword(payload));
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>Forgot password</title>
      </Helmet>
      <div css={forgotContainer}>
        <div css={leftSection}>
          <div>
            <h1 css={leftHeading}>Forgot password</h1>

            {forgotPasswordStatus === Status.SUCCESS && (
              <AlertCard message="If the email you specified exists in our system, we've sent a password reset link to it" />
            )}
            <form css={leftContainer} onSubmit={handleForgotPassword}>
              <label htmlFor="email" css={leftFormLabel}>
                <span>Email</span>
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder={
                    domainBrandStatus
                      ? domainBrand["domainBrand"]["languageDictionary"]["msg_enter_your_email"]
                      : "Enter your email address"
                  }
                  required
                  onChange={(e) => updateEmailField(e.target.value)}
                />
              </label>

              <BrandedSubmitButton css={leftFormButton} type="submit" disabled={isButtonDisabled}>
                {isButtonDisabled ? <ButtonSpinner /> : "Submit"}
              </BrandedSubmitButton>

              <Link to="/" css={leftLink}>
                or return to homepage
              </Link>
            </form>
          </div>
        </div>
        <RightBanner />
      </div>
    </React.Fragment>
  );
};

export default ForgotPassword;

import { SUBDOMAIN_CHECK } from "../../Store/constants/appLauncher.constants";
import { subDomainMapper } from "../Helpers/subdomainMaps";

export const DEFAULT_BASEURL = "test";
export const LOCALHOST_BASEURL = "localhost";
export const TEMPORARY_BASEURL = "multiview-mta";
export const DEV_BRANCH_BASEURL = "develop-branch-multiview";
export const DEV_ENV = "co";

export const SUBDOMAIN_ENV = (url, root) => {
  return !(
    url === TEMPORARY_BASEURL ||
    url === DEV_BRANCH_BASEURL ||
    url === LOCALHOST_BASEURL ||
    root === DEV_ENV
  );
};

const fetchSubDomain = () => {
  const subDomainBaseURL = window.location.hostname.split(".")[0];
  const subDomainOrigin = window.location.hostname.split(".")[2];
  const domainURL =
    subDomainBaseURL === TEMPORARY_BASEURL ||
    subDomainBaseURL === DEV_BRANCH_BASEURL ||
    subDomainBaseURL === LOCALHOST_BASEURL
      ? DEFAULT_BASEURL
      : subDomainBaseURL;
  const subdomainChecker = SUBDOMAIN_CHECK(window.location.hostname);
  const subdomainEnv = SUBDOMAIN_ENV(subDomainBaseURL, subDomainOrigin);

  // console.log(window.location.hostname, subDomainBaseURL, subDomainOrigin, domainURL, domainURL.toUpperCase(), subdomainChecker, subdomainEnv);
  const subdomainState =
    domainURL === DEFAULT_BASEURL
      ? subDomainMapper["HOSTS"]
      : subdomainChecker && subdomainEnv
      ? subDomainMapper[`${domainURL.toUpperCase()}-LIVE`]
      : subdomainChecker
      ? subDomainMapper[domainURL.toUpperCase()]
      : (window.location.href = "https://spotbanc.com/");
  return `https://${subdomainState.API_SUBDOMAIN}.${subdomainState.API_ENV.env}`;
};

export const getBaseUrl = () => fetchSubDomain();

import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useHistory, Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";
import Footer from "Components/Footer";
import NavBar from "Components/Navbar";
import { Span, Image, Card } from "./style";

const ItemsInCart = ({ productImage, currency }) => {
  const history = useHistory();
  const { cart } = useSelector(({ cartReducer }) => ({
    cart: cartReducer
  }));

  const cartQuantity = cart.items.reduce((acc, item) => acc + item.quantity, 0);
  const itemsSubtotal = cart.items.reduce((acc, item) => {
    return acc + item.quantity * item.product_value;
  }, 0);

  const itemsInCart = cartQuantity > 0 ? `${cartQuantity} items` : `${cartQuantity} item`;

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  if (cartQuantity < 1) {
    return <Redirect to="/merchant-product" />;
  }

  return (
    <>
      <Helmet>
        <title>Items in cart</title>
      </Helmet>
      <NavBar />
      <section className="vh-100">
        <div className="container">
          <div className="mt-5 mb-5">
            <div className="d-flex justify-content-between align-items-center">
              <h2 className="text-blue mb-5">Items in cart</h2>
            </div>

            {/* --- Product description --- */}

            <div className="row">
              <div className="col-12">
                <Card className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-4">
                        <div className="d-flex justify-content-between">
                          <div className="mt-3">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="#28a745"
                              width="40px">
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                              />
                            </svg>
                          </div>
                          <Image>
                            <img src={productImage} alt="product" className="img-fluid" />
                          </Image>
                          <div>
                            <Span className="text-green font-weight-bolder">Added to Cart</Span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3 col-xl-4">
                        <div className="d-flex justify-content-between mt-4">
                          <h5 className="text-blue">
                            Cart subtotal ({itemsInCart}):{" "}
                            <span className="text-green">
                              {" "}
                              {currency}
                              {itemsSubtotal}
                            </span>
                          </h5>
                        </div>
                      </div>
                      <div className="col-md-5 col-xl-4">
                        <div className="d-flex justify-content-around mt-3">
                          <Link
                            to="/cart"
                            className="btn px-md-3 btn-blue text-white"
                            onClick={() => history.push()}
                            role="button"
                            tabIndex="-1"
                            onKeyPress={() => {}}>
                            Go to shopping cart ({itemsInCart})
                          </Link>
                          {/* <Link
                          to="/profile/order-history"
                          className="btn p-2 btn-green text-white"
                          onClick={() => history.push()}
                          role="button"
                          tabIndex="-1"
                          onKeyPress={() => {}}
                        >
                          Proceed to checkout ({itemsInCart})
                        </Link> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default ItemsInCart;

// eslint-disable-next-line no-unused-vars
import React from 'react';

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';

const errorStyles = css`
  color: red;
  margin-top: 5px;
  font-weight: 500;
`;

const ErrorField = ({ formik, fieldName, selectField, fetchingData }) => {
  return (
    <React.Fragment>
      {!fetchingData &&
      formik.touched[fieldName] &&
      formik.errors[fieldName] ? (
        <div css={errorStyles}>
          {selectField
            ? formik.errors[fieldName].value
            : formik.errors[fieldName]}
        </div>
      ) : null}
    </React.Fragment>
  );
};

export default ErrorField;

import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { AmountCard, ListItem } from "./style";

const PaymentSummary = ({ address, name, subtotal, total, shippingfee }) => {
  const history = useHistory();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <AmountCard className="card border-0 mb-3 p-xl-3">
      <div className="card-body">
        <ul className="list-group list-unstyled text-white">
          <ListItem>
            <h5 className="font-weight-bold">Shipping and Installation</h5>

            <div className="d-flex">
              <p>Installation</p>
              <div className="ml-auto">
                <h6>{shippingfee}35</h6>
              </div>
            </div>
          </ListItem>

          <ListItem>
            <h5 className="text-success font-normal">Address</h5>

            <div className="d-flex">
              <div className="p-0 col-8">
                {address}
              </div>
              <div className="ml-auto">
                <button className="btn btn-green text-white" onClick={handleShow}>
                  Change
                </button>
                <Modal show={show} onHide={handleClose} animation={true}>
                  <Modal.Header closeButton>
                    <Modal.Title>Choose your location</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="text-blue mb-3">
                      <p>Delivery options and delivery speeds may vary for different locations</p>
                    </div>
                    <div className="card mb-3">
                      <div className="card-body">
                        <span className="text-blue">
                          {name}
                        </span>
                        <span className="ml-2">
                          {address}
                        </span>
                      </div>
                    </div>
                  </Modal.Body>

                  <Modal.Footer>
                    {" "}
                    <div className="mx-auto">
                      <Link
                        to="/addresses"
                        className="text-decoration-none"
                        onClick={() => history.push("/addresses")}
                        role="button"
                        tabIndex="-1"
                        onKeyPress={() => {}}>
                        <button type="submit" className="btn btn-green text-white">
                          Manage address book
                        </button>
                      </Link>
                    </div>
                  </Modal.Footer>
                </Modal>
              </div>
            </div>
          </ListItem>
          <ListItem>
            <div className="d-flex mb-3">
              <h6>Sub-Total</h6>
              <div className="ml-auto">
                <h6>
                  {subtotal}
                </h6>
              </div>
            </div>
            <div className="d-flex mb-3">
              <p>Shipping &amp; Installation</p>
              <div className="ml-auto">
                <h6>{shippingfee}35</h6>
              </div>
            </div>
            <div className="d-flex">
              <h4 className="text-green">Total</h4>
              <div className="ml-auto text-green">
                <h4 className="font-weight-bolder">
                  {total}
                </h4>
              </div>
            </div>
          </ListItem>
        </ul>
      </div>
    </AmountCard>
  );
};

export default PaymentSummary;

import * as DynamicFields from "../constants/dynamicFields.constants";
import * as Loading from "../constants/loadingState.constants";

const initialState = {
  loading: '',
};

const dynamicFieldsReducer = (state = initialState, action) => {
  switch (action.type){
    case DynamicFields.GET_DYNAMIC_FIELDS_REQUEST:
      return {
        ...state,
        loading: Loading.FETCHING,
      };

    case DynamicFields.GET_DYNAMIC_FIELDS_SUCCESS:
      let formType = action.payload.formType;
      return {
        ...state,
        [formType]: action.payload,
        loading: Loading.SUCCESS,
      }
    case DynamicFields.GET_DYNAMIC_FIELDS_FAILURE:
      return {
        ...state,
        loading : Loading.ERROR,
      }
    default:
      return state;
  }
}

export default dynamicFieldsReducer;

import React from "react";
import { useHistory } from "react-router-dom";
import Arrow from "Assets/svg/left-arrow.svg";

const BackArrow = () => {
  const history = useHistory();

  const handleClick = () => {
    history.goBack();
  }

  return (
    <button className="btn mt-4" type="button" onClick={handleClick}>
     <img src={Arrow} alt="..." />
    </button>
  );
};

export default BackArrow;

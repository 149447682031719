import React from "react";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import styled from "@emotion/styled";
import { useDispatch } from "react-redux";
import * as CartActions from "Store/actions/cart.action";

const Card = styled.div`
  background-color: #e3edf36b;
  border-radius: 12px;
`;

const Media = styled.div`
  display: flex;
  align-items: flex-start;
`;

const MediaBody = styled.div`
  flex: 1;
`;

const ShoppingCartItem = ({ name, amount, imageSrc, productId, quantity }) => {
  const dispatch = useDispatch();

  const handleIncrement = () => {
    dispatch(CartActions.incrementQuantity(productId, quantity));
  };

  const handleDecrement = () => {
    dispatch(CartActions.decrementQuantity(productId, quantity));
  };

  const removeItemInCart = () => {
    dispatch(CartActions.removeItemFromCart(productId));
  };

  return (
    <ul className="list-group list-unstyled">
      <li className="list-item border-0">
        <Card className="p-3 p-md-1 p-xl-3 d-sm-flex justify-content-between align-items-center mt-3 mb-3 pb-3">
          <Media className="d-block d-sm-flex align-items-center text-center text-sm-left">
            <div
              className="d-inline-block mx-auto mr-sm-4"
              style={{ width: "7.5rem" }}
            >
              <img src={imageSrc} alt={name} className="img-fluid" />
            </div>

            <MediaBody className="pt-2">
              <h5 className="text-green font-weight-bold">{name}</h5>
              <div>
                <h6 className="text-blue">{amount}</h6>
              </div>
            </MediaBody>
          </Media>

          <div
            className="pt-2 pt-sm-0 pl-sm-3 mx-auto mx-sm-0 text-center text-sm-left"
            style={{ maxWidth: "8rem" }}
          >
            <div className="form-group">
              <Form.Label className="font-weight-medium text-blue">
                Quantity
              </Form.Label>
              <InputGroup className="input-group-sm">
                <InputGroup.Prepend>
                  <Button
                    className="btn text-white btn-blue large font-weight-bolder"
                    onClick={handleDecrement}
                    disabled={quantity === 1}
                  >
                    -
                  </Button>
                </InputGroup.Prepend>

                <span className="border px-3 pt-1 justify-content-center text-blue font-weight-bolder">
                  {quantity}
                </span>

                <InputGroup.Append>
                  <Button
                    className="btn text-white btn-blue large font-weight-bolder"
                    onClick={handleIncrement}
                  >
                    +
                  </Button>
                </InputGroup.Append>
              </InputGroup>
            </div>
            <button className="btn small text-muted text-decoration-none ml-auto" onClick={removeItemInCart}>
              <svg viewBox="0 0 20 20" fill="#dc3545" width="20px">
                <path
                  fillRule="evenodd"
                  d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                  clipRule="evenodd"
                />
              </svg>
              <span className="p" style={{ color: "#dc3545" }}>
                Remove
              </span>
            </button>
          </div>
        </Card>
      </li>
    </ul>
  );
};

export default ShoppingCartItem;

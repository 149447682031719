import transferIcon from "Assets/svg/transfer.svg";
import surfaceIcon from "Assets/svg/surface1.svg";
import accountIcon from "Assets/svg/account-icon.svg";
import addUserIcon from "Assets/svg/add-user.svg";
import airtimeIcon from "Assets/svg/airtime-topup.svg";
import billIcon from "Assets/svg/bill-payment.svg";
import cashIcon from "Assets/svg/cash-pickup.svg";
import mobileIcon from "Assets/svg/mobile-money.svg";
import merchantIcon from "Assets/svg/merchant-product.svg";
import configureStore from 'Store';
const store = configureStore();

export const getProtectedThing = () => {
  const state = store.getState();
  console.log(state);
}

export const transactionTypesMap = {
  MULTICURRENCY_WALLET_VIRTUAL_ACCOUNT_MANAGEMENT: {
    id: 1,
    to: "/account/statement",
    icon: accountIcon,
    text: "Account Statement",
    lingualText: "l_wallet_transaction_history",
    visibleTo: ["INDIVIDUAL", "COMPANY"],
  },
  ADD_NEW_USER: {
    id: 2,
    to: "/users",
    icon: addUserIcon,
    text: "Add new user",
    lingualText: "WO_ADD_A_NEW_USE_764293023",
    visibleTo: ["INDIVIDUAL", "COMPANY"],
  },
  MOBILEAIRTIMETOP_UP: {
    id: 3,
    to: "/airtime-topup",
    icon: airtimeIcon,
    text: "Airtime Topup",
    lingualText: "m_mobile_top_up",
    visibleTo: ["CASHIER", "INDIVIDUAL", "COMPANY"],
  },
  BILLPAYMENT: {
    id: 4,
    to: "/billpayment",
    icon: billIcon,
    text: "Bill Payment",
    lingualText: "WO_BILL_PAYMEN_-1233806888",
    visibleTo: ["CASHIER", "INDIVIDUAL", "COMPANY"],
  },
  CASHPICKUP: {
    id: 5,
    to: "/transfer",
    icon: cashIcon,
    text: "Cash Pickup",
    lingualText: "para_cash_pickup",
    visibleTo: ["CASHIER", "INDIVIDUAL", "COMPANY"],
  },
  MOBILEMONEY: {
    id: 6,
    to: "/transfer",
    icon: mobileIcon,
    text: "Mobile Money",
    lingualText: "MOBILE_MONE_1497679979",
    visibleTo: ["CASHIER", "INDIVIDUAL", "COMPANY"],
  },
  ACCOUNTPAYMENT: {
    id: 7,
    to: "/transfer",
    icon: transferIcon,
    text: "Transfer Funds",
    lingualText: "WO_TRANSFER_FUND_1805465128",
    visibleTo: ["CASHIER", "INDIVIDUAL", "COMPANY"],
  },
  INTERNATIONALREMITTANCE: {
    id: 8,
    to: "/cashier/payout-search",
    icon: transferIcon,
    text: "Payout",
    lingualText: "title_payout",
    visibleTo: ["CASHIER"]
  },
  REQUEST_BANK_ACCOUNT: {
    id: 9,
    to: "/bank-account/create",
    icon: surfaceIcon,
    text: "Request bank account",
    lingualText: "WO_REQUEST_BANK_ACCOUN_1842427382",
    visibleTo: ["INDIVIDUAL", "COMPANY"],
  },
  MERCHANTPAYMENTS: {
    id: 10,
    to: "/merchant-product",
    icon: merchantIcon,
    text: "Merchant Product",
    lingualText: "WO_MERCHANT_PRODUCT_1436670176",
    visibleTo: ["CASHIER", "INDIVIDUAL", "COMPANY"],
  },
  PAYROLL_MANAGEMENT: { id: 11, visibleTo: ["CASHIER", "INDIVIDUAL", "COMPANY"] },
  PREPAID_CARDS_SERVICES: { id: 12, visibleTo: ["CASHIER", "INDIVIDUAL", "COMPANY"] },
  PAYOUTBANKTRANSFER: { id: 13, visibleTo: ["CASHIER", "INDIVIDUAL", "COMPANY"] },
  AGENCY_BANKING_MANAGEMENT: { id: 14, visibleTo: ["CASHIER", "INDIVIDUAL", "COMPANY"] },
  SMS_MONEY_TRANSFER: { id: 15, visibleTo: ["CASHIER", "INDIVIDUAL", "COMPANY"] },
  SMS: { id: 16, visibleTo: ["CASHIER", "INDIVIDUAL", "COMPANY"] },
  SANCTION_LIST: { id: 17, visibleTo: ["CASHIER", "INDIVIDUAL", "COMPANY"] },
  VOUCHER_RECHARGE_CARDS_MANAGEMENT: {
    id: 18,
    visibleTo: ["CASHIER", "INDIVIDUAL", "COMPANY"],
  },
  BUREAU_DE_CHANGE: { id: 19, visibleTo: ["CASHIER", "INDIVIDUAL", "COMPANY"] },
  MULTI_LINGUAL_LANGUAGE_SUPPORT: {
    id: 20,
    visibleTo: ["CASHIER", "INDIVIDUAL", "COMPANY"],
  },
  E_DISBURSEMENT: { id: 21, visibleTo: ["CASHIER", "INDIVIDUAL", "COMPANY"] },
  LOAN: { id: 22, visibleTo: ["CASHIER", "INDIVIDUAL", "COMPANY"] },
  CRYPTO_CURRENCY: { id: 23, visibleTo: ["CASHIER", "INDIVIDUAL", "COMPANY"] },

  CURRENCY_CONVERTER: {
    id: 24,
    to: "/merchant-product",
    icon: transferIcon,
    text: "Currency Converter",
    lingualText: "WO_TRANSFER_FUND_1805465128",
    visibleTo: ["CASHIER", "COMPANY"],
  },

  FUND_ACCOUNT: {
    id: 25,
    to: "account/fund-account",
    icon: transferIcon,
    text: "Fund Account",
    lingualText: "l_fund_wallet",
    visibleTo: ["CASHIER", "INDIVIDUAL", "COMPANY"],
  },

  TRANSFER_MODES: {
    id: 26,
    to: "transfer-modes",
    icon: transferIcon,
    text: "Transfer Funds",
    lingualText: "WO_TRANSFER_FUND_1805465128",
    visibleTo: ["CASHIER", "INDIVIDUAL", "COMPANY"],
  },
};

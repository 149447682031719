import styled from "@emotion/styled";

export const Image = styled.div`
  height: 60px;
  width: 60px;
  margin: 6px 0;
  margin-left: 10px;
`;

export const Span = styled.span`
  display: block;
  margin-top: 24.5px;
  margin-bottom: 24.5px;
  // border-right: 2px solid #90a8be47;
  font-size: 15px;
  color: #0055ad;
  font-weight: 500;
  // padding-left: 20px;
  // padding-right: 20px;
  white-space: nowrap;
`;

export const Card = styled.div`
  border-radius: 15px;
  background-color: #e3edf36b;
}
`;

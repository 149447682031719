import { css } from '@emotion/react';

export const forgotContainer = css`
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  @media (min-width: 769px) {
    flex-direction: row;
  }
`;

export const leftSection = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 5vw;

  @media (min-width: 769px) {
    flex-basis: 50%;
    max-width: 50%;
    margin-bottom: 0;
  }
`;

export const leftContainer = css`
  padding: 40px 30px 0;
  display: flex;
  flex-direction: column;

  @media (max-width: 56.25rem) {
    padding: 0;
    margin-top: 1rem;
  }
`;

export const leftHeading = () => css`
  font-weight: 600;
  font-size: 40px;
  border-bottom: 0.5px solid #0fcc98;
  margin: 0;
  padding-bottom: 20px;
  color: var(--page-title-color);

  @media (max-width: 56.25rem) {
    font-size: 2rem;
    margin-top: 1rem;
  }
`;

export const leftFormLabel = css`
  display: flex;
  flex-direction: column;

  span {
    font-size: 25px;
    color: black;
    margin-bottom: 18px;
  }

  input {
    background: #e3edf3;
    border: 0;
    height: 80px;
    border-radius: 5px;
    font-size: 16px;
    // width: 400px;
    color: #0055ad;
    font-weight: 300;
    padding: 10px 20px;
  }
`;

export const leftFormButton = (theme) => css`
  display: block;
  background: ${theme.primaryColor};
  border: 0;
  height: 80px;
  margin-top: 20px;
  width: 100%;
  border-radius: 5px;
  color: #fafafa;
  font-weight: 700;
  font-size: 25px;
`;

export const leftLink = (theme) => css`
  color: ${theme.secondaryColor};
  margin: 20px auto;
  text-decoration: none;
`;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import customStyles from "Assets/styles/dropdown/customStyles";
import Navbar from "react-bootstrap/Navbar";
import Select from "react-select";
import styles from "./index.module.scss";
import cookieHelper from "Utils/Helpers/cookieHelper";
import config from "Utils/Helpers/config";
import * as DomainBrand from "Store/actions/domain-brand.action";
import DropDownComponent from "Components/DropDownComponent";
import downArrow from "Assets/svg/blue-drop-down-arrow.svg";
import { fetchAppLanguage, languageChangeHelper } from "Utils/Helpers/general";
import * as Loading from "Store/constants/loadingState.constants";

const NavBar = () => {
  const dispatch = useDispatch();
  let domainBrandStatus;

  let brandLangSelectorOptions, selectedLanguage = fetchAppLanguage();
  const [languageField, updateLanguageField] = useState("");

  const { domainBrand } = useSelector(({ domainBrandReducer }) => ({
    domainBrand: domainBrandReducer
  }));

  domainBrandStatus = domainBrand.loading === Loading.SUCCESS;

  brandLangSelectorOptions = domainBrand.loading === Loading.SUCCESS ? (
    domainBrand["domainBrand"]["language"].map((forEach, index) => {
      return (
        <option value={JSON.stringify(forEach)} key={index}>
          {forEach["commonName"]}
        </option>
      );
    })
  ) : (
    <option value={JSON.stringify({ code: "EN", commonName: "ENGLISH" })}>English</option>
  );

  let languageStatus = brandLangSelectorOptions.length > 0;

  const handleLanguageChange = (event) => {
    const languageHelper = languageChangeHelper(event);
    updateLanguageField(languageHelper["value"]);
    dispatch(DomainBrand.getDomainBrandIdentity(languageHelper["subdomain"], languageHelper["languageObject"]["id"]));
  };

  useEffect(() => {
    if (domainBrand.loading === "" || domainBrand.loading === null) {
      const selectedLanguage = fetchAppLanguage();
      const subdomain = cookieHelper.get(config.DOMAIN_DATA);
      dispatch(DomainBrand.getDomainBrandIdentity(subdomain, selectedLanguage["id"]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const langSelectorOptions = (
    <>
      <option>English</option>
    </>
  );

  return (
    <Navbar collapseOnSelect expand="lg" className={`${styles.navBar}`}>
      <div className="container-fluid">
        <Navbar.Brand>
          <Link to="/">
            <img
              src={domainBrandStatus ? domainBrand["domainBrand"]["logo"] : "MultiView MTA"}
              alt={domainBrandStatus ? domainBrand["domainBrand"]["companyName"] : "MultiView MTA"}
              className="img-fluid"
              style={{ width: "100px" }}
            />
          </Link>
        </Navbar.Brand>

        <span className="ml-auto" style={{ minWidth: "150px" }}>
          {languageStatus ? (
            <Select
              options={brandLangSelectorOptions}
              styles={customStyles}
              isClearable={false}
              isSearchable={true}
              name={languageField}
              value={languageField}
              customTheme={""}
              placeholder="ENGLISH"
              onChange={(option) => {
                handleLanguageChange(option);
              }}
              components={{
                IndicatorSeparator: null,
                DropdownIndicator: () => <DropDownComponent iconSource={downArrow} />
              }}
            />
          ) : (
            <select>{langSelectorOptions}</select>
          )}
        </span>
      </div>
    </Navbar>
  );
};

export default NavBar;

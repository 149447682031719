// eslint-disable-next-line no-unused-vars
import React, { useEffect } from "react";
/** @jsx jsx */
/** @jsxRuntime classic */

import { jsx, css } from "@emotion/react";
import { Helmet } from "react-helmet";
import { Switch, useRouteMatch, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import NavBar from "Components/Navbar";
import Footer from "Components/Footer";
import { Container, Ellipse } from "Components/Styled";
import HeadingText from "Components/HeadingText";
import CustomLink from "./atom/CustomLink";
import EditProfile from "./atom/EditProfile";
import ViewNotifications from "./atom/Notifications/View";
import { InnerContainer, Main } from "./profileStyles";
import * as StyleSheet from "./profileStyles";
import offSetImg from "Assets/svg/man-user-1.svg";
import { ReactComponent as EditIcon } from "Assets/svg/edit-blue.svg";
import { ReactComponent as ViewIcon } from "Assets/svg/search.svg";
import { ReactComponent as NotifyIcon } from "Assets/svg/notification.svg";
import { ReactComponent as LogOutIcon } from "Assets/svg/logout.svg";
import { ReactComponent as CartIcon } from "Assets/svg/merchant-product.svg";
import avatar from "Assets/images/gravatar.png";
import { getProfile } from "Store/actions/profile.action";
import CreateNotifications from "./atom/Notifications/Create";
import OrderDetails from "./atom/OrderDetails";
import OrderHistory from "./atom/OrderHistory";
import { OffsetIcon } from "Components/Styled";
import { ImageElement } from "Components/Styled";
import { logUserOut } from "Store/actions/auth.action";
import * as Loading from "Store/constants/loadingState.constants";
import ProfileButton from "./atom/ChangeProfilePicture";
import cookieHelper from "../../../Utils/Helpers/cookieHelper";
import config from "../../../Utils/Helpers/config";
import { TokenHelpers } from "../../../Utils/Helpers";
import Error404 from "../../Public/404";
import BackArrow from "Components/BackArrow";
import ReadMessage from "./atom/Notifications/Read";

const Profile = () => {
  const { path } = useRouteMatch();
  const dispatch = useDispatch();

  const { user: { userData, userProfileLoading }, domainBrand } = useSelector((
    { profileReducer, domainBrandReducer }) => ({
    user: profileReducer,
    domainBrand: domainBrandReducer
  }));

  const languageDictionary = domainBrand.domainBrand["languageDictionary"];
  const languageDictionaryStatus = !(typeof languageDictionary === "undefined" || languageDictionary === null);
  const checkMerchantPaymentStatus = cookieHelper.get(config.MERCHANT_PAYMENT_STATUS);

  const MerchantRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        TokenHelpers.checkIfLoggedIn() && checkMerchantPaymentStatus !== "ENABLED" ? <Component {...props} /> : <Error404 />
      }
    />
  );

  let profileImage = typeof userData.profileImage === "undefined" || userData.profileImage === null ? avatar : userData.profileImage.replace("?type=profile", "");

  useEffect(() => {
    if (userProfileLoading === Loading.ERROR) dispatch(getProfile());
  }, [dispatch]);

  return (
    <Container>
      <Helmet>
        <title>
          {languageDictionaryStatus ? languageDictionary["WO_USER_PROFIL_548513751"] : "User profile"}
        </title>
      </Helmet>
      <NavBar />
      <InnerContainer>
        <OffsetIcon>
          <ImageElement src={offSetImg} title="offset icon" />
        </OffsetIcon>
        <Main className="mt-5">
          <div className="d-flex">
            <BackArrow />
            <HeadingText text={languageDictionaryStatus ? languageDictionary["WO_USER_PROFIL_548513751"] : "User profile"} />
          </div>
          <div css={StyleSheet.sectionContainer}>
            <section css={StyleSheet.leftSection}>
              <div css={StyleSheet.profileBox}>
                <div css={StyleSheet.profilePicture}>
                  <div css={StyleSheet.profilePictureInner}>
                    <div css={StyleSheet.profilePictureMain}>
                      <ImageElement title="current user" src={profileImage} />
                    </div>
                  </div>
                </div>

                <div css={StyleSheet.profileBoxContainer}>
                  <Ellipse css={StyleSheet.leftEllipse} />
                  <Ellipse css={StyleSheet.rightEllipse} />
                  <p css={StyleSheet.userNameStyle}>
                    {`${userData.firstName || ""} ${userData.lastName || ""}`}
                  </p>
                  <p css={StyleSheet.emailStyle}>{userData.email}</p>
                </div>
              </div>

              <div>
                <CustomLink
                  to="/profile/change-profile-picture"
                  activeOnlyWhenExact
                  label="Upload Profile Picture"
                  SvgIcon={EditIcon}
                />

                <CustomLink
                  to="/profile"
                  activeOnlyWhenExact
                  label={languageDictionaryStatus ? languageDictionary["WO_VIEW_PROFIL_1113374480"] : "View Profile"}
                  SvgIcon={ViewIcon}
                />

                {checkMerchantPaymentStatus === "ENABLED" ?
                  <CustomLink
                    to="/profile/order-history"
                    activeOnlyWhenExact
                    label="Order History"
                    SvgIcon={CartIcon}
                  /> : ""
                }

                <CustomLink
                  to="/profile/notifications"
                  label={languageDictionaryStatus ? languageDictionary["WO_CONTACT_SUPPOR_-1552316922"] : "Contact Support"}
                  SvgIcon={NotifyIcon}
                />
                <button css={StyleSheet.logOutButton} onClick={() => dispatch(logUserOut())}>
                  <span css={css`width: 8%;
                    margin-left: 8%;
                    margin-right: 17%;`}>
                    <LogOutIcon height="100%" width="100%" />
                  </span>
                  <span>
                    {languageDictionaryStatus ? languageDictionary["button_logout"] : "Log Out"}
                  </span>
                </button>
              </div>
            </section>

            <section css={StyleSheet.rightSection}>
              <Switch>
                <Route exact path={path}>
                  <EditProfile />
                </Route>
                <Route exact path={`${path}/change-profile-picture`}>
                  <ProfileButton />
                </Route>
                <Route exact path={`${path}/order-history`}>
                  <OrderHistory />
                </Route>
                <MerchantRoute path={`${path}/order-details`}>
                  <OrderDetails />
                </MerchantRoute>
                <Route exact path={`${path}/notifications`}>
                  <ViewNotifications />
                </Route>
                <Route exact path={`${path}/notifications/compose`}>
                  <CreateNotifications />
                </Route>
                <Route exact path={`${path}/notifications/read`}>
                  <ReadMessage />
                </Route>
              </Switch>
            </section>
          </div>
        </Main>
      </InnerContainer>
      <Footer />
    </Container>
  );
};

export default Profile;

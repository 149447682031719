// eslint-disable-next-line no-unused-vars
import React from "react";
/** @jsx jsx */
/** @jsxRuntime classic */
import { jsx } from "@emotion/react";

import { transferContainer, emailColumn, noBorderColumn, fullWidthContainer } from "./bedStyle";
import Column from "../TransactionInfoSection/Column";
import { Row, RowContent, RowHeading } from "../TransactionInfoSection/style";

const TransferColumn = ({ data }) => {
  const bankDetails = data.recipient_bank_details || {};
  const { AccountHolderName, BankName, AccountNumber, SWIFT } = bankDetails;

  return (
    <div css={transferContainer}>
      <RowHeading>Account Holder name:</RowHeading>
      <RowContent>{AccountHolderName}</RowContent>
      <RowHeading>Bank Name:</RowHeading>
      <RowContent>{BankName}</RowContent>
      <Row noMargin>
        <div css={noBorderColumn}>
          <RowHeading>Swift Code:</RowHeading>
          <RowContent>{SWIFT}</RowContent>
        </div>
      </Row>
      <RowHeading>Account Number:</RowHeading>
      <RowContent>{AccountNumber}</RowContent>
    </div>
  );
};

const EmailColumn = ({ heading, content }) => {
  return (
    <div css={emailColumn}>
      <RowHeading>{heading}</RowHeading>
      <RowContent>{content}</RowContent>
    </div>
  );
};

const BeneficiaryDetails = ({ data }) => {
  const benDetails = data.receiver || {};
  const { first_name, last_name, email, code, phone } = benDetails;

  return (
    <div css={fullWidthContainer}>
      <Row>
        <Column heading="Beneficiary Code:" content={code} />
        <Column heading="Beneficiary Address:" content="" />
      </Row>
      <Row>
        <Column
          heading="Beneficiary Name:"
          content={`${first_name || "---"} ${last_name || "---"}`}
        />
        <Column heading="Beneficiary Phone:" content={phone} />
      </Row>
      <Row>
        <TransferColumn data={data} />
        <EmailColumn css={emailColumn} heading="Beneficiary Email Address:" content={email} />
      </Row>
    </div>
  );
};

export default BeneficiaryDetails;

import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useFormik } from "formik";
import * as Yup from "yup";

import HeadingText from "Components/HeadingText";
import CustomInput from "Components/CustomInput";
import CustomButton from "Components/CustomButton";
import { CardContainer, FormContainer } from "./styled";
import { useDispatch, useSelector } from "react-redux";
import { lookupAccountInfo } from "Store/actions/transfer.action";
import * as Loading from "Store/constants/loadingState.constants";
import { useHistory, useLocation } from "react-router-dom";
import navigateMethod from "Utils/Helpers/navigateMethod";
import BackArrow from "Components/BackArrow";

const AccountToAccount = () => {
  const dispatch = useDispatch(), history = useHistory(), location = useLocation();
  const [accountInfoLookupStatus, profile, domainBrand] = useSelector((
    {
      transferReducer,
      profileReducer,
      domainBrandReducer
    }) => [transferReducer, profileReducer, domainBrandReducer]);

  const [senderDetailsForCashier, updateSenderDetailsForCashier] = useState({});
  const languageDictionary = domainBrand.domainBrand["languageDictionary"];
  const languageDictionaryStatus = !(typeof languageDictionary === "undefined" || languageDictionary === null);

  const formik = useFormik({
    initialValues: { accountNumber: "" },
    validationSchema: Yup.object({
      accountNumber: Yup.string().required("required")
    }),
    onSubmit: (values) => {
      const navigator = navigateMethod(history, "/transfer/account-to-account/details");
      const payload = { findbyAccountNumber: values.accountNumber };
      dispatch(lookupAccountInfo(payload, senderDetailsForCashier, navigator));
    }
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  useEffect(() => {
    if ("user" in history.location.state) updateSenderDetailsForCashier(location.state.user);
    else {
      if (profile["userData"]["userType"] === "CASHIER") history.push({
        pathname: "/get-sender",
        state: { prevLocation: history.location.pathname }
      });
    }
  });

  return (
    <>
      <Helmet>
        <title>
          {languageDictionaryStatus ? languageDictionary["WO_ACCOUNT_TO_ACCOUNT_T_-364487608"] : "Account to Account Transfer"}
        </title>
      </Helmet>
      <div className="d-flex">
        <BackArrow />
        <HeadingText
          text={languageDictionaryStatus ? languageDictionary["WO_ACCOUNT_TO_ACCOUNT_T_-364487608"] : "Account to Account Transfer"}
        />
      </div>
      <CardContainer>
        <HeadingText
          text={languageDictionaryStatus ? languageDictionary["FIND_A_BENEFICIAR_-1943678036"] : "Find Beneficiary"}
          fontSize="20px"
        />

        <FormContainer onSubmit={formik.handleSubmit}>
          <CustomInput
            formik={formik}
            inputKey="accountNumber"
            inputSize="100"
            labelName="Enter beneficiary account number"
            required
          />
          <CustomButton
            buttonText={
              languageDictionaryStatus ? languageDictionary["FIND_ACCOUN_31978564"] : "FIND ACCOUNT"
            }
            buttonWidth="100%"
            marginTop="0"
            fontSize="13px"
            loading={accountInfoLookupStatus === Loading.FETCHING}
          />
        </FormContainer>
      </CardContainer>
    </>
  );
};

export default AccountToAccount;

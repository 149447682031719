import Axios from "./Axios.service";

export default class AuthService {
  static async login(payload) {
    const requestData = {
      email: payload.email,
      currentPassword: payload.password,
      deviceManufacturer: "android",
      model: "android",
      version: "1",
      brand: "samsung",
      userAgent: "124",
      socialId: "",
      socialMediaAccessToken: "",
      appVersion: "1",
      appId: "1"
    };
    return Axios({
      method: "PUT",
      url: "/web-services/api/v6/services/securitymanagement/login",
      data: requestData,
      headers: {
        platform: "fincode",
        UUID: 200
      }
    });
  }

  static async forgotPassword({ email }) {
    return Axios({
      method: "GET",
      url: `/web-services/api/v6/services/securitymanagement/reset-password/${email}`,
      headers: {
        platform: "fincode",
        UUID: 200
      }
    });
  }

  static async otpVerification(payload) {
    return Axios({
      method: "POST",
      data: payload,
      url: `/web-services/api/v6/services/securitymanagement/verify-login-otp`,
      headers: {
        platform: "fincode",
        UUID: 200
      }
    });
  }

  static async resendOtp() {
    return Axios({
      method: "POST",
      url: `/web-services/api/v6/services/securitymanagement/resend-login-otp`,
      headers: {
        platform: "fincode",
        UUID: 200
      }
    });
  }


  static async logout() {
    return Axios({
      method: "POST",
      url: "/web-services/api/v6/services/securitymanagement/logout",
      headers: {
        platform: "fincode",
        UUID: 200
      }
    });
  }
}

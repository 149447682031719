// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
/** @jsx jsx */
/** @jsxRuntime classic */

import { jsx, css } from "@emotion/react";
import AriaModal from "react-aria-modal";
import { useLocation, Redirect, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Footer from "Components/Footer";
import NavBar from "Components/Navbar";

import SubHeadingText from "Components/SubHeadingText";
import HeadingText from "Components/HeadingText";
import CheckBox from "Components/CheckBox";
import CheckBoxContent from "Components/CheckBoxContent/termsAndConditions";
import CustomButton from "Components/CustomButton";
import Spinner from "Components/Spinner";

import CardPayment from "./atom/CardPayment";
import BankTransfer from "./atom/BankTransfer";
import Wallet from "./atom/Wallet";
import ModalContent from "./atom/ModalContent";
import {
  leftSectionWrapper,
  rightSectionWrapper,
  bodyWrapper,
  buttonStyle,
  textWrapperStyle,
  AmountDetailWrapper,
  Separator,
  CircleCheckWrapper,
  CheckMark
} from "./style";
import { fetchPaymentMethods } from "Store/actions/transfer.action";
import * as Loading from "Store/constants/loadingState.constants";

const PaymentLabels = {
  ONLINE_CARD_PAYMENT: "Card Payment",
  BANK_TRANSFER: "Bank Transfer",
  E_WALLET: "Wallet"
};

const UserPayment = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    cart: { paymentInfo },
    transfer: { paymentMethodStatus, paymentMethod, paymentStatus }
  } = useSelector(({ cartReducer, transferReducer }) => ({
    cart: cartReducer,
    transfer: transferReducer
  }));

  const [activeMethod, updateActiveMethod] = useState({});
  const [checkBoxValue, updateCheckBoxValue] = useState(false);
  const [showModal, updateShowModal] = useState(false);
  const getApplicationNode = () => {
    return document.getElementById("root");
  };

  useEffect(() => {
    if (paymentInfo) {
      const {
        currency,
        totalCost,
        pcn,
        payableType
      } = paymentInfo;
      const payload = {
        pcn,
        currency,
        amount: totalCost,
        payableType,
        countryIsoCode3: "",
        transactionType: "ACCOUNTPAYMENT"
      };
      dispatch(fetchPaymentMethods(payload));
    }
  }, [paymentInfo]);

  useEffect(() => {

    if (
      paymentMethodStatus === Loading.SUCCESS &&
      paymentMethod.paymentMethodsMetadata &&
      paymentMethod.paymentMethodsMetadata.length > 0
    ) {
      const { paymentMethodsMetadata } = paymentMethod;
      updateActiveMethod(paymentMethodsMetadata[0]);
    }
  }, [paymentMethodStatus]);

  const makeCardPayment = () => {
    const domainUrl = "http://cath.themoneytransferapplication.com";
    const originUrl = window.location.origin;
    const redirectUrl = `&callbackurl=${originUrl}/order-confirmation`;
    const finalLink = activeMethod.hosted_gateway_url;
    const paymentUrl = `${domainUrl}${finalLink}${redirectUrl}`;

    window.location.href = paymentUrl;
  };

  const PaymentComponents = (dataObject) => ({
    ONLINE_CARD_PAYMENT: (
      <CardPayment dataObject={dataObject} submitAction={makeCardPayment} />
    ),
    BANK_TRANSFER: <BankTransfer dataObject={dataObject} />,
    E_WALLET: <Wallet dataObject={dataObject} />
  });

  const buttonMethod = {
    E_WALLET: () => {
    },
    BANK_TRANSFER: () => {
    }
  };

  const LabelButton = ({ paymentMethod }) => {
    const { type } = paymentMethod;
    return (
      <button
        css={buttonStyle(activeMethod.type === type)}
        onClick={() => updateActiveMethod(paymentMethod)}
      >
        {PaymentLabels[type]}
        <CircleCheckWrapper active={activeMethod.type === type}>
          <CheckMark />
        </CircleCheckWrapper>
      </button>
    );
  };

  const { currency } = paymentMethod;
  const sendingAmount = paymentInfo ? paymentInfo.totalCost : null;

  return (
    <React.Fragment>
      <Helmet>
        <title>Payment Details &mdash; Multiview MTA</title>
      </Helmet>
      <NavBar />
      <div className="container">
        <div className="mt-5 mb-5">
          <HeadingText text="Payment Details" />
          <div css={bodyWrapper}>
            {paymentStatus === Loading.FETCHING ||
            paymentMethodStatus === Loading.FETCHING ? (
              <Spinner containerWidth="100%" containerHeight="50vh" />
            ) : (
              <React.Fragment>
                <section css={leftSectionWrapper}>
                  <SubHeadingText text="Choose payment method" />

                  <div
                    css={css`
                      display: flex;
                      /* justify-content: space-between; */
                      margin-top: 20px;
                    `}
                  >
                    {paymentMethod.paymentMethodsMetadata
                      ? paymentMethod.paymentMethodsMetadata.map((method) => (
                        <LabelButton
                          paymentMethod={method}
                          key={method.type}
                        />
                      ))
                      : null}
                  </div>

                  {
                    PaymentComponents({ ...activeMethod, currency })[
                      activeMethod.type
                      ]
                  }
                </section>
                <section css={rightSectionWrapper}>
                  <SubHeadingText text="Payment" />

                  <AmountDetailWrapper>
                    <div css={textWrapperStyle}>
                      <p>Sending Amount:</p>
                      <p>
                        {sendingAmount && Number(sendingAmount).toFixed(2)}{" "}
                        {currency}
                      </p>
                    </div>
                    <div css={textWrapperStyle}>
                      <p>Charges:</p>
                      <p>0.00 {currency}</p>
                    </div>
                    <div css={textWrapperStyle}>
                      <p>Payment Fee:</p>
                      <p>
                        {activeMethod["payment-method-fee"] &&

                        Number(activeMethod["payment-method-fee"]).toFixed(
                          2
                        )}{" "}

                        {currency}
                      </p>

                    </div>

                    <Separator />
                    <div css={textWrapperStyle}>
                      <p>Total to Pay</p>
                      <p>
                        {activeMethod["total-amount"] &&
                        Number(activeMethod["total-amount"]).toFixed(2)}{" "}
                        {currency}
                      </p>
                    </div>
                    <Separator />
                    <div css={textWrapperStyle}>
                      <p>Your beneficiary receives:</p>
                      <p>
                        {sendingAmount && Number(sendingAmount).toFixed(2)}{" "}
                        {currency}
                      </p>
                    </div>
                  </AmountDetailWrapper>

                  {activeMethod.type === "ONLINE_CARD_PAYMENT" ? null : (
                    <div
                      css={css`
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        margin-top: 2rem;
                      `}
                    >
                      <div>
                        <CheckBox
                          checkboxName="tAndC"
                          checkboxId="GeneralTerms"
                          checkboxValue={checkBoxValue}
                          checkboxOnChange={(e) =>
                            updateCheckBoxValue(e.target.checked)
                          }
                          content={
                            <CheckBoxContent checkboxId="GeneralTerms" />
                          }
                        />
                      </div>
                      <div
                        css={css`
                          width: 60%;
                        `}
                      >
                        <CustomButton
                          buttonText="Click here to continue"
                          buttonWidth="100%"
                          disabled={!checkBoxValue}
                          onClick={buttonMethod[activeMethod.type]}
                        />
                      </div>
                    </div>
                  )}
                </section>
                {showModal ? (
                  <AriaModal
                    titleText="transfer details"
                    onExit={() => updateShowModal(false)}
                    getApplicationNode={getApplicationNode}
                    verticallyCenter={true}
                    underlayStyle={{
                      backdropFilter: "blur(28px)",
                      background: "none"
                    }}
                  >
                    <ModalContent
                      closeModal={() => {
                        updateShowModal(false);
                        history.push("/transfer/details");
                      }}
                    />
                  </AriaModal>
                ) : null}
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default UserPayment;

// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
/** @jsx jsx */
/** @jsxRuntime classic */
import { jsx } from "@emotion/react";
import Select from "react-select";

import FormLabel from "Components/FormLabel";
import DropDownComponent from "Components/DropDownComponent";
import FlexibleButton from "Components/FlexibleButton";
import ErrorField from "Components/ErrorField";
import downArrow from "Assets/svg/blue-drop-down-arrow.svg";
import customStyles from "Assets/styles/dropdown/customStyles";
import {
  TransContainer,
  FormRow,
  selectWrapper,
  selectDataWrapper,
  buttonWrapper,
  singleRowItem
} from "./style";
import CreateCategory from "../Create";
import { useDispatch } from "react-redux";
import ButtonSpinner from "Components/ButtonSpinner";
import * as Loading from "Store/constants/loadingState.constants";
import {
  fetchRequiredBankFields,
  fetchRequiredBankFieldsByFilter,
  fetchRequiredDocumentsForTransaction,
  getQuoteData
} from "Store/actions/transfer.action";
import {
  fetchCurrenciesByCountries,
  fetchReceivingCurrenciesByCountries
} from "Store/actions/country.action";

import Styles from "../../../../../index.scss";
import { numberInputField } from "../stylesheet";
import BeneficiaryService from "../../../../../Utils/Services/Beneficiary.service";
import { toast } from "react-toastify";
import { bankPairs } from "../../../../../Utils/constants";

const TransDetails = ({
  pageStatus,
  userRole,
  originCountry,
  cashier,
  transactionType,
  chargeCategory,
  formik,
  beneficiaries,
  countries,
  getRecipientBankDetails,
  transfers,
  history,
  bankDetailsSelect,
  updateBankDetailsSelect,
  profile,
  theme,
  languageDictionary,
  currencies
}) => {
  const dispatch = useDispatch();
  const { beneficiaryBankInfo } = transfers;
  const {
    userData: { customerType, enableVamProfile }
  } = profile;
  const languageDictionaryStatus = !(
    typeof languageDictionary === "undefined" || languageDictionary === null
  );
  const fetchingProfile = profile.userProfileLoading === "FETCHING";
  const [beneficiarySearchName, setBeneficiarySearchName] = useState("");
  const [sendingAmount, setSendingAmount] = useState("");
  const [receivingAmount, setReceivingAmount] = useState("");
  const [readyToCallQuote, setReadyToCallQuote] = useState(false);
  const [inverseCalculation, setInverseCalculation] = useState(false);
  const [quoteType, setQuoteType] = useState("");
  const requiredDocumentRules = transfers["requiredDocuments"];
  const requiredDocumentStatus = "others" in requiredDocumentRules;

  const purposeOfTransferMultiLingual = {
    INDIVIDUAL: [
      {
        value: "FAMILY_SUPPORT",
        label: languageDictionaryStatus ? languageDictionary["l_family_support"] : "Family Support"
      },
      {
        value: "BUILDING_PROJECT",
        label: languageDictionaryStatus
          ? languageDictionary["l_building_project"]
          : "Building Project"
      },
      {
        value: "EDUCATIONAL_EXPENSES",
        label: languageDictionaryStatus
          ? languageDictionary["l_educational_expenses"]
          : "Educational Expenses"
      },
      {
        value: "PAYBACK",
        label: languageDictionaryStatus ? languageDictionary["l_payback"] : "Payback"
      },
      { value: "SAVINGS", label: "Savings" },
      { value: "GIFT", label: "Gift" },
      { value: "CHARITABLE_DONATION", label: "Charitable Donations" },
      { value: "GOODS_AND_SERVICES", label: "Goods and services" },
      { value: "OTHER", label: languageDictionaryStatus ? languageDictionary["l_other"] : "Other" }
    ],
    COMPANY: [
      {
        label: "Merchandise transaction/Goods purchase",
        value: "MERCHANDISE_TRANSACTION/GODS_PURCHASE"
      },
      {
        label: "Services transaction/ services purchase",
        value: "SERVICES_TRANSACTION/SERVICES_PURCHASE"
      },
      { label: "Credit payment", value: "CARD_PAYMENT" },
      { label: "Investment", value: "INVESTMENT" },
      { label: "Regulatory Payment (e.g., penalty, fine)", value: "REGULATORY_PAYMENT" },
      { label: "Grants/donations", value: "GRANTS/DONATIONS" },
      { label: "Banking", value: "BANKING" },
      { label: "Business expenses", value: "BUSINESS_EXPENSES" },
      { label: "Royalty Payment", value: "ROYALTY_PAYMENT" },
      { label: "Dividend Payment", value: "DIVIDEND_PAYMENT" },
      { label: "Loan payment", value: "LOAN_PAYMENT" },
      { label: "Real estate/Rent", value: "REAL_ESTATE/RENT" },
      { label: "Tax Payment", value: "TAX_PAYMENT" },
      { label: "Others not listed", value: "OTHERS" }
    ]
  };

  const sourceOfFundsMultiLingual = {
    INDIVIDUAL: [
      { value: "BORROW_LOAN", label: "Borrowed Funds/Loan" },
      { value: "CHARITABLE_DONATION", label: "Charitable Donations" },
      { value: "GIFT", label: "Gift" },
      { value: "PENSION_GOVERNMENT_WELFARE", label: "Pensions/Government/Welfare" },
      { value: "INHERITANCE", label: "Inheritance" },
      { value: "MEDICAL_AID", label: "Medical aid" },
      { value: "SALARY_INCOME", label: "Salary/Income" },
      { value: "SAVINGS", label: "Savings" },
      { value: "OTHER", label: "Other" }
    ],
    COMPANY: [
      { value: "COMPANY_EARNINGS", label: "Company Earnings (this company)" },
      { value: "COMPANY_EARNINGS", label: "Company Earnings (related company)" },
      { value: "CREDIT", label: "Credit" },
      { value: "DIVIDENDS_OR_PROFITS_FROM_COMPANY", label: "Dividends or Profits from Company" },
      { value: "INVESTMENT_INCOME", label: "Investment Income" },
      { value: "LOAN", label: "Loan" },
      { value: "SALE_OF_ASSETS", label: "Sale of Assets" },
      { value: "PERSONAL_SAVINGS", label: "Personal Savings" },
      {
        value: "VENTURE_CAPITAL/ANGEL_INVESTOR/CROWDFUNDING",
        label: "Venture Capital / Angel Investor / Crowdfunding"
      },
      { value: "GRANT", label: "Grant" },
      { value: "OTHER", label: "Others not listed" }
    ]
  };

  let purposeOfTransfer,
    sourceOfFunds,
    renderBeforeCurrencyValidation,
    renderAfterSendingCurrencyValidation,
    renderAfterCurrencyValidation;
  customerType === "INDIVIDUAL"
    ? (purposeOfTransfer = purposeOfTransferMultiLingual["INDIVIDUAL"])
    : (purposeOfTransfer = purposeOfTransferMultiLingual["COMPANY"]);
  customerType === "INDIVIDUAL"
    ? (sourceOfFunds = sourceOfFundsMultiLingual["INDIVIDUAL"])
    : (sourceOfFunds = sourceOfFundsMultiLingual["COMPANY"]);

  let callQuoteData = {
    chargeCategory,
    transactionType,
    paymentType: "ONLINE_CARD_PAYMENT",
    conversion: true,
    deriveAmountCurrency: "GBP"
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (currencies["supportedCurrenciesByCountries"].length > 0) {
      formik.setFieldValue("sendingCurrency", currencies["supportedCurrenciesByCountries"][0]);
    }
  }, [currencies["supportedCurrenciesByCountries"]]);

  useEffect(() => {
    if (currencies["supportedReceivingCurrenciesByCountries"].length > 0) {
      formik.setFieldValue(
        "receivingCurrency",
        currencies["supportedReceivingCurrenciesByCountries"][0]
      );
    }
  }, [currencies["supportedReceivingCurrenciesByCountries"]]);

  const searchBeneficiary = async () => {
    const searchParams = `?customer-code=${cashier["code"]}&search-criteria=${beneficiarySearchName}`;
    const { data: responseData } = await BeneficiaryService.findBeneficiaryBySearchCriteria(
      searchParams
    );
    if (responseData.data["receivers"].length < 1)
      toast.error(`No beneficiary exists for ${beneficiarySearchName}`, {
        position: toast.POSITION.TOP_CENTER
      });
    else {
      const beneficiaryObject = responseData.data["receivers"][0];
      beneficiaryObject.value = responseData.data["receivers"][0]["beneficiaryCode"];
      beneficiaryObject.label = `${responseData.data["receivers"][0]["firstName"]} ${responseData.data["receivers"][0]["lastName"]}`;
      getRecipientBankDetails(beneficiaryObject);
      formik.setFieldValue("beneficiary", beneficiaryObject);
    }
  };

  callQuoteData.principalAmountCurrency = formik.values["sendingCurrency"]["value"];
  callQuoteData.deriveAmountCurrency = formik.values["receivingCurrency"]["value"];
  callQuoteData.destinationCountry = formik.values["receivingCountry"]["value"];
  fetchingProfile
    ? (callQuoteData.tradeOriginatingCountry = "GBR")
    : (callQuoteData.tradeOriginatingCountry = originCountry);

  const getRequiredFields = (option) =>
    dispatch(fetchRequiredBankFields({ countryCode: option.value }));
  const getRequiredFieldsWithCurrency = (option) =>
    dispatch(
      fetchRequiredBankFieldsByFilter(
        formik.values.receivingCountry.value,
        transactionType,
        option.value
      )
    );

  const handleKeyUp = () => {
    setReadyToCallQuote(false);
    const timeoutId = setTimeout(() => {
      setReadyToCallQuote(true);
    }, 2000);
    return () => clearTimeout(timeoutId);
  };

  useEffect(() => {
    callQuoteData.inverseCalculation = inverseCalculation;
    quoteType === "sendingAmount"
      ? (callQuoteData.principalAmount = sendingAmount)
      : quoteType === "receivingAmount"
      ? (callQuoteData.deriveAmount = receivingAmount)
      : (callQuoteData.principalAmount = null);
    if (readyToCallQuote) {
      getRequiredDocumentsForTransactions(sendingAmount);
      dispatch(getQuoteData(callQuoteData));
    }
  }, [readyToCallQuote]);

  const onChangeReceivingCurrency = () => {
    if (
      sendingAmount !== "" ||
      formik.values.sendingAmount !== "" ||
      receivingAmount !== "0.00" ||
      formik.values.receivingAmount !== ""
    ) {
      const timeoutId = setTimeout(() => {
        callQuoteData.principalAmount = formik.values.sendingAmount;
        callQuoteData.inverseCalculation = false;
        getRequiredDocumentsForTransactions(sendingAmount);
        dispatch(getQuoteData(callQuoteData));
      }, 2000);
      return () => clearTimeout(timeoutId);
    }
  };

  const getRequiredDocumentsForTransactions = (sendingAmount) => {
    const payload = {
      direction: "PAYIN",
      senderCode: formik.values.beneficiary.beneficiaryCode,
      receivingCountryIso3Code: formik.values.receivingCountry["iso3Code"],
      sendingCurrencyCode: formik.values["sendingCurrency"]["value"],
      devliveryMethod: transactionType,
      amountSend: parseFloat(sendingAmount)
    };
    dispatch(fetchRequiredDocumentsForTransaction(payload));
  };

  useEffect(() => {
    setSendingAmount(transfers.quoteData.sendingAmount);
    setReceivingAmount(transfers.quoteData.receivingAmount);
    formik.setFieldValue("sendingAmount", transfers.quoteData.sendingAmount);
    formik.setFieldValue("receivingAmount", transfers.quoteData.receivingAmount);
  }, [transfers.quoteData.sendingAmount, transfers.quoteData.receivingAmount]);

  let loadingData = currencies.loading === Loading.FETCHING;

  const fetchSupportedSendingCurrencies = (option) => {
    const destinationCountry = option["iso3Code"];
    dispatch(fetchCurrenciesByCountries(originCountry, destinationCountry, transactionType));
  };

  const fetchSupportedReceivingCurrencies = (option) => {
    const sendingCurrencyParameter = option["value"];
    const sendingCountry = formik.values["receivingCountry"]["iso3Code"];
    dispatch(
      fetchReceivingCurrenciesByCountries(
        originCountry,
        sendingCurrencyParameter,
        sendingCountry,
        transactionType
      )
    );
  };

  const setBankFields = (option) => {
    let formikMap;
    const requiredBankFields = formik.values["requiredBankFields"];
    const validOptions = Object.keys(requiredBankFields || {})
      .filter((value) => requiredBankFields[value] === true)
      .map((value) => value.split("Required")[0]);
    validOptions.forEach(
      (element) => (formikMap = { ...formikMap, ...{ [bankPairs[element]]: option[element] } })
    );
    formik.setValues({ ...formik.values, ...formikMap });
  };

  renderBeforeCurrencyValidation =
    formik.values["beneficiary"] === "" ||
    formik.values["beneficiary"] === null ||
    formik.values["receivingCountry"] === "" ||
    formik.values["receivingCountry"] === null;

  renderAfterSendingCurrencyValidation =
    loadingData ||
    formik.values["beneficiary"] === "" ||
    formik.values["beneficiary"] === null ||
    formik.values["receivingCountry"] === "" ||
    formik.values["receivingCountry"] === null ||
    formik.values["sendingCurrency"] === "" ||
    formik.values["sendingCurrency"] === null;

  renderAfterCurrencyValidation =
    loadingData ||
    formik.values["beneficiary"] === "" ||
    formik.values["beneficiary"] === null ||
    formik.values["receivingCountry"] === "" ||
    formik.values["receivingCountry"] === null ||
    formik.values["sendingCurrency"] === "" ||
    formik.values["sendingCurrency"] === null ||
    formik.values["receivingCurrency"] === "" ||
    formik.values["receivingCurrency"] === null;

  const amountFieldStatus =
    formik.values.sendingCurrency === "" ||
    formik.values.receivingCurrency === "" ||
    formik.values.sendingCurrency === null ||
    formik.values.receivingCurrency === null;

  const amountFieldTooltip =
    formik.values.sendingCurrency === "" ||
    formik.values.receivingCurrency === "" ||
    formik.values.sendingCurrency === null ||
    formik.values.receivingCurrency === null
      ? "Select sending currency and receiving currency"
      : "";

  return (
    <React.Fragment>
      <Helmet>
        <title>{pageStatus}</title>
      </Helmet>

      <div css={TransContainer}>
        {userRole === "CASHIER" ? (
          <div css={FormRow}>
            <div css={selectDataWrapper}>
              <FormLabel
                text={
                  languageDictionaryStatus
                    ? languageDictionary["para_find_beneficiary"]
                    : "Find Beneficiary"
                }>
                <input
                  type={"text"}
                  name="beneficiarySearchName"
                  value={beneficiarySearchName}
                  onChange={(e) => setBeneficiarySearchName(e.target.value)}
                  css={numberInputField}
                  className={Styles.numberInputField}
                  placeholder={
                    languageDictionaryStatus
                      ? languageDictionary["l_receiver_name"]
                      : "Beneficiary name"
                  }
                />
              </FormLabel>
            </div>
            <div css={buttonWrapper}>
              <FlexibleButton
                buttonText={languageDictionaryStatus ? languageDictionary["b_search"] : "Search"}
                buttonWidth="100%"
                onClick={searchBeneficiary}
                height="50px"
                paddingVertical="0.3rem"
                paddingHorizontal="0.3rem"
                fontSize="14px"
              />
            </div>
          </div>
        ) : (
          <div css={FormRow}>
            <div css={selectDataWrapper}>
              <FormLabel
                text={
                  languageDictionaryStatus
                    ? languageDictionary["WO_WHOM_WILL_YOU_LIKE_T_-26099070"]
                    : "Whom will you like to send money to?"
                }>
                <Select
                  noOptionsMessage={() => "No beneficiary added"}
                  options={beneficiaries.dropDownList}
                  styles={customStyles}
                  isClearable={false}
                  isSearchable={true}
                  customTheme={theme}
                  placeholder={
                    languageDictionaryStatus
                      ? languageDictionary["FIND_A_BENEFICIAR_-1943678036"]
                      : "Select a beneficiary"
                  }
                  components={{
                    IndicatorSeparator: null,
                    DropdownIndicator: () => <DropDownComponent iconSource={downArrow} />
                  }}
                  name="beneficiary"
                  value={formik.values.beneficiary}
                  onBlur={() => formik.setFieldTouched("beneficiary", true)}
                  onChange={(option) => {
                    getRecipientBankDetails(option);
                    formik.setFieldValue("beneficiary", option);
                  }}
                />
                <ErrorField
                  formik={formik}
                  fieldName="beneficiary"
                  fetchingData={beneficiaries.loading}
                  selectField
                />
              </FormLabel>
            </div>
            <div css={buttonWrapper}>
              <FlexibleButton
                buttonText={
                  languageDictionaryStatus
                    ? languageDictionary["WO_ADD_A_NEW_BENEFICIAR_1555543493"]
                    : "ADD NEW BENEFICIARY"
                }
                buttonWidth="100%"
                onClick={() => history.push("/beneficiaries/create")}
                height="50px"
                paddingVertical="0.3rem"
                paddingHorizontal="0.3rem"
                fontSize="14px"
              />
            </div>
          </div>
        )}

        {formik.values["beneficiary"] === "" ||
        typeof formik.values["beneficiary"] === "undefined" ? (
          ""
        ) : (
          <div css={FormRow}>
            <div css={singleRowItem}>
              <FormLabel
                text={
                  languageDictionaryStatus
                    ? languageDictionary["l_receiving_country"]
                    : "Receiving Country"
                }>
                <Select
                  noOptionsMessage={() => "No available country"}
                  options={countries}
                  styles={customStyles}
                  isClearable={false}
                  isSearchable={true}
                  placeholder={
                    languageDictionaryStatus
                      ? languageDictionary["l_destination_country"]
                      : "Select a country"
                  }
                  customTheme={theme}
                  components={{
                    IndicatorSeparator: null,
                    DropdownIndicator: () =>
                      loadingData ? <ButtonSpinner /> : <DropDownComponent iconSource={downArrow} />
                  }}
                  name="receivingCountry"
                  onChange={(option) => {
                    getRequiredFields(option);
                    fetchSupportedSendingCurrencies(option);
                    formik.setFieldValue("receivingCountry", option);
                  }}
                />
              </FormLabel>
            </div>
          </div>
        )}

        {formik.values["beneficiary"] === "" ||
        typeof formik.values["beneficiary"] === "undefined" ? (
          ""
        ) : (
          <div css={FormRow}>
            <div css={singleRowItem}>
              <FormLabel text={"Beneficiary Bank Details"}>
                <Select
                  noOptionsMessage={() => "Beneficiary has not added any bank yet"}
                  // value={bankDetailsSelect}
                  options={beneficiaryBankInfo}
                  styles={customStyles}
                  isClearable={false}
                  isSearchable={false}
                  customTheme={theme}
                  placeholder={
                    languageDictionaryStatus
                      ? languageDictionary["WO_CHOOSE_OPTIO_-243822441"]
                      : "Select an option"
                  }
                  components={{
                    IndicatorSeparator: null,
                    DropdownIndicator: () => <DropDownComponent iconSource={downArrow} />
                  }}
                  onChange={(option) => {
                    updateBankDetailsSelect(option);
                    setBankFields(option);
                  }}
                />
              </FormLabel>
            </div>
          </div>
        )}

        {renderBeforeCurrencyValidation ? (
          ""
        ) : (
          <div css={FormRow}>
            <div css={selectWrapper}>
              <FormLabel
                text={
                  languageDictionaryStatus
                    ? languageDictionary["l_sending_currency"]
                    : "Sending currency"
                }>
                <Select
                  noOptionsMessage={() => "No supported currency added"}
                  value={formik.values.sendingCurrency}
                  options={currencies["supportedCurrenciesByCountries"]}
                  styles={customStyles}
                  isClearable={false}
                  isSearchable={true}
                  customTheme={theme}
                  placeholder={
                    languageDictionaryStatus
                      ? languageDictionary["WO_SELECT_CURRENC_1917455719"]
                      : "Sending Currency"
                  }
                  name="sendingCurrency"
                  onChange={(option) => {
                    formik.setFieldValue("sendingCurrency", option);
                    fetchSupportedReceivingCurrencies(option);
                  }}
                  onBlur={() => formik.setFieldTouched("sendingCurrency", true)}
                  components={{
                    IndicatorSeparator: null,
                    DropdownIndicator: () => <DropDownComponent iconSource={downArrow} />
                  }}
                />
              </FormLabel>
            </div>
            <div css={selectWrapper}>
              <FormLabel
                text={
                  languageDictionaryStatus
                    ? languageDictionary["para_sending_amount"]
                    : "Sending Amount"
                }>
                <input
                  disabled={amountFieldStatus}
                  title={amountFieldTooltip}
                  type={"text"}
                  name="sendingAmount"
                  value={sendingAmount}
                  onChange={(event) => {
                    setReadyToCallQuote(false);
                    setSendingAmount(event.target.value);
                    setInverseCalculation(false);
                    setQuoteType("sendingAmount");
                    formik.setFieldValue("sendingAmount", event.target.value);
                  }}
                  onKeyUp={handleKeyUp}
                  css={numberInputField}
                  className={Styles.numberInputField}
                  placeholder={"Enter Amount"}
                />
              </FormLabel>
            </div>
          </div>
        )}

        {renderAfterSendingCurrencyValidation ? (
          ""
        ) : (
          <div css={FormRow}>
            <div css={selectWrapper}>
              <FormLabel
                text={
                  languageDictionaryStatus
                    ? languageDictionary["para_receiving_currency"]
                    : "Receiving currency"
                }>
                <Select
                  options={currencies["supportedReceivingCurrenciesByCountries"]}
                  value={formik.values.receivingCurrency}
                  styles={customStyles}
                  isClearable={false}
                  isSearchable={false}
                  customTheme={theme}
                  placeholder={
                    languageDictionaryStatus
                      ? languageDictionary["para_receiving_currency"]
                      : "Receiving currency"
                  }
                  name="receivingCurrency"
                  onChange={(option) => {
                    formik.setFieldValue("receivingCurrency", option);
                    getRequiredFieldsWithCurrency(option);
                    onChangeReceivingCurrency();
                  }}
                  onBlur={() => formik.setFieldTouched("receivingCurrency", true)}
                  components={{
                    IndicatorSeparator: null,
                    DropdownIndicator: () => <DropDownComponent iconSource={downArrow} />
                  }}
                />
              </FormLabel>
            </div>
            <div css={selectWrapper}>
              <FormLabel
                text={
                  languageDictionaryStatus
                    ? languageDictionary["para_receiving_amount"]
                    : "Receiving Amount"
                }>
                <input
                  disabled={amountFieldStatus}
                  title={amountFieldTooltip}
                  type={"text"}
                  name="receivingAmount"
                  value={receivingAmount}
                  onChange={(event) => {
                    setReadyToCallQuote(false);
                    setReceivingAmount(event.target.value);
                    setInverseCalculation(true);
                    setQuoteType("receivingAmount");
                  }}
                  onKeyUp={handleKeyUp}
                  css={numberInputField}
                  className={Styles.numberInputField}
                  placeholder={"Enter Amount"}
                />
              </FormLabel>
            </div>
          </div>
        )}

        {renderAfterCurrencyValidation || pageStatus !== "Mobile Money" ? (
          ""
        ) : (
          <div css={FormRow}>
            <div css={singleRowItem}>
              <FormLabel
                text={
                  languageDictionaryStatus
                    ? languageDictionary["para_phone_number"]
                    : "Phone Number"
                }>
                <input
                  type={"text"}
                  name="phoneNumber"
                  id="phoneNumber"
                  onChange={formik.handleChange}
                  css={numberInputField}
                  className={Styles.numberInputField}
                  placeholder={
                    languageDictionaryStatus
                      ? languageDictionary["WO_PLEASE_ENTER_PHONE_N_880796970"]
                      : "Enter Phone Number"
                  }
                />
              </FormLabel>
            </div>
          </div>
        )}

        {renderAfterCurrencyValidation || pageStatus !== "Mobile Money" ? (
          ""
        ) : (
          <div css={FormRow}>
            <div css={singleRowItem}>
              <FormLabel
                text={
                  languageDictionaryStatus
                    ? languageDictionary["para_confirm_phone_number"]
                    : "Confirm Phone Number"
                }>
                <input
                  type={"text"}
                  name="confirmPhoneNumber"
                  id="confirmPhoneNumber"
                  onChange={formik.handleChange}
                  css={numberInputField}
                  className={Styles.numberInputField}
                  placeholder={
                    languageDictionaryStatus
                      ? languageDictionary["WO_PLEASE_ENTER_PHONE_N_880796970"]
                      : "Enter Phone Number"
                  }
                />
              </FormLabel>
            </div>
          </div>
        )}

        {renderAfterCurrencyValidation || pageStatus !== "Cash Pickup" ? (
          ""
        ) : (
          <div css={FormRow}>
            <div css={singleRowItem}>
              <FormLabel
                text={
                  languageDictionaryStatus
                    ? languageDictionary["para_security_question"]
                    : "Security Question"
                }>
                <input
                  type={"text"}
                  name="securityQuestion"
                  id="securityQuestion"
                  onChange={formik.handleChange}
                  css={numberInputField}
                  className={Styles.numberInputField}
                  placeholder={
                    languageDictionaryStatus
                      ? languageDictionary["para_security_question"]
                      : "Security Question"
                  }
                />
              </FormLabel>
            </div>
          </div>
        )}

        {pageStatus !== "Cash Pickup" || renderAfterCurrencyValidation ? (
          ""
        ) : (
          <div css={FormRow}>
            <div css={singleRowItem}>
              <FormLabel
                text={
                  languageDictionaryStatus
                    ? languageDictionary["para_security_answer"]
                    : "Security Answer"
                }>
                <input
                  type={"text"}
                  name="securityAnswer"
                  id="securityAnswer"
                  onChange={formik.handleChange}
                  css={numberInputField}
                  className={Styles.numberInputField}
                  placeholder={
                    languageDictionaryStatus
                      ? languageDictionary["para_please_answer_for_the_security_question"]
                      : "Security Answer"
                  }
                />
              </FormLabel>
            </div>
          </div>
        )}

        {pageStatus !== "Transfer Funds" || renderAfterCurrencyValidation ? (
          ""
        ) : (
          <div css={FormRow}>
            <div css={singleRowItem}>
              <FormLabel
                text={
                  languageDictionaryStatus
                    ? languageDictionary["para_payment_reference"]
                    : "Payment Reference"
                }>
                <input
                  type={"text"}
                  name="transactionReference"
                  id="transactionReference"
                  onChange={formik.handleChange}
                  css={numberInputField}
                  className={Styles.numberInputField}
                  placeholder={
                    languageDictionaryStatus
                      ? languageDictionary["l_payment_reference_code"]
                      : "Payment Reference Code"
                  }
                />
              </FormLabel>
            </div>
          </div>
        )}

        {renderAfterCurrencyValidation ? (
          ""
        ) : (
          <div css={FormRow}>
            <div css={singleRowItem}>
              <FormLabel
                text={
                  languageDictionaryStatus
                    ? languageDictionary["l_purpose_transfer"]
                    : "Purpose of Transfer"
                }>
                <Select
                  options={purposeOfTransfer}
                  styles={customStyles}
                  isClearable={false}
                  isSearchable={false}
                  customTheme={theme}
                  placeholder={
                    languageDictionaryStatus
                      ? languageDictionary["WO_CHOOSE_OPTIO_-243822441"]
                      : "Select an option"
                  }
                  components={{
                    IndicatorSeparator: null,
                    DropdownIndicator: () => <DropDownComponent iconSource={downArrow} />
                  }}
                  name="remittancePurpose"
                  onChange={(option) => formik.setFieldValue("remittancePurpose", option)}
                />
              </FormLabel>
            </div>
          </div>
        )}

        {renderAfterCurrencyValidation || enableVamProfile ? (
          ""
        ) : (
          <div css={FormRow}>
            <div css={singleRowItem}>
              <FormLabel
                text={
                  languageDictionaryStatus
                    ? languageDictionary["WO_SOURCE_OF_FUND_-1732861383"]
                    : "Source of funds"
                }>
                <Select
                  options={sourceOfFunds}
                  styles={customStyles}
                  isClearable={false}
                  isSearchable={false}
                  customTheme={theme}
                  placeholder={
                    languageDictionaryStatus
                      ? languageDictionary["WO_CHOOSE_OPTIO_-243822441"]
                      : "Select an option"
                  }
                  components={{
                    IndicatorSeparator: null,
                    DropdownIndicator: () => <DropDownComponent iconSource={downArrow} />
                  }}
                  name="sourceOfFunds"
                  onChange={(option) => formik.setFieldValue("sourceOfFunds", option)}
                />
              </FormLabel>
            </div>
          </div>
        )}

        {renderAfterCurrencyValidation ? (
          ""
        ) : (
          <CreateCategory theme={theme} languageDictionary={languageDictionary} />
        )}

        {/*{renderAfterCurrencyValidation || requiredDocumentStatus ? (
          ""
        ) : (
          <> cool
          </>
        )}*/}
      </div>
    </React.Fragment>
  );
};

export default TransDetails;

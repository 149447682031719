// eslint-disable-next-line no-unused-vars
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fileToBase64 } from "Utils/Helpers/general";
import { toast } from "react-toastify";
import { updateProfilePicture } from "Store/actions/document.action";
import Spinner from "Components/Spinner";
import { ImageElement } from "Components/Styled";
/** @jsx jsx */
/** @jsxRuntime classic */

import { jsx, css } from "@emotion/react";
import * as StyleSheet from "./style";
import { useEffect } from "react";

const UploadImage = () => {
  const dispatch = useDispatch();
  const [submitPhoto, setSubmitPhoto] = useState(null);
  const [photoUrl, setPhotoUrl] = useState("");

  const { userData, pictureLoading } = useSelector(({ profileReducer, documentReducer }) => ({
    userData: profileReducer.userData,
    pictureLoading: documentReducer.pictureLoading,
  }));

  const onChangeHandler = async (event) => {
    const selectedFileSize = event.target.files[0].size || null;
    if (selectedFileSize === null|| typeof selectedFileSize === 'undefined') {

    } else if (event.target.files[0] && !event.target.files[0].type.includes('image')) {
      toast.error("File is not an image", {
        position: "top-center",
      });
    } else if (selectedFileSize > 512758) {
      toast.error("File size too large", {
        position: "top-center",
      });
    } else {
      let src = URL.createObjectURL(event.target.files[0]);
      setSubmitPhoto(event.target.files[0]);
      setPhotoUrl(src);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setPhotoUrl(userData.profileImage);
  }, []);

  const handleSubmit = async () => {
    try {
      const fileDataBase64 = await fileToBase64(submitPhoto);
      const [, imageBase64] = fileDataBase64.split(",");
      const senderCode = userData.customerCode;

      const payload = {
        imageBase64,
        senderCode,
        imageFileName: senderCode + "_profile_picture",
      };
      dispatch(updateProfilePicture(payload));
    } catch (error) {
      toast.error("Error uploading file", {
        position: "top-center",
      });
    }
  };

  return (
    <div>
      {pictureLoading ? (
        <Spinner />
      ) : (
        <div css={StyleSheet.profileBox}>
          <div css={StyleSheet.profilePicture}>
            <div css={StyleSheet.profilePictureInner}>
              <div css={StyleSheet.profilePictureMain}>
                <ImageElement
                  src={photoUrl}
                />
              </div>
            </div>
          </div>
          <div css={StyleSheet.profileBoxUpload}>
            <div css={StyleSheet.profileBoxUploadContainer}>
              <div css={StyleSheet.profileBoxUploadInner}>
                <form>
                  <input
                    type="file"
                    css={StyleSheet.inputStyle}
                    name="file"
                    onChange={onChangeHandler}
                  />
                </form>
              </div>
              <button onClick={handleSubmit} css={StyleSheet.buttonStyle}>
                UPLOAD PICTURE
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UploadImage;

import React  from "react";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import Footer from "Components/Footer";
import NavBar from "Components/Navbar";
import { NextButton, CardBorder, SanctionCard } from "./style";

const PayoutTransaction = () => {
  const history = useHistory();
  let payoutData;
  if (typeof history.location.state === "undefined") history.push("/cashier/payout-search");
  else payoutData = history.location.state;

  console.log(payoutData);

  const payoutTransaction = () => {
    history.push({pathname: '/cashier/security-question', state: { pcn: payoutData.pcn, payoutData: payoutData.response }});
  }

  return (
    <>
      <Helmet>
        <title>Payout Transaction &mdash; Multiview MTA</title>
      </Helmet>
      <NavBar />
      <section>
        <div className="container">
          <div className="mt-5 mb-5">
            <h2 className="text-blue font-weight-bold">Payout Transaction</h2>
          </div>

          <hr className="mb-3" />
          <div className="row mb-5">
            <div className="col-lg-12">
              <CardBorder className="card mb-3 mb-lg-5 p-2 p-xl-3">
                <div className="mb-5" id="beneficiary">
                  <h5 className="text-blue font-weight-bold">
                    Beneficiary Details
                  </h5>
                  <CardBorder className="card">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-12 col-lg-12">
                          <div className="row">
                            <div className="col-4 mb-3">
                              <p className="text-blue text-uppercase">
                                Beneficiary code
                              </p>
                              <span className="h6">-</span>
                            </div>
                            <div className="col-4 mb-3">
                              <p className="text-blue text-uppercase">
                                Beneficiary Name
                              </p>
                              <span className="h6">{payoutData.response["receiverFirstName"]} {payoutData.response["receiverMiddleName"]} {payoutData.response["receiverLastName"]}</span>
                            </div>
                            <div className="col-4 mb-3">
                              <p className="text-blue text-uppercase">
                                Payout Amount
                              </p>
                              <span className="h6">{payoutData.response["receiverCurrency"]} {payoutData.response.receivingAmount}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </CardBorder>
                </div>

                <div className="mb-5" id="attach">
                  <h5 className="text-blue font-weight-bold">
                    Attached Customer Documents
                  </h5>
                  <CardBorder className="card">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-12 col-md-6">
                          <p className="text-blue">
                            No documents have been attached
                          </p>
                        </div>
                        <div className="col-12 col-md-3">
                          <NextButton className="btn btn-lg btn-blue btn-block text-uppercase text-white">
                            attach file
                          </NextButton>
                        </div>
                      </div>
                    </div>
                  </CardBorder>
                </div>

                <div id="transaction">
                  <h5 className="text-blue font-weight-bold">
                    Transaction Details
                  </h5>
                  <div className="mb-5">
                    <div className="card">
                      <div className="card-body">
                        <div className="row align-items-center">
                          <div className="col-6 col-md-4 mb-5">
                            <p className="text-blue text-uppercase">
                              Transaction date
                            </p>
                            <span className="h6">-</span>
                          </div>
                          <div className="col-6 col-md-4 mb-5">
                            <p className="text-blue text-uppercase">
                              PCN
                            </p>
                            <span className="h6">{payoutData.pcn}</span>
                          </div>
                          <div className="col-6 col-md-4 mb-5">
                            <p className="text-blue text-uppercase">
                              Transaction Type
                            </p>
                            <span className="h6">{payoutData.response.transactionType}</span>
                          </div>
                          <div className="col-6 col-md-4 mb-5">
                            <p className="text-blue text-uppercase">
                              Sending Amount
                            </p>
                            <span className="h6 text-green">{payoutData.response.sendingCurrency} {payoutData.response.sendingAmount}</span>
                          </div>
                          <div className="col-6 col-md-4 mb-5">
                            <p className="text-blue text-uppercase">
                              Payout Amount
                            </p>
                            <span className="h6 text-green text-uppercase">{payoutData.response["receiverCurrency"]} {payoutData.response.receivingAmount}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mb-5" id="pay">
                  <h5 className="text-blue font-weight-bold">
                    Pay Centres Available
                  </h5>
                  <CardBorder className="card">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-12 col-md-4">
                          <NextButton className="btn btn-lg btn-blue btn-block text-uppercase text-white">
                            expand details
                          </NextButton>
                        </div>
                      </div>
                    </div>
                  </CardBorder>
                </div>

                <div className="mb-5" id="sender">
                  <h5 className="text-blue font-weight-bold">Sender Details</h5>
                  <SanctionCard className="card">
                    <div className="text-center"/>
                  </SanctionCard>
                </div>

                <div className="mb-3">
                  <div className="row d-flex justify-content-between">
                    <div className="col-12 col-md-3 mb-3">
                      <NextButton className="btn btn-lg btn-blue btn-block text-uppercase text-white">
                        Blacklist request
                      </NextButton>
                    </div>
                    <div className="col-12 col-md-3 mb-3">
                      <NextButton onClick={payoutTransaction} className="btn btn-lg btn-green btn-block text-uppercase text-white">
                        payout
                      </NextButton>
                    </div>
                  </div>
                </div>
              </CardBorder>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default PayoutTransaction;

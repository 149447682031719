import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";

import Spinner from "Components/Spinner";
import HeadingText from "Components/HeadingText";
import FxCard from "Components/FxCard";
import CustomButton from "Components/CustomButton";
import CustomNumInput from "Components/CustomNumInput";
import { Container, InnerContainer } from "Components/Styled";
import NavBar from "Components/Navbar";
import Footer from "Components/Footer";

import { FlexFormWrapper, MiniContainer, ButtonAlign, CardBox, FundFormRow } from "./styled";

import { getAccountBalances } from "Store/actions/account.action";
import { getQuoteData, initiateFundWallet } from "Store/actions/transfer.action";
import * as Loading from "Store/constants/loadingState.constants";
import offSetImg from "Assets/svg/transfer1.svg";
import navigateMethod from "../../../Utils/Helpers/navigateMethod";
import CustomInput from "../../../Components/CustomInput";
import TransferService from "../../../Utils/Services/Transfer.service";
import { toast } from "react-toastify";
import customStyles from "../../../Assets/styles/dropdown/customStyles";
import DropDownComponent from "../../../Components/DropDownComponent";
import downArrow from "../../../Assets/svg/blue-drop-down-arrow.svg";
import Select from "react-select";
import Label from "../../../Components/Label";
import SubHeadingText from "../../../Components/SubHeadingText";

const FundAccount = () => {
  const dispatch = useDispatch(), history = useHistory();
  const { accounts, transfers, profile, domainBrand, theme } = useSelector(
    ({ accountReducer, transferReducer, profileReducer, domainBrandReducer, themeReducer }) => ({
      accounts: accountReducer,
      transfers: transferReducer,
      profile: profileReducer,
      domainBrand: domainBrandReducer,
      theme: themeReducer
    })
  );
  const languageDictionary = domainBrand.domainBrand["languageDictionary"];
  const languageDictionaryStatus = !(typeof languageDictionary === "undefined" || languageDictionary === null);
  const [beneficiaryId, setBeneficiaryId] = useState("");
  const [currencySymbol, setCurrencySymbol] = useState("");
  const [accountName, setAccountName] = useState("");
  const [beneficiarySearchName, setBeneficiarySearchName] = useState("");

  const formik = useFormik({
    initialValues: { amount: "", currency: "" },
    validationSchema: Yup.object({
      amount: Yup.string().required("Required"),
      currency: Yup.object({ value: Yup.string().required("Required") })
    }),
    onSubmit: () => {
    }
  });
  const fetchingProfile = profile.userProfileLoading === Loading.FETCHING;
  let callQuoteData = {
    transactionType: "WALLET",
    conversion: true,
    chargeCategory: "WALLET_MONEY_LOAD_CASH",
    paymentType: "E_WALLET",
    inverseCalculation: true
  };
  fetchingProfile ? (callQuoteData.tradeOriginatingCountry = "") : (callQuoteData.tradeOriginatingCountry = profile["userData"]["address"]["countryIso3"]);

  useEffect(() => {
    dispatch(getAccountBalances());
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      callQuoteData.principalAmountCurrency = formik.values["currency"]["walletAccountCurrency"];
      callQuoteData.deriveAmountCurrency = formik.values["currency"]["walletAccountCurrency"];
      callQuoteData.deriveAmount = formik.values["amount"];
      dispatch(getQuoteData(callQuoteData));
    }, 1000);
    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.amount]);

  const spinnerLoading = accounts.loadingStatus === Loading.FETCHING;
  const { quoteData: { total_amount, payment_method_fee, fxRate, tax } } = transfers;
  const openingBalance = formik.values.currency["walletAccountBalance"] || "";
  const buttonLoading = transfers.fundWalletStatus === Loading.FETCHING;

  const fundAccount = () => {
    const accountNumber = profile["userData"]["userType"] === "CASHIER" ? formik.values.searchParam : formik.values.currency["walletAccountNumber"];
    const accountNameData = profile["userData"]["userType"] === "CASHIER" ? accountName : formik.values.currency.accountName;
    if (currencySymbol === "" || formik.values.amount === "") toast.error("Fill in required fields", { position: toast.POSITION.TOP_CENTER });
    else {
      const formattedAmount = Number(formik.values.amount.replace(/,/g, ""));
      const dataBody = {
        amountDepositing: formattedAmount,
        accountCurrency: currencySymbol,
        accountNumber: accountNumber
      };
      const options = {
        $referrer: "fundWallet",
        $accountName: accountNameData,
        $currency: dataBody.accountCurrency,
        $accountNumber: dataBody.accountNumber
      };
      if (profile["userData"]["userType"] === "CASHIER") dataBody.accountOwnerCustomerId = beneficiaryId;
      if (profile["userData"]["userType"] === "CASHIER") dataBody.cashierPassword = formik.values.password;
      const navigator = navigateMethod(history, "/transfer/choose-payment-method");
      dispatch(initiateFundWallet(dataBody, navigator, options));
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const searchForAccount = async () => {
    const payload = { "findbyAccountNumber": formik.values.searchParam };
    const { data: responseData } = await TransferService.accountInfoLookup(payload);
    if (responseData["status"] === "FAILED") toast.error(`${responseData["message"]}`, { position: toast.POSITION.TOP_CENTER });
    else {
      setCurrencySymbol(responseData.data.currencyCode);
      setBeneficiaryId(responseData.data.beneficiaryId);
      setAccountName(`${responseData.data["firstName"]} ${responseData.data["lastName"]}`);
    }
  };

  return (
    <>
      <Helmet>
        <title>
          {languageDictionaryStatus ? languageDictionary["l_fund_wallet"] : "Fund Account"}
        </title>
      </Helmet>
      <Container>
        <NavBar />
        <InnerContainer>
          <div className="fdt__canvasImg">
            <img src={offSetImg} alt="off canvas icon" />
          </div>
          <div className="fdt_topSection">
            <HeadingText text={languageDictionaryStatus ? languageDictionary["l_fund_wallet"] : "Fund Account"} />
            <SubHeadingText
              text={languageDictionaryStatus ? languageDictionary["WO_SELECT_THE_ACCOUNT_Y_17790977"] : "Select a transfer method"} />
            {spinnerLoading ? <Spinner /> :
              <FlexFormWrapper onSubmit={formik.handleSubmit}>
                <CardBox>
                  <FundFormRow>
                    {profile["userData"]["userType"] === "CASHIER" ?
                      <div className="row">
                        <div className="col-8">
                          <CustomInput
                            formik={formik}
                            inputKey="searchParam"
                            inputSize="200"
                            labelName={languageDictionaryStatus ? languageDictionary["FIND_ACCOUN_31978564"] : "Find Account"}
                          />
                        </div>
                        <div className="col-4 mt-4">
                          <ButtonAlign>
                            <CustomButton
                              type="button"
                              buttonText={languageDictionaryStatus ? languageDictionary["button_search"] : "Search"}
                              buttonWidth="100%"
                              loading={buttonLoading}
                              onClick={searchForAccount}
                            />
                          </ButtonAlign>
                        </div>
                      </div> :
                      <>
                        <Label className="benCreate__main__form--inputHeading">
                          {languageDictionaryStatus ? languageDictionary["l_type"] : "Select the account to fund"}
                        </Label>
                        <Select
                          options={accounts.dropDownList}
                          styles={customStyles}
                          customTheme={theme}
                          isClearable={false}
                          isSearchable={true}
                          placeholder={languageDictionaryStatus ? languageDictionary["l_select__your_account"] : " Select your account"}
                          value={formik["currency"]}
                          onChange={(option) => {
                            formik.setFieldValue("currency", option);
                            setCurrencySymbol(option["value"]);
                          }}
                          onBlur={() => formik.setFieldTouched("currency", true)}
                          components={{
                            IndicatorSeparator: null,
                            DropdownIndicator: () => <DropDownComponent iconSource={downArrow} />
                          }}
                        />
                      </>

                    }
                  </FundFormRow>
                  <CustomNumInput
                    formik={formik}
                    inputKey="amount"
                    inputSize="100"
                    labelName={languageDictionaryStatus ? languageDictionary["para_amount_depositing"] : "Amount Depositing"}
                  />
                  {profile["userData"]["userType"] === "CASHIER" ?
                    <CustomInput
                      formik={formik}
                      inputType={"password"}
                      inputKey="password"
                      inputSize="100"
                      labelName={languageDictionaryStatus ? languageDictionary["param_password"] : "Password"}
                    /> : ""
                  }
                  <ButtonAlign>
                    <CustomButton
                      onClick={fundAccount}
                      buttonText={languageDictionaryStatus ? languageDictionary["button_make_payment"] : "MAKE PAYMENT"}
                      loading={buttonLoading}
                    />
                  </ButtonAlign>
                </CardBox>

                <MiniContainer>
                  <div>
                    <FxCard
                      currencySymbol={currencySymbol}
                      fxRate={fxRate}
                      tax={tax}
                      totalAmount={total_amount}
                      paymentFee={payment_method_fee}
                      openingBalance={openingBalance}
                      languageStatus={languageDictionaryStatus}
                      languageDictionary={languageDictionary}
                    />
                  </div>
                </MiniContainer>
              </FlexFormWrapper>
            }
          </div>
        </InnerContainer>
        <Footer />
      </Container>
    </>
  );
};

export default FundAccount;

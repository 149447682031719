import * as Bill from "Store/constants/bill.constants";

const initialState = {
  countries: [],
  services: [],
  operators: [],
  products: [],
  loading: false,
  loadingStatus: "",
  operatorsLoadingStatus: "",
  servicesLoadingStatus: "",
  paymentLoadingStatus: "",
  productsLoadingStatus: "",
  quoteLoadingStatus: "",
  paymentInfo: {},
  quoteData: {},
  paymentMethod: {},
  paymentMethodStatus: "",
  paymentStatus: "",
};

const billReducer = (state = initialState, action) => {
  switch (action.type) {
    case Bill.FETCH_COUNTRY_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        loadingStatus: "FETCHING",
      };

    case Bill.FETCH_COUNTRY_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        loadingStatus: "SUCCESS",
        countries: action.payload,
      };

    case Bill.FETCH_COUNTRY_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        loadingStatus: "ERROR",
      };

    case Bill.FETCH_BILL_SERVICE_BY_COUNTRY_REQUEST:
      return {
        ...state,
        loading: true,
        servicesLoadingStatus: "FETCHING",
      };

    case Bill.FETCH_BILL_SERVICE_BY_COUNTRY_SUCCESS:
      return {
        ...state,
        loading: false,
        servicesLoadingStatus: "SUCCESS",
        services: action.payload,
      };

    case Bill.FETCH_BILL_SERVICE_BY_COUNTRY_FAILURE:
      return {
        ...state,
        loading: false,
        servicesLoadingStatus: "ERROR",
      };
    case Bill.FETCH_OPERATORS_BY_SERVICE_REQUEST:
      return {
        ...state,
        loading: true,
        operatorsLoadingStatus: "FETCHING",
      };

    case Bill.FETCH_OPERATORS_BY_SERVICE_SUCCESS:
      return {
        ...state,
        loading: false,
        operatorsLoadingStatus: "SUCCESS",
        operators: action.payload,
      };

    case Bill.FETCH_OPERATORS_BY_SERVICE_FAILURE:
      return {
        ...state,
        loading: false,
        operatorsLoadingStatus: "ERROR",
      };
    case Bill.FETCH_PRODUCTS_BY_OPERATOR_REQUEST:
      return {
        ...state,
        loading: true,
        productsLoadingStatus: "FETCHING",
      };

    case Bill.FETCH_PRODUCTS_BY_OPERATOR_SUCCESS:
      return {
        ...state,
        loading: false,
        productsLoadingStatus: "SUCCESS",
        products: action.payload,
      };

    case Bill.FETCH_PRODUCTS_BY_OPERATOR_FAILURE:
      return {
        ...state,
        loading: false,
        productsLoadingStatus: "ERROR",
      };
    case Bill.FETCH_QUOTE_FOR_BILL_PAYMENT_REQUEST:
      return {
        ...state,
        loading: true,
        quoteLoadingStatus: "FETCHING",
      };

    case Bill.FETCH_QUOTE_FOR_BILL_PAYMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        quoteLoadingStatus: "SUCCESS",
        quoteData: action.payload,
      };

    case Bill.FETCH_QUOTE_FOR_BILL_PAYMENT_FAILURE:
      return {
        ...state,
        loading: false,
        quoteLoadingStatus: "ERROR",
      };

    case Bill.INITIATE_BILL_PAYMENT_REQUEST:
      return {
        ...state,
        loading: true,
        paymentLoadingStatus: "FETCHING",
      };

    case Bill.INITIATE_BILL_PAYMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        paymentLoadingStatus: "SUCCESS",
        paymentInfo: action.payload,
      };

    case Bill.INITIATE_BILL_PAYMENT_FAILURE:
      return {
        ...state,
        paymentLoadingStatus: "ERROR",
      };
    case Bill.LIST_BILL_PAYMENT_METHODS_REQUEST:
      return {
        ...state,
        paymentMethodStatus: "FETCHING",
        paymentMethod: {},
      };
    case Bill.LIST_BILL_PAYMENT_METHODS_SUCCESS:
      return {
        ...state,
        paymentMethodStatus: "SUCCESS",
        paymentMethod: action.payload,
      };
    case Bill.LIST_BILL_PAYMENT_METHODS_FAILURE:
      return {
        ...state,
        paymentMethodStatus: "ERROR",
      };

    default:
      return state;
  }
};

export default billReducer;

import styled from '@emotion/styled';

export const FlexFormWrapper = styled.form`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;

  @media (max-width: 56.25rem) {
    flex-direction: column;
  }
`;

export const MiniContainer = styled.div`
  max-width: 48.5%;
  flex-basis: 48.5%;
  display: flex;
  flex-direction: column;

  @media (max-width: 56.25rem) {
    max-width: 100%;
    margin-bottom: 1.5rem;
  }

  
`;

export const ButtonAlign = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: auto;

  @media (max-width: 56.25rem) {
    margin-bottom: 1rem;
  }
`;

export const CardBox = styled.div`
  border: 1px solid #e3edf3;
  border-radius: 10px;
  padding: 45px;
  flex-basis: 48.5%;

  @media (max-width: 56.25rem) {
    padding: 1rem;
    width: 100%;
    margin-bottom: 1rem;
  }
`;

export const FundFormRow = styled.div`
  margin-bottom: 30px;

  @media (max-width: 56.25rem) {
    width: 100%;
    margin-bottom: 1rem;

  }
`;

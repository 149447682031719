import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import * as AirtimeActions from "Store/actions/airtime.action";
import { getAllBeneficiaries } from "Store/actions/beneficiary.action";
import * as Loading from "Store/constants/loadingState.constants";
import Footer from "Components/Footer";
import NavBar from "Components/Navbar";
import Notes from "Components/Notes";
import Select from "react-select";
import DropDownComponent from "Components/DropDownComponent";
import customStyles from "Assets/styles/dropdown/customStyles";
import downArrow from "Assets/svg/blue-drop-down-arrow.svg";
import { debounce } from "lodash";
import ButtonSpinner from "Components/ButtonSpinner";
import { Input, Button } from "./style";
import BackArrow from "Components/BackArrow";

const AirtimeDetails = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const [selected, setSelected] = useState({ product: null });
  const [toggleBeneficiary, toggleBeneficiaryBtn] = React.useState("");

  const [beneficiaryDropdown, setBeneficiaryDropdown] = React.useState(false);
  const displayBeneficiary = () => setBeneficiaryDropdown(true);

  const {
    airtime: { preTopupLoadingStatus, preTopupData, quoteData },
    beneficiaries,
    domainBrand
  } = useSelector(({ airtimeReducer, beneficiaryReducer, domainBrandReducer }) => ({
    airtime: airtimeReducer,
    beneficiaries: beneficiaryReducer,
    domainBrand: domainBrandReducer
  }));

  const languageDictionary = domainBrand.domainBrand["languageDictionary"];
  const languageDictionaryStatus = !(
    typeof languageDictionary === "undefined" || languageDictionary === null
  );

  const destinationCountryIso3 = location.search.split("=")[1].split("&")[0];

  const [initialValues, updateInitialValues] = useState({ beneficiary: "" });

  useEffect(() => {
    dispatch(getAllBeneficiaries());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (beneficiaries.loadingStatus === Loading.SUCCESS && location.state) {
      const {
        state: { id, beneficiaryCode }
      } = location;
      const preselectedBeneficiary = beneficiaries.dropDownList.find(
        (ben) => ben.id === id || ben.beneficiaryCode === beneficiaryCode
      );
      updateInitialValues({
        beneficiary: preselectedBeneficiary
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [beneficiaries.loadingStatus]);

  const fetchAirtimeDetails = debounce((phone) => {
    dispatch(AirtimeActions.getPreTopup(phone, destinationCountryIso3));
  }, 1000);

  const handleAirtimePayment = () => {
    const { amount, phone } = selected;

    dispatch(
      AirtimeActions.initiateAirtimePayment({
        amount: amount.value,
        phoneNumber: phone,
        destinationCountryIso3
      })
    );

    history.push("/airtime-topup/choose-payment-method");
  };

  return (
    <>
      <Helmet>
        <title>Airtime Details</title>
      </Helmet>
      <NavBar />
      <section>
        <div className="container">
          <div className="mt-5 mb-5">
            <div className="d-flex">
              <BackArrow />
              <h2 className="text-blue mt-4">
                {languageDictionaryStatus ? languageDictionary["m_mobile_top_up"] : "Airtime Topup"}
              </h2>
            </div>
            <div className="row">
              <div className="col-12 col-xl-7">
                <div className="card mt-5 p-md-5">
                  <div className="card-body mb-3 p-md-3">
                    {/* --- Search box for amount to send --- */}
                    <div className="row mb-5">
                      <div className="col-12">
                        <p className="text-blue">Enter phone number</p>
                        <div className="input-group mb-3">
                          <Input
                            type="tel"
                            className="form-control"
                            placeholder="e.g. +44865327"
                            onChange={(evt) => {
                              toggleBeneficiaryBtn(evt?.target?.value);
                              fetchAirtimeDetails(evt.target?.value);
                              setSelected({ ...selected, phone: evt.target?.value });
                            }}
                          />
                          <span className="input-group-append">
                            {preTopupLoadingStatus === "FETCHING" ? (
                              <ButtonSpinner containerHeight="inherit" />
                            ) : (
                              <div className="input-group-text">
                                <img src={preTopupData.operatorLogo || {}} alt="" />
                              </div>
                            )}
                          </span>
                        </div>

                        {toggleBeneficiary?.length === 0 && (
                          <>
                            <div className="mb-3">
                              <p className="text-blue">OR</p>
                            </div>
                            <div className="row d-flex justify-content-between mb-3">
                              <div className="col-12 col-md-6 mb-3">
                                <button
                                  type="button"
                                  className="btn btn-block btn-green text-white text-uppercase"
                                  onClick={displayBeneficiary}>
                                  select beneficiary
                                </button>
                              </div>
                              <div className="col-12 col-md-6">
                                <Button
                                  className="btn btn-block"
                                  onClick={() => history.push("/beneficiaries/create")}>
                                  Add New Beneficiary
                                </Button>
                              </div>
                            </div>
                          </>
                        )}
                        {beneficiaryDropdown ? (
                          <>
                            <div className="row">
                              <div className="col-12">
                                <Select
                                  options={beneficiaries.dropDownList}
                                  styles={customStyles}
                                  isClearable={false}
                                  isSearchable={true}
                                  customTheme={""}
                                  placeholder="Select beneficiary"
                                  onChange={(option) => console.log(option.phoneNumber)}
                                  components={{
                                    IndicatorSeparator: null,
                                    DropdownIndicator: () => (
                                      <DropDownComponent iconSource={downArrow} />
                                    )
                                  }}
                                />
                              </div>
                            </div>
                          </>
                        ) : null}

                        {/* ----- Top Amount ----- */}
                        <div className="mt-5 mb-5">
                          <p className="text-blue">Topup Amount</p>
                          <Select
                            options={(preTopupData.topupProductList || []).map((e) => ({
                              label: e,
                              value: e,
                              original: e
                            }))}
                            onChange={(value) => {
                              const { original } = value;
                              dispatch(
                                AirtimeActions.getQuoteForAirtimeTopup({
                                  amount: original,
                                  currency: preTopupData.currency
                                })
                              );
                              setSelected({ ...selected, amount: value });
                            }}
                            styles={customStyles}
                            isClearable={false}
                            isSearchable={true}
                            customTheme={""}
                            placeholder="Select an amount"
                            components={{
                              IndicatorSeparator: null,
                              DropdownIndicator: () => <DropDownComponent iconSource={downArrow} />
                            }}
                          />
                        </div>
                        {/* ------ Note Section */}
                        <Notes
                          exchangeRate={`1.00 GBP = ${quoteData.fxRate || "1.00"}${
                            quoteData.receivingCurrency || ""
                          }`}
                          fee={quoteData.payment_method_fee || "0.00"}
                          tax={quoteData.tax || "0.00"}
                          totalAmount={`${quoteData.total_amount || "0.00"}${
                            quoteData.sendingCurrency || ""
                          }`}
                        />
                        {/* ----- Proceed button ------ */}
                        <div className="mt-5 col-12 col-md-6">
                          <button
                            className="btn btn-lg btn-blue btn-block text-uppercase text-white"
                            disabled={!quoteData.total_amount}
                            onClick={handleAirtimePayment}>
                            Proceed
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default AirtimeDetails;

// eslint-disable-next-line no-unused-vars
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { alertBankTransfer, fetchPayLater } from "Store/actions/transfer.action";
import {
  containerStyle,
  ContainerWithCounter,
  StyledParagraph,
  StyledTextHeading,
  StyledInfoContainer,
  buttonWrapper,
  buttonModalWrapper,
  ContentContainer,
  MainHeading,
  SubHeading,
  SubHeadingTwo,
  MoneyText
} from "./BankTransferstyle";

/** @jsx jsx */
/** @jsxRuntime classic */

import { jsx, css } from "@emotion/react";
import { useLocation } from "react-router-dom";
import CustomButton from "Components/CustomButton";
import AriaModal from "react-aria-modal";
import { InnerBottomContainer, TitleHeading } from "./WalletStyle";

const BankTransfer = ({ dataObject }) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const { parameters: { "bank-details": bankFields, paymentGatewayRef } } = dataObject;
  const { transfer: { paymentMethod } } = useSelector(({ transferReducer }) => ({ transfer: transferReducer }));
  const [showPayModal, updatePayModal] = useState(false);
  const [showPaidModal, updatePaidModal] = useState(false);
  const { domainBrand } = useSelector(({ domainBrandReducer }) => ({ domainBrand: domainBrandReducer }));
  const languageDictionary = domainBrand.domainBrand["languageDictionary"];
  const languageDictionaryStatus = !(typeof languageDictionary === "undefined" || languageDictionary === null);
  const { currency } = paymentMethod;
  const sendingAmount = location.state ? location.state.amount : null;
  const pcn = location.state ? location.state.pcn : null;
  const payableType = location.state ? location.state.payableType : null;
  const getApplicationNode = () => {
    return document.getElementById("root");
  };

  const handlePayLater = () => {
    dispatch(fetchPayLater({ pcn, payableType }));
    updatePayModal(true);
  };

  const handlePaidNow = () => {
    dispatch(alertBankTransfer({ pcn, payableType }));
    updatePaidModal(true);
  };

  const redirectToTransfer = () => {
    history.push("/transfer/history");
  };

  const redirectToHome = () => {
    history.push("/");
  };

  return (
    <>
      <div>
        <ContainerWithCounter counter="1">
          <StyledParagraph>
            {languageDictionaryStatus
              ? languageDictionary["para_bank_transfer_step1"]
              : "Connect to your online banking or call your bank & transfer the sending amount to the bank account given below"}
          </StyledParagraph>
        </ContainerWithCounter>

        <ContainerWithCounter
          counter="2"
          css={css`margin: 3rem 0 1rem 0;`}>
          <StyledParagraph>
            {languageDictionaryStatus
              ? languageDictionary["para_bank_transfer_step2"]
              : "Transfer the total amount due, using the following information:"}
          </StyledParagraph>

          <div
            css={css`
              background: #f5f9fb;
              border-radius: 6px;
              padding: 2.5rem 2rem;
              margin-top: 1rem;
            `}>
            {Object.entries(bankFields).map(([fieldKey, fieldValue]) => (
              <StyledInfoContainer key={fieldKey}>
                <StyledTextHeading>{fieldKey}</StyledTextHeading>
                <StyledParagraph>{fieldValue}</StyledParagraph>
              </StyledInfoContainer>
            ))}
          </div>
        </ContainerWithCounter>
        <InnerBottomContainer>
          <TitleHeading
            css={(theme) => css`
              color: ${theme.primaryColor};
              margin-bottom: 0;
            `}>
            {languageDictionaryStatus ? languageDictionary["para_bank_transfer_instruction"] : "TRACKING NUMBER:"}
          </TitleHeading>
          <TitleHeading
            css={css`
              color: #0fcc98;
              margin-left: 5px;
              margin-bottom: 0;
            `}>
            {paymentGatewayRef}
          </TitleHeading>
        </InnerBottomContainer>

        <div css={buttonWrapper}>
          <CustomButton
            buttonText={languageDictionaryStatus ? languageDictionary["l_i_will_pay_later"] : "I will pay later"}
            buttonWidth="95%"
            marginLeft="10px"
            onClick={handlePayLater}
          />
          {showPayModal === true ? (
            <AriaModal
              titleText="I have paid"
              onExit={() => updatePaidModal(false)}
              getApplicationNode={getApplicationNode}
              verticallyCenter={true}
              underlayStyle={{
                backdropFilter: "blur(28px)",
                background: "none"
              }}>
              <ContentContainer>
                <div className="mb-5 h1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="#0fcc98"
                    width="100px">
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </div>
                <MainHeading>
                  {languageDictionaryStatus
                    ? languageDictionary["para_we_are_waiting_for_your_payment"]
                    : "We are waiting for your payment."}
                </MainHeading>
                <SubHeading>
                  {languageDictionaryStatus
                    ? languageDictionary["para_pay_later_content_wallet"]
                    : "Once you initate the payment and we receive the money it will be deposited on your wallet account. You will get a confirmation through email or sms."}
                </SubHeading>
                <div css={buttonModalWrapper}>
                  <CustomButton
                    buttonText={
                      languageDictionaryStatus ? languageDictionary["l_pay_now"] : "Pay now"
                    }
                    buttonWidth="25%"
                    onClick={() => updatePayModal(false)}
                  />
                  <CustomButton
                    buttonText="Return to dashboard"
                    buttonWidth="25%"
                    onClick={redirectToHome}
                  />
                </div>
              </ContentContainer>
            </AriaModal>
          ) : null}

          {/* I have paid button */}
          <CustomButton
            buttonText={languageDictionaryStatus ? languageDictionary["l_i_have_paid"] : "I have paid"}
            buttonWidth="95%"
            onClick={handlePaidNow}
            marginLeft="10px"
          />

          {showPaidModal === true ? (
            <AriaModal
              titleText="I have paid"
              onExit={() => updatePaidModal(false)}
              getApplicationNode={getApplicationNode}
              verticallyCenter={true}
              underlayStyle={{
                backdropFilter: "blur(28px)",
                background: "none"
              }}>
              <ContentContainer>
                <div className="mb-5 h1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="#0fcc98"
                    width="100px">
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </div>
                <MainHeading>
                  {languageDictionaryStatus
                    ? languageDictionary["l_we_are_checking_the_details_of_your_payment"]
                    : "We are checking the details of your payment"}
                </MainHeading>
                <SubHeading>
                  {languageDictionaryStatus
                    ? languageDictionary["para_what_happens_after_we_receive_your_payment"]
                    : "What happens after we receive your payment"}
                </SubHeading>
                <SubHeading>
                  {languageDictionaryStatus
                    ? languageDictionary["para_after_we_receive_your_money"] + ", "
                    : "After we receive your money,"}
                  <MoneyText>
                    {sendingAmount && Number(sendingAmount).toFixed(2)} {currency}
                  </MoneyText>{" "}
                  {languageDictionaryStatus
                    ? languageDictionary["para_will_be_transferred_to"] + " "
                    : "will be transferred to"}
                  your beneficiary.
                </SubHeading>
                <div css={buttonModalWrapper}>
                  <CustomButton
                    buttonText={
                      languageDictionaryStatus
                        ? languageDictionary["m_transactions"]
                        : "Transfer History"
                    }
                    buttonWidth="25%"
                    onClick={redirectToTransfer}
                  />
                  <CustomButton
                    buttonText="Return to dashboard"
                    buttonWidth="25%"
                    onClick={redirectToHome}
                  />
                </div>
              </ContentContainer>
            </AriaModal>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default BankTransfer;

// eslint-disable-next-line no-unused-vars
import React from "react";
/** @jsx jsx */
/** @jsxRuntime classic */

import { jsx } from "@emotion/react";
import { paymentsTableStyle } from "./payStyle";

const TableRow = ({ tableData }) => {
  const { payment_status, date, payment_method } = tableData;

  // payment type
  const paymentTypeRender =
    payment_method === "ONLINE_CARD_PAYMENT"
      ? "Online Card Payment"
      : payment_method === "E_WALLET"
      ? "Wallet"
      : payment_method === "BANK_TRANSFER"
      ? "Bank Transfer"
      : payment_method;

  // payment status
  const paymentStatusRender =
    payment_status === "NOT_PROCESSED"
      ? "NOT PROCESSED"
      : payment_status === "ALREADY_PAID"
      ? "ALREADY PAID"
      : payment_status;
  return (
    <tr>
      <td>
        <span>{date || "-"}</span>
      </td>
      <td>
        <span>{paymentTypeRender || "-"}</span>
      </td>
      <td>
        <span>{"-"}</span>
      </td>
      <td>
        <span>{"-"}</span>
      </td>
      <td>
        <span>{paymentStatusRender || "-"}</span>
      </td>
    </tr>
  );
};

const PaymentTable = ({ data }) => {
  return (
    <div css={paymentsTableStyle}>
      <table>
        <thead>
          <tr>
            <th scope="col">Date</th>
            <th scope="col">Payment method</th>
            <th scope="col">api provider</th>
            <th scope="col">payment reference</th>
            <th scope="col">payment success</th>
          </tr>
        </thead>
        <tbody>
          <TableRow tableData={data} />
        </tbody>
      </table>
    </div>
  );
};

export default PaymentTable;

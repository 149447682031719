import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const uploadContainer = css`
  margin-top: 30px;
`;

export const uploadLabel = (theme) => css`
  display: flex;
  flex-direction: column;
  color: ${theme.primaryColor};
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
`;

export const InputTitle = styled.p`
  font-size: 1rem;
  font-weight: 500;
  color: ${(props) => props.theme.primaryColor};
`;

export const uploadContainerHeader = css`
  border-bottom: 1px solid #90a8be6b;
  padding-bottom: 15px;
  margin-bottom: 15px;
`;

export const noInvoiceText = css`
  color: #90a8be;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 30px;
`;

export const attachButtonContainer = css`
  display: flex;
  justify-content: flex-end;
`;

export const modalContainer = css`
  background: white;
  border: 1px solid #1149835b;
  min-height: 70vh;
  max-height: 80vh;
  border-radius: 20px;
  display: flex;
  padding: 25px 30px;
  width: 50vw;
  flex-direction: column;
  overflow-y: auto;

  @media (max-width: 56.25rem) {
    width: 100%;
    padding: 1rem;
  }
`;

export const modalContainerHeading = css`
  font-size: 20px;
  color: #90a8be;
  font-weight: 400;
  padding-bottom: 10px;
  margin-bottom: 15px;
  border-bottom: 1px solid #1149835b;
`;

export const modalFormContainer = css``;

export const modalFormRow = css`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  align-items: flex-end;

  @media (max-width: 56.25rem) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const modalFormColumn = css`
  flex-basis: 47.5%;
  max-width: 47.5%;

  @media (max-width: 56.25rem) {
    max-width: 100%;
    flex-basis: 100%;
  }
`;

export const modalFormDatePicker = css`
  margin-top: 12px;
  background: #e3edf3;
  border: 0;
  width: 100%;
  padding: 15px 10px;
  border-radius: 4px;
  height: 48px;
  color: var(--primary-color);
  font-size: 15px;
  font-weight: 300;

  @media (max-width: 56.25rem) {
    width: 89vw !important;
  }
`;

export const fileUploadLabel = css`
  width: 100%;
`;

export const fileUploadInput = css`
  overflow: visible;
  width: 100%;
  border: 0;
  background: #e3edf3;
  border-radius: 5px;
  padding: 15px 20px;
  font-size: 16px;
  font-weight: 400;
  color: #14a3de;
  font-weight: 300;
  margin-top: 12px;
`;

export const modalFormButton = css`
  margin-top: 12px;
`;

export const tableStyle = (theme) => {
  return css`
    overflow-x: auto;
    margin-bottom: 30px;

    table {
      border-collapse: separate;
      border-spacing: 0 15px;
      width: 100%;
      border: 1px solid #e2e8ec;
      border-radius: 5px;
      background: var(--background-color);
    }

    th {
      color: var(--text-color);
      font-weight: 600;
      text-transform: uppercase;
      font-size: 12px;
      padding-top: 5px;
      padding-bottom: 5px;
      padding-left: 10px;
      padding-right: 10px;
    }

    td {
      text-align: center;
    }

    tbody {
      td {
        background: white;

        span {
          display: block;
          margin-top: 24.5px;
          margin-bottom: 24.5px;
          border-right: 2px solid #90a8be47;
          font-size: 14px;
          color: ${theme.primaryColor};
          font-weight: 500;
          padding-left: 10px;
          padding-right: 10px;
        }

        &:nth-of-type(1) {
          span {
            font-weight: 400;
          }
        }

        &:last-of-type {
          span {
            /* color: #90a8be; */
          }
        }

        &:last-of-type {
          span {
            border-right: none;
          }
        }
      }
    }
  `;
};

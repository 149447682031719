import styled from "@emotion/styled";


export const NextButton = styled.button`
  font-size: 1rem;
`;

export const Card = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  word-wrap: break-word;
  border-radius: 0.25rem;
  padding: 1.5rem;
  border: 1px solid #0055ad;
`;
import { css } from "@emotion/react";
import { containerStyle } from "Assets/styles/global/base";

export const styleSheet = (theme) => {
  return css`
    min-height: 100vh;
    background: var(--background-color);
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .main-section {
      flex: 1;
      position: relative;
    }

    .canvas-img {
      position: absolute;
      right: -11vw;
      width: 30vw;
      z-index: -1;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .heading-wrapper {
      ${containerStyle};
      padding-top: 50px;
      @media (max-width: 56.25rem) {
        padding: 1rem;
        font-size: 1rem;
      }
    }

    .body-section {
      ${containerStyle};
      padding-bottom: 60px;
    }

    .top-section {
      display: flex;
      padding-top: 30px;

      @media (max-width: 56.25rem) {
        flex-direction: column;
      }
    }

    .top-section--leftSide {
      @media (max-width: 56.25rem) {
        margin: auto;
      }
      .first-circle {
        border: 1px solid #0fcc9838;
        height: 312px;
        width: 312px;
        border-radius: 50%;
        display: flex;
      }

      .second-circle {
        border: 1px solid #3796f680;
        height: 264px;
        width: 264px;
        border-radius: 50%;
        margin: auto;
        display: flex;
      }

      .third-circle {
        border: 1px solid ${theme.primaryColor};
        height: 212px;
        width: 212px;
        border-radius: 50%;
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }

      .circle-text {
        color: #90a8be;
        font-size: 18px;
        line-height: 1.5;
        text-align: center;
      }

      .circle-number {
        color: ${theme.primaryColor};
        font-size: 50px;
        font-weight: 600;
        line-height: 1.2;
      }
    }

    .top-section--rightSide {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 70px;
      flex: 1;

      @media (max-width: 56.25rem) {
        padding: 1rem;
        margin-top: 1rem;
      }

      button {
        height: 108px;
        width: 55%;
        background: ${theme.primaryColor};
        color: white;
        border: 0;
        box-shadow: 5px 5px 25px #0000002b;
        border-radius: 5px;
        margin-bottom: 30px;
        position: relative;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        @media (max-width: 56.25rem) {
          width: 100%;
        }

        &:last-of-type {
          margin-bottom: 0;
        }

        &::before {
          content: "";
          clip-path: circle(50% at 50% 50%);
          position: absolute;
          background: #3796f6;
          height: 80px;
          width: 80px;
          top: -55px;
          right: -15px;
          left: auto;
          bottom: auto;
        }

        &::after {
          content: "";
          clip-path: circle(50% at 50% 50%);
          position: absolute;
          height: 80px;
          width: 80px;
          bottom: -55px;
          left: -15px;
          right: auto;
          background: #0fcc98;
        }

        p {
          font-size: 16px;
          font-weight: 300;
        }

        span {
          display: flex;
          justify-content: center;
          align-items: center;

          &:first-of-type {
            flex-basis: 7%;
            margin-left: 10%;
            margin-right: 5%;
          }

          &:last-of-type {
            margin-left: auto;
            margin-right: 10%;
            flex-basis: 5%;
          }

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  `;
};

export const bottomSection = (theme) => {
  return css`
    ${containerStyle};
    padding-bottom: 30px;

    @media (max-width: 56.25rem) {
      padding: 1rem;
    }

    .table-controls {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media (max-width: 56.25rem) {
        flex-direction: column;
        align-items: unset;
      }
    }

    .table-controls--filters {
      display: flex;
      flex-basis: 33%;
      justify-content: space-between;
    }

    .table-controls--filterInput {
      flex-basis: 82%;
      max-width: 82%;

      input {
        width: 100%;
        margin-top: 12px;
        border: 0;
        background: #e3edf3;
        border-radius: 5px;
        padding: 15px 20px;
        font-size: 16px;
        font-weight: 400;
        color: #0055ad;
        font-weight: 300;

        &:focus {
          outline: #2684ff solid 1px;
        }
      }
    }

    .table-controls--filterSelect,
    .table-controls--sortSelect {
      flex-basis: 40%;
    }

    .table-controls--download {
      flex-basis: 15%;
      margin-top: 12px;
      background: #e3edf3;
      border-radius: 5px;
      display: flex;
      border: 0;
      // align-items: center;
      justify-content: center;

      img {
        height: 100%;
        width: 40%;
      }
    }

    .table-controls--text {
      font-size: 22px;
      color: ${theme.primaryColor};
      font-weight: 400;
    }
  `;
};

export const tableStyle = (theme) => {
  return css`
    margin: 30px 0;
    overflow-x: auto;

    table {
      border-collapse: separate;
      border-spacing: 0 15px;
      width: 100%;
      border: 1px solid #e2e8ec;
      border-radius: 5px;
      background: #ffffff;
    }

    th {
      color: #90a8be;
      font-weight: 600;
      text-transform: uppercase;
      font-size: 12px;
      padding-top: 5px;
      padding-bottom: 5px;
      padding-left: 30px;
      padding-right: 30px;

      
      &:nth-of-type(2) {
        width: 25%;
      }
      &:nth-of-type(4),&:nth-of-type(5) {
        width: 13%;
      }

      /* &:last-of-type {
        padding-left: 15px;
        padding-right: 15px;
        width: 5%;
      } */
    }

    td {
      text-align: center;
    }

    tbody {
      td {
        background: #e3edf3;

        span {
          display: block;
          margin-top: 24.5px;
          margin-bottom: 24.5px;
          border-right: 2px solid #90a8be47;
          font-size: 17px;
          color: ${theme.primaryColor};
          font-weight: 500;
          padding-left: 20px;
          padding-right: 20px;
          // word-break: break-all;

            &.green {
              color: #0fcc98;
              font-weight: 500;
            }
            &.red {
              color: #e50c37;
              font-weight: 500;
            }
            &.blue {
              color: #368fea;
              font-weight: 500;
            }
          }
        }

        &:nth-of-type(1) {
          span {
            font-weight: 400;
          }
        }
        &:nth-of-type(2) {
          span {
            font-weight: 500;
          }
        }
        &:nth-of-type(3) {
          span {
            color: #90a8be;
          }
        }

        /* &:nth-of-type(4) {
          span {
            color: ${theme.primaryColor};


        } */

        .table-row-controls {
          display: flex;
          justify-content: space-around;
          padding: 0 10px;
        }

        /* &:last-of-type {
          span {
            border-left: 2px solid #90a8be47;
            border-right: none;
          }

          & > span {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            span {
              height: 5px;
              width: 5px;
              background: #0055ad;
              border-radius: 50%;
              margin: 1px auto;
            }
          }
        } */
      }
    }
  `;
};

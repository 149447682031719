import { ActionCreator } from "../../Utils/Helpers";
import * as DynamicFields from "../constants/dynamicFields.constants";
import * as DynamicFieldsService from "../../Utils/Services/DynamicFields.service";


export const fetchDynamicFields = (formType) => async (dispatch) => {
  try {
    dispatch(ActionCreator(DynamicFields.GET_DYNAMIC_FIELDS_REQUEST));
    const { data: responseData } = await DynamicFieldsService.fetchDynamicFieldByFormType(formType);
    if (responseData.status === "FAILED") throw responseData;

    const fields = responseData['data'];
    fields.formType = formType;
    dispatch(ActionCreator(DynamicFields.GET_DYNAMIC_FIELDS_SUCCESS, fields));
  } catch (error) {
    dispatch(ActionCreator(DynamicFields.GET_DYNAMIC_FIELDS_FAILURE));
  }
};

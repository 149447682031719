// eslint-disable-next-line no-unused-vars
import React from 'react';
/** @jsx jsx */
/** @jsxRuntime classic */

import { jsx, css } from '@emotion/react';

const inputStyle = css`
  left: 0;
  opacity: 0;
  position: absolute;
  top: 3px;
  height: 16px;
  width: 18px;

  &:focus + label:before {
    box-shadow: 0 0 0 3px #0fcc98;
    outline: 3px solid transparent;
  }

  &:checked + label:after {
    opacity: 1;
  }
`;

const labelStyle = theme => css`
  cursor: pointer;
  &:before {
    content: '';
    left: 0;
    position: absolute;
    top: 3px;
    height: 16px;
    width: 18px;
    border: 1px solid ${theme.primaryColor};
    border-radius: 3px;
  }

  &:after {
    content: '';
    border: 1.5px solid ${theme.primaryColor};
    border-left: 0;
    border-top: 0;
    height: 9px;
    left: 7px;
    opacity: 0;
    position: absolute;
    top: 5px;
    width: 5px;
    transform: rotate(45deg);
    transition: opacity 0.2s ease-in-out;
  }
`;

const CheckBox = ({
  content,
  checkboxId,
  link,
  checkboxName,
  checkboxValue,
  checkboxOnChange
}) => {
  return (
    <div
      css={css`
        position: relative;
        padding-left: 1rem;
      `}
    >
      <input
        type="checkbox"
        name={checkboxName}
        id={checkboxId}
        css={inputStyle}
        checked={checkboxValue}
        onChange={checkboxOnChange}
      />
      <label htmlFor={checkboxId} css={labelStyle}>
        {null}
      </label>
      {content}
    </div>
  );
};

export default CheckBox;

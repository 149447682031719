import { ActionCreator } from "../../Utils/Helpers";
import * as CashPickUpConstants from "../constants/cash-pickup.constants";
import { fetchSupportedCountriesByDeliveryMethod } from "../../Utils/Services/Country.service";


export const getCashPickupCountryList = (originCountry) => async (dispatch) => {
  try {
    dispatch(ActionCreator(CashPickUpConstants.FETCH_COUNTRY_LIST_REQUEST));
    const { data: responseData } = await fetchSupportedCountriesByDeliveryMethod(originCountry, 'CASHPICKUP');

    if (responseData.status === "FAILED") {
      throw responseData;
    }

    const countries = responseData['data']['countries'];
    dispatch(ActionCreator(CashPickUpConstants.FETCH_COUNTRY_LIST_SUCCESS, countries));
  } catch (error) {
    dispatch(ActionCreator(CashPickUpConstants.FETCH_COUNTRY_LIST_FAILURE));
  }
};

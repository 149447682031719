import * as Airtime from "Store/constants/airtime.constants";

const initialState = {
  countries: [],
  loading: false,
  loadingStatus: "",
  preTopupLoadingStatus: "",
  quoteLoadingStatus: "",
  preTopupData: {},
  quoteData: {},
  paymentInfo: {},
  paymentMethod: {},
  paymentMethodStatus: "",
  paymentStatus: "",
};

const airtimeReducer = (state = initialState, action) => {
  switch (action.type) {
    case Airtime.FETCH_COUNTRY_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        loadingStatus: "FETCHING",
      };

    case Airtime.FETCH_COUNTRY_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        loadingStatus: "SUCCESS",
        countries: action.payload,
      };

    case Airtime.FETCH_COUNTRY_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        loadingStatus: "ERROR",
      };
    case Airtime.FETCH_PRETOPUP_REQUEST:
      return {
        ...state,
        loading: true,
        preTopupLoadingStatus: "FETCHING",
      };

    case Airtime.FETCH_PRETOPUP_SUCCESS:
      return {
        ...state,
        loading: false,
        preTopupLoadingStatus: "SUCCESS",
        preTopupData: action.payload,
      };
    case Airtime.FETCH_PRETOPUP_FAILURE:
      return {
        ...state,
        loading: false,
        preTopupLoadingStatus: "ERROR",
      };
    case Airtime.FETCH_QUOTE_FOR_AIRTIME_TOPUP_REQUEST:
      return {
        ...state,
        loading: true,
        quoteLoadingStatus: "FETCHING",
      };
    case Airtime.FETCH_QUOTE_FOR_AIRTIME_TOPUP_SUCCESS:
      return {
        ...state,
        loading: false,
        quoteLoadingStatus: "SUCCESS",
        quoteData: action.payload,
      };
    case Airtime.FETCH_QUOTE_FOR_AIRTIME_TOPUP_FAILURE:
      return {
        ...state,
        loading: false,
        quoteLoadingStatus: "ERROR",
      };
    case Airtime.INITIATE_AIRTIME_PAYMENT_REQUEST:
      return {
        ...state,
        loading: true,
        loadingStatus: "FETCHING",
      };
    case Airtime.INITIATE_AIRTIME_PAYMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        loadingStatus: "SUCCESS",
        paymentInfo: action.payload,
      };
    case Airtime.INITIATE_AIRTIME_PAYMENT_FAILURE:
      return {
        ...state,
        loadingStatus: "ERROR",
      };
    case Airtime.LIST_AIRTIME_PAYMENT_METHODS_REQUEST:
      return {
        ...state,
        paymentMethodStatus: "FETCHING",
        paymentMethod: {},
      };
    case Airtime.LIST_AIRTIME_PAYMENT_METHODS_SUCCESS:
      return {
        ...state,
        paymentMethodStatus: "SUCCESS",
        paymentMethod: action.payload,
      };
    case Airtime.LIST_AIRTIME_PAYMENT_METHODS_FAILURE:
      return {
        ...state,
        paymentMethodStatus: "ERROR",
      };
    default:
      return state;
  }
};

export default airtimeReducer;

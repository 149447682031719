import { css } from '@emotion/react';

export const rightSection = () => css`
  background: var(--navbar-color);
  display: flex;
  position: relative;
  overflow: hidden;
  min-height: 310px;
  flex: 1;

  @media (min-width: 769px) {
    flex-basis: 50%;
    max-width: 50%;
    height: auto;
  }
`;

export const bannerText = css`
  margin: auto;
  padding-left: 2rem;
  p {
    color: var(--navbar-text-color);
    font-weight: 500;
    font-size: 19px;
    text-align: center;

    @media (min-width: 769px) {
      font-size: 35px;
    }

    &:nth-of-type(2) {
      font-size: 32px;
      @media (min-width: 769px) {
        font-size: 60px;
      }
    }

    &:nth-of-type(3) {
      font-weight: 700;
      color: var(--navbar-text-color);
    }
  }
`;

export const topRightCircle = css`
  position: absolute;
  border-radius: 50%;
  right: -10%;
  border: 2px solid white;
  padding: 1.5%;
  height: 132px;
  width: 132px;
  top: -25%;

  @media (min-width: 769px) {
    height: 220px;
    width: 243px;
  }

  @media (min-width: 1400px) {
    top: -18%;
  }

  @media (min-width: 1600px) {
    top: -14%;
  }

  div {
    background: #0fcc98;
    height: 100%;
    width: 100%;
    border-radius: 50%;
  }
`;

export const bottomRightCircle = css`
  position: absolute;
  border-radius: 50%;
  right: -10%;
  border: 2px solid white;
  padding: 1.5%;
  height: 132px;
  width: 132px;
  bottom: -25%;

  @media (min-width: 769px) {
    height: 220px;
    width: 243px;
  }

  @media (min-width: 1400px) {
    bottom: -18%;
  }

  @media (min-width: 1600px) {
    bottom: -14%;
  }

  div {
    background: #3796f6;
    height: 100%;
    width: 100%;
    border-radius: 50%;
  }
`;

export const bottomImgDark = (theme) => css`
  position: absolute;
  height: 169px;
  bottom: -70px;
  left: -310px;

  @media (min-width: 769px) {
    bottom: -130px;
    left: -313px;
    height: 353px;
  }

  svg {
    height: 100%;
    width: 100%;

    path.a {
      stroke: ${theme.primaryColor};
    }
  }
`;

export const circleOne = css`
  position: absolute;
  top: 20%;
  left: 20%;
  border: 2px solid #ffffff4d;
  border-radius: 50%;
  height: 16px;
  width: 16px;

  @media (min-width: 769px) {
    height: 50px;
    width: 50px;
  }
`;

export const circleTwo = css`
  position: absolute;
  top: 40%;
  right: -2%;
  border: 2px solid #ffffff4d;
  border-radius: 50%;
  height: 16px;
  width: 16px;

  @media (min-width: 769px) {
    height: 50px;
    width: 50px;
  }
`;

export const circleThree = css`
  position: absolute;
  bottom: 23%;
  right: 20%;
  border: 2px solid #ffffff4d;
  border-radius: 50%;
  height: 16px;
  width: 16px;

  @media (min-width: 769px) {
    height: 50px;
    width: 50px;
  }
`;

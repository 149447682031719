import * as Beneficiary from 'Store/constants/beneficiary.constants';
import * as Transfer from "Store/constants/transfer.constants";
import * as Loading from "Store/constants/loadingState.constants";

const initialState = {
  list: [],
  loading: false,
  createLoading: false,
  dropDownList: [],
  loadingStatus: "",
  supportedBanks: [],
  supportedBanksStatus: "",
  beneficiaryDetails: {},
  beneficiaryDetailsStatus: "",
  beneficiaryBankInfo: [],
  beneficiaryBankInfoStatus: ""
};

const formatForDropdown = (payloadData) => {
  return payloadData.map((item) => ({
    ...item,
    value: item.beneficiaryCode,
    label: `${item.firstName} ${item.lastName}`
  }));
};

const formatSupportedBanksDropdown = (payloadData) => {
  return payloadData.map((item) => ({
    ...item,
    value: item.id,
    label: item.name || item.bankName
  }));
};

const removeEmpty = (obj) => {
  Object.keys(obj).forEach((key) => (obj[key] === null || obj[key] === "") && delete obj[key]);
};

const beneficiaryReducer = (state = initialState, action) => {
  switch (action.type) {
    case Beneficiary.FETCH_BENEFICIARY_REQUEST:
      return {
        ...state,
        loading: true,
        loadingStatus: Loading.FETCHING
      };

    case Beneficiary.FETCH_BENEFICIARY_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload,
        dropDownList: formatForDropdown(action.payload),
        loadingStatus: Loading.SUCCESS
      };

    case Beneficiary.FETCH_BENEFICIARY_FAILURE:
      return {
        ...state,
        list: [],
        loading: false,
        loadingStatus: Loading.ERROR
      };
    case Transfer.FETCH_BENEFICIARY_BANK_INFO_REQUEST: {
      return {
        ...state,
        beneficiaryBankInfoStatus: Loading.FETCHING,
        beneficiaryBankInfo: []
      };
    }

    case Transfer.FETCH_BENEFICIARY_BANK_INFO_SUCCESS: {
      const bankInfo = [...action.payload];
      bankInfo.forEach((item) => removeEmpty(item));

      return {
        ...state,
        beneficiaryBankInfoStatus: Loading.SUCCESS,
        beneficiaryBankInfo: formatSupportedBanksDropdown(bankInfo)
      };
    }

    case Transfer.FETCH_BENEFICIARY_BANK_INFO_FAILURE: {
      return {
        ...state,
        beneficiaryBankInfoStatus: Loading.ERROR
      };
    }

    case Beneficiary.CREATE_BENEFICIARY_REQUEST:
      return {
        ...state,
        createLoading: true
      };

    case Beneficiary.CREATE_BENEFICIARY_SUCCESS:
    case Beneficiary.CREATE_BENEFICIARY_FAILURE:
      return {
        ...state,
        createLoading: false
      };

    case Beneficiary.UPDATE_BENEFICIARY_REQUEST:
      return {
        ...state,
        updateLoading: Loading.FETCHING
      };

    case Beneficiary.UPDATE_BENEFICIARY_SUCCESS:
      return {
        ...state,
        updateLoading: Loading.SUCCESS
      };

    case Beneficiary.UPDATE_BENEFICIARY_FAILURE:
      return {
        ...state,
        updateLoading: Loading.ERROR
      };

    case Beneficiary.FETCH_SUPPORTED_BANKS_REQUEST:
      return {
        ...state,
        supportedBanks: action.payload,
        supportedBanksStatus: Loading.FETCHING
      };

    case Beneficiary.FETCH_SUPPORTED_BANKS_SUCCESS:
      return {
        ...state,
        supportedBanks: formatSupportedBanksDropdown(action.payload),
        supportedBanksStatus: Loading.SUCCESS
      };

    case Beneficiary.FETCH_SUPPORTED_BANKS_FAILURE:
      return {
        ...state,
        supportedBanks: [],
        supportedBanksStatus: Loading.ERROR
      };

    case Beneficiary.FETCH_BENEFICIARY_DETAILS_REQUEST:
      return {
        ...state,
        beneficiaryDetails: {},
        beneficiaryDetailsStatus: Loading.FETCHING
      };

    case Beneficiary.FETCH_BENEFICIARY_DETAILS_SUCCESS:
      return {
        ...state,
        beneficiaryDetails: action.payload,
        beneficiaryDetailsStatus: Loading.SUCCESS
      };

    case Beneficiary.FETCH_BENEFICIARY_DETAILS_FAILURE:
      return {
        ...state,
        beneficiaryDetails: {},
        beneficiaryDetailsStatus: Loading.ERROR
      };

    default:
      return state;
  }
};

export default beneficiaryReducer;

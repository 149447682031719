import React from 'react';
import styled from '@emotion/styled';
import Logo from 'Assets/images/kogopay.png';

const MainBody = styled.div`
  border: 1px solid #e3edf3;
  border-radius: 10px;
  padding: 45px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  background: white;

  @media ( max-width: 56.25rem ) {
    padding: 0.5rem;
    flex-direction: column;
  }
`;

const LogoStyle = styled.div`
    display: flex;
    align-items: center;
    text-decoration: none;
    width: 150px;
    height: 60px;
    align-self: center;
    background: white;

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
`;


const ColumnBody = styled.div`
  flex-basis: 40.5%;
  max-width: 40.5%;
  display: flex;
  justify-content: space-between;

  @media ( max-width: 56.25rem ) {
    max-width: 100%;
    flex-direction: column;
  }
`;



const MainItem = styled.div`
  flex-basis: 40.5%;
  max-width: 40.5%;

  @media ( max-width: 56.25rem ) {
    max-width: 100%;
  }

  &:nth-of-type(odd) {
    flex-basis: 55.5%;
    max-width: 55.5%;

    @media ( max-width: 56.25rem ) {
      max-width: 100%;
      flex-basis: 100%;
    }
  }

  &:nth-of-type(1),
  &:nth-of-type(2) {
    margin-bottom: 20px;
  }

  h3 {
    font-size: 18px;
    font-weight: 500;
    color: ${(props) => props.theme.primaryColor};
    line-height: 1.6;
    
  }

  h2 {
    font-size: 20px;
    font-weight: 500;
    border-buttom: 2px solid red;
    color: ${(props) => props.theme.primaryColor};
    line-height: 1.6;
    
  }

  p {
    font-size: 20px;
    font-weight: 500;
    line-height: 1.7;
    color: #000000AF;

    @media ( max-width: 56.25rem ){
      font-size: 1rem;
    }
  }
  
`;

// const formatNum = (num) =>
//   num
//     ? Number(num).toLocaleString(undefined, {
//         minimumFractionDigits: 2,
//         maximumFractionDigits: 2,
//       })
//     : '0.00';

const FxCard = ({
  currencySymbol,
  fxRate,
  tax,
  totalAmount,
  openingBalance,
  paymentFee,
}) => {
  return (
    <MainBody>
        <MainItem>
          <LogoStyle>
            <img src={Logo} width={"100px"} height="50px" alt="logo" />
          </LogoStyle>
      </MainItem>
      <MainItem>
        <h2>Customer service information</h2>
      </MainItem>

    <ColumnBody>
      <MainItem>
        <h3>Cathy Walter</h3>
        <p>
          {"No 25, Engineer John Street, Lagos"}
        </p>
      </MainItem>
    </ColumnBody>

      <ColumnBody>
          <MainItem>
            <h3>Phone Number</h3>
            <p>
              {"08100991054"}
            </p>
          </MainItem>
          <MainItem>
            <h3>Website</h3>
            <p>
              {"Visit Site"}
            </p>
          </MainItem>
          <MainItem>
            <h3>Address</h3>
            <p>
            {"No 25, Engineer John Street, Lagos"}
            </p>
          </MainItem>
      </ColumnBody>
    </MainBody>
  );
};

export default FxCard;

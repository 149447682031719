import React from "react";
import styled from "@emotion/styled";
import cookieHelper from "../../Utils/Helpers/cookieHelper";
import config from "../../Utils/Helpers/config";
import { useHistory } from "react-router-dom";

export const Card = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 80px;
  word-wrap: break-word;
  background-color: #e3edf3;
  border-radius: 0.25rem;
  padding: 0.5rem;

  @media screen and (max-width: 1000px) {
    height: 95px;
  }
`;

const P = styled.p`
  line-height: 1;
  font-size: 0.825rem;
  color: ${(props) => props.theme.primaryColor};
`;

const CountryFlagCard = ({ link, country, params, flag, text }) => {
  let history = useHistory();

  const getDetails = (link, country) => {
    cookieHelper.set(config.PARAMS_COUNTRY, country, config.COOKIE_EXPIRY_PERIOD);
    history.push(link);
  }

  flag = flag.replace("https://", "http://");
  return (
    <div className="col-4 col-md-2 mb-3">
      <Card>
          <div role="button" onClick={() => getDetails(link, params)} onKeyDown={getDetails} className="text-decoration-none" aria-hidden="true">
            <div className="mx-auto text-center">
              <img src={flag} alt={text} />
              <P className="mt-2 mb-0 card-text">{country}</P>
            </div>
          </div>
      </Card>
    </div>
  );
};

export default CountryFlagCard;

// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
/** @jsx jsx */
/** @jsxRuntime classic */

import { jsx, css } from "@emotion/react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";

import HeadingText from "Components/HeadingText";
import FlexibleButton from "Components/FlexibleButton";
import usePagination from "Components/Pagination/usePagination";
import Pagination from "Components/Pagination";
import customStyles from "Assets/styles/dropdown/customStyles";
import ViewTable from "./ViewTable";
import DropDownComponent from "Components/DropDownComponent";
import downArrow from "Assets/svg/blue-drop-down-arrow.svg";

import { fetchNotifications } from "Store/actions/notification.action";
import * as Loading from "Store/constants/loadingState.constants";

const notificationListOptions = [
  { value: "ALL", label: "All" },
  { value: "READ", label: "Read" },
  { value: "SENT", label: "Sent" },
  { value: "UNREAD", label: "Unread" }
];

const Notifications = () => {
  const [listStatus, updateListStatus] = useState(notificationListOptions[0]);
  const history = useHistory();
  const dispatch = useDispatch();
  const { notification, profile, theme, domainBrand } = useSelector(
    ({ notificationReducer, profileReducer, themeReducer, domainBrandReducer }) => ({
      notification: notificationReducer,
      profile: profileReducer,
      theme: themeReducer,
      domainBrand: domainBrandReducer
    })
  );

  const languageDictionary = domainBrand.domainBrand["languageDictionary"];
  const languageDictionaryStatus = !(typeof languageDictionary === "undefined" || languageDictionary === null);

  useEffect(() => {
    if (profile.userProfileLoading === Loading.SUCCESS) {
      dispatch(fetchNotifications(listStatus.value));
    }
  }, [dispatch, listStatus.value]);

  const collection = notification.list;
  const PAGE_LIMIT = 5;
  const [currentData, onPageChange] = usePagination({ collection, pageLimit: PAGE_LIMIT });

  return (
    <div>
      <Helmet>
        <title>
          {languageDictionaryStatus ? languageDictionary["WO_CONTACT_SUPPOR_-1552316922"] : "Contact Support"}{" "}
        </title>
      </Helmet>
      <div
        css={css`
          display: flex;
          justify-content: space-between;
          align-items: center;
        `}>
        <HeadingText
          text={languageDictionaryStatus ? languageDictionary["WO_CONTACT_SUPPOR_-1552316922"] : "Contact Support"}
        />
        <FlexibleButton
          buttonText={languageDictionaryStatus ? languageDictionary["WO_COMPOSE_MESSAG_-1485040865"] : "Compose Message"}
          paddingVertical="11px"
          paddingHorizontal="12px"
          fontSize="13px"
          onClick={() => history.push("/profile/notifications/compose")}
        />
      </div>
      <div
        css={css`
          display: flex;
          align-items: center;
          justify-content: flex-end;
          margin-top: 15px;

          @media (max-width: 56.25rem) {
            justify-content: flex-start;
          }
        `}>
        <p
          css={css`
            margin-top: 12px;
            margin-right: 12px;
            color: #14a3de;
          `}>
          Sort by:
        </p>
        <div
          css={css`
            flex-basis: 35%;
            max-width: 35%;
          `}>
          <Select
            value={listStatus}
            options={notificationListOptions}
            styles={customStyles}
            isClearable={false}
            isSearchable={false}
            customTheme={theme}
            onChange={updateListStatus}
            components={{
              IndicatorSeparator: null,
              DropdownIndicator: () => <DropDownComponent iconSource={downArrow} />
            }}
          />
        </div>
      </div>
      <ViewTable notifications={currentData} />
      <Pagination
        resultName="Number of messages"
        onPageChange={onPageChange}
        pageLimit={PAGE_LIMIT}
        totalRecords={collection.length}
      />
    </div>
  );
};

export default Notifications;

import React, { PureComponent } from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';


export default class ChartBar extends PureComponent {


  render() {

    return (
      <div style={{ width: "100%", height: 320 }}>
        <ResponsiveContainer>
          <BarChart
            height={300}
            data={this.props?.data}
            margin={{
              top: 5,
              right: 10,
              left: 0,
              bottom: 5,
            }}>
            <XAxis dataKey="month" />
            <YAxis />
            <Tooltip />
            <Legend verticalAlign="top" height={36} />
            <Bar name="Number of Transactions" dataKey="numberOfTransactions" fill="#14A3DE" />
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  }
}

import { ActionCreator } from "Utils/Helpers";
import * as Airtime from "Store/constants/airtime.constants";
import * as AirtimeService from "Utils/Services/Airtime.service";
import { fetchSupportedCountriesByDeliveryMethod } from "Utils/Services/Country.service";
import { expiredTokenCheck } from "./auth.action";

export const getCountryList = (originCountry) => async (dispatch) => {
  try {
    dispatch(ActionCreator(Airtime.FETCH_COUNTRY_LIST_REQUEST));
    const { data: responseData } = await fetchSupportedCountriesByDeliveryMethod(originCountry, 'AIRTIME_TOPUP');

    if (responseData.status === "FAILED") {
      throw responseData;
    }

    const {
      data: { countries },
    } = responseData;
    dispatch(ActionCreator(Airtime.FETCH_COUNTRY_LIST_SUCCESS, countries));
  } catch (error) {
    expiredTokenCheck(error, dispatch);
    dispatch(ActionCreator(Airtime.FETCH_COUNTRY_LIST_FAILURE));
  }
};

export const getPreTopup = (phoneNumber, destinationCountryIso3) => async (dispatch) => {
  try {
    dispatch(ActionCreator(Airtime.FETCH_PRETOPUP_REQUEST));
    const { data: responseData } = await AirtimeService.fetchPreTopup(
      phoneNumber,
      destinationCountryIso3
    );

    if (responseData.status === "FAILED") {
      throw responseData;
    }

    const { data } = responseData;
    dispatch(ActionCreator(Airtime.FETCH_PRETOPUP_SUCCESS, data));
  } catch (error) {
    expiredTokenCheck(error, dispatch);
    dispatch(ActionCreator(Airtime.FETCH_PRETOPUP_FAILURE));
  }
};

export const getQuoteForAirtimeTopup = (payload) => async (dispatch) => {
  try {
    dispatch(ActionCreator(Airtime.FETCH_QUOTE_FOR_AIRTIME_TOPUP_REQUEST));
    const { data: responseData } = await AirtimeService.fetchQuoteForAirtimeTopup(payload);
    if (responseData.status === "FAILED") {
      throw responseData;
    }
    const { data } = responseData;

    dispatch(ActionCreator(Airtime.FETCH_QUOTE_FOR_AIRTIME_TOPUP_SUCCESS, data));
  } catch (error) {
    expiredTokenCheck(error, dispatch);

    dispatch(ActionCreator(Airtime.FETCH_QUOTE_FOR_AIRTIME_TOPUP_FAILURE));
    console.error(error);
  }
};

export const initiateAirtimePayment = (payload) => async (dispatch) => {
  try {
    dispatch(ActionCreator(Airtime.INITIATE_AIRTIME_PAYMENT_REQUEST));
    const { data: responseData } = await AirtimeService.initiateAirtimePayment(payload);
    if (responseData.status === "FAILED") {
      throw responseData;
    }
    const { data } = responseData;

    dispatch(ActionCreator(Airtime.INITIATE_AIRTIME_PAYMENT_SUCCESS, data));
  } catch (error) {
    expiredTokenCheck(error, dispatch);

    dispatch(ActionCreator(Airtime.INITIATE_AIRTIME_PAYMENT_FAILURE));
    console.error(error);
  }
};

export const fetchAirtimePaymentMethods = (payload) => async (dispatch) => {
  try {
    dispatch(ActionCreator(Airtime.LIST_AIRTIME_PAYMENT_METHODS_REQUEST));
    const { data: responseData } = await AirtimeService.getAirtimePaymentMethods(payload);
    if (responseData.status === "FAILED") {
      throw responseData;
    }

    dispatch(ActionCreator(Airtime.LIST_AIRTIME_PAYMENT_METHODS_SUCCESS, responseData.data));
  } catch (error) {
    expiredTokenCheck(error, dispatch);

    dispatch(ActionCreator(Airtime.LIST_AIRTIME_PAYMENT_METHODS_FAILURE));
    console.error(error);
  }
};

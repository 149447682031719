import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useFormik } from "formik";
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
import * as Yup from "yup";
import { useLocation, useHistory } from "react-router-dom";

import NavBar from "Components/Navbar";
import Footer from "Components/Footer";
import "./index.scss";
import { customStyles } from "./dropDownStyles";
import ButtonSpinner from "Components/ButtonSpinner";
import DropDownComponent from "Components/DropDownComponent";
import CustomInput from "Components/CustomInput";
import Spinner from "Components/Spinner";
import ErrorField from "Components/ErrorField";

import { getAllCountries } from "Store/actions/country.action";
import { createBeneficiary, getSupportedBanks } from "Store/actions/beneficiary.action";
import * as Loading from "Store/constants/loadingState.constants";
import { relationshipOptions, fieldNames, typeOptions } from "Utils/constants";
import { fetchRequiredBankFields, fetchRequiredBankFieldsByFilter } from "Store/actions/transfer.action";
import { objCreator } from "../sharedUtils";
import BackArrow from "Components/BackArrow";
import { fetchDynamicFields } from "../../../../Store/actions/dynamicFields.action";
import DynamicFields from "../../../../Components/DynamicFields";
import { dynamicFieldsFormikGetKeys } from "../../../../Utils/Helpers/general";

const BeneficiaryCreation = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  let allDynamicFields;

  const { beneficiarySelector, countrySelector, transfers, theme, domainBrand, dynamicFields } = useSelector(
    ({
       beneficiaryReducer,
       countryReducer,
       transferReducer,
       themeReducer,
       domainBrandReducer,
       dynamicFieldsReducer
     }) => ({
      beneficiarySelector: beneficiaryReducer,
      transfers: transferReducer,
      countrySelector: {
        countryDropdown: countryReducer.countryDropdownList,
        countryCodeDropdown: countryReducer.countryCodeDropdownList,
        currencyDropdownList: countryReducer.currencyDropdownList,
        loading: countryReducer.loading
      },
      theme: themeReducer,
      domainBrand: domainBrandReducer,
      dynamicFields: dynamicFieldsReducer
    })
  );

  const languageDictionary = domainBrand.domainBrand["languageDictionary"];
  const languageDictionaryStatus = !(typeof languageDictionary === "undefined" || languageDictionary === null);

  const submitRedirect = () => {
    let { from } = location.state || { from: { pathname: "/beneficiaries" } };
    const navigateMethod = () => history.replace(from);
    return navigateMethod;
  };

  const bankActionType = [
    { label: languageDictionaryStatus ? languageDictionary["para_country"] : "Country", value: "COUNTRY" },
    { label: languageDictionaryStatus ? languageDictionary["l_currency"] : "Currency", value: "CURRENCY" }
  ];

  const initialValidation = {
    benCountry: Yup.object({
      value: Yup.string().required("required")
    }),
    companyName: Yup.string().when("type", {
      is: (val) => val.value === "COMPANY",
      then: Yup.string().required("required")
    }),
    bankCountry: Yup.object().when("isChecked", {
      is: true,
      then: Yup.object({
        value: Yup.string().required("required")
      })
    }),
    firstName: Yup.string().when("type", {
      is: (val) => val.value === "INDIVIDUAL",
      then: Yup.string().required("required")
    }),
    lastName: Yup.string().when("type", {
      is: (val) => val.value === "INDIVIDUAL",
      then: Yup.string().required("required")
    })
  };

  const initialValuesData = {
    email: "",
    type: typeOptions[0],
    bankType: bankActionType[0],
    address1: "",
    companyName: "",
    regNumber: "",
    benCountry: "",
    relationship: "",
    bankCountry: "",
    bankCurrency: "",
    firstName: "",
    middleName: "",
    lastName: "",
    isChecked: false
  };
  const [validationSchema, updateValidationSchema] = useState(initialValidation);
  const [initialValues, updateInitialValues] = useState(initialValuesData);
  const [dynamicFormType, setDynamicFormType] = useState("REGISTERED_CUSTOMER_CREATE_BENE");

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    // validationSchema: Yup.object(validationSchema),
    onSubmit: (values) => {
      let isCreateBankDetails = values.isChecked;
      let bankDetails = {};
      let dynamicValues = dynamicFieldsFormikGetKeys(dynamicFields[dynamicFormType]["dynamicFields"], values);
      const userRequest = {
        ...values,
        dynamicFields: { ...dynamicValues },
        type: values.type.value,
        // address1: values.benCountry.label,
        countryCommonName: values.benCountry.label,
        country: values.benCountry.value,
      };

      const fields = transfers.bankField;
      if (isCreateBankDetails) {
        bankDetails = Object.entries(fields).reduce((acc, [key, value]) => {
          if (value === true) {
            const fieldName = key.replace(/required/i, "");

            switch (fieldName) {
              case "country": {
                acc[fieldName] = values.benCountry.value;
                break;
              }
              case "city": {
                acc["bankCity"] = values[fieldName];
                break;
              }
              case "bankName": {
                acc["bankName"] = values[fieldName].name;
                break;
              }
              case "documentNumber": {
                acc["docNumber"] = values[fieldName];
                break;
              }
              case "addressLine": {
                acc["bankAddressLine"] = values[fieldName];
                break;
              }
              case "iban": {
                acc["ibanCode"] = values[key];
                break;
              }
              case "postCode": {
                acc["bankPostCode"] = values[fieldName];
                break;
              }
              case "state": {
                acc["bankState"] = values[fieldName];
                break;
              }
              case "cnpj": {
                acc["cnpj"] = values[key];
                break;
              }
              case "cpf": {
                acc["cpf"] = values[key];
                break;
              }
              case "op": {
                acc["op"] = values[key];
                break;
              }
              case "ifsc": {
                acc["ifscCode"] = values[key];
                break;
              }
              case "rutnumber": {
                acc["rutNumber"] = values[fieldName];
                break;
              }
              default:
                acc[fieldName] = values[fieldName];
            }
          }
          return acc;
        }, {});
      }

      const navigateMethod = submitRedirect();
      dispatch(
        createBeneficiary({
          userRequest,
          navigateMethod,
          bankDetails,
          isCreateBankDetails
        })
      );
      console.log("bankDetails", bankDetails);
      console.log("userRequest", userRequest);
    }
  });

  useEffect(() => {
    (formik.values.type.value === "COMPANY") ? setDynamicFormType("COMPANY_CUSTOMER_CREATE_BENE")
      : (formik.values.type.value === "INDIVIDUAL") ? setDynamicFormType("REGISTERED_CUSTOMER_CREATE_BENE")
        : setDynamicFormType("REGISTERED_CUSTOMER_CREATE_BENE");
    dispatch(fetchDynamicFields(dynamicFormType));
  }, [formik.values.type, dynamicFormType]);

  useEffect(() => {
    if (countrySelector.loading !== Loading.SUCCESS || countrySelector.loading === Loading.ERROR) {
      dispatch(getAllCountries());
    }
  }, []);

  useEffect(() => {
    if (transfers.bankFieldStatus === "SUCCESS") {

      const bankFields = objCreator(transfers.bankField || {});
      updateInitialValues({
        ...formik.values,
        ...bankFields.fieldData
      });
      updateValidationSchema({
        ...validationSchema,
        ...bankFields.validations
      });
    }
  }, [transfers.bankFieldStatus]);

  useEffect(() => {
    const {
      values: { benCountry, bankCountry }
    } = formik;
    if ((benCountry && benCountry.value) || (bankCountry && bankCountry.value)) {
      const countryIso = bankCountry.value
        ? bankCountry.value
        : benCountry.value
          ? benCountry.value
          : "";
      dispatch(getSupportedBanks({ countryIso }));
    }
  }, [formik.values.benCountry, formik.values.bankCountry]);

  allDynamicFields = dynamicFields.loading === Loading.FETCHING || typeof dynamicFields[dynamicFormType] === "undefined" ? [] : dynamicFields[dynamicFormType]["dynamicFields"];
  const renderDynamicFields = allDynamicFields.map((eachField, index) => {
    return <DynamicFields divClass="col-6 mt-4" key={index} theme={theme} eachField={eachField} formik={formik} />;
  });

  const countryLoading = countrySelector.loading === Loading.FETCHING;
  const { bankField } = transfers;
  const fieldMappedComponent = Object.keys(bankField || {})
    .filter((value) => bankField[value] === true)
    .map((value) => {
      const itemField = value.split("Required")[0];

      if (itemField === "country") {
        return (
          <div className="benCreate__main__form--control">
            <p className="benCreate__main__form--inputHeading">
              {languageDictionaryStatus
                ? languageDictionary["WO_SELECT_BANK_ACCOUNT_-1095805078"]
                : "Bank Account Country"}
              <span style={{ color: "red" }}>*</span>
            </p>
            <ErrorField
              formik={formik}
              fieldName="bankCountry"
              fetchingData={countryLoading}
              selectField
            />
            <Select
              value={
                formik.values.bankCountry.value
                  ? formik.values.bankCountry
                  : formik.values.benCountry
              }
              onChange={(option) => {
                formik.setFieldValue("bankCountry", option);
                formik.setFieldValue("bankName", "");
              }}
              onBlur={() => formik.setFieldTouched("bankCountry", true)}
              options={countrySelector.countryDropdown}
              placeholder="Please select..."
              name="bankCountry"
              styles={customStyles}
              components={{
                IndicatorSeparator: null,
                DropdownIndicator: DropDownComponent
              }}
              customTheme={theme}
              isLoading={countryLoading}
              loadingMessage={() => "Fetching country list"}
            />
          </div>
        );
      }

      if (itemField === "bankName") {
        return (
          <div className="benCreate__main__form--control">
            <p className="benCreate__main__form--inputHeading">
              {languageDictionaryStatus ? languageDictionary["para_bank_anme"] : "Bank Name"}
              <span style={{ color: "red" }}>*</span>
            </p>
            <ErrorField
              formik={formik}
              fieldName="bankName"
              fetchingData={beneficiarySelector.supportedBanksStatus === "LOADING"}
              selectField
            />
            <Select
              value={formik.values.bankName}
              onChange={(option) => formik.setFieldValue("bankName", option)}
              onBlur={() => formik.setFieldTouched("bankName", true)}
              options={beneficiarySelector.supportedBanks}
              placeholder="Please select..."
              name="bankName"
              styles={customStyles}
              components={{
                IndicatorSeparator: null,
                DropdownIndicator: DropDownComponent
              }}
              customTheme={theme}
              isLoading={beneficiarySelector.supportedBanksStatus === "LOADING"}
              loadingMessage={() => "Fetching bank list"}
            />
          </div>
        );
      }
      return (
        <CustomInput
          key={itemField}
          formik={formik}
          inputKey={itemField}
          inputSize="100"
          labelName={fieldNames[itemField]}
        />
      );
    });

  return (
    <>
      <Helmet>
        <title>
          {languageDictionaryStatus
            ? languageDictionary["button_add_new_receiver"]
            : "Add New Beneficiary"}
        </title>
      </Helmet>
      <NavBar />
      <main className="benCreate__main">
        <div className="d-flex">
          <BackArrow />
          <h2 className="benCreate__main__heading">
            {languageDictionaryStatus
              ? languageDictionary["button_add_new_receiver"]
              : "Add New Beneficiary"}
          </h2>
        </div>
        <div className="benCreate__main__container">
          <form className="benCreate__main__form" onSubmit={formik.handleSubmit}>
            <section className="benCreate__main__form--topSection">
              <div className="benCreate__main__form--control">
                <p className="benCreate__main__form--inputHeading">
                  {languageDictionaryStatus ? languageDictionary["para_user_type"] : "User Type"}
                </p>
                <Select
                  value={formik.values.type}
                  onChange={(option) => formik.setFieldValue("type", option)}
                  onBlur={() => formik.setFieldTouched("type", true)}
                  options={typeOptions}
                  placeholder="Please select..."
                  isClearable={false}
                  isSearchable={false}
                  styles={customStyles}
                  customTheme={theme}
                  components={{
                    IndicatorSeparator: null,
                    DropdownIndicator: DropDownComponent
                  }}
                />
              </div>
              {formik.values.type.label === "Company" ? (
                <div className="benCreate__main__form--subHeading">
                  <h3>
                    {languageDictionaryStatus
                      ? languageDictionary["para_company_information"]
                      : "COMPANY INFORMATION"}
                  </h3>
                </div>
              ) : null}
              {formik.values.type.label === "Company" ? null : (
                <>
                  <div className="benCreate__main__form--control">
                    <label htmlFor="firstName">
                      <p className="benCreate__main__form--inputHeading">
                        {languageDictionaryStatus ? languageDictionary["para_first_name"] : "First Name"}
                        <span style={{ color: "red" }}>*</span>
                      </p>
                      <ErrorField formik={formik} fieldName="firstName" />
                      <input
                        type="text"
                        name="firstName"
                        id="firstName"
                        onChange={formik.handleChange}
                        value={formik.values.firstName}
                      />
                    </label>
                  </div>

                  <div className="benCreate__main__form--control">
                    <label htmlFor="lastName">
                      <p className="benCreate__main__form--inputHeading">
                        {languageDictionaryStatus
                          ? languageDictionary["para_last_name"]
                          : "Last Name"}
                        <span style={{ color: "red" }}>*</span>
                      </p>
                      <ErrorField formik={formik} fieldName="lastName" />
                      <input
                        type="text"
                        name="lastName"
                        id="lastName"
                        onChange={formik.handleChange}
                        value={formik.values.lastName}
                      />
                    </label>
                  </div>

                </>
              )}
              {formik.values.type.label === "Company" ? (
                <div className="benCreate__main__form--control">
                  <label htmlFor="companyName">
                    <p className="benCreate__main__form--inputHeading">
                      {languageDictionaryStatus ? languageDictionary["l_company_name"] : "Company Name"}
                      <span style={{ color: "red" }}>*</span>
                    </p>
                    <ErrorField formik={formik} fieldName="companyName" />
                    <input
                      type="text"
                      name="companyName"
                      id="companyName"
                      onChange={formik.handleChange}
                      value={formik.values.companyName}
                    />
                  </label>
                </div>
              ) : null}

              {formik.values.type.label === "Company" ? (
                <div className="benCreate__main__form--control">
                  <label htmlFor="regNumber">
                    <p className="benCreate__main__form--inputHeading">
                      {languageDictionaryStatus ? languageDictionary["WO_REGISTRATION_NUMBE_1407955590"] : "Registration Number"}
                      <span style={{ color: "red" }}>*</span>
                    </p>
                    <input
                      type="text"
                      name="regNumber"
                      id="regNumber"
                      onChange={formik.handleChange}
                      value={formik.values.regNumber}
                    />
                  </label>
                </div>
              ) : null}

              <div className="benCreate__main__form--control">
                <p className="benCreate__main__form--inputHeading">
                  {languageDictionaryStatus ? languageDictionary["WO_COUNTR_1271562677"] : "Country"}
                  <span style={{ color: "red" }}>*</span>
                </p>
                <ErrorField
                  formik={formik}
                  fieldName="benCountry"
                  fetchingData={countryLoading}
                  selectField
                />
                <Select
                  value={formik.values.benCountry}
                  onChange={(option) => {
                    formik.setFieldValue("benCountry", option);
                    formik.setFieldValue("bankCountry", option);
                    dispatch(
                      fetchRequiredBankFields({
                        countryCode: option.value
                      })
                    );
                  }}
                  onBlur={() => formik.setFieldTouched("benCountry", true)}
                  options={countrySelector.countryDropdown}
                  placeholder="Please select..."
                  name="benCountry"
                  styles={customStyles}
                  components={{
                    IndicatorSeparator: null,
                    DropdownIndicator: DropDownComponent
                  }}
                  customTheme={theme}
                  isLoading={countryLoading}
                  loadingMessage={() => "Fetching country list"}
                />
              </div>
            </section>

            <section className="benCreate__main__form--bottomSection">
              <div className="benCreate__main__form--subHeading">
                <h3>
                  {languageDictionaryStatus ? languageDictionary["para_bank_account_details"] : "BANK ACCOUNT DETAILS"}
                </h3>
              </div>

              <div className="benCreate__main__form--checkbox">
                <label htmlFor="provideBeneficiary">
                  <input
                    type="checkbox"
                    name="isChecked"
                    id="provideBeneficiary"
                    checked={formik.values.isChecked}
                    onChange={formik.handleChange}
                    disabled={!formik.values.benCountry.value}
                  />
                  {languageDictionaryStatus ? languageDictionary["WO_I_WILL_PROVIDE_BENEF_-175913142"] : "Provide Beneficiary"}
                </label>
              </div>
              {formik.values.isChecked ?
                <div className="benCreate__main__form--control">
                  <p className="benCreate__main__form--inputHeading">
                    {languageDictionaryStatus ? languageDictionary["l_action_type"] : "Type"}
                  </p>
                  <Select
                    value={formik.values.bankType}
                    onChange={(option) => formik.setFieldValue("bankType", option)}
                    onBlur={() => formik.setFieldTouched("bankType", true)}
                    options={bankActionType}
                    placeholder="Please select..."
                    isClearable={false}
                    isSearchable={true}
                    styles={customStyles}
                    customTheme={theme}
                    components={{
                      IndicatorSeparator: null,
                      DropdownIndicator: DropDownComponent
                    }}
                  />
                </div> : ""
              }

              {formik.values.isChecked && formik.values.bankType.value === "COUNTRY" ?
                <div className="benCreate__main__form--control">
                  <p className="benCreate__main__form--inputHeading">
                    {languageDictionaryStatus ? languageDictionary["para_country"] : "Country"}
                  </p>
                  <Select
                    value={formik.values.bankCountry}
                    onChange={(option) => {
                      formik.setFieldValue("bankCountry", option);
                      dispatch(fetchRequiredBankFields({ countryCode: option.value }));
                    }}
                    onBlur={() => formik.setFieldTouched("benCountry", true)}
                    options={countrySelector.countryDropdown}
                    placeholder="Please select..."
                    name="benCountry"
                    styles={customStyles}
                    components={{
                      IndicatorSeparator: null,
                      DropdownIndicator: DropDownComponent
                    }}
                    customTheme={theme}
                    isLoading={countryLoading}
                    loadingMessage={() => "Fetching country list"}
                  />
                </div> : formik.values.isChecked && formik.values.bankType.value === "CURRENCY" ?
                  <>
                    <div className="benCreate__main__form--control">
                      <p className="benCreate__main__form--inputHeading">
                        {languageDictionaryStatus ? languageDictionary["para_country"] : "Country"}
                      </p>
                      <Select
                        value={formik.values.bankCountry}
                        onChange={(option) => {
                          formik.setFieldValue("bankCountry", option);
                          dispatch(fetchRequiredBankFields({ countryCode: option.value }));
                        }}
                        onBlur={() => formik.setFieldTouched("benCountry", true)}
                        options={countrySelector.countryDropdown}
                        placeholder="Please select..."
                        name="benCountry"
                        styles={customStyles}
                        components={{
                          IndicatorSeparator: null,
                          DropdownIndicator: DropDownComponent
                        }}
                        customTheme={theme}
                        isLoading={countryLoading}
                        loadingMessage={() => "Fetching country list"}
                      />
                    </div>
                    <div className="benCreate__main__form--control">
                      <p className="benCreate__main__form--inputHeading">
                        {languageDictionaryStatus ? languageDictionary["l_currency"] : "Currency"}
                      </p>
                      <Select
                        value={formik.values.bankCurrency}
                        onChange={(option) => {
                          formik.setFieldValue("bankCurrency", option);
                          dispatch(fetchRequiredBankFieldsByFilter(formik.values.bankCountry.value, "ACCOUNTPAYMENT", option.value));
                        }}
                        onBlur={() => formik.setFieldTouched("bankCurrency", true)}
                        options={countrySelector.currencyDropdownList}
                        placeholder="Please select..."
                        name="bankCurrency"
                        styles={customStyles}
                        components={{
                          IndicatorSeparator: null,
                          DropdownIndicator: DropDownComponent
                        }}
                        customTheme={theme}
                        isLoading={countryLoading}
                        loadingMessage={() => "Fetching country list"}
                      />
                    </div>
                  </> : ""

              }

              {formik.values.isChecked && (
                <>
                  {transfers.bankFieldStatus === Loading.FETCHING ? (
                    <Spinner containerHeight="30vh" />
                  ) : (
                    <>{fieldMappedComponent}</>
                  )}
                </>
              )}
            </section>

            <div className="benCreate__main__form--button-submit">
              <button type="submit" disabled={beneficiarySelector.createLoading || countryLoading}>
                {beneficiarySelector.createLoading ? (
                  <ButtonSpinner />
                ) : (
                  <span>
                    {languageDictionaryStatus
                      ? languageDictionary["title_create_beneficiary"]
                      : "Create Beneficiary"}
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default BeneficiaryCreation;

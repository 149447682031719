export const FETCH_COUNTRY_LIST_REQUEST = "FETCH_AIRTIME_COUNTRY_LIST_REQUEST";
export const FETCH_COUNTRY_LIST_SUCCESS = "FETCH_AIRTIME_COUNTRY_LIST_SUCCESS";
export const FETCH_COUNTRY_LIST_FAILURE = "FETCH_AIRTIME_COUNTRY_LIST_FAILURE";

export const FETCH_PRETOPUP_REQUEST = "FETCH_AIRTIME_PRETOPUP_REQUEST";
export const FETCH_PRETOPUP_SUCCESS = "FETCH_AIRTIME_PRETOPUP_SUCCESS";
export const FETCH_PRETOPUP_FAILURE = "FETCH_AIRTIME_PRETOPUP_FAILURE";

export const FETCH_QUOTE_FOR_AIRTIME_TOPUP_REQUEST =
  "FETCH_AIRTIME_QUOTE_FOR_AIRTIME_TOPUP_REQUEST";
export const FETCH_QUOTE_FOR_AIRTIME_TOPUP_SUCCESS =
  "FETCH_AIRTIME_QUOTE_FOR_AIRTIME_TOPUP_SUCCESS";
export const FETCH_QUOTE_FOR_AIRTIME_TOPUP_FAILURE =
  "FETCH_AIRTIME_QUOTE_FOR_AIRTIME_TOPUP_FAILURE";

export const INITIATE_AIRTIME_PAYMENT_REQUEST = "INITIATE_AIRTIME_PAYMENT_REQUEST";
export const INITIATE_AIRTIME_PAYMENT_SUCCESS = "INITIATE_AIRTIME_PAYMENT_SUCCESS";
export const INITIATE_AIRTIME_PAYMENT_FAILURE = "INITIATE_AIRTIME_PAYMENT_FAILURE";

export const LIST_AIRTIME_PAYMENT_METHODS_REQUEST = "LIST_AIRTIME_PAYMENT_METHODS_REQUEST";
export const LIST_AIRTIME_PAYMENT_METHODS_SUCCESS = "LIST_AIRTIME_PAYMENT_METHODS_SUCCESS";
export const LIST_AIRTIME_PAYMENT_METHODS_FAILURE = "LIST_AIRTIME_PAYMENT_METHODS_FAILURE";

import { css } from "@emotion/react";

export const FormRow = css`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;

  @media (max-width: 56.25rem) {
    flex-direction: column;
  }
`;

export const modalContainer = css`
  background: white;
  border: 1px solid #1149835b;
  min-height: 70vh;
  max-height: 80vh;
  border-radius: 20px;
  display: flex;
  padding: 25px 30px;
  width: 100vw;
  flex-direction: column;
  overflow-y: auto;

  @media (max-width: 56.25rem) {
    width: 100%;
    padding: 1rem;
  }
`;

export const modalContainerHeading = css`
  font-size: 20px;
  color: #90a8be;
  font-weight: 400;
  padding-bottom: 10px;
  margin-bottom: 15px;
  border-bottom: 1px solid #1149835b;
  text-align: center
`;

export const buttonWrapper = css`
  flex-basis: 32%;
  max-width: 40%;
  margin-top: 1.3rem;

  @media (max-width: 56.25rem) {
    margin-top: 0;
    max-width: 60%;
  }
`;

export const selectDataWrapper = css`
  flex-basis: 65%;
  max-width: 100%;

  @media (max-width: 56.25rem) {
    width: 100%;
    font-size: 0.9375rem;
    margin: 0 0 1rem;
  }
`;
import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const transInfoSection = css`
  flex-basis: 48%;
  max-width: 48%;
  border: 1px solid #1149831a;
  border-radius: 10px;
  background: white;
  padding: 40px 40px;

  @media ( max-width: 56.25rem) {
    padding: 1rem;
    max-width: 100%;
  }
`;

export const columnContainer = css`
  flex-basis: 47%;
  max-width: 47%;
  border-bottom: 1.5px solid #1149831a;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${(props) => (props.noMargin ? 0 : '30px')};
`;

export const RowContent = styled.p`
  color: ${(props) => props.theme.primaryColor};
  font-weight: 500;
  font-size: 16px;
  padding-top: 12px;
  padding-bottom: 20px;
  word-break: break-word;
  line-height: 1.4;
`;

export const RowHeading = styled.p`
  color: ${(props) => props.theme.primaryColor};
  font-weight: 600;
  font-size: 13px;
  color: #000000af;
  word-break: break-word;
`;

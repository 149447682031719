import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Footer from "Components/Footer";
import NavBar from "Components/Navbar";
import styled from "@emotion/styled";

const Input = styled.input`
  width: 100%;
  border: 0;
  background: #e3edf3;
  border-radius: 5px;
  padding: 1.5rem;
  font-size: 16px;
  font-weight: 400;

  ::placeholder {
    color: #6c757d;
  }
`;

export const NextButton = styled.button`
  font-size: 1rem;
`;

const MakePaymentOtp = () => {
  const history = useHistory();

  return (
    <>
      <Helmet>
        <title>Scan and Pay &mdash; Multiview MTA</title>
      </Helmet>
      <NavBar />
      <section>
        <div className="container">
          <div className="mt-5 mb-5">
            <div className="d-flex justify-content-between align-items-center">
              <h2 className="text-blue ">Make Payment</h2>
            </div>
            <div className="card mt-5 p-md-5">
              <div className="card-body mb-3 p-md-3">
                {/* --- Search box for product --- */}
                <div className="row mb-5">
                  <div className="col-12 col-md-6">
                    {/* ---- Enter reference number */}
                    <div className="mb-5 mt-3 mt-md-0">
                      <p className="text-blue">Email Address or Phone Number</p>
                      <Input
                        type="text"
                        className="form-control mb-5"
                        placeholder="Type in a here....."
                      />
                    </div>

                    {/* ---- OTP */}
                    <div>
                      <p className="text-blue text-uppercase">otp</p>
                      <Input
                        type="text"
                        className="form-control"
                        placeholder="Type in a number here....."
                      />
                    </div>
                  </div>
                </div>

                {/* ----- Proceed Button ----- */}
                <div className="row">
                  <div className="col-6 col-md-3 col-xl-2 ">
                    <Link
                      to="/make-payment-details"
                      onClick={() => history.push()}
                      role="button"
                      tabIndex="-1"
                      onKeyPress={() => {}}
                    >
                      <NextButton className="btn btn-lg btn-blue btn-block text-uppercase text-white">
                        continue
                      </NextButton>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default MakePaymentOtp;

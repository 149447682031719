// eslint-disable-next-line no-unused-vars
import React from 'react';
/** @jsx jsx */
/** @jsxRuntime classic */

import { jsx } from '@emotion/react';
import { transInfoSection, Row } from './style';
import Column from './Column';

const TransactionInfoSection = ({ data }) => {
  const {
    code,
    date,
    country_from,
    display_status,
    deliveryMethod,
    payment_method,
  } = data;

  const deliveryMethodRender = (deliveryMethod === 'ACCOUNTPAYMENT') ? 'Transfer Funds' : (deliveryMethod === 'CASHPICKUP') ?
    'Cash Pickup' : (deliveryMethod === 'MOBILE_MONEY') ? 'Mobile Money' : (deliveryMethod === 'AIRTIME_TOPUP') ? 'Airtime Topup' : 'Others';

  const paymentTypeRender = (payment_method === 'ONLINE_CARD_PAYMENT') ? 'Online Card Payment' : (payment_method === 'E_WALLET') ? 'Wallet' :
    (payment_method === 'BANK_TRANSFER') ? 'Bank Transfer' : 'Others';
  return (
    <div css={transInfoSection}>
      <Row>
        <Column heading="PCN:" content={code} />
        <Column heading="Transaction Date:" content={date} />
      </Row>
      <Row>
        <Column heading="Transaction Status:" content={display_status} />
        <Column heading="Sending Country" content={country_from} />
      </Row>
      <Row>
        <Column heading="Delivery Method:" content={deliveryMethodRender} />
        <Column heading="Payment Type:" content={paymentTypeRender} />
      </Row>
      <Row noMargin>
        <Column heading="Confirmation staff:" content={null} />
        <Column heading="Remarks:" content="" />
      </Row>
    </div>
  );
};

export default TransactionInfoSection;

const config = {
  API_KEY: "",
  KEY: "12384-098757cc6spot34nC-987to890rcc67cc9-2976ted;C6Gp8UlpCyYPJFdkAxfCyP4vml4bCLE19Yi8SCR3a5ye8QRlTC5ZQQxE1k3KJU",
  GOOGLE_OAUTH: "707908700771-6b36s8qsna6n1b28ktnfv4656g3rvuf0.apps.googleusercontent.com",
  FB_OAUTH: "234416911798587",
  PROJECT_ID: "spotbanc-ui",
  PROD_URL: "",
  COOKIE_EXPIRY_PERIOD: 1,
  DOMAIN_DATA: "BTYygFTP4vmgVjjGvBVcRXvTYx",
  LANGUAGE_DATA: "FFrTy4mgVjjGvHmgRXvTYxVBVcR",
  USER_VERIFIED_STATUS: "HgTYxVBFmGjjGVcRmgRXvFVvrTy4",
  USER_COUNTRY: "KYgRXvFVvHgTYxmGjjGVcRVBFrTy4",
  PARAMS_COUNTRY: "FKTYxmGjjGVrTYgRXvFcgRvVBFVvHy4",
  MERCHANT_PAYMENT_STATUS: "TERvVfBFVvHy4dvKTYxgRXvFcgRvVmGjjGVrTY",
  TRANSFER_MODE_STATUS: "TVmGjjTERvYxgRXvFcgRvVfBFVvHy4dvKGVrTY",
  LOGO: "TYxgRXvFRvVfBFGcgBjjTERvvKGVrTVvHy4dVmY"
};

export default config;

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';

import downArrow from 'Assets/svg/drop-down-arrow.svg';

const styObj = css({
  height: '7px',
  width: '14px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
});

const DropDownComponent = ({ iconSource }) => {
  const defaultIcon = iconSource || downArrow;

  return (
    <div css={styObj}>
      <img
        src={defaultIcon}
        alt="down arrow"
        css={css`
          height: 100%;
          width: 100%;
        `}
      />
    </div>
  );
};

export default DropDownComponent;

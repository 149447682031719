import styled from '@emotion/styled';

export const BrandedSubmitButton = styled.button`
    background-color: var(--submit-button-color) !important;
    color: var(--submit-button-text-color) !important; 
  :hover {
    background-color: var(--submit-button-hover-color) !important;
  }
  :active {
    background-color: var(--submit-button-active-color) !important;
  }
  `;

export const BrandedRegularButton = styled.button`
    background-color: var(--regular-button-color) !important;
    color: var(--regular-button-text-color) !important; 
  :hover {
    background-color: var(--regular-button-hover-color) !important;
  }
  :active {
    background-color: var(--regular-button-active-color) !important;
  }
  `;

export const BrandedDeleteButton = styled.button`
    background-color: var(--delete-button-color) !important;
    color: var(--delete-button-text-color) !important; 
  :hover {
    background-color: var(--delete-button-hover-color) !important;
  }
  :active {
    background-color: var(--delete-button-active-color) !important;
  }
  `;


import styled from '@emotion/styled';

export const Card = styled.div`
  border: 1px solid #e3edf3;
  border-radius: 10px;
  padding: 45px;
  max-width: 48.5%;
  flex-basis: 48.5%;

  @media (max-width: 56.25rem ){
    padding: 1rem;
    max-width: 100%;
    margin-bottom: 1rem;
  }
`;

export const MiniContainer = styled.div`
  max-width: 48.5%;
  flex-basis: 48.5%;
  display: flex;
  flex-direction: column;

  @media (max-width: 56.25rem ){
    max-width: 100%;
    
  }
`;

export const FlexWrapper = styled.form`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;

  @media (max-width: 56.25rem ){
    flex-direction: column;
  }
`;

export const FieldContainer = styled.div`
  border: 1px solid #e3edf3;
  border-radius: 10px;
  padding: 45px;
  margin-top: 20px;
  background: white;

  @media (max-width: 56.25rem ){
    padding: 1rem;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: auto;
`;

export const FormRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;

  @media (max-width: 56.25rem ){
    flex-direction: column;
  }
`;

export const RowItem = styled.div`
  flex-basis: ${(props) => props.flex || '100%'};
  max-width: ${(props) => props.flex || '100%'};

  @media (max-width: 56.25rem ){
    width: 100%
  }
`;

export const LabelHeading = styled.p`
  color: ${(props) => props.theme.primaryColor};
`;

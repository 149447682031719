import styled from "@emotion/styled";

export const Input = styled.input`
  width: 100%;
  margin-top: 12px;
  border: 0;
  background: #e3edf3;
  border-radius: 5px;
  padding: 1.5rem;
  font-size: 16px;
  font-weight: 400;

  ::placeholder {
    color: #6c757d;
  }
`;

export const Button = styled.button`
  color: #0055AD;
  border-color: #0055AD;
  border-radius: 0.3125rem;

  :hover {
    background-color: #0055AD;
    color: #fff;
  }
`;

export const NextButton = styled.button`
  font-size: 1rem;
`;


import React from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import styled from "@emotion/styled";

const Image = styled.div`
  position: relative;
  overflow: hidden;
`;

const Product = (props) => {
  const history = useHistory();
  const { name, amount, imageSrc, currency, product } = props;
  const { product_id } = product || {};

  return (
    <div className="col-6 col-md-3 mb-5">
      <Link
        to={{
          pathName: `/merchant-product/${product_id}`,
          state: product || {},
        }}
        className="text-decoration-none"
        onClick={() => history.push(`/merchant-product/${product_id}`)}
        role="button"
        tabIndex="-1"
        onKeyPress={() => {}}
      >
        <Image>
          <img src={imageSrc} alt={name} className="img-fluid" />
        </Image>
        <div className="position-relative">
          <h5 className="text-blue">{name}</h5>
          <span className="text-green h3">{`${currency}${amount}`}</span>
        </div>
      </Link>
    </div>
  );
};

export default Product;

// eslint-disable-next-line no-unused-vars
import React from "react";
/** @jsx jsx */
/** @jsxRuntime classic */

import { jsx } from "@emotion/react";
import { amountSection, amountSectionInner, amountSectionBottom, amountSectionRow } from "./style";

const AmountSection = ({ data }) => {
  const {
    amount_sent,
    sending_currency_code,
    amount_received,
    charges,
    paymentMethodFee,
    total_amount_paid,
    receiving_currency_code,
    vat
  } = data;

  const amountReceived = typeof amount_received === "undefined" || amount_received === null ? amount_received : amount_received.replace(/,/g, "");

  return (
    <div css={amountSection}>
      <div css={amountSectionInner}>
        <div css={amountSectionRow}>
          <p>Amount:</p>
          <p>
            {sending_currency_code} {parseFloat(amount_sent).toFixed(2)}
          </p>
        </div>
        <div css={amountSectionRow}>
          <p>Commission:</p>
          <p>
            {sending_currency_code} {parseFloat(charges).toFixed(2)}
          </p>
        </div>
        <div css={amountSectionRow}>
          <p>Payment Fee:</p>
          <p>
            {sending_currency_code} {parseFloat(paymentMethodFee).toFixed(2)}
          </p>
        </div>
        <div css={amountSectionRow}>
          <p>VAT:</p>
          <p>
            {sending_currency_code}{" "}
            {parseFloat(vat)
              .toFixed(2)
              .replace(/[^0-9.]/g, ".")}
          </p>
        </div>
        <div css={amountSectionRow}>
          <p>Total Amount Paid:</p>
          <p>
            {sending_currency_code} {parseFloat(total_amount_paid).toFixed(2)}
          </p>
        </div>
      </div>
      <div css={amountSectionBottom}>
        <p>Your beneficiary receives:</p>
        <p>
          {receiving_currency_code} {parseFloat(amountReceived).toLocaleString("en-US")}
        </p>
      </div>
    </div>
  );
};

export default AmountSection;

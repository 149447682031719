import React from "react";
import { Helmet } from "react-helmet";
import OrderItems from "Components/OrderItems";
import { useSelector } from "react-redux";
import {
  Order,
  OrderWrapper,
  OrderHeader,
  OrderBody,
  BlockHeader,
  Block,
  BlockBody,
  Item
} from "./style";

export const OrderDetails = () => {
  const { cart } = useSelector(({ cartReducer }) => ({
    cart: cartReducer
  }));
  return (
    <>
      <Helmet>
        <title>Order Details</title>
      </Helmet>
      <section>
        <div className="container">
          <div className="mb-5">
            <h2 className="text-blue mb-5">Order Details #0001</h2>

            <div className="row">
              <div className="col-12">
                <Order className="mb-5">
                  <OrderWrapper>
                    <OrderHeader className="text-center mb-5">
                      <div className="row">
                        <div className="col-5">Item</div>
                        <div className="col-2">Price</div>
                        <div className="col-3">Quantity</div>
                        <div className="col-2">Total</div>
                      </div>
                    </OrderHeader>
                    <OrderBody>
                      {/* ---- Ordered items list ----- */}
                      <OrderItems
                        imageSrc={cart.productMainPhotoUrl}
                        name={cart.product_name}
                        amount={`${cart.product_currency}${cart.product_value}`}
                        quantity={cart.quantity}
                        totalAmount={`${cart.product_currency}${cart.product_value}`}
                      />
                    </OrderBody>
                  </OrderWrapper>
                </Order>
                <div className="row my-5">
                  <div className="col-md-6">
                    <Block className="mb-5">
                      <BlockHeader>
                        <h6 className="text-uppercase mb-0">order summary</h6>
                      </BlockHeader>
                      <BlockBody>
                        <ul className="mb-0 list-unstyled">
                          <Item>
                            <span>Order Subtotal</span>
                            <span>&#163;479.00</span>
                          </Item>
                          <Item>
                            <span>Shipping &amp; Installation</span>
                            <span>&#163;35.00</span>
                          </Item>
                          <Item className="border-0">
                            <span>Total</span>
                            <strong className="h5">&#163;514.00</strong>
                          </Item>
                        </ul>
                      </BlockBody>
                    </Block>
                  </div>

                  {/* ------ Invoice details ----- */}
                  <div className="col-md-6">
                    <Block>
                      <BlockHeader>
                        <h6 className="text-uppercase mb-0">invoice address</h6>
                      </BlockHeader>
                      <BlockBody className="pt-1">
                        <div className="col-6">
                          <p>
                            John Brown, <br />
                            13/25 New Avenue New Heaven 45Y 73J England Great Britain.
                          </p>
                        </div>
                      </BlockBody>
                      <BlockHeader>
                        <h6 className="text-uppercase mb-0">shipping address</h6>
                      </BlockHeader>
                      <BlockBody className="pt-1">
                        <div className="col-6">
                          <p>
                            John Brown, <br />
                            13/25 New Avenue New Heaven 45Y 73J England Great Britain.
                          </p>
                        </div>
                      </BlockBody>
                    </Block>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default OrderDetails;

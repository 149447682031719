export const FETCH_ACCOUNT_BALANCE_REQUEST = 'FETCH_ACCOUNT_BALANCE_REQUEST';
export const FETCH_ACCOUNT_BALANCE_SUCCESS = 'FETCH_ACCOUNT_BALANCE_SUCCESS';
export const FETCH_ACCOUNT_BALANCE_FAILURE = 'FETCH_ACCOUNT_BALANCE_FAILURE';

export const FETCH_ACCOUNT_BALANCE_BY_ACCOUNT_CURRENCY_REQUEST = 'FETCH_ACCOUNT_BALANCE_BY_ACCOUNT_CURRENCY_REQUEST';
export const FETCH_ACCOUNT_BALANCE_BY_ACCOUNT_CURRENCY_SUCCESS = 'FETCH_ACCOUNT_BALANCE_BY_ACCOUNT_CURRENCY_SUCCESS';
export const FETCH_ACCOUNT_BALANCE_BY_ACCOUNT_CURRENCY_FAILURE = 'FETCH_ACCOUNT_BALANCE_BY_ACCOUNT_CURRENCY_FAILURE';

export const FETCH_ACCOUNT_STATEMENT_REQUEST = 'FETCH_ACCOUNT_STATEMENT_REQUEST';
export const FETCH_ACCOUNT_STATEMENT_SUCCESS = 'FETCH_ACCOUNT_STATEMENT_SUCCESS';
export const FETCH_ACCOUNT_STATEMENT_FAILURE = 'FETCH_ACCOUNT_STATEMENT_FAILURE';

export const FETCH_MONTHLY_ACCOUNT_BALANCE_REQUEST = 'FETCH_MONTHLY_ACCOUNT_BALANCE_REQUEST';
export const FETCH_MONTHLY_ACCOUNT_BALANCE_SUCCESS = 'FETCH_MONTHLY_ACCOUNT_BALANCE_SUCCESS';
export const FETCH_MONTHLY_ACCOUNT_BALANCE_FAILURE = 'FETCH_MONTHLY_ACCOUNT_BALANCE_FAILURE';

export const FETCH_YEARLY_TRANSACTION_REQUEST = 'FETCH_YEARLY_TRANSACTION_REQUEST';
export const FETCH_YEARLY_TRANSACTION_SUCCESS = 'FETCH_YEARLY_TRANSACTION_SUCCESS';
export const FETCH_YEARLY_TRANSACTION_FAILURE = 'FETCH_YEARLY_TRANSACTION_FAILURE';

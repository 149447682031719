import Axios from "./Axios.service";

export class DocumentService {
  static listDocuments(customerCode) {
    return Axios({
      method: "GET",
      url: `/web-services/api/v6/services/documentmanagement/list-documents/${customerCode}`,
      headers: {
        platform: "fincode",
        UUID: 200,
      },
    });
  }

  static attachDocuments(payload) {
    return Axios({
      method: "POST",
      url: `/web-services/api/v6/services/documentmanagement/attach-documents`,
      data: payload,
      headers: {
        platform: "fincode",
        UUID: 200,
      },
    });
  }

  static deleteDocument(payload) {
    const { customerCode, docId } = payload;
    return Axios({
      method: "DELETE",
      url: `web-services/api/v6/services/documentmanagement/delete-document/${customerCode}/${docId}`,
      headers: {
        platform: "fincode",
        UUID: 200,
      },
    });
  }

  static updateProfilePicture(payload) {
    return Axios({
      method: "PUT",
      url: `web-services/api/v6/services/documentmanagement/upload-profile-picture`,
      headers: {
        platform: "fincode",
        UUID: 200,
      },
      data: payload,
    });
  }
}

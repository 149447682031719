import * as Yup from 'yup';

export const objCreator = (valueData) => {
  return Object.keys(valueData || {}).reduce(
    (acc, curr) => {
      if (valueData[curr] === true) {
        const key = curr.split("Required")[0];
        if (key === "country") {
          return acc;
        }
        acc.fieldData[key] = "";
        acc.validations[key] = Yup.string().when("isChecked", {
          is: true,
          then: Yup.string().required("required"),
        });
      }
      return acc;
    },
    { fieldData: {}, validations: {} }
  );
};

// eslint-disable-next-line no-unused-vars
import React from "react";
import { Link, useLocation } from "react-router-dom";

const Error404 = () => {
    let location = useLocation();
  return (
    <div className="py-5">
      <div className="container py-5">
        <div className="row justify-content-center align-items-center">
          <div className="col-lg-5">
            <div className="card">
              <div className="card-body p-4">
                <div className="text-center">
                  <h1 className="text-blue">
                    404
                  </h1>
                  <h4 className="text-danger mt-3">Page Not Found. No match for <code>{location.pathname}</code></h4>
                  <p className="text-muted mt-3">
                    It's looking like you may have taken a wrong turn. Don't worry... it happens to
                    the best of us. Here's a little tip that might help you get back on track.
                  </p>

                  <Link className="btn btn-blue mt-3 text-white" to="/">
                    Return Home
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Error404;

import * as Country from "Store/constants/country.constants";
import * as Loading from "Store/constants/loadingState.constants";

const initialState = {
  unformattedCountryList: [],
  supportedCountries: [],
  countryDropdownList: [],
  currencyDropdownList: [],
  countryCodeDropdownList: [],
  supportedCurrenciesByCountries: [],
  supportedReceivingCurrenciesByCountries: [],
  loading: ""
};

const formatForCountryDropdown = (payloadData) => {
  return payloadData.map((item) => ({ value: item["iso3Code"], label: item["common_name"] }));
};

const formatForCurrencyDropdown = (payloadData) => {
  return payloadData.map((item) => ({ value: item["currencyCode"], label: item["currencyCode"] }));
};

const formatForCountryCodeDropdown = (payloadData) => {
  return payloadData.reduce((acc, curr) => {
    if (curr["telephoneCode"] && curr["telephoneCode"].length > 0) {
      acc.push({
        value: curr["telephoneCode"],
        label: `${curr["common_name"]} (${curr["telephoneCode"]})`
      });
    }
    return acc;
  }, []);
};

const formatForSendingCurrencies = (payloadData) => {
  return payloadData.map((item) => ({ value: item, label: item }));
};

const countryReducer = (state = initialState, action) => {
  switch (action.type) {
    case Country.FETCH_CURRENCY_BY_COUNTRIES_REQUEST:
      return {
        ...state,
        loading: Loading.FETCHING
      };

    case Country.FETCH_CURRENCY_BY_COUNTRIES_SUCCESS:
      const supportedCurrenciesByCountries = formatForSendingCurrencies(action.payload);
      return {
        ...state,
        loading: Loading.SUCCESS,
        supportedCurrenciesByCountries
      };

    case Country.FETCH_CURRENCY_BY_COUNTRIES_FAILURE:
      return {
        ...state,
        loading: Loading.ERROR
      };

    case Country.FETCH_RECEIVING_CURRENCY_BY_COUNTRIES_REQUEST:
      return {
        ...state,
        loading: Loading.FETCHING
      };

    case Country.FETCH_RECEIVING_CURRENCY_BY_COUNTRIES_SUCCESS:
      const supportedReceivingCurrenciesByCountries = formatForSendingCurrencies(action.payload);
      return {
        ...state,
        loading: Loading.SUCCESS,
        supportedReceivingCurrenciesByCountries
      };

    case Country.FETCH_RECEIVING_CURRENCY_BY_COUNTRIES_FAILURE:
      return {
        ...state,
        loading: Loading.ERROR
      };

    case Country.FETCH_ALL_COUNTRIES_REQUEST:
      return {
        ...state,
        loading: Loading.FETCHING
      };

    case Country.FETCH_ALL_COUNTRIES_SUCCESS:
      const countryDropdownList = formatForCountryDropdown(action.payload);
      const countryCodeDropdownList = formatForCountryCodeDropdown(action.payload);
      const currencyDropdownList = formatForCurrencyDropdown(action.payload);

      return {
        ...state,
        loading: Loading.SUCCESS,
        unformattedCountryList: action.payload,
        countryDropdownList,
        countryCodeDropdownList,
        currencyDropdownList
      };

    case Country.FETCH_ALL_COUNTRIES_FAILURE:
      return {
        ...state,
        unformattedCountryList: [],
        loading: Loading.ERROR
      };

    case Country.FETCH_ALL_SUPPORTED_COUNTRIES_REQUEST:
      return {
        ...state,
        loading: Loading.FETCHING
      };

    case Country.FETCH_ALL_SUPPORTED_COUNTRIES_SUCCESS:
      const supportedCountriesDropdown = formatForCountryDropdown(action.payload);
      return {
        ...state,
        loading: Loading.SUCCESS,
        supportedCountries: supportedCountriesDropdown
      };

    case Country.FETCH_ALL_SUPPORTED_COUNTRIES_FAILURE:
      return {
        ...state,
        unformattedCountryList: [],
        loading: Loading.ERROR
      };

    default:
      return state;
  }
};

export default countryReducer;

// eslint-disable-next-line no-unused-vars
import React from 'react';
import styled from '@emotion/styled';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { containerStyle } from 'Assets/styles/global/base';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: var(--background-color);
`;

export const InnerContainer = styled.div`
  flex: 1;
  width: 100%;
  overflow: hidden;
  position: relative;
`;

export const Main = styled.div`
  ${containerStyle};
  padding-top: 2.8rem;

  @media (max-width: 56.25rem) {
    padding: 1rem;
  }
`;

export const MainCompliance = styled.div`
  ${containerStyle};
  padding-top: 2.8rem;
  margin-left: 15rem;
  margin-right: 15rem;

  @media (max-width: 56.25rem) {
    margin: 0;
  }
`;

export const Ellipse = styled.div`
  background: #0fcc984d;
  height: 100px;
  width: 100px;
  border-radius: 50%;
  position: absolute;
`;

export const OffsetIcon = styled.div`
  position: absolute;
  top: 0;
  right: -12vw;
  width: 30vw;
  z-index: -1;
`;

export const ImageElement = props => (
  <img
    src={props.src}
    css={css`
      width: 100%;
      height: 100%;
      object-fit: cover;
    `}
    alt={props.title}
  />
);

import Axios from "./Axios.service";

export const fetchDynamicFieldByFormType = async (formType) => {
  return Axios({
    method: 'GET',
    url: `/web-services/api/v6/services/dynamicfields/dynamic-fields-group/${formType}`,
    headers: {
      platform: 'fincode',
      UUID: 200
    }
  });

};

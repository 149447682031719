// eslint-disable-next-line no-unused-vars
import React, { useEffect } from "react";
/** @jsx jsx */
/** @jsxRuntime classic */

import { jsx, css } from "@emotion/react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import HeadingText from "Components/HeadingText";
import CustomButton from "Components/CustomButton";
import CustomInput from "Components/CustomInput";
import FormLabel from "Components/FormLabel";
import CustomTextArea from "Components/CustomTextArea";
import DropDownComponent from "Components/DropDownComponent";
import ErrorField from "Components/ErrorField";
import customStyles from "Assets/styles/dropdown/customStyles";
import downArrow from "Assets/svg/blue-drop-down-arrow.svg";
import { getCustomerServiceUsers } from "Store/actions/user.action";
import * as Loading from "Store/constants/loadingState.constants";
import navigateMethod from "Utils/Helpers/navigateMethod";
import { sendNotification } from "Store/actions/notification.action";
import BackArrow from "../../../../../../Components/BackArrow";

const ReadMessage = () => {
  const history = useHistory();

  console.log(history.location.state.tableData);


  useEffect(() => {
  }, []);

  return (

    <div className="d-flex">
      <BackArrow />
      <HeadingText text={"Read"} />
    </div>
  );
};

export default ReadMessage;

import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const containerStyle = css`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  border: 1px solid #1149835b;
  border-radius: 10px;
  padding: 3rem 40px;

  @media (max-width: 56.25rem) {
    padding: 1.2rem;
  }
`;

export const ContainerWithCounter = styled.div`
  background: #e3edf3;
  border-radius: 6px;
  padding: 2.5rem 7%;
  position: relative;

  &:before {
    content: "${(props) => props.counter}";
    background: ${(props) => props.theme.primaryColor};
    height: 30px;
    width: 30px;
    position: absolute;
    border-radius: 50%;
    top: -12px;
    left: -15px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    font-weight: 500;
  }
`;

export const StyledParagraph = styled.p`
  color: ${(props) => props.theme.primaryColor};
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5;
`;

export const StyledTextHeading = styled.p`
  color: #000000af;
  font-weight: 500;
  font-size: 0.9rem;
  line-height: 1.3;
  margin-bottom: 0.3rem;
`;

export const StyledInfoContainer = styled.div`
  margin-bottom: 2.2rem;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from "react";
/** @jsx jsx */
/** @jsxRuntime classic */

import { jsx } from "@emotion/react";
import * as Yup from "yup";
import { useFormik } from "formik";
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";

import HeadingText from "Components/HeadingText";
import NavBar from "Components/Navbar";
import Footer from "Components/Footer";
import { Container, Main, InnerContainer, OffsetIcon, ImageElement } from "Components/Styled";
import CustomInput from "Components/CustomInput";
import DropDownComponent from "Components/DropDownComponent";
import Label from "Components/Label";

import downArrow from "Assets/svg/blue-drop-down-arrow.svg";
import customStyles from "Assets/styles/dropdown/customStyles";
import offSetImg from "Assets/svg/man-user-1.svg";
import { mainBody, selectWrapper, buttonWrapper } from "./style";
import ErrorField from "Components/ErrorField";
import CustomButton from "Components/CustomButton";
import { getAllCountries } from "Store/actions/country.action";
import * as Loading from "Store/constants/loadingState.constants";
import { useHistory } from "react-router-dom";
import { createNewUser } from "Store/actions/user.action";
import { userStatus } from "Utils/constants";
import BackArrow from "Components/BackArrow";

const CreateUser = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(!passwordShown);
  };
  const { country, user, profile, theme, domainBrand } = useSelector(
    ({ countryReducer, userReducer, profileReducer, themeReducer, domainBrandReducer }) => ({
      country: countryReducer,
      user: userReducer,
      profile: profileReducer,
      theme: themeReducer,
      domainBrand: domainBrandReducer
    })
  );

  const languageDictionary = domainBrand.domainBrand["languageDictionary"];
  const languageDictionaryStatus = !(typeof languageDictionary === "undefined" || languageDictionary === null);
  const userAddress = profile["userData"]["address"];

  const formik = useFormik({
    initialValues: {
      email: "",
      firstName: "",
      lastName: "",
      phone: "",
      country: userAddress["countryCommonName"],
      status: "",
      address1: userAddress["address1"],
      address2: userAddress["address2"],
      city: userAddress["city"],
      dialingCode: "",
      password: "",
      confirmPassword: "",
      postcode: userAddress["postcode"]
    },
    validationSchema: Yup.object({
      email: Yup.string().required("required"),
      firstName: Yup.string().required("required"),
      lastName: Yup.string().required("required"),
      phone: Yup.string().required("required"),
      dialingCode: Yup.object({
        value: Yup.string().required("required")
      }),
      password: Yup.string().required("required"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords do not match")
        .required("Required")
    }),

    onSubmit: (values) => {
      const payloadData = {
        email: values.email,
        password: values.password,
        confirmPassword: values.confirmPassword,
        firstName: values.firstName,
        lastName: values.lastName,
        phone: values.phone,
        dialingCode: values.dialingCode.value,
        // customerGender: values.customerGender.value,
        address: {
          address1: values.address1,
          address2: values.address2,
          city: values.city,
          postcode: values.postcode,
          countryCommonName: values.country,
          countryIso3: userAddress["countryIso3"]
        }
      };

      const navigateMethod = () => history.push("/users");
      dispatch(createNewUser(payloadData, navigateMethod));
    }
  });

  useEffect(() => {
    if (country.loading !== Loading.SUCCESS || country.loading === Loading.ERROR) dispatch(getAllCountries());
  }, []);
  const countryLoading = country.loading === Loading.FETCHING;
  const userCreationLoading = user.userCreateStatus === Loading.FETCHING;

  return (
    <Container>
      <NavBar />
      <InnerContainer>
        <OffsetIcon>
          <ImageElement src={offSetImg} title="money icon" />
        </OffsetIcon>

        <Main className="mt-5">
          <div className="d-flex">
            <BackArrow />
            <HeadingText
              text={languageDictionaryStatus ? languageDictionary["WO_ADD_NEW_USE_1247195183"] : "Add a new user"} />
          </div>
          <form css={mainBody} onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-md-6">
                <Label required={true}
                       text={languageDictionaryStatus ? languageDictionary["l_first_name"] : "First Name"}>
                  <CustomInput formik={formik} inputKey="firstName" inputSize={47.5} />
                </Label>
              </div>

              <div className="col-md-6">
                <Label required={true}
                       text={languageDictionaryStatus ? languageDictionary["para_last_name"] : "Last Name"}>
                  <CustomInput formik={formik} inputKey="lastName" inputSize={47.5} />
                </Label>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <Label required={true} text={languageDictionaryStatus ? languageDictionary["l_email"] : "Email"}>
                  <CustomInput
                    formik={formik}
                    inputKey="email"
                    inputSize={47.5}
                    inputType="email"
                  />
                </Label>
              </div>
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-6">
                    <Label required={true} text={languageDictionaryStatus ? languageDictionary["l_password"] : "Password"}>
                      <CustomInput
                        formik={formik}
                        inputKey="password"
                        inputSize={47.5}
                        inputType="password"
                      />
                    </Label>
                  </div>
                  <div className="col-md-6">
                    <Label required={true} text={languageDictionaryStatus ? languageDictionary["para_confirm_password"] : "Confirm Password"}>
                      <CustomInput
                        formik={formik}
                        inputKey="confirmPassword"
                        inputSize={47.5}
                        inputType="password"
                      />
                    </Label>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <p className="benCreate__main__form--inputHeading">
                  {languageDictionaryStatus ? languageDictionary["para_status"] : "Status"}
                  <ErrorField formik={formik} fieldName="status" selectField />
                  <div css={selectWrapper}>
                    <Select
                      value={formik.values.status}
                      onChange={(option) => formik.setFieldValue("status", option)}
                      onBlur={() => formik.setFieldTouched("status", true)}
                      options={userStatus}
                      styles={customStyles}
                      isClearable={false}
                      isSearchable={false}
                      className="mt-3"
                      customTheme={theme}
                      placeholder="Select status"
                      components={{
                        IndicatorSeparator: null,
                        DropdownIndicator: () => <DropDownComponent iconSource={downArrow} />
                      }}
                    />
                  </div>
                </p>

                {/* <Label
                  text={languageDictionaryStatus ? languageDictionary["para_status"] : "Status"}>
                  <ErrorField formik={formik} fieldName="status" selectField />{" "}
                  <div css={selectWrapper}>
                    <Select
                      value={formik.values.status}
                      onChange={(option) => formik.setFieldValue("status", option)}
                      onBlur={() => formik.setFieldTouched("status", true)}
                      options={userStatus}
                      styles={customStyles}
                      isClearable={false}
                      isSearchable={false}
                      className="mt-3"
                      customTheme={theme}
                      placeholder="Select status"
                      components={{
                        IndicatorSeparator: null,
                        DropdownIndicator: () => <DropDownComponent iconSource={downArrow} />
                      }}
                    />
                  </div>
                </Label> */}
              </div>
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-6">
                    <Label required={true} text={"Dialling Code"}>
                      <ErrorField
                        formik={formik}
                        fieldName="dialingCode"
                        fetchingData={countryLoading}
                        selectField
                      />{" "}
                      <div css={selectWrapper}>
                        <Select
                          value={formik.values.dialingCode}
                          onChange={(option) => formik.setFieldValue("dialingCode", option)}
                          onBlur={() => formik.setFieldTouched("dialingCode", true)}
                          options={country.countryCodeDropdownList}
                          styles={customStyles}
                          isClearable={false}
                          className="mt-3"
                          //isSearchable={false}
                          customTheme={theme}
                          isLoading={countryLoading}
                          placeholder="Select country code"
                          loadingMessage={() => "Fetching country codes"}
                          components={{
                            IndicatorSeparator: null,
                            DropdownIndicator: () => <DropDownComponent iconSource={downArrow} />
                          }}
                        />
                      </div>
                    </Label>
                  </div>

                  <div className="col-md-6">
                    <Label required={true}
                           text={languageDictionaryStatus ? languageDictionary["para_phone_number"] : "Phone Number"}>
                      <CustomInput formik={formik} inputKey="phone" inputSize={47.5} />
                    </Label>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <Label required={true}
                       text={languageDictionaryStatus ? languageDictionary["WO_COUNTR_1271562677"] : "Country"}>
                  <div css={selectWrapper}>
                    <CustomInput disabled={true} formik={formik} inputKey="country" inputSize={47.5} />
                  </div>
                </Label>
              </div>

              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-6">
                    <Label text={languageDictionaryStatus ? languageDictionary["para_city"] : "City"}>
                      <CustomInput disabled={true} formik={formik} inputKey="city" inputSize={47.5} />
                    </Label>
                  </div>

                  <div className="col-md-6">
                    <Label required={false}
                           text={languageDictionaryStatus ? languageDictionary["POSTCOD_1360880751"] : "Postcode"}>
                      <CustomInput disabled={true} formik={formik} inputKey="postcode" inputSize={47.5} />
                    </Label>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <Label required={true}
                       text={languageDictionaryStatus ? languageDictionary["para_address_line_1"] : "Address Line 1"}>
                  <CustomInput disabled={true} formik={formik} inputKey="address1" inputSize={47.5} />
                </Label>
              </div>
              <div className="col-md-6">
                <Label required={false}
                       text={languageDictionaryStatus ? languageDictionary["para_address_line_2"] : "Address Line 2"}>
                  <CustomInput disabled={true} formik={formik} inputKey="address2" inputSize={47.5} />
                </Label>
              </div>
            </div>
            <div css={buttonWrapper}>
              <CustomButton buttonText="CREATE USER" loading={userCreationLoading} />
            </div>
          </form>
        </Main>
      </InnerContainer>
      <Footer />
    </Container>
  );
};

export default CreateUser;

import styled from "@emotion/styled";

export const NextButton = styled.button`
  font-size: 0.9rem;
`;

export const Input = styled.input`
  width: 100%;
  border: 0;
  background: #e3edf3;
  border-radius: 5px;
  padding: 1.5rem;
  font-size: 16px;
  font-weight: 400;

  ::placeholder {
    color: #6c757d;
  }
`;

export const CardBorder = styled.div`
 border: 4px solid #E3EDF3;
`;

export const SanctionCard = styled.div`
  border: 4px dotted #0FCC984F;
  background: #0FCC9817;
  padding: 2rem 3rem;

`;
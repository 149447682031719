import { ActionCreator } from 'Utils/Helpers';
import * as Compliance from 'Store/constants/compliance.constants';
import { fetchComplianceForm, fetchRequiredFieldsByType } from "Utils/Services/Compliance.service";

export const fetchComplianceRequiredFields = (type) => async (dispatch) => {
    try {
        dispatch(ActionCreator(Compliance.GET_REQUIRED_COMPLIANCE_FIELDS_REQUEST));
        const { data : responseData } = await fetchRequiredFieldsByType(type);

        if (responseData.status === 'FAILED') throw responseData;
        dispatch(ActionCreator(Compliance.GET_REQUIRED_COMPLIANCE_FIELDS_SUCCESS, responseData.data));
    } catch (error) {
        dispatch(ActionCreator(Compliance.GET_REQUIRED_COMPLIANCE_FIELDS_FAILURE));
    }
};

export const getAllComplianceForm = () => async (dispatch) => {
    try {
        dispatch(ActionCreator(Compliance.GET_COMPLIANCE_REQUEST));
        const { data : responseData } = await fetchComplianceForm();

        const {data : { awaitingComplianceActions },} = responseData;
        if (responseData.status === 'FAILED'){
            throw responseData;
        }
        dispatch(ActionCreator(Compliance.GET_COMPLIANCE_SUCCESS, awaitingComplianceActions));
    } catch (error) {
        dispatch(ActionCreator(Compliance.GET_COMPLIANCE_FAIL));
    }
};


export const updateUploadLater = () => async (dispatch) => {
    try {
        dispatch(ActionCreator(Compliance.UPLOAD_COMPLIANCE_LATER_SUCCESS));
    } catch (error) {
        dispatch(ActionCreator(Compliance.UPLOAD_COMPLIANCE_LATER_FAIL));
    }
}

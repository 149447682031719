import { toast } from "react-toastify";

import AuthService from "Utils/Services/Auth.service";
import * as BaseURL from "../../Utils/Services/BaseUrl";
import { ActionCreator, TokenHelpers, SetAuthHeader } from "Utils/Helpers";
import cookieHelper from "../../Utils/Helpers/cookieHelper";
import { fetchAppLanguage } from "../../Utils/Helpers/general";
import config from "../../Utils/Helpers/config";
import * as AuthConstants from "Store/constants/auth.constants";
import { getDomainBrandIdentity } from "./domain-brand.action";
import { getProfile } from "./profile.action";
import * as CartActions from "./cart.action";
import setAuthHeader from "../../Utils/Helpers/setAuthHeader";

export const loginUser = (payload, navigate) => async (dispatch) => {
  try {
    dispatch(ActionCreator(AuthConstants.LOGIN_REQUEST));
    const { data: responseData } = await AuthService.login(payload);

    if (responseData.status === "FAILED" && responseData.errorCode === "LOGIN_OTP_REQUIRE_VERIFICATION") {
      dispatch(ActionCreator(AuthConstants.AWAITING_TOKEN));
      const authToken = responseData.data.token;
      await setAuthHeader(authToken);
      return;
    } else if (responseData.status === "FAILED") throw responseData;


    let userIsIndividual = "INDIVIDUAL";
    if (responseData.data["profileDetailsResponseDTO"] !== null) {
      userIsIndividual = responseData.data["profileDetailsResponseDTO"].customerType === "INDIVIDUAL" ? "INDIVIDUAL" : "NON-INDIVIDUAL";
    }

    await loginSuccessAction(responseData, navigate, dispatch);
  } catch (error) {
    const message = "User tries to login with a different domain or user details are not valid";
    const failedMsg = ("message" in error) ? error.message : "Internal server error";
    const response = failedMsg === null ? message : failedMsg;
    dispatch(ActionCreator(AuthConstants.LOGIN_FAILURE));
    toast.error(response, { position: toast.POSITION.TOP_CENTER, autoClose: false });
    console.error(error);
  }
};

export const loginSuccessAction = async (responseData, navigate, dispatch) => {
  const verificationStatus = responseData.data["profileDetailsResponseDTO"]["userAccountVerified"];
  const userType = responseData.data["profileDetailsResponseDTO"]["userType"];
  const userAccountVerified = (userType === "CASHIER") ? "VERIFIED" : verificationStatus ? "VERIFIED" : "UNVERIFIED";
  await cookieHelper.set(config.USER_VERIFIED_STATUS, userAccountVerified, config.COOKIE_EXPIRY_PERIOD);
  dispatch(ActionCreator(AuthConstants.LOGIN_SUCCESS, responseData.data));
  const authToken = responseData.data.token;
  await TokenHelpers.saveToken(authToken);
  await setLoggedInUser(null, dispatch, "Login");
  navigate();
};

export const setLoggedInUser = async (store, dispatch, type) => {
  let subdomain =
    cookieHelper.get(config.DOMAIN_DATA) === null || cookieHelper.get(config.DOMAIN_DATA) === ""
      ? BaseURL.DEFAULT_BASEURL
      : cookieHelper.get(config.DOMAIN_DATA);
  let languageOption = fetchAppLanguage();
  const token = TokenHelpers.getToken();
  const tokenExists = TokenHelpers.checkIfLoggedIn();

  if (type === "Login") {
    if (tokenExists) {
      await SetAuthHeader(token);
      await dispatch(ActionCreator(AuthConstants.SET_LOGGED_IN_USER));
      await dispatch(getProfile());
      await dispatch(CartActions.getItemsInCart());
      // dispatch(getDomainBrandIdentity(subdomain, languageOption["id"]));
    }
  } else if (type === "Reload") {
    if (tokenExists) {
      await SetAuthHeader(token);
      await store.dispatch(ActionCreator(AuthConstants.SET_LOGGED_IN_USER));
      await store.dispatch(getProfile());
      await store.dispatch(CartActions.getItemsInCart());
      // store.dispatch(getDomainBrandIdentity(subdomain, languageOption["id"]));
    }
  }
};

export const validateOtp = (payload, navigate) => async (dispatch) => {
  try {
    dispatch(ActionCreator(AuthConstants.VALIDATE_OTP_REQUEST));
    const {data: responseData} = await AuthService.otpVerification(payload);
    if (responseData.status === "FAILED") throw responseData;

    dispatch(ActionCreator(AuthConstants.VALIDATE_OTP_SUCCESS));
    await loginSuccessAction(responseData, navigate, dispatch);

  } catch (error) {
    dispatch(ActionCreator(AuthConstants.VALIDATE_OTP_FAILURE));
    toast.error(error.message);
  }
}

export const forgotPassword =
  ({ email }) =>
    async (dispatch) => {
      try {
        dispatch(ActionCreator(AuthConstants.FORGOT_PASSWORD_REQUEST));
        const { data: responseData } = await AuthService.forgotPassword({ email });

        if (responseData.status === "FAILED") {
          throw responseData;
        }

        dispatch(ActionCreator(AuthConstants.FORGOT_PASSWORD_SUCCESS, { email, responseData }));
      } catch (error) {
        dispatch(ActionCreator(AuthConstants.FORGOT_PASSWORD_FAILURE, { email }));
        toast.error("Invalid email", {
          position: toast.POSITION.TOP_CENTER
        });
      }
    };

export const logUserOut = (url) => async (dispatch) => {
  const logOutUrl = url === null || url === "" || typeof url === "undefined" ? "/login" : url;
  try {
    await AuthService.logout();
    window.location.href = logOutUrl;
    TokenHelpers.deleteToken();
    TokenHelpers.clearAllTokens();
    dispatch(ActionCreator(AuthConstants.LOG_OUT));
  } catch (error) {
    console.error("Error attempting to log out user");
  }
};

export const expiredTokenCheck = (error, dispatch) => {
  if (error.message && error.message.indexOf("Supplied token NOT found X-Auth-Token") > -1) {
    dispatch(logUserOut());
    toast.error("Logged out due to inactivity", {
      position: toast.POSITION.TOP_CENTER,
      toastId: "session_expired_id"
    });
  }
};

import * as Compliance from 'Store/constants/compliance.constants'
import * as Loading from 'Store/constants/loadingState.constants'


const initialState = {
    complianceFormList: [],
    dynamicFields: [],
    loading: '',
    uploadLater: false,
};

const complianceReducer = (state = initialState, action) => {
    switch (action.type){
        case Compliance.GET_REQUIRED_COMPLIANCE_FIELDS_REQUEST:
            return {
                ...state,
                loading: Loading.FETCHING,
            }

        case Compliance.GET_REQUIRED_COMPLIANCE_FIELDS_SUCCESS:
            return {
                ...state,
                dynamicFields: action.payload,
                loading: Loading.SUCCESS,
            }

        case Compliance.GET_REQUIRED_COMPLIANCE_FIELDS_FAILURE:
            return {
                ...state,
                loading : Loading.ERROR,
            }

        case Compliance.GET_COMPLIANCE_REQUEST:
            return {
                ...state,
                loading: Loading.FETCHING,
            };

        case Compliance.GET_COMPLIANCE_SUCCESS:
            return {
                ...state,
                complianceFormList: action.payload,
                loading: Loading.SUCCESS,
            }
        case Compliance.GET_COMPLIANCE_FAIL:
            return {
                ...state,
                loading : Loading.ERROR,
            }
        case Compliance.UPLOAD_COMPLIANCE_LATER_SUCCESS:
            return {
                ...state,
                uploadLater: true,
            }

        default:
            return state;
    }
}

export default complianceReducer;

import * as Profile from "Store/constants/profile.constants";
import * as Loading from "Store/constants/loadingState.constants";

export const modifyUserProfile = (userData, imageUrl) => {
  if (imageUrl !== null) {
    userData["profileImage"] = imageUrl;
    return userData;
  }
  return userData;
};

const initialState = {
  userProfileLoading: "",
  userData: {},
  editProfileStatus: "",
};

const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case Profile.FETCH_USER_PROFILE_REQUEST:
      return {
        ...state,
        userProfileLoading: Loading.FETCHING,
        userData: {},
      };

    case Profile.FETCH_USER_PROFILE_SUCCESS:
      return {
        ...state,
        userProfileLoading: Loading.SUCCESS,
        userData: action.payload,
        userAccountVerified: action.payload.userAccountVerified,
      };

    case Profile.FETCH_USER_PROFILE_FAILURE:
      return {
        ...state,
        userProfileLoading: Loading.ERROR,
        userData: {},
      };

    case Profile.EDIT_USER_PROFILE_REQUEST:
      return {
        ...state,
        editProfileStatus: Loading.FETCHING,
        // userData: {}
      };

    case Profile.EDIT_USER_PROFILE_SUCCESS:
      return {
        ...state,
        editProfileStatus: Loading.SUCCESS,
        // userData: action.payload
      };

    case Profile.EDIT_USER_PROFILE_FAILURE:
      return {
        ...state,
        editProfileStatus: Loading.ERROR,
        // userData: {}
      };

    case Profile.SET_USER_PROFILE_PICTURE_SUCCESS:
      const formattedProfile = modifyUserProfile(state.userData, action.payload.userProfileUrl);

      return {
        userData: formattedProfile,
      };

    default:
      return state;
  }
};

export default profileReducer;

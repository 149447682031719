/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import ButtonSpinner from 'Components/ButtonSpinner';

const buttonStyle = ({
  buttonSize,
  marginLeft,
  fontSize,
  marginTop,
  theme,
}) => {
  return css`
    margin-top: ${marginTop || '10px'};
    border: 0;
    background: var(--regular-button-color);
    border-radius: 5px;
    font-size: ${fontSize || '15px'};
    font-weight: 500;
    color: var(--regular-button-text-color);
    padding: 1rem 0.9rem;
    min-width: ${buttonSize};
    margin-left: ${marginLeft || 0};
    transition: opacity 0.2s ease-in-out;
    cursor: pointer;
    :hover {
      background: var(--regular-button-hover-color);
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.7;
    }
  `;
};

const CustomButton = ({ loading, buttonWidth, buttonText, onClick, marginLeft,
  disabled, fontSize, marginTop, type,}) => {
  const buttonSize = buttonWidth || '150px';
  return (
    <button
      type={type || 'submit'}
      disabled={disabled || loading}
      css={(theme) => buttonStyle({ buttonSize, marginLeft, fontSize, marginTop, theme })}
      onClick={onClick || null}
    >
      {loading ? <ButtonSpinner /> : <span>{buttonText}</span>}
    </button>
  );
};

export default CustomButton;

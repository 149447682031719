// eslint-disable-next-line no-unused-vars
import React from 'react';
/** @jsx jsx */
/** @jsxRuntime classic */
import { css, jsx } from '@emotion/react';

import { useSelector } from "react-redux";
import TableButton from 'Components/TableRowButton';
import SearchIcon from 'Assets/svg/search.svg';
import { bottomSection, tableStyle } from './styleSheet';
import { General } from 'Utils/Helpers';
import { useHistory } from "react-router-dom";

const statusColor = (data) => data.status || !data['replyingToId'] ? 'green' : 'red';

const statusGetter = (data) => {
  if (data === 'OUTGOING_MAIL') return 'SENT';
  return data.status ? 'READ' : 'UNREAD';
};

const TableRow = ({ tableData }) => {
  const { senderName, subject, status, sentDate, inBoxDirection } = tableData;
  const history = useHistory();
  const pushObject = {pathname: '/profile/notifications/read', state: { tableData }};

  return (
    <tr>
      <td>
        <span>{senderName}</span>
      </td>
      <td>
        <span>{subject}</span>
      </td>
      <td>
        <span className={statusColor(status)}>{statusGetter(inBoxDirection)}</span>
      </td>
      <td>
        <span>{General.formatDate(sentDate)}</span>
      </td>
      <td>
        <div className="table-row-controls" >
          <TableButton color="#0055AD" icon={SearchIcon} onClick={() => history.push(pushObject)} />
        </div>
      </td>
    </tr>
  );
};

const ViewTable = ({ notifications }) => {

  const { domainBrand } = useSelector(({ domainBrandReducer }) => ({ domainBrand: domainBrandReducer, }));
  const languageDictionary = domainBrand.domainBrand["languageDictionary"];
  const languageDictionaryStatus = !(typeof languageDictionary === "undefined" || languageDictionary === null);

  return (
    <section css={(theme) => bottomSection(theme)}>
      <div css={(theme) => tableStyle(theme)}>
        <table>
          <thead>
            <tr>
              <th scope="col">
                {languageDictionaryStatus ? languageDictionary["para_sent_by"] : "Sent By"}
              </th>
              <th scope="col">
                {languageDictionaryStatus ? languageDictionary["para_subject"] : "Subject"}
              </th>
              <th scope="col">
                {languageDictionaryStatus ? languageDictionary["para_status"] : "Status"}
              </th>
              <th scope="col">
                {languageDictionaryStatus ? languageDictionary["para_date"] : "Date"}
              </th>
              <th />
            </tr>
          </thead>
          <tbody>
            {notifications < 1 ? (
              <>
                <tr>
                  <td colSpan="5" className="text-center bg-grey">
                    <span>No notifications available</span>
                  </td>
                </tr>
              </>
            ) : (
              <>
                {notifications.map((iData) => (
                  <TableRow tableData={iData} key={iData.messageId} />
                ))}
              </>
            )}
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default ViewTable;

import { ActionCreator } from "Utils/Helpers";
import * as Beneficiary from "Store/constants/beneficiary.constants";
import BeneficiaryService from "Utils/Services/Beneficiary.service";
import { getBeneficiaryBankDetails } from "Store/actions/transfer.action";
import * as Transfer from "Store/constants/transfer.constants";
import { toast } from "react-toastify";
import { expiredTokenCheck } from "./auth.action";

export const getAllBeneficiaries = () => async (dispatch) => {
  try {
    dispatch(ActionCreator(Beneficiary.FETCH_BENEFICIARY_REQUEST));
    const { data: responseData } = await BeneficiaryService.fetchAll();
    if (responseData.status === "FAILED") {
      throw responseData;
    }

    const {
      data: { receivers }
    } = responseData;
    dispatch(ActionCreator(Beneficiary.FETCH_BENEFICIARY_SUCCESS, receivers));
  } catch (error) {
    expiredTokenCheck(error, dispatch);

    dispatch(ActionCreator(Beneficiary.FETCH_BENEFICIARY_FAILURE));
    console.error(error);
  }
};

export const createBeneficiary = (
  {
    userRequest,
    navigateMethod,
    bankDetails,
    isCreateBankDetails
  }) => async (dispatch) => {
  try {
    dispatch(ActionCreator(Beneficiary.CREATE_BENEFICIARY_REQUEST));
    const { data: responseData } = await BeneficiaryService.create(userRequest);

    if (responseData.status === "FAILED") {
      throw responseData;
    }

    console.log(responseData);

    const { data } = responseData;
    if (isCreateBankDetails) {
      const { firstName, lastName, companyName, type } = userRequest;
      const accountHolderName = type === "COMPANY" ? companyName : `${firstName} ${lastName}`;

      const bankCreateData = {
        // swiftCode: "",
        accountHolderName,
        accountOwnerCustomerCode: data.beneficiaryCode,
        ...bankDetails
      };
      await BeneficiaryService.addBankDetails(bankCreateData);
    }

    dispatch(ActionCreator(Beneficiary.CREATE_BENEFICIARY_SUCCESS));
    toast.success("Beneficiary Creation Successful", {
      position: toast.POSITION.TOP_CENTER
    });

    navigateMethod();
  } catch (error) {
    expiredTokenCheck(error, dispatch);

    dispatch(ActionCreator(Beneficiary.CREATE_BENEFICIARY_FAILURE));

    const errorMessage = error.message || "Beneficiary Creation Failed";
    toast.error(errorMessage, {
      position: toast.POSITION.TOP_CENTER
    });
  }
};

export const getSupportedBanks =
  ({ countryIso }) =>
    async (dispatch) => {
      try {
        dispatch(ActionCreator(Beneficiary.FETCH_SUPPORTED_BANKS_REQUEST));
        const { data: responseData } = await BeneficiaryService.listSupportedBanks({
          countryIso
        });

        if (responseData.status === "FAILED") {
          throw responseData;
        }

        const {
          data: { bankDetails }
        } = responseData;
        dispatch(ActionCreator(Beneficiary.FETCH_SUPPORTED_BANKS_SUCCESS, bankDetails));
      } catch (error) {
        expiredTokenCheck(error, dispatch);

        dispatch(ActionCreator(Beneficiary.FETCH_SUPPORTED_BANKS_FAILURE));
        console.error(error);
      }
    };

export const getBeneficiaryDetails =
  ({ beneficiaryId }) =>
    async (dispatch) => {
      try {
        dispatch(ActionCreator(Beneficiary.FETCH_BENEFICIARY_DETAILS_REQUEST));
        const { data: responseData } = await BeneficiaryService.fetchBeneficiaryDetails({
          beneficiaryId
        });

        if (responseData.status === "FAILED") {
          throw responseData;
        }

        const { data } = responseData;

        dispatch(ActionCreator(Beneficiary.FETCH_BENEFICIARY_DETAILS_SUCCESS, data));
        // getBeneficiaryBankDetails(data.senderCode);
      } catch (error) {
        expiredTokenCheck(error, dispatch);

        dispatch(ActionCreator(Beneficiary.FETCH_BENEFICIARY_DETAILS_FAILURE));
        console.error(error);
      }
    };

/*
export const updateBeneficiary = ({ userRequest, navigateMethod, bankDetails, isCreateBankDetails }) =>
  async (dispatch) => {
    try {
      dispatch(ActionCreator(Beneficiary.UPDATE_BENEFICIARY_REQUEST));
      const { data: responseData } = await BeneficiaryService.update(userRequest);

      if (responseData.status === "FAILED") throw responseData;
      const { data } = responseData;

      /!*if (isCreateBankDetails) {
        const { firstName, lastName, companyName, type } = userRequest;
        const accountHolderName = type === "COMPANY" ? companyName : `${firstName} ${lastName}`;

        const bankCreateData = {
          swiftCode: "",
          accountHolderName,
          accountOwnerCustomerCode: data.beneficiaryCode,
          ...bankDetails
        };
        await BeneficiaryService.updateBankDetails(bankCreateData);
      }*!/

      dispatch(ActionCreator(Beneficiary.UPDATE_BENEFICIARY_SUCCESS));
      toast.success("Beneficiary details updated successfully", { position: toast.POSITION.TOP_CENTER });
      navigateMethod();
    } catch (error) {
      expiredTokenCheck(error, dispatch);

      dispatch(ActionCreator(Beneficiary.UPDATE_BENEFICIARY_FAILURE));
      const errorMessage = error.message || "Beneficiary Update Failed";
      toast.error(errorMessage, {
        position: toast.POSITION.TOP_CENTER
      });
    }
  };*/

export const updateBeneficiary = (payload, navigateMethod) => async (dispatch) => {
  try {
    dispatch(ActionCreator(Beneficiary.UPDATE_BENEFICIARY_REQUEST));
    const { data: responseData } = await BeneficiaryService.update(payload);

    if (responseData.status === "FAILED") throw responseData;

    dispatch(ActionCreator(Beneficiary.UPDATE_BENEFICIARY_SUCCESS));
    toast.success("Beneficiary details updated successfully", { position: toast.POSITION.TOP_CENTER });
    navigateMethod();
  } catch (error) {
    expiredTokenCheck(error, dispatch);

    dispatch(ActionCreator(Beneficiary.UPDATE_BENEFICIARY_FAILURE));
    const errorMessage = error.message || "Beneficiary Update Failed";
    toast.error(errorMessage, { position: toast.POSITION.TOP_CENTER });
  }
};

export const createBeneficiaryBankDetails = (payload, navigateMethod) => async (dispatch) => {
  try {
    dispatch(ActionCreator(Beneficiary.UPDATE_BENEFICIARY_REQUEST));
    const { data: responseData } = await BeneficiaryService.addBankDetails(payload);

    if (responseData.status === "FAILED") throw responseData;

    dispatch(ActionCreator(Beneficiary.UPDATE_BENEFICIARY_SUCCESS));
    toast.success("Beneficiary Bank Details Updated Successful", { position: toast.POSITION.TOP_CENTER });
    navigateMethod();
  } catch (error) {
    expiredTokenCheck(error, dispatch);

    dispatch(ActionCreator(Beneficiary.UPDATE_BENEFICIARY_FAILURE));
    const errorMessage = error.message || "Beneficiary Update Failed";
    toast.error(errorMessage, { position: toast.POSITION.TOP_CENTER });
  }
};

export const updateBeneficiaryBankDetails = (payload, navigateMethod) => async (dispatch) => {
  try {
    dispatch(ActionCreator(Beneficiary.UPDATE_BENEFICIARY_REQUEST));
    const { data: responseData } = await BeneficiaryService.updateBankDetails(payload);

    if (responseData.status === "FAILED") throw responseData;

    dispatch(ActionCreator(Beneficiary.UPDATE_BENEFICIARY_SUCCESS));
    toast.success("Beneficiary Bank Details Updated Successful", { position: toast.POSITION.TOP_CENTER });
    navigateMethod();
  } catch (error) {
    expiredTokenCheck(error, dispatch);

    dispatch(ActionCreator(Beneficiary.UPDATE_BENEFICIARY_FAILURE));
    const errorMessage = error.message || "Beneficiary Update Failed";
    toast.error(errorMessage, { position: toast.POSITION.TOP_CENTER });
  }
};

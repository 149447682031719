import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCountryList } from "Store/actions/bill.action";
import { Helmet } from "react-helmet";
import Footer from "Components/Footer";
import NavBar from "Components/Navbar";
import Loader from "Components/loader/Loader";
import CountryFlagCard from "Components/CountryFlagCard";
import Spinner from "Components/Spinner";
import styled from "@emotion/styled";
import cookieHelper from "Utils/Helpers/cookieHelper";
import config from "Utils/Helpers/config";
import BackArrow from "Components/BackArrow";

const Input = styled.input`
  width: 100%;
  margin-top: 12px;
  border: 0;
  background: #e3edf3;
  border-radius: 5px;
  padding: 1.5rem;
  font-size: 16px;
  font-weight: 400;

  ::placeholder {
    color: #6c757d;
  }
`;

const BillDestinationCountry = () => {
  const dispatch = useDispatch();
  const [filteredCountries, setFilteredCountries] = useState(null);
  const { bill, domainBrand } = useSelector(({ billReducer, domainBrandReducer }) => ({
    bill: billReducer,
    domainBrand: domainBrandReducer
  }));

  // Language state
  const languageDictionary = domainBrand.domainBrand["languageDictionary"];
  const languageDictionaryStatus = !(
    typeof languageDictionary === "undefined" || languageDictionary === null
  );

  useEffect(() => {
    const tradeOriginCountry = cookieHelper.get(config.USER_COUNTRY);
    if (bill.loadingStatus !== "SUCCESS") {
      dispatch(getCountryList(tradeOriginCountry));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = (event) => {
    const { value } = event.target;
    if (!value) {
      setFilteredCountries(null);
      return;
    }
    const searchTerm = value.toLowerCase();
    const filtered = bill.countries.filter((country) => {
      const countryName = country["common_name"].toLowerCase();
      const countryIso3Code = country["iso3Code"].toLowerCase();
      return countryName.includes(searchTerm) || countryIso3Code.includes(searchTerm);
    });
    setFilteredCountries(filtered);
  };

  return (
    <>
      <Helmet>
        <title>
          {languageDictionaryStatus
            ? languageDictionary["DESTINATION_COUNTR_1477464105"]
            : "Destination Country"}
        </title>
      </Helmet>
      <NavBar />
      <section>
        {bill.loadingStatus === "FETCHING" ? (
          <Loader />
        ) : (
          <div className="container">
            <div className="mt-5 mb-5">
              <div className="d-flex">
                <BackArrow />
                <h2 className="text-blue mt-4">
                  {languageDictionaryStatus
                    ? languageDictionary["WO_BILL_PAYMEN_-1233806888"]
                    : "Bill Payment"}
                </h2>
              </div>
              <div className="card mt-5 p-xl-5">
                <div className="card-body mb-3 p-md-3">
                  {/* --- Search box for destination country --- */}
                  <div className="row mb-5">
                    <div className="col-12 col-md-6">
                      <p className="text-blue">
                        {languageDictionaryStatus
                          ? languageDictionary["DESTINATION_COUNTR_1477464105"]
                          : "Destination Country"}
                      </p>
                      <Input
                        type="text"
                        className="form-control"
                        placeholder="Search for country..."
                        onChange={handleSearch}
                      />
                    </div>
                  </div>

                  {/* --- Search box for destination country --- */}
                  <div className="row">
                    <div className="col-12">
                      <p className="text-blue ">
                        {languageDictionaryStatus
                          ? languageDictionary["msg_please_select_a_country"]
                          : "Select a country"}
                      </p>

                      {bill.loadingStatus === "FETCHING" ? (
                        <Spinner containerHeight="30vh" />
                      ) : bill.countries.length === 0 ? (
                        <p className="text-center h3">No countries available now</p>
                      ) : (
                        <div className="row">
                          {(filteredCountries || bill.countries).map((country) => {
                            return (
                              <CountryFlagCard
                                key={country["iso3Code"]}
                                text={country["iso3Code"]}
                                link={`/billpayment/services?country=${country["iso3Code"]}`}
                                params={country["iso3Code"]}
                                flag={country.flag}
                                country={country["common_name"]}
                              />
                            );
                          })}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </section>

      <Footer />
    </>
  );
};

export default BillDestinationCountry;

import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useSelector, useDispatch } from "react-redux";
import { Redirect, useHistory, useLocation, Link, NavLink } from "react-router-dom";
import Select from "react-select";
import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

import Styles from "./index.module.scss";
import customStyles from "Assets/styles/dropdown/customStyles";
import ButtonSpinner from "Components/ButtonSpinner";
import fundsImg from "Assets/svg/fundsImage.svg";
import fundsBlueImg from "Assets/svg/fundsBlue.svg";
import googleLogo from "Assets/svg/google-logo.svg";
import { loginUser, validateOtp } from "Store/actions/auth.action";
import * as Loading from "Store/constants/loadingState.constants";
import * as DomainBrand from "Store/actions/domain-brand.action";
import { dynamicFieldsFormikGetKeys, fetchAppLanguage, languageChangeHelper } from "Utils/Helpers/general";
import config from "../../../Utils/Helpers/config";
import Loader from "../../../Components/loader/Loader";
import DropDownComponent from "../../../Components/DropDownComponent";
import facebook from "Assets/svg/facebook.svg";
import downArrow from "../../../Assets/svg/blue-drop-down-arrow.svg";
import { BrandedRegularButton } from "../../../Components/BrandingComponents";
import { ActionCreator, TokenHelpers } from "../../../Utils/Helpers";
import { fetchDynamicFields } from "../../../Store/actions/dynamicFields.action";
import DynamicFields from "../../../Components/DynamicFields";
import { useFormik } from "formik";
import AuthService from "../../../Utils/Services/Auth.service";
import { toast } from "react-toastify";
import * as AuthConstants from "../../../Store/constants/auth.constants";

const Login = () => {
  let domainBrandStatus, brandLangSelectorOptions, showSpinner, allDynamicFields,
    dynamicFormType = "REGISTERED_CUSTOMER_LOGIN";
  let selectedLanguage = fetchAppLanguage(), isLoggedIn = TokenHelpers.checkIfLoggedIn();
  const { authSelector, domainBrand, dynamicFields, theme } = useSelector((
    {
      authReducer,
      domainBrandReducer,
      dynamicFieldsReducer,
      themeReducer
    }) => ({
    authSelector: authReducer,
    domainBrand: domainBrandReducer,
    dynamicFields: dynamicFieldsReducer,
    theme: themeReducer
  }));
  const dispatch = useDispatch(), history = useHistory(), location = useLocation();
  const [requireToken, setRequireToken] = useState(false);
  const [emailField, updateEmailField] = useState("");
  const [passwordField, updatePasswordField] = useState("");
  const [otp, updateOTP] = useState("");
  const [rememberMeField, updateRememberMeField] = useState(false);
  const [languageField, updateLanguageField] = useState(selectedLanguage["commonName"]);
  const socialMediaGoogleStatus = domainBrand["domainBrand"]["isGoogleLoginEnabled"];
  const socialMediaFacebookStatus = domainBrand["domainBrand"]["isFacebookLoginEnabled"];
  const resetOtp = event => {
    event.preventDefault();
    dispatch(ActionCreator(AuthConstants.DISABLED_AWAITING_TOKEN));
    setRequireToken(false);
  };

  useEffect(() => {
    setRequireToken(authSelector.awaitingToken);
  }, [authSelector.awaitingToken]);

  useEffect(() => {
    dispatch(fetchDynamicFields(dynamicFormType));
  }, [dispatch]);

  const formik = useFormik({
    initialValues: {},
    onSubmit: (values) => {
      let dynamicValues = dynamicFieldsFormikGetKeys(dynamicFields[dynamicFormType]["dynamicFields"], values);
    }
  });

  showSpinner = domainBrand.loading === Loading.FETCHING;
  domainBrandStatus = domainBrand.loading === Loading.SUCCESS;
  allDynamicFields = dynamicFields.loading === Loading.FETCHING || typeof dynamicFields[dynamicFormType] === "undefined" ? [] : dynamicFields[dynamicFormType]["dynamicFields"];

  if (isLoggedIn) return <Redirect to={{ pathname: "/dashboard" }} />;
  const isButtonDisabled = authSelector.loginStatus === Loading.FETCHING;
  brandLangSelectorOptions = domainBrand.loading === Loading.SUCCESS ? domainBrand["domainBrand"]["language"] : {
    value: "EN", label: "ENGLISH"
  };

  const handleLanguageChange = (option) => {
    const languageHelper = languageChangeHelper(option);
    updateLanguageField(languageHelper["value"]);
    dispatch(DomainBrand.getDomainBrandIdentity(languageHelper["subdomain"], languageHelper["value"]["id"]));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let { from } = location.state || { from: { pathname: "/dashboard" } };
    const navigateMethod = () => history.replace(from);
    const payload = { email: emailField, password: passwordField };
    dispatch(loginUser(payload, navigateMethod));
  };

  const validateLoginOTP = async event => {
    event.preventDefault();
    const navigateMethod = () => history.push("/dashboard");
    await dispatch(validateOtp({ otp }, navigateMethod));
  };

  const resendOTP = async event => {
    event.preventDefault();
    const { data: responseData } = await AuthService.resendOtp();
    if (responseData.status !== Loading.SUCCESS) toast.error(`Could not resend otp`);
    else toast.success(`A new otp has been sent to your email`);
  };

  const renderDynamicFields = allDynamicFields.map((eachField, index) => {
    return <DynamicFields key={index} theme={theme} eachField={eachField} formik={formik} />;
  });

  const toggleRemember = e => {
    e.preventDefault();
    updateRememberMeField(!rememberMeField);
  };

  const googleOAuthResponse = (response) => {
    console.log(response);
  };

  const facebookOAuthResponse = (response) => response.socialLogin = { status: true, type: "FacebookOAuth" };

  const LanguageSelector = () => {
    return (
      <>
        <span className={Styles.lang}>
          {domainBrandStatus ? (
            <Select
              options={brandLangSelectorOptions}
              styles={customStyles}
              isClearable={false}
              isSearchable={true}
              name={languageField}
              value={selectedLanguage}
              customTheme={""}
              placeholder="Select language"
              onChange={(option) => {
                handleLanguageChange(option);
              }}
              components={{
                IndicatorSeparator: null,
                DropdownIndicator: () => <DropDownComponent iconSource={downArrow} />
              }}
            />
          ) : (
            <Select
              options={brandLangSelectorOptions}
              styles={customStyles}
              isClearable={false}
              isSearchable={true}
              name={languageField}
              value={selectedLanguage}
              customTheme={""}
              placeholder="Select language"
              onChange={(option) => {
                handleLanguageChange(option);
              }}
              components={{
                IndicatorSeparator: null,
                DropdownIndicator: () => <DropDownComponent iconSource={downArrow} />
              }}
            />
          )}
        </span>
      </>
    );
  };

  return (
    <>
      {showSpinner ? (
        <div className={Styles.modSpinner}>
          <Loader />
        </div>
      ) : (
        <div className={Styles.login}>
          <Helmet>
            <title>
              Login into your account &mdash;{" "}
              {domainBrandStatus ? domainBrand["domainBrand"]["companyName"] : "MultiView MTA"}
            </title>
          </Helmet>
          <>
            <section className={Styles.leftSection}>
              <div className={Styles.topContainer}>
                <div className="d-flex justify-content-between">
                  <div className={Styles.logo}>
                    <img
                      src={domainBrandStatus ? domainBrand["domainBrand"]["logo"] : "MultiView MTA"}
                      alt={domainBrandStatus ? domainBrand["domainBrand"]["companyName"] : "MultiView MTA"}
                      className="img-fluid"
                      style={{ width: "10rem" }}
                    />
                  </div>
                  <div className="d-block d-md-none">
                    <LanguageSelector />
                  </div>
                </div>

                {requireToken ?
                  <div className={Styles.Heading}>
                    <p>
                      {domainBrandStatus ? domainBrand["domainBrand"]["languageDictionary"]["WO_VALIDATE_OT_1624166261"] : "Enter OTP"}
                    </p>
                    <p>Enter the security code we sent to your email address.</p>
                  </div> :
                  <div className={Styles.Heading}>
                    <p>{domainBrandStatus ? domainBrand["domainBrand"]["languageDictionary"]["b_login"] : "Login"}</p>
                    <p>
                      {domainBrandStatus ? domainBrand["domainBrand"]["languageDictionary"]["para_enter_your_creditentials_email_and_password"]
                        : "Enter your details to start banking"}
                    </p>
                  </div>
                }

                {requireToken ?
                  <form className={Styles.form}>
                    <div className={Styles.inputGroup}>
                      <input
                        type="otp"
                        id="otp"
                        placeholder={domainBrandStatus ? domainBrand["domainBrand"]["languageDictionary"]["WO_ENTER_OT_430460335"] : "Enter your otp"}
                        required
                        value={otp}
                        onChange={e => updateOTP(e.target.value)}
                      />
                    </div>

                    <div className={Styles.noPasswordSection}>
                      <div>
                        <NavLink onClick={resendOTP} to="">
                          <span>{"I didn't receive a code"}</span>
                        </NavLink>
                      </div>
                    </div>

                    <div className={Styles.loginButton}>
                      <button onClick={validateLoginOTP} disabled={isButtonDisabled} type="button">
                        {isButtonDisabled ? <ButtonSpinner /> :
                          <span>
                          {domainBrandStatus ? domainBrand["domainBrand"]["languageDictionary"]["WO_VALIDATE_OT_1624166261"] : "Validate OTP"}
                        </span>
                        }
                      </button>
                    </div>
                  </form> :
                  <form className={Styles.form} onSubmit={handleSubmit}>
                    <div className={Styles.inputGroup}>
                      <label htmlFor="emailAddress" className="form-label d-none">
                        Email Address
                      </label>
                      <input
                        type="email"
                        id="emailAddress"
                        placeholder={domainBrandStatus ? domainBrand["domainBrand"]["languageDictionary"]["msg_enter_your_email"] : "Enter your email address"}
                        required
                        value={emailField}
                        onChange={(e) => updateEmailField(e.target.value)}
                      />
                    </div>

                    <div className={Styles.inputGroup}>
                      <label htmlFor="password" className="form-label d-none">
                        Password
                      </label>
                      <input
                        type="password"
                        id="password"
                        placeholder={domainBrandStatus ? domainBrand["domainBrand"]["languageDictionary"]["msg_enter_password"] : "Enter your password"}
                        required
                        value={passwordField}
                        onChange={(e) => updatePasswordField(e.target.value)}
                      />
                    </div>

                    {renderDynamicFields}
                    <div>
                      <label>
                        <input type="checkbox" value={rememberMeField} onBlur={toggleRemember} />{" "}
                        Remember me
                      </label>
                    </div>

                    <div className={Styles.noPasswordSection}>
                      <div>
                        <p>
                          <Link to="/sign-up">
                          <span>
                            {domainBrandStatus ? domainBrand["domainBrand"]["languageDictionary"]["WO_SIGN_UP_FOR_AN_ACCOU_-546145385"] : "Sign Up for an account"}
                          </span>
                          </Link>
                        </p>
                      </div>
                    </div>

                    <div className={Styles.loginButton}>
                      <button disabled={isButtonDisabled} type="submit">
                        {isButtonDisabled ? <ButtonSpinner /> :
                          <span>
                          {domainBrandStatus ? domainBrand["domainBrand"]["languageDictionary"]["b_login"] : "Log in"}
                        </span>
                        }
                      </button>
                    </div>
                  </form>
                }

                {requireToken ?
                  <div className="text-center mt-0 mb-3">
                    <NavLink onClick={resetOtp} to="">
                      {"Attempt another login"}
                    </NavLink>
                  </div> :
                  <div className="text-center mt-0 mb-3">
                    <Link to="/forgot-password">
                      {domainBrandStatus ? domainBrand["domainBrand"]["languageDictionary"]["forgot_password"] + "?" : "Forgot Password?"}
                    </Link>
                  </div>
                }

                {socialMediaGoogleStatus || socialMediaFacebookStatus ?
                  <div className="text-center text-green mt-2">
                    {domainBrandStatus ? domainBrand["domainBrand"]["languageDictionary"]["WO_O_-2029650847"] : "OR"}
                  </div> : ""
                }
                <div className="row mt-2" style={{ margin: "0 auto" }}>
                  {socialMediaGoogleStatus ? (
                    <div className="col-12 col-xl-6 mb-3">
                      <GoogleLogin
                        clientId={config.GOOGLE_OAUTH}
                        onSuccess={googleOAuthResponse}
                        onFailure={googleOAuthResponse}
                        render={(renderProps) => (
                          <BrandedRegularButton
                            onClick={renderProps.onClick}
                            disabled={renderProps.disabled}
                            className="btn btn-block text-dark font-weight-bold">
                            <img
                              src={googleLogo}
                              alt="Google OAuth"
                              style={{ height: "18px", marginRight: "8px", width: "18px" }}
                            />
                            {domainBrandStatus
                              ? domainBrand["domainBrand"]["languageDictionary"]["l_google_login"]
                              : "Google Login"}
                          </BrandedRegularButton>
                        )}
                        cookiePolicy={"single_host_origin"}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {socialMediaFacebookStatus ? (
                    <div className="col-12 col-xl-6 mb-3">
                      <FacebookLogin
                        appId="234416911798587"
                        fields="name,email,picture"
                        callback={facebookOAuthResponse}
                        render={(renderProps) => (
                          <BrandedRegularButton
                            onClick={renderProps.onClick}
                            className="btn btn-block text-dark font-weight-bold">
                            <img src={facebook} alt="" style={{ marginRight: "8px" }} />
                            {/*{domainBrandStatus ? (domainBrand["domainBrand"]["languageDictionary"]["l_google_login"]) : ("Facebook Login")}*/}
                            Facebook Login
                          </BrandedRegularButton>
                        )}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div className={Styles.bottomBar} />

              <div className={Styles.bottomImgLight}>
                <img src={fundsImg} alt="" />
              </div>
            </section>

            <section className={Styles.rightSection}>
              <div className={Styles.lang}>
                <LanguageSelector />
              </div>

              <div className={Styles.bannerText}>
                <p>The simplest way</p>
                <p>to set up your</p>
                <p>digital banking service</p>
              </div>

              <div className={Styles.topRightCircle}>
                <div />
              </div>
              <div className={Styles.bottomRightCircle}>
                <div />
              </div>

              <div className={Styles.circle__one} />
              <div className={Styles.circle__two} />
              <div className={Styles.circle__three} />

              <div className={Styles.bottomImgDark}>
                <img src={fundsBlueImg} alt="funds Icon" />
              </div>
            </section>
          </>
        </div>
      )}
    </>
  );
};

export default Login;

import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import { useHistory } from "react-router-dom";
import Cookie from "js-cookie";
import moment from "moment";

import NavBar from "Components/Navbar";
import Footer from "Components/Footer";
import HeadingText from "Components/HeadingText";
import SubHeadingText from "Components/SubHeadingText";
import DropDownComponent from "Components/DropDownComponent";
import ChartBar2 from "Components/ChartBar3";
import FlexibleButton from "Components/FlexibleButton";
import Spinner from "Components/Spinner";
import Pagination from "Components/Pagination";
import usePagination from "Components/Pagination/usePagination";
import BackArrow from "Components/BackArrow";

import offSetImg from "Assets/svg/funds.svg";
import downArrow from "Assets/svg/blue-down-arrow.svg";
import customStyles from "Assets/styles/dropdown/customStyles";
import customStylesTwo from "./customStylesTwo";
import downloadIcon from "Assets/svg/download.svg";

import { filterAccountStatement } from "Utils/DummyData/dropDown";
import "./index.scss";
import { General } from "Utils/Helpers";
import { filterData, daysFromToday, getYear } from "Utils/Helpers/general";

import {
  getAccountStatement,
  getAccountBalances,
  getYearlyTransaction,
  getAccountBalancesByAccountCurrency
} from "Store/actions/account.action";
import { fetchCurrencies } from "Store/actions/bankAccount.action";
import pdfGeneratorStatement from "../../../Utils/Helpers/pdfGeneratorStatement";

const formatNum = (num) =>
  num
    ? Number(num).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })
    : "0.00";

const selectClassName = (num) => {
  if (num > 0) {
    return "ast_row_green";
  } else if (num < 0) {
    return "ast_row_red";
  } else {
    return "";
  }
};

const TableRow = ({ tableData }) => {
  const {
    executionDate,
    pcn,
    beneficiaryName,
    transactionAmount,
    paymentReference,
    newBalance,
    currencyCode
  } = tableData;

  return (
    <tr>
      <td>
        <span>{General.formatDate(executionDate) || "-"}</span>
      </td>
      <td>
        <span>{pcn || "-"}</span>
      </td>
      <td>
        <span>{beneficiaryName || "-"}</span>
      </td>
      <td>
        <span>{paymentReference || "-"}</span>
      </td>
      <td>
        <span className={selectClassName(transactionAmount)}>
          {(transactionAmount && (transactionAmount)) || "-"}
        </span>
      </td>
      <td>
        <span>{`${currencyCode}${formatNum(newBalance)}` || "-"}</span>
      </td>
    </tr>
  );
};

const generateDownloadData = (data) => {
  return data.map(
    ({
       executionDate,
       pcn,
       beneficiaryName,
       transactionAmount,
       paymentReference,
       newBalance,
       currencyCode
     }) => [
      General.formatDate(executionDate) || "-",
      pcn || "-",
      beneficiaryName || "-",
      paymentReference || "-",
      (transactionAmount && transactionAmount) || "-",
      `${currencyCode}${formatNum(newBalance)}` || "-"
    ]
  );
};

const defaultState = {
  selectedCurrency: "",
  tableData: {
    accountName: "No Account Name",
    walletAccountCurrency: "",
    walletAccountNumber: ""
  }
};

const AccountStatement = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  let { selectedCurrency: _selectedCurrency, tableData } =
    typeof history.location.state !== "undefined" && "selectedCurrency" in history.location.state ? history.location.state : defaultState;
  let selectedPropsSubmitStatus = Boolean(tableData);
  let selectedPropsAccount = { value: tableData.walletAccountCurrency, label: tableData.accountName };

  const [account, theme, profile, currencies, domainBrand] = useSelector(
    ({ accountReducer, themeReducer, profileReducer, bankAccountReducer, domainBrandReducer }) => [
      accountReducer,
      themeReducer,
      profileReducer,
      bankAccountReducer["walletCurrenciesDropdown"],
      domainBrandReducer
    ]
  );

  // ?start-year=2021

  useEffect(() => {
    if (selectedPropsSubmitStatus) statementFetch(selectedPropsAccount);
  }, []);

  const PAGE_LIMIT = 5;
  let startYear = getYear();
  const [fromDate, setFromDate] = useState(daysFromToday());
  const [toDate, setToDate] = useState(new Date());
  const [filterText, setFilterText] = useState("");
  const [filterSection, setFilterSection] = useState(filterAccountStatement[0]);
  const [collection, setCollection] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState({ ...tableData, ...selectedPropsAccount });
  const [selectedCurrency, setSelectedCurrency] = useState(_selectedCurrency);
  const languageDictionary = domainBrand.domainBrand["languageDictionary"];
  const languageDictionaryStatus = !(
    typeof languageDictionary === "undefined" || languageDictionary === null
  );

  const statementFetch = ({ walletAccountCurrency, walletAccountNumber }) => {
    handleSearch(walletAccountCurrency, toDate, fromDate, walletAccountNumber);
  };

  const handleSearch = (_selectedCurrency, _toDate, _fromDate, _walletAccountNumber) => {
    dispatch(
      getAccountStatement({
        selectedCurrency: _selectedCurrency,
        toDate: _toDate,
        fromDate: _fromDate,
        accountNumber: _walletAccountNumber
      })
    );
  };

  useEffect(() => {
    try {
      const { $accountNumber, $accountName, $currency } = JSON.parse(Cookie.get("recentPayment"));
      setSelectedCurrency({ label: $currency, value: $currency });
      setSelectedAccount({ ...selectedAccount, label: $accountName, value: $accountNumber });
      statementFetch({ walletAccountCurrency: $currency, walletAccountNumber: $accountNumber });
      Cookie.remove("recentPayment");
    } catch (err) {
    }
  }, []);

  useEffect(() => {
    const data = account.statement.walletStatement;
    if (data.length > 0) {
      const filteredData = filterData(data, filterText, filterSection.value);
      setCollection(filteredData);
    }
  }, [filterText, filterSection.value, account.statement.walletStatement]);

  useEffect(() => {
    // dispatch(getAccountBalancesByAccountCurrency(selectedCurrency["value"]));
    dispatch(getAccountBalancesByAccountCurrency(""));
  }, [selectedCurrency]);

  const [currentData, onPageChange] = usePagination({
    collection,
    pageLimit: PAGE_LIMIT
  });

  useEffect(() => {
    const { userData } = profile;
    dispatch(getAccountBalances());
    dispatch(fetchCurrencies());
    dispatch(getYearlyTransaction(userData.customerCode, startYear));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  const generatePDF = () => {
    const fromDate = moment(fromDate).format("MMM Do yyyy");
    const toDate = moment(toDate).format("MMM Do yyyy");
    const data = {profile, selectedAccount, fromDate, toDate};
    const pdfData = formatPDFData(account.statement.walletStatement);
    pdfGeneratorStatement(data, pdfData, domainBrand).then(err => console.log(err));
  }

  const formatPDFData = data => {
    return data.reverse().map(({executionDate, pcn, paymentReference, principalAmount, newBalance, currencyCode}) => [
        moment(new Date(executionDate)).format("DD-MM-yyyy") || "",
        pcn || "",
        paymentReference || "",
        {text: principalAmount || "0.00", alignment: "right"},
        {text: newBalance || "", alignment: "right"},
      ]
    );
  };

  return (
    <>
      <Helmet>
        <title>
          {languageDictionaryStatus
            ? languageDictionary["l_wallet_transaction_history"]
            : "Account Statement"}
        </title>
      </Helmet>

      <div className="ast_body">
        <NavBar />
        <main className="ast_main">
          <>
            <div className="ast__canvasImg">
              <img src={offSetImg} alt="off canvas icon" />
            </div>
            <div className="ast-heading--text">
              <BackArrow />
              <HeadingText
                text={
                  languageDictionaryStatus
                    ? languageDictionary["l_wallet_transaction_history"]
                    : "Account Statement"
                }
              />
            </div>

            <section className="ast_topSection">
              <div className="ast_legendContainer">
                <div className="ast_select">
                  <SubHeadingText
                    text={
                      languageDictionaryStatus
                        ? languageDictionary["WO_SELECT_CURRENC_1917455719"]
                        : "Select Currency"
                    }
                  />
                  <Select
                    options={currencies}
                    styles={customStyles}
                    isClearable={false}
                    isSearchable={true}
                    value={selectedCurrency}
                    customTheme={theme}
                    onChange={(option) => {
                      setSelectedCurrency(option);
                    }}
                    components={{
                      IndicatorSeparator: null,
                      DropdownIndicator: () => <DropDownComponent iconSource={downArrow} />
                    }}
                  />
                </div>

                <div className="ast_select second-legend">
                  <SubHeadingText text="Select Account" />
                  <Select
                    options={account.accountBalanceDropDownList}
                    styles={customStyles}
                    isClearable={false}
                    isSearchable={true}
                    value={selectedAccount}
                    customTheme={theme}
                    onChange={(option) => {
                      setSelectedAccount(option);
                    }}
                    components={{
                      IndicatorSeparator: null,
                      DropdownIndicator: () => <DropDownComponent iconSource={downArrow} />
                    }}
                  />
                </div>

                <div className="ast_dateCont">
                  <div className="ast_dateCont__start">
                    <SubHeadingText
                      text={languageDictionaryStatus ? languageDictionary["para_start_date_"] : "Start Date"}
                    />
                    <DatePicker
                      selected={fromDate}
                      onChange={setFromDate}
                      startDate={fromDate}
                      endDate={toDate}
                      maxDate={toDate}
                      dateFormat="dd MMMM yyyy"
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      selectsStart
                      className="ast_datePicker mt-2"
                    />
                  </div>
                  <div className="ast_dateCont__end">
                    <SubHeadingText
                      text={
                        languageDictionaryStatus ? languageDictionary["l_end_date"] : "End Date"
                      }
                    />
                    <DatePicker
                      selected={toDate}
                      onChange={setToDate}
                      startDate={fromDate}
                      endDate={toDate}
                      minDate={fromDate}
                      dateFormat="dd MMMM yyyy"
                      selectsEnd
                      className="ast_datePicker"
                    />
                  </div>
                </div>

                <div className="ast_dateCont__search">
                  <FlexibleButton
                    buttonText={
                      languageDictionaryStatus ? languageDictionary["button_search"] : "Search"
                    }
                    height="48px"
                    loading={account.statement.loadingStatus === "FETCHING"}
                    disabled={
                      account.statement.loadingStatus === "FETCHING" || account.list.length < 1
                    }
                    onClick={() => statementFetch(selectedAccount)}
                  />
                </div>
              </div>

              <div className="ast_chartBalanceSection">
                <div className="ast_chartContainer">
                  <div className="ast_chartBarsWrapper">
                    <ChartBar2 data={account.totalYearlyTrxn} />
                  </div>
                </div>

                <div className="ast_balanceAmount">
                  <div className="ast_balanceWrapper__one">
                    <h4>
                      {languageDictionaryStatus ? languageDictionary["l_current_balance"] : "Current Balance"}
                    </h4>
                    <p>
                      {selectedAccount.value || ""} {" "}
                      {typeof selectedAccount['walletAccountBalance'] === "undefined" ? "0.0" : selectedAccount['walletAccountBalance'].toFixed(2)}
                    </p>
                  </div>
                </div>
              </div>
            </section>

            <section className="ast_tableSection">
              <div className="ast_controls">
                <div className="ast_controls__filterSection">
                  <div className="ast_controls__filterContainer">
                    <div className="ast_controls__filterSelect">
                      <Select
                        options={filterAccountStatement}
                        styles={customStylesTwo}
                        isSearchable={false}
                        backspaceRemovesValue={false}
                        placeholder="Filter"
                        onChange={setFilterSection}
                        value={filterSection}
                        customTheme={theme}
                        components={{
                          IndicatorSeparator: null,
                          DropdownIndicator: () => <DropDownComponent iconSource={downArrow} />
                        }}
                      />
                    </div>
                    <div className="ast_controls__sortSelect">
                      <label htmlFor="filterText">
                        <input
                          type="text"
                          name="filterText"
                          id="filterText"
                          placeholder="search or filter results..."
                          value={filterText}
                          onChange={(e) => setFilterText(e.target.value)}
                        />
                      </label>
                    </div>
                  </div>
                  <button
                    className="ast_controls__download"
                    onClick={generatePDF}>
                    <img src={downloadIcon} alt="icon" />
                  </button>
                </div>
              </div>

              <div className="ast_table">
                {account.statement.loadingStatus === "FETCHING" ? (
                  <Spinner containerHeight="40vh" />
                ) : (
                  <>
                    <div className="scrollable-table">
                      <table>
                        <thead>
                        <tr>
                          <th scope="col">
                            {languageDictionaryStatus ? languageDictionary["l_date"] : "Date"}
                          </th>
                          <th scope="col">pcn</th>
                          <th scope="col">
                            {languageDictionaryStatus
                              ? languageDictionary["WO_ACCOUNT_NAM_-935856925"]
                              : "Account Name"}
                          </th>
                          <th scope="col">
                            {languageDictionaryStatus
                              ? languageDictionary["PAYMENT_REFERENCE_1737194208"]
                              : "Payment Reference"}
                          </th>
                          <th scope="col">
                            {languageDictionaryStatus
                              ? languageDictionary["para_amount"]
                              : "Amount"}
                          </th>
                          <th scope="col">
                            {languageDictionaryStatus
                              ? languageDictionary["para_balance"]
                              : "Balance"}
                          </th>
                        </tr>
                        </thead>
                        <tbody>
                        {currentData < 1 ? (
                          <>
                            <tr>
                              <td colSpan="6" className="text-center bg-grey">
                                <span>No account statement available</span>
                              </td>
                            </tr>
                          </>
                        ) : (
                          <>
                            {currentData.map((iData, index) => (
                              <TableRow tableData={iData} key={index} />
                            ))}
                          </>
                        )}
                        </tbody>
                      </table>
                    </div>
                    <Pagination
                      resultName="Total Statements"
                      onPageChange={onPageChange}
                      pageLimit={PAGE_LIMIT}
                      totalRecords={collection.length}
                    />
                  </>
                )}
              </div>
            </section>
          </>
        </main>
        <Footer />
      </div>
    </>
  );
};

export default AccountStatement;

export const relationshipOptions = {
  Company: [
    { value: "BANKING", label: "Banking" },
    { value: "BUYER_MERCHANT", label: "Buyer/Merchant" },
    { value: "CHARITY", label: "Charity (e.g., Donation)" },
    { value: "CLIENT", label: "Client/Customer" },
    { value: "CONTRACTOR", label: "Contractor" },
    { value: "COOPERATE_SERVICE", label: "Corporate Services" },
    { value: "EMPLOYEE", label: "Employee" },
    { value: "INVESTOR", label: "Investor" },
    { value: "REGULATOR", label: "Regulator" },
    { value: "SPONSOR", label: "Sponsor" },
    { value: "RELATED_ENTITY", label: "Related Entity" },
    { value: "VENDOR", label: "Vendor" }
  ],

  Individual: [
    { value: "FRIENDS", label: "Friends" },
    { value: "PARENTS", label: "Parents" },
    { value: "RELATIVES", label: "Relatives" },
    { value: "SPOUSE", label: "Spouse" },
    { value: "AUNT", label: "Aunt" },
    { value: "BROTHER", label: "Brother" },
    { value: "BROTHER_IN_LAW", label: "Brother-in-law" },
    { value: "COUSIN", label: "Cousin" },
    { value: "DAUGHTER", label: "Daughter" },
    { value: "FATHER", label: "Father" },
    { value: "FATHER_IN_LAW", label: "Father-in-law" },
    { value: "FRIEND", label: "Friend" },
    { value: "GRAND_FATHER", label: "Grandfather" },
    { value: "GRAND_MOTHER", label: "Grandmother" },
    { value: "HUSBAND", label: "Husband" },
    { value: "MOTHER", label: "Mother" },
    { value: "MOTHER_IN_LAW", label: "Mother-in-law" },
    { value: "NEPHEW", label: "Nephew" },
    { value: "NIECE", label: "Niece" },
    { value: "SELF", label: "Self (i.e. the sender, himself)" },
    { value: "SISTER", label: "Sister" },
    { value: "SISTER_IN_LAW", label: "Sister-in-law" },
    { value: "SON", label: "Son" },
    { value: "UNCLE", label: "Uncle" },
    { value: "WIFE", label: "Wife" },
    { value: "OTHER", label: "Others not listed" }
  ]
};

export const purposeOfTransfer = {
  INDIVIDUAL: [
    { value: "FAMILY_SUPPORT", label: "Family Support" },
    { value: "BUILDING_PROJECT", label: "Building Project" },
    { value: "EDUCATIONAL_EXPENSES", label: "Educational Expenses" },
    { value: "PAYBACK", label: "Payback" },
    { value: "SAVINGS", label: "Savings" },
    { value: "GIFT", label: "Gift" },
    { value: "CHARITABLE_DONATION", label: "Charitable Donations" },
    { value: "GOODS_AND_SERVICES", label: "Goods and services" },
    { value: "OTHER", label: "Other" }
  ],
  COMPANY: [
    {
      label: "Merchandise transaction/Goods purchase",
      value: "MERCHANDISE_TRANSACTION/GODS_PURCHASE"
    },
    {
      label: "Services transaction/ services purchase",
      value: "SERVICES_TRANSACTION/SERVICES_PURCHASE"
    },
    { label: "Credit payment", value: "CARD_PAYMENT" },
    { label: "Investment", value: "INVESTMENT" },
    {
      label: "Regulatory Payment (e.g., penalty, fine)",
      value: "REGULATORY_PAYMENT"
    },
    { label: "Grants/donations", value: "GRANTS/DONATIONS" },
    { label: "Banking", value: "BANKING" },
    { label: "Business expenses", value: "BUSINESS_EXPENSES" },
    { label: "Royalty Payment", value: "ROYALTY_PAYMENT" },
    { label: "Dividend Payment", value: "DIVIDEND_PAYMENT" },
    { label: "Loan payment", value: "LOAN_PAYMENT" },
    { label: "Real estate/Rent", value: "REAL_ESTATE/RENT" },
    { label: "Tax Payment", value: "TAX_PAYMENT" },
    { label: "Others not listed", value: "OTHERS" }
  ]
};

export const sourceOfFunds = {
  INDIVIDUAL: [
    { value: "BORROW_LOAN", label: "Borrowed Funds/Loan" },
    { value: "CHARITABLE_DONATION", label: "Charitable Donations" },
    { value: "GIFT", label: "Gift" },
    {
      value: "PENSION_GOVERNMENT_WELFARE",
      label: "Pensions/Government/Welfare"
    },
    { value: "INHERITANCE", label: "Inheritance" },
    { value: "MEDICAL_AID", label: "Medical aid" },
    { value: "SALARY_INCOME", label: "Salary/Income" },
    { value: "SAVINGS", label: "Savings" },
    { value: "OTHER", label: "Other" }
  ],
  COMPANY: [
    {
      value: "COMPANY_EARNINGS",
      label: "Company Earnings (this company)"
    },
    {
      value: "COMPANY_EARNINGS",
      label: "Company Earnings (related company)"
    },
    { value: "CREDIT", label: "Credit" },
    {
      value: "DIVIDENDS_OR_PROFITS_FROM_COMPANY",
      label: "Dividends or Profits from Company"
    },
    { value: "INVESTMENT_INCOME", label: "Investment Income" },
    { value: "LOAN", label: "Loan" },
    { value: "SALE_OF_ASSETS", label: "Sale of Assets" },
    { value: "PERSONAL_SAVINGS", label: "Personal Savings" },
    {
      value: "VENTURE_CAPITAL/ANGEL_INVESTOR/CROWDFUNDING",
      label: "Venture Capital / Angel Investor / Crowdfunding"
    },
    { value: "GRANT", label: "Grant" },
    { value: "OTHER", label: "Others not listed" }
  ]
};

export const userStatus = [
  { value: "ACTIVATE", label: "Activate" },
  { value: "DEACTIVATE", label: "Deactivate" }
];

export const typeOptions = [
  { value: "Individual".toUpperCase(), label: "Individual" },
  { value: "Company".toUpperCase(), label: "Company" }
];

export const documentType = [
  { value: "PASSPORT", label: "Passport" },
  { value: "DRIVING_LICENSE", label: "Driving License" },
  { value: "NATIONAL_ID_CARD", label: "National ID Card" },
  { value: "VOTE_CARD", label: "Vote Card" },
  { value: "ADDRESS_PROOF", label: "Proof of address" },
  { value: "SOURCE_OF_INCOME_PROOF", label: "Proof for the source of income" },
  { value: "OTHER_OFFICIAL_DOCUMENT", label: "Other Official Document" },
  { value: "PROOF_OF_PAYMENT_DOCUMENT", label: "Proof Of Payment" },
  { value: "EXPRESS_DEPOSIT_DOCUMENT", label: "Direct Debit Mandate Document" },
  { value: "CUSTOMER_DECLARATION_FORM", label: "Customer declaration form" },
  { value: "BUSINESS_REGISTRATION", label: "Certificate of Incorporation" },
  { value: "TAX_CERTIFICATE", label: "Valid Business Licence/Tax Certificate" },
  { value: "ARTICLES_OF_INCORPORATION", label: "Articles of Incorporation / Memorandum of Association" },
  { value: "DIRECTORS_PASSPORT", label: "Passport or other identification documents of directors" },
  {
    value: "REGULATORY_APPROVAL",
    label: "Regulatory Approval/License/Registration to engage in Remittance/Money Transfer"
  },
  {
    value: "AUDITED_FINANCIAL_STATEMENTS",
    label: "Last 3 years audited financial statements"
  },
  {
    value: "PHOTOGRAPH_OF_THE_OUTLET",
    label: "Photograph of the outlet"
  },
  {
    value: "COMPLIANCE_POLICIES",
    label: "Compliance policies for Anti-money laundering and counter terrorism financing"
  },
  {
    value: " KYC_QUESTIONNAIRES",
    label: "Complete AML/CFT and KYC Questionnaires for Remittance Tie-Up"
  },
  {
    value: "OTHER",
    label: "Other"
  }
];

export const complianceOptions = [
  { value: "PASSPORT", label: "Passport" }
  // { value: 'ADDRESS_PROOF', label: 'Proof of address' },
  // { value: 'NATIONAL_ID_CARD', label: 'National ID Card' },
  // { value: 'VOTE_CARD', label: 'Vote Card' },
  // { value: 'DRIVING_LICENSE', label: 'Driving Licence' },
];

export const fieldNames = {
  accountNumber: "Account Number",
  accountHolderName: "Account Holder Name",
  accountType: "Account Type",
  addressLine: "Address Line",
  bankCode: "Bank Code",
  bankAddressLine: "Bank Address Line",
  bankCity: "Bank City",
  bankName: "Bank Name",
  bankState: "Bank State",
  branchCode: "Branch Code",
  bankCountry: "Bank Country",
  bankPostCode: "Bank Post Code",
  branchDistrict: "Branch District",
  branchName: "Branch Name",
  cardType: "Card Type",
  city: "City",
  country: "Country",
  cnpjrequired: "CNPJ",
  cpfrequired: "CPF",
  creditCardNumber: "Credit Card",
  debitCardNumber: "Debit Card",
  documentNumber: "Document Number",
  sortCode: "Sort Code",
  swiftCode: "Swift Code",
  ibanrequired: "Iban Code",
  iban: "Iban Code",
  ibanCode: "Iban Code",
  routingNumber: "Routing Number",
  transitNumber: "Transit Number",
  ifscrequired: "Ifsc Code",
  rutnumber: "Rut Number",
  oprequired: "OP",
  phoneNumber: "Phone Number",
  postCode: "Post Code",
  recipientType: "Recipient Type",
  state: "State"
};

export const bankPairs = {
  accountCategory: "accountcategory",
  accountHolderName: "accountholdername",
  accountId: "accountid",
  accountNumber: "bankaccountno",
  accountRank: "accountrank",
  accountType: "accounttype",
  addressLine: "addressline1",
  areaCode: "areacode",
  bankBranchId: "bankbranchid",
  bankCode: "bankcode",
  bankName: "bankname",
  branchCode: "branchcode",
  branchDistrict: "branchdistrict",
  branchName: "branchname",
  bankState: "bankstate",
  cardType: "cardtype",
  city: "bankcity",
  cnpj: "cnpj",
  cpf: "cpf",
  country: "country",
  iban: "iban",
  identityAlias: "identityalias",
  msisdn: "msisdn",
  ifsccode: "ifsccode",
  linkRef: "linkref",
  mobileMoneyNumber: "mobilemoneynumber",
  op: "op",
  organisationId: "organisationid",
  recipientType: "recipientType",
  RUTNumber: "rutingnumber",
  rutNumber: "rutnumber",
  sortCode: "sortcode",
  swiftCode: "swiftbic",
  transitNumber: "accountrank",
  username: "username",
  walletId: "walletid",
};

export const nationalityList = {
  nationality: [
    { value: "Afghan", label: "Afghan" },
    { value: "Albanian", label: "Albanian" },
    { value: "Aandorran", label: "Andorran" },
    { value: "Algerian", label: "Algerian" },
    { value: "American", label: "American" },
    { value: "Angolan", label: "Angolan" },
    { value: "Argentinean", label: "Argentinean" },
    { value: "Australian", label: "Australian" },

    { value: "Brazilian", label: "Brazilian" },
    { value: "British", label: "British" },
    { value: "Bruneian", label: "Bruneian" },
    { value: "Burkinabe", label: "Burkinabe" },
    { value: "Burmese", label: "Burmese" },
    { value: "Burundian", label: "Burundian" },

    { value: "Cambodian", label: "Cambodian" },
    { value: "Cameroonian", label: "Cameroonian" },
    { value: "Canadian", label: "Canadian" },
    { value: "Cape Verdean", label: "Cape Verdean" },
    { value: "Central African", label: "Central African" },
    { value: "Chadian", label: "Chadian" },

    { value: "Chilean", label: "Chilean" },
    { value: "Chinese", label: "Chinese" },
    { value: "Colombian", label: "Colombian" },
    { value: "Comoran", label: "Comoran" },
    { value: "Congolese", label: "Congolese" },
    { value: "Costa Rican", label: "Costa Rican" },
    { value: "Croatian", label: "Croatian" },
    { value: "Cuban", label: "Cuban" },
    { value: "Cypriot", label: "Cypriot" },
    { value: "Czech", label: "Czech" },

    { value: "Danish", label: "Danish" },
    { value: "Djibouti", label: "Djibouti" },
    { value: "Dominican", label: "Dominican" },
    { value: "Dutch", label: "Dutch" },

    { value: "East Timorese", label: "East Timorese" },
    { value: "Ecuadorean", label: "Ecuadorean" },
    { value: "Egyptian", label: "Egyptian" },
    { value: "Emirian", label: "Emirian" },
    { value: "Equatorial Guinean", label: "Equatorial Guinean" },
    { value: "Eritrean", label: "Eritrean" },
    { value: "Estonian", label: "Estonian" },
    { value: "Ethiopian", label: "Ethiopian" },

    { value: "Fijian", label: "Fijian" },
    { value: "Filipino", label: "Filipino" },
    { value: "Finnish", label: "Finnish" },
    { value: "French", label: "French" },

    { value: "Gabonese", label: "Gabonese" },
    { value: "Gambian", label: "Gambian" },
    { value: "Georgian", label: "Georgian" },
    { value: "German", label: "German" },
    { value: "Ghanaian", label: "Ghanaian" },
    { value: "Greek", label: "Greek" },
    { value: "Grenadian", label: "Grenadian" },
    { value: "Guatemalan", label: "Guatemalan" },
    { value: "Guinea-Bissauan", label: "Guinea-Bissauan" },
    { value: "Guinean", label: "Guinean" },
    { value: "Guyanese", label: "Guyanese" },

    { value: "Haitian", label: "Haitian" },
    { value: "Herzegovinian", label: "Herzegovinian" },
    { value: "Honduran", label: "Honduran" },
    { value: "Hungarian", label: "Hungarian" },

    { value: "I-Kiribati", label: "I-Kiribati" },
    { value: "Icelander", label: "Icelander" },
    { value: "Indian", label: "Indian" },
    { value: "Indonesian", label: "Indonesian" },
    { value: "Iranian", label: "Iranian" },
    { value: "Iraqi", label: "Iraqi" },
    { value: "Irish", label: "Irish" },
    { value: "Israeli", label: "Israeli" },
    { value: "Italian", label: "Italian" },
    { value: "Ivorian", label: "Ivorian" },

    { value: "Jamaican", label: "Jamaican" },
    { value: "Japanese", label: "Japanese" },
    { value: "Jordanian", label: "Jordanian" },

    { value: "Kazakhstani", label: "Kazakhstani" },
    { value: "Kenyan", label: "Kenyan" },
    { value: "Kittian and Nevisian", label: "Kittian and Nevisian" },
    { value: "Kuwaiti", label: "Kuwaiti" },
    { value: "Kyrgyz", label: "Kyrgyz" },

    { value: "Laotian", label: "Laotian" },
    { value: "Latvian", label: "Latvian" },
    { value: "Lebanese", label: "Lebanese" },
    { value: "Liberian", label: "Liberian" },
    { value: "Libyan", label: "Libyan" },
    { value: "Liechtensteiner", label: "Liechtensteiner" },
    { value: "Lithuanian", label: "Lithuanian" },
    { value: "Luxembourger", label: "Luxembourger" },

    { value: "Macedonian", label: "Macedonian" },
    { value: "Malagasy", label: "Malagasy" },
    { value: "Malawian", label: "Malawian" },
    { value: "Malaysian", label: "Malaysian" },
    { value: "Maldivian", label: "Maldivian" },
    { value: "Malian", label: "Malian" },
    { value: "Maltese", label: "Maltese" },
    { value: "Marshallese", label: "Marshallese" },
    { value: "Mauritanian", label: "Mauritanian" },
    { value: "Mauritian", label: "Mauritian" },
    { value: "Mexican", label: "Mexican" },
    { value: "Micronesian", label: "Micronesian" },
    { value: "Moldovan", label: "Moldovan" },
    { value: "Monacan", label: "Monacan" },
    { value: "Mongolian", label: "Mongolian" },
    { value: "Moroccan", label: "Moroccan" },
    { value: "Mosotho", label: "Mosotho" },
    { value: "Motswana", label: "Motswana" },
    { value: "Mozambican", label: "Mozambican" },

    { value: "Namibian", label: "Namibian" },
    { value: "Nauruan", label: "Nauruan" },
    { value: "Nepalese", label: "Nepalese" },
    { value: "New Zealander", label: "New Zealander" },
    { value: "Ni-Vanuatu", label: "Ni-Vanuatu" },
    { value: "Nicaraguan", label: "Nicaraguan" },
    { value: "Nigerian", label: "Nigerian" },
    { value: "Nigerien", label: "Nigerien" },
    { value: "North Korean", label: "North Korean" },
    { value: "Northern Irish", label: "Northern Irish" },
    { value: "Norwegian", label: "Norwegian" },
    { value: "Omani", label: "Omani" },

    { value: "Pakistani", label: "Pakistani" },
    { value: "Palauan", label: "Palauan" },
    { value: "Panamanian", label: "Panamanian" },
    { value: "Papua New Guinean", label: "Papua New Guinean" },
    { value: "Paraguayan", label: "Paraguayan" },
    { value: "Peruvian", label: "Peruvian" },
    { value: "Peruvian", label: "Peruvian" },
    { value: "Polish", label: "Polish" },
    { value: "Portuguese", label: "Portuguese" },

    { value: "Qatari", label: "Qatari" },
    { value: "Romanian", label: "Romanian" },
    { value: "Russian", label: "Russian" },
    { value: "Rwandan", label: "Rwandan" },
    { value: "Saint Lucian", label: "Saint Lucian" },
    { value: "Salvadoran", label: "Salvadoran" },
    { value: "Samoan", label: "Samoan" },
    { value: "San Marinese", label: "San Marinese" },
    { value: "Sao Tomean", label: "Sao Tomean" },
    { value: "Saudi", label: "Saudi" },
    { value: "Scottish", label: "Scottish" },
    { value: "Senegalese", label: "Senegalese" },
    { value: "Serbian", label: "Serbian" },
    { value: "Seychellois", label: "Seychellois" },
    { value: "Sierra Leonean", label: "Sierra Leonean" },
    { value: "Singaporean", label: "Singaporean" },
    { value: "Slovakian", label: "Slovakian" },
    { value: "Slovenian", label: "Slovenian" },

    { value: "Solomon Islander", label: "Solomon Islander" },
    { value: "Somali", label: "Somali" },
    { value: "South African", label: "South African" },
    { value: "Spanish", label: "Spanish" },
    { value: "Sri Lankan", label: "Sri Lankan" },
    { value: "Sudanese", label: "Sudanese" },
    { value: "Surinamer", label: "Surinamer" },
    { value: "Swazi", label: "Swazi" },
    { value: "Swedish", label: "Swedish" },
    { value: "Swiss", label: "Swiss" },
    { value: "Syrian", label: "Syrian" },

    { value: "Taiwanese", label: "Taiwanese" },
    { value: "Tajik", label: "Tajik" },
    { value: "Tanzanian", label: "Tanzanian" },
    { value: "Thai", label: "Thai" },
    { value: "Togolese", label: "Togolese" },
    { value: "Tongan", label: "Tongan" },
    { value: "Trinidadian or Tobagonian", label: "Trinidadian or Tobagonian" },
    { value: "Tunisian", label: "Tunisian" },
    { value: "Turkish", label: "Turkish" },
    { value: "Tuvaluan", label: "Tuvaluan" },
    { value: "Ugandan", label: "Ugandan" },

    { value: "Ukrainian", label: "Ukrainian" },
    { value: "Uruguayan", label: "Uruguayan" },
    { value: "Uzbekistani", label: "Uzbekistani" },
    { value: "Venezuelan", label: "Venezuelan" },

    { value: "Vietnamese", label: "Vietnamese" },
    { value: "Welsh", label: "Welsh" },
    { value: "Yemenite", label: "Yemenite" },
    { value: "Zambian", label: "Zambian" },
    { value: "Zimbabwean", label: "Zimbabwean" },
    { value: "", label: "" }
  ]
};

import { css } from '@emotion/react';

export const sectionOne = css`
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;

  @media ( max-width: 56.25rem ) {
    flex-direction: column;
  }
`;

export const sectionTwo = css`
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
`;

export const mainWrapper = css`
  padding-top: 0;
`;

// eslint-disable-next-line no-unused-vars
import React from 'react';
/** @jsx jsx */
/** @jsxRuntime classic */

import { css, jsx } from '@emotion/react';
import styled from '@emotion/styled';

const LegendCircle = styled.div`
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: ${(props) => props.color};
`;

const LegendTitle = styled.span`
  color: #90a8be;
  font-size: 15px;
  font-weight: 500;
  margin-left: 10px;
`;

const LegendWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const LegendContainer = styled.div`
  display: grid;
  column-gap: 30px;
  row-gap: 10px;
  grid-template-columns: auto auto auto auto;

  @media ( max-width: 56.25rem ){
    grid-template-columns: repeat(2, 1fr);
    // padding: 1rem;
  }
`;

const ChartLegend = ({ data }) => {
  return (
    <LegendContainer>
      {data.map((legendI) => (
        <LegendWrapper key={legendI.color}>
          <LegendCircle color={legendI.color} />
          <LegendTitle>{legendI.name}</LegendTitle>
        </LegendWrapper>
      ))}
    </LegendContainer>
  );
};

export default ChartLegend;

// eslint-disable-next-line no-unused-vars
import React from "react";
/** @jsx jsx */
/** @jsxRuntime classic */

import { jsx } from "@emotion/react";
import CustomButton from "Components/CustomButton";
import CustomInput from "Components/CustomInput";
import { pdBody, headingContainer, headingLeft, confirmButton } from "./style";
import { fieldNames } from "Utils/constants";
import * as Loading from "../../../../../Store/constants/loadingState.constants";
import Styles from "../../../../Auth/Login/index.module.scss";
import Spinner from "../../../../../Components/Spinner";

const PaymentDetails = (
  {
    formik,
    bankDetailsSelect,
    languageDictionary,
    transfers,
    pageStatus,
    profile
  }) => {
  const sendingCurrency = formik.values["sendingCurrency"]["value"] ? formik.values["sendingCurrency"]["value"] : "";
  const receivingCurrency = formik.values["receivingCurrency"]["value"] ? formik.values["receivingCurrency"]["value"] : "";
  const fields = transfers.bankFields || [];
  const fieldMapped = fields.map((value) => {
    // console.log(value);
    // console.log(formik.values);

    return (
      <CustomInput
        key={value["apiName"]}
        formik={formik}
        inputKey={value["apiName"]}
        inputSize="100"
        labelName={value["label"]}
      />
    );
  });

  const { quoteData: { total_amount, payment_method_fee, fxRate, tax, sendMoneyfee } } = transfers;
  const languageDictionaryStatus = !(typeof languageDictionary === "undefined" || languageDictionary === null);
  let exchangeRateStatus, feeStatus, taxStatus, totalAmountStatus, bankDetailsStatus, showSpinner;
  exchangeRateStatus = !(
    sendingCurrency === "" ||
    sendingCurrency === null ||
    receivingCurrency === "" ||
    receivingCurrency === null
  );
  feeStatus = !(payment_method_fee === 0 || payment_method_fee === "" || fxRate === null);
  totalAmountStatus = !(total_amount === 0 || total_amount === "" || total_amount === null);
  taxStatus = !(total_amount === 0 || total_amount === "" || total_amount === null);
  bankDetailsStatus = !(
    bankDetailsSelect === "" ||
    typeof bankDetailsSelect === "undefined" ||
    total_amount === null
  );
  showSpinner = transfers.quoteStatus === Loading.FETCHING;
  const createTransactionLoading = transfers.createTransactionStatus === Loading.FETCHING;

  return (
    <>
      {showSpinner ? (
        <div className={Styles.modSpinner}>
          <Spinner />
        </div>
      ) : (
        <div css={pdBody}>
          {!bankDetailsStatus ? "" :
            <div css={headingContainer}>
              <div css={headingLeft}>
                <h3>Account Holder</h3>
                <p>{bankDetailsSelect["accountHolderName"]}</p>
              </div>
              <div className="pd_details__headingRight">
                <h3>{languageDictionaryStatus ? languageDictionary["l_beneficiary_code"] : "Beneficiary Code"}</h3>
                <p>{bankDetailsSelect["accountOwnerCustomerCode"]}</p>
              </div>
            </div>
          }

          <div css={headingContainer}>
            <div css={headingLeft}>
              <h3>{languageDictionaryStatus ? languageDictionary["para_exchange_rate_"] : "Exchange rate"}</h3>
              <p>
                1.00 {sendingCurrency} = {fxRate || "1.00"} {receivingCurrency}
              </p>
            </div>
          </div>
          <div css={headingContainer}>
            <div css={headingLeft}>
              <h3>{languageDictionaryStatus ? languageDictionary["l_total_charges"] : "Charges"}</h3>
              <p>
                {sendMoneyfee || "0.00"} {sendingCurrency}

              </p>
            </div>
            <div className="pd_details__headingRight">
              <h3>{languageDictionaryStatus ? languageDictionary["l_fee"] : "Fee"}</h3>
              <p>
                {payment_method_fee || "0.00"} {sendingCurrency}
              </p>
            </div>
          </div>

          <div css={headingContainer}>
            {taxStatus ?
              <div css={headingLeft}>
                <h3>{languageDictionaryStatus ? languageDictionary["l_vat"] : "Tax"}</h3>
                <p>
                  {tax ? tax : "0.00"} {sendingCurrency}
                </p>
              </div> : ""
            }
            {totalAmountStatus ?
              <div className="pd_details__headingRight">
                <h3>{languageDictionaryStatus ? languageDictionary["para_total_amount"] : "Total Amount"}</h3>
                <p>
                  {total_amount || "0.00"} {sendingCurrency}
                </p>
              </div> : ""
            }
          </div>

          {pageStatus !== "Transfer Funds" ? "" : fieldMapped}

          {profile.userData["userType"] === "CASHIER" ?
            <CustomInput
              key="password"
              formik={formik}
              inputKey="password"
              inputSize="100"
              labelName="Password"
              inputType="password"
            /> : ""
          }

          {(taxStatus || totalAmountStatus || feeStatus || exchangeRateStatus) && (
            <div css={confirmButton}>
              <CustomButton
                buttonText={languageDictionaryStatus ? languageDictionary["para_confirm"] : "Confirm"}
                buttonWidth="120px"
                loading={createTransactionLoading}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default PaymentDetails;

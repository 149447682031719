import Axios from "./Axios.service";

export const fetchUsers = async () => {
  return Axios({
    method: "GET",
    url: "/web-services/api/v6/services/usermanagement/list-users",
    headers: {
      platform: "fincode",
      UUID: 200,
    },
  });
};

export const createUser = async (payload) => {
  payload.appVersion = 0;

  return Axios({
    method: "POST",
    url: "/web-services/api/v6/services/usermanagement/add-user-to-account",
    data: payload,
    headers: {
      platform: "fincode",
      UUID: 200,
    },
  });
};

export const fetchCustomerServiceTeam = async () => {
  return Axios({
    method: "GET",
    url: "/web-services/api/v6/services/usermanagement/list-customer-services-team",
    headers: {
      platform: "fincode",
      UUID: 200,
    },
  });
};

export const activateUser = async ({ userId }) => {
  return Axios({
    method: "GET",
    url: `/web-services/api/v6/services/usermanagement/activate-user-account/${userId}`,
    headers: {
      platform: "fincode",
      UUID: 200,
    },
  });
};

export const deactivateUser = async ({ userId }) => {
  return Axios({
    method: "GET",
    url: `/web-services/api/v6/services/usermanagement/deactivate-user-account/${userId}`,
    headers: {
      platform: "fincode",
      UUID: 200,
    },
  });
};

export const changePassword = async (payload) => {
  return Axios({
    method: "PUT",
    url: "/web-services/api/v6/services/securitymanagement/change-password",
    headers: {
      platform: "fincode",
      UUID: 200,
    },
    data: payload,
  });
};

export const customerSignUp = async (payload) => {
  return Axios({
    method: "POST",
    url: "/web-services/api/v6/services/usermanagement/register-customer",
    data: payload
  });
};

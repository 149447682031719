import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useFormik } from "formik";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { Redirect, useHistory, useLocation } from "react-router-dom";

import NavBar from "Components/Navbar";
import Footer from "Components/Footer";
import "./index.scss";
import { customStyles } from "./dropDownStyles";
import ButtonSpinner from "Components/ButtonSpinner";
import DropDownComponent from "Components/DropDownComponent";
import CustomInput from "Components/CustomInput";
import Spinner from "Components/Spinner";
import ErrorField from "Components/ErrorField";

import { getAllCountries } from "Store/actions/country.action";
import {
  createBeneficiaryBankDetails,
  getBeneficiaryDetails,
  getSupportedBanks,
  updateBeneficiary,
  updateBeneficiaryBankDetails
} from "Store/actions/beneficiary.action";
import * as Loading from "Store/constants/loadingState.constants";
import { fieldNames, relationshipOptions, typeOptions } from "Utils/constants";
import {
  fetchRequiredBankFields,
  fetchRequiredBankFieldsByFilter,
  getBeneficiaryBankDetails
} from "Store/actions/transfer.action";
import BackArrow from "Components/BackArrow";
import { BrandedRegularButton } from "../../../../Components/BrandingComponents";

const BeneficiaryEdit = () => {
  const dispatch = useDispatch(), location = useLocation(), history = useHistory();
  const { beneficiarySelector, countrySelector, transfers, theme, domainBrand } = useSelector(
    ({
       beneficiaryReducer,
       countryReducer,
       transferReducer,
       themeReducer,
       domainBrandReducer
     }) => ({
      beneficiarySelector: beneficiaryReducer,
      transfers: transferReducer,
      countrySelector: {
        countryDropdown: countryReducer.countryDropdownList,
        countryCodeDropdown: countryReducer.countryCodeDropdownList,
        currencyDropdownList: countryReducer.currencyDropdownList,
        loading: countryReducer.loading
      },
      theme: themeReducer,
      domainBrand: domainBrandReducer
    })
  );
  const languageDictionary = domainBrand.domainBrand["languageDictionary"];
  const languageDictionaryStatus = !(typeof languageDictionary === "undefined" || languageDictionary === null);

  const submitRedirect = () => {
    let { from } = location.state || { from: { pathname: "/beneficiaries" } };
    return () => history.replace(from);
  };

  const initialValidation = {
    benCountry: Yup.object({ value: Yup.string().required("required") }),
    companyName: Yup.string().when("type", {
      is: (val) => val.value === "COMPANY",
      then: Yup.string().required("required")
    }),
    firstName: Yup.string().when("type", {
      is: (val) => val.value === "INDIVIDUAL",
      then: Yup.string().required("required")
    }),
    lastName: Yup.string().when("type", {
      is: (val) => val.value === "INDIVIDUAL",
      then: Yup.string().required("required")
    })
  };

  const updateBankDropdown = [
    { label: "Add New Bank", value: "addBank" },
    { label: "Update Existing Bank", value: "updateBank" }
  ];

  const bankActionType = [
    { label: languageDictionaryStatus ? languageDictionary["para_country"] : "Country", value: "COUNTRY" },
    { label: languageDictionaryStatus ? languageDictionary["l_currency"] : "Currency", value: "CURRENCY" }
  ];

  const initialValuesData = {
    email: "",
    type: typeOptions[0],
    bankType: bankActionType[0],
    address1: "",
    address2: "",
    postcode: "",
    companyName: "",
    addressCity: "",
    regNumber: "",
    state: "",
    phone: "",
    benCountry: "",
    relationship: "",
    bankCountry: "",
    bankCurrency: "",
    bank: "",
    firstName: "",
    middleName: "",
    lastName: "",
    isChecked: false
  };
  const [validationSchema] = useState(initialValidation);
  const [initialValues, updateInitialValues] = useState(initialValuesData);
  const [updateBankState, setUpdateBankState] = useState("");
  let requiredFields = [];

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: Yup.object(validationSchema),
    onSubmit: (values) => {
      let bankDetails = {};
      const { state: { id } } = location;
      const userRequest = {
        id,
        type: values.type.value,
        firstName: values.firstName,
        lastName: values.lastName,
        companyName: values.companyName,
        regNumber: values.regNumber,
        address: {
          address1: values.address1,
          city: values.addressCity,
          postcode: values.postcode,
          state: values.state,
          countryCommonName: values.benCountry.label,
          countryIso3: values.benCountry.value
        }
      };

      const customerId = transfers.beneficiaryBankInfo[0].customerId;
      const beneficiaryId = transfers.beneficiaryBankInfo[0].id;
      bankDetails.customerId = customerId;
      bankDetails.id = beneficiaryId;
      const navigateMethod = submitRedirect();
      dispatch(updateBeneficiary(userRequest, navigateMethod));
    }
  });

  useEffect(() => {
    if (location.state && location.state.id) {
      dispatch(getBeneficiaryDetails({ beneficiaryId: location.state.id }));
      dispatch(getBeneficiaryBankDetails({ customerCode: location.state.beneficiaryCode }));
      dispatch(fetchRequiredBankFields({ countryCode: location.state.country }));
    }
  }, [dispatch, location.state]);

  useEffect(() => {
    const { values: { benCountry, bankCountry } } = formik;
    if (benCountry && benCountry.value || bankCountry && bankCountry.value) {
      const countryIso = bankCountry.value ? bankCountry.value : benCountry.value ? benCountry.value : "";
      dispatch(getSupportedBanks({ countryIso }));
      dispatch(fetchRequiredBankFields({ countryCode: bankCountry.value }));
    }
  }, [formik.values.benCountry, formik.values.bankCountry]);

  useEffect(() => {
    const { beneficiaryDetailsStatus, beneficiaryDetails } = beneficiarySelector;
    if (beneficiaryDetailsStatus === Loading.SUCCESS && countrySelector.loading === Loading.SUCCESS) {
      const {
        email, phone, type, firstName, middleName, lastName, relationShip, regNumber, companyName,
        address: { city: addressCity, countryIso3, address1, address2, postcode, state }
      } = beneficiaryDetails;

      const selectedType = typeOptions.find((item) => item.value === type);
      updateInitialValues({
        ...formik.initialValues,
        email: email || "",
        phone: phone || "",
        firstName: firstName || "",
        middleName: middleName || "",
        lastName: lastName || "",
        addressCity: addressCity || "",
        address1: address1 || "",
        address2: address2 || "",
        postcode: postcode || "",
        state: state || "",
        regNumber: regNumber || "",
        companyName: companyName || "",
        relationship: relationshipOptions[selectedType.label].find((item) => item.value === relationShip),
        benCountry: countrySelector.countryDropdown.find((item) => item.value === countryIso3),
        bankCountry: countrySelector.countryDropdown.find((item) => item.value === countryIso3),
        type: selectedType
      });
    }
  }, [beneficiarySelector.beneficiaryDetailsStatus, countrySelector.loading]);

  useEffect(() => {
    if (countrySelector.loading !== Loading.SUCCESS || countrySelector.loading === Loading.ERROR) dispatch(getAllCountries());
  }, []);

  const countryLoading = countrySelector.loading === Loading.FETCHING;
  const { bankField } = transfers;
  const fieldMappedComponent = Object.keys(bankField)
    .filter(value => bankField[value] === true)
    .map((value, index) => {
      let itemField = value.split("Required")[0];
      let lowerReq = itemField.split("required")[0];
      if (lowerReq === "iban") itemField = "ibanCode";
      if (itemField !== "ibanrequired") requiredFields.push(itemField);
      if (itemField === "country") return null;
      else if (itemField === "bankName" && updateBankState.value === "updateBank") return null;
      else if (itemField === "bankName") {
        return (
          <div key={index} className="benCreate__main__form--control">
            <p className="benCreate__main__form--inputHeading">
              {languageDictionaryStatus ? languageDictionary["para_bank_anme"] : "Bank Name"}
            </p>
            <ErrorField
              formik={formik}
              fieldName="bankName"
              fetchingData={beneficiarySelector.supportedBanksStatus === "LOADING"}
              selectField
            />
            <Select
              value={
                beneficiarySelector &&
                beneficiarySelector.supportedBanks &&
                beneficiarySelector.supportedBanks.filter((option) => option.name === formik.values.bankName)
              }
              onChange={(option) => formik.setFieldValue("bankName", option.label)}
              onBlur={() => formik.setFieldTouched("bankName", true)}
              options={beneficiarySelector.supportedBanks}
              placeholder="Please select..."
              name="bankName"
              id="bankName"
              styles={customStyles}
              components={{
                IndicatorSeparator: null,
                DropdownIndicator: DropDownComponent
              }}
              customTheme={theme}
              isLoading={beneficiarySelector.supportedBanksStatus === "LOADING"}
              loadingMessage={() => "Fetching bank list"}
            />
          </div>
        );
      }

      return (
        <CustomInput
          key={itemField}
          formik={formik}
          inputKey={itemField}
          nestedKey="bank"
          inputSize="100"
          labelName={fieldNames[itemField]}
        />
      );
    });

  const updateBeneficiaryDetails = () => {
    const values = formik.values;
    const userRequest = {
      id: location.state.id,
      type: values.type.value,
      firstName: values.firstName,
      lastName: values.lastName,
      companyName: values.companyName,
      regNumber: values.regNumber,
      address: {
        address1: values.address1,
        city: values.addressCity,
        postcode: values.postcode,
        state: values.state,
        countryCommonName: values.benCountry.label,
        countryIso3: values.benCountry.value
      }
    };
    const navigateMethod = submitRedirect();
    dispatch(updateBeneficiary(userRequest, navigateMethod));
  };

  const updateBeneficiaryBank = () => {
    const accountHolderName = formik.values.type.value === "COMPANY" ? formik.values.companyName : `${formik.values.firstName} ${formik.values.lastName}`;
    const beneficiaryCode = location.state.beneficiaryCode;
    const bankName = updateBankState.value === "addBank" ? { bankName: formik.values.bankName } : updateBankState.value === "updateBank" ? { bankName: formik.values.bank.bankName } : null;
    const navigateMethod = submitRedirect();
    let payload = {
      accountHolderName,
      bankName,
      accountOwnerCustomerCode: beneficiaryCode,
      country: formik.values.bankCountry.value
    };

    if (updateBankState.value === "addBank") {
      for (let element of requiredFields) {
        const ifExist = Object.keys(formik.values).some(key => key === element);
        payload = { ...payload, ...formik.values.bank, ...bankName };
        // if (!ifExist) toast.error(`Bank fields are required`, { position: toast.POSITION.TOP_CENTER });
        if (!ifExist) break;
      }
      dispatch(createBeneficiaryBankDetails(payload, navigateMethod));
    } else if (updateBankState.value === "updateBank") {
      for (let element of requiredFields) {
        payload = { ...payload, ...formik.values.bank, ...bankName };
      }
      delete payload.value;
      delete payload.label;
      dispatch(updateBeneficiaryBankDetails(payload, navigateMethod));
    }
  };

  if (!location.state) return <Redirect to="/beneficiaries" />;
  const showSpinner = countryLoading || beneficiarySelector.beneficiaryDetailsStatus === Loading.FETCHING;

  return (
    <div>
      <Helmet>
        <title>
          {languageDictionaryStatus ? languageDictionary["title_update_beneficiary_details"] : "Update Beneficiary"}
        </title>
      </Helmet>
      <NavBar />
      <main className="benCreate__main">
        {showSpinner ? <Spinner containerHeight="60vh" /> :
          <>
            <div className="d-flex">
              <BackArrow />
              <h2 className="benCreate__main__heading">
                {languageDictionaryStatus ? languageDictionary["title_update_beneficiary_details"] : "Update Beneficiary"}
              </h2>
            </div>
            <div className="benCreate__main__container">
              <form className="benCreate__main__form" onSubmit={formik.handleSubmit}>

                <section className="benCreate__main__form--topSection">
                  <div className="benCreate__main__form--control">
                    <p className="benCreate__main__form--inputHeading">
                      {" "}{languageDictionaryStatus ? languageDictionary["para_user_type"] : "User Type"}
                    </p>
                    <Select
                      value={formik.values.type}
                      onChange={(option) => formik.setFieldValue("type", option)}
                      onBlur={() => formik.setFieldTouched("type", true)}
                      options={typeOptions}
                      placeholder="Please select..."
                      isClearable={false}
                      isSearchable={false}
                      styles={customStyles}
                      customTheme={theme}
                      components={{
                        IndicatorSeparator: null,
                        DropdownIndicator: DropDownComponent
                      }}
                    />
                  </div>

                  {formik.values.type.label === "Company" ? (
                    <div className="benCreate__main__form--subHeading">
                      <h3>
                        {languageDictionaryStatus
                          ? languageDictionary["para_company_information"]
                          : "COMPANY INFORMATION"}
                      </h3>
                    </div>
                  ) : null}
                  {formik.values.type.label === "Company" ? null : (
                    <React.Fragment>
                      <div className="benCreate__main__form--control">
                        <label htmlFor="firstName">
                          <p className="benCreate__main__form--inputHeading">
                            {languageDictionaryStatus
                              ? languageDictionary["para_first_name"]
                              : "First Name"}
                            <span style={{ color: "red" }}>*</span>
                          </p>
                          <ErrorField formik={formik} fieldName="firstName" />
                          <input
                            type="text"
                            name="firstName"
                            id="firstName"
                            onChange={formik.handleChange}
                            value={formik.values.firstName}
                          />
                        </label>
                      </div>

                      <div className="benCreate__main__form--control">
                        <label htmlFor="lastName">
                          <p className="benCreate__main__form--inputHeading">
                            {languageDictionaryStatus
                              ? languageDictionary["para_last_name"]
                              : "Last Name"}
                            <span style={{ color: "red" }}>*</span>
                          </p>
                          <ErrorField formik={formik} fieldName="lastName" />
                          <input
                            type="text"
                            name="lastName"
                            id="lastName"
                            onChange={formik.handleChange}
                            value={formik.values.lastName}
                          />
                        </label>
                      </div>
                    </React.Fragment>
                  )}

                  {formik.values.type.label === "Company" ? (
                    <div className="benCreate__main__form--control">
                      <label htmlFor="companyName">
                        <p className="benCreate__main__form--inputHeading">
                          {languageDictionaryStatus
                            ? languageDictionary["l_company_name"]
                            : "Company Name"}
                          <span style={{ color: "red" }}>*</span>
                        </p>
                        <ErrorField formik={formik} fieldName="companyName" />
                        <input
                          type="text"
                          name="companyName"
                          id="companyName"
                          onChange={formik.handleChange}
                          value={formik.values.companyName}
                        />
                      </label>
                    </div>
                  ) : null}

                  {formik.values.type.label === "Company" ? (
                    <div className="benCreate__main__form--control">
                      <label htmlFor="regNumber">
                        <p className="benCreate__main__form--inputHeading">
                          {languageDictionaryStatus
                            ? languageDictionary["WO_REGISTRATION_NUMBE_1407955590"]
                            : "Registration Number"}
                          <span style={{ color: "red" }}>*</span>
                        </p>
                        <input
                          type="text"
                          name="regNumber"
                          id="regNumber"
                          onChange={formik.handleChange}
                          value={formik.values.regNumber}
                        />
                      </label>
                    </div>
                  ) : null}

                  <div className="benCreate__main__form--control">
                    <p className="benCreate__main__form--inputHeading">
                      <p className="benCreate__main__form--inputHeading">
                        {languageDictionaryStatus
                          ? languageDictionary["WO_COUNTR_1271562677"]
                          : "Country"}
                        <span style={{ color: "red" }}>*</span>
                      </p>
                    </p>
                    <ErrorField
                      formik={formik}
                      fieldName="benCountry"
                      fetchingData={countryLoading}
                      selectField
                    />
                    <Select
                      value={formik.values.benCountry}
                      onChange={(option) => {
                        formik.setFieldValue("benCountry", option);
                        formik.setFieldValue("bankCountry", option);
                        dispatch(
                          fetchRequiredBankFields({
                            countryCode: option.value
                          })
                        );
                      }}
                      onBlur={() => formik.setFieldTouched("benCountry", true)}
                      options={countrySelector.countryDropdown}
                      placeholder="Please select..."
                      name="benCountry"
                      styles={customStyles}
                      components={{
                        IndicatorSeparator: null,
                        DropdownIndicator: DropDownComponent
                      }}
                      customTheme={theme}
                      isLoading={countryLoading}
                      loadingMessage={() => "Fetching country list"}
                    />
                  </div>

                </section>

                <div className="benCreate__main__form--button-submit">
                  <BrandedRegularButton
                    type="button"
                    onClick={updateBeneficiaryDetails}
                    disabled={beneficiarySelector.updateLoading === Loading.FETCHING || countryLoading}>
                    {beneficiarySelector.updateLoading === Loading.FETCHING ? <ButtonSpinner /> :
                      <span>{languageDictionaryStatus ? languageDictionary["WO_SAVE_CHANGE_846696805"] : "Save Changes"}</span>
                    }
                  </BrandedRegularButton>
                </div>
              </form>


              <section className="benCreate__main__form--bottomSection">
                <div className="benCreate__main__form--subHeading">
                  <h3>
                    {languageDictionaryStatus ? languageDictionary["para_bank_account_details"] : "BANK ACCOUNT DETAILS"}
                  </h3>
                </div>

                <div className="benCreate__main__form--control">
                  <p className="benCreate__main__form--inputHeading">
                    {" Select Update Type"}
                  </p>
                  <Select
                    value={updateBankState}
                    onChange={option => setUpdateBankState(option)}
                    onBlur={() => formik.setFieldTouched("type", true)}
                    options={updateBankDropdown}
                    placeholder="Please select..."
                    isClearable={false}
                    isSearchable={false}
                    styles={customStyles}
                    customTheme={theme}
                    components={{
                      IndicatorSeparator: null,
                      DropdownIndicator: DropDownComponent
                    }}
                  />
                </div>

                {updateBankState.value === "updateBank" ?
                  <div className="benCreate__main__form--control">
                    <p className="benCreate__main__form--inputHeading">
                      {" "}{languageDictionaryStatus ? languageDictionary["WO_UPDATE_DETAIL_1271084717"] : "Update Details"}
                    </p>
                    <Select
                      value={formik.values.bank}
                      onChange={option => formik.setFieldValue("bank", option)}
                      onBlur={() => formik.setFieldTouched("type", true)}
                      options={transfers.beneficiaryBankInfo}
                      placeholder="Please select..."
                      isClearable={false}
                      isSearchable={false}
                      styles={customStyles}
                      customTheme={theme}
                      components={{
                        IndicatorSeparator: null,
                        DropdownIndicator: DropDownComponent
                      }}
                    />
                  </div> : updateBankState.value === "addBank" ?
                    <div className="benCreate__main__form--checkbox">
                      <label htmlFor="provideBeneficiary">
                        <input
                          type="checkbox"
                          name="isChecked"
                          id="provideBeneficiary"
                          checked={formik.values.isChecked}
                          onChange={formik.handleChange}
                          disabled={!(formik.values.benCountry && formik.values.benCountry.value)}
                        />
                        {languageDictionaryStatus ? languageDictionary["WO_I_WILL_PROVIDE_BENEF_-175913142"] : "I will provide beneficiary account details below"}
                      </label>
                    </div> : null
                }

                {formik.values.isChecked && updateBankState.value === "addBank" ?
                  <div className="benCreate__main__form--control">
                    <p className="benCreate__main__form--inputHeading">
                      {languageDictionaryStatus ? languageDictionary["l_action_type"] : "Type"}
                    </p>
                    <Select
                      value={formik.values.bankType}
                      onChange={(option) => formik.setFieldValue("bankType", option)}
                      onBlur={() => formik.setFieldTouched("bankType", true)}
                      options={bankActionType}
                      placeholder="Please select..."
                      isClearable={false}
                      isSearchable={true}
                      styles={customStyles}
                      customTheme={theme}
                      components={{
                        IndicatorSeparator: null,
                        DropdownIndicator: DropDownComponent
                      }}
                    />
                  </div> : ""
                }

                {Object.entries(formik.values.bank).length !== 0 && updateBankState.value === "updateBank" ?
                  <>
                    {transfers.bankFieldStatus === Loading.FETCHING ? <Spinner containerHeight="30vh" /> :
                      <>{fieldMappedComponent}</>
                    }
                  </> : ""
                }

                {formik.values.isChecked && updateBankState.value === "addBank" ?
                  <>
                    <div className="benCreate__main__form--control">
                      <p className="benCreate__main__form--inputHeading">
                        {languageDictionaryStatus ? languageDictionary["WO_SELECT_BANK_ACCOUNT_-1095805078"] : "Bank Account Country"}
                      </p>
                      <ErrorField
                        formik={formik}
                        fieldName="bankCountry"
                        fetchingData={countryLoading}
                        selectField
                      />
                      <Select
                        value={formik.values.bankCountry}
                        onChange={(option) => {
                          formik.setFieldValue("bankCountry", option);
                        }}
                        onBlur={() => formik.setFieldTouched("bankCountry", true)}
                        options={countrySelector.countryDropdown}
                        placeholder="Please select..."
                        name="bankCountry"
                        styles={customStyles}
                        components={{
                          IndicatorSeparator: null,
                          DropdownIndicator: DropDownComponent
                        }}
                        customTheme={theme}
                        isLoading={countryLoading}
                        loadingMessage={() => "Fetching country list"}
                        disabled
                      />
                    </div>

                    {formik.values.bankType.value === "CURRENCY" ?
                      <div className="benCreate__main__form--control">
                        <p className="benCreate__main__form--inputHeading">
                          {languageDictionaryStatus ? languageDictionary["l_currency"] : "Currency"}
                        </p>
                        <Select
                          value={formik.values.bankCurrency}
                          onChange={(option) => {
                            formik.setFieldValue("bankCurrency", option);
                            dispatch(fetchRequiredBankFieldsByFilter(formik.values.bankCountry.value, "ACCOUNTPAYMENT", option.value));
                          }}
                          onBlur={() => formik.setFieldTouched("bankCurrency", true)}
                          options={countrySelector.currencyDropdownList}
                          placeholder="Please select..."
                          name="bankCurrency"
                          styles={customStyles}
                          components={{
                            IndicatorSeparator: null,
                            DropdownIndicator: DropDownComponent
                          }}
                          customTheme={theme}
                          isLoading={countryLoading}
                          loadingMessage={() => "Fetching country list"}
                        />
                      </div> : ""
                    }

                    {transfers.bankFieldStatus === Loading.FETCHING ? <Spinner containerHeight="30vh" /> :
                      <>{fieldMappedComponent}</>
                    }
                  </> : ""
                }
              </section>

              <div className="benCreate__main__form--button-submit">
                <BrandedRegularButton
                  type="button"
                  onClick={updateBeneficiaryBank}
                  disabled={beneficiarySelector.updateLoading === Loading.FETCHING || countryLoading}>
                  {beneficiarySelector.updateLoading === Loading.FETCHING ? <ButtonSpinner /> :
                    <span>{languageDictionaryStatus ? languageDictionary["WO_SAVE_CHANGE_846696805"] : "Save Changes"}</span>
                  }
                </BrandedRegularButton>
              </div>
            </div>
          </>
        }
      </main>
      <Footer />
    </div>
  );
};

export default BeneficiaryEdit;

import { css } from '@emotion/react';
import { containerStyle } from 'Assets/styles/global/base';

export const bottomSection = (theme) => {
  return css`
    ${containerStyle};
  `;
};

export const tableStyle = (theme) => {
  return css`
    margin: 10px 0 30px;
    overflow-x: auto;

    table {
      border-collapse: separate;
      border-spacing: 0 15px;
      width: 100%;
      border: 1px solid #e2e8ec;
      border-radius: 5px;
      background: #ffffff;
    }

    th {
      color: #90a8be;
      font-weight: 600;
      text-transform: uppercase;
      font-size: 12px;
      padding-top: 5px;
      padding-bottom: 5px;
      padding-left: 30px;
      padding-right: 30px;
    }

    td {
      text-align: center;
    }

    tbody {
      td {
        background: #e3edf3;

        span {
          display: block;
          margin-top: 24.5px;
          margin-bottom: 24.5px;
          border-right: 2px solid #90a8be47;
          font-size: 14px;
          color: ${theme.primaryColor};
          font-weight: 500;
          padding-left: 20px;
          padding-right: 20px;
        }

        &:nth-of-type(1) {
          span {
            font-weight: 400;
          }
        }
        &:nth-of-type(2) {
          span {
            font-weight: 500;
          }
        }
        &:nth-of-type(4) {
          span {
            color: #90a8be;
          }
        }

        &:nth-of-type(3) {
          span {
            text-transform: uppercase;

            &.green {
              color: #0fcc98;
            }
            &.red {
              color: #e50c37;
            }
          }
        }

        .table-row-controls {
          display: flex;
          justify-content: space-around;
          padding: 0 10px;
        }
      }
    }
  `;
};

import styled from "@emotion/styled";
import { jsx, css } from "@emotion/react";

export const leftSectionWrapper = css`
  flex-basis: 47%;
  display: flex;
  flex-direction: column;
`;

export const rightSectionWrapper = css`
  flex-basis: 47%;
`;

export const bodyWrapper = css`
  display: flex;
  justify-content: space-between;
  padding-top: 30px;

  @media (max-width: 56.25rem) {
    flex-direction: column;
  }
`;

export const buttonStyle = (active) => css`
  border: 0.71px solid ${active ? "#0FCC98" : "#0055ad"};
  border-radius: 14px;
  flex-basis: 32%;
  height: 50px;
  color: ${active ? "white" : "black"};
  font-size: 14px;
  font-weight: 500;
  position: relative;
  outline: none;
  background: ${active ? "#0FCC98" : "white"};
  cursor: pointer;

  &:nth-of-type(2) {
    margin-right: 1.3333%;
    margin-left: 1.3333%;
  }
`;

export const textWrapperStyle = (theme) => css`
  display: flex;
  justify-content: space-between;

  &:not(:last-of-type) {
    margin-bottom: 40px;
  }

  p {
    font-size: 16px;
    color: ${theme.primaryColor};
    font-weight: 500;
  }
`;

export const AmountDetailWrapper = styled.div`
  background: #f5f9fb;
  border-radius: 10px;
  border: 1px solid #1149835b;
  margin-top: 20px;
  padding: 60px 7%;
`;

export const Separator = styled.hr`
  margin-bottom: 40px;
  border: 0;
  border-bottom: 1px solid #1149835b;
`;

export const CircleCheckWrapper = styled.span`
  position: absolute;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  border: 0.71px solid ${(props) => (props.active ? "white" : "#0055ad")};
  top: 10px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 56.25rem) {
    top: 2px;
  }
`;

export const CheckMark = styled.div`
  border: 1px solid white;
  border-left: 0;
  border-top: 0;
  height: 8px;
  width: 4px;
  transform: rotate(45deg);
`;

import Axios from "./Axios.service";

export default class BankAccountService {
  static async create(payload) {
    return Axios({
      method: "POST",
      url: "web-services/api/v6/services/walletmanagement/createnewwalletaccount",
      headers: {
        platform: "fincode",
        UUID: 200,
      },
      data: payload,
    });
  }

  static async fetchCurrencies() {
    return Axios({
      method: "GET",
      url: "web-services/api/v6/services/walletmanagement/listSupportedwalletaccountcurrencies",
      headers: {
        platform: "fincode",
        UUID: 200,
      },
    });
  }
}

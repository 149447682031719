import styled from "@emotion/styled";

export const Input = styled.input`
  width: 100%;
  border: 0;
  height: auto;
  background: #e3edf3;
  border-radius: 5px;
  /* padding: 0.375rem 0.75rem; */
  font-size: 16px;
  font-weight: 400;

  ::placeholder {
    color: #6c757d;
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const Button = styled.button`
  background-color: ${(props) => props.theme.primaryColor};
  color: #fff;

  :hover {
    color: #fff;
  }
`;

import Axios from "./Axios.service";

export default class TransferService {
  static async getTransferHistory({ currency, toDate, fromDate }) {
    const data = {
      toDate,
      fromDate,
      fromAmount: "0",
      toAmount: "10000000000",
      selectedCurrency: currency,
      startNumber: "1",
      appId: "1.0"
    };
    return Axios({
      data,
      method: "POST",
      url: "web-services/api/v6/services/transactionmanagement/history-data",
      headers: {
        platform: "fincode",
        UUID: 200
      }
    });
  }

  static async getQuote(requestPayload) {
    return Axios({
      data: requestPayload,
      method: "POST",
      url: "web-services/api/v6/services/quote/callQuote",
      headers: {
        platform: "fincode",
        UUID: 200
      }
    });
  }

  static async createTransaction(
    {
      senderCustomerCode,
      recipientCustomerCode,
      amount,
      sendAmount,
      receivingCurrency,
      originCountry,
      receivingCountry,
      creditParty,
      securityQuestion,
      securityAnswer,
      sendingCurrency,
      transactionReference,
      sourceOfFunds,
      remittancePurpose,
      type
    }, password) {
    const requestPayload = {
      type,
      amount,
      sendAmount,
      inclussive: true,
      receivingCurrency: receivingCurrency,
      sendCurrency: sendingCurrency,
      transactionProcessingMode: "NONE_AGGREGATION",
      descriptionText: "",
      preExistingSenderCustomerCode: senderCustomerCode,
      preExistingRecipientCustomerCode: recipientCustomerCode,
      creditParty,
      securityQuestion,
      securityAnswer,
      transactionReference,
      internationalTransferInformation: {
        originCountry,
        receivingCountry,
        sourceOfFunds: sourceOfFunds,
        remittancePurpose: remittancePurpose,
        deliveryMethod: "directtoaccount"
      }
    };

    return Axios({
      data: requestPayload,
      method: "POST",
      url: "/web-services/api/v6/services/transactionmanagement/create-transaction",
      headers: {
        platform: "fincode",
        UUID: 200,
        password
      }
    });
  }

  static async getPaymentMethods({ payableType, amount, pcn, currency, countryIsoCode3 }) {
    const requestPayload = {
      countryIsoCode3,
      currency,
      pcn,
      amount,
      payAbleType: payableType,
      transactionType: "ACCOUNTPAYMENT",
      appId: "1"
    };

    return Axios({
      data: requestPayload,
      method: "POST",
      url: "/web-services/api/v6/services/paymentmanagement/supported-payment-methods",
      headers: {
        platform: "fincode",
        UUID: 200
      }
    });
  }

  static async makeWalletPayment(payload) {
    return Axios({
      data: payload,
      method: "POST",
      url: "/web-services/api/v6/services/paymentmanagement/makepaymentfromwalletaccount",
      headers: {
        platform: "fincode",
        UUID: 200
      }
    });
  }

  static async fetchRequiredBankFields(path) {
    return Axios({
      method: "GET",
      url: `web-services/api/v6/services/bankmanagement/required-bank-fields/${path}`,
      headers: {
        platform: "fincode",
        UUID: 200
      }
    });
  }

  static async cardPayment() {
    return Axios({
      method: "POST",
      url: "https://cors-anywhere.herokuapp.com/cath.themoneytransferapplication.com/preinterswitch.xhtml?payable-type=TRANSACTION&pcn=CATH11411431&paymenttype=ONLINE_PAYMENT&subAction=ACCOUNTPAYMENT&X-Auth-Token=YoyLJEgshKcNI8vLnir2FrH6bDWFkr&platform=fincode&uuid=200"
    });
  }

  static async fetchTransactionStatus({ pcn }) {
    return Axios({
      method: "GET",
      url: `web-services/api/v6/services/transactionmanagement/checktransactionstatus/${pcn}`,
      headers: {
        platform: "fincode",
        UUID: 200
      }
    });
  }

  static async accountInfoLookup(requestBody) {
    return Axios({
      method: "POST",
      url: "web-services/api/v6/services/walletmanagement/walletaccountinfolookup",
      headers: {
        platform: "fincode",
        UUID: 200
      },
      data: requestBody
    });
  }

  static async lockTransactionForPayout(pcn) {
    return Axios({
      method: "GET",
      url: `web-services/api/v6/services/transactionmanagement/locktransactionforpayout/${pcn}`,
      headers: {
        platform: "fincode",
        UUID: 200
      }
    });
  }

  static async payoutTransaction(requestBody, password) {
    return Axios({
      method: "POST",
      url: `web-services/api/v6/services/transactionmanagement/payouttransaction`,
      headers: {
        platform: "fincode",
        UUID: 200,
        password
      },
      data: requestBody
    });
  }

  static async bookWalletToWallet(requestBody) {

    return Axios({
      method: "POST",
      url: "web-services/api/v6/services/walletmanagement/book-wallet-to-wallet-transafer",
      headers: {
        platform: "fincode",
        UUID: 200
      },
      data: requestBody
    });
  }

  static async bookFundWalletAccount(payload) {
    return Axios({
      method: "POST",
      url: "web-services/api/v6/services/walletmanagement/initatefundmywalletaccount",
      headers: {
        platform: "fincode",
        UUID: 200
      },
      data: payload
    });
  }

  static async fetchTransactionDetails({ pcn, payableType }) {
    return Axios({
      method: "GET",
      url: `web-services/api/v6/services/transactionmanagement/fetch-payable-type/${pcn}/${payableType}`,
      headers: {
        platform: "fincode",
        UUID: 200
      }
    });
  }

  static async cancelTransaction({ pcn, password, payableType }) {
    return Axios({
      method: "POST",
      url: "web-services/api/v6/services/transactionmanagement/cancel-payable-type",
      headers: {
        platform: "fincode",
        UUID: 200
      },
      data: {
        password,
        pcn,
        payableType
      }
    });
  }

  static async payLater({ pcn, payableType }) {
    return Axios({
      method: "PUT",
      url: `web-services/api/v6/services/paymentmanagement/bank-iwillpaylater-wallet/${pcn}/${payableType}`,
      headers: {
        platform: "fincode",
        UUID: 200
      }
    });
  }

  static async notifyBank({ pcn, payableType }) {
    return Axios({
      method: "POST",
      url: "web-services/api/v6/services/paymentmanagement/notify-bank-transfer-payment",
      headers: {
        platform: "fincode",
        UUID: 200
      },
      data: {
        pcn,
        option: "bank_paid_now",
        payAbleType: payableType
      }
    });
  }
}

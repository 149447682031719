import * as Merchant from "Store/constants/merchant.constants";

const initialState = {
  countries: [],
  services: [],
  products: [],
  loading: false,
  loadingStatus: "",
  servicesLoadingStatus: "",
  productsLoadingStatus: "",
  paymentLoadingStatus: "",
  paymentInfo: {},
};

const merchantReducer = (state = initialState, action) => {
  switch (action.type) {
    case Merchant.FETCH_COUNTRY_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        loadingStatus: "FETCHING",
      };

    case Merchant.FETCH_COUNTRY_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        loadingStatus: "SUCCESS",
        countries: action.payload,
      };

    case Merchant.FETCH_COUNTRY_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        loadingStatus: "ERROR",
      };

    case Merchant.FETCH_MERCHANT_CATEGORY_BY_COUNTRY_REQUEST:
      return {
        ...state,
        loading: true,
        servicesLoadingStatus: "FETCHING",
      };

    case Merchant.FETCH_MERCHANT_CATEGORY_BY_COUNTRY_SUCCESS:
      return {
        ...state,
        loading: false,
        servicesLoadingStatus: "SUCCESS",
        services: action.payload,
      };

    case Merchant.FETCH_MERCHANT_CATEGORY_BY_COUNTRY_FAILURE:
      return {
        ...state,
        loading: false,
        servicesLoadingStatus: "ERROR",
      };
    case Merchant.FETCH_PRODUCTS_REQUEST:
      return {
        ...state,
        loading: true,
        productsLoadingStatus: "FETCHING",
      };

    case Merchant.FETCH_PRODUCTS_SUCCESS:
      return {
        ...state,
        loading: false,
        productsLoadingStatus: "SUCCESS",
        products: action.payload,
      };

    case Merchant.FETCH_PRODUCTS_FAILURE:
      return {
        ...state,
        loading: false,
        productsLoadingStatus: "ERROR",
      };

    case Merchant.INITIATE_MERCHANT_PAYMENT_REQUEST: 
      return {
        ...state,
        loading: true,
        paymentLoadingStatus: "FETCHING",
      };

    case Merchant.INITIATE_MERCHANT_PAYMENT_SUCCESS: 
      return {
        ...state,
        loading: false,
        paymentLoadingStatus: "SUCCESS",
        paymentInfo: action.payload,
      };

    case Merchant.INITIATE_MERCHANT_PAYMENT_FAILURE: 
      return {
        ...state,
        paymentLoadingStatus: "ERROR",
      };

    default:
      return state;
  }
};

export default merchantReducer;

import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { modalContainer, modalContainerHeading } from "./style";
import FormLabel from "Components/FormLabel";
import Select from "react-select";
import downArrow from "Assets/svg/blue-drop-down-arrow.svg";
import customStyles from "Assets/styles/dropdown/customStyles";
import DropDownComponent from "Components/DropDownComponent";
import AriaModal from "react-aria-modal";

import CustomInput from "Components/CustomInput";
import CustomTextArea from "Components/CustomTextArea";

import "./index.scss";
import CustomButton from "Components/CustomButton";
import FlexibleButton from "Components/FlexibleButton";
import { createCategory, getAllCategories } from "Store/actions/category.action";

const CreateCategory = ({ languageDictionary }) => {
  const dispatch = useDispatch();
  const [showCategoryModal, updateShowCategoryModal] = useState(false);

  const { categories, theme, domainBrand } = useSelector(
    ({ categoryReducer, themeReducer, domainBrandReducer }) => ({
      categories: categoryReducer,
      theme: themeReducer,
      domainBrand: domainBrandReducer,
    })
  );

  // language state
  const languageDictionaryStatus = !(
    typeof languageDictionary === "undefined" || languageDictionary === null
  );

  const formik = useFormik({
    initialValues: {
      categoryName: "",
      description: "",
    },
    validationSchema: Yup.object({
      categoryName: Yup.string().required("Category name is Required"),
      description: Yup.string().required("Description is required"),
    }),
    onSubmit: (values) => {
      const userRequest = {
        description: values.description,
        name: values.categoryName,
      };
      dispatch(createCategory(userRequest));
      setTimeout(() => {
        updateShowCategoryModal(false);
        dispatch(getAllCategories());
      }, 1000);
    },
  });

  const getApplicationNode = () => {
    return document.getElementById("root");
  };

  return (
    <div className="row">
      <div className="col-md-8">
        <FormLabel
          text={
            languageDictionaryStatus ? languageDictionary["WO_CATEGOR_63639526"] : "Categories"
          }>
          <Select
            options={categories.dropDownList}
            styles={customStyles}
            isClearable={false}
            isSearchable={true}
            customTheme={theme}
            placeholder={
              languageDictionaryStatus
                ? languageDictionary["WO_CHOOSE_OPTIO_-243822441"]
                : "Select an option"
            }
            components={{
              IndicatorSeparator: null,
              DropdownIndicator: () => <DropDownComponent iconSource={downArrow} />,
            }}
          />
        </FormLabel>
      </div>
      <div className="col-md-4 mt-md-4">
        <FlexibleButton
          buttonText={
            languageDictionaryStatus
              ? languageDictionary["WO_ADD_CATEGOR_-866821534"]
              : "Add Category"
          }
          buttonWidth="100%"
          onClick={() => {
            updateShowCategoryModal(true);
          }}
          height="50px"
          paddingVertical="0.3rem"
          paddingHorizontal="0.3rem"
          fontSize="14px"
        />
      </div>
      {showCategoryModal === true ? (
        <AriaModal
          titleText={
            languageDictionaryStatus
              ? languageDictionary["para_attach_customer_documents"]
              : "Create Category"
          }
          onExit={() => updateShowCategoryModal(false)}
          getApplicationNode={getApplicationNode}
          verticallyCenter={true}
          underlayStyle={{
            backdropFilter: "blur(28px)",
            background: "none",
          }}>
          <div css={modalContainer}>
            <h2 css={modalContainerHeading}>
              {languageDictionaryStatus
                ? languageDictionary["WO_ADD_CATEGOR_-866821534"]
                : "Add Category"}
            </h2>
            <div className="createCategory__formContainer__wrapper">
              <form onSubmit={formik.handleSubmit}>
                <CustomInput
                  formik={formik}
                  inputKey="categoryName"
                  placeholder="Enter new category"
                  inputSize="80"
                />
                <CustomTextArea
                  formik={formik}
                  inputKey="description"
                  placeholder="Notes"
                  inputSize="100"
                />

                <CustomButton
                  buttonText={
                    languageDictionaryStatus
                      ? languageDictionary["WO_ADD_CATEGOR_-866821534"]
                      : "Add Category"
                  }
                  loading={categories.createLoading}
                  onClick={() => updateShowCategoryModal(true)}
                />
              </form>
            </div>
          </div>
        </AriaModal>
      ) : null}
    </div>
  );
};

export default CreateCategory;

// eslint-disable-next-line no-unused-vars
import React from 'react';
/** @jsx jsx */
/** @jsxRuntime classic */

import { jsx } from '@emotion/react';
import NavBar from 'Components/Navbar';
import Footer from 'Components/Footer';
import {
  newsContainer,
  newsMain,
  mainContainer,
  mainHeading,
  topSection,
  topSectionCard,
  topSectionCardDate,
  topSectionCardTitle,
  topSectionCardButton,
  topSectionImg,
  bottomSection,
  bottomSectionCard,
  bottomSectionCardButton,
  bottomSectionImg,
} from './style';

import newsImgOne from 'Assets/images/newsImg1.jpg';
import newsImgTwo from 'Assets/images/newsImg2.jpg';

const NewsAndUpdates = () => {
  return (
    <div css={newsContainer}>
      <NavBar />
      <main css={newsMain}>
        <div css={mainContainer}>
          <h2 css={mainHeading}>News and Updates</h2>

          <div css={topSection}>
            <div css={topSectionCard}>
              <p css={topSectionCardDate}>22 OCT 2019</p>
              <p css={topSectionCardTitle}>Why use SpotBanc</p>
              <button css={topSectionCardButton}>Read more</button>
            </div>

            <div css={topSectionImg}>
              <img src={newsImgOne} alt="news" />
            </div>
          </div>

          <div css={bottomSection}>
            <div css={bottomSectionImg}>
              <img src={newsImgTwo} alt="news" />
            </div>

            <div css={bottomSectionCard}>
              <p css={topSectionCardDate}>22 OCT 2019</p>
              <p css={topSectionCardTitle}>Why use SpotBanc</p>
              <button css={bottomSectionCardButton}>Read more</button>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default NewsAndUpdates;

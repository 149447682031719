import styled from '@emotion/styled';

export const Input = styled.input`
    width: 100%;
    margin-top: 12px;
    border: 0;
    background: #e3edf3;
    border-radius: 5px;
    padding: 1.5rem;
    font-size: 16px;
    font-weight: 400;

    ::placeholder {
        color: #6c757d;
      }
`;

export const Card = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #e3edf3;
    border-radius: 0.25rem; 
`;
import { ActionCreator } from "Utils/Helpers";
import * as Cart from "Store/constants/cart.constants";
import * as CartService from "Utils/Services/Cart.service";
import { toast } from "react-toastify";
import { expiredTokenCheck } from "./auth.action";

export const addToCart = (productId) => async (dispatch, getItemsInCart) => {
  try {
    dispatch(ActionCreator(Cart.ADD_TO_CART_REQUEST));
    const { data: responseData } = await CartService.addItemToCart(productId);

    if (responseData.status === "FAILED") {
      throw responseData;
    }

    const { data } = responseData;
    dispatch(ActionCreator(Cart.ADD_TO_CART_SUCCESS, data));
  } catch (error) {
    dispatch(ActionCreator(Cart.ADD_TO_CART_FAILURE));
  }
};

export const getItemsInCart = () => async (dispatch) => {
  try {
    dispatch(ActionCreator(Cart.GET_ITEMS_IN_CART_REQUEST));
    const { data: responseData } = await CartService.getAllItemsInCart();

    if (responseData.status === "FAILED") {
      throw responseData;
    }

    const { data: { cart } } = responseData;
    dispatch(ActionCreator(Cart.GET_ITEMS_IN_CART_SUCCESS, cart));
  } catch (error) {
    dispatch(ActionCreator(Cart.GET_ITEMS_IN_CART_FAILURE));
  }
};

export const incrementQuantity = (itemId, quantity) => async (dispatch) => {
  try {
    dispatch(ActionCreator(Cart.INCREMENT_QUANTITY_REQUEST));
    const { data: responseData } = await CartService.incrementQuantity(itemId, quantity);

    if (responseData.status === "FAILED") {
      throw responseData;
    }

    const { data } = responseData;
    dispatch(
      ActionCreator(Cart.INCREMENT_QUANTITY_SUCCESS, { productId: itemId, quantity: data.quantity })
    );
  } catch (error) {
    dispatch(ActionCreator(Cart.INCREMENT_QUANTITY_FAILURE));
  }
};

export const decrementQuantity = (itemId, quantity) => async (dispatch) => {
  try {
    dispatch(ActionCreator(Cart.DECREMENT_QUANTITY_REQUEST));
    const { data: responseData } = await CartService.decrementQuantity(itemId, quantity);

    if (responseData.status === "FAILED") {
      throw responseData;
    }

    const { data } = responseData;
    dispatch(
      ActionCreator(Cart.DECREMENT_QUANTITY_SUCCESS, { productId: itemId, quantity: data.quantity })
    );
  } catch (error) {
    dispatch(ActionCreator(Cart.DECREMENT_QUANTITY_FAILURE));
  }
};

export const removeItemFromCart = (itemId) => async (dispatch) => {
  try {
    dispatch(ActionCreator(Cart.REMOVE_FROM_CART_REQUEST));
    const { data: responseData } = await CartService.removeItemFromCart(itemId);

    if (responseData.status === "FAILED") {
      throw responseData;
    }

    const {
      data: { cart },
    } = responseData;
    dispatch(ActionCreator(Cart.REMOVE_FROM_CART_SUCCESS, itemId, cart));
  } catch (error) {
    dispatch(ActionCreator(Cart.REMOVE_FROM_CART_FAILURE));
  }
};

export const initiateCartPayment = (payload) => async (dispatch) => {
  try {
    dispatch(ActionCreator(Cart.INITIATE_CART_PAYMENT_REQUEST));
    const { data: responseData } = await CartService.initiateCartPayment(payload);
    if (responseData.status === "FAILED") {
      throw responseData;
    }

    dispatch(ActionCreator(Cart.INITIATE_CART_PAYMENT_SUCCESS, responseData.data));
  } catch (error) {
    expiredTokenCheck(error, dispatch);

    dispatch(ActionCreator(Cart.INITIATE_CART_PAYMENT_FAILURE));
    console.error(error);
  }
};


import { css } from '@emotion/react';
import { containerStyle } from 'Assets/styles/global/base';

export const bodyCSS = () => {
  return css`
    min-height: 100vh;
    background: var(--background-color);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    main {
      flex: 1;
      position: relative;
    }
  `;
};

export const mainSectionCSS = () => {
  return css`
    ${containerStyle};
    padding-top: 40px;
    padding-bottom: 60px;
    @media (max-width: 56.25rem) {
      padding: 1rem;
      margin-top: 2rem;
    }`;
};

export const selectWrapperCSS = css`
  margin-bottom: 30px;
  @media (max-width: 56.25rem) {
    width: 100%;
`;

export const selectWalletCSS = css`
  margin-bottom: 35px;
  min-height: 2rem;
  @media (max-width: 56.25rem) {
    width: 100%;
`;

export const formWrapperCSS = css`
  border: 1px solid #90a8be6b;
  border-radius: 5px;
  padding-top: 45px;
  padding-bottom: 40px;
  width: 80%;
  margin: 30px auto;
  @media (max-width: 56.25rem) {
    width: 100%;
  }
`;

export const formContainerCSS = css`
  padding-left: 45px;
  padding-right: 15%;
  @media (max-width: 56.25rem) {
    padding: 1rem;
  }
`;

export const offCanvasIconCSS = css`
  position: absolute;
  top: 10vh;
  right: -11vw;
  width: 30vw;
  z-index: -1;
  img {
    width: 100%;
    height: 100%;
  }
`;

import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ThemeProvider } from "@emotion/react";

import Login from "Pages/Auth/Login";
import AccountBalance from "Pages/Dashboard/AccountBalance";
import BeneficiaryCreation from "Pages/Dashboard/Beneficiary/Create";
import BeneficiaryEdit from "Pages/Dashboard/Beneficiary/Edit";
import BeneficiaryList from "Pages/Dashboard/Beneficiary/ViewAll";
import CreateCategory from "Pages/Dashboard/Category/Create";
import ViewCategories from "Pages/Dashboard/Category/ViewAll";
import FundsTransfer from "Pages/Dashboard/Transfer/Routes";
import AccountStatement from "Pages/Dashboard/AccountStatement";
import AccountStatementPrint from "Pages/Dashboard/AccountStatement/printReceipt";
import ViewUsers from "Pages/Dashboard/User/View";
import CreateUser from "Pages/Dashboard/User/Create";
import CreateBankAccount from "Pages/Dashboard/BankAccount/Create";
import Profile from "Pages/Dashboard/Profile";
import FundAccount from "Pages/Dashboard/FundAccount";
import EditUser from "Pages/Dashboard/User/Edit";
import Contact from "Pages/Public/Contact";
import NewsAndUpdates from "Pages/Public/News";
import ResetPassword from "Pages/Auth/ResetPassword";
import ForgotPassword from "Pages/Auth/ForgotPassword";
import SignUp from "Pages/Auth/SignUp";
import AirtimeRoutes from "Pages/MultiView/Airtime/Routes";
import BillPaymentRoutes from "Pages/MultiView/BillPayment/Routes";
import MerchantProductRoutes from "Pages/MultiView/Merchant/Routes";
import OrderConfirmation from "Pages/MultiView/Merchant/OrderConfirmation";
import Error404 from "Pages/Public/404";
import Cart from "Pages/MultiView/Cart";
import Payout from "Pages/MultiView/Cashier/Payout";
import PayoutSearch from "Pages/MultiView/Cashier/PayoutSearch";
import PayoutTransaction from "Pages/MultiView/Cashier/PayoutTransaction";
import SecurityQuestion from "Pages/MultiView/Cashier/SecurityQuestion";
import MakePayment from "Pages/MultiView/MakePayment";
import MakePaymentOtp from "Pages/MultiView/MakePayment/MakePayment_OTP";
import UserPayment from "Pages/MultiView/Payment/UserPayment";
import MakePaymentDetails from "Pages/MultiView/MakePayment/MakePayment_Details";
import Compliance from "Pages/Dashboard/KYC/ComplianceForm";
import ScanAndPay from "Pages/MultiView/ScanAndPay";
import AirtimeDetails from "./Pages/MultiView/Airtime/AirtimeDetails";
import DashboardHome from "./Pages/Dashboard/Home";
import TransferTypes from "./Pages/Dashboard/Transfer/TransferType";
import PaymentMethod from "./Pages/Dashboard/Transfer/PaymentMethod";
import ChangePassword from "Pages/Dashboard/ChangePassword";
import Loader from "./Components/loader/Loader";

import { modifyStyle, modifyStyleWithCSS } from "Utils/updateStyles";
import { TokenHelpers } from "./Utils/Helpers";
import cookieHelper from "./Utils/Helpers/cookieHelper";
import config from "./Utils/Helpers/config";
import { fetchAppLanguage, fetchHostURL } from "./Utils/Helpers/general";
import { SUBDOMAIN_CHECK } from "./Store/constants/appLauncher.constants";
import * as Loading from "./Store/constants/loadingState.constants";
import * as BaseURL from "./Utils/Services/BaseUrl";
import * as DomainBrand from "./Store/actions/domain-brand.action";
import { convertDomainImageToBlob } from "./Utils/Helpers/imageDataUrl";
import { transactionTypes } from "./Utils/Services/DomainBrand.service";
import { setLoggedInUser } from "./Store/actions/auth.action";
import GetCustomerDetails from "./Pages/MultiView/Cashier/CustomerDetails";

const App = () => {
  let subdomain,
    domainURL = fetchHostURL(),
    selectedLanguage = fetchAppLanguage();
  const dispatch = useDispatch();
  const theme = useSelector(({ themeReducer }) => themeReducer);
  const domainBrand = useSelector(({ domainBrandReducer }) => domainBrandReducer);
  const [transferModeStatus, updateTransferModeStatus] = useState({});
  const checkMerchantPaymentStatus = cookieHelper.get(config.MERCHANT_PAYMENT_STATUS);
  const checkIfBrandCSSExists = !(
    typeof domainBrand["domainBrand"]["css"] === "undefined" ||
    domainBrand["domainBrand"]["css"] === null ||
    domainBrand["domainBrand"]["css"] === ""
  );
  cookieHelper.set(config.LOGO, domainBrand.domainBrand["logo"], 1);

  if (SUBDOMAIN_CHECK(domainURL)) {
    subdomain = domainURL.split(".")[0];
    cookieHelper.set(config.DOMAIN_DATA, subdomain, config.COOKIE_EXPIRY_PERIOD);
  } else {
    subdomain = BaseURL.DEFAULT_BASEURL;
    cookieHelper.set(config.DOMAIN_DATA, subdomain, config.COOKIE_EXPIRY_PERIOD);
  }

  useEffect(() => {
    const url = window.location.href.split("?token=")[0];
    const token = window.location.href.split("?token=")[1];
    if (typeof token !== "undefined" && token !== "")
      getExternalSite(token, url).catch((err) => console.log(err));
  }, []);

  const getExternalSite = async (token, url) => {
    await TokenHelpers.saveToken(token);
    await setLoggedInUser(null, dispatch, "Login");
    window.location.href = url;
  };

  useEffect(() => {
    if (domainBrand.loading !== Loading.SUCCESS)
      dispatch(DomainBrand.getDomainBrandIdentity(subdomain, selectedLanguage["id"]));
  }, []);

  useEffect(() => {
    if (checkIfBrandCSSExists) modifyStyleWithCSS(domainBrand["domainBrand"]["css"]);
    else modifyStyle(domainBrand["domainBrand"]);
    convertDomainImageToBlob(domainBrand["domainBrand"]["logo"]);
  }, [domainBrand]);

  // const getFaviconElement = () => {
  //   return document.getElementById("favicon");
  // };

  // useEffect(() => {
  //   const favicon = getFaviconElement();
  //   favicon.href = domainBrand["domainBrand"]["favicon"];
  // }, []);

  useEffect(() => {
    transactionTypes().then((res) => {
      const dataObject = res.data.data["transactionTypes"];
      let newTransTypes = [],
        transferRouteObject = {};
      dataObject.forEach((element) => newTransTypes.push(element["serviceType"]));
      if (newTransTypes.includes("MULTICURRENCY_WALLET_VIRTUAL_ACCOUNT_MANAGEMENT")) {
        transferRouteObject = { ...transferRouteObject, wallet: "ENABLED" };
      }
      if (newTransTypes.includes("ACCOUNTPAYMENT")) {
        newTransTypes = newTransTypes.filter((e) => e !== "ACCOUNTPAYMENT");
        transferRouteObject = { ...transferRouteObject, transferFunds: "ENABLED" };
      }
      cookieHelper.set(config.TRANSFER_MODE_STATUS, transferRouteObject, 1);
      updateTransferModeStatus(transferRouteObject);
    });
  }, []);

  const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        TokenHelpers.checkIfLoggedIn() ? <Component {...props} /> : <Redirect to={`/login`} />
      }
    />
  );

  const MerchantRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        TokenHelpers.checkIfLoggedIn() && checkMerchantPaymentStatus === "ENABLED" ? (
          <Component {...props} />
        ) : (
          <Error404 />
        )
      }
    />
  );

  const TransferModesRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        TokenHelpers.checkIfLoggedIn() &&
        transferModeStatus.transferFunds === "ENABLED" &&
        transferModeStatus.wallet === "ENABLED" ? (
          <Component {...props} />
        ) : (
          <Error404 />
        )
      }
    />
  );

  const VerifiedRoute = ({ children, path, ...rest }) => {
    const renderFunc = ({ location }) => {
      const accountStatus = cookieHelper.get(config.USER_VERIFIED_STATUS);
      const accountVerifiedStatus = !(
        accountStatus === null ||
        accountStatus === "" ||
        accountStatus === "UNVERIFIED"
      );
      return accountVerifiedStatus ? (
        <Switch>{children}</Switch>
      ) : (
        <Redirect to={{ pathname: "/kyc_document_upload", state: { from: location } }} />
      );
    };
    return <Route {...rest} render={renderFunc} />;
  };

  if (domainBrand.loading !== Loading.SUCCESS) return <Loader />;

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Switch>
          <Route path="/login" component={Login} />
          <Route exact path="/sign-up" component={SignUp} />
          <Route exact path="/reset-password" component={ResetPassword} />
          <Route exact path="/forgot-password" component={ForgotPassword} />
          <PrivateRoute exact path="/kyc_document_upload" component={Compliance} />

          <VerifiedRoute>
            <PrivateRoute exact path={["/dashboard", "/"]} component={DashboardHome} />
            <PrivateRoute exact path="/change-password" component={ChangePassword} />

            <PrivateRoute exact path="/contact" component={Contact} />
            <PrivateRoute exact path="/news" component={NewsAndUpdates} />
            <PrivateRoute path="/profile" component={Profile} />

            <PrivateRoute exact path="/account/balance" component={AccountBalance} />
            <PrivateRoute exact path="/account/statement" component={AccountStatement} />
            <PrivateRoute exact path="/account/statement-print" component={AccountStatementPrint} />
            <PrivateRoute exact path="/account/fund-account" component={FundAccount} />
            <PrivateRoute exact path="/beneficiaries" component={BeneficiaryList} />
            <PrivateRoute exact path="/beneficiaries/create" component={BeneficiaryCreation} />
            <PrivateRoute exact path="/beneficiaries/update" component={BeneficiaryEdit} />
            <PrivateRoute exact path="/categories/create" component={CreateCategory} />
            <PrivateRoute exact path="/categories" component={ViewCategories} />
            <PrivateRoute path="/transfer" component={FundsTransfer} />
            <TransferModesRoute path="/transfer-modes" component={TransferTypes} />
            <PrivateRoute exact path="/users" component={ViewUsers} />
            <PrivateRoute exact path="/users/create" component={CreateUser} />
            <PrivateRoute exact path="/users/update" component={EditUser} />
            <PrivateRoute exact path="/bank-account/create" component={CreateBankAccount} />

            <PrivateRoute path="/airtime-topup" component={AirtimeRoutes} />
            <PrivateRoute exact path="/airtime-topup/services" component={AirtimeDetails} />
            <PrivateRoute path="/billpayment" component={BillPaymentRoutes} />

            <PrivateRoute path="/transfer/choose-payment-method" component={PaymentMethod} />

            <PrivateRoute path="/merchant-product" component={MerchantProductRoutes} />

            <PrivateRoute exact path="/cart" component={Cart} />
            <MerchantRoute exact path="/scan-and-pay" component={ScanAndPay} />
            <PrivateRoute exact path="/get-sender" component={GetCustomerDetails} />
            <PrivateRoute exact path="/make-payment" component={MakePayment} />
            <PrivateRoute exact path="/make-payment-otp" component={MakePaymentOtp} />
            <PrivateRoute exact path="/make-payment-details" component={MakePaymentDetails} />
            <PrivateRoute exact path="/cashier/payout" component={Payout} />
            <PrivateRoute exact path="/cashier/payout-search" component={PayoutSearch} />
            <PrivateRoute exact path="/cashier/security-question" component={SecurityQuestion} />
            <PrivateRoute exact path="/cashier/payout-transaction" component={PayoutTransaction} />
            <PrivateRoute exact path="/order-confirmation" component={OrderConfirmation} />
            <PrivateRoute exact path="/checkout" component={UserPayment} />

            <PrivateRoute path="/*" component={Error404} />
          </VerifiedRoute>
        </Switch>
      </Router>
    </ThemeProvider>
  );
};

export default App;

import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';

import NavBar from 'Components/Navbar';
import Footer from 'Components/Footer';
import CustomInput from 'Components/CustomInput';
import CustomTextArea from 'Components/CustomTextArea';

import './index.scss';
import offSetImg from 'Assets/svg/transfer1.svg';
import CustomButton from 'Components/CustomButton';
import FlexibleButton from 'Components/FlexibleButton';
// import CustomSelect from 'Components/CustomSelect';
import { createCategory } from 'Store/actions/category.action';

const CreateCategory = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const categorySelector = useSelector(({ categoryReducer }) => ({
    createLoading: categoryReducer.createLoading
  }));

  const submitRedirect = () => {
    let { from } = location.state || { from: { pathname: '/categories' } };
    const navigateMethod = () => history.replace(from);
    return navigateMethod;
  };

  const formik = useFormik({
    initialValues: {
      categoryName: '',
      description: ''
    },
    validationSchema: Yup.object({
      categoryName: Yup.string().required('Category name is Required'),
      description: Yup.string().required('Description is required')
    }),
    onSubmit: values => {
      const userRequest = {
        description: values.description,
        name: values.categoryName
      };
      const navigateMethod = submitRedirect();
      dispatch(createCategory(userRequest, navigateMethod));
      // alert(JSON.stringify(values, null, 2));
    }
  });

  const onClickHandler = () => history.push('/categories');

  return (
    <div className="createCategory__wrapper">
      <NavBar />
      <main className="createCategory__main">
        <div className="createCategory__main__canvasImg">
          <img src={offSetImg} alt="off canvas icon" />
        </div>

        <div className="createCategory__main__container">
          <div className="createCategory__topControls">
            <div className="createCategory__topControls__headingContainer">
              {/* <CustomSelect /> */}
              <p className="createCategory__topControls__heading">
                Add a new category
              </p>
            </div>
            <FlexibleButton
              buttonText="All Categories"
              buttonColor="#E3EDF3"
              fontSize="13px"
              textColor="#0055AD"
              fontWeight={500}
              paddingVertical="0.8125rem"
              onClick={onClickHandler}
            />
          </div>

          <div className="createCategory__formContainer">
            <div className="createCategory__formContainer__wrapper">
              <form onSubmit={formik.handleSubmit}>
                <CustomInput
                  formik={formik}
                  inputKey="categoryName"
                  placeholder="Enter new category"
                  inputSize="80"
                />
                <CustomTextArea
                  formik={formik}
                  inputKey="description"
                  placeholder="Notes"
                  inputSize="80"
                />

                <CustomButton
                  buttonText="ADD NEW CATEGORY"
                  loading={categorySelector.createLoading}
                />
              </form>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default CreateCategory;

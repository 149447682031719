import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useHistory, useLocation, Link } from "react-router-dom";
import Footer from "Components/Footer";
import NavBar from "Components/Navbar";
import styled from "@emotion/styled";

const NextButton = styled.button`
  font-size: 1rem;
`;

const OrderConfirmation = () => {
  const history = useHistory();
  const location = useLocation();

  const [order, setOrder] = useState(location.search)

  useEffect(() => {
    // eslint-disable-next-line no-unused-vars
    const pcn = setOrder(location.search.split('pcn=')[1])
  }, [location.search])


  return (
    <>
      <Helmet>
        <title>Order Confirmation &mdash; Multiview MTA</title>
      </Helmet>
      <NavBar />
      <section>
        <div className="container">
          <div className="mt-5 mb-5">
            <div className="d-flex justify-content-between align-items-center">
              <h2 className="text-blue">Order Confirmation</h2>
            </div>
            <div className="card mt-5 p-md-5">
              <div className="card-body mb-3 p-md-3">
                {/* --- Search box for product --- */}
                <div className="row justify-content-center mb-5">
                  <div className="col-12 col-md-10 col-lg-8 col-xl-6 text-center">
                    {/* ----  Icon ---- */}
                    <div className="mb-5 h1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="#0fcc98"
                        width="100px"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                    </div>

                    <h2 className="mb-2 text-blue">Your Order is Completed!</h2>

                    <p className="text-muted">
                      Your order has been placed and will be processed as soon
                      as possible. . Make sure you make note of your order
                      number, which is{" "}
                      <span className="text-blue font-weight-bold text-decoration-underline">
                        {order}

                      </span>{" "}
                      You will be receiving an email shortly with confirmation
                      of your order.
                    </p>


                  </div>
                </div>

                {/* ----- Proceed Button ----- */}
                <div className="row justify-content-center text-center">
                  <div className="col-12 col-md-5 col-xl-3 mb-3">
                    <Link
                      to="/merchant-product"
                      onClick={() => history.push()}
                      role="button"
                      tabIndex="-1"
                      onKeyPress={() => {}}
                      className="text-decoration-none"
                    >
                      <NextButton className="btn btn-lg btn-green btn-block text-uppercase text-white">
                        continue shopping
                      </NextButton>
                    </Link>
                  </div>

                  {/* --- Track order --- */}
                  <div className="col-12 col-md-4 col-xl-3 mb-3">
                    <Link
                      to="/profile/order-history"
                      onClick={() => history.push()}
                      role="button"
                      tabIndex="-1"
                      onKeyPress={() => {}}
                      className="text-decoration-none"
                    >
                      <NextButton className="btn btn-lg btn-blue btn-block text-uppercase text-white">
                        View Order
                      </NextButton>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default OrderConfirmation;

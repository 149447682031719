import Axios from "./Axios.service";

export const addItemToCart = async (productId) => {
  return Axios({
    method: "POST",
    url: "web-services/api/v6/services/cartmanagement/add-item-to-cart",
    headers: {
      platform: "fincode",
      UUID: 200,
    },
    data: {
      payableType: "MERCHANT",
      payableId: productId,
      quantity: 1,
    },
  });
};

export const getAllItemsInCart = async () => {
  return Axios({
    method: "GET",
    url: "/web-services/api/v6/services/cartmanagement/get-all-items-in-cart",
    headers: {
      platform: "fincode",
      UUID: 200,
    },
  });
};

export const removeItemFromCart = async (productId) => {
  return Axios({
    method: "DELETE",
    url: `/web-services/api/v6/services/cartmanagement/delete-cart-item/${productId}`,
    headers: {
      platform: "fincode",
      UUID: 200,
    },
  });
};

export const incrementQuantity = async (productId, quantity) => {
  return Axios({
    method: "PUT",
    url: "/web-services/api/v6/services/cartmanagement/update-cart",
    headers: {
      platform: "fincode",
      UUID: 200,
    },
    data: {
      payableType: "MERCHANT",
      payableId: productId,
      quantity: quantity + 1,
    },
  });
};

export const decrementQuantity = async (productId, quantity) => {
  return Axios({
    method: "PUT",
    url: "/web-services/api/v6/services/cartmanagement/update-cart",
    headers: {
      platform: "fincode",
      UUID: 200,
    },
    data: {
      payableType: "MERCHANT",
      payableId: productId,
      quantity: quantity - 1,
    },
  });
};

export const initiateCartPayment = async (payload) => {
  return Axios({
    method: "POST",
    url: "/web-services/api/v6/services/cartmanagement/initiate-payment",
    headers: {
      platform: "fincode",
      UUID: 200,
    },
    data: payload,
  });
};
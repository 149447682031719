import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import Footer from "Components/Footer";
import NavBar from "Components/Navbar";
import Loader from "Components/loader/Loader";
import Spinner from "Components/Spinner";
import { getCountryList } from "Store/actions/merchant.action";
import CountryFlagCard from "Components/CountryFlagCard";
import styled from "@emotion/styled";
import cookieHelper from "Utils/Helpers/cookieHelper";
import config from "Utils/Helpers/config";
import BackArrow from "Components/BackArrow";

const Input = styled.input`
  width: 100%;
  margin-top: 12px;
  border: 0;
  background: #e3edf3;
  border-radius: 5px;
  padding: 1.5rem;
  font-size: 16px;
  font-weight: 400;

  ::placeholder {
    color: #6c757d;
  }
`;

const MerchantCountry = () => {
  const dispatch = useDispatch();
  const [filteredCountries, setFilteredCountries] = useState(null);
  const {
    merchant: { countries, loadingStatus },
    domainBrand,
  } = useSelector(({ merchantReducer, domainBrandReducer }) => ({
    merchant: merchantReducer,
    domainBrand: domainBrandReducer,
  }));

  // Language state
  const languageDictionary = domainBrand.domainBrand["languageDictionary"];
  const languageDictionaryStatus = !(
    typeof languageDictionary === "undefined" || languageDictionary === null
  );

  useEffect(() => {
    const tradeOriginCountry = cookieHelper.get(config.USER_COUNTRY);
     if (loadingStatus !== "SUCCESS") {
    dispatch(getCountryList(tradeOriginCountry));
     }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = (event) => {
    const { value } = event.target;
    if (!value) {
      setFilteredCountries(null);
      return;
    }
    const searchTerm = value.toLowerCase();
    const filtered = countries.filter((country) => {
      const countryName = country.common_name.toLowerCase();
      const countryIso3Code = country.iso3Code.toLowerCase();
      return countryName.includes(searchTerm) || countryIso3Code.includes(searchTerm);
    });
    setFilteredCountries(filtered);
  };

  return (
    <>
      <Helmet>
        <title>
          {languageDictionaryStatus
            ? languageDictionary["WO_MERCHANT_PRODUCT_1436670176"]
            : "Merchant Product"}
        </title>
      </Helmet>
      <NavBar />
      <section>
        {loadingStatus === "FETCHING" ? (
          <Loader />
        ) : (
          <div className="container">
            <div className="mt-5 mb-5">
              <div className="d-flex">
                <BackArrow />
                <h2 className="text-blue mt-4">{languageDictionaryStatus ? languageDictionary["WO_MERCHANT_PRODUCT_1436670176"]  : "Merchant Product"}
                </h2>
              </div>

              <div className="card mt-5 p-xl-5">
                <div className="card-body mb-3 p-md-3">
                  {/* --- Search box for destination country --- */}
                  <div className="row mb-5">
                    <div className="col-12 col-md-8 col-lg-6">
                      <p className="text-blue">
                        {languageDictionaryStatus
                          ? languageDictionary["DESTINATION_COUNTR_1477464105"]
                          : "Destination Country"}
                      </p>
                      <Input
                        type="text"
                        className="form-control"
                        placeholder="Search for country..."
                        onChange={handleSearch}
                      />
                    </div>
                  </div>

                  {/* --- Search box for destination country --- */}
                  <div className="row">
                    <div className="col-12">
                      {loadingStatus === "FETCHING" ? (
                        <Spinner containerHeight="30vh" />
                      ) : countries.length === 0 ? (
                        <p className="text-center">No countries available now</p>
                      ) : (
                        <>
                          <p className="text-blue ">
                            {languageDictionaryStatus
                              ? languageDictionary["msg_please_select_a_country"]
                              : "Select a country"}
                          </p>

                          <div className="row">
                            {(filteredCountries || countries).map((country) => {
                              return (
                                <CountryFlagCard
                                  key={country.iso3Code}
                                  text={country.iso3Code}
                                  link={`/merchant-product/products?country=${country["iso3Code"]}`}
                                  params={country["iso3Code"]}
                                  flag={country.flag}
                                  country={country.common_name}
                                />
                              );
                            })}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </section>

      <Footer />
    </>
  );
};

export default MerchantCountry;

// eslint-disable-next-line no-unused-vars
import React from 'react';
/** @jsx jsx */
/** @jsxRuntime classic */
import { jsx } from '@emotion/react';
import { ReactComponent as TickIcon } from 'Assets/svg/tick.svg';
import { alertContainer, alertCheck, alertText } from './style';

const AlertCard = ({ message }) => {
  return (
    <div css={alertContainer}>
      <div css={alertCheck}>
        <TickIcon />
      </div>

      <p css={alertText}>{message}</p>
    </div>
  );
};

export default AlertCard;

import Axios from "./Axios.service";

export const fetchCountryList = async () => {
  return Axios({
    method: "GET",
    url: `/web-services/api/v6/services/quote/destination-country-by-delivery-method/GBR/BILL_PAYMENT`,
    headers: {
      platform: "fincode",
      UUID: 200,
    },
  });
};

export const fetchBillServiceByCountry = async (countryIso3Code) => {
  return Axios({
    method: "GET",
    url: `/web-services/api/v6/services/billpayment/fetchservicesbycountry1/${countryIso3Code}`,
    headers: {
      platform: "fincode",
      UUID: 200,
    },
  });
};

export const fetchOperatorsByService = async (countryIso3Code, serviceId) => {
  return Axios({
    method: "GET",
    url: `/web-services/api/v6/services/billpayment/fetchoperatorsbycountry2/${countryIso3Code}/${serviceId}`,
    headers: {
      platform: "fincode",
      UUID: 200,
    },
  });
};

export const fetchProductsByOperator = async (
  countryIso3Code,
  serviceId,
  operatorId,
  accountReferenceNumber
) => {
  return Axios({
    method: "GET",
    url: `/web-services/api/v6/services/billpayment/fetchcountrysproducts3/${countryIso3Code}/${serviceId}/${operatorId}?accountreference=${accountReferenceNumber}`,
    headers: {
      platform: "fincode",
      UUID: 200,
    },
  });
};

export const fetchQuoteForBillPayment = async ({ amount, currency }) => {
  const requestPayload = {
    paymentType: "ONLINE_CARD_PAYMENT",
    chargeCategory: "MONEYTRANSFER",
    principalAmount: amount,
    principalAmountCurrency: "GBP",
    deriveAmount: amount,
    deriveAmountCurrency: currency,
    transactionType: "BILL_PAYMENT",
    inverseCalculation: true,
    conversion: true,
  };

  return Axios({
    data: requestPayload,
    method: "POST",
    url: "web-services/api/v6/services/quote/callQuote",
    headers: {
      platform: "fincode",
      UUID: 200,
    },
  });
};

export const initiateBillPayment = async ({
  currency,
  productId,
  amount,
  serviceId,
  operatorId,
  destinationCountryIso3,
}) => {
  const requestPayload = {
    payer_currency: "GBP",
    product_currency: currency,
    product_id: productId,
    product_value: amount,
    service_id: serviceId,
    operator_id: operatorId,
    bill_payment_destination_iso: destinationCountryIso3,
  };
  return Axios({
    method: "POST",
    url: "/web-services/api/v6/services/billpayment/orderproduct",
    headers: {
      platform: "fincode",
      UUID: 200,
    },
    data: requestPayload,
  });
};

export const getBillPaymentMethods = async ({
  payableType,
  amount,
  pcn,
  currency,
  countryIsoCode3,
}) => {
  const requestPayload = {
    countryIsoCode3,
    currency,
    pcn,
    amount,
    payAbleType: payableType,
    transactionType: "BILL_PAYMENT",
    appId: "1",
  };

  return Axios({
    data: requestPayload,
    method: "POST",
    url: "/web-services/api/v6/services/paymentmanagement/supported-payment-methods",
    headers: {
      platform: "fincode",
      UUID: 200,
    },
  });
};

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import customStyles from "Assets/styles/dropdown/customStyles";
import { checkUrlExists } from "../../Utils/Helpers/general";
import styles from "./index.module.scss";
import Select from "react-select";
import avatar from "Assets/images/gravatar.png";

import * as DomainBrand from "Store/actions/domain-brand.action";
import cookieHelper from "Utils/Helpers/cookieHelper";
import config from "Utils/Helpers/config";
import { fetchAppLanguage, languageChangeHelper } from "Utils/Helpers/general";
import * as Loading from "Store/constants/loadingState.constants";
import DropDownComponent from "Components/DropDownComponent";
import downArrow from "Assets/svg/blue-drop-down-arrow.svg";
import { logUserOut } from "Store/actions/auth.action";

const Dropdown = ({ navItems, smallSize, switchMethod, isButton, logOutMethod }) => {
  const LinkElement = ({ item }) => {
    if (isButton) {
      return <button onClick={() => switchMethod(item.name)}>{item.name}</button>;
    } else if (item.isLogOut) {
      return <button onClick={logOutMethod}>{item.name}</button>;
    } else {
      return <Link to={item.link}>{item.name}</Link>;
    }
  };

  return navItems ? (
    <ul
      className={`${styles.navBar__dropdown} ${
        smallSize ? styles.navBar__dropdown__small : styles.navBar__dropdown__large
      }`}>
      {navItems.map((item, index) => {
        return (
          <li key={index} className={`${item.active ? styles.active : null}`}>
            <LinkElement item={item} />
          </li>
        );
      })}
    </ul>
  ) : null;
};

const NavBar = () => {
  const dispatch = useDispatch();
  const { userData } = useSelector(({ profileReducer }) => profileReducer);

  let domainBrandStatus, brandLangSelectorOptions, selectedLanguage = fetchAppLanguage();
  const [languageField, updateLanguageField] = useState("");

  const { domainBrand } = useSelector(({ domainBrandReducer }) => ({
    domainBrand: domainBrandReducer
  }));

  brandLangSelectorOptions =
    domainBrand.loading === Loading.SUCCESS ?
      domainBrand["domainBrand"]["language"].map((forEach, index) => {
        return (
          <option value={JSON.stringify(forEach)} key={index}>
            {forEach["commonName"]}
          </option>
        );
      }) : <option value={JSON.stringify({ code: "EN", commonName: "ENGLISH" })}>English</option>;

  let languageStatus = brandLangSelectorOptions.length > 0;

  const handleLanguageChange = (event) => {
    const languageHelper = languageChangeHelper(event);
    updateLanguageField(languageHelper["value"]);
    dispatch(DomainBrand.getDomainBrandIdentity(languageHelper["subdomain"], languageHelper["languageObject"]["id"]));
  };

  useEffect(() => {
    if (domainBrand.loading === "" || domainBrand.loading === null) {
      const selectedLanguage = fetchAppLanguage();
      const subdomain = cookieHelper.get(config.DOMAIN_DATA);
      dispatch(DomainBrand.getDomainBrandIdentity(subdomain, selectedLanguage["id"]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const langSelectorOptions = (
    <>
      <option>English</option>
    </>
  );

  let profileImageStatus = checkUrlExists(userData.profileImage);
  let profileImage;
  typeof userData.profileImage === "undefined" || typeof profileImageStatus === "undefined"
    ? (profileImage = avatar)
    : (profileImage = userData.profileImage.replace("?type=profile", ""));

  domainBrandStatus = domainBrand.loading === Loading.SUCCESS;

  return (
    <Navbar collapseOnSelect expand="lg" className={`${styles.navBar}`}>
      <div className="container-fluid">
        <Navbar.Brand>
          <Link to="/" className="d-none d-md-block">
            <img
              src={domainBrandStatus ? domainBrand["domainBrand"]["logo"] : "MultiView MTA"}
              alt={domainBrandStatus ? domainBrand["domainBrand"]["companyName"] : "MultiView MTA"}
              className="img-fluid"
              style={{ width: "100px" }}
            />
          </Link>
          <Link to="/dashboard" className="d-md-none">
            <img
              src={domainBrandStatus ? domainBrand["domainBrand"]["logo"] : "MultiView MTA"}
              alt="logo"
              className="img-fluid"
              style={{ width: "80px" }}
            />
          </Link>
        </Navbar.Brand>
        <div className="ml-md-auto" style={{ minWidth: "150px" }}>
          {languageStatus ? (
            <Select
              options={brandLangSelectorOptions}
              styles={customStyles}
              isClearable={false}
              isSearchable={true}
              name={languageField}
              value={languageField}
              customTheme={""}
              placeholder="ENGLISH"
              onChange={(option) => {
                handleLanguageChange(option);
              }}
              components={{
                IndicatorSeparator: null,
                DropdownIndicator: () => <DropDownComponent iconSource={downArrow} />
              }}
            />
          ) : (
            <select>{langSelectorOptions}</select>
          )}
        </div>

        <div className={styles.navBar__rightSection__avatar}>
          <span>
            <img src={profileImage} alt="profile_picture" />
          </span>
          <span>
            <img src={downArrow} alt="right arrow" />
          </span>

          <Dropdown
            logOutMethod={() => dispatch(logUserOut(domainBrand.domainBrand['publicWebSite']))}
            navItems={[{ name: "Login", isLogOut: true }]}
          />
        </div>
      </div>
    </Navbar>
  );
};

export default NavBar;

export const modifyStyle = (themeData) => {
  const root = document.documentElement;
  root.style.setProperty("--background-color", themeData["backgroundColor"] || "#FAFAFA");
  root.style.setProperty("--navbar-color", themeData["top_bottom_bar"] || "#0078B3");
  root.style.setProperty("--page-title-color", themeData["page_title_color"] || "#0078B3");
  root.style.setProperty("--text-color", themeData["text_color"] || "#333333");
  root.style.setProperty("--navbar-text-color", themeData["font_style1"] || "#333333");
  root.style.setProperty("--labels-color", themeData["font_style3"] || "#333333");
  root.style.setProperty("--font-style", themeData["font_style4"] || "Poppins");
  root.style.setProperty("--font-style2", themeData["font_style5"] || "Poppins");
  root.style.setProperty("--font-size", themeData["font_size1"] || "1rem");
  root.style.setProperty("--font-size2", themeData["font_size2"] || "1rem");
  root.style.setProperty("--submit-button-color", themeData["buttonColor"] || "#0078B3");
  root.style.setProperty("--submit-button-text-color", themeData["button_text_color"] || "#FAFAFA");
  root.style.setProperty("--submit-button-active-color", themeData["buttonActive1"] || "#449F85");
  root.style.setProperty("--submit-button-hover-color", themeData["buttonHoverColor"] || "#4BB395");
  root.style.setProperty("--regular-button-color", themeData["buttonColor1"] || "#4E7BAA");
  root.style.setProperty("--regular-button-text-color", themeData["buttonTextColor1"] || "#FAFAFA");
  root.style.setProperty("--regular-button-active-color", themeData["buttonActive2"] || "#41668C");
  root.style.setProperty("--regular-button-hover-color", themeData["buttonHoverColor1"] || "#436A92");
  root.style.setProperty("--delete-button-color", themeData["buttonColor2"] || "#CF6768");
  root.style.setProperty("--delete-button-text-color", themeData["buttonTextColor2"] || "#FAFAFA");
  root.style.setProperty("--delete-button-active-color", themeData["buttonActive3"] || "#864345");
  root.style.setProperty("--delete-button-hover-color", themeData["buttonHoverColor2"] || "#AE595A");
};


export const modifyStyleWithCSS = (themeData) => {

  const root = document.documentElement;
  root.style.removeProperty("--background-color");
  root.style.removeProperty("--navbar-color");
  root.style.removeProperty("--page-title-color");
  root.style.removeProperty("--text-color");
  root.style.removeProperty("--navbar-text-color");
  root.style.removeProperty("--labels-color");
  root.style.removeProperty("--submit-button-color");
  root.style.removeProperty("--submit-button-text-color");
  root.style.removeProperty("--submit-button-active-color");
  root.style.removeProperty("--submit-button-hover-color");
  root.style.removeProperty("--regular-button-color");
  root.style.removeProperty("--regular-button-text-color");
  root.style.removeProperty("--regular-button-active-color");
  root.style.removeProperty("--regular-button-hover-color");
  root.style.removeProperty("--delete-button-color");
  root.style.removeProperty("--delete-button-text-color");
  root.style.removeProperty("--delete-button-active-color");
  root.style.removeProperty("--delete-button-hover-color");

  const style = document.createElement("style");
  style.textContent = themeData;
  document.head.append(style);
};

// eslint-disable-next-line no-unused-vars
import React from 'react';
/** @jsx jsx */
/** @jsxRuntime classic */

import { jsx, css } from '@emotion/react';
import { useRouteMatch, Link } from 'react-router-dom';

const CustomLink = ({ to, activeOnlyWhenExact, label, SvgIcon }) => {
  const match = useRouteMatch({ path: to, exact: activeOnlyWhenExact });

  const activeCss = () => css`
    background: var(--navbar-color);
    color: white;
    :hover {
      color: white;
    }
    path {
      fill: white;
    }
  `;

  const linkCss = theme => css`
    display: flex;
    background: #e3edf373;
    height: 80px;
    margin-bottom: 20px;
    border-radius: 5px;
    text-decoration: none;
    align-items: center;
    color: ${theme.primaryColor};

    path {
      fill: ${theme.primaryColor};
    }

    ${match && activeCss(theme)};
  `;

  return (
    <Link to={to} css={linkCss}>
      <span
        css={css`
          width: 8%;
          margin-left: 8%;
          margin-right: 17%;
        `}
      >
        <SvgIcon height="100%" width="100%" />
      </span>
      <span>{label}</span>
    </Link>
  );
};

export default CustomLink;

export const FETCH_BENEFICIARY_REQUEST = 'FETCH_BENEFICIARY_REQUEST';
export const FETCH_BENEFICIARY_SUCCESS = 'FETCH_BENEFICIARY_SUCCESS';
export const FETCH_BENEFICIARY_FAILURE = 'FETCH_BENEFICIARY_FAILURE';

export const CREATE_BENEFICIARY_REQUEST = 'CREATE_BENEFICIARY_REQUEST';
export const CREATE_BENEFICIARY_SUCCESS = 'CREATE_BENEFICIARY_SUCCESS';
export const CREATE_BENEFICIARY_FAILURE = 'CREATE_BENEFICIARY_FAILURE';

export const UPDATE_BENEFICIARY_REQUEST = 'UPDATE_BENEFICIARY_REQUEST';
export const UPDATE_BENEFICIARY_SUCCESS = 'UPDATE_BENEFICIARY_SUCCESS';
export const UPDATE_BENEFICIARY_FAILURE = 'UPDATE_BENEFICIARY_FAILURE';

export const FETCH_SUPPORTED_BANKS_REQUEST = 'FETCH_SUPPORTED_BANKS_REQUEST';
export const FETCH_SUPPORTED_BANKS_SUCCESS = 'FETCH_SUPPORTED_BANKS_SUCCESS';
export const FETCH_SUPPORTED_BANKS_FAILURE = 'FETCH_SUPPORTED_BANKS_FAILURE';

export const FETCH_BENEFICIARY_DETAILS_REQUEST =
  'FETCH_BENEFICIARY_DETAILS_REQUEST';
export const FETCH_BENEFICIARY_DETAILS_SUCCESS =
  'FETCH_BENEFICIARY_DETAILS_SUCCESS';
export const FETCH_BENEFICIARY_DETAILS_FAILURE =
  'FETCH_BENEFICIARY_DETAILS_FAILURE';

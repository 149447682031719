import { ActionCreator } from "Utils/Helpers";
import * as Bill from "Store/constants/bill.constants";
import * as BillService from "Utils/Services/Bill.service";
import { expiredTokenCheck } from "./auth.action";
import { fetchSupportedCountriesByDeliveryMethod } from "Utils/Services/Country.service";

export const getCountryList = (originCountry) => async (dispatch) => {
  try {
    dispatch(ActionCreator(Bill.FETCH_COUNTRY_LIST_REQUEST));
    const { data: responseData } = await fetchSupportedCountriesByDeliveryMethod(originCountry,"BILL_PAYMENT");

    if (responseData.status === "FAILED") {
      throw responseData;
    }

    const {
      data: { countries },
    } = responseData;
    dispatch(ActionCreator(Bill.FETCH_COUNTRY_LIST_SUCCESS, countries));
  } catch (error) {
    dispatch(ActionCreator(Bill.FETCH_COUNTRY_LIST_FAILURE));
  }
};

export const getBillServiceByCountry = (countryIso3Code) => async (dispatch) => {
  try {
    dispatch(ActionCreator(Bill.FETCH_BILL_SERVICE_BY_COUNTRY_REQUEST));
    const { data: responseData } = await BillService.fetchBillServiceByCountry(countryIso3Code);
    if (responseData.status === "FAILED") {
      throw responseData;
    }

    const {
      data: { serviceDTO },
    } = responseData;
    dispatch(ActionCreator(Bill.FETCH_BILL_SERVICE_BY_COUNTRY_SUCCESS, serviceDTO));
  } catch (error) {
    dispatch(ActionCreator(Bill.FETCH_BILL_SERVICE_BY_COUNTRY_FAILURE));
  }
};

export const getOperatorByService = (countryIso3Code, serviceId) => async (dispatch) => {
  try {
    dispatch(ActionCreator(Bill.FETCH_OPERATORS_BY_SERVICE_REQUEST));
    const { data: responseData } = await BillService.fetchOperatorsByService(
      countryIso3Code,
      serviceId
    );

    if (responseData.status === "FAILED") {
      throw responseData;
    }

    const {
      data: { providerDTO },
    } = responseData;

    dispatch(ActionCreator(Bill.FETCH_OPERATORS_BY_SERVICE_SUCCESS, providerDTO));
  } catch (error) {
    dispatch(ActionCreator(Bill.FETCH_OPERATORS_BY_SERVICE_FAILURE));
  }
};

export const getProductByOperator = (
  countryIso3Code,
  serviceId,
  operatorId,
  accountReferenceNumber
) => async (dispatch) => {
  try {
    dispatch(ActionCreator(Bill.FETCH_PRODUCTS_BY_OPERATOR_REQUEST));
    const { data: responseData } = await BillService.fetchProductsByOperator(
      countryIso3Code,
      serviceId,
      operatorId,
      accountReferenceNumber
    );

    if (responseData.status === "FAILED") {
      throw responseData;
    }

    const {
      data: { productDTO },
    } = responseData;

    dispatch(ActionCreator(Bill.FETCH_PRODUCTS_BY_OPERATOR_SUCCESS, productDTO));
  } catch (error) {
    dispatch(ActionCreator(Bill.FETCH_PRODUCTS_BY_OPERATOR_FAILURE));
  }
};

export const getQuoteForBillPayment = (payload) => async (dispatch) => {
  try {
    dispatch(ActionCreator(Bill.FETCH_QUOTE_FOR_BILL_PAYMENT_REQUEST));
    const { data: responseData } = await BillService.fetchQuoteForBillPayment(payload);
    if (responseData.status === "FAILED") {
      throw responseData;
    }
    const { data } = responseData;

    dispatch(ActionCreator(Bill.FETCH_QUOTE_FOR_BILL_PAYMENT_SUCCESS, data));
  } catch (error) {
    expiredTokenCheck(error, dispatch);

    dispatch(ActionCreator(Bill.FETCH_QUOTE_FOR_BILL_PAYMENT_FAILURE));
    console.error(error);
  }
};

export const initiateBillPayment = (payload) => async (dispatch) => {
  try {
    dispatch(ActionCreator(Bill.INITIATE_BILL_PAYMENT_REQUEST));
    const { data: responseData } = await BillService.initiateBillPayment(payload);
    if (responseData.status === "FAILED") {
      throw responseData;
    }
    const { data } = responseData;

    dispatch(ActionCreator(Bill.INITIATE_BILL_PAYMENT_SUCCESS, data));
  } catch (error) {
    expiredTokenCheck(error, dispatch);

    dispatch(ActionCreator(Bill.INITIATE_BILL_PAYMENT_FAILURE));
    console.error(error);
  }
};

export const fetchBillPaymentMethods = (payload) => async (dispatch) => {
  try {
    dispatch(ActionCreator(Bill.LIST_BILL_PAYMENT_METHODS_REQUEST));
    const { data: responseData } = await BillService.getBillPaymentMethods(payload);
    if (responseData.status === "FAILED") {
      throw responseData;
    }

    dispatch(ActionCreator(Bill.LIST_BILL_PAYMENT_METHODS_SUCCESS, responseData.data));
  } catch (error) {
    expiredTokenCheck(error, dispatch);

    dispatch(ActionCreator(Bill.LIST_BILL_PAYMENT_METHODS_FAILURE));
    console.error(error);
  }
};

import * as Document from 'Store/constants/document.constants';
import * as Loading from 'Store/constants/loadingState.constants';

const initialState = {
  documentList: [],
  documentStatus: '',
  uploadDocumentStatus: '',
  deleteDocumentStatus: '',
  pictureLoading: false,
  imageUrl: '',
};


const documentReducer = (state = initialState, action) => {
  switch (action.type) {
    case Document.ADD_DOCUMENTS_REQUEST:
      return {
        ...state,
        uploadDocumentStatus: Loading.FETCHING,
      };

    case Document.ADD_DOCUMENTS_SUCCESS:
      return {
        ...state,
        uploadDocumentStatus: Loading.SUCCESS,
        documentList: action.payload,
      };

    case Document.ADD_DOCUMENTS_FAILURE:
      return { ...state, 
        uploadDocumentStatus: Loading.ERROR 
      };

    case Document.FETCH_DOCUMENTS_REQUEST:
      return { ...state, documentStatus: Loading.FETCHING, documentList: [] };

    case Document.FETCH_DOCUMENTS_SUCCESS:
      return {
        ...state,
        documentStatus: Loading.SUCCESS,
        documentList: action.payload,
      };

    case Document.FETCH_DOCUMENTS_FAILURE:
      return { ...state, documentStatus: Loading.ERROR, documentList: [] };

    case Document.DELETE_DOCUMENT_REQUEST:
      return {
        ...state,
        deleteDocumentStatus: Loading.FETCHING,
      };

    case Document.DELETE_DOCUMENT_SUCCESS:

      const documentList = [...state.documentList];
      const documentInfo = action.payload;
      const newDocumentList = documentList.filter(item => item.id !== documentInfo);

      return {
        ...state,
        documentList: newDocumentList,
        deleteDocumentStatus: Loading.SUCCESS,

      };

    case Document.DELETE_DOCUMENT_FAILURE:
      return { ...state, 
        deleteDocumentStatus: Loading.ERROR 
      };

    case Document.UPDATE_PROFILE_PICTURE_REQUEST:

      return {
        ...state,
        pictureLoading: action.payload.loading,
      }
    case Document.UPDATE_PROFILE_PICTURE_SUCCESS:
      
      return {
        ...state,
        pictureLoading: action.payload.loading,
        imageUrl: action.payload.data.data.userProfileUrl,
    }

    case Document.UPDATE_PROFILE_PICTURE_FAILURE:
      return {
        ...state,
        pictureLoading: action.payload.loading,
    }

    default:
      return state;
  }
};

export default documentReducer;


// UPDATE_PROFILE_PICTURE_REQUEST
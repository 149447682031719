export const FETCH_COUNTRY_LIST_REQUEST = 'FETCH_COUNTRY_LIST_REQUEST';
export const FETCH_COUNTRY_LIST_SUCCESS = 'FETCH_COUNTRY_LIST_SUCCESS';
export const FETCH_COUNTRY_LIST_FAILURE = 'FETCH_COUNTRY_LIST_FAILURE';

export const FETCH_MERCHANT_CATEGORY_BY_COUNTRY_REQUEST = 'FETCH_MERCHANT_CATEGORY_BY_COUNTRY_REQUEST';
export const FETCH_MERCHANT_CATEGORY_BY_COUNTRY_SUCCESS = 'FETCH_MERCHANT_CATEGORY_BY_COUNTRY_SUCCESS';
export const FETCH_MERCHANT_CATEGORY_BY_COUNTRY_FAILURE = 'FETCH_MERCHANT_CATEGORY_BY_COUNTRY_FAILURE';

export const FETCH_PRODUCTS_REQUEST = 'FETCH_PRODUCTS_REQUEST';
export const FETCH_PRODUCTS_SUCCESS = 'FETCH_PRODUCTS_SUCCESS';
export const FETCH_PRODUCTS_FAILURE = 'FETCH_PRODUCTS_FAILURE';

export const INITIATE_MERCHANT_PAYMENT_REQUEST = 'INITIATE_MERCHANT_PAYMENT_REQUEST';
export const INITIATE_MERCHANT_PAYMENT_SUCCESS = 'INITIATE_MERCHANT_PAYMENT_SUCCESS';
export const INITIATE_MERCHANT_PAYMENT_FAILURE = 'INITIATE_MERCHANT_PAYMENT_FAILURE';

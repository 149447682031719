import CryptoJS from 'crypto-js';
import config from './config';

const encryptHelper = {

    encrypt: (data) => {
        if (!data) {
            return null;
        } else {
            return CryptoJS.AES.encrypt(JSON.stringify(data), config.KEY);
        }
    },

    decrypt: (data) => {
        if (!data) {
            return null;
        } else {
            const bytes = CryptoJS.AES.decrypt(data.toString(), config.KEY);
            return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

            // const bytes = CryptoJS.AES.decrypt(data.toString(), config.KEY);

        }
    },

    jwtDecode: (data) => {
        if (!data) {
            return null;
        } else if (typeof (data) === 'object') {
            return data;
        } else {
            const base64Url = data.split('.')[1];
            const base64 = base64Url.replace('-', '+').replace('_', '/');
            return JSON.parse(window.atob(base64));
        }

    }

    // Add more for the decryption service
};

export default encryptHelper;

import styled from '@emotion/styled';

const shared = ({ primaryColor }) => `
  height: 40px;
  width: 45px;
  margin-right: 6px;
  background: #E3EDF3;
  border: 0;
  border-radius: 2px;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${primaryColor};
  cursor: pointer;

  &:disabled {
    opacity: 0.4;
  }

  svg {
    width: 11px;
    height: 18px;

    .a {
      fill: ${primaryColor};
    }
  }
`;

const activeClass = ({ primaryColor }) => `
  background: ${primaryColor};
  color: white;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 20px 0;
`;

export const LeftArrowButton = styled.button`
  ${(props) => shared(props.theme)};
`;

export const RightArrowButton = styled.button`
  ${(props) => shared(props.theme)};
  margin-right: 2px;
`;

export const NumKeyButton = styled.button`
  ${(props) => shared(props.theme)};
  ${(props) => (props.active ? activeClass(props.theme) : null)};
`;

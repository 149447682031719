import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import {
  ResponsiveContainer,
  ComposedChart,
  Area,
  XAxis,
  YAxis,
  Label,
  Legend,
  CartesianGrid,
  Tooltip
} from "recharts";

import Footer from "Components/Footer";
import NavBar from "Components/Navbar";
import TableButton from "Components/TableRowButton";
import DropDownComponent from "Components/DropDownComponent";
import Pagination from "Components/Pagination";
import usePagination from "Components/Pagination/usePagination";

import Styles from "./index.module.scss";
import customStyles from "Assets/styles/dropdown/customStyles";

import fundsIcon from "Assets/svg/funds.svg";
import rightArrowIcon from "Assets/svg/right-arrow.svg";
import transferIcon from "Assets/svg/transfer.svg";
import financialIcon from "Assets/svg/financial.svg";
import downArrow from "Assets/svg/blue-down-arrow.svg";
import downloadIcon from "Assets/svg/download.svg";
import {
  getAccountBalances,
  getAccountBalancesByAccountCurrency,
  getMonthlyWalletBalances
} from "Store/actions/account.action";
import { fetchCurrencies } from "Store/actions/bankAccount.action";
import navigateMethod from "Utils/Helpers/navigateMethod";

import { filterData, getYear } from "Utils/Helpers/general";
import pdfGenerator from "Utils/Helpers/pdfGenerator";
import { BrandedRegularButton } from "../../../Components/BrandingComponents";
import Loader from "../../../Components/loader/Loader";
import * as Loading from "../../../Store/constants/loadingState.constants";

const formatNum = (num) =>
  num ? Number(num).toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }) : "0.00";

const TableRow = ({ languageStatus, dictionary, tableData, selectedCurrency }) => {
  const history = useHistory();
  const { accountName, walletAccountBalance, walletAccountCurrency, walletAccountNumber } = tableData;
  const viewAccountStatement = () => history.push({
    pathname: "/account/statement",
    state: { tableData, selectedCurrency }
  });

  return (
    <tr>
      <td>
        <span>{accountName || "-"}</span>
      </td>
      <td>
        <span>{walletAccountNumber}</span>
      </td>
      <td>
        <span>{`${walletAccountCurrency}${formatNum(walletAccountBalance)}`}</span>
      </td>
      <td>
        <span>
          <TableButton
            text={languageStatus ? dictionary["WO_VIEW_ACCOUNT_STATEME_-626069816"] : "View account statement"}
            onClick={viewAccountStatement} />
        </span>
      </td>
    </tr>
  );
};

const generateDownloadData = (data) => {
  return data.map(
    ({ accountName, walletAccountNumber, walletAccountCurrency, walletAccountBalance }) => [
      accountName,
      walletAccountNumber,
      `${walletAccountCurrency}${formatNum(walletAccountBalance)}`
    ]
  );
};

const TickText = ({ x, y, payload, color, dy }) => {
  return (
    <g transform={`translate(${x}, ${y})`}>
      <text fill={color} x={0} y={0} dy={dy} textAnchor="end" fontSize={13}>
        {payload.value}
      </text>
    </g>
  );
};

const Chart = ({ languageStatus, dictionary }) => {
  const monthlyData = useSelector(({ accountReducer }) => accountReducer["monthlyAccountBalance"]);
  return (
    <ResponsiveContainer width="100%" height="100%" style={{ zIndex: 5 }}>
      <ComposedChart data={monthlyData}>
        <XAxis dataKey="month" tickLine={false} tick={<TickText color="#90A8BE" dy={20} />} />
        <YAxis
          tickLine={false}
          tick={<TickText color="#0055AD" dy={5} left={0} />}
          label={{
            value: [languageStatus ? dictionary["WO_ACCOUNT_BALANC_-1400513605"] : "Account Balance"],
            angle: -90,
            position: "insideLeft"
          }}
        />
        <Tooltip />
        <Legend verticalAlign="top" height={36} />
        <CartesianGrid stroke="#1149831C" />

        <Area
          type="monotone"
          name={languageStatus ? dictionary["WO_ACCOUNT_BALANC_-1400513605"] : "Account Balance"}
          dataKey="walletBalance"
          stroke="#0055AD"
          fillOpacity={1}
          fill="url(#colorUv)"
        />
        <defs>
          <linearGradient id="colorUv" x1="1" y1="0" x2="1" y2="1">
            <stop offset="5%" stopColor="#0055AD" stopOpacity={0.7} />
            <stop offset="95%" stopColor="#3796F680" stopOpacity={0.3} />
          </linearGradient>
        </defs>
      </ComposedChart>
    </ResponsiveContainer>
  );
};

const AccountBalance = () => {
  let accountDropdown = [{ value: "ALL", label: "ALL ACCOUNTS", walletAccountNumber: null }];
  const [accountSelector, currencies, domainBrand] = useSelector(
    ({ accountReducer, bankAccountReducer, domainBrandReducer }) => [
      accountReducer,
      bankAccountReducer["walletCurrenciesDropdown"],
      domainBrandReducer
    ]
  );
  let startYearQuery = getYear(), yearData = [];
  for (let i = 1990; i <= startYearQuery; i++) {
    yearData[i] = {};
    yearData[i].label = i.toString(10);
    yearData[i].value = i.toString(10);
  }

  const theme = useSelector(({ themeReducer }) => themeReducer);
  const dispatch = useDispatch();
  const history = useHistory();
  const [filterText, setFilterText] = useState("");
  const [collection, setCollection] = useState([]);
  const languageDictionary = domainBrand.domainBrand["languageDictionary"];
  const languageDictionaryStatus = !(typeof languageDictionary === "undefined" || languageDictionary === null);
  const accountBalanceLoading = accountSelector["balanceLoadingStatus"] === "FETCHING";
  const [selectedAccount, setSelectedAccount] = useState(accountDropdown[0]);
  const [selectedCurrency, setSelectedCurrency] = useState({});
  const stillLoading = accountSelector.loadingStatus === Loading.FETCHING;
  const [selectedYear, setSelectedYear] = useState(yearData[yearData.length - 1]);

  useEffect(() => {
    dispatch(fetchCurrencies());
    dispatch(getAccountBalances());
  }, [dispatch]);

  useEffect(() => {
    if (Object.entries(selectedCurrency).length !== 0) {
      dispatch(getAccountBalancesByAccountCurrency(selectedCurrency["value"]));
    }
  }, [selectedCurrency]);

  useEffect(() => {
    let accountQuery = "";
    if (selectedAccount.walletAccountNumber !== null) {
      accountQuery = `&accountNumber=${selectedAccount["walletAccountNumber"]}`;
    }
    if (Object.entries(selectedCurrency).length !== 0) {
      dispatch(
        getMonthlyWalletBalances(selectedCurrency["value"], selectedYear["value"], accountQuery)
      );
    }
  }, [selectedCurrency, selectedYear, selectedAccount]);

  useEffect(() => {
    const data = accountSelector.list;
    if (data.length > 0) {
      const filteredData = filterData(data, filterText, ["accountName"]);
      setCollection(filteredData);
    }
  }, [filterText, accountSelector.list]);

  const PAGE_LIMIT = 5;
  const [currentData, onPageChange] = usePagination({
    collection,
    pageLimit: PAGE_LIMIT
  });

  if (Object.entries(selectedCurrency).length === 0 && currencies.length > 0) {
    setSelectedCurrency(currencies[0]);
  }

  if (accountSelector.accountBalanceDropDownList.length > 0) {
    accountDropdown = [...accountDropdown, ...accountSelector.accountBalanceDropDownList];
  }

  const redirectToAccountStatement = ({ walletAccountCurrency, walletAccountBalance }) => {
    const navigator = navigateMethod(history, "/account/statement");
    dispatch(getAccountBalances(walletAccountBalance), navigator);
    dispatch(fetchCurrencies(walletAccountCurrency), navigator);
  };

  return (
    <>
      <Helmet>
        <title>
          {languageDictionaryStatus ? languageDictionary["WO_ACCOUNT_BALANCE_960871353"] : "Account Balance"}
        </title>
      </Helmet>
      <div>
        <NavBar />
        <main className={Styles.mainContainer}>
          {stillLoading ? (
            <>
              <Loader />
            </>
          ) : (
            <>
              <div className={Styles.canvasImg}>
                <img src={fundsIcon} alt="" />
              </div>
              <section className={Styles.topSection}>
                <div className={Styles.topSection__leftSide}>
                  <div className={Styles.topSection__leftSide__heading}>
                    <p>
                      {languageDictionaryStatus
                        ? languageDictionary["WO_ACCOUNT_BALANCE_960871353"]
                        : "Account Balance"}
                    </p>
                  </div>
                  <div className={Styles.topSection__leftSide__subHeading}>
                    <span>
                      {languageDictionaryStatus
                        ? languageDictionary["WO_SELECT_CURRENC_1917455719"]
                        : "Select Currency"}
                    </span>
                  </div>

                  <div className={Styles.topSection__leftSide__dropDown}>
                    <Select
                      noOptionsMessage={() => "No wallet currency added"}
                      options={currencies}
                      styles={customStyles}
                      isClearable={false}
                      isSearchable={true}
                      customTheme={theme}
                      value={selectedCurrency}
                      onChange={(option) => {
                        setSelectedCurrency(option);
                      }}
                      components={{
                        IndicatorSeparator: null,
                        DropdownIndicator: () => <DropDownComponent iconSource={downArrow} />
                      }}
                    />
                  </div>
                  <div className={Styles.topSection__leftSide__subHeading}>
                    <span>
                      {languageDictionaryStatus
                        ? languageDictionary["WO_SELECT_ACCOUN_416302202"]
                        : "Select Account"}
                    </span>
                  </div>

                  <div className={Styles.topSection__leftSide__dropDown}>
                    <Select
                      noOptionsMessage={() => "No wallet account created yet"}
                      options={accountDropdown}
                      styles={customStyles}
                      isClearable={false}
                      isSearchable={true}
                      customTheme={theme}
                      value={selectedAccount}
                      onChange={(option) => setSelectedAccount(option)}
                      isLoading={accountBalanceLoading}
                      components={{
                        IndicatorSeparator: null,
                        DropdownIndicator: () => <DropDownComponent iconSource={downArrow} />
                      }}
                    />
                  </div>

                  <div className={Styles.topSection__leftSide__subHeading}>
                    <span>
                      {languageDictionaryStatus
                        ? languageDictionary["WO_SELECT_YEA_15189667"]
                        : "Select Year"}
                    </span>
                  </div>

                  <div className={Styles.topSection__leftSide__dropDown}>
                    <Select
                      options={yearData}
                      styles={customStyles}
                      isClearable={false}
                      isSearchable={true}
                      customTheme={theme}
                      value={selectedYear}
                      onChange={(option) => setSelectedYear(option)}
                      isLoading={accountBalanceLoading}
                      components={{
                        IndicatorSeparator: null,
                        DropdownIndicator: () => <DropDownComponent iconSource={downArrow} />
                      }}
                    />
                  </div>

                  {selectedAccount.value === "ALL" ? (
                    ""
                  ) : (
                    <div className={Styles.currentBalanceContainer}>
                      <p>Current Balance</p>
                      <p>{Object.keys(selectedAccount).length < 1 ? 0 : `${selectedAccount["walletAccountCurrency"]} ${selectedAccount["walletAccountBalance"]}`}</p>
                    </div>
                  )}

                  <div className={Styles.buttonWrapper}>
                    <BrandedRegularButton onClick={() => history.push("/transfer")}>
                      <span>
                        <img src={transferIcon} alt="transfer icon" />
                      </span>
                      <span>
                        {languageDictionaryStatus
                          ? languageDictionary["WO_TRANSFER_FUND_-1694451190"]
                          : "Transfer Funds"}
                      </span>
                      <span>
                        <img src={rightArrowIcon} alt="right arrow" />
                      </span>
                    </BrandedRegularButton>
                    <BrandedRegularButton onClick={() => history.push("/account/statement")}>
                      <span>
                        <img src={financialIcon} alt="statement icon" />
                      </span>
                      <span>
                        {languageDictionaryStatus
                          ? languageDictionary["l_wallet_transaction_history"]
                          : "Account Statement"}
                      </span>
                      <span>
                        <img src={rightArrowIcon} alt="" />
                      </span>
                    </BrandedRegularButton>
                  </div>
                </div>
                <div className={Styles.topSection__chartSide}>
                  <div className={Styles.topSection__chartSide__topContainer}>
                    <div className={Styles.chartSection}>
                      <Chart languageStatus={languageDictionaryStatus} dictionary={languageDictionary} />
                    </div>
                  </div>
                </div>
              </section>
              <section className={Styles.bottomSection}>
                <h3 className={Styles.mainHeading}>
                  {languageDictionaryStatus
                    ? languageDictionary["WO_ACCOUNT_1211395312"]
                    : "Accounts"}
                </h3>
                <div className={Styles.filterSection}>
                  <div className={Styles.filterSection__filterBar}>
                    <label htmlFor="filterText">
                      <input
                        type="text"
                        name="filterText"
                        id="filterText"
                        placeholder="search or filter by account name"
                        value={filterText}
                        onChange={(e) => setFilterText(e.target.value)}
                      />
                    </label>
                  </div>
                  <button
                    className={Styles.filterSection__download}
                    onClick={() =>
                      pdfGenerator({
                        heading: "Account Balance",
                        tableHeaders: ["Account Name", "Account Number", "Balance"],
                        data: generateDownloadData(accountSelector.list)
                      })
                    }>
                    <img src={downloadIcon} alt="download icon" />
                  </button>
                </div>

                <div className={Styles.activityTable}>
                  <table>
                    <thead>
                    <tr>
                      <th scope="col">
                        {languageDictionaryStatus
                          ? languageDictionary["WO_ACCOUNT_NAM_-935856925"]
                          : "Account Name"}
                      </th>
                      <th scope="col">
                        {languageDictionaryStatus
                          ? languageDictionary["para_account_number"]
                          : "Account Number"}
                      </th>
                      <th scope="col">
                        {languageDictionaryStatus
                          ? languageDictionary["para_balance"]
                          : "Balance"}
                      </th>
                      <th
                        scope="col">{languageDictionaryStatus ? languageDictionary["WO_VIEW_ACCOUNT_STATEME_-626069816"] : "Action"}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {currentData < 1 ? (
                      <>
                        <tr>
                          <td colSpan="6" className="text-center bg-grey">
                            <span>No account available</span>
                          </td>
                        </tr>
                      </>
                    ) : (
                      <>
                        {currentData.map((iData, index) => (
                          <TableRow
                            languageStatus={languageDictionaryStatus}
                            dictionary={languageDictionary}
                            tableData={iData}
                            key={index}
                            redirectClick={redirectToAccountStatement}
                            selectedCurrency={selectedCurrency}
                          />
                        ))}
                      </>
                    )}
                    </tbody>
                  </table>
                </div>
                <Pagination
                  resultName="Total Number of Accounts"
                  onPageChange={onPageChange}
                  pageLimit={PAGE_LIMIT}
                  totalRecords={collection.length}
                />
              </section>
            </>
          )}
        </main>
        <Footer />
      </div>
    </>
  );
};

export default AccountBalance;

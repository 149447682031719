import React from "react";
import { Helmet } from "react-helmet";
import { useHistory, Link } from "react-router-dom";
import Footer from "Components/Footer";
import NavBar from "Components/Navbar";
import userIcon from "Assets/svg/user-icon.svg";
import { Card, NextButton } from "./style";

const MakePaymentDetails = () => {
  const history = useHistory();

  return (
    <>
      <Helmet>
        <title>Scan and Pay &mdash; Multiview MTA</title>
      </Helmet>
      <NavBar />
      <section>
        <div className="container">
          <div className="mt-5 mb-5">
            <div className="d-flex justify-content-between align-items-center">
              <h2 className="text-blue ">Make Payment</h2>
            </div>
            <div className="card mt-5 p-md-5">
              <div className="card-body mb-3 p-md-3">
                {/* --- Search box for product --- */}
                <div className="row mb-5">
                  <div className="col-12 col-xl-6">
                    {/* ---- Enter reference number */}
                    <Card>
                      <div className="mb-5 mt-3 mt-md-0 text-center border-bottom-blue">
                        <img src={userIcon} alt="user" className="mb-4" />
                        <h4 className="text-blue font-weight-bold mb-4">
                          Catherine Ocharive
                        </h4>
                      </div>

                      {/* ---- OTP */}
                      <div>
                        <div className="row mb-3">
                          <div className="col text-note">PCN</div>
                          <div className="col-auto text-blue font-weight-bold">
                          CATH677262525
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col text-note">REASON</div>
                          <div className="col-auto text-blue font-weight-bold">
                          Product Purchase
                          </div>
                        </div>
                      </div>
                    </Card>
                  </div>
                </div>

                {/* ----- Proceed Button ----- */}
                <div className="row">
                  <div className="col-6 col-md-3 col-xl-2 ">
                    <Link
                      to="/order-confirmation"
                      onClick={() => history.push()}
                      role="button"
                      tabIndex="-1"
                      onKeyPress={() => {}}
                    >
                      <NextButton className="btn btn-lg btn-blue btn-block text-uppercase text-white">
                        pay
                      </NextButton>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default MakePaymentDetails;

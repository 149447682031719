import pdfmake from "pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

const pdfGeneratorStatement = async (data, statement, domainBrand) => {
  pdfmake.vfs = pdfFonts["pdfMake"].vfs;
  const dictionary = domainBrand.domainBrand["languageDictionary"];
  const languageStatus = !(typeof dictionary === "undefined" || dictionary === null);

  const {profile, selectedAccount, fromDate, toDate} = data;
  const {firstName, lastName, address: {address1, address2, city, countryCommonName}} = profile["userData"];
  const {walletAccountNumber, walletAccountCurrency, walletAccountBalance} = selectedAccount;

  const headerWidth = "*";
  const tableHeaders = [
    "Date", "PCN", "Payment Reference",
    {text: "Amount", alignment: "right"},
    {text: "Balance", alignment: "right"}
  ];
  const headers = tableHeaders.map((item) => ({text: item, style: "tableHeader"}));
  const tableColumns = tableHeaders.map(() => headerWidth);

  const docDefinition = {
    pageOrientation: "portrait",

    content: [
      {
        columns: []
      },
      {
        text: languageStatus ? dictionary["l_wallet_transaction_history"] : "Account Statements",
        style: "header"
      },
      {
        columns: [
          {
            columns: [
              {
                text: "Name \u200B \n Address \u200B \n Account Number \u200B \n Currency \u200B \n Balance",
                width: 100,
                style: "infoNude"
              },
              {
                text: `${firstName ?? ""} ${lastName ?? ""} \n ${address1} ${address2 ?? ""}, ${city ?? ""}, ${countryCommonName ?? ""} \n ${walletAccountNumber} \n ${walletAccountCurrency} \n ${walletAccountBalance}`,
                width: "auto",
                style: "info"
              }
            ]
          }
        ]
      },
      {
        text: `Please find below account statement from ${fromDate} to ${toDate}`,
        style: "subInfo"
      },
      {
        text: "Statement Information",
        style: "subheader"
      },
      {canvas: [{type: "line", x1: 0, y1: 5, x2: 595 - 2 * 40, y2: 5, lineWidth: 0.2}], style: "pretext"},
      {
        table: {
          headerRows: 1,
          widths: [60, 120, 185, 60, 60],
          tableColumns,
          body: [headers, ...statement],
        },
        layout: 'noBorders',
        style: "tableBody"
      }
    ],

    styles: {
      header: {
        fontSize: 18,
        bold: true,
        margin: [0, 20],
        alignment: "left"
      },
      subheader: {
        fontSize: 12,
        bold: true,
        margin: [0, 30, 0, 10],
        alignment: "left"
      },
      pretext: {
        margin: [0, 0, 0, 20],
        alignment: "left"
      },
      infoNude: {
        color: "#696969",
        fontSize: 10,
        margin: [0, 10, 0, 0],
        bold: true,
        alignment: "left"
      },
      info: {
        color: "black",
        fontSize: 10,
        margin: [0, 10, 0, 0],
        alignment: "left"
      },
      subInfo: {
        color: "black",
        fontSize: 8,
        margin: [0, 10, 0, 0],
        alignment: "left"
      },
      tableHeader: {
        bold: true,
        color: "black",
        fontSize: 10,
        alignment: "left"
      },
      tableBody: {
        fontSize: 10,
        alignment: "left"
      },
    }
  };

  pdfmake.createPdf(docDefinition).open();
};

export default pdfGeneratorStatement;

import styled from "@emotion/styled";

export const AmountCard = styled.div`
  background-color: #0055ad;
  border-radius: 0.625rem;
`;

export const ListItem = styled.li`
    background-color: #0055AD !important;
    position: relative;
    display: block;
    padding: 1.5rem 0.5rem;
    border-top: 1px solid #e5e5e5;
`;

export const NextButton = styled.button`
  font-size: 1rem;
`;

export const CartCircle = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #0055AD;
`;
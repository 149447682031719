export const customStyles = {
  option: (provided, state) => {
    const {
      selectProps: {
        customTheme: { primaryColor },
      },
    } = state;
    return {
      ...provided,
      borderBottom: `${
        state.value === state.options[state.options.length - 1].value
          ? null
          : '1px solid #90A8BE47'
      }`,
      color: state.isSelected ? '#fff' : primaryColor,
      padding: '15px 20px',
      fontSize: '16px',
    };
  },
  control: (provided, state) => {
    const {
      selectProps: {
        customTheme: { primaryColor },
      },
    } = state;

    return {
      ...provided,
      background: primaryColor,
      color: 'red',
      border: 0,
    };
  },
  loadingIndicator: (provided) => {
    return {
      ...provided,
      color: 'white',
      fontSize: 6,
    };
  },
  placeholder: (provided) => ({
    ...provided,
    color: 'white',
    fontSize: '17px',
  }),
  container: (provided) => ({
    ...provided,
    marginTop: 12,
    // padding: 20
  }),
  menu: (provided) => {
    return {
      ...provided,
      margin: 0,
      background: '#F5F9FB',
      borderRadius: 0,
      padding: 0,
    };
  },
  menuList: (provided) => {
    return {
      ...provided,
      padding: 0,
    };
  },
  dropdownIndicator: (provided) => {
    return {
      ...provided,
    };
  },
  indicatorsContainer: (provided) => {
    return {
      ...provided,
      paddingLeft: 8,
      paddingRight: 20,
    };
  },
  input: (provided) => {
    return {
      ...provided,
      padding: 0,
      margin: 0,
      color: '#fff',
    };
  },
  singleValue: (provided, state) => {
    return { ...provided, color: '#fff', fontSize: '16px' };
  },
  valueContainer: (provided) => {
    return {
      ...provided,
      padding: '15px 20px',
    };
  },
};

// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect } from "react";
/** @jsx jsx */
/** @jsxRuntime classic */

import { jsx } from "@emotion/react";
import { useFormik } from "formik";
import * as Yup from "yup";
import AriaModal from "react-aria-modal";
import DatePicker from "react-datepicker";
import { useSelector, useDispatch } from "react-redux";
import CustomButton from "Components/CustomButton";
import {
  uploadLabel,
  uploadContainer,
  uploadContainerHeader,
  attachButtonContainer,
  modalContainer,
  modalFormContainer,
  modalFormRow,
  modalContainerHeading,
  fileUploadInput,
  fileUploadLabel,
  InputTitle,
  modalFormDatePicker,
  tableStyle
} from "./styles";
import FormLabel from "Components/FormLabel";
import CustomInput from "Components/CustomInput";
import FormSelect from "Components/FormSelect";
import ErrorField from "Components/ErrorField";
import { documentType } from "Utils/constants";
import * as Loading from "Store/constants/loadingState.constants";
import { getAllCountries } from "Store/actions/country.action";
import { fetchDocuments, uploadDocuments, deleteUserDocument } from "Store/actions/document.action";
import { formatDateShort, fileToBase64, daysFromToday } from "Utils/Helpers/general";
import { toast } from "react-toastify";
import searchIcon from "Assets/svg/search.svg";
import downloadIcon from "Assets/svg/download.svg";

const UploadDocuments = ({ languageDictionary }) => {
  const dispatch = useDispatch();
  const [showModal, updateShowModal] = useState(false);
  const {
    countryDropdown,
    countryLoading,
    userData,
    userStatus,
    documentList,
    documentStatus,
    uploadDocumentStatus,
    domainBrand
  } = useSelector(({ countryReducer, profileReducer, documentReducer, domainBrandReducer }) => ({
    countryDropdown: countryReducer.countryDropdownList,
    countryLoading: countryReducer.loading,
    userData: profileReducer.userData,
    userStatus: profileReducer.userProfileLoading,
    documentList: documentReducer.documentList,
    documentStatus: documentReducer.documentStatus,
    uploadDocumentStatus: documentReducer.uploadDocumentStatus,
    domainBrand: domainBrandReducer
  }));

  const validateFile = (values) => {
    const allowedExtensions = ["jpg", "jpeg", "png", "pdf", "doc", "docx", "xls", "xlsx"], sizeLimit = 2097152;
    const { name: fileName, size: fileSize } = values;
    const fileExtension = fileName.split(".").pop();
    const formatFileExtension = fileExtension.toLowerCase();

    if (!allowedExtensions.includes(formatFileExtension)) {
      toast.error("Please upload only jpg, jpeg, png and pdf files", {
        position: "top-center",
        autoClose: false
      });
      values = null;
      return false;
    } else if (fileSize > sizeLimit) {
      toast.error("File size too large", { position: "top-center" });
      values = null;
      return false;
    }
    return true;
  };

  const formik = useFormik({
    initialValues: {
      documentType: "",
      issueDate: daysFromToday(),
      expiryDate: new Date(),
      idNumber: "",
      issueCountry: "",
      dateOfBirth: daysFromToday(),
      file_data: ""
    },
    validationSchema: Yup.object({
      idNumber: Yup.string().required("required"),
      issueCountry: Yup.object({
        value: Yup.string().required("required")
      }),
      documentType: Yup.object({
        value: Yup.string().required("required")
      }),
      expiryDate: Yup.string().required("required"),
      issueDate: Yup.string().required("required"),
      dateOfBirth: Yup.string().required("required")
    }),
    validate: (values) => {
      const errors = {};
      if (!(values.file_data instanceof File)) errors.file_data = "file is required";
      return errors;
    },
    onSubmit: async (values) => {
      try {
        const fileDataBase64 = await fileToBase64(values.file_data);
        const payload = {
          customerCode: userData.customerCode,
          attachedDocument: [
            {
              file_base64: fileDataBase64,
              name: values.documentType.value,
              idType: values.documentType.value,
              dob: values.dateOfBirth,
              issueCountry: values.issueCountry.value,
              issueNo: values.idNumber,
              issueDate: values.issueDate.toISOString(),
              expiryDate: values.expiryDate
            }
          ]
        };
        dispatch(uploadDocuments(payload));
        setTimeout(() => {
          updateShowModal(false);
        }, 2000);
      } catch (error) {
        toast.error("error uploading file", { position: "top-center" });
      }
    }
  });

  const getApplicationNode = () => {
    return document.getElementById("root");
  };

  useEffect(() => {
    dispatch(getAllCountries());
  }, [dispatch]);

  useEffect(() => {
    if (userStatus === Loading.SUCCESS) dispatch(fetchDocuments(userData.customerCode));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userStatus]);

  const languageDictionaryStatus = !(typeof languageDictionary === "undefined" || languageDictionary === null);
  const docUploadLoading = uploadDocumentStatus === Loading.FETCHING;
  const deleteDoc = (id) => {
    const payload = { customerCode: userData.customerCode, docId: id };
    dispatch(deleteUserDocument(payload));
  };

  const download = (download_url) => {
    const element = document.createElement("a");
    const file = new Blob([download_url], { type: "image/*" });
    element.href = URL.createObjectURL(file);
    element.download = "image.jpg";
    element.click();
  };

  return (
    <div css={uploadContainer}>
      <div css={uploadContainerHeader}>
        <p css={uploadLabel}>
          {languageDictionaryStatus ? languageDictionary["para_customer_documents"] : "Customer Documents"}
        </p>
      </div>

      <div css={tableStyle}>
        <table>
          <thead>
          <tr>
            <th scope="col">
              {languageDictionaryStatus ? languageDictionary["para_name"] : "Name"}
            </th>
            <th scope="col">
              {languageDictionaryStatus ? languageDictionary["l_issue_date"] : "Issue Date"}
            </th>
            <th scope="col">
              {languageDictionaryStatus ? languageDictionary["l_expiry_date"] : "Expiry Date"}
            </th>
            <th scope="col" />
          </tr>
          </thead>
          <tbody>
          {documentList < 1 ? (
            <>
              <tr>
                <td colSpan="4" className="text-center bg-grey">
                    <span>
                      {languageDictionaryStatus ? languageDictionary["WO_NO_INVOICE_HAVE_BEEN_1725209251"] : "No invoice have been attached"}
                    </span>
                </td>
              </tr>
            </>
          ) : (
            <>
              {documentList.map((doc) => {
                const { type, expiryDate, issueDate, download_url, id } = doc;
                return (
                  <tr key={doc.id}>
                    <td>
                      <span>{type}</span>
                    </td>
                    <td>
                      <span>{formatDateShort(issueDate)}</span>
                    </td>
                    <td>
                      <span>{formatDateShort(expiryDate)}</span>
                    </td>
                    <td>
                        <span className="d-flex">
                          <a href={download_url} target="_blank" rel="noopener noreferrer">
                            <div className="btn btn-blue">
                              <img src={searchIcon} alt="Preview" />
                            </div>
                          </a>

                          <a
                            href={download_url}
                            download
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={() => download(download_url)}>
                            <div className="btn">
                              <img src={downloadIcon} alt="download" />
                            </div>
                          </a>
                        </span>
                    </td>
                    <td>
                        <span style={{ cursor: "pointer" }}>
                          <a href="#action" onClick={() => deleteDoc(id)}>
                            <svg viewBox="0 0 20 20" fill="#dc3545" width="26px">
                              <path
                                fillRule="evenodd"
                                d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </a>
                        </span>
                    </td>
                  </tr>
                );
              })}
            </>
          )}
          </tbody>
        </table>
      </div>

      <div>
        <p></p>
      </div>
      <div css={attachButtonContainer}>
        <CustomButton
          buttonText={languageDictionaryStatus ? languageDictionary["button_attach_document"] : "Attach Document"}
          buttonWidth="120px"
          marginTop="0"
          onClick={() => updateShowModal(true)}
          type="button"
        />
      </div>
      {showModal ? (
        <AriaModal
          titleText={languageDictionaryStatus ? languageDictionary["para_attach_customer_documents"] : "Attach Customer Documents"}
          onExit={() => updateShowModal(false)}
          getApplicationNode={getApplicationNode}
          verticallyCenter={true}
          underlayStyle={{
            backdropFilter: "blur(28px)",
            background: "none"
          }}>
          <div css={modalContainer}>
            <h2 css={modalContainerHeading}>
              {languageDictionaryStatus ? languageDictionary["para_attach_customer_documents"] : "Attach Customer Documents"}
            </h2>
            <form onSubmit={formik.handleSubmit}>
              <div>
                <FormSelect
                  formik={formik}
                  options={documentType}
                  selectLabel={languageDictionaryStatus ? languageDictionary["WO_DOCUMENT_974417700"] : "Documents"}
                  selectPlaceholder="Select document type"
                  selectName="documentType"
                />
              </div>

              <div css={modalFormContainer}>
                <div className="row mt-md-3">
                  <div className="col-md-6">
                    <InputTitle className="mb-xl-3">
                      {languageDictionaryStatus ? languageDictionary["l_issue_country"] : "Issue Country"}
                    </InputTitle>
                    <FormSelect
                      formik={formik}
                      options={countryDropdown}
                      selectPlaceholder="Select issue country"
                      selectName="issueCountry"
                      isSearchable
                    />
                  </div>

                  <div className="col-md-6">
                    <CustomInput
                      formik={formik}
                      inputKey="idNumber"
                      labelName={languageDictionaryStatus ? languageDictionary["WO_ID_NUMBE_1281572260"] : "ID Number"}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <FormLabel
                    text={languageDictionaryStatus ? languageDictionary["l_issue_date"] : "Issue Date"}>
                    <ErrorField formik={formik} fieldName="issueDate" />
                    <DatePicker
                      selected={formik.values.issueDate}
                      onChange={(date) => formik.setFieldValue("issueDate", date)}
                      onBlur={() => formik.setFieldTouched("issueDate", true)}
                      startDate={formik.values.issueDate}
                      endDate={formik.values.expiryDate}
                      maxDate={formik.values.expiryDate}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      selectsStart
                      css={modalFormDatePicker}
                    />
                  </FormLabel>
                </div>
                <div className="col-md-6">
                  <FormLabel text={languageDictionaryStatus ? languageDictionary["l_expiry_date"] : "Expiry Date"}>
                    <ErrorField formik={formik} fieldName="expiryDate" />
                    <DatePicker
                      selected={formik.values.expiryDate}
                      onChange={(date) => formik.setFieldValue("expiryDate", date)}
                      onBlur={() => formik.setFieldTouched("expiryDate", true)}
                      startDate={formik.values.issueDate}
                      endDate={formik.values.expiryDate}
                      minDate={formik.values.issueDate}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      selectsEnd
                      css={modalFormDatePicker}
                    />
                  </FormLabel>
                </div>
              </div>
              <div className="row mt-md-2">
                <div className="col-md-6">
                  <FormLabel text={languageDictionaryStatus ? languageDictionary["l_date_of_birth"] : "Date Of Birth"}>
                    <ErrorField formik={formik} fieldName="dateOfBirth" />
                    <DatePicker
                      selected={formik.values.dateOfBirth}
                      onChange={(date) => formik.setFieldValue("dateOfBirth", date)}
                      onBlur={() => formik.setFieldTouched("dateOfBirth", true)}
                      css={modalFormDatePicker}
                      format="YYYY-MM-DD"
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                    />
                  </FormLabel>
                </div>
              </div>

              <div css={modalFormRow}>
                <label htmlFor="fileUpload" css={fileUploadLabel}>
                  <ErrorField formik={formik} fieldName="file_data" />
                  <input
                    css={fileUploadInput}
                    type="file"
                    id="fileUpload"
                    name="fileUpload"
                    onChange={(e) => {
                      const isValid = validateFile(e.target.files[0]);
                      if (isValid) formik.setFieldValue("file_data", e.target.files[0]);
                    }}
                    onBlur={() => formik.setFieldTouched("file_data", true)}
                  />
                </label>
              </div>

              <div css={modalFormRow}>
                <CustomButton
                  buttonText={languageDictionaryStatus ? languageDictionary["button_submit"] : "Submit"}
                  buttonWidth="100%"
                  marginTop="0"
                  onClick={() => updateShowModal(true)}
                  loading={docUploadLoading}
                />
              </div>
            </form>
          </div>
        </AriaModal>
      ) : null}
    </div>
  );
};

export default UploadDocuments;

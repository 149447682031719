import Axios from "./Axios.service";

const fetchAccountBalance = async () => {
  return Axios({
    method: "GET",
    url: "/web-services/api/v6/services/walletmanagement/walletaccountlist",
    headers: {
      platform: "fincode",
      UUID: 200
    }
  });
};

const fetchAccountBalanceMonthlyStatement = async (accountCurrency, startYear, accountQuery) => {
  return Axios({
    method: "GET",
    url: `/web-services/api/v6/services/walletmanagement/wallet-account-balance/${accountCurrency}?start-year=${startYear}${accountQuery}`,
    headers: {
      platform: "fincode",
      UUID: 200
    }
  });
};

const fetchAccountBalanceByAccountCurrency = async (accountCurrency) => {
  return Axios({
    method: "GET",
    url: `/web-services/api/v6/services/walletmanagement/walletaccountlist?account-currency=${accountCurrency}`,
    headers: {
      platform: "fincode",
      UUID: 200
    }
  });
};

const fetchAccountStatement = data => {
  return Axios({
    method: "POST",
    url: "/web-services/api/v6/services/walletmanagement/walletledger",
    headers: {
      platform: "fincode",
      UUID: 200
    },
    data
  });
};

const fetchYearlyTransaction = async (userCode, startYear) => {
  return Axios({
    method: "GET",
    url: `/web-services/api/v6/services/transactionmanagement/fetch-monthly-transactions/${userCode}?start-year=${startYear}`,
    headers: {
      platform: "fincode",
      UUID: 200
    }
  });

};


export {
  fetchAccountBalance,
  fetchAccountStatement,
  fetchYearlyTransaction,
  fetchAccountBalanceMonthlyStatement,
  fetchAccountBalanceByAccountCurrency
};

import { useState } from 'react';

const usePagination = ({ pageLimit, collection }) => {

  const [currentData, setCurrentData] = useState([]);
  const onPageChange = (data) => {
    const { currentPage } = data;
    const offset = (currentPage - 1) * pageLimit;

    const list = collection.slice(offset, offset + pageLimit);
    setCurrentData(list);
  };
  return [currentData, onPageChange];
};

export default usePagination;
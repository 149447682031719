import { css } from '@emotion/react';

export const formContainer = css`
  border: 1px solid white;
  border-radius: 5px;
  display: flex;
  padding: 60px 40px;
  background: var(--background-color);

  @media (max-width: 56.25rem) {
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem;
  }
`;

export const numberInputField = css`
  border: 0;
  //background: var(--background-color);
  background: #e3edf3;
  border-radius: 5px;
  padding: 11px 20px;
  margin-top: 1.2rem;
  font-size: 16px;
  color: #0055ad;
  font-weight: 300;

  &::placeholder {
    opacity: 1;
  }

  @media (max-width: 56.25rem) {
    width: 100%;
  }
`;

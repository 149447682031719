export const ADD_TO_CART_REQUEST = 'ADD_TO_CART_REQUEST';
export const ADD_TO_CART_SUCCESS = 'ADD_TO_CART_SUCCESS';
export const ADD_TO_CART_FAILURE = 'ADD_TO_CART_FAILURE';

export const GET_ITEMS_IN_CART_REQUEST = 'GET_ITEMS_IN_CART_REQUEST';
export const GET_ITEMS_IN_CART_SUCCESS = 'GET_ITEMS_IN_CART_SUCCESS';
export const GET_ITEMS_IN_CART_FAILURE = 'GET_ITEMS_IN_CART_FAILURE';

export const INCREMENT_QUANTITY_REQUEST = 'INCREMENT_QUANTITY_REQUEST';
export const INCREMENT_QUANTITY_SUCCESS = 'INCREMENT_QUANTITY_SUCCESS';
export const INCREMENT_QUANTITY_FAILURE = 'INCREMENT_QUANTITY_FAILURE';

export const DECREMENT_QUANTITY_REQUEST = 'DECREMENT_QUANTITY_REQUEST';
export const DECREMENT_QUANTITY_SUCCESS = 'DECREMENT_QUANTITY_SUCCESS';
export const DECREMENT_QUANTITY_FAILURE = 'DECREMENT_QUANTITY_FAILURE';

export const REMOVE_FROM_CART_REQUEST = 'REMOVE_FROM_CART_REQUEST';
export const REMOVE_FROM_CART_SUCCESS = 'REMOVE_FROM_CART_SUCCESS';
export const REMOVE_FROM_CART_FAILURE = 'REMOVE_FROM_CART_FAILURE';

export const LIST_PAYMENT_METHODS_REQUEST = 'LIST_PAYMENT_METHODS_REQUEST';
export const LIST_PAYMENT_METHODS_SUCCESS = 'LIST_PAYMENT_METHODS_SUCCESS';
export const LIST_PAYMENT_METHODS_FAILURE = 'LIST_PAYMENT_METHODS_FAILURE';

export const INITIATE_CART_PAYMENT_REQUEST = 'INITIATE_CART_PAYMENT_REQUEST';
export const INITIATE_CART_PAYMENT_SUCCESS = 'INITIATE_CART_PAYMENT_SUCCESS';
export const INITIATE_CART_PAYMENT_FAILURE = 'INITIATE_CART_PAYMENT_FAILURE';
import styled from "@emotion/styled";

export const Input = styled.input`
  width: 100%;
  border: 0;
  background: #e3edf3;
  border-radius: 5px;
  padding: 1.5rem;
  font-size: 16px;
  font-weight: 400;

  ::placeholder {
    color: #6c757d;
  }
`;

export const Card = styled.div`
  background-color: ${(props) => props.theme.secondaryColor};
  color: #fff;
`;

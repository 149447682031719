import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const modalFormColumn = css`
  flex-basis: 47.5%;
  max-width: 47.5%;
`;

export const modalFormDatePicker = css`
  margin-top: 12px;
  background: var(--background-color);
  border: 0;
  width: 100%;
  padding: 15px 10px;
  border-radius: 4px;
  height: 48px;
  color: var(--primary-color);
  font-size: 15px;
  font-weight: 300;
`;


export const mainBody = css`
  border: 1px solid #e3edf3;
  border-radius: 10px;
  background: var(--background-color);
  padding: 40px 40px;
  // width: 48.5%;
  margin-top: 30px;
  margin-bottom: 50px;
  

`;

export const Row = styled.div`
  // max-width: 50%;
  max-width: 500px;
  margin: auto;

`;

export const selectWrapper = css`
  margin-bottom: 30px;
`;

export const selectDropdownContainer = css`
  max-width: 47.5%;
  flex-basis: 47.5%;
`;

export const buttonWrapper = css`
  display: flex;
  // justify-content: flex-start;
`;

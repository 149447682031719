// eslint-disable-next-line no-unused-vars
import React from 'react';
/** @jsx jsx */
/** @jsxRuntime classic */

import { jsx } from '@emotion/react';
import { columnContainer, RowContent, RowHeading } from './style';

const Column = ({ heading, content }) => {
  return (
    <div css={columnContainer}>
      <RowHeading>{heading}</RowHeading>
      <RowContent>{content}</RowContent>
    </div>
  );
};

export default Column;

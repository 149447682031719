import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useSelector, useDispatch } from "react-redux";
// import Select from 'react-select';
import { useHistory } from "react-router-dom";

import "./index.scss";
import NavBar from "Components/Navbar";
import Footer from "Components/Footer";
import manUserIcon from "Assets/svg/man-user-1.svg";
import plusIcon from "Assets/svg/plus.svg";
import editIcon from "Assets/svg/edit-blue.svg";
// import dummyImg from 'Assets/images/dummyImg.png';
import { getAllBeneficiaries } from "Store/actions/beneficiary.action";
import Spinner from "Components/Spinner";
import Pagination from "Components/Pagination";
import usePagination from "Components/Pagination/usePagination";
import { searchFilter, filterData, checkUrlExists } from "Utils/Helpers/general";
import avatar from "Assets/images/gravatar.png";
import BackArrow from "Components/BackArrow";
import { BrandedRegularButton } from "../../../../Components/BrandingComponents";

const CardTemplate = ({ item, languageStatus, dictionary, sendMoneyClick, editButtonClick }) => {
  const { firstName, lastName, profilePhoto } = item;
  let profileImageStatus = checkUrlExists(profilePhoto);
  let profileImage;
  typeof profilePhoto === "undefined" || typeof profileImageStatus === "undefined" ? (profileImage = avatar)
    : (profileImage = profilePhoto.replace("?type=profile", ""));

  return (
    <div className="benView__card">
      <div className="benView__card--ellipse-top" />
      <div className="benView__card--ellipse-bottom" />

      <div className="benView__card__image--outer">
        <div className="benView__card__image--inner">
          <div className="benView__card__image--main">
            <img src={profileImage} alt="beneficiary" />
          </div>
        </div>
      </div>

      <h3 className="benView__card--userName">{`${firstName} ${lastName}`}</h3>

      <div className="benView__card__actions">
        <BrandedRegularButton onClick={() => sendMoneyClick(item)}>
          {languageStatus ? dictionary["title_send_money"] : "Send Money"}
        </BrandedRegularButton>
        <button onClick={() => editButtonClick(item)}>
          <img src={editIcon} alt="edit" />
        </button>
      </div>
    </div>
  );
};

const CardsList = ({ benData, languageStatus, dictionary, sendMoneyClick, editButtonClick }) => {
  return (
    <>
      {React.Children.toArray(
        benData.map((item) => (
          <CardTemplate
            item={item}
            languageStatus={languageStatus}
            dictionary={dictionary}
            sendMoneyClick={sendMoneyClick}
            editButtonClick={editButtonClick}
          />
        ))
      )}
    </>
  );
};

const ViewBeneficiaries = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [filterText, setFilterText] = useState("");
  const [collection, setCollection] = useState([]);

  const benSelector = useSelector(({ beneficiaryReducer, domainBrandReducer }) => ({
    list: beneficiaryReducer.list,
    loading: beneficiaryReducer.loading
  }));

  const domainBrand = useSelector(({ domainBrandReducer }) => ({ domainBrand: domainBrandReducer }));
  const languageDictionary = domainBrand.domainBrand["languageDictionary"];
  const languageDictionaryStatus = !(typeof languageDictionary === "undefined" || languageDictionary === null);


  useEffect(() => {
    const data = benSelector.list;
    if (data.length > 0) {
      const filteredData = searchFilter(data, filterText, ["firstName", "lastName"]);
      setCollection(filteredData);
    }
  }, [filterText, benSelector.list]);

  useEffect(() => {
    dispatch(getAllBeneficiaries());
  }, [dispatch]);

  // const collection = benSelector.list;
  const PAGE_LIMIT = 9;
  const [currentData, onPageChange] = usePagination({
    collection,
    pageLimit: PAGE_LIMIT
  });

  const sendMoneyClick = (stateData) => {
    history.push({
      pathname: "/transfer",
      state: stateData
    });
  };

  const editButtonClick = (stateData) => {
    history.push({
      pathname: "/beneficiaries/update",
      state: stateData
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <>
      <Helmet>
        <title>Beneficiaries</title>
      </Helmet>
      <NavBar />
      <main className="benView__main">
        <div className="benView__main__canvasImg">
          <img src={manUserIcon} alt="off canvas icon" />
        </div>
        <div className="benView__main__container">
          <div className="d-flex">
            <BackArrow />
            <h1 className="benView__main__heading">
              {languageDictionaryStatus ? languageDictionary["WO_BENEFICIARIE_-1845397122"] : "Beneficiary"}
            </h1>
          </div>
          <div className="benView__main__controls">
            <div className="benView__main__controls-button">
              <BrandedRegularButton onClick={() => history.push("/beneficiaries/create")}>
                <span>
                  <img src={plusIcon} alt="plus icon" />
                </span>
                <span>{languageDictionaryStatus ? languageDictionary["WO_ADD_A_NEW_BENEFICIAR_1555543493"] : "Add New Beneficiary"}</span>
              </BrandedRegularButton>
            </div>
          </div>

          {benSelector.loading ? (
            <Spinner />
          ) : (
            <React.Fragment>
              <div className="benview__sortSelect">
                <label htmlFor="filterText">
                  <input
                    type="text"
                    name="filterText"
                    id="filterText"
                    placeholder={languageDictionaryStatus ? languageDictionary["WO_SEARCH_BY_NAM_-257888228"] : "Search by name"}
                    value={filterText}
                    onChange={(e) => setFilterText(e.target.value)}
                  />
                </label>
              </div>
              <div className="benView__main__cardContainer">
                {benSelector.loading ||
                (benSelector.list.length > 0 && (
                  <CardsList
                    benData={currentData}
                    languageStatus={currentData}
                    dictionary={currentData}
                    sendMoneyClick={sendMoneyClick}
                    editButtonClick={editButtonClick}
                  />
                ))}
              </div>
              <Pagination
                resultName="Total number of beneficiaries"
                onPageChange={onPageChange}
                pageLimit={PAGE_LIMIT}
                totalRecords={collection.length}
              />
            </React.Fragment>
          )}
        </div>
      </main>
      <Footer />
    </>
  );
};

export default ViewBeneficiaries;

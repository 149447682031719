export const FETCH_MOBILE_MONEY_COUNTRY_LIST_REQUEST = "FETCH_MOBILE_MONEY_COUNTRY_LIST_REQUEST";
export const FETCH_MOBILE_MONEY_COUNTRY_LIST_SUCCESS = "FETCH_MOBILE_MONEY_COUNTRY_LIST_SUCCESS";
export const FETCH_MOBILE_MONEY_COUNTRY_LIST_FAILURE = "FETCH_MOBILE_MONEY_COUNTRY_LIST_FAILURE";

export const FETCH_SUPPORTED_CURRENCIES_REQUEST = "FETCH_SUPPORTED_CURRENCIES_REQUEST";
export const FETCH_SUPPORTED_CURRENCIES_SUCCESS = "FETCH_SUPPORTED_CURRENCIES_SUCCESS";
export const FETCH_SUPPORTED_CURRENCIES_FAILURE = "FETCH_SUPPORTED_CURRENCIES_FAILURE";

export const FETCH_SUPPORTED_DELIVERY_CURRENCIES_REQUEST = "FETCH_SUPPORTED_DELIVERY_CURRENCIES_REQUEST";
export const FETCH_SUPPORTED_DELIVERY_CURRENCIES_SUCCESS = "FETCH_SUPPORTED_DELIVERY_CURRENCIES_SUCCESS";
export const FETCH_SUPPORTED_DELIVERY_CURRENCIES_FAILURE = "FETCH_SUPPORTED_DELIVERY_CURRENCIES_FAILURE";

export const FETCH_QUOTE_FOR_MOBILE_MONEY_REQUEST = "FETCH_QUOTE_FOR_MOBILE_MONEY_REQUEST";
export const FETCH_QUOTE_FOR_MOBILE_MONEY_SUCCESS = "FETCH_QUOTE_FOR_MOBILE_MONEY_SUCCESS";
export const FETCH_QUOTE_FOR_MOBILE_MONEY_FAILURE = "FETCH_QUOTE_FOR_MOBILE_MONEY_FAILURE";

import * as AuthConstants from "Store/constants/auth.constants";
import * as Loading from "Store/constants/loadingState.constants";

const initialState = {
  isAuthenticated: false,
  awaitingToken: false,
  loginStatus: "",
  awaitingComplianceAction: [],
  notAuthenticated: true,
  forgotPasswordStatus: "",
  message: "",
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case AuthConstants.LOGIN_REQUEST:
      return {
        ...state,
        loginStatus: Loading.FETCHING,
      };

    case AuthConstants.DISABLED_AWAITING_TOKEN:
      return {...state, awaitingToken: false};

    case AuthConstants.AWAITING_TOKEN:
      return {...state, loginStatus: Loading.SUCCESS, awaitingToken: true};

    case AuthConstants.LOGIN_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isAuthenticated: true,
        loginStatus: Loading.SUCCESS,
        authBody: action.payload,
        awaitingComplianceAction: action.payload.awaitingComplianceAction,
        notAuthenticated: false,
        userAccountVerified: action.payload["profileDetailsResponseDTO"]["userAccountVerified"],
      };

    case AuthConstants.LOGIN_FAILURE:
      return {
        ...state,
        loginStatus: Loading.ERROR,
      };

    case AuthConstants.FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        forgotPasswordStatus: Loading.FETCHING,
      };

    case AuthConstants.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        ...action.payload,
        forgotPasswordStatus: Loading.SUCCESS,
      };

    case AuthConstants.FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        forgotPasswordStatus: Loading.ERROR,
      };

    case AuthConstants.SET_LOGGED_IN_USER:
      return {
        ...state,
        isAuthenticated: true,
      };

    case AuthConstants.LOG_OUT: {
      return { ...state, isAuthenticated: false };
    }

    default:
      return state;
  }
};

export default authReducer;

import { useCallback, useEffect } from "react";
import debounce from "lodash.debounce";
import { useDispatch } from "react-redux";

import { getQuoteData } from "Store/actions/transfer.action";

const useQuoteDebounceHook = ({ amount, currency }) => {
  const dispatch = useDispatch();
  const debounceAmount = useCallback(debounce((amountVal, currency) => {
      dispatch(getQuoteData({ amount: amountVal, currency: currency.value }));
    }, 1000),
    []);

  useEffect(() => {
    const formattedAmount = Number(amount.replace(/,/g, ""));
    debounceAmount(formattedAmount, currency);
  }, [amount, debounceAmount, currency]);

  return [];
};

export default useQuoteDebounceHook;

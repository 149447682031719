import { css } from '@emotion/react';

export const FormRow = css`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;

  @media (max-width: 56.25rem) {
    flex-direction: column;
  }
`;

export const TransContainer = css`
  flex-basis: 55%;
  padding-right: 2.5%;
  border-right: 1px solid #e5eef7;

  @media (max-width: 56.25rem) {
    padding-right: 0;
    border-right: none;
  }
`;

export const selectWrapper = css`
  flex-basis: 48%;
  max-width: 100%;

  @media (max-width: 56.25rem) {
    width: 100%;
    font-size: 0.9375rem;
    margin: 0 0 1rem;
  }
`;

export const selectDataWrapper = css`
  flex-basis: 65%;
  max-width: 100%;

  @media (max-width: 56.25rem) {
    width: 100%;
    font-size: 0.9375rem;
    margin: 0 0 1rem;
  }
`;

export const buttonWrapper = css`
  flex-basis: 32%;
  max-width: 40%;
  margin-top: 2.3rem;

  @media (max-width: 56.25rem) {
    margin-top: 0;
    max-width: 60%;
  }
`;

export const leftColumn = css`
  flex-basis: 30%;
  max-width: 30%;
  align-self: flex-end;
  @media (max-width: 56.25rem) {
    align-self: flex-start;
    max-width: 100%;
  }
`;

export const middleColumn = css`
  flex-basis: 40%;
  max-width: 40%;
  align-self: flex-end;
  @media (max-width: 56.25rem) {
    align-self: flex-start;
    max-width: 100%;
  }
`;

export const rightColumn = css`
  flex-basis: 25%;
  max-width: 25%;
  align-self: flex-end;
  @media (max-width: 56.25rem) {
    align-self: flex-start;
    max-width: 100%;
  }
`;

export const singleRowItem = css`
  flex: 1;
`;

import { css } from '@emotion/react';

export const containerStyle = css`
  max-width: 1200px;
  margin: 0 auto;

  @media screen and (min-width: 900px) and (max-width: 1250px) {
    padding: 0 5%;
  }
`;

import React from "react";
import rightArrowIcon from "Assets/svg/right-arrow.svg";
import "./index.css";

const PaymentCard = ({ onChangeActiveMethod, type, icon, text, prefix, muted, currency, selectIcon }) => {

  const selectCardType = () => {
    onChangeActiveMethod(type);
  };

  if (typeof muted === "undefined" || muted === "" || muted === "0") muted = `no `;
  else if (muted % 1 !== 0) muted = parseFloat(muted).toFixed(2) + " " + currency + " in";
  else muted = parseFloat(muted) + " " + currency + " in";

  const selectedIconRender =
    <div className="col-auto text-center pl-2">
      <img src={rightArrowIcon} alt="right arrow icon" />
    </div>;

  return (
    <div className="col-md-12 col-xl-12 mb-3 pl-0">
      <div className="card card-color w-100 rounded-2 p-1 position-relative overflow-hidden"
           onClick={selectCardType}
           role="button"
           tabIndex="-1"
           onKeyPress={() => {
           }}
      >
        <div className="card-body">
          <div className="row g-0 align-items-center">
            <div className="col">
              <div className="media align-items-center">
                <img src={icon} alt="account icon" className="mr-3 img-fluid" />
                <div className="media-body">
                  <div className="text mt-2">{text}</div>
                  <div className="muted">{prefix} {muted} payment fees</div>
                </div>
              </div>
            </div>
            {selectIcon ? selectedIconRender : ""}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentCard;

import styled from "@emotion/styled";

export const NextButton = styled.button`
  font-size: 1rem;
`;

export const Order = styled.div`
@media (max-width: 991.98px) {
    overflow-x: auto;
}
`;

export const OrderWrapper = styled.div`
@media (max-width: 991.98px) {
    min-width: 768px;
}
`;

export const OrderHeader = styled.div`
    text-transform: uppercase;
    letter-spacing: 0.2em;
    padding: 1.2rem 2rem;
    background: #0055AD;
    color: white;
`;

export const OrderBody = styled.div`
    padding: 0 2rem;
`;

export const Block = styled.div`
    border-radius: 10px;
    background: #0055AD;

`;
export const BlockHeader = styled.div`
    padding: 1.2rem 1.5rem;
    color: white;
`;

export const BlockBody = styled.div`
    padding: 1.2rem 1.5rem;
    color: white;

`;

export const Item = styled.li`
    display: flex;
    justify-content: space-between;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid #E3EDF3;
`;


/** @jsx jsx */
/** @jsxRuntime classic */

import { jsx, css, keyframes } from '@emotion/react';

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export default ({ spinnerSize, borderSize }) => {
  const borderWidth = borderSize || '3px';
  const spinnerDimension = spinnerSize || '23px';

  return (
    <div
      css={(theme) => css`
        height: ${spinnerDimension};
        width: ${spinnerDimension};
        border: ${borderWidth} solid transparent;
        border-radius: 50%;
        border-top: ${borderWidth} solid ${theme.primaryColor};
        border-bottom: ${borderWidth} solid ${theme.primaryColor};
        animation: ${spin} 2s linear infinite;
      `}
    />
  );
};
